import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDisplay, useGetCurrentTheme, useGetPartnerId } from 'hooks';
import { useGetSpaceGeolocation, useGetUserRelatedSpaceInfo } from './hooks';
import { useLazyGetSpaceCoursesBySpaceIdQuery, ISpace, useGetSpaceByNameQuery } from 'store';
import { usePageTitle } from 'hooks/usePageTitle';
import useSendLoadedMessageToIframe from 'hooks/useSendLoadedMessageToIframe';

import { ROUTES } from 'app/constants';

import { ReactComponent as Chevron } from './assets/chevron.svg';

import SpacePageTop from './ui/SpacePageTop';
import { CourseCard } from 'shared/ui/course-card';
import SpaceEditingModal from './ui/SpaceEditingModal';
import SpaceCourseConstructor from './ui/SpaceCourseConstructor';
import { CourseForm } from 'features/coursesEditor';
import { ICourse } from 'interface/courses';
import SpaceQuests from './ui/SpaceQuest';
import { CourseBanner } from '../study/courses/courseBanner/CourseBanner';
import CreateQuestModal from './ui/SpaceQuest/questModal';
import DeleteSpaceButton from './ui/DeleteSpace/button';
import { SpaceAnalytics } from './ui/SpaceAnalytics';
import { SpacePageContext, TSpacePageModal } from './context';
import { SpaceCoursesLoader } from './ui/SpaceShimmerLoader/spaceShimmerLoader';
import SpaceLeaderboardWinners from 'pages/leaderboard/components/spacePreview/SpaceLeaderboardPreview';
import { GlobalLoader } from 'shared/utils/GlobalLoader/GlobalLoader';

import * as S from './space.styles';

const SpacePage = () => {
  const partnerId = useGetPartnerId();
  const { spaceName } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useDisplay();
  const theme = useGetCurrentTheme();
  const { state: navigateState } = useLocation();

  const {
    data: space,
    isLoading,
    isError,
  } = useGetSpaceByNameQuery(spaceName?.replaceAll('_', ' ') || '', {
    refetchOnMountOrArgChange: true,
  });
  const [getCourses, { isFetching: coursesLoading }] = useLazyGetSpaceCoursesBySpaceIdQuery();
  const { isAbleToEdit } = useGetUserRelatedSpaceInfo(space || null);

  const [creationModalOpen, setCreationModalOpen] = useState(false);
  const [courses, setCourses] = useState<ICourse[]>([]);
  const [selectedCourse, setSelectedCourse] = useState<ICourse | null>(null);
  const [modal, setModal] = useState<TSpacePageModal>(null);
  const [questModalVisible, setQuestModalVisible] = useState(false);
  const [currentSpace, setCurrentSpace] = useState<ISpace | null>(null);

  const isEditOnLoad = useMemo(() => (navigateState ? navigateState.edit : false), [navigateState]);

  useSendLoadedMessageToIframe(!!space && !isLoading);

  usePageTitle(currentSpace?.name || null);

  const handleGetCourses = useCallback(
    (spaceId?: string) => {
      if (spaceId) {
        getCourses({ spaceId, page: 1, limit: 999999 })
          .unwrap()
          .then((res) => {
            setCourses(res.items);
          });
      }
    },
    [getCourses]
  );

  const updateSpace = (arg: Partial<ISpace>) => {
    if (!!currentSpace) {
      setCurrentSpace((s) => (s ? { ...s, ...arg } : null));
    }
  };

  const handleCourseSubmit = useCallback(() => {
    if (space) {
      handleGetCourses(space._id);
    }
    setCreationModalOpen(false);
  }, [space, handleGetCourses]);

  useEffect(() => {
    if (space) {
      setCurrentSpace(space);
      if (isEditOnLoad && isAbleToEdit) {
        setModal('edit');
      }
      handleGetCourses(space._id);
    }
  }, [space, isEditOnLoad, isAbleToEdit, handleGetCourses]);

  useEffect(() => {
    if (isError) {
      if (!partnerId) {
        navigate(ROUTES.MAIN);
      }
    }
  }, [isError, partnerId, navigate]);

  const CoursesContent = useMemo(
    () => () => {
      if (coursesLoading) {
        return <SpaceCoursesLoader />;
      }
      if (!courses.length && (!isAbleToEdit || isMobile)) {
        return null;
      }

      return (
        <S.Col>
          {!isMobile && <S.Topic $theme={theme}>{'Courses'}</S.Topic>}
          <S.CoursesList className="space-courses-list" $smaller={true}>
            {courses.map((course) => {
              const isPartnerId = !!partnerId;
              const url = `/${spaceName}/course/${course._id}`;
              const currentUrl = isPartnerId ? `${url}/?partnerID=${partnerId}` : url;

              return (
                <CourseCard
                  isInSpace={true}
                  openEditorInSpace={() => navigate(currentUrl)}
                  key={course._id}
                  isEditMode={isAbleToEdit}
                  onClick={() => setSelectedCourse(course)}
                  course={course}
                  isSpaceAdmin={isAbleToEdit}
                  spaceId={space?._id}
                  onAfterDelete={() => handleGetCourses(space?._id)}
                />
              );
            })}
            {isAbleToEdit && !isMobile && (
              <S.AddCourseBox $small={true} onClick={() => setCreationModalOpen(true)}>
                <S.AddCourseTop $theme={theme} $small={true} />
                <S.AddCourseBottom $theme={theme} $small={true}>
                  New Course
                </S.AddCourseBottom>
              </S.AddCourseBox>
            )}
          </S.CoursesList>
        </S.Col>
      );
    },
    [coursesLoading, isAbleToEdit, isMobile, theme, courses, handleGetCourses]
  );

  return (
    <SpacePageContext.Provider value={{ space: currentSpace, updateSpace, setModal, modal }}>
      <S.Page $loading={isLoading && isMobile} className={partnerId ? 'partner' : ''}>
        {isMobile && !isLoading && <S.PageBg initial={{ opacity: 0 }} animate={{ opacity: 1 }} $bg={currentSpace?.image} />}
        <SpaceCourseConstructor space={currentSpace} />
        <SpaceAnalytics />

        <CourseBanner course={selectedCourse as ICourse} open={!!selectedCourse} setSelectedCourse={() => setSelectedCourse(null)} />
        <CourseForm
          open={creationModalOpen}
          onClose={() => {
            setCreationModalOpen(false);
          }}
          spaceId={space?._id || ''}
          onSubmitHandler={handleCourseSubmit}
        />
        {!!space && !partnerId && (
          <S.BackButton $theme={theme} onClick={() => navigate(ROUTES.SPACES)}>
            <Chevron />
            Back to spaces
          </S.BackButton>
        )}
        <SpacePageTop coursesLength={courses.length} />
        <SpaceQuests
          spaceId={currentSpace?._id || ''}
          spaceImage={currentSpace?.imageThumb}
          isOwner={isAbleToEdit}
          onAddQuest={() => setQuestModalVisible(true)}
        />
        {!!space && !isMobile && <SpaceLeaderboardWinners spaceName={space.name} />}
        <CoursesContent />
        {isMobile && !!space && <SpaceLeaderboardWinners spaceName={space.name} />}
        {isAbleToEdit && <SpaceEditingModal isVisible={modal === 'edit'} />}
        {isAbleToEdit && (
          <CreateQuestModal spaceId={currentSpace?._id || ''} isOpen={questModalVisible} onClose={() => setQuestModalVisible(false)} />
        )}
        <DeleteSpaceButton spaceId={currentSpace?._id} />
      </S.Page>
    </SpacePageContext.Provider>
  );
};

export const SpacePagePartnerRouter = () => {
  const { isProcessed } = useGetSpaceGeolocation();

  if (!isProcessed) {
    return <GlobalLoader />;
  }
  return <SpacePage />;
};

export default SpacePage;
