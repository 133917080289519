import styled from 'styled-components';
import { NullButton } from '../button';
import { THEMES } from 'app/constants';

export const IconBox = styled.div`
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const Label = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 100%;
`;

export const Button = styled(NullButton)<{ $theme: THEMES; $disabled?: boolean }>`
  display: flex;
  align-items: center;
  gap: 6px;
  transition: 0.3s;
  opacity: ${({ $disabled }) => Number(!$disabled)};
  ${({ $disabled }) => $disabled && 'pointer-events: none;'}
  &.add {
    & ${IconBox} {
      color: var(--font-color-strong);
      background: ${({ $theme }) => ($theme === THEMES.DARK ? '#FFFFFF0A' : '#1111110A')};
    }
    & ${Label} {
      color: var(--font-color);
    }
  }
  &.import {
    & ${IconBox} {
      color: #ffffff;
      background: var(--mighty-blue);
    }
    & ${Label} {
      color: var(--mighty-blue);
    }
  }
  &:hover {
    scale: 1.02;
  }
`;
