import { useEffect, useRef, useState } from 'react';
import { useIsConnectionRestored, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useLazyGetUserDataQuery, useLoginMFAMutation } from 'store';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { Account, TonProofItemReplySuccess } from '@tonconnect/ui';
import { EAuthMethods } from 'interface';

const payloadTTLMS = 1000 * 60 * 15;

export function useTONBackendAuth() {
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const interval = useRef<ReturnType<typeof setInterval> | undefined>();
  const [token, setToken] = useState<null | string>(null);
  const [getUser, { isLoading: isUserLoading }] = useLazyGetUserDataQuery();

  const [login, { isLoading }] = useLoginMFAMutation();

  const generatePayload = () => ({ tonProof: Date.now().toString() });

  const checkProof = async (proof: TonProofItemReplySuccess['proof'], account: Account): Promise<string | undefined> => {
    try {
      const referralCode = localStorage.getItem('referralCode');

      const requestBody = {
        address: account.address,
        network: account.chain,
        proof: {
          ...proof,
          state_init: account.walletStateInit,
        },
      };

      const response = await login({ type: EAuthMethods.TON, data: requestBody, referralCode: referralCode || undefined }).unwrap();

      return response?.accessToken;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isConnectionRestored) {
      return;
    }

    clearInterval(interval.current);

    if (!wallet) {
      setToken(null);

      const refreshPayload = async () => {
        tonConnectUI.setConnectRequestParameters({
          state: 'loading',
        });

        const value = generatePayload();

        if (!value) {
          tonConnectUI.setConnectRequestParameters(null);
        } else {
          tonConnectUI.setConnectRequestParameters({
            state: 'ready',
            value,
          });
        }
      };

      refreshPayload();
      setInterval(refreshPayload, payloadTTLMS);
      return;
    }

    if (token) {
      return;
    }

    if (!isLoading && !isUserLoading) {
      if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
        checkProof(wallet.connectItems.tonProof.proof, wallet.account).then((result) => {
          if (result) {
            getUser().then(() => {
              setTimeout(() => {
                setToken(result);
              }, 1000);
            });
          } else {
            setToken(null);
            toast.error(<Answer type="incorrect" label="Please try another wallet" />);
            tonConnectUI.disconnect();
          }
        });
      } else {
        toast.error(<Answer type="incorrect" label="Please try another wallet" />);
        tonConnectUI.disconnect();
      }
    }
  }, [wallet, isConnectionRestored, isLoading]);
  return { isLoading };
}
