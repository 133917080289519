import { AnimatePresence } from 'framer-motion';
import * as S from './styles';
import { LockIcon } from 'assets/icons/icons';

const LockLabel = ({ count = 0 }: { count?: number }) => {
  return (
    <AnimatePresence>
      {count > 0 && (
        <S.Lock initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} exit={{ opacity: 0, scale: 0 }}>
          <LockIcon />
          {count}
        </S.Lock>
      )}
    </AnimatePresence>
  );
};

export default LockLabel;
