import { useEffect } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ReactComponent as PassIcon } from 'assets/components/password.svg';
import styles from './styles.module.css';
import { Error, StrongPasswordIndicator } from '../../../ui';
import { getPasswordStrong } from '../../../utils';
import { ISetNewPasswordForm } from 'interface';
import { BasicButtonV1, InputPassword } from 'shared/ui';

interface IProps {
  error?: string;
  isLoading: boolean;
  onSubmitHandler: (data: ISetNewPasswordForm) => void;
}

export const SetNewPasswordForm = ({ isLoading, error, onSubmitHandler }: IProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const schema = yup.object().shape({
    password: yup
      .string()
      .min(8, t('common:formErrors.minLength', { count: 8 }))
      .max(32, t('common:formErrors.maxLength', { count: 32 }))
      .required(t('common:formErrors.requiredPassword')),
    confirm_password: yup
      .string()
      .min(8, t('common:formErrors.minLength', { count: 8 }))
      .max(32, t('common:formErrors.maxLength', { count: 32 }))
      .required(t('common:formErrors.requiredPassword'))
      .oneOf([yup.ref('password')], t('common:formErrors.notMatchPassword')),
  });
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<ISetNewPasswordForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });
  //Логика для слежения за изменениями ввода паролей
  const password = watch('password');
  const cpassword = watch('confirm_password');
  useEffect(() => {
    if (password !== cpassword && !!cpassword && !!password) {
      setError('confirm_password', { message: "The passwords don't match" });
    } else {
      clearErrors('confirm_password');
    }
  }, [password, cpassword]);
  const onSubmit = (data: ISetNewPasswordForm) => {
    onSubmitHandler(data);
  };

  const strong = getPasswordStrong(password, t('common:strongPassword', { returnObjects: true })).strong;
  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <InputPassword
            className={clsx(styles.input, {
              [styles.inputWithValue]: !!password,
              [styles.lowStrongPassword]: strong === 'no_lower' || strong === 'no_upper' || strong === 'no_number',
              [styles.middleStrongPassword]: strong === 'middle',
              [styles.highStrongPassword]: strong === 'high',
            })}
            icon={PassIcon}
            placeholder={t('resetPassword.setNewPassword.form.password')}
            isError={!!errors?.password?.message || !!errors?.confirm_password?.message}
            {...field}
          />
        )}
      />
      <StrongPasswordIndicator passInfo={getPasswordStrong(password, t('common:strongPassword', { returnObjects: true }))} />
      <Controller
        name="confirm_password"
        control={control}
        render={({ field }) => (
          <InputPassword
            className={clsx({
              [styles.lowStrongPassword]: strong === 'no_lower' || strong === 'no_upper' || strong === 'no_number',
              [styles.middleStrongPassword]: strong === 'middle',
              [styles.highStrongPassword]: strong === 'high',
            })}
            icon={PassIcon}
            placeholder={t('resetPassword.setNewPassword.form.confirmPassword')}
            isError={!!errors?.password?.message || !!errors?.confirm_password?.message}
            {...field}
          />
        )}
      />
      <div className={styles.errorContainer}>
        <Error title={errors?.password?.message || errors?.confirm_password?.message || error} />
      </div>
      <BasicButtonV1 isLoading={isLoading} disabled={isLoading || !isValid} type={'submit'}>
        {t('resetPassword.setNewPassword.form.submit')}
      </BasicButtonV1>
    </form>
  );
};
