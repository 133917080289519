import { useNavigate, useParams } from 'react-router-dom';
import { ICourseFull } from 'interface/courses';
import { useState } from 'react';
import { coursesConstructorAPI, getCurrentTheme, useGetSpaceByNameQuery } from 'store';
import { toast } from 'react-toastify';
import { AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';

import { ReactComponent as PencilIcon } from '../assets/pencil-icon.svg';
import { ReactComponent as UnpublishIcon } from '../assets/closed-eye-icon.svg';
import { ReactComponent as PublishIcon } from '../assets/eye-icon.svg';
import { ReactComponent as TrashIcon } from '../assets/trash-icon.svg';

import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import * as S from './styles';
import Answer from 'components/library/messages/Answer';
import { ECourseSpaceVisibility } from 'app/constants';

type Props = {
  course: ICourseFull;
  isVisible: boolean;
  onDelete?: (arg: string) => void;
  handleNavigate: () => void;
  color?: string;
};

const CircleMenu = ({ course, isVisible, onDelete, color, handleNavigate }: Props) => {
  const navigate = useNavigate();
  const { spaceName = '' } = useParams();
  const [toggleSpaceVisibilityStatus, { isLoading: isDevToggling }] = coursesConstructorAPI.useEditCourseSpaceVisibilityMutation();
  const [isInDev, setInDev] = useState(course?.spaceVisibility === ECourseSpaceVisibility.Invisible);
  const spaceNamePrepared = spaceName.replaceAll('_', ' ');
  const { data: spaceInfo } = useGetSpaceByNameQuery(spaceNamePrepared ?? '');

  const theme = useSelector(getCurrentTheme);

  const [activeButtons, setActiveButtons] = useState([false, false, false]);

  const handleEnter = (i: number) => () => {
    const newActive = [false, false, false];
    newActive[i] = true;
    setActiveButtons(newActive);
  };

  const handleLeave = () => setActiveButtons([false, false, false]);

  function handleToggleDev() {
    const { spaceVisibility } = course;
    setInDev((prev) => !prev);
    setTimeout(() => {
      if (spaceInfo) {
        const newVisibility =
          spaceVisibility === ECourseSpaceVisibility.Invisible ? ECourseSpaceVisibility.Visible : ECourseSpaceVisibility.Invisible;

        toggleSpaceVisibilityStatus({ spaceVisibility: newVisibility, spaceId: spaceInfo._id, id: course._id })
          .unwrap()
          .catch((ex) => {
            console.error(ex);
            toast.error(<Answer label="" subtext={ex?.data?.message || 'You are unauthorized for this...'} type="incorrect" />);
            setInDev((prev) => !prev);
          })
          .then(() => setInDev((prev) => !prev));
      }
    }, 300);
  }

  const handleOpenEditor = () => {
    if (!course.chapters.length) {
      navigate(`/${spaceName}/course/${course._id}`);
      return;
    }
    if (!course.chapters[0].lessons.length) {
      navigate(`/${spaceName}/chapter/${course.chapters[0]._id}`);
      return;
    }
    navigate(`/${spaceName}/lesson/${course.chapters[0].lessons[0]._id}`);
  };

  const devStatusLabel = isInDev ? 'Unblished' : 'Published';

  return (
    <div
      style={{
        width: '106px',
        height: '150px',
        position: 'absolute',
        top: '-3px',
        right: '-3px',
        transform: `translate(50%, -52.5px)`,
        pointerEvents: isVisible ? 'all' : 'none',
        overflow: 'hidden',
        zIndex: '5',
      }}
    >
      <AnimatePresence>
        {isVisible ? (
          <S.Container
            key={'visible-menu'}
            onClick={(e) => e.stopPropagation()}
            animate={{ rotate: '0deg', opacity: 1 }}
            initial={{ rotate: '180deg', opacity: 0 }}
            exit={{ rotate: '45deg', opacity: -1 }}
            style={{ pointerEvents: isDevToggling ? 'none' : 'all', transformOrigin: '50% 50%' }}
          >
            <S.SVGField $theme={theme} />
            <S.SVGButtonBg1 $theme={theme} $active={activeButtons[0]} />
            <S.SVGButtonBg2 $theme={theme} $active={activeButtons[1]} />
            <S.SVGButtonBg3 $theme={theme} $active={activeButtons[2]} />

            <S.Button $theme={theme} className="_1" onMouseEnter={handleEnter(0)} onClick={handleOpenEditor} onMouseLeave={handleLeave}>
              <PencilIcon />
            </S.Button>
            <S.Button $theme={theme} className="_2" onMouseEnter={handleEnter(1)} onClick={handleToggleDev} onMouseLeave={handleLeave}>
              {isDevToggling ? <MightyLoaderMini style={{ width: '25px' }} /> : isInDev ? <UnpublishIcon /> : <PublishIcon />}
            </S.Button>
            <S.Button
              $theme={theme}
              className="_3"
              onMouseEnter={handleEnter(2)}
              onClick={() => onDelete && onDelete(course._id)}
              onMouseLeave={handleLeave}
            >
              <TrashIcon />
            </S.Button>
          </S.Container>
        ) : null}
        {isVisible && <S.Fakehalf onClick={handleNavigate} />}
      </AnimatePresence>
      <S.Tooltip key={'edit'} $isVisible={isVisible && activeButtons[0]}>
        Edit
      </S.Tooltip>
      <S.Tooltip key={devStatusLabel} $isVisible={isVisible && activeButtons[1]}>
        {devStatusLabel}
      </S.Tooltip>
      <S.Tooltip key={'delete'} $isVisible={isVisible && activeButtons[2]}>
        Delete
      </S.Tooltip>
    </div>
  );
};

export default CircleMenu;
