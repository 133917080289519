import * as S from './styles';
import { useLazyGetMyPartsQuery } from 'store';

import ReferralsProgress from './progress';
import { useDisplay, useGetUserData, useGetCurrentTheme } from 'hooks';
import CopyBlock from './copy';
import { useNavigate } from 'react-router-dom';
import { BackButton } from 'components/library/BackButton';

import { ReactComponent as RefIcon } from '../assets/refIcon.svg';
import chestImgSmall from '../assets/chest-image-small.png';
import ChestImageAnimated from './ChestAnimated';
import { useEffect, useMemo, useState } from 'react';
import ReferralsModal from '../modal';
import RefsSocialsBlock from './socialsBlock';
import { useReferralsShareText } from './utils';

const ReferralsPage = () => {
  const theme = useGetCurrentTheme();
  const [refsModalOpen, setRefsModalOpen] = useState(false);
  const { isMobile } = useDisplay();
  const {
    referrals: { referralCode, referralBonus },
  } = useGetUserData();

  const [, setOldBonus] = useState(referralBonus || 0);

  const navigate = useNavigate();

  const [fetchReferrals] = useLazyGetMyPartsQuery();

  const refLink = referralCode ? `${window.location.origin}/ref/${referralCode}` : '';

  useEffect(() => {
    const interval = setInterval(() => {
      fetchReferrals({ props: ['referrals'] })
        .unwrap()
        .then((res) => {
          setOldBonus((old) => {
            if (res.referrals) {
              if (res.referrals.referralBonus > old) {
                setRefsModalOpen(true);
                return res.referrals.referralBonus;
              }
            }
            return old;
          });
        })
        .catch(console.error);
    }, 5000);
    return () => clearInterval(interval);
  }, [fetchReferrals]);

  const variants = useMemo(
    () =>
      isMobile
        ? {
            initial: {
              y: 1000,
              opacity: 1,
            },
            animate: {
              y: 0,
              scale: 1,
            },
          }
        : {
            initial: {
              scale: 0.3,
              opacity: 0,
            },
            animate: {
              scale: 1,
              opacity: 1,
              y: 0,
            },
          },
    [isMobile]
  );

  return (
    <S.Container $theme={theme}>
      <ReferralsModal isOpen={refsModalOpen} onClose={() => setRefsModalOpen(false)} onSkip={() => {}} />
      {isMobile && <BackButton text={''} className="refs-back-button" onClick={() => navigate('/')} />}
      <S.Content variants={variants} animate={'animate'} initial={'initial'} transition={{ duration: 0.5, bounce: isMobile ? 0.4 : false }}>
        {!isMobile && <BackButton className="refs-back-button" onClick={() => navigate('/')} />}
        {isMobile && <ChestImageAnimated containerClassName="chest" size={207} />}
        <S.Column className="left">
          {!isMobile && <ChestImageAnimated containerClassName="chest" />}
          {!isMobile && (
            <S.Row>
              <S.Sub $theme={theme}>Bonus rewards</S.Sub>
              <S.Tip $theme={theme}>
                <RefIcon /> 1ref <span>=</span> <img src={chestImgSmall} alt="chest" /> 1 chest
              </S.Tip>
            </S.Row>
          )}
          <ReferralsProgress />
          {isMobile && <RefsSocialsBlock />}
        </S.Column>
        <S.Column className="right">
          <S.Title $theme={theme}>
            <S.Blue>Learn</S.Blue> Together, <br />
            <S.Blue>Earn</S.Blue> Together!
          </S.Title>
          <S.Sub $theme={theme}>How it works?</S.Sub>
          <S.List>
            <S.ListItem $theme={theme}>
              <S.ListNum $theme={theme}>1</S.ListNum>
              Share your unique referral link with friends
            </S.ListItem>
            <S.ListItem $theme={theme}>
              <S.ListNum $theme={theme}>2</S.ListNum>
              When a friend signs up using your link and completes any lesson, you both receive a Nature Chest
            </S.ListItem>
            <S.ListItem $theme={theme}>
              <S.ListNum $theme={theme}>3</S.ListNum>
              Get a chest for every referral
            </S.ListItem>
          </S.List>
          <CopyBlock refLink={refLink} />
          {!isMobile && <RefsSocialsBlock />}
        </S.Column>
      </S.Content>
    </S.Container>
  );
};

export default ReferralsPage;
