//
import { NavLink } from 'react-router-dom';
import styles from './styles.module.css';
// import { ReactComponent as WalletIcon } from 'assets/components/wallet.svg';
import { ROUTES } from 'app/constants';
import { LogInForm } from 'features/auth';
import { AnimateWrapper } from 'features/auth/ui';
import { ILogInForm } from 'interface';
// import { Button } from 'shared/ui';
import { useTranslation } from 'react-i18next';

interface IProps {
  isLoading: boolean;
  error?: string;
  onSubmitHandler: (data: ILogInForm) => void;
  openWalletModal: () => void;
}

export const LogIn = ({ isLoading, error, onSubmitHandler, openWalletModal }: IProps) => {
  const { t } = useTranslation('auth');
  return (
    <AnimateWrapper>
      <div className={styles.container}>
        <LogInForm error={error} onSubmitHandler={onSubmitHandler} isLoading={isLoading} />
        <NavLink className={styles.resetPasswordLink} to={ROUTES.RESET_PASSWORD} replace>
          {t('logIn.forgotPassword')}
        </NavLink>
        {/* <div className={styles.subTitle}>{t('or')}</div> */}
        {/* <Button onClick={openWalletModal} icon={WalletIcon} variant={'bordered'}>
          {t('walletAuth')}
        </Button> */}
      </div>
    </AnimateWrapper>
  );
};
