import { motion } from 'framer-motion';
import { FC } from 'react';
import { ICustomDivMotionComponent } from 'shared/ui/motion';

export interface ISlideXProps extends ICustomDivMotionComponent {
  delay?: number;
}

export const SlideX: FC<ISlideXProps> = ({
  delay,
  children,
  initial = { opacity: 0, x: 100 },
  transition = { delay: delay ? delay : 0 },
  animate = { opacity: 1, x: 0 },
  ...rest
}) => {
  return (
    <motion.div initial={initial} animate={animate} transition={transition} {...rest}>
      {children}
    </motion.div>
  );
};

export const SlowSlideX: FC<ISlideXProps> = ({ children, ...rest }) => {
  return (
    <motion.div
      style={{ willChange: 'auto' }}
      initial={{ x: 100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ ease: 'easeOut', duration: 1 }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
