import * as Styled from './styles';
import BasicButton from 'shared/ui/basic-button/button';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';

export const NoPremium = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(ROUTES.FULLSCREEN_PREMIUM);
  };

  return (
    <Styled.Container>
      <Styled.Span>No premium :(</Styled.Span>
      <BasicButton onClick={handleClick} className="button" variant="gold" label="Buy Premium" />
    </Styled.Container>
  );
};
