import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      color: #fff;
    `
  ),
  Header = styled.h3(
    () => css`
      font-size: 57px;
      font-weight: 500;
      text-align: center;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      color: inherit;
      margin-bottom: 14px;
    `
  ),
  Span = styled.span(
    () => css`
      /* display: inline-flex;
      align-items: center; */
      color: inherit;
    `
  ),
  Name = styled.span(
    () => css`
      color: inherit;
    `
  ),
  Img = styled.img(
    () => css`
      height: 48px;
      width: auto;
      aspect-ratio: 1/1;
    `
  ),
  QuoteContainer = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 254px;
      margin: 0 auto 36px;

      ${Quote} {
        border-right: 2px solid rgba(255, 255, 255, 0.2);
        &:last-of-type {
          border-right: none;
        }
      }
    `
  ),
  Quote = styled.span(
    () => css`
      color: inherit;
      font-size: 20px;
      line-height: 20px;
      letter-spacing: 0.5px;
      text-align: center;
      width: 100%;
      white-space: pre;
      &:last-of-type {
        padding-right: 0;
        padding-left: 8px;
      }
    `
  );
