import { ReactComponent as AnswersIcon } from './assets/answers.svg';
import { ReactComponent as CommentsIcon } from './assets/comments.svg';
import { ReactComponent as StudentsIcon } from './assets/students.svg';
import { ReactComponent as SettingsIcon } from './assets/settings.svg';
import * as S from './styles';
import { AnimatePresence } from 'framer-motion';
import { useDashboardContext } from './DashboardContext';
import { useGetStudentsCount } from './hooks';
import { InfoBox, VisibilityChanger } from './components';
import { useGetAnswers, useGetComments } from 'features/CommentsDrawer/hooks';
import { useParams } from 'react-router-dom';
import ResizebleSkeleton from 'shared/ui/course-card/CourseCard/utils/ui/skeletons/resizebleSkeleton';
import { STATIC_HOST_PATH } from 'app/constants/path';
import { Block } from './components/parts/styles';
import { DashboardButton } from './components/parts';
import { CourseForm } from 'features/coursesEditor';
import { useDisclosure, useGetCurrentTheme } from 'hooks';
import { useGetCourseForEditorByPartIdQuery } from 'store';

const CourseDashboard = () => {
  const theme = useGetCurrentTheme();
  const { courseId = '', lessonId = '' } = useParams();
  const { slideId, setTab, answersCount, commentsCount } = useDashboardContext();
  const { isLoading: answersLoading } = useGetAnswers(slideId);
  const { isLoading: commentsLoading } = useGetComments(slideId);
  const { count: studentsCount, isLoading: studentsLoading } = useGetStudentsCount(slideId);
  const { isOpen, open, close } = useDisclosure(false);

  const id = courseId || lessonId;

  const { data: course, isFetching: courseFetching, refetch: refetchCourse } = useGetCourseForEditorByPartIdQuery(id, { skip: !id });

  const isLoading = answersLoading || commentsLoading || studentsLoading || courseFetching;

  const onClick = (arg: 'Answers' | 'Comments' | '') => {
    setTab && setTab(arg);
  };

  const handleRefetch = () => {
    close();
    refetchCourse();
  };

  return (
    <>
      {!!courseId && (
        <S.Top>
          {isLoading ? (
            <>
              <ResizebleSkeleton $radius={8} $width={'36px'} $height={'36px'} />
              <ResizebleSkeleton $radius={8} $width={'250px'} $height={'30px'} />
            </>
          ) : (
            <>
              <S.CourseImg src={STATIC_HOST_PATH + course?.imageCover} />
              {course?.name}
            </>
          )}
        </S.Top>
      )}
      <AnimatePresence>
        {!!slideId && (
          <S.SlideInfo
            initial={{ opacity: 0, margin: '0px', padding: '0px', height: 0 }}
            animate={{ opacity: 1, margin: '20px 0px 0px', padding: '10px', height: '36px' }}
            exit={{ opacity: -1, margin: '0px', padding: '0px', height: 0 }}
          >
            Info for selected slide:
          </S.SlideInfo>
        )}
      </AnimatePresence>
      <S.Container>
        <InfoBox icon={<StudentsIcon />} title={'Students'} number={studentsCount} isLoading={isLoading} />
        <InfoBox
          icon={<CommentsIcon />}
          title={'Comments'}
          number={commentsCount}
          onClick={() => onClick('Comments')}
          isLoading={isLoading}
          requiresCheck={!!commentsCount}
        />
        <InfoBox
          icon={<AnswersIcon />}
          title={'Answers'}
          number={answersCount}
          onClick={() => onClick('Answers')}
          isLoading={isLoading}
          requiresCheck={!!answersCount}
        />
        <Block>
          <VisibilityChanger />
          <DashboardButton onClick={open} label={'Course Settings'} isLoading={isLoading} icon={<SettingsIcon />} />
        </Block>
      </S.Container>

      <S.Bottom $theme={theme}>Course Structure</S.Bottom>
      {!!course && <CourseForm onSubmitHandler={handleRefetch} open={isOpen} editCourseData={course} onClose={close} />}
    </>
  );
};

export default CourseDashboard;
