import { RefObject, useEffect, useState } from 'react';

type TUseDrawer = {
  $bodyRef: RefObject<any>;
  $portalRef: RefObject<any>;
  isVisible?: boolean;
  onClose?: () => void;
};

export const useDrawer = ({ isVisible, $portalRef, $bodyRef, onClose }: TUseDrawer) => {
  const [isOpen, setIsOpen] = useState(isVisible);

  useEffect(() => setIsOpen(isVisible), [isVisible]);

  useEffect(() => {
    $bodyRef.current.appendChild($portalRef.current);

    const bodyEl = $bodyRef.current;

    return () => {
      // Ensure scroll overflow is removed
      bodyEl.style.overflow = '';
    };
  }, []);

  useEffect(() => {
    const updatePageScroll = () => {
      if (isOpen) {
        $bodyRef.current.style.overflow = 'hidden';
      } else {
        $bodyRef.current.style.overflow = '';
      }
    };
    updatePageScroll();
  }, [isOpen]);

  function handleClose() {
    if (onClose) {
      onClose();
    }
    return setIsOpen(false);
  }

  return {
    isOpen,
    handleClose,
    setIsOpen,
  };
};
