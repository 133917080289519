import { FC, Fragment } from 'react';
import { TTextToSpeech } from '../model';
import { PlayButton } from 'shared/ui';
import { useTextToSpeech } from '../utils/useTextToSpeech';
import { DisableInSafari } from '../../DisableInSafari';

export const TextToSpeech: FC<TTextToSpeech> = ({ className = '', variant = 'default' }) => {
  const { onClick, isPlaying } = useTextToSpeech();

  return (
    <Fragment>
      <DisableInSafari>
        <PlayButton className={className} playing={isPlaying} onClick={onClick} variant={variant} />
      </DisableInSafari>
    </Fragment>
  );
};
