import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as FrameSvg } from '../../assets/eye-drop.svg';

const ACTIVE_COLOR = '#6481F9';
const INACTIVE_COLOR = '#282F47';
const INACTIVE_COLOR_LIGHT = '#E4E9F9';
const INACTIVE_W_OPACITY = '#627EEA40';

export const Wrapper = styled.div<{ $noPadding?: boolean }>`
  ${(p) => !p.$noPadding && 'padding-top: 70px;'}
  width: 100%;
  overflow: hidden;
  position: relative;
  mask-type: aplha;
  mask-image: linear-gradient(90deg, transparent 0%, #000 3%, #000 97%, transparent 100%);
  @media screen and (max-width: 992px) {
    overflow-x: auto;
    margin-bottom: 32px;
    padding-left: 16px;
    margin-left: -16px;
    min-width: calc(100% + 32px);
    scrollbar-width: none;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const Track = styled(motion.div)`
  width: fit-content;
  position: relative;
  min-width: 100%;
  display: flex;

  @media screen and (max-width: 992px) {
    padding-right: 32px;
  }
`;

export const Line = styled.div<{ $theme?: THEMES }>`
  position: absolute;
  left: 0;
  top: 23.5px;
  transform: translateY(-50%);
  height: 2px;
  width: 100%;
  border-radius: 2px;
  background: ${(p) => (p.$theme === THEMES.DARK ? INACTIVE_W_OPACITY : INACTIVE_COLOR_LIGHT)};
  z-index: -1;
`;

export const Progress = styled(Line)<{ $width: number }>`
  width: ${(p) => p.$width}px;
  background: ${ACTIVE_COLOR};
`;

export const Item = styled.div<{ $marginRight?: number }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 107px;
  min-width: 107px;
  gap: 10px;
  &:not(:last-child) {
    margin-right: ${(p) => p.$marginRight}px;
  }
`;

export const RefsNum = styled.div<{ $active?: boolean; $theme: THEMES }>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 47px;
  padding: 0px 7px;
  height: 47px;
  background: ${(p) => (p.$active ? ACTIVE_COLOR : p.$theme === THEMES.LIGHT ? INACTIVE_COLOR_LIGHT : INACTIVE_COLOR)};
  color: white;
  border-radius: 47px;
  color: ${(p) => (p.$theme === THEMES.DARK || p.$active ? '#fff' : '#627EEA')};
  font-size: 16px;
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const Reward = styled.div<{ $active?: boolean; $theme: THEMES }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  text-align: center;
  font-size: 16px;
  line-height: 100%;
  font-weight: 400;
  & > span {
    text-align: center;
    min-width: 110px;
    color: ${(p) => (p.$active ? '#637FFA' : p.$theme === THEMES.DARK ? '#ffffff66' : '#111')};
  }
`;

export const ChestBlock = styled.div<{ $active?: boolean; $smaller?: boolean }>`
  width: 80px;
  height: 80px;
  ${(p) => p.$smaller && 'width: 57px; height: 57px;'}
  border-radius: 50%;
  border: 3px solid ${(p) => (p.$active ? '#627EEA' : 'transparent')};
  background: #627eea26;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ChestImg = styled.img`
  width: auto;
  height: 108%;
  &.first {
    height: 90%;
    object-fit: contain;
  }
`;

export const User = styled.div<{ $hovered?: boolean; $left: number }>`
  left: ${(p) => p.$left}px;
  transform: translateX(-50%);
  position: absolute;
  height: 35px;
  top: 6px;
  transition: linear 0.3s;
`;

export const UserBlock = styled.div<{ $theme: THEMES; $hidden?: boolean; $hovered?: boolean; $translateX: number | string }>`
  background: ${(p) => (p.$hovered ? ACTIVE_COLOR : p.$theme === THEMES.LIGHT ? INACTIVE_COLOR_LIGHT : INACTIVE_COLOR)};
  border-radius: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  padding: 0px 10px;
  color: ${(p) => (p.$theme === THEMES.DARK || p.$hovered ? '#fff' : '#627EEA')};
  ${(p) => p.$hidden && 'opacity: 0; pointer-events: none;'}
  transform: translateX(${(p) => p.$translateX}px);
  transition: all ease 0.3s, transform linear 0.3s;
  & > img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    margin-left: 5px;
    margin-right: 3px;
  }
  & > svg {
    width: 16px;
    height: 16px;
  }
`;

export const UserFloat = styled(motion.div)<{ $active?: boolean; $theme: THEMES }>`
  width: 60px;
  height: 70px;
  padding: 5px 5px 15px;
  color: ${(p) => (p.$active ? ACTIVE_COLOR : p.$theme === THEMES.LIGHT ? INACTIVE_W_OPACITY : 'white')};
  position: absolute;
  bottom: calc(100% + 7px);
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.3s;
`;

export const UserFrame = styled(FrameSvg)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

export const Button = styled.div<{ $hide?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  cursor: pointer;
  transition: 0.3s;
  & > svg {
    height: 22px;
    width: 22px;
  }
  &:hover {
    opacity: 0.8;
  }
  border-radius: 50%;
  ${(p) => p.$hide && 'opacity: 0; pointer-events: none;'}
`;

export const Buttons = styled.div<{ $theme: THEMES }>`
  position: absolute;
  top: calc(70px + (100% - 70px) / 2);
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 56px * 2);
  color: ${(p) => (p.$theme === THEMES.DARK ? 'white' : '#627EEA')};
  display: flex;
  justify-content: space-between;
  align-items: center;
  & > ${Button} {
    background: ${(p) => (p.$theme === THEMES.LIGHT ? '#6481F926' : '#6481F926')};
  }
`;

export const Tooltip = styled(motion.div)<{ $theme: THEMES; $hidden?: boolean; $right?: boolean }>`
  width: 250px;
  overflow: hidden;
  height: fit-content;
  max-height: fit-content;
  border-radius: 4px;
  background: ${(p) => (p.$theme === THEMES.DARK ? '#FFFFFF0D' : '#627EEA26')};
  color: ${(p) => (p.$theme === THEMES.DARK ? '#fff' : '#111')};
  position: absolute;
  left: calc(100% + 5px);
  top: 0;
  padding: 14px 16px 14px;
  line-height: 21.12px;
  /* display: flex; */
  /* align-items: center; */
  font-weight: 400;
  font-size: 16px;
  transition: 0.3s;
  backdrop-filter: blur(10px);
  ${(p) => p.$hidden && 'opacity: 0; pointer-events: none; top: 30px;'}
  ${(p) => p.$right && 'left: auto; right: calc(100% + 5px)'}
    & .refs-logo {
    transform: translateY(3px);
    margin-top: -3px;
    margin-bottom: -4px;
  }
`;
