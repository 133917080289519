import { useEffect, useRef, useState } from 'react';
import { TButtonsProps } from './types';
import { useAttachAuthMethodMutation, useLazyGetMyPartsQuery } from 'store';
import PreaparedButton from './styledButton';
import { useLinkModalContext } from '../context';
import { EAuthMethods } from 'interface';
import style from './style.module.scss';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';

const GoogleLinkButton = ({ title, handleClose }: TButtonsProps) => {
  const [isSdkReady, setSdkReady] = useState(false);
  const signInButtonRef = useRef<HTMLDivElement>(null);
  const [attach] = useAttachAuthMethodMutation();
  const [refetchUser] = useLazyGetMyPartsQuery();
  const { setStage, stage } = useLinkModalContext();
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://accounts.google.com/gsi/client';
    script.async = true;
    script.defer = true;
    script.onload = () => setSdkReady(true);
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (isSdkReady && window.google) {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: handleCredentialResponse,
        prompt: 'select_account',
      });

      window.google.accounts.id.renderButton(signInButtonRef.current, { theme: 'outline', size: 'xxl', width: 200, 'max-width': 1000, height: 56 });
    }
  }, [isSdkReady]);

  const handleCredentialResponse = async (response: any) => {
    setStage('progress');
    attach({ type: EAuthMethods.Google, data: { token: response.credential } })
      .unwrap()
      .then(() => {
        refetchUser({ props: ['authMethods'] })
          .then(() => setStage('success'))
          .catch(console.error);
      })
      .catch((err) => {
        console.error(err);
        if (err?.data?.message) {
          toast.error(<Answer label={'Oops!'} subtext={err?.data?.message} type="incorrect" />);
          setStage('meaningfull-error');
          return;
        }
        toast.error(<Answer label="Oops!" subtext={'Please try again in a few minutes. If issue persists, contact support'} type="incorrect" />);
        setStage('error');
      });
  };

  return (
    <div className={style.wrapper}>
      {stage !== 'success' && <div className={style.google} ref={signInButtonRef}></div>}
      <PreaparedButton onClick={stage === 'success' ? handleClose : () => {}} title={title} />
    </div>
  );
};

export default GoogleLinkButton;
