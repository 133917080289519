import { useDisplay, useGetCurrentTheme, useGetUserData } from 'hooks';
import { ROUTES } from 'app/constants';
import { useNavigate } from 'react-router-dom';

import { Modal } from 'shared/ui';
import { IOnetimerComponent } from 'shared/ui/onetime-notifications/types';
import ChestImageAnimated from '../screen/ChestAnimated';
import { BackButton } from 'components/library/BackButton';
import BasicButton from 'shared/ui/basic-button/button';

import chestImg from '../assets/chest-image-small.png';

import modalStyles from './modal.module.scss';
import * as S from './styles';

type RefsProps = {
  handleClose: () => void;
};

const ReferralsModalBox = ({ handleClose }: RefsProps) => {
  const {
    referrals: { referralBonus },
  } = useGetUserData();
  const { isMobile } = useDisplay();
  const theme = useGetCurrentTheme();
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(ROUTES.REFERRALS);
    handleClose();
  };

  return (
    <S.Container>
      <ChestImageAnimated size={isMobile ? 265 : 400} containerClassName="chest" smallerGlow />
      <S.Title $theme={theme}>
        You invited {referralBonus} <br /> new friends!
      </S.Title>
      <S.RewsBox $theme={theme}>
        <img src={chestImg} alt="chest" /> <span>{referralBonus} chests</span> {/* <USDCReward /> */}
      </S.RewsBox>
      <BasicButton label={'View progress'} fullWidth={false} variant="cool" style={{ height: 66 }} onClick={handleNavigate} />
    </S.Container>
  );
};

interface Props extends IOnetimerComponent {
  isOpen?: boolean;
}

const ReferralsModal = ({ onClose, isOpen = true }: Props) => {
  const { isMobile } = useDisplay();
  const theme = useGetCurrentTheme();

  return (
    <Modal
      closeButtonLocation="outside"
      closeButtonVariant={isMobile ? 'none' : 'filled'}
      className={`${modalStyles.modal} ${modalStyles[theme]}`}
      containerClassName={modalStyles.container}
      containerChildren={isMobile ? <BackButton text="" className={modalStyles.back} /> : <></>}
      open={isOpen}
      onClose={onClose}
    >
      <ReferralsModalBox handleClose={onClose} />
    </Modal>
  );
};

export default ReferralsModal;
