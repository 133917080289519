import plusIcon from '../../assets/plus-darker.svg';
import styled from 'styled-components';
import { SliderButton } from '../../spaces.styles';
import { THEMES } from 'app/constants';

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 55px;
  @media screen and (max-width: 992px) {
    margin-bottom: 0px;
  }
`;
export const Title = styled.p<{ $theme: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.DARK ? '#fdfdfd' : 'rgb(98, 126, 234)')};
  font-size: 26px;
  font-weight: 500;
`;

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

const colors = {
  [THEMES.DARK]: {
    normal: '',
    darker: '',
  },
  [THEMES.LIGHT]: {
    normal: '#1C1D26',
    darker: '#1C1D2666',
  },
};

export const Questbox = styled.div<{ $darker?: boolean; $theme: THEMES; $hover?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  gap: 6px;
  font-size: 16px;
  color: ${(p) => (p.$darker ? colors[p.$theme].darker : colors[p.$theme].normal)};
  cursor: pointer;
  width: 100px;
  transition: 0.3s;
  ${(p) => p.$hover && 'margin-right: 50px;'}
`;

export const QuestTools = styled.div<{ visible?: boolean }>`
  display: flex;
  top: 0;
  left: 0;
  position: absolute;
  gap: 10px;
  background: #00000088;
  color: white;
  padding: 5px;
  border-radius: 5px;
  transition: 0.3s;
  opacity: 0;
  pointer-events: all;
  z-index: 5;
  ${(p) => p.visible && 'opacity: 1; pointer-events: all;'}
  & > div {
    height: 20px;
    width: auto;
  }
`;

export const QuestTool = styled.div`
  cursor: pointer;
  display: flex;
  & > svg {
    height: 100%;
    width: auto;
  }
`;

export const AddQuestImage = styled.div<{ $theme: THEMES }>`
  width: 100px;
  height: 100px;
  min-height: 100px;
  border-radius: 50%;
  background-color: ${(p) => (p.$theme === THEMES.DARK ? '#ffffff0f' : '#1111110f')};
  background-image: url(${plusIcon});
  background-size: 50% 50%;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.85;
  }
`;

export const QuestTop = styled.div<{ bg?: string; $finished?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(p) => (p.$finished ? '0px' : '3px')};
  width: 100px;
  height: 100px;
  min-height: 100px;
  border-radius: 50%;
  position: relative;
  & .quest-circle {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const QuestIconFrame = styled.div<{ bg?: string; $finished?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  ${(p) => !p.$finished && 'border: 4px solid;'}
  border-color: #2A2B3366;
  &.light {
    border-color: #ffffff;
  }
  border-radius: 50%;
  overflow: hidden;
  & > * {
    width: 46px;
    height: auto;
  }
  &::after {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0%;
    left: 0%;
    border-radius: 50%;
    opacity: 0.4;
    background: ${(p) => p.bg || 'none'};
    content: '';
    display: block;
    z-index: -1;
  }
`;

export const QuestSliderButton = styled(SliderButton)`
  ${(p) => (p.$next ? 'right: calc(-49px - 17px);' : 'left: calc(-49px - 17px);')}
  position: absolute;
  top: 29px;
  &:hover {
    transform: scale(1.1);
  }
`;

export const RightTopIcon = styled.img`
  width: 60px;
  height: 60px;
  top: 0;
  right: 0;
  position: absolute;
  pointer-events: none;
  object-fit: contain;
  z-index: 1;
  transform: translate(15px, -15px);
`;

export const ModalBox = styled.div`
  padding: 48px;
  width: 596px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  & > form {
    margin-bottom: 0;
    height: 100%;
  }
  @media screen and (max-width: 992px) {
    padding: 20px;
    width: 100%;
  }
`;

export const ModalTitle = styled.p<{ $theme: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.DARK ? 'white' : '#111')};
  font-size: 20px;
`;

export const ModalButtons = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  margin-top: auto;
  justify-content: center;
`;

export const ScrollList = styled.div`
  display: flex;
  gap: 16px;
  overflow: auto scroll;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  padding: 30px 19px 60px;
  margin: -30px -19px 0px;
`;
