import { FC, HTMLAttributes } from 'react';

import * as Styles from './styles';
import { AngleLeftIcon } from 'assets/icons/icons';
import clsx from 'clsx';

type TBackButtonProps = HTMLAttributes<HTMLButtonElement> & {
  text?: string;
};

export const BackButton: FC<TBackButtonProps> = ({ text, className, ...others }) => {
  return (
    <Styles.SButton {...others} className={clsx(className)}>
      <AngleLeftIcon />
      {text ?? 'Back'}
    </Styles.SButton>
  );
};
