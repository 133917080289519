import { HTMLAttributes, RefObject, useRef } from 'react';
import ReactDom from 'react-dom';
import styles from './styles.module.scss';
import { ReactComponent as CrossIcon } from 'assets/components/cross.svg';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useAddBluredBg } from 'hooks';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  className?: string;
  containerClassName?: string;
  isClosable?: boolean;
  innerRef?: RefObject<any>;
  isBlurBg?: boolean;
  modalBlurAmountPx?: number;
  isBg?: boolean;
  closeButtonLocation?: 'inside' | 'outside';
  closeButtonVariant?: 'transparent' | 'filled' | 'none';
  closeButtonForm?: 'round' | 'square';
  closeButtonClassName?: string;
  containerChildren?: React.ReactNode;
  specificPortalId?: string;
}

export const Modal = ({
  open,
  children,
  onClose,
  className = '',
  containerClassName = '',
  isClosable = true,
  closeButtonLocation = 'inside',
  closeButtonVariant = 'transparent',
  closeButtonForm = 'square',
  closeButtonClassName = '',
  innerRef,
  isBlurBg,
  modalBlurAmountPx = 16,
  isBg = true,
  containerChildren,
  specificPortalId,
}: IProps) => {
  const theme = useSelector(getCurrentTheme);
  const modalRef = useRef<HTMLDivElement>(null);
  const bluredBg = useAddBluredBg({ zIndex: 1021, targetRef: modalRef, blurAmount: modalBlurAmountPx });

  if (!open) {
    return null;
  }

  return ReactDom.createPortal(
    <>
      <div ref={innerRef} onClick={onClose} className={clsx(styles.container, { [containerClassName]: !!containerClassName })}>
        {!!containerChildren && containerChildren}
        <div
          ref={modalRef}
          onClick={(e) => e.stopPropagation()}
          className={clsx(styles.modal, { [`glass-div basic ${theme}`]: !isBlurBg && isBg, [className]: !!className })}
        >
          {isClosable && (
            <div
              onClick={onClose}
              className={clsx(styles.crossIcon, styles[closeButtonLocation], styles[closeButtonVariant], styles[closeButtonForm], {
                [closeButtonClassName]: !!closeButtonClassName,
              })}
            >
              <CrossIcon />
            </div>
          )}
          {children}
        </div>
      </div>
      {isBlurBg && bluredBg}
    </>,
    document.getElementById(specificPortalId ?? 'portal')!
  );
};
