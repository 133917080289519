import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-height: 405px;
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: fit-content;
  max-height: inherit;
  overflow-y: auto;
  padding-right: 30px;
  margin-right: -30px;
  box-sizing: content-box;
`;

export const Header = styled.div<{ $theme?: THEMES }>`
  display: flex;
  padding-bottom: 7px;
  width: 100%;
  border-bottom: 1px solid ${(p) => (p.$theme === THEMES.LIGHT ? '#11111126' : '#FFFFFF26')};
`;

export const Heading = styled.p<{ $theme?: THEMES }>`
  opacity: 0.4;
  font-size: 16px;
  font-weight: 400;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#fff')};
`;
