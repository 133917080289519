import { FC } from 'react';

import styles from './styles.module.css';
import { parseIfJson } from '../../../../../../../../utils/object';
import { TFileMetadata } from '../types';
import { AttachmentIcon } from '../../../../../../../../assets/icons/icons';
import { buildStaticUrl } from '../../utils/media-helpers';

type TFileProps = {
  meta: string;
};

const File: FC<TFileProps> = ({ meta }) => {
  const content = parseIfJson<TFileMetadata>(meta) || meta;

  return (
    <a className={styles.root} href={buildStaticUrl(content)} target="_blank">
      <AttachmentIcon className={styles.icon} />
      <span>{typeof content !== 'string' ? content.name : content}</span>
    </a>
  );
};

export default File;
