import { MobileModal } from 'shared/utils/mobile';
import { ListOfCodes } from '../../listCodes';
interface IProps {
  isOpen: boolean;
  onClose: () => void;
}
export const ModalActivatedCodes = ({ isOpen, onClose }: IProps) => {
  return (
    <MobileModal isOpen={isOpen} onClose={onClose}>
      <ListOfCodes />
    </MobileModal>
  );
};
