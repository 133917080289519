import SlideOne from './slides/slide1/slide1';
import { InView } from 'shared/ui/motion';
import styles from './styles.module.css';

export default function InventoryInfo() {
  return (
    <InView forcedOnMobile className={styles.container}>
      {/* {isMobile ? (
        <div className={styles.content}>
          <SlideOne />
          <SlideOne />
        </div>
      ) : (
        <PanelsCarousel width="100%" height="360px" variant="blue" slides={[<SlideOne />, <SlideOne />, <SlideOne />]} />
      )} */}
      <SlideOne />
    </InView>
  );
}
