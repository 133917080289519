import { ChangeEvent, FC, useRef } from 'react';
import { FaUpload } from 'react-icons/fa';
import { BasicButtonV1, Modal } from 'shared/ui';

import * as Elements from './styles';

type TUploadModalProps = {
  open: boolean;
  onUpload: (files: FileList | null) => void;
  onClose: () => void;
  type: 'image' | 'file';
};

function getTitleByType(type: TUploadModalProps['type']) {
  if (type === 'file') {
    return 'File';
  }

  if (type === 'image') {
    return 'Image';
  }
}

const UploadModal: FC<TUploadModalProps> = ({ open, onUpload, onClose, type }) => {
  const $fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    $fileInputRef.current?.click();
  };

  const handleFileInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    onUpload(evt.target.files);
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Elements.SContainer>
        <form>
          <input ref={$fileInputRef} accept="image/*" type="file" hidden id="uploadModalFileInput" onChange={handleFileInputChange} />
          <BasicButtonV1 type="button" onClick={handleUploadButtonClick}>
            Select {getTitleByType(type)}&nbsp;
            <FaUpload />
          </BasicButtonV1>
        </form>
      </Elements.SContainer>
    </Modal>
  );
};

export default UploadModal;
