import styled from 'styled-components';
import arrow from './assets/chevron-left.svg';
import arrowRight from './assets/chevron-right.svg';
import arrowBlue from './assets/arr-left-blue.svg';
import arrowBlueRight from './assets/arr-right-blue.svg';
import bg from './assets/mobile-bg.webp';
import bgLight from './assets/mobile-bg-light.webp';
import { THEMES } from 'app/constants';
import { SPACE_COLORS } from './spaceColors';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1015px;
  min-height: 100vh;
  z-index: 1;
  transition: transform 0.3s ease-in-out;
  position: relative;
  @media screen and (min-width: 1620px) {
    transform: translateX(calc(50vw - (1015px / 2) - 206px));
  }
  @media screen and (max-width: 992px) {
    padding: 59px 19px 19px;
    width: 100%;
    min-height: auto;
    max-width: 100vw;
    transform: none;
    padding-bottom: 100px;
    margin-top: -31px;
  }
`;

export const PageBg = styled.div<{ $theme: THEMES }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 460px;
  background-image: url(${(p) => (p.$theme === THEMES.DARK ? bg : bgLight)});
  background-repeat: no-repeat;
  background-position: center 50%;
  background-size: cover;
  z-index: 0;

  @media screen and (max-width: 992px) {
    top: -30px;
  }
`;

export const Section = styled.div<{ $noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  margin-bottom: ${(p) => (p.$noMargin ? '0' : '48px')};
  @media screen and (max-width: 992px) {
    width: 100%;
    max-width: 100%;
  }
`;

export const TitleBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  z-index: 1;
  margin-bottom: 48px;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
  }
`;

export const TitleLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  @media screen and (max-width: 992px) {
    text-align: center;
    align-items: center;
    gap: 0px;
  }
`;
export const TitleBottom = styled.span<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  color: ${(p) => SPACE_COLORS[p.$theme].title};
  gap: 5px;
  & > div {
    margin-right: -3px;
  }
  @media screen and (max-width: 992px) {
    text-align: center;
    font-size: 26px;
  }
`;

export const Title = styled.h1<{ $theme: THEMES }>`
  font-size: 35px;
  line-height: 39.9px;
  font-weight: 500;
  color: ${(p) => SPACE_COLORS[p.$theme].title};
  margin: 0;
  @media screen and (max-width: 992px) {
    font-size: 26px;
    margin-bottom: 14px;
    line-height: 100%;
  }
`;

export const TitleDesc = styled.p<{ $theme: THEMES }>`
  font-size: 16px;
  font-weight: 400;
  line-height: 18.88px;
  color: ${(p) => SPACE_COLORS[p.$theme].subtitles};
  @media screen and (max-width: 992px) {
    text-align: center;
    max-width: 270px;
    margin-bottom: 24px;
  }
`;

export const Subtitle = styled(Title)`
  font-size: 26px;
  margin-bottom: 24px;
  @media screen and (max-width: 992px) {
    margin-bottom: 20px;
  }
`;

export const CreateButton = styled.button<{ $theme: THEMES }>`
  position: relative;
  font-size: 20px;
  color: white;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  z-index: 0;
  border: none;
  outline: none;
  border-radius: 300px;
  padding: 14px 30px;
  ${(p) =>
    p.$theme === THEMES.DARK
      ? ` background: linear-gradient(90deg, #6487ef 0%, #53259b 125.56%);
    &::after {
      background: #212433;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      top: 2px;
      left: 2px;
      border-radius: 300px;
      position: absolute;
      content: '';
      display: block;
      z-index: -1;
    }`
      : `background: linear-gradient(90deg, #6487EF 0%, #53259B 125.56%), linear-gradient(281.94deg, rgba(255, 255, 255, 0.21) -0.2%, rgba(255, 255, 255, 0.03) 98.72%);  `}

  @media screen and (max-width: 992px) {
    font-size: 18px;
    & > svg {
      width: 33px;
      height: auto;
    }
  }
`;
export const Grid = styled.div<{ $moreCols?: boolean }>`
  display: grid;
  grid-template-columns: ${(p) => (p.$moreCols ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr')};
  gap: 16px;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 8px;
  }
`;

export const SliderButtons = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-end;
  gap: 6px;
`;

const arrows = {
  [THEMES.DARK]: {
    left: arrow,
    right: arrowRight,
  },
  [THEMES.LIGHT]: {
    left: arrowBlue,
    right: arrowBlueRight,
  },
};

export const SliderButton = styled.div<{ $next?: boolean; disabled?: boolean; hidden?: boolean; $theme: THEMES }>`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-image: url(${(p) => (p.$next ? arrows[p.$theme].right : arrows[p.$theme].left)});
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-color: ${(p) => (p.$theme === THEMES.DARK ? '#ffffff0a' : '#627EEA1A')};
  &:hover {
    filter: brightness(1.1);
    transform: scale(1.1);
  }
  ${(p) => p.disabled && 'opacity: .4; pointer-events: none;'}
  transition: all .3s;

  cursor: pointer;
  ${(p) => p.hidden && 'display: none;'}
`;
