import { GRADES } from 'app/constants';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { getIsAuth } from 'store';

import CountDown from '../count';

import styles from './styles.module.css';

interface IUserDaily {
  _id: string;
  collectedAt: Date;
}

interface Props {
  lastUserCollectedReward?: IUserDaily;
  isRewardable?: boolean;
  quantity?: number;
  name?: string;
  grade?: GRADES;
  isSubtitle?: boolean;
}

function getTimeRemainingUntilNextReward() {
  // Convert the current time to CET
  const currentDate: Date = new Date();
  const CETOffset: number = 1; // CET is UTC+1, adjust this value for dailyght saving time if needed
  const currentTimeCET: Date = new Date(currentDate.getTime() + CETOffset * 60 * 60 * 1000);

  // Calculate the next 6 AM CET time
  const nextRewardCET: Date = new Date(currentTimeCET.getFullYear(), currentTimeCET.getMonth(), currentTimeCET.getDate(), 6, 0, 0);

  if (currentTimeCET.getHours() >= 6) {
    // If it's past 6 AM CET, move the nextRewardCET to the next day
    nextRewardCET.setDate(nextRewardCET.getDate() + 1);
  }

  // Calculate time remaining until the next reward window
  const timeRemaining = nextRewardCET?.getTime() - currentTimeCET?.getTime();

  return timeRemaining;
}

export default function Header({ isRewardable, quantity, name, grade, isSubtitle = true, lastUserCollectedReward }: Props) {
  const theme = useSelector(getCurrentTheme);
  const isAuth = useSelector(getIsAuth);

  if (!isAuth) {
    return (
      <h4 className={styles.header}>
        <span className={styles.title}>Collect your rewards every day</span>
      </h4>
    );
  }

  const color = `var(--grade-${theme}-color-${grade})`;

  const title = isRewardable ? (
    <span className={styles.title}>Collect your rewards every day</span>
  ) : (
    <span className={styles.title}>Congratulations!</span>
  );
  const subTitle = isRewardable ? (
    <span>You can claim a new reward every day after 6:00 CET</span>
  ) : (
    <span>
      Your reward is{' '}
      <span style={{ color }}>
        {quantity} {name}
      </span>
      . Come back in <CountDown className={styles.countDown} from={getTimeRemainingUntilNextReward()} /> to continue your streak.
    </span>
  );

  return (
    <>
      <h4 className={styles.header}>{title}</h4>
      {isSubtitle && <p className={styles.text}>{subTitle}</p>}
    </>
  );
}
