import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import { Flex, NullButton } from 'shared/ui';
import styled from 'styled-components';

const BG_BY_TYPE = {
  facebook: 'linear-gradient(90deg, #1877F2 0%, #6CACFF 143.81%)',
  instagram: 'linear-gradient(90deg, #E226A8 0%, #7F0CFD 149.74%)',
  telegram: 'linear-gradient(90deg, #29A9EB 0%, #2180B1 125.56%)',
  x: 'linear-gradient(90deg, #1D9BF0 0%, #53259B 170.1%)',
  discord: 'linear-gradient(90deg, #5865F2 0%, #53259B 158.76%)',
};

export const Container = styled(motion.div)<{ $theme: THEMES; $biggerPadding?: boolean }>`
  border-radius: 40px 40px 0px 0px;
  padding: 36px 20px calc(100vh + ${({ $biggerPadding }) => ($biggerPadding ? '106px' : '36px')});
  background: ${({ $theme }) => ($theme === THEMES.LIGHT ? '#ffffff' : '#1C1D26')};
  display: flex;
  flex-direction: column;
  gap: 36px;
  width: 100%;
  height: fit-content;
  position: fixed;
  bottom: -100vh; // to have some additional bg to hide borders
  left: 0;
  z-index: 2000;
`;

export const Block = styled(Flex)`
  flex-direction: column;
  width: 100%;
`;

export const Title = styled.p`
  color: var(--font-color-strong);
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 12px;
  line-height: 20px;
`;

export const Subtitle = styled.p`
  color: var(--font-color);
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  margin-bottom: 20px;
`;

export const Button = styled(NullButton)<{ $type: 'facebook' | 'instagram' | 'telegram' | 'x' | 'discord' }>`
  width: 100%;
  height: 44px;
  font-size: 16px;
  font-weight: 500;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 4px;
  background: ${({ $type }) => BG_BY_TYPE[$type]};
`;
