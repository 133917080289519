import { FC, PropsWithChildren } from 'react';
import { SWrapper } from './styles';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

interface ISettingsCardWrapperProps extends PropsWithChildren {
  className?: string;
  onClick?: () => void;
}

export const SettingsCardWrapper: FC<ISettingsCardWrapperProps> = ({ children, className = '', onClick }) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <SWrapper className={className} onClick={onClick} $theme={theme}>
      {children}
    </SWrapper>
  );
};
