import { BubbleMenu, Editor } from '@tiptap/react';
import { FC, memo, useCallback, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';
import ColorsMenu from './components/ColorsMenu';

import { ReactComponent as BoldIcon } from './assets/bold.svg';
import { ReactComponent as ItalicIcon } from './assets/italic.svg';
import { ReactComponent as UnderlineIcon } from './assets/underline.svg';
import { ReactComponent as CodeIcon } from './assets/code.svg';
import { ReactComponent as LinkIcon } from './assets/link.svg';
import { ReactComponent as ColorIcon } from './assets/color.svg';
import { ReactComponent as H1Icon } from './assets/h1.svg';
import { ReactComponent as H2Icon } from './assets/h2.svg';
import { ReactComponent as H3Icon } from './assets/h3.svg';

import './styles.css';
import LinkFieldPopup from './components/LinkFieldPopup';

type TBubbleProps = {
  editor: Editor;
};

const Bubble: FC<TBubbleProps> = ({ editor }) => {
  const [isLinkFieldPopupOpened, setIsLinkFieldPopupOpened] = useState(false);
  const [isColorMenuOpened, setIsColorMenuOpened] = useState(false);
  const $bubbleRef = useRef<HTMLDivElement>(null);
  const theme = useTheme() as any;
  const colors = useMemo(() => {
    return [
      { color: '', title: 'Default' },
      { color: theme.theme.basicBlue, title: 'Blue' },
      { color: 'gray', title: 'Gray' },
      { color: theme.theme.basicYellow, title: 'Yellow' },
      { color: theme.theme.basicGreen, title: 'Green' },
      { color: theme.theme.basicPurple, title: 'Purple' },
      { color: theme.theme.basicRed, title: 'Red' },
      { color: theme.theme.basicCyan, title: 'Cyan' },
    ];
  }, [theme]);

  const handleColorMenuToggle = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      if (isColorMenuOpened) {
        setIsColorMenuOpened(false);
      } else {
        setIsColorMenuOpened(true);
      }
    },
    [isColorMenuOpened]
  );

  const handleColorMenuClose = useCallback(() => {
    setIsColorMenuOpened(false);
  }, []);

  const handleLinkFieldPopupToggle = useCallback(() => {
    if (editor.isActive('link')) {
      editor.chain().focus().unsetLink().run();
      return;
    }

    setIsLinkFieldPopupOpened((prev) => !prev);
  }, []);

  const handleLinkFieldPopupClose = useCallback(() => {
    setIsLinkFieldPopupOpened(false);
  }, []);

  return (
    <BubbleMenu editor={editor} tippyOptions={{ hideOnClick: true }}>
      <div className="tiptapEditor-bubble" ref={$bubbleRef}>
        <div className="tiptapEditor-buttons">
          <button data-active={editor.isActive('heading', { level: 1 })} onClick={() => editor.chain().toggleHeading({ level: 1 }).run()}>
            <H1Icon />
          </button>
          <button data-active={editor.isActive('heading', { level: 2 })} onClick={() => editor.chain().toggleHeading({ level: 2 }).run()}>
            <H2Icon />
          </button>
          <button data-active={editor.isActive('heading', { level: 3 })} onClick={() => editor.chain().toggleHeading({ level: 3 }).run()}>
            <H3Icon />
          </button>
          <button data-active={editor.isActive('bold')} onClick={() => editor.chain().toggleBold().run()}>
            <BoldIcon />
          </button>
          <button data-active={editor.isActive('italic')} onClick={() => editor.chain().toggleItalic().run()}>
            <ItalicIcon />
          </button>
          <button data-active={editor.isActive('underline')} onClick={() => editor.chain().toggleUnderline().run()}>
            <UnderlineIcon />
          </button>
          <button data-active={editor.isActive('code')} onClick={() => editor.chain().toggleCode().run()}>
            <CodeIcon />
          </button>
          <button data-active={editor.isActive('link')} onClick={handleLinkFieldPopupToggle}>
            <LinkIcon />
          </button>
          <button
            onClick={handleColorMenuToggle}
            data-active={isColorMenuOpened || colors.some((item) => editor.isActive('textStyle', { color: item.color }))}
          >
            <ColorIcon />
          </button>
        </div>
        {isLinkFieldPopupOpened && <LinkFieldPopup editor={editor} onClose={handleLinkFieldPopupClose} />}
        {isColorMenuOpened && (
          <ColorsMenu
            onClose={handleColorMenuClose}
            editor={editor}
            colors={[
              { color: '', title: 'Default' },
              { color: theme.theme.basicBlue, title: 'Blue' },
              { color: 'gray', title: 'Gray' },
              { color: theme.theme.basicYellow, title: 'Yellow' },
              { color: theme.theme.basicGreen, title: 'Green' },
              { color: theme.theme.basicPurple, title: 'Purple' },
              { color: theme.theme.basicRed, title: 'Red' },
              { color: theme.theme.basicCyan, title: 'Cyan' },
            ]}
          />
        )}
      </div>
    </BubbleMenu>
  );
};

export default memo(Bubble);
