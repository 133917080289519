import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import { BasicButtonV1 } from 'shared/ui';
import styled from 'styled-components';

export const Tab = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 9px;
`;

export const Image = styled.img`
  height: 147px;
  width: auto;
  object-fit: contain;
  margin-bottom: 16px;
`;

export const Title = styled.p<{ $theme?: THEMES }>`
  font-size: 26px;
  color: var(--font-color-strong);
  margin-bottom: 8px;
  text-align: center;
`;

export const Subtitle = styled.p<{ $theme?: THEMES }>`
  font-size: 16px;
  opacity: 0.7;
  color: var(--font-color-strong);
  margin-bottom: 32px;
  text-align: center;
  max-width: 420px;
`;

export const DiscordButton = styled(BasicButtonV1)`
  border-radius: 100px !important;
  width: 174px !important;
  background: linear-gradient(92.09deg, rgba(255, 255, 255, 0.14) 1.76%, rgba(255, 255, 255, 0.06) 109.28%) !important;
  color: var(--font-color-strong) !important;
  font-size: 20px !important;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
`;
