import * as S from './styles';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { ReactComponent as MessageIcon } from '../assets/message.svg';
import { useCallback, useMemo, useState } from 'react';
import { IComment, IRewardMinimal, getCurrentTheme, useRewardUserAndArchiveCommentMutation } from 'store';
import RewardsPicker from './RewardsPicker';
import { AnimatePresence } from 'framer-motion';
import { BasicButtonV1 } from 'shared/ui';
import { useSelector } from 'react-redux';
import { getFormatDate } from 'utils/date';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  comment: IComment;
  removeComment: (_id: string) => void;
};

const Comment = ({ comment, removeComment }: Props) => {
  const [rewards, setRewards] = useState<IRewardMinimal[]>([]);
  const [isHover, setHover] = useState(false);
  const navigate = useNavigate();

  const theme = useSelector(getCurrentTheme);
  const { spaceName } = useParams();
  const totalRewards = useMemo(() => rewards.reduce((acc, el) => (acc += el.quantity), 0), [rewards]);

  const [archive, { isLoading }] = useRewardUserAndArchiveCommentMutation();

  const goToSlide = () => {
    if (spaceName) {
      navigate(`/${spaceName}/lesson/${comment.lessonId}#${comment.slideId}`);
    } else {
      navigate(`/courses/edit/lessons/${comment.lessonId}#${comment.slideId}`);
    }
  };
  const handleRewardsChange = (id: number, quantity: number) => {
    setRewards((s) => {
      const restOfstate = s.filter((el) => el.id !== id);
      const item = { id, quantity };
      return [...restOfstate, item].sort((a, b) => a.id - b.id);
    });
  };

  const handleApprove = () => {
    archive({ commentId: comment._id, rewards: rewards.filter((el) => !!el.quantity), slideId: comment.slideId })
      .unwrap()
      .then(() => {
        console.log('Comment approved');
        setRewards([]);
        removeComment(comment._id);
      })
      .catch(console.error);
  };

  const handleDecline = () => {
    archive({ commentId: comment._id, slideId: comment.slideId })
      .unwrap()
      .then(() => {
        console.log('Comment declined');
        removeComment(comment._id);
      })
      .catch(console.error);
  };

  const { widgetId } = comment;

  const $widgetById = useMemo(() => {
    return document.querySelector(`[data-rbd-draggable-id="${widgetId}"]`);
  }, [widgetId]);

  const widgetHighlightingById = useCallback(() => {
    if (!$widgetById) return;

    // @ts-ignore
    $widgetById.classList.add('comment-hovered');
  }, [widgetId]);

  const widgetRemoveHighlighting = useCallback(() => {
    if (!$widgetById) return;

    $widgetById.classList.remove('comment-hovered');
  }, []);

  return (
    <S.Container $theme={theme} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <S.Path onClick={goToSlide} $theme={theme}>
        {comment.path}
      </S.Path>
      <S.Box $theme={theme}>
        <S.ProfileBox>
          <AvatarPic avatar={comment.user.avatar} nickname={comment.user.nickname} customization={''} />
          <S.ProfileInfo>
            <S.Nickname $theme={theme}>{comment.user.nickname}</S.Nickname>
            <S.Mail $theme={theme}>{comment.user.email}</S.Mail>
            <S.Date $theme={theme}>{getFormatDate(new Date(comment.comment.createdAt), true)}</S.Date>
          </S.ProfileInfo>
        </S.ProfileBox>
        <S.FlexColBox>
          <MessageIcon />
          <S.Content $theme={theme} isQuestion onMouseEnter={widgetHighlightingById} onMouseLeave={widgetRemoveHighlighting}>
            Selected Text: {comment.comment.selectedText}
          </S.Content>
        </S.FlexColBox>
        <S.FlexColBox nm>
          <S.Label $theme={theme}>Student's comment</S.Label>
          <S.Content $theme={theme}>{comment.comment.commentText}</S.Content>
        </S.FlexColBox>
        <AnimatePresence>
          {(isHover || isLoading) && (
            <S.Footer
              animate={{ height: 'auto', opacity: 1, marginTop: '20px' }}
              initial={{ height: 0, opacity: 0, marginTop: 0 }}
              exit={{ height: 0, opacity: -1, marginTop: 0 }}
            >
              <S.Label $theme={theme}>award designation</S.Label>
              <RewardsPicker rewards={rewards} handleRewardsChange={handleRewardsChange} />
              <S.Buttons>
                <BasicButtonV1
                  onClick={handleApprove}
                  disabled={!totalRewards}
                  isLoading={isLoading}
                  style={{ width: 'fit-content' }}
                  variant="solid"
                  size="sm"
                >
                  Approve & gift reward
                </BasicButtonV1>
                <BasicButtonV1 isLoading={isLoading} onClick={handleDecline} style={{ width: 'fit-content' }} variant="outline" size="sm">
                  Decline
                </BasicButtonV1>
              </S.Buttons>
            </S.Footer>
          )}
        </AnimatePresence>
      </S.Box>
    </S.Container>
  );
};

export default Comment;
