import { ESpecialIcons } from './NewCustomizationMenu/constants';
import { ETask, ETaskWidgetPreset, ITaskWidgets } from './types';

const getContent = (title: string) =>
  `{"type":"doc","content":[{"type":"paragraph","content":[{"type":"text","marks":[{"type":"bold"}],"text":"${title}"}]}]}`;

export const TaskWidgetPresets: ITaskWidgets = {
  [ETaskWidgetPreset.MAIL]: {
    title: 'Task',
    content: getContent('Send the Email'),
    icon: ESpecialIcons.MAIL,
    color: '#FDFDFD',
  },
  [ETaskWidgetPreset.WEBSITE]: {
    title: 'Task',
    content: getContent('Visit the website'),
    icon: ESpecialIcons.WEBSITE,
    color: '#FDFDFD',
  },
  [ETaskWidgetPreset.YT_WATCH]: {
    title: 'Task',
    content: getContent('Watch a video on YouTube'),
    icon: ESpecialIcons.YOUTUBE,
    color: '#FF0209',
  },
  [ETaskWidgetPreset.YT_VISIT]: {
    title: 'Task',
    content: getContent('Visit a channel on YouTube'),
    icon: ESpecialIcons.YOUTUBE,
    color: '#FF0209',
  },
  [ETaskWidgetPreset.FACEBOOK]: {
    title: 'Task',
    content: getContent('Visit a page on Facebook'),
    icon: ESpecialIcons.FACEBOOK,
    color: '#1877F2',
  },
  [ETaskWidgetPreset.X_FOLLOW]: {
    title: 'Task',
    content: getContent('Follow X'),
    icon: ESpecialIcons.TWITTER,
    color: '#1D9BF0',
  },
  [ETaskWidgetPreset.X_LIKE]: {
    title: 'Task',
    content: getContent('Like a Tweet'),
    icon: ESpecialIcons.TWITTER,
    color: '#1D9BF0',
  },
  [ETaskWidgetPreset.X_RETWEET]: {
    title: 'Task',
    content: getContent('Retweet a Tweet'),
    icon: ESpecialIcons.TWITTER,
    color: '#1D9BF0',
  },
  [ETaskWidgetPreset.DISCORD]: {
    title: 'Task',
    content: getContent('Join Discord'),
    icon: ESpecialIcons.DISCORD,
    color: '#5765F2',
  },
  [ETaskWidgetPreset.INSTAGRAM_VISIT]: {
    title: 'Task',
    content: getContent('Visit a profile'),
    icon: ESpecialIcons.INSTAGRAM,
    color: '#E226A8',
  },
  [ETaskWidgetPreset.INSTAGRAM_SAVE]: {
    title: 'Task',
    content: getContent('Save a profile'),
    icon: ESpecialIcons.INSTAGRAM,
    color: '#E226A8',
  },
  [ETaskWidgetPreset.INSTAGRAM_COMMENT]: {
    title: 'Task',
    content: getContent('Comment a profile'),
    icon: ESpecialIcons.INSTAGRAM,
    color: '#E226A8',
  },
  [ETaskWidgetPreset.INSTAGRAM_LIKE]: {
    title: 'Task',
    content: getContent('Like a profile'),
    icon: ESpecialIcons.INSTAGRAM,
    color: '#E226A8',
  },
  [ETaskWidgetPreset.TELEGRAM]: {
    title: 'Task',
    content: getContent('Join Telegram'),
    icon: ESpecialIcons.TELEGRAM,
    color: '#29A9EB',
  },
  // on-chain
  [ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_ETH]: {
    title: 'Task',
    content: getContent('Bridge yourself ETH tokens using bridge.ton.org'),
    icon: ESpecialIcons.TON,
    color: '#29A9EB',
    link: 'https://bridge.ton.org',
    taskType: ETask.TONBridgeETH,
  },
  [ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_BSC]: {
    title: 'Task',
    content: getContent('Bridge yourself BSC tokens using bridge.ton.org'),
    icon: ESpecialIcons.TON,
    color: '#29A9EB',
    link: 'https://bridge.ton.org',
    taskType: ETask.TONBridgeBSC,
  },
  [ETaskWidgetPreset.ONCHAIN_DEPOSIT]: {
    title: 'Task',
    content: getContent('Deposit tokens to your wallet'),
    icon: ESpecialIcons.TON,
    color: '#29A9EB',
    taskType: ETask.TONDeposit,
  },
};
