import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { FRIEND_STATUS } from 'app/constants';
import { IFriendsItem } from 'interface';

import AvatarPic from '../../../../library/utils/AvatarPic/avatar';
import UserContextMenu from '../../../../shared/UserContextMenu/user-context';
import { Modal } from 'shared/ui';

import { DotsVerticalIcon } from 'assets/icons/icons';

import styles from './socials.module.scss';

export type TSocials = {
  isShown: boolean;
  title: 'followings' | 'followers' | '';
  users: IFriendsItem[];
  status: FRIEND_STATUS;
};

interface Props {
  friends: IFriendsItem[];
}

export default function SocialsList({ friends }: Props) {
  const [socials, setSocials] = useState<TSocials>({ isShown: false, title: 'followers', users: friends, status: FRIEND_STATUS.REQUESTED });
  const { isShown, title, users, status } = socials;
  const $socialModal = useRef(null);

  const followers = friends.filter((i) => i.status === FRIEND_STATUS.REQUESTED).length;
  const following = friends.filter((i) => i.status === FRIEND_STATUS.PENDING).length;

  useEffect(() => {
    const users = friends.filter((friend) => friend.status === status);
    setSocials((prev) => ({
      ...prev,
      users,
    }));
  }, [friends, status]);

  const handleShow = (status: FRIEND_STATUS.PENDING | FRIEND_STATUS.REQUESTED) => () => {
    const users = friends.filter((friend) => friend.status === status);
    setSocials({
      isShown: true,
      status,
      title: status === FRIEND_STATUS.PENDING ? 'followings' : 'followers',
      users,
    });
  };

  const handleHide = () => {
    setSocials({
      isShown: false,
      status: 0,
      title: '',
      users: [],
    });
  };

  return (
    <>
      <div className={styles.follows}>
        <p className={styles.following} onClick={handleShow(FRIEND_STATUS.PENDING)}>
          <span>{following}</span> following
        </p>
        <p className={styles.followers} onClick={handleShow(FRIEND_STATUS.REQUESTED)}>
          <span>{followers}</span> followers
        </p>
      </div>
      <Modal innerRef={$socialModal} open={isShown} onClose={handleHide} closeButtonLocation="outside" className={styles.modal}>
        <div className={styles.socials}>
          <p>
            {users.length} {title}
          </p>
          <div className={clsx(styles.socialsItems, 'tiny-sb')}>
            {users.map(({ recipient: user }) =>
              user ? (
                <div key={user._id + String(Math.random())} className={styles.socialsItem}>
                  <div className={styles.user}>
                    <AvatarPic avatar={user?.avatar} nickname={user.nickname} smallGlow customization={user.customization} />
                    <p>{user.nickname}</p>
                  </div>
                  <UserContextMenu
                    toUnfollow={title === 'followings'}
                    isAddFriend={title === 'followers'}
                    wrapperClassName={styles.menu}
                    user={user}
                    profileId={user._id}
                    modalRef={$socialModal}
                  >
                    <DotsVerticalIcon color="var(--font-color-strong)" role={'button'} />
                  </UserContextMenu>
                </div>
              ) : null
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
