import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useDisplay } from 'hooks';

import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import { TooltipSmall } from 'shared/ui/tooltip';

import './progress-bar-styles.scss';

const ProgressBar = ({
  progressData: { progress, sectionsNumber },
  changeSlide,
  current,
  variant = 'old',
  activeSlide,
  slideNames,
  hideButtons = false,
}) => {
  const ref = useRef(null);
  const theme = useSelector(getCurrentTheme);
  const [width, setWidth] = useState(0);
  const last = sectionsNumber - progress > 0 ? 1 : 0;
  const total = Math.floor((width / sectionsNumber) * (progress + last));
  const { isMobile } = useDisplay();

  function handleNav(itemIndex) {
    changeSlide(itemIndex);
  }

  function handleNewNav(itemIndex) {
    if (itemIndex > progress) return;
    changeSlide(itemIndex);
  }

  function handleBack() {
    if (current !== 0) {
      changeSlide(current - 1);
    }
  }

  function handleNext() {
    if (progress < activeSlide + 1) return;
    changeSlide(activeSlide + 1);
  }

  useEffect(() => {
    function handleWindowResize() {
      setWidth(ref.current?.clientWidth);
    }

    if (ref) {
      handleWindowResize();
      window.addEventListener('resize', handleWindowResize);
    }
    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, [ref]);

  if (variant === 'new') {
    return (
      <div className={`progress-box ${variant}`} ref={ref}>
        <div className="bar">
          {!hideButtons && (
            <button className={`progressbar-navigate ${isMobile && 'mobile'}`} onClick={handleBack} disabled={current === 0}>
              <BsChevronLeft strokeWidth={1.5} />
            </button>
          )}
          {[...Array(sectionsNumber).keys()].map((item) => (
            <TooltipSmall key={'progressbar-item' + item} className={'cell_tooltip'} delay={100} text={slideNames[item].name} direction="bottom">
              <div className={'cell-wrapper'} onClick={() => handleNewNav(item)}>
                <div
                  className={`cell ${theme} ${current === item && 'current'} ${item <= activeSlide ? 'cell_filled' : 'cell_empty'} ${
                    progress < item ? 'cell_disabled' : ''
                  }`}
                  style={{ width: `100%`, zIndex: 2 }}
                  data-prog={current !== item ? item + 1 : ''}
                />
              </div>
            </TooltipSmall>
          ))}
          {!hideButtons && (
            <button
              className={`progressbar-navigate ${isMobile && 'mobile'}`}
              onClick={handleNext}
              disabled={progress <= activeSlide || activeSlide + 1 === sectionsNumber}
            >
              <BsChevronRight strokeWidth={1.5} />
            </button>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={`progress-box ${variant}`} ref={ref}>
      <div className="bar" style={{ width: `${total}px` }}>
        {[...Array(progress + last).keys()].map((item) => {
          return item === current ? (
            <div
              className={`cell current ${item === progress && 'last'}`}
              key={'progressbar-item' + item}
              onClick={() => handleNav(item)}
              style={{ width: `100%`, zIndex: 2 }}
              data-prog={current !== item ? item + 1 : ''}
            >
              <p style={{ color: 'white' }}>{item + 1}</p>
            </div>
          ) : (
            <div
              className={`cell ${item === progress && 'last'}`}
              key={'progressbar-item' + item}
              onClick={() => handleNav(item)}
              style={{ width: `100%`, zIndex: 2 }}
              data-prog={current !== item ? item + 1 : ''}
            >
              <p style={{ color: 'white' }}>{current === item ? item + 1 : ''}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProgressBar;
