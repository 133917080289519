import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { IRewardMinimal, getCurrentTheme, getItems, getUserData } from 'store';
import * as S from './styles';
import { ReactComponent as MinusIcon } from '../assets/minus.svg';
import { ReactComponent as PlusIcon } from '../assets/plus.svg';
import { USER_ROLES } from 'app/constants';

const REWARDS_FOR_THIS_PICKER: { max: number }[] = [
  {
    max: 10,
  },
  {
    max: 1,
  },
];

type Props = {
  rewards: IRewardMinimal[];
  handleRewardsChange: (id: number, quantity: number) => void;
};

const RewardsPicker = ({ rewards, handleRewardsChange }: Props) => {
  const items = useSelector(getItems);
  const { roles } = useSelector(getUserData);
  const theme = useSelector(getCurrentTheme);
  const isAdmin = roles.some((el) => el === USER_ROLES.ADMIN);

  const CURRENT_REWARDS = isAdmin ? items.filter((i) => i.type === 'fungible') : items.filter((el) => el.id === 0 || el.id === 1);

  const [editing, setEditing] = useState(false);

  const totalRewards = useMemo(() => rewards.reduce((acc, el) => (acc += el.quantity), 0), [rewards]);

  const EditingContent = useMemo(
    () =>
      editing
        ? CURRENT_REWARDS.map((item) => {
            const currentQuantity = rewards.find((el) => el.id === item.id)?.quantity || 0;
            const maxQuantity = isAdmin ? undefined : REWARDS_FOR_THIS_PICKER[item.id]?.max;
            return (
              <S.RewardEdit key={item.id}>
                <S.RewardIcon src={item?.imageThumb || item?.image} />
                <S.RewardQuantityEdit>
                  <S.AddBtn
                    onClick={() => handleRewardsChange(item.id, currentQuantity >= 1 ? currentQuantity - 1 : 0)}
                    $disabled={currentQuantity <= 0}
                  >
                    <MinusIcon />
                  </S.AddBtn>
                  <S.QuantityInput
                    $theme={theme}
                    min={0}
                    value={currentQuantity}
                    type="number"
                    onChange={(e) => {
                      if (!maxQuantity || +e.target.value <= maxQuantity) {
                        handleRewardsChange(item.id, Number(e.target.value));
                      } else {
                        handleRewardsChange(item.id, +e.target.value < maxQuantity ? +e.target.value : currentQuantity);
                      }
                    }}
                    max={maxQuantity}
                  />
                  <S.AddBtn
                    onClick={() => handleRewardsChange(item.id, currentQuantity + 1)}
                    $disabled={typeof maxQuantity === 'number' && currentQuantity >= maxQuantity}
                  >
                    <PlusIcon />
                  </S.AddBtn>
                </S.RewardQuantityEdit>
              </S.RewardEdit>
            );
          })
        : null,
    [totalRewards, rewards, editing]
  );

  if (!editing) {
    return (
      <S.RewardsPreview>
        {totalRewards ? (
          <>
            {rewards.map((r) => {
              const item = items.find((el) => el.id === r.id);
              if (r.quantity && item) {
                return (
                  <S.RewardItem key={r.id} $theme={theme}>
                    <S.RewardIcon src={item?.imageThumb || item?.image} /> {r.quantity}
                  </S.RewardItem>
                );
              }
              return null;
            })}
          </>
        ) : (
          'No rewards'
        )}
        <S.EditButton $theme={theme} onClick={() => setEditing(true)}>
          Edit
        </S.EditButton>
      </S.RewardsPreview>
    );
  }

  return <S.EditList>{EditingContent}</S.EditList>;
};

export default RewardsPicker;
