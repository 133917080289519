import { EItemFungibleType } from 'interface';

export const REWARDS_BY_REFS = [
  {
    referrals: 1,
    rewards: [
      {
        fungibleType: EItemFungibleType.NatureChest,
        amount: 1,
      },
    ],
  },
  {
    referrals: 10,
    rewards: [
      {
        fungibleType: EItemFungibleType.AquaChest,
        amount: 1,
      },
    ],
  },
  {
    referrals: 20,
    rewards: [
      {
        fungibleType: EItemFungibleType.VioletChest,
        amount: 1,
      },
    ],
  },
  {
    referrals: 50,
    rewards: [
      {
        fungibleType: EItemFungibleType.DivineChest,
        amount: 1,
      },
    ],
  },
  {
    referrals: 100,
    rewards: [
      {
        fungibleType: EItemFungibleType.RoyalChest,
        amount: 1,
      },
    ],
  },
  {
    referrals: 500,
    rewards: [
      {
        fungibleType: EItemFungibleType.RoyalChest,
        amount: 1,
      },
    ],
  },
  {
    referrals: 1000,
    rewards: [
      {
        fungibleType: EItemFungibleType.RoyalChest,
        amount: 1,
      },
    ],
  },
];

export const REWARDS_STEPS = [1, 10, 20, 50, 100, 500, 1000];

export const NumWidthByRefs: { [key: string]: number } = {
  _1: 47,
  _10: 48,
  _20: 48,
  _50: 48,
  _100: 57,
  _500: 57,
  _1000: 66,
};

export const USDC_BY_REFS = [
  {
    refs: 10,
    usdc: 5,
  },
  {
    refs: 20,
    usdc: 5,
  },
  {
    refs: 50,
    usdc: 5,
  },
  {
    refs: 100,
    usdc: 5,
  },
  {
    refs: 500,
    usdc: 5,
  },
  {
    refs: 1000,
    usdc: 500,
  },
];
