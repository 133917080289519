import { BasicButtonV1 } from 'shared/ui';
import styles from '../styles.module.scss';

type Props = {
  onClick: () => void;
  title: string;
  isDisabled?: boolean;
};

const PreaparedButton = ({ onClick, title, isDisabled = false }: Props) => {
  return (
    <BasicButtonV1 variant="primary" onClick={onClick} className={styles.button} disabled={isDisabled}>
      {title}
    </BasicButtonV1>
  );
};

export default PreaparedButton;
