export const getCursorCoordinates = () => {
  const selection = window.getSelection();
  if (!selection) {
    return { x: 0, y: 0 };
  }

  if (selection.rangeCount === 0) {
    return { x: 0, y: 0 };
  }

  const range = selection.getRangeAt(0);

  const rect = range.getBoundingClientRect();

  return { x: rect.left, y: rect.top };
};

export const getElementAtCursor = () => {
  const selection = window.getSelection();
  if (!selection || !selection.focusNode) {
    return null;
  }

  return selection.focusNode.parentElement;
};

export type TNode = {
  tag: string;
  attributes: Record<string, string>;
  children: TNode[];
  text: string;
};

export const html2JSON = (node: HTMLElement) => {
  const result: TNode = {
    tag: '',
    attributes: {},
    children: [],
    text: '',
  };

  const tag = node.tagName.toLowerCase();
  if (tag === 'script') {
    result.tag = 'div';
  } else {
    result.tag = tag;
  }

  const className = node.getAttribute('class');
  const dataMightyAnswer = node.getAttribute('data-mighty-answer');
  const contentEditable = node.getAttribute('contenteditable');

  if (className !== null) {
    result.attributes['class'] = className;
  }
  if (dataMightyAnswer !== null) {
    result.attributes['data-mighty-answer'] = dataMightyAnswer;
    result.attributes['data-mighty-type'] = 'mighty-answer-slot';
  }
  if (contentEditable !== null) {
    result.attributes['contenteditable'] = contentEditable;
  }

  const childNodes = Array.from(node.childNodes);

  childNodes.forEach((child) => {
    if (child.nodeType === Node.TEXT_NODE) {
      result.children.push({ tag: '__text', text: child.textContent ?? '', attributes: {}, children: [] });
    } else {
      result.children.push(html2JSON(child as HTMLElement));
    }
  });

  return result;
};

export const json2HTML = (node: TNode) => {
  const rootNode = document.createElement(node.tag);

  Object.keys(node.attributes).forEach((key) => {
    rootNode.setAttribute(key, node.attributes[key]);
  });

  node.children.forEach((child) => {
    if (child.tag === '__text') {
      rootNode.insertAdjacentText('beforeend', child.text);
      return;
    }

    rootNode.appendChild(json2HTML(child));
  });

  return rootNode;
};

export const extractHTMLFromJsonDOM = (node: string) => {
  try {
    const nodeObject = JSON.parse(node);
    return json2HTML(nodeObject);
  } catch (e) {
    return null;
  }
};
