import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 12px;

      > .button {
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: 0.5px;
        color: white;
        padding: 6px 12px;
        height: 28px;
      }
    `
  ),
  Span = styled.span`
    color: rgba(222, 161, 99, 1);
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.5px;
    white-space: pre;
  `;
