import { IEvent } from 'interface';
import { useCallback, useMemo } from 'react';
import { useLazyRefetchHistoryQuery } from 'store';

type Props = {
  updateState: any;
  page: number;
  totalPages: number;
  limit?: number;
  items: IEvent[];
  first3items?: IEvent[];
  isLoading: boolean;
};

export const useGetMoreEvents = ({ updateState, page = 1, limit = 10, items = [], totalPages, isLoading }: Props) => {
  const [fetchHistory] = useLazyRefetchHistoryQuery();

  const isLoadable = useMemo(() => page <= totalPages, [page, totalPages]);

  const handleRefetch = useCallback(async () => {
    if (isLoading) return;

    updateState({ page: page + 1 });

    if (isLoadable) {
      updateState({ isLoading: true });

      fetchHistory({ page, limit })
        .unwrap()
        .then((data) => {
          if (!data) return;
          const { events, totalPages } = data;

          const newEvents = [...items, ...events];

          if (events.length > 0) {
            updateState({ items: newEvents, totalPages: totalPages });
            // dispatch(setUserEvents(newEvents));
          } else {
            updateState({ page: totalPages > 0 ? totalPages : 1 });
          }
          updateState({ totalPages: totalPages });
        })
        .catch(console.error)
        .finally(() => {
          updateState({ isLoading: false });
        });
    }
  }, [page, limit, isLoading, isLoadable, isLoading]);

  return { handleRefetch, isLoadable, isLoading };
};
