import { useUserEnergyLevel } from 'hooks';
import { motion } from 'framer-motion';
import clsx from 'clsx';

import { ReactComponent as Lightning } from 'assets/icons/lightning.svg';

import * as Styled from './styles';

type Props = {
  className?: string;
};

export const EnergyIncomeIndicator = ({ className = '' }: Props) => {
  const { filledUp, energyIncome, countdown } = useUserEnergyLevel();

  return !filledUp ? (
    <Styled.Text className={clsx({ [className]: !!className })}>
      +{energyIncome}{' '}
      <motion.span style={{ display: 'inline-block' }} animate={countdown === null ? { translateY: [0, -10, 0] } : {}} transition={{ duration: 0.7 }}>
        <Lightning />
      </motion.span>{' '}
      in <span>{countdown ?? '0:00'}</span>
    </Styled.Text>
  ) : (
    <Styled.Text className={clsx({ [className]: !!className })}>
      <Lightning /> max
    </Styled.Text>
  );
};
