import { Editor } from '@tiptap/react';
import { ChangeEvent, FC, useState } from 'react';

import * as Elements from './styles';
import { Input } from 'shared/ui';
import IconButton from 'components/library/iconButton';
import { FaCheckCircle } from 'react-icons/fa';

type TFieldPopupProps = {
  onClose: () => void;
  editor: Editor;
};

const LinkFieldPopup: FC<TFieldPopupProps> = ({ onClose, editor }) => {
  const [value, setValue] = useState('');

  const handleInputChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setValue(evt.target.value);
  };

  const handleFormSubmit = (evt: React.FormEvent) => {
    evt.preventDefault();

    editor.chain().focus().extendMarkRange('link').setLink({ href: value }).run();
    onClose();
  };

  return (
    <Elements.SFieldLinkPopup>
      <form onSubmit={handleFormSubmit} id="linkFieldPopup">
        <Input value="some value" onChange={handleInputChange} placeholder="Enter link" className="link-field-popup__input" />
        <IconButton className="link-field-popup__icon-button" icon={<FaCheckCircle size={25} />} type="submit" form="linkFieldPopup" />
      </form>
    </Elements.SFieldLinkPopup>
  );
};

export default LinkFieldPopup;
