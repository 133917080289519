import styled, { css } from 'styled-components';
import lightningBlue from './assets/lightning-blue.png';
import lightningGreen from './assets/lightning-green.png';
import lightningRed from './assets/lightning-red.png';
import lightningYellow from './assets/lightning-yellow.png';
import lightningPurple from './assets/lightning-purple.png';

function currentColorLightning(color: string) {
  switch (color) {
    case 'blue':
      return lightningBlue;
    case 'green':
      return lightningGreen;
    case 'red':
      return lightningRed;
    case 'yellow':
      return lightningYellow;
    default:
      return lightningPurple;
  }
}

export const Container = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: center;
    `
  ),
  Content = styled.div(
    () => css`
      position: relative;
      max-width: 260px;
    `
  ),
  Letter = styled.img(
    () => css`
      position: relative;
      width: 100%;
    `
  ),
  Icon = styled.img(
    () => css`
      max-width: 32px;
      position: absolute;
      top: 35%;
      left: 40%;
      transform: rotate(20deg);
      z-index: 5;
    `
  ),
  Lightning = styled.div<{ $color: string }>(
    ({ $color }) => css`
      background-image: url(${currentColorLightning($color)});
      background-size: cover;
      background-repeat: no-repeat;
      width: 500px;
      height: 500px;
      position: absolute;
      top: -78%;
      left: -52%;
      mix-blend-mode: screen;
      pointer-events: none;
      transform-origin: 50% 47%;
    `
  ),
  Mask = styled.img(
    () => css`
      position: absolute;
      top: 5px;
      left: 14px;
      height: 145px;
    `
  );
