import { useDisplay, useGetCurrentTheme, useGetIsAuth } from 'hooks';
import * as S from './styles';
import MiniUser from 'components/library/navbar/MiniUser/widget';
import { useClientDetection } from 'utils/useClientDetection';
import { memo, useMemo } from 'react';

type Props = {
  subText: string;
  image: string;
  navToOverview: () => void;
  courseName: string;
  scrollTop?: number;
};

const LessonHeader = ({ image, navToOverview, subText, courseName, scrollTop = 0 }: Props) => {
  const { os } = useClientDetection();
  const isIOS = os === 'iOS';
  const theme = useGetCurrentTheme();
  const { isMobile } = useDisplay();
  const isAuth = useGetIsAuth();

  const defaultTop = isAuth ? 44 : 39;

  const isHiding = scrollTop > 50 && isMobile;

  const currentOffset = useMemo(() => {
    if (scrollTop > defaultTop) return -13;
    return 0;
  }, [scrollTop, isMobile]);

  return (
    <>
      <S.Container $alignItems="center" $justifyContent="space-between" $isIOS={isIOS} animate={{ opacity: isHiding ? 0 : 1 }} exit={{ opacity: 0 }}>
        <S.LeftPart $alignItems="center">
          <div className="cross-box" style={{ width: 24, minWidth: 24, height: 24 }}>
            <div
              style={{
                display: 'flex',
                position: isMobile ? 'static' : 'fixed',
                top: defaultTop,
                transition: '.2s ease-in-out',
                transform: `translateY(${currentOffset}px)`,
              }}
            >
              <S.Cross $theme={theme} onClick={navToOverview} />
            </div>
          </div>
          <S.Image src={image} />
          <S.Col>
            <S.Name $theme={theme}>{courseName}</S.Name>
            <S.Sub $theme={theme}>{subText}</S.Sub>
          </S.Col>
        </S.LeftPart>
        <S.RightPart>{!isMobile && isAuth && <MiniUser isAvatar />}</S.RightPart>
      </S.Container>
    </>
  );
};

export default memo(LessonHeader);
