import styled, { css } from 'styled-components';

type Props = {
  $size?: number | string;
  $radius?: number | string;
  $margin?: number | string;
  $width?: number | string;
  $height?: number | string;
};

const ResizebleSkeleton = styled.div<Props>(
  ({ $margin = 0, $radius = 4, $size = '100%', $height, $width }) => css`
    width: ${$width ?? $size};
    height: ${$height ?? $size};
    border-radius: ${$radius};
    margin: ${$margin};

    background: linear-gradient(-45deg, rgba(100, 135, 239, 0.1) 40%, rgba(100, 135, 239, 0.2) 50%, rgba(100, 135, 239, 0.1) 60%);
    background-size: 300%;
    background-position-x: 200%;

    animation: shimmer 2s infinite linear forwards;

    @keyframes shimmer {
      to {
        background-position-x: -100%;
      }
    }
  `
);

export default ResizebleSkeleton;
