import { FC, useEffect, useState } from 'react';
import Text from '@tiptap/extension-text';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import { TCustomizationWarning, TWarningWidgetProps } from './model';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

import Bubble from '../TextWidget/components/Bubble';
import TiptapEditor from 'components/library/tiptapEditor';
import Warning from 'components/pages/study/courses/material-pages/SlideContent/components/Warning';
import UploadModal from 'components/library/uploadModal/UploadModal';
import { useSlideContext } from '../../../SlideContext';
import { useImageUpload } from './hooks/useImageUpload';
import { useImageDelete } from './hooks/useImageDelete';

const WarningWidget: FC<TWarningWidgetProps> = ({ id, onChange, content, isCustomization, color = '#FFBF66', icon = 'Info', image }) => {
  const theme = useSelector(getCurrentTheme);
  const { slide } = useSlideContext();

  const uploadImage = useImageUpload(slide._id, id);
  const deleteImage = useImageDelete(slide._id, id);
  const [isUploadModalOpened, setIsUploadModalOpened] = useState(false);
  const [customization, setCustomization] = useState<TCustomizationWarning>({ icon: icon!, color: color! });

  useEffect(() => {
    if (customization.color === '#fff' || customization.color === '#000') {
      setCustomization((prevState) => ({ ...prevState, color: theme === 'dark' ? '#fff' : '#000' }));
    }
  }, [theme]);

  useEffect(() => {
    if (isCustomization) {
      if (color && (customization.color !== color || customization.icon !== icon)) {
        onChange(customization);
      }
    }
  }, [customization, color, icon, isCustomization, onChange]);

  function handleSelect(changes: any) {
    return setCustomization((prevState) => ({ ...prevState, ...changes }));
  }

  const handleCloseUploadModal = () => {
    setIsUploadModalOpened(false);
  };

  const handleOpenUploadModal = () => {
    setIsUploadModalOpened(true);
  };

  const handleImageUpload = (image: FileList | null) => {
    uploadImage(image)?.then(handleCloseUploadModal);
  };

  const handleDeleteImage = () => {
    deleteImage();
  };

  return (
    <div style={{ width: '100%' }} className={'root-transition'}>
      <Warning
        image={image}
        type={customization.icon}
        color={customization.color}
        handleSelect={handleSelect}
        onUploadImage={handleOpenUploadModal}
        onDeleteImage={handleDeleteImage}
      >
        <TiptapEditor
          extensions={[StarterKit, Text, TextStyle, Color, Underline]}
          bubble={(editor) => <Bubble editor={editor} />}
          content={content}
          placeholder="Type infotext"
          onChange={onChange}
        />
      </Warning>
      <UploadModal open={isUploadModalOpened} onUpload={handleImageUpload} onClose={handleCloseUploadModal} type={'image'} />
    </div>
  );
};

export default WarningWidget;
