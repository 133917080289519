import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      height: 100%;
      display: flex;
      white-space: pre;
      text-align: right;

      .avatar {
        margin-left: 20px;
      }
    `
  ),
  Data = styled.div(
    () => css`
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      gap: 8px;
      position: relative;
    `
  ),
  Info = styled.div(
    () => css`
      display: inline-flex;
      align-items: center;
      justify-content: space-between;
      gap: 2px;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 100%;

      .exp-pic {
        height: auto;
        width: 16px;
      }
    `
  ),
  Exp = styled.div(
    () => css`
      font-size: 22px;
      line-height: 22px;
      font-weight: bold;
      color: var(--font-color-strong);
    `
  ),
  EnergySpan = styled.span(
    () => css`
      font-size: 14px;
      line-height: 100%;
      display: inline-flex;
      align-items: center;

      span {
        opacity: 0.7;
      }

      svg {
        color: var(--grade-dark-color-uncommon);
        fill: var(--grade-dark-color-uncommon);
        margin-right: 5px;
      }
    `
  ),
  CoinNotice = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 3px;
      position: absolute;
      z-index: 1;
      bottom: -4px;
      left: 0;
      border-radius: 4px;
      border: 1.4px solid rgba(255, 255, 255, 0.1);
      background: linear-gradient(0deg, rgba(100, 135, 239, 0.3) 0%, rgba(100, 135, 239, 0.3) 100%),
        radial-gradient(228.11% 200.99% at 186.12% -46.05%, rgba(98, 126, 234, 0.8) 0%, rgba(28, 29, 38, 0) 100%),
        linear-gradient(76deg, rgba(50, 53, 71, 0.47) 5.23%, rgba(40, 42, 57, 0.8) 107.31%);
      backdrop-filter: blur(15px);
      width: 77px;
      height: 32px;
      pointer-events: none;

      div {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 100%;
      }
    `
  ),
  TTitle = styled.h3(
    () => css`
      color: #fff;
      text-align: center;
      font-size: 20px;
      font-weight: 500;
      line-height: 115%;
      letter-spacing: 0.5px;
      margin: 0 0 6px 0;
    `
  ),
  TText = styled.p<{ $fontSize?: string; $opacity?: number; $lineHeight?: string; $margin?: string; $maxW?: string }>(
    ({ $fontSize = '14px', $opacity = 1, $lineHeight = '124%', $margin, $maxW }) => css`
      color: #fff;
      font-size: ${$fontSize};
      font-weight: 400;
      line-height: ${$lineHeight};
      text-align: center;
      opacity: ${$opacity};
      margin: ${$margin};
      max-width: ${$maxW};

      span {
        color: inherit;
        cursor: pointer;
        text-decoration: underline;
      }
    `
  );
