export const formatAnswers = (node: HTMLElement) => {
  const answers = node.querySelectorAll('[data-mighty-type="mighty-answer-slot"]');

  answers.forEach((answer, idx) => {
    const block = document.createElement('span');
    block.setAttribute('data-answer-idx', idx.toString());
    block.setAttribute('data-type', 'mighty-answer-block');
    answer.replaceWith(block);
  });

  return node;
};
