import { FC, useMemo } from 'react';
import { useDisclosure } from 'hooks';
import { IRewardMinimal } from 'store';

import { TChapterListItemProps } from './types';

import { Menu } from './components';
import ChapterCard from '../ChapterCard';

import { Container } from './styles';

const ChapterListItem: FC<TChapterListItemProps> = ({
  isInEditMode,
  rewards,
  isLoading,
  courseId,
  onToggleLockChapter,
  onIsInEditModeChange,
  onChange,
  onDelete,
  onRewardsShow,
  onEditChapterRequest,
  ...props
}) => {
  const { isOpen, open, close } = useDisclosure(false);

  const rewardsUnduplicated = useMemo(() => {
    if (!rewards?.length) return [];

    const duplicates: { [key: number]: IRewardMinimal } = {};

    rewards.forEach((obj) => {
      const rewardId = obj.id;

      if (duplicates.hasOwnProperty(rewardId)) {
        duplicates[rewardId].quantity += obj.quantity;
      } else {
        duplicates[rewardId] = { ...obj };
      }
    });

    return Object.values(duplicates);
  }, [rewards]);

  const isLocked = useMemo(() => Boolean(props.dependencies?.length), [props.dependencies]);

  return (
    <Container $gap="8px" onMouseEnter={open} onMouseLeave={close}>
      <ChapterCard
        index={props.index}
        id={props.id}
        name={props.name}
        dependencies={props.dependencies}
        isInEditMode={isInEditMode}
        onChange={onChange}
        onEditChapterRequest={onEditChapterRequest}
        onIsInEditModeChange={onIsInEditModeChange}
        isLoading={isLoading}
        courseId={courseId}
      />
      <Menu
        onDelete={onDelete}
        onIsInEditModeChange={onIsInEditModeChange}
        rewards={rewardsUnduplicated}
        onLock={() => onToggleLockChapter(props.id)}
        isLocked={isLocked}
        isShown={isOpen}
      />
    </Container>
  );
};

export default ChapterListItem;
