import { useRef, useEffect, MutableRefObject } from 'react';

export default function useClickOutside<T extends HTMLElement>(handler: () => void): MutableRefObject<T | null> {
  const domNode = useRef<T>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (domNode.current && !domNode.current.contains(event.target as Node)) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [domNode, handler]);

  return domNode;
}
