// import { Badge } from 'components/library/navbar/UserMenu/panels/effects/badges/badge';
// import { useGetIsPremium } from 'hooks';
// import { PremiumLabel } from 'shared/ui/logo';
// import { IExpEffectBadgeCountdown } from '../../types';
// import { EEffectsListItemBadgeType } from 'components/library/navbar/UserMenu/panels/effects/badges/types';
// import * as Styled from './styles';
// import clsx from 'clsx';

type Props = {
  isBadge?: boolean;
};

export const Premium = ({ isBadge = true }: Props) => {
  return <></>;
  // const { updatedAt, expiresAt, isPremium } = useGetIsPremium();

  // if (!isPremium) {
  //   return <></>;
  // }

  // const data: IExpEffectBadgeCountdown = {
  //   type: 'countdown',
  //   updatedAt: new Date(),
  //   expiredAt: new Date(),
  //   effectName: EEffectsListItemBadgeType.TIMER,
  //   grade: 'legendary',
  // };

  // if (updatedAt && expiresAt) {
  //   data.updatedAt = new Date(updatedAt);
  //   data.expiredAt = new Date(expiresAt);
  // }

  // return (
  //   <Styled.Container>
  //     <PremiumLabel />
  //     {isBadge && <Badge className={clsx('badge')} data={data} />}
  //   </Styled.Container>
  // );
};
