import styled from 'styled-components';

export const SWrapper = styled.div`
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    background: rgba(19, 26, 41, 0.71);
    backdrop-filter: blur(20px);
    z-index: 3000;
  `,
  SContainer = styled.div`
    max-width: 934px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    will-change: scale;
  `,
  STitle = styled.h2`
    color: #fff;
    font-size: 72px;
    font-weight: 500;
    line-height: 119%;
    margin-bottom: 11px;

    @media screen and (max-width: 992px) {
      font-size: 32px;
      margin-bottom: 5px;
    }
  `,
  SSubtitle = styled.p`
    color: #fff;
    font-size: 28px;
    line-height: 143%;
    letter-spacing: 0.5px;
    text-align: center;

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 125%;
    }
  `,
  SRewardsWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
    max-height: 450px;
    overflow: auto;
    padding-right: 15px;

    &.singleChild {
      grid-template-columns: repeat(1, 1fr);
      padding-right: 0px;
    }
  `;
