import { useEffect, useReducer } from 'react';
import { Select } from 'shared/ui';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { getUserData } from 'store';
import { IUserItem } from 'interface';

import InventoryItem from 'components/library/cards/inventory/InventoryItem';

import styles from './styles.module.css';

interface IProps {
  chooseGrade: {
    onChangeHandler: any;
    options: any;
    value: any;
  };
  variant?: 'default' | 'mobile';
}

const options = [
  { label: 'All', value: 'all' },
  { label: 'Consumables', value: 'consumables' },
  { label: 'Weapon', value: 'weapon' },
  { label: 'Rare', value: 'rare' },
  { label: 'Uncommon', value: 'uncommon' },
  { label: 'Common', value: 'common' },
];

const groupOptions = [
  { label: 'All', value: 'all' },
  { label: 'Name', value: 'name' },
];

interface State {
  inventoryArray: IUserItem[];
  type: string;
  criteria: TCriteria;
}
type TCriteria = 'Group by' | 'all' | 'name';

const defaultState: State = {
  inventoryArray: [],
  type: 'all',
  criteria: 'Group by',
};

export const InventoryList = ({ chooseGrade, variant = 'default' }: IProps) => {
  const {
    // nickname,
    exp: { items },
  } = useSelector(getUserData);
  const theme = useSelector(getCurrentTheme);
  // const [nfts, setNFTS] = useState<INFToken[]>([]);
  const [state, updateState] = useReducer((prev: State, next: Partial<State>) => ({ ...prev, ...next }), defaultState);
  // const { getNFTs, isLoading: isNFTSLoading } = useNFTBalances();

  const { inventoryArray, type, criteria } = state;
  const maxQuantityItems = 35;
  console.log(chooseGrade);

  // useEffect(() => {
  //   async function hh() {
  //     const heh = await getNFTs();
  //     heh[0] && setNFTS(heh);
  //   }
  //   hh();
  // }, [nickname, getNFTs]);

  useEffect(() => {
    let itemsArray: IUserItem[] = [...items];

    if (criteria !== 'all' && criteria !== 'Group by') {
      // @ts-ignore
      itemsArray = itemsArray.sort((a, b) => {
        // @ts-ignore
        return b[criteria] && a[criteria] && a[criteria].localeCompare(b[criteria]);
      });
    }
    const inventoryLength = itemsArray.length;
    if (inventoryLength < maxQuantityItems) {
      itemsArray.push(...new Array(maxQuantityItems - inventoryLength));
    }
    // @ts-ignore
    itemsArray = itemsArray.filter((i: any) => i?.isNFT || (i?.quantity && i?.quantity > 0) || !i);
    updateState({ inventoryArray: itemsArray });
  }, [criteria, items]);

  function handleGrouping(val: string) {
    val !== 'all' ? updateState({ criteria: val as TCriteria }) : updateState({ criteria: 'all' });
  }

  function handleTypes(val: string) {
    val !== 'all' ? updateState({ type: val }) : updateState({ type: 'all' });
  }

  return (
    <>
      <div className={clsx(styles.selects, styles[variant])}>
        <Select variant="flat" menu="panel" options={options} value={type} onChange={handleTypes} defaultValue={options[0].value} />

        {variant === 'default' ? (
          <Select variant="flat" menu="panel" options={groupOptions} value={criteria} onChange={handleGrouping} placeholder={'Group by'} />
        ) : (
          <Select
            placeholder={'Sort by Grades'}
            options={chooseGrade.options}
            value={chooseGrade.value}
            onChange={chooseGrade.onChangeHandler}
            className={styles.sortGrades}
          />
        )}
      </div>
      <div className={clsx(styles.container, 'tiny-sb', styles[variant])}>
        {inventoryArray.map((item, index: number) => {
          return !item ? (
            <div key={'inventoryArray-item' + index} className={clsx(styles.emptyItem, styles[theme], 'fancy-border')} />
          ) : (
            <InventoryItem key={item.item.name} isSmallMid itemId={item.item.id} amount={item.quantity} className={styles.item} />
          );
        })}
      </div>
    </>
  );
};
