import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useDisplay } from 'hooks';

import { IoAdd } from 'react-icons/io5';

import styles from './styles.module.css';

interface IProps {
  onCLick: () => void;
  title: string;
  className?: string;
}

export const AddButton = ({ title, onCLick, className = '' }: IProps) => {
  const theme = useSelector(getCurrentTheme);
  const { isMobile } = useDisplay();
  return (
    <button
      className={clsx(styles.button, `glass-div basic ${theme}`, {
        [className]: !!className,
        [styles.mobile]: isMobile,
      })}
      onClick={onCLick}
    >
      <IoAdd className={styles.iconContainer} />
      <div className={styles.title}>{title}</div>
    </button>
  );
};
