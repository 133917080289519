import useAverageColorFromImageUrl from 'hooks/useAverageColorFromImageUrl';
import * as S from './styles';
import { ISpace } from 'store';
import { SpaceFollowButton } from '../../SpaceCreation/components/followButton';
import { useNavigate } from 'react-router-dom';
import { useGetSpaceRewards } from '../../hooks';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { THEMES } from 'app/constants';

import { ReactComponent as VerifiedIcon } from '../../assets/verified.svg';

type Props = {
  space: ISpace;
};

export const MobileSpaceSlide = ({ space }: Props) => {
  const theme = useSelector(getCurrentTheme);
  const color = useAverageColorFromImageUrl(space.imageThumb, { opacity: theme === THEMES.DARK ? '0.1' : '1' });
  const navigate = useNavigate();
  const { total: rewards, isLoadingRewards } = useGetSpaceRewards(space._id);

  return (
    <S.MobileSlideContainer onClick={() => navigate('/' + space.name.replaceAll(' ', '_'))}>
      {!isLoadingRewards &&
        rewards.map((el, i) =>
          i > 5 ? null : (
            <S.RewardImage
              src={el.imageThumb}
              index={i}
              key={el.id}
              animate={{ y: 0, opacity: 1 }}
              initial={{ y: -10, opacity: 0 }}
              transition={{ delay: i * 0.5 }}
            />
          )
        )}
      <S.MobileSlideImage src={space.imageThumb || ''} />
      <S.MobileSlideBox color={!color ? 'rgba(17,17,17,0.2)' : color}>
        <S.MobileSlideName>
          <span>{space.name}</span> {space.isVerified && <VerifiedIcon style={{ minWidth: '25px' }} />}
        </S.MobileSlideName>
        <S.MobileSlidetags>
          {space.tags.map((el, i) =>
            i > 1 ? null : (
              <S.Tag key={el}>
                <span>{el}</span>
              </S.Tag>
            )
          )}
        </S.MobileSlidetags>
        <SpaceFollowButton spaceId={space._id} />
      </S.MobileSlideBox>
    </S.MobileSlideContainer>
  );
};

export default MobileSpaceSlide;
