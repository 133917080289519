import { THEMES } from 'app/constants';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div(
    () => css`
      max-width: 445px;
      width: 100%;
      position: relative;
      border-radius: 4px;
      overflow: hidden;

      @media screen and (max-width: 992px) {
        ${Bottom} {
          height: 100%;
          overflow: hidden;
        }

        ${Root} {
          height: auto;
        }

        ${NameLabel} {
          width: 100%;
          white-space: pre-wrap;
        }
      }
    `
  ),
  Root = styled.div<{$theme?: THEMES}>(
    ({$theme}) => css`
      border-radius: 4px;
      width: 100%;
      height: 84px;
      padding: 16px 12px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-size: 16px;
      border-radius: 5px;
      overflow: hidden;
      background: linear-gradient(142.74deg, rgba(255, 255, 255, 0.08) 1.72%, rgba(255, 255, 255, 0) 153.63%);
      transition: 0.3s;
      ${$theme === THEMES.LIGHT && 'border: 1px solid var(--mighty-blue);'}
      &:hover {
        filter: brightness(1.1);
      }
    `
  ),
  LockWrap = styled.div`
    display: flex;
    gap: 8px;
    align-items: center;
  `,
  Bottom = styled.div(
    () => css`
      display: flex;
      justify-content: space-between;
      color: var(--font-color);
      align-items: flex-end;
    `
  ),
  Name = styled.div(
    () => css`
      font-size: 20px;
      font-weight: 500;
      flex-shrink: 0;
      width: 100%;
      max-width: calc(100% - 75px);
      position: relative;
    `
  ),
  NameLabel = styled.div(
    () => css`
      width: calc(100% - 10px);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      color: var(--font-color-strong);
    `
  ),
  SeeItem = styled.div<{ $isEditing?: boolean }>(
    ({ $isEditing }) => css`
      cursor: pointer;
      position: absolute;
      right: 18px;
      top: 50%;
      transform: translateY(-50%);
      opacity: ${$isEditing ? 0 : 1};
      pointer-events: ${$isEditing ? 'none' : 'all'};
      transition: 0.3s;
      display: flex;
      align-items: center;
      gap: 6px;
      & > svg {
        size: 20px;
      }
      &:hover {
        span {
          color: var(--font-color-strong);
        }
      }
    `
  ),
  Control = styled.div(
    () => css`
      width: 100%;
    `
  ),
  ErrorMessage = styled.div(
    () => css`
      font-size: 14px;
      margin-top: 15px;
    `
  ),
  Controls = styled.div(
    () => css`
      margin-top: 15px;
      display: flex;
      justify-content: flex-end;
      gap: 15px;
    `
  ),
  EditButton = styled.div(
    () => css`
      color: var(--font-color);
      position: absolute;
      top: 0;
      right: 0;

      & > div {
        padding: 10px 15px;
      }

      &:hover {
        filter: brightness(1.2);
      }

      button {
        color: var(--font-color);
      }
    `
  );
