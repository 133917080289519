import { toast } from 'react-toastify';
import { coursesConstructorAPI } from 'store';

export const useImageDelete = (slideId: string, widgetId: string) => {
  const [deleteAttachment] = coursesConstructorAPI.useDeleteAttachmentMutation();

  const deleteImage = () => {
    return deleteAttachment({ slideId, widgetId }).catch((err) => {
      console.dir(err);
      toast('Something went wrong. Please try again', { type: 'error' });
    });
  };

  return deleteImage;
};
