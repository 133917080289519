import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      width: 163.5px;
      max-width: 163.5px;
      height: 55.5px;
      flex-shrink: 0;
      display: flex;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(29px);
    `
  ),
  ImageContainer = styled.div(
    () => css`
      width: 55.5px;
      min-width: 55.5px;
      height: 55.5px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;
      border-radius: 4px;

      img {
        object-fit: contain;
        max-height: 100%;
      }
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 13px 11px 9px;
      width: 110px;
    `
  ),
  CourseName = styled.p(
    () => css`
      color: var(--font-color-strong);
      font-size: 13px;
      line-height: 114%;
      line-break: anywhere;
    `
  );
