import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Page = styled.div<{ $theme: THEMES }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2999;
  background-color: #1c1d26;
  background-image: radial-gradient(134.84% 202.54% at 99.3% 0%, rgba(98, 126, 234, 0.2) 0%, rgba(0, 0, 0, 0.02) 100%);
  overflow: auto scroll;
  ${({ $theme }) => $theme === THEMES.LIGHT && 'background-image: linear-gradient(#fff, #fff);'}
  @media screen and (max-width: 992px) {
    padding: 0px 16px;
    overflow: hidden auto;
  }
`;

export const Animation = styled.div`
  position: absolute;
  z-index: 3001;
  width: 100%;
  height: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 752px;
  padding-top: 36px;
  gap: 48px;
  & .vertical-lesson-loader {
    margin: 0 auto;
  }
  @media screen and (max-width: 992px) {
    gap: 36px;
  }
`;

export const SlideWrapper = styled(motion.div)`
  height: fit-content;

  display: flex;
  flex-direction: column;
  position: relative;

  @media screen and (max-width: 992px) {
    width: 100%;
  }
  @media screen and (max-width: 428pt) {
    padding-bottom: 35px;
  }

  & .lesson-button {
    margin-top: 32px;
  }

  & h3 {
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 1;
    margin-bottom: 2.2rem;

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 20.7px;
    }
  }
  & .page-num {
    position: absolute;
    top: 23px;
    right: 18px;

    opacity: 0.5;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }
  & .lesson-content-space {
    width: 100%;
    position: relative;
    color: var(--font-color-strong);
    word-break: break-word;

    .code {
      color: white;
    }
  }
`;

export const LessonBottom = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 63px;
  gap: 30px;
  animation: appear 0.3s 0.5s;
  & .vertical-lesson-button {
    font-size: 20px !important;
    font-weight: 500 !important;
    width: fit-content;
    height: 66px;
  }
  @media screen and (max-width: 992px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 20px;
    & .vertical-lesson-button {
      height: 52px !important;
      width: 100% !important;
    }
  }
  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const ScrollTopButton = styled.div<{ $reversed: boolean }>`
  cursor: pointer;
  color: var(--font-color);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  position: fixed;
  right: 50px;
  bottom: 55px;
  z-index: 2;
  transform: rotate(${({ $reversed }) => ($reversed ? '180deg' : '0deg')});
  transition: 0.2s;
  background-color: #ffffff21;
  &.light {
    background-color: #11111121;
  }
  &:hover {
    scale: 1.1;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const LessonExitButton = styled(motion.div)`
  z-index: 3002;
  position: fixed;
  top: 22px;
  left: 16px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff26;
  backdrop-filter: blur(10px);
  cursor: pointer;
  & > svg {
    width: 37px;
    height: 37px;
    color: var(--font-color-strong);
  }
`;
