import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLazyGetStudentsCountByChapterQuery,
  useLazyGetStudentsCountByCourseQuery,
  useLazyGetStudentsCountByLessonQuery,
  useLazyGetStudentsCountBySlideQuery,
} from 'store';

const HOOKS = {
  slide: useLazyGetStudentsCountBySlideQuery,
  lesson: useLazyGetStudentsCountByLessonQuery,
  chapter: useLazyGetStudentsCountByChapterQuery,
  course: useLazyGetStudentsCountByCourseQuery,
};

const useGetNeededRequestHook = (slideId?: string) => {
  const { lessonId, chapterId, courseId } = useParams();

  if (!!slideId) {
    return { hook: HOOKS.slide, id: slideId };
  }
  if (lessonId) {
    return { hook: HOOKS.lesson, id: lessonId };
  }
  if (chapterId) {
    return { hook: HOOKS.chapter, id: chapterId };
  }
  if (courseId) {
    return { hook: HOOKS.course, id: courseId };
  }

  return { hook: HOOKS.course, id: '' };
};

export const useGetStudentsCount = (slideId?: string) => {
  const [count, setCount] = useState(0);
  const { hook, id } = useGetNeededRequestHook(slideId);
  const [getStudents, { isFetching }] = hook();

  useEffect(() => {
    setCount(0);
  }, [hook]);

  useEffect(() => {
    if (!!id) {
      getStudents(id)
        .unwrap()
        .then((res) => {
          setCount(res.students);
        })
        .catch(console.error);
    }
  }, [getStudents, id]);

  return { count, isLoading: isFetching };
};
