import { createContext, useContext } from 'react';

export type TStage = 'initial' | 'progress' | 'success' | 'error' | 'meaningfull-error';

type TLinkContext = {
  stage: TStage;
  setStage: (arg: TStage) => void;
};

export const LinkModalContext = createContext<TLinkContext>({
  stage: 'initial',
  setStage: () => {},
});

export const useLinkModalContext = () => useContext(LinkModalContext);
