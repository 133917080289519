import { Tooltip } from 'shared/ui/tooltip';
import * as Styled from './styles';
import { IRewardMinimal, getCurrentTheme } from 'store';
import { useGetItemGetterByKey } from 'hooks';
import { useSelector } from 'react-redux';

type Props = {
  triggerRef: any;
  label?: string;
  rewards: IRewardMinimal[];
};

export const CourseCardRewardsTooltip = ({ triggerRef, label, rewards }: Props) => {
  const itemGetter = useGetItemGetterByKey();
  const theme = useSelector(getCurrentTheme);

  return (
    <Styled.Container as={Tooltip} triggerRef={triggerRef}>
      {!!label && (
        <p className="header" style={{ marginBottom: '1rem' }}>
          {label}
        </p>
      )}
      {rewards.length > 0 &&
        rewards.map((reward, index) => {
          const item = itemGetter('id', reward.id);

          return (
            <Styled.Reward key={item.createdAt.toString() + index}>
              <Styled.Image src={item.imageThumb} alt={item.name} />
              <Styled.Text>
                <Styled.Quantity>{reward.quantity}</Styled.Quantity>
                <Styled.Label style={{ color: `var(--grade-${theme}-color-${item.grade})` }}>{item.name}</Styled.Label>
              </Styled.Text>
            </Styled.Reward>
          );
        })}
    </Styled.Container>
  );
};
