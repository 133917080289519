import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES, USER_ROLES } from 'app/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { getUserData } from 'store';

interface IProps {
  requiredRoles?: USER_ROLES[];
  redirectPath?: string;
}

export const SpecificRoleRoute = ({ requiredRoles = [USER_ROLES.ADMIN], redirectPath = ROUTES.MAIN }: IProps) => {
  const { roles } = useSelector(getUserData, shallowEqual);

  return roles && roles.find((role) => requiredRoles.includes(role)) ? <Outlet /> : <Navigate to={redirectPath} />;
};

export const AdminOrSpaceAdminRoute = () => {
  const { roles } = useSelector(getUserData);
  if (!roles) {
    return <Navigate to={ROUTES.AUTH} />;
  }

  return <Outlet />;
};
