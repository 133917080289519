import { CourseCardProps } from '../types';
import useGetCourseProgressChapters from 'hooks/useGetCourseProgressChapters';
import clsx from 'clsx';
import { useDisplay, useGetCurrentTheme, useGetItemGetterByKey } from 'hooks';

import { LazyImage } from 'shared/ui/lazyImage';
import MiniTabsProgressBar from 'shared/ui/progress-bar/MiniTabs';
import { CourseCardMenu } from '../CourseCardMenu';
import { CourseCardMiniMobile } from './mobile';

import { ReactComponent as Clock } from 'assets/icons/clock.svg';

import * as Styled from './styles';
import { CourseCardRewardsTooltip } from '../utils';
import { useRef, useState } from 'react';
import CourseCardHoverMenu from '../../CourseCardHoverMenu';

export const CourseCardMini = (props: CourseCardProps) => {
  const theme = useGetCurrentTheme();
  const ref = useRef(null);
  const { isMobile } = useDisplay();
  const {
    isAnyLoading,
    cover,
    courseData,
    isEditMode,
    isFavourite,
    isInDev,
    isLoading,
    className,
    onClick,
    toggleFav,
    handleImageUpload,
    handleToggleDev,
    handleOpenConfirmationModal,
    handleOpenEditModal,
    handleDuplication,
    isInSpace,
    openEditorInSpace,
    isSpaceAdmin,
    spaceId,
    isAdmin,
  } = props;
  const { name, estimatedTime, rewards = [] } = courseData;
  const [imageLoaded, setImageLoaded] = useState(false);
  const time = estimatedTime.toString().replace('hours', 'hr').replace('minutes', 'min');
  const { completed, total } = useGetCourseProgressChapters({ course: props.courseData });
  const getItem = useGetItemGetterByKey();
  const [menuVisible, setMenuVisible] = useState(false);

  const handleOpen = () => {
    if ((isAdmin || (isInSpace && isSpaceAdmin)) && isEditMode) {
      setMenuVisible(true);
    }
  };
  const handleClose = () => setMenuVisible(false);

  return isMobile ? (
    <CourseCardMiniMobile {...props} />
  ) : (
    <Styled.Container
      className={clsx(className, theme)}
      onMouseEnter={handleOpen}
      onMouseLeave={handleClose}
      style={{ marginRight: menuVisible ? '50px' : 0, transition: 'margin 0.3s' }}
    >
      <CourseCardMenu
        handleDuplication={handleDuplication}
        className={'menu'}
        isLoading={isLoading}
        isAnyLoading={isAnyLoading}
        isFavourite={isFavourite}
        isEditMode={isEditMode}
        isInDev={isInDev}
        toggleFav={toggleFav}
        handleToggleDev={handleToggleDev}
        handleOpenConfirmationModal={handleOpenConfirmationModal}
        handleImageUpload={handleImageUpload}
        handleOpenEditModal={handleOpenEditModal}
        isInSpace={isInSpace}
        openEditorInSpace={openEditorInSpace}
        isSpaceAdmin={isSpaceAdmin}
      />
      {isEditMode && (
        <CourseCardHoverMenu
          isInSpace={isInSpace}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
          handleOpenEditModal={handleOpenEditModal}
          handleImageUpload={handleImageUpload}
          openEditorInSpace={isSpaceAdmin ? openEditorInSpace : onClick}
          handleDuplication={handleDuplication}
          isSpaceAdmin={isSpaceAdmin}
          courseData={courseData}
          isVisible={menuVisible}
          handleClose={handleClose}
          spaceId={spaceId}
        />
      )}
      <Styled.ImageContainer $imgLoaded={imageLoaded} onClick={onClick}>
        <img onLoad={() => setImageLoaded(true)} src={cover} alt={`${name} course cover`} />
        {/* <MightyLoaderMini className="card-image-loader" /> */}
        <Styled.Skeleton className="card-image-loader" />
      </Styled.ImageContainer>
      <Styled.Body onClick={onClick}>
        <Styled.Progress as={MiniTabsProgressBar} tabsFilled={completed} tabsTotal={total} />
        <Styled.Name className={clsx('tiny-sb')}>{name}</Styled.Name>
        <Styled.Bottom>
          <Styled.Time>
            <Clock />
            {time}
          </Styled.Time>
          <Styled.Rewards ref={ref}>
            {rewards.slice(0, 3).map((rew, index) => {
              const reward = getItem('id', rew.id);
              return <LazyImage key={reward.createdAt.toString() + index} src={reward.imageThumb} alt={reward.name} />;
            })}
          </Styled.Rewards>
          <CourseCardRewardsTooltip triggerRef={ref} label={`Rewards for ${name} Course:`} rewards={rewards} />
        </Styled.Bottom>
      </Styled.Body>
    </Styled.Container>
  );
};
