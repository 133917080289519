import { useGetLeftOffLessonQuery } from 'store';
import styled from 'styled-components';
import * as S from '../../styles';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as PlayIcon } from 'assets/icons/playAudio.svg';
import { Environment } from 'app/constants';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

const Container = styled.div`
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
`;

const LeftOffLessonCompact = () => {
  const { data: lesson, isLoading } = useGetLeftOffLessonQuery();
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (lesson) {
      const { chapterId, lessonId, courseId } = lesson;
      navigate(`/courses/${courseId}/${chapterId}/${lessonId}`);
    }
  }, [lesson]);

  if (isLoading) {
    return <MightyLoaderMini style={{ width: 48, minWidth: 48, height: 48, marginBottom: 23 }} />;
  }
  if (!lesson) {
    return null;
  }
  return (
    <S.ButtonWrapper onClick={handleClick}>
      <Container style={{ backgroundImage: `url(${Environment.StaticHostPath + lesson.courseImageCover})` }}>
        <PlayIcon />
      </Container>
      <S.Sub>Continue</S.Sub>
    </S.ButtonWrapper>
  );
};

export default LeftOffLessonCompact;
