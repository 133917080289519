import { useSelector } from 'react-redux';
import { getUserData } from 'store';
import InventoryItem from '../../../../../../library/cards/inventory/InventoryItem';
import { GRADES } from 'app/constants';
import { InventoryGradeInfo } from './InventoryGradeInfo/InventoryGradeInfo';

import styles from './styles.module.css';

interface IProps {
  itemId: number;
  label: GRADES;
}

export const InventoryInfoItem = ({ itemId, label }: IProps) => {
  const {
    exp: { items },
  } = useSelector(getUserData);
  const item = items.find((i) => i.id === itemId);

  return (
    <div className={styles.item}>
      <InventoryGradeInfo label={label} />
      <InventoryItem amount={item?.quantity ?? 0} itemId={itemId} />
    </div>
  );
};
