import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    aspect-ratio: 1 / 1;
    width: 40px;
    height: 40px;

    img {
      object-fit: contain;
      width: 100%;
      height: 100%;
    }
  `
);
