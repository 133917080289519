import * as S from './styles';
import { Tag } from 'shared/ui';
import { EGroupTag, THEMES } from 'app/constants';

type Props = {
  tags: EGroupTag[];
  handleTagSelect: (arg: EGroupTag) => void;
  in2cols?: boolean;
  theme?: THEMES;
};

const TagsPicker = ({ handleTagSelect, tags, in2cols, theme }: Props) => {
  return (
    <S.TagsGrid $in2cols={in2cols}>
      {Object.values(EGroupTag).map((el) => (
        <Tag key={el} theme={theme} label={el} onClick={() => handleTagSelect(el)} isActive={tags.includes(el)} />
      ))}
    </S.TagsGrid>
  );
};

export default TagsPicker;
