import { FC } from 'react';
import { ESelectionInputType, TSelectionInputProps } from './types';
import { MightyCheckboxRadio } from 'shared/study-ui/checkbox-radio/mighty-checkbox-radio';

import styles from './styles.module.css';

const SelectionInput: FC<TSelectionInputProps> = ({ type, label, checked, additionalInfo, onChange }) => {
  return (
    <MightyCheckboxRadio
      label={label?.toString() ?? ''}
      onChange={onChange}
      type={type === ESelectionInputType.CHECKBOX ? 'checkbox' : 'radio'}
      checked={checked}
    >
      {additionalInfo && <div className={styles.additionalInfo}>{additionalInfo}</div>}
    </MightyCheckboxRadio>
  );
};

export default SelectionInput;
