import { IResultCourse, ISlideFull } from 'interface/courses';
import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import Lesson from '../../../lessons/Lesson/Lesson';
import TestFrame from '../../../tests/TestFrame/TestFrame';
import { useGetMappedContent } from '../../../lessons/hooks/useGetMappedContent';
import * as S from './styles';
import { useGetIsAuth, useNavigateToAuth, useObserver } from 'hooks';
import { ESlideWidgetTypes, IRewardMinimal } from 'store';
import { MaterialPageContext } from '../../../materialPageContext';
import { useSlideMethods } from '../../hooks';
import ClaimRewardsButton from '../claimRewards';
import clsx from 'clsx';
import { TaskRewards } from '../claimRewards/task/taskRewards';
import { defaultState, stateReducer } from './state';

interface Props {
  slide: ISlideFull;
  testsSlidesIds: string[];
  completedSlides: string[];
  resultCourse: IResultCourse | null;
  rewardedSlidesIds: string[];
  setResultCourse: (arg: IResultCourse) => void;
  handleEnergyModal: (val: boolean) => void;
  setIsLoading: (val: boolean) => void;
  addClaimedRewards: (arg: IRewardMinimal[]) => void;
  startSlideUnlockAnimation: () => Promise<void>;
}

const Slide = ({
  slide,
  testsSlidesIds,
  completedSlides,
  resultCourse,
  setResultCourse,
  handleEnergyModal,
  setIsLoading,
  addClaimedRewards,
  startSlideUnlockAnimation,
  rewardedSlidesIds,
}: Props) => {
  const isAuth = useGetIsAuth();
  const navToAuth = useNavigateToAuth();

  // STATE
  const isCompletingRef = useRef(false);
  const [state, updateState] = useReducer(stateReducer, defaultState);
  const { completedTasksCount, isRight, isVisible, tasksLeft } = state;

  // ACTIONS
  const updateRight = (isRight: boolean | null) => updateState({ isRight });
  const setVisible = () => updateState({ isVisible: true });
  const setCompletedTasksCount = () => updateState({ type: 'TASKS_COUNT' });
  const setTasksLeft = (payload: number) => updateState({ type: 'TASKS_LEFT', payload });

  // HOOK METHODS
  const { completeInfoSlide, completeTestSlide, isLoading, resultSlide } = useSlideMethods({
    addClaimedRewards,
    completedSlides,
    handleEnergyModal,
    resultCourse,
    setIsLoading,
    setResultCourse,
    slide,
    updateRight,
    isSlideRewarded: !!rewardedSlidesIds.includes(slide._id),
  });

  // MAPPED CONTENT
  const { mappedContent } = useGetMappedContent({
    slide,
    incrementCompletedTasksCount: async () => setCompletedTasksCount(),
    setTasksLeft,
  });

  // MEMOES
  const Content = useMemo(() => (slide.typeOfSlide === 'Info' ? Lesson : TestFrame), [slide]);
  const isTask = useMemo(
    () => !!slide.content.find((el) => el.isRequired && el.type === ESlideWidgetTypes.TASK) && slide.typeOfSlide === 'Info',
    [slide]
  );
  const isTaskCompleted = useMemo(() => (isTask ? completedTasksCount >= tasksLeft : true), [tasksLeft, completedTasksCount]);
  const isCompleted = completedSlides.includes(slide._id);
  const isRewarded = rewardedSlidesIds.includes(slide._id);
  const isOptionalTest = slide.slideType.isOptional && slide.typeOfSlide !== 'Info';
  const isForceLoading = useMemo(() => {
    if (!isAuth) return false;
    if (!resultSlide) return true;
    if (isOptionalTest && !isCompleted) return true;
    return false;
  }, [isOptionalTest, resultSlide, isAuth, isCompleted]);
  const isShowRewardButton = useMemo(
    () =>
      isVisible && completedSlides.includes(slide._id) && !!slide.rewards.length && !isRewarded && !tasksLeft && !testsSlidesIds.includes(slide._id),
    [isVisible, completedSlides, slide, isRewarded, tasksLeft, testsSlidesIds]
  );

  // OBSERVER HANDLER
  const handler = useCallback(
    (isInView: boolean) => {
      if (isVisible) return;
      if (isInView) setVisible();
    },
    [isVisible]
  );

  const setObserverRef = useObserver({ handler });

  const handleAnswer = useCallback(
    async (userAnswers: string[]) => {
      if (!isAuth) {
        navToAuth();
        return;
      }
      if (isForceLoading) return;
      await completeTestSlide(userAnswers, startSlideUnlockAnimation);
    },
    [isAuth, slide, isForceLoading]
  );

  // COMPLETE INFO SLIDE OR OPTIONAL TEST W/OUT (NEEDED TO BE ABLE TO SKIP IT)
  useEffect(() => {
    const allowForOptionalTest = isOptionalTest && resultSlide
    if (isVisible && (!isForceLoading || allowForOptionalTest) && !isCompletingRef.current) {
      const treatLikeInfo = (slide.typeOfSlide === 'Info' || isOptionalTest) && isTaskCompleted;
      if (treatLikeInfo) {
        isCompletingRef.current = true;
        completeInfoSlide();
      }
    }
  }, [completeInfoSlide, slide, isTaskCompleted, isVisible, isForceLoading, isOptionalTest, resultSlide]);

  return (
    <MaterialPageContext.Provider
      value={{
        isCompleting: isLoading || isForceLoading,
        isInProgress: !completedSlides.includes(slide._id),
        navForward: () => {},
        navigateUser: () => {},
        progressCourse: handleAnswer,
        rewards: [],
        slideId: slide._id,
        hideButtonOnRight: true,
      }}
    >
      <S.Container id={slide._id} ref={setObserverRef} className={clsx({ isVisible })}>
        <Content
          testsIds={testsSlidesIds}
          isRewarded={rewardedSlidesIds.includes(slide._id)}
          slide={{ ...slide, content: mappedContent }}
          right={isRight}
          updateRight={(right: boolean | null) => updateRight(right)}
          isCompleted={completedSlides.includes(slide._id)}
        />
        {}
        {isTask && !!slide.rewards.length && !!resultSlide && (
          <TaskRewards rewards={slide.rewards} disabled={!isTaskCompleted} rewarded={isRewarded} />
        )}
        {isShowRewardButton && !isTask && (
          <ClaimRewardsButton
            resultCourseId={resultCourse?._id || ''}
            setResultCourse={setResultCourse}
            updateRewards={addClaimedRewards}
            slideId={slide._id}
            rewards={slide.rewards}
          />
        )}
      </S.Container>
    </MaterialPageContext.Provider>
  );
};

export default React.memo(Slide);
