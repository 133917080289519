import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import { ReactComponent as Confirm } from 'assets/icons/check-fill-green.svg';
import { ReactComponent as Deny } from 'assets/icons/cross-fill-red.svg';

import styles from './styles.module.css';
import { FaSpinner } from 'react-icons/fa';

type Props = {
  isOpen: boolean;
  onClose: () => any;
  deleteCourse: () => any;
  isLoading: boolean;
  title?: string;
  description?: string;
};

export function ConfirmationModal({ isOpen, isLoading, onClose, deleteCourse, title = 'Delete Course?', description = '' }: Props) {
  const theme = useSelector(getCurrentTheme);

  if (!isOpen) return null;

  return (
    <CenteredModal isClosing={true} closeButtonLocation="outside" show={isOpen} onClose={onClose}>
      <div className={clsx(styles.container, styles[theme], 'glass-div')}>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.subtitle}>{description}</p>
        <div className={styles.buttons}>
          {isLoading ? (
            <FaSpinner className={clsx('spinner', styles.spinner)} />
          ) : (
            <>
              <Confirm className={styles.button} role="button" onClick={deleteCourse} />
              <Deny className={styles.button} role="button" onClick={onClose} />
            </>
          )}
        </div>
      </div>
    </CenteredModal>
  );
}
