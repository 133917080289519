import { USER_ROLES } from 'app/constants';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import BasicButton from 'shared/ui/basic-button/button';
import { getUserData, useDeleteSpaceMutation } from 'store';

type Props = {
  spaceId?: string;
};

const DeleteSpaceButton = ({ spaceId }: Props) => {
  const { roles } = useSelector(getUserData);
  const isAdmin = !!roles && roles.includes(USER_ROLES.ADMIN);
  const [deleteSpace, { isLoading }] = useDeleteSpaceMutation();
  const navigate = useNavigate();

  if (!spaceId || !isAdmin || process.env.NODE_ENV !== 'development') {
    return null;
  }

  const onClick = () => {
    deleteSpace({ spaceId })
      .unwrap()
      .then(() => navigate('/spaces'))
      .catch(console.error);
  };

  return (
    <BasicButton style={{ width: 'fit-content', opacity: 0.3 }} loading={isLoading} variant="primary" onClick={onClick} label="[DEV] Delete space" />
  );
};

export default DeleteSpaceButton;
