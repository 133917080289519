import styled, { css } from 'styled-components';

export const SRoot = styled.button<{ active: boolean }>(
  ({ active, theme }) => css`
    padding: 0;
    border: none;
    outline: none;
    position: relative;
    background: transparent;
    color: ${theme.theme.fontColor};

    &::after {
      content: '';
      position: absolute;
      top: calc(100% + 2px);
      left: 0;
      width: 100%;
      height: 2px;
      background: ${active ? theme.theme.basicBlue : 'transparent'};
    }
  `
);
