import hexToRgba from 'hex-to-rgba';
import styled, { css } from 'styled-components';

export const SActivatedCodeItem = styled.div(
    ({ theme }) => css`
      background-color: ${({ theme }) => hexToRgba(theme.theme.fontColor, 0.1)};
      border-radius: 4px;
      padding: 15px;
      margin-bottom: 10px;
      .column {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .activate-field {
        display: grid;
        grid-template-columns: auto 50px;
        gap: 10px;
      }

      .activate-grey-color {
        color: var(--font-color-strong);
        opacity: 0.4;
      }

      p {
        margin-top: 12px;
      }
    `
  ),
  SCodeHeader = styled.div(
    () => css`
      display: flex;
      height: 50px;
      margin-bottom: 14px;
      img {
        margin-right: 15px;
        width: 50px;
        height: 50px;
        border-radius: 10px;
      }

      .activateCode-image {
        position: relative;

        svg {
          position: absolute;
          top: 0;
          right: 0;
          transform: translate(-60%, -30%);
          width: 16px;
          height: 16px;
        }
      }
    `
  ),
  SCodeField = styled.div(
    () => css`
      background-color: rgba(255, 255, 255, 0.06);
      border-radius: 4px;
      font-size: 16px;
      line-height: 16px;
      font-weight: 400;
      height: 50px;
      align-items: center;
      display: flex;
      padding: 0 20px 0 10px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      p {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    `
  ),
  SCodeFieldButton = styled.button(
    () => css`
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.06);
      border-radius: 4px;
      border: none;
      height: 50px;
      color: #fff;
      transition: background-color 0.2s ease-in-out;
      font-size: 25px;

      &:hover {
        background-color: rgba(71, 77, 115, 0.5);
      }

      svg {
        margin-bottom: -2px;
        color: var(--font-color-strong);
      }

      &.active {
        background-color: #474d73;
      }
    `
  );
