import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      & > *:not(*:last-child) {
        border-bottom: var(--basic-border);
      }
    `
  ),
  Effect = styled.div(
    () => css`
      width: calc(100% + 10px);
    `
  );
