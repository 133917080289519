import styled, { css } from 'styled-components';

export const Button = styled.button(
  () => css`
    border: none;
    outline: none;
    background: none;
    cursor: pointer;
    padding: 0;
    font: inherit;
    color: inherit;
    text-align: inherit;
    text-decoration: none;
    display: inline-block;
    margin: 0;
    line-height: 1;
    border-radius: 0;
    appearance: none;
    user-select: none;
    vertical-align: middle;
    -webkit-tap-highlight-color: transparent;
    -webkit-appearance: none;
    -moz-appearance: none;
  `
);
