import { FC, useCallback, useRef, useState } from 'react';
import { nanoid } from 'nanoid';
import { TDropdownMenuItem } from '../../types';
import { motion } from 'framer-motion';

import { ReactComponent as AngleIcon } from 'assets/icons/angle.svg';

import * as Elements from './styles';

export const DropdownMenuItem: FC<TDropdownMenuItem> = ({ title, menu, icon, onClick, isTopic, letOverflow }) => {
  const [menuOpened, setMenuOpened] = useState(false);
  const $timeout = useRef<number>();

  const handleItemClick = (callback?: () => void) => () => {
    if (isTopic) {
      return;
    }
    callback?.();
    setMenuOpened(false);
  };

  const handleMouseEnter = useCallback(() => {
    window.clearTimeout($timeout.current);
    setMenuOpened(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    $timeout.current = window.setTimeout(() => {
      setMenuOpened(false);
    }, 100);
  }, []);

  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.5 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Elements.SMenuItem onClick={handleItemClick(onClick)} className={`${isTopic ? 'topic' : ''}`}>
        <Elements.SMenuItemContent>
          {icon && <Elements.SMenuItemIcon>{icon}</Elements.SMenuItemIcon>}
          <span>{title}</span>
        </Elements.SMenuItemContent>
        {menu && <AngleIcon style={{ height: '10px' }} />}
      </Elements.SMenuItem>
      {menu && menuOpened && (
        <div
          style={{
            position: 'absolute',
            left: '100%',
            top: menu.bottomAlign ? 'auto' : '0',
            bottom: menu.bottomAlign ? '0' : 'auto',
            paddingLeft: '5px',
            maxHeight: '350px',
            overflow: letOverflow ? 'visible' : 'auto scroll',
          }}
        >
          <Elements.SMenu onScroll={(e) => e.stopPropagation()} onWheel={(e) => e.stopPropagation()}>
            {menu.items.map((item) => (
              <DropdownMenuItem {...item} key={nanoid()} onClick={handleItemClick(item.onClick)} />
            ))}
          </Elements.SMenu>
        </div>
      )}
    </motion.div>
  );
};
