import styled from 'styled-components';

export const SAddComment = styled.div`
    border-radius: 5px 5px 5px 0px;
    background: #afb4b8;
    padding: 6px 12px;
    display: flex;
    align-items: center;
    color: #fff;
  `,
  SText = styled.div`
    font-size: 16px;
    margin-left: 4px;
  `;

export const SContentWrapper = styled.form`
    padding: 16px 9px 15px 0;
    width: 375px;
  `,
  SButtonWrapper = styled.div`
    display: flex;
    margin-top: 20px;
    gap: 16px;

    button {
      width: auto;
      padding: 12px 16px;
      min-height: 44px;

      &:hover {
        padding: 12px 16px;
      }
    }
  `;
