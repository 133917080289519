import { FC } from 'react';
import CoursesConstructorLessonsList from './parts/lessonsList';
import { useGlobalLoader } from '../../shared/utils/GlobalLoader/useGlobalLoader';
import { useParams } from 'react-router-dom';

const CoursesConstructorLessonsPage: FC = () => {
  const { chapterId } = useParams();
  useGlobalLoader({ dependency: [false] });
  return chapterId ? <CoursesConstructorLessonsList chapterIdProp={chapterId} /> : null;
};

export default CoursesConstructorLessonsPage;
