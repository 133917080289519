import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

interface Props extends PropsWithChildren {
  className?: any;
  maxWidth?: number | string;
  maxHeight?: number | string;
}

export const CustomScroll: FC<Props> = ({ className = '', children, maxWidth, maxHeight }) => {
  const currentStyle = () => {
    if (maxWidth && maxHeight) {
      return { maxWidth, maxHeight, overflow: 'scroll' };
    }
    if (maxWidth) {
      return { maxWidth, overflow: 'scroll hidden' };
    }
    if (maxHeight) {
      return { maxHeight, overflow: 'hidden scroll' };
    }
  };

  return (
    <div style={currentStyle()} className={clsx({ [className]: !!className }, 'tiny-sb')}>
      {children}
    </div>
  );
};
