import clsx from 'clsx';
import { useEffect, useReducer } from 'react';
import { ILeftOffLesson } from './types';
import { useLazyGetLeftOffLessonByCourseIdQuery, useLazyGetLeftOffLessonQuery } from 'store';
import { useNavigate, useParams } from 'react-router-dom';
import { STATIC_HOST_PATH } from 'app/constants/path';
import useAverageColorFromImageUrl from 'hooks/useAverageColorFromImageUrl';
import { ROUTES } from 'app/constants';

import BasicButton from 'shared/ui/basic-button/button';
import InventoryItem from 'components/library/cards/inventory/InventoryItem';
import { ProfileCourseCard } from 'shared/ui/course-card';
import { MultipurposeBannerLoader } from 'shared/ui';

import { HiArrowRight } from 'react-icons/hi';

import stylesBanner from './stylesBanner.module.scss';
import stylesPanel from './stylesPanel.module.scss';

interface State {
  isLoading: boolean;
  isActive: boolean;
  isSeeLessons: boolean;
  leftOff: ILeftOffLesson | null;
}

const defaultState: State = {
  isLoading: true,
  isActive: false,
  leftOff: null,
  isSeeLessons: false,
};

interface IProps {
  isActive?: boolean;
  className?: string;
  isBannerOnly?: boolean;
  isPanelOnly?: boolean;
  onMPBannerClose: () => void;
  givenCourseId?: string | null;
}

export default function LeftOffLesson({
  className = '',
  isBannerOnly = false,
  isPanelOnly = false,
  onMPBannerClose,
  isActive,
  givenCourseId,
}: IProps) {
  const [getLeftOffLessonByCourseId] = useLazyGetLeftOffLessonByCourseIdQuery();
  const [getLeftOffLesson] = useLazyGetLeftOffLessonQuery();
  const { courseId: currentCourseId } = useParams();
  const navigate = useNavigate();
  const [state, updateState] = useReducer((prev: State, next: Partial<State>) => ({ ...prev, ...next }), defaultState);
  const color = useAverageColorFromImageUrl(STATIC_HOST_PATH + (state.leftOff?.courseImageCover ?? ''));

  useEffect(() => {
    (async () => {
      const id = givenCourseId ?? currentCourseId;
      let leftOffLesson;
      if (id) {
        leftOffLesson = await getLeftOffLessonByCourseId(id)
          .unwrap()
          .then((res) => structuredClone(res))
          .catch(onMPBannerClose);
      } else {
        leftOffLesson = await getLeftOffLesson()
          .unwrap()
          .then((res) => structuredClone(res))
          .catch(onMPBannerClose);
      }
      if (leftOffLesson?.lessonId) {
        updateState({ leftOff: leftOffLesson, isLoading: false });
      } else {
        onMPBannerClose();
      }
    })();
    return () => updateState(defaultState);
  }, [currentCourseId, getLeftOffLessonByCourseId, getLeftOffLesson, givenCourseId, onMPBannerClose]);

  const { leftOff, isLoading } = state;

  useEffect(() => {
    if (!isLoading && !leftOff?.courseId && isActive) {
      onMPBannerClose();
    }
  }, [isLoading, leftOff?.courseId, isActive, onMPBannerClose]);

  if (isLoading) {
    return <MultipurposeBannerLoader className={clsx(stylesBanner.loader, { [stylesBanner.bannerOnly]: isBannerOnly })} />;
  }

  if (!leftOff) {
    return null;
  }

  const { chapterId, index, lessonId, lessonName, courseId, courseImageCover, courseName, rewards, completion } = leftOff;

  const cover = STATIC_HOST_PATH.concat(courseImageCover);

  function handleNavToLesson() {
    navigate(`${ROUTES.COURSES}/${courseId}/${chapterId}/${lessonId}`);
  }

  const courseNameCut = courseName?.length < 30 ? courseName : courseName?.slice(0, 30) + '...';

  return (
    <>
      <div
        className={clsx(stylesBanner.container, {
          [stylesBanner.bannerOnly]: isBannerOnly && !isPanelOnly,
          [stylesBanner.panelOnly]: !isBannerOnly && isPanelOnly,
        })}
      >
        <div className={stylesBanner.cover}>
          <img src={cover} alt={courseName} />
        </div>
        <div className={stylesBanner.body}>
          <div className={stylesBanner.info}>
            <p className={stylesBanner.header}>Continue where you left off</p>
            <p className={stylesBanner.text}>
              <span style={{ color }}>{courseNameCut}</span>
            </p>
            <p className={stylesBanner.subtext}>Hint: spend your energy everyday to receive more rewards</p>
          </div>
          <BasicButton
            style={{ background: color?.replace(/,1\)/, ',0.1)') }}
            isLabelFirst
            onClick={handleNavToLesson}
            className={stylesBanner.button}
            label="Continue"
            icon={<HiArrowRight />}
            variant="cool"
          />
        </div>
      </div>
      <div
        className={clsx(stylesPanel.container, {
          [stylesPanel.bannerOnly]: isBannerOnly && !isPanelOnly,
          [stylesPanel.panelOnly]: !isBannerOnly && isPanelOnly,
        })}
      >
        <h4 className={stylesPanel.header}>Continue where you left off:</h4>
        <ProfileCourseCard
          className={stylesPanel.coursecard}
          _id={lessonId}
          completion={completion}
          name={courseName}
          imageCover={courseImageCover}
        />
        <p className={stylesPanel.text}>
          <span style={{ color }}>{courseName}</span> | Lesson {index + 1}: {lessonName}
        </p>
        <div className={stylesPanel.rewardsWrap}>
          <p>Rewards:</p>
          <div className={clsx(stylesPanel.rewards, 'tiny-sb')}>
            {rewards.map((reward, index) => (
              <InventoryItem key={'left-off-reward' + index} isShowTooltip={false} itemId={reward.id} amount={reward.quantity} isSmall />
            ))}
          </div>
        </div>
        <div className={stylesPanel.buttons}>
          {/* <BasicButton className={stylesPanel.button} isLabelFirst onClick={handleSeeLessons} label="See Lessons" variant="outline" /> */}
          <BasicButton className={stylesPanel.button} isLabelFirst onClick={handleNavToLesson} label="Continue" variant="cool" />
        </div>
      </div>
    </>
  );
}
