import { MouseEvent, forwardRef, useState } from 'react';
import styles from './styles.module.css';
import useClickOutside from 'hooks/useClickOutside';
import clsx from 'clsx';
import { DropDownItem } from './dropdown/dropdownItem';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { BsChevronUp } from 'react-icons/bs';
import { LazyImage } from '../lazyImage';

interface ISelectProps {
  options: Array<{ label: string; value: string; icon?: any; background?: string }>;
  placeholder?: string;
  className?: string;
  variant?: 'round' | 'flat' | 'filled';
  menu?: 'list' | 'panel';
  icon?: any;
  value: string;
  onChange: (value: string) => void;
  defaultValue?: string;
  fullWidth?: boolean;
  color?: 'red';
  detailed?: boolean;
}

export const Select = forwardRef(
  (
    {
      options,
      placeholder = 'Choose value',
      variant = 'round',
      menu = 'list',
      icon,
      className = '',
      value,
      onChange,
      defaultValue,
      fullWidth = false,
      color,
      detailed = false,
    }: ISelectProps,
    ref
  ) => {
    const theme = useSelector(getCurrentTheme);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const { label, icon: GivenIcon = '', background = '' } = options.find((i) => i.value === (value || defaultValue)) || {};

    const openDropDown = (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation();
      setIsOpen(!isOpen);
    };

    const closeDropDown = () => {
      setIsOpen(false);
    };

    const onClickHandler = (value: string) => {
      onChange(value);
      setIsOpen(false);
    };

    const elementRef = useClickOutside<HTMLDivElement>(closeDropDown);

    return (
      <div
        className={clsx(styles.container, {
          [className]: !!className,
          [styles.active]: isOpen,
          [styles.round]: variant === 'round',
          [styles.flat]: variant === 'flat',
          [styles.filled]: variant === 'filled',
          [styles.fullWidth]: fullWidth,
          [styles.red]: color === 'red',
        })}
        ref={elementRef}
        onClick={openDropDown}
      >
        <div className={`glass-div basic ${theme} ${styles.select} dropdown-select`}>
          {GivenIcon &&
            (typeof GivenIcon === 'string' ? (
              <LazyImage src={GivenIcon} className={styles.givenIcon} alt="" />
            ) : (
              <GivenIcon className={styles.givenIcon} />
            ))}
          {label || placeholder}
          {background && <div className={styles.background} style={{ background }} />}
        </div>
        {!icon ? (
          <BsChevronUp size={15} color="var(--font-color)" className={styles.icon} />
        ) : (
          <LazyImage className={styles.icon} src={icon} alt="" />
        )}
        <div
          className={clsx(styles.wrapper, {
            [styles.hidden]: !isOpen,
            [styles.panel]: menu === 'panel',
            [styles.list]: menu === 'list',
          })}
        >
          <div className={clsx(`dropdown-list glass-div basic ${theme} ${styles.dropdown}`)}>
            {options.map((option, index) => {
              const selectedValue = option.value === (value || defaultValue);
              return <DropDownItem key={index} option={option} onClick={onClickHandler} selectedValue={selectedValue} detailed={detailed} />;
            })}
          </div>
        </div>
      </div>
    );
  }
);
