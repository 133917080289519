import clsx from 'clsx';
import { motion } from 'framer-motion';
import { IEffectIndicatorProps } from '../types';
import { useGetEffectsColorData } from '../data';

import { CircleProgress } from 'shared/ui/progress-bar/circle-progress';

import * as Styled from './styles';

export const Amount = ({
  type = 'energy',
  className = '',
  zIndex,
  isClickable = false,
  onClick,
  amount = 0,
  showAmount = false,
}: Pick<IEffectIndicatorProps, 'amount' | 'onClick' | 'zIndex' | 'isClickable' | 'className' | 'type' | 'showAmount'>) => {
  const { color, image } = useGetEffectsColorData(type);

  const handleClick = () => {
    if (isClickable && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <Styled.Container
      className={clsx(type, { [className]: !!className, clickable: isClickable })}
      style={{ zIndex }}
      role="button"
      onClick={handleClick}
    >
      <CircleProgress
        circle={{
          color,
        }}
        className="countdown"
        percent={amount}
      />
      {showAmount ? (
        <Styled.Amount as={motion.div} initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
          {amount}
          <span>%</span>
        </Styled.Amount>
      ) : (
        <Styled.ItemImage className={clsx(type)} src={image} />
      )}
    </Styled.Container>
  );
};
