import styled, { css } from 'styled-components';
import { EItemFungibleType, EServiceEffect } from 'interface';

export const Container = styled.div(
    ({ theme }) => css`
      height: 100%;
      position: relative;
      aspect-ratio: 1 / 1;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      background: none;
      border-radius: 100%;

      &.${EServiceEffect.ENERGY}, &.${EItemFungibleType.SmallEnergyPotion}, &.${EItemFungibleType.SmallEnergyPotion} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          rgb(41, 171, 136) 0%,
          ${!theme.dark ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.${EServiceEffect.NOENERGY} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #f14f63 0%,
          ${!theme.dark ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.${EServiceEffect.NOPREMIUM} {
        background: radial-gradient(
          50% 50% at 50% 50%,
          #ffbf66 0%,
          ${!theme.dark ? 'rgb(225, 225, 225)' : 'rgb(50, 50, 50)'} calc(85%),
          transparent calc(90%)
        );
      }
      &.clickable {
        cursor: pointer;
      }
      .countdown {
        position: absolute;
        top: 0;
        left: 0;
      }
    `
  ),
  ItemImage = styled.img`
    height: 100%;
    width: auto;
    padding: 5px;
    &.${EServiceEffect.ENERGY}, &.${EServiceEffect.NOENERGY} {
      padding: 25%;
    }
  `,
  Amount = styled.div`
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -0.5px;

    span {
      color: #fff !important;
      font-size: 10px;
      font-weight: 500;
    }
  `;
