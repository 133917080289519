import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { ListOfCodes } from '../../listCodes';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}
export const DesktopModalActivationCode = ({ isOpen, onClose }: IProps) => {
  const theme = useSelector(getCurrentTheme);

  return (
    <CenteredModal zIndex={[1200, 1100]} show={isOpen} onClose={onClose}>
      <div style={{ width: '600px', padding: '0 12px 12px 12px' }} className={`menu-main-wrap glass-div basic ${theme} strong`}>
        <ListOfCodes />
      </div>
    </CenteredModal>
  );
};
