import { useState } from 'react';

import CheckAnswerButton from '../utils/CheckAnswerButton/CheckAnswerButton';
import { NodesView } from './NodesView';

import styles from './styles.module.css';
import { AnswerEditorStyles } from 'components/library/courseConstructor/components/TestConstructorContainer/components/InfoSlideTypeForm/components/AnswersEditor/styles';
import { useGetCustomNav } from '../utils/useGetCustomNav';

const Fillspaces = ({ slideId, nodes, right, updateRight, elementWithCondition }) => {
  const [userInput, setUserInput] = useState([]);

  const handleAnswerChange = (idx, value) => {
    setUserInput((prev) => {
      const arrClone = structuredClone(prev);
      arrClone[idx] = value?.trim();
      return arrClone;
    });
  };

  const buttonElement = useGetCustomNav(
    <CheckAnswerButton
      className="lesson-button"
      isActive={Boolean(userInput[0])}
      userAnswers={userInput}
      slideId={slideId}
      right={right}
      updateRight={updateRight}
    />,
    elementWithCondition
  );

  return (
    <>
      <div className={styles.root}>
        <div className="lesson-content-space">
          <AnswerEditorStyles />
          <NodesView rootNode={nodes} className="inline-answers-v2" onAnswerChange={handleAnswerChange} right={right} updateRight={updateRight} />
        </div>
      </div>
      {buttonElement}
    </>
  );
};

export default Fillspaces;
