import { useState } from 'react';

import { CircleButton } from 'shared/ui/circle-button';

import { ReactComponent as VolumeMedium } from 'assets/icons/volume-medium.svg';
import { ReactComponent as Volume0 } from 'assets/icons/volume-0.svg';
import { ReactComponent as VolumeMax } from 'assets/icons/volume-max.svg';
import { SControlWrapper } from '../../style/controls';

import { motion } from 'framer-motion';
import { useTextToSpeech } from '../../utils/useTextToSpeech';
import { RangeSlider } from 'shared/ui/RangeSlider';
import { dispatch, useDebounce } from 'hooks';
import { getLessonPageVolume, setLessonPageVolume } from 'store';
import { useSelector } from 'react-redux';

export const VolumeTextToSpeech = ({ $larger = false }: { $larger?: boolean }) => {
  const [isOpen, setIsOpen] = useState(false);
  const lessonPageVolume = useSelector(getLessonPageVolume);
  const debounce = useDebounce();

  const volume = lessonPageVolume / 100; // To be able to move back to TTS too

  // const { settings, handleVolumeChange: handleChangeTTSVolume } = useTextToSpeech();

  // const { volume } = settings;

  const getCurrentIcon = () => {
    if (volume <= 0.05) {
      return <Volume0 />;
    }
    if (volume <= 0.5) {
      return <VolumeMedium />;
    }
    return <VolumeMax />;
  };

  function handleToggleVolume() {
    if (volume) {
      handleVolumeChange(0);
    } else {
      handleVolumeChange(1);
    }
  }

  const handleVolumeChange = debounce((volume: number) => {
    // from 0 to 1
    // handleChangeTTSVolume(volume);
    dispatch(setLessonPageVolume(volume * 100));
  }, 100);

  return (
    <SControlWrapper $larger={$larger} as={motion.div} $isOpen={isOpen} onHoverStart={() => setIsOpen(true)} onHoverEnd={() => setIsOpen(false)}>
      <motion.div
        style={{ marginRight: 'auto', overflow: 'hidden' }}
        initial={{ opacity: 0, width: 0 }}
        animate={isOpen ? { opacity: 1, width: 55 } : { opacity: 0, width: 0 }}
      >
        <RangeSlider
          knobColor={'#fff'}
          progressColor={'#627EEA'}
          knobSize={10}
          myValue={volume}
          height={2}
          min={0}
          max={1}
          onChange={handleVolumeChange}
          withKnob
          widthSlider={45}
        />
      </motion.div>
      <CircleButton icon={getCurrentIcon()} onClick={() => handleToggleVolume()} />
    </SControlWrapper>
  );
};
