import clsx from 'clsx';
import moment from 'moment';
import { useEffect, useState } from 'react';

type Props = {
  from: number;
  className?: string;
};

export default function CountDown({ from = 0, className = '' }: Props) {
  const [clock, setClock] = useState(from || 0);

  useEffect(() => {
    const timeout = setInterval(() => {
      if (clock > 0) {
        clock - 1000 > 0 ? setClock((prev) => prev - 1000) : setClock(0);
      }
    }, 1000);
    return () => clearInterval(timeout);
  }, [clock]);

  const formattedTime = moment(clock);
  let formatArray: string[] = [];

  if (formattedTime.hours() > 0) formatArray.push('HH [hours]');

  if (formattedTime.hours() > 0 || formattedTime.minutes() > 0) formatArray.push('mm [minutes]');

  if (formattedTime.hours() <= 0 || formattedTime.minutes() <= 0) formatArray.push('ss [seconds]');

  const formatString = formatArray.join(', ');

  const formattedOutput = formattedTime.format(formatString);

  return <span className={clsx({ [className]: !!className })}>{formattedOutput}</span>;
}
