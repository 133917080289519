import styles from './styles.module.css';
import { ICheckCodeForm } from 'interface';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { BasicButtonV1, NullButton } from 'shared/ui';
import { CodeInput, Error } from 'features/auth/ui';
import { useTimer } from 'hooks';
import { useTranslation } from 'react-i18next';

interface IProps {
  isLoading: boolean;
  error: string;
  onSubmitHandler: (data: ICheckCodeForm) => void;
  getCodeAgainHandler: () => Promise<void>;
}

export const CheckCodeForm = ({ isLoading, onSubmitHandler, error, getCodeAgainHandler }: IProps) => {
  const { t } = useTranslation('auth');
  const { seconds, minutes, startAgainHandler } = useTimer(1, 60);
  const schema = yup.object().shape({
    resetCode: yup.string().required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ICheckCodeForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ICheckCodeForm) => {
    onSubmitHandler(data);
  };

  const getCodeAgain = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (!time && startAgainHandler) {
      getCodeAgainHandler().then(() => startAgainHandler());
    }
  };

  const time =
    minutes !== 0 || seconds !== 0 ? `${minutes}${t('resetPassword.checkCode.time.min')} ${seconds}${t('resetPassword.checkCode.time.sec')}` : null;

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="resetCode"
        control={control}
        render={({ field }) => (
          <CodeInput disabled={isLoading} isValid={!error} containerClassName={styles.inputContainer} {...field} name="resetCode" />
        )}
      />
      <div className={styles.errorContainer}>
        <Error title={errors?.resetCode?.message || error} />
      </div>
      <BasicButtonV1 isLoading={isLoading} className={styles.button} disabled={isLoading || !isValid} type={'submit'}>
        {t('resetPassword.checkCode.form.submit')}
      </BasicButtonV1>
      <div className={styles.timerContainer}>
        {time ? (
          <>
            <span className={styles.timer}>{t('resetPassword.checkCode.time.title')}</span>
            <span className={styles.time}>{time}</span>
          </>
        ) : (
          <NullButton disabled={isLoading} className={styles.sendButton} onClick={getCodeAgain}>
            {t('resetPassword.checkCode.time.sendAgain')}
          </NullButton>
        )}
      </div>
    </form>
  );
};
