import { Suspense } from 'react';
import { Navigate, Outlet, matchPath, useLocation } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { GlobalLoader } from 'shared/utils';

interface IProps {
  isAuth: boolean;
  redirectPath?: string;
}

export const PublicRoute = ({ isAuth, redirectPath = ROUTES.MAIN }: IProps) => {
  const { pathname, state } = useLocation();
  const redirectFrom: string[] = [ROUTES.AUTH, ROUTES.SIGN_UP, ROUTES.LOGIN, ROUTES.RESET_PASSWORD, ROUTES.AUTHEMAIL];
  const isRedirect = isAuth && redirectFrom.some((path) => matchPath(path, pathname));

  return <Suspense fallback={<GlobalLoader isLoading />}>{isRedirect ? <Navigate to={state?.prev ?? redirectPath} /> : <Outlet />}</Suspense>;
};
