import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  gap: 8px;
  margin-bottom: 32px;
  margin-top: 19px;
`;

const bgs = {
  [THEMES.DARK]: {
    active: '#FF674524',
    default: '#2A2D3C',
  },
  [THEMES.LIGHT]: {
    active: '#FF674524',
    default: '#1111110A',
  },
};

export const Top = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
  margin-bottom: 25px;
  color: var(--font-color-strong);
  font-size: 30px;
  line-height: 100%;
  font-weight: 500;
  margin-top: 30px;
`;

export const CourseImg = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 8px;
  object-fit: cover;
`;

export const Box = styled.div<{ $active?: boolean; $theme: THEMES }>`
  display: flex;
  flex-direction: column;
  background: ${(p) => (p.$active ? bgs[p.$theme].active : bgs[p.$theme].default)};
  min-width: 104px;
  width: 104px;
  min-height: 148px;
  height: 148px;
  border-radius: 8px;
  padding: 14px 12px 20px;
  overflow: hidden;
  transition: 0.3s ease-in-out;
  cursor: pointer;
  ${(p) => p.$theme === THEMES.LIGHT && `border: 1px solid ${p.$active ? '#FF6745' : '#627EEA'};`}
  ${(p) => p.$active && 'padding-bottom: 44px;'}
  ${(p) => p.$active && 'border-radius: 8px 8px 4px 4px;'}
  &:hover {
    filter: brightness(1.1);
  }
  position: relative;
`;

export const BoxTitle = styled.div<{ $theme: THEMES }>`
  margin-bottom: auto;
  font-size: 16px;
  color: ${(p) => (p.$theme === THEMES.DARK ? '#fdfdfd66' : '#111111')};
`;

export const IconBox = styled.div<{ $active?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 6px;
  width: fit-content;
  color: ${({ $active }) => ($active ? '#FC6842' : '#6487EF')};
  & > svg {
    width: 24px;
    height: 24px;
    transition: 0.1s;
  }
`;

export const Num = styled.p<{ $theme: THEMES }>`
  font-size: 22px;
  line-height: 100%;
  color: ${(p) => (p.$theme === THEMES.DARK ? '#fdfdfd' : '#111111')};
  font-weight: 500;
`;

export const Bottom = styled.p<{ $theme: THEMES }>`
  width: 100%;
  margin-bottom: 28px;
  padding-bottom: 10px;
  border-bottom: 2px solid ${(p) => (p.$theme === THEMES.LIGHT ? '#1111111a' : '#ffffff1a')};
  font-size: 20px;
  font-weight: 400;
  line-height: 100%;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#fdfdfd66')};
  max-width: 1028px;
`;

export const RequiresCheck = styled(motion.div)`
  width: 100%;
  height: 27px;
  background: #ff6745;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  white-space: nowrap;
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const SlideInfo = styled(motion.div)`
  font-size: 16px;
  color: rgba(253, 253, 253, 0.4);
  width: fit-content;
  padding: 0px 10px;
  height: 36px;
  border-radius: 10px;
  background: rgba(255, 255, 255, 0.04);
`;
