import { useEffect, useMemo, useReducer } from 'react';
import { useToggleFavouriteMutation, coursesConstructorAPI } from 'store';
import { ICourse } from 'interface/courses';
import { STATIC_HOST_PATH } from 'app/constants/path';
import { toast } from 'react-toastify';
import { useDisplay, useGetCurrentTheme, useGetIsAuth, useGetUserData, useNavigateToAuth } from 'hooks';
import clsx from 'clsx';
import { toBase64 } from 'shared/utils/files/toBase64';
import { USER_ROLES } from 'app/constants';

import { CourseForm } from 'features/coursesEditor';
import { ConfirmationModal } from './ConfirmationModal';
import { CourseCardMini } from './CourseCardMini';
import Answer from 'components/library/messages/Answer';

import styles from './styles.module.scss';

interface IProps {
  course: ICourse;
  onClick?: () => any;
  className?: string;
  classNameMobile?: string;
  customLabel?: string;
  isEditMode?: boolean;
  selectCourse?: (course: ICourse) => void;
  isInSpace?: boolean;
  openEditorInSpace?: () => void;
  isSpaceAdmin?: boolean;
  onAfterDelete?: () => void;
  spaceId?: string;
}

interface IState {
  course: ICourse;
  confirmModal: boolean;
  isOpenEditModal: boolean;
  isInDev: boolean;
  isFavourite: boolean;
}

export const CourseCard = ({
  course: courseGiven,
  onClick,
  className = '',
  classNameMobile = '',
  customLabel = '',
  isEditMode = false,
  selectCourse,
  isInSpace,
  openEditorInSpace,
  isSpaceAdmin,
  onAfterDelete,
  spaceId,
}: IProps) => {
  const { favouriteCourses = [], roles, _id: userId } = useGetUserData(true);
  const theme = useGetCurrentTheme();
  const isAuth = useGetIsAuth();
  const { isMobile } = useDisplay();
  const navToAuth = useNavigateToAuth();
  const [toggleFavourite, { isLoading }] = useToggleFavouriteMutation();
  const [deleteCourse, { isLoading: isDeleting }] = coursesConstructorAPI.useDeleteCourseMutation();
  const [toggleDev, { isLoading: isDevToggling }] = coursesConstructorAPI.useToggleIsInDevMutation();
  const [duplicateCourse] = coursesConstructorAPI.useDuplicateCourseLazyMutation();
  const isAnyLoading = isLoading || isDeleting || isDevToggling;
  const [update] = coursesConstructorAPI.useUpdateCourseImageMutation();
  const [{ confirmModal, course, isFavourite, isInDev, isOpenEditModal }, updateState] = useReducer(
    (prev: IState, next: Partial<IState>) => ({ ...prev, ...next }),
    {
      course: courseGiven,
      confirmModal: false,
      isOpenEditModal: false,
      isInDev: false,
      isFavourite: false,
    }
  );

  const { completion = 0, _id, imageCover, imageBanner, isInDevelopment } = course;

  const cover = useMemo(() => {
    if (!imageCover && !imageBanner) {
      return '';
    }

    return `${STATIC_HOST_PATH}${imageCover || imageBanner}`;
  }, [imageCover, imageBanner]);

  useEffect(() => {
    if (userId) {
      updateState({
        isInDev: isInDevelopment,
        isFavourite: favouriteCourses.includes(_id),
      });
    }
  }, [favouriteCourses, isInDevelopment, _id, userId]);

  const isStarted = completion > 0;

  const isAdmin = roles?.includes(USER_ROLES.ADMIN);

  const toggleFav = async () => {
    if (!isAuth) {
      navToAuth();
    }
    updateState({ isFavourite: !isFavourite });
    await toggleFavourite({ courseId: _id })
      .unwrap()
      .catch(() => {
        updateState({ isFavourite: !isFavourite });
      });
  };

  const openDetails = () => {
    if (selectCourse) {
      selectCourse(course);
    }
  };

  const handleCourseDeletion = () =>
    deleteCourse(_id)
      .unwrap()
      .then(handleCloseConfirmationModal)
      .catch((ex) => {
        console.error(ex);
        toast.error(<Answer label="Oops" subtext="couldn't remove course, check the console" type="incorrect" />);
      })
      .finally(() => (onAfterDelete ? onAfterDelete() : {}));

  const handleToggleDev = () => {
    const currentInDev = isInDev;
    updateState({ isInDev: !currentInDev });

    setTimeout(() => {
      toggleDev(_id)
        .unwrap()
        .catch((ex) => {
          console.error(ex);
          toast.error(<Answer label="" subtext={ex?.data?.message || 'You are unauthorized for this...'} type="incorrect" />);
          updateState({ isInDev: currentInDev });
        });
    }, 300);
  };

  const handleOpenConfirmationModal = () => {
    updateState({ confirmModal: true });
  };

  const handleOpenEditModal = () => {
    updateState({ isOpenEditModal: !isOpenEditModal });
  };

  const handleCloseEditModal = () => {
    updateState({ isOpenEditModal: false });
  };

  const handleSubmitEditModal = (newCourse?: Partial<ICourse>) => {
    if (newCourse) {
      updateState({
        isOpenEditModal: false,
        course: { ...course, ...newCourse },
      });
    } else {
      handleCloseEditModal();
    }
  };

  const handleCloseConfirmationModal = () => {
    updateState({ confirmModal: false });
  };

  const handleDuplication = () => {
    duplicateCourse(_id);
  };

  const handleImageUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.currentTarget?.files?.length) {
      return;
    }
    const file = e.currentTarget.files[0];
    if (file) {
      const sizeInKb = file.size / 1024;
      const maxSize = 1024 * 3;
      if (sizeInKb > maxSize) {
        toast.error(<Answer label="File is too large" subtext="Max size is 3mb" type="incorrect" />);
      } else {
        const base64Image = await toBase64(file);
        if (base64Image)
          await update({ id: _id, image: base64Image })
            .unwrap()
            .then((res) => updateState({ course: { ...course, imageCover: res.imageCover, imageLogo: res.imageLogo } }));
      }
    }
  };

  const courseCardMininum = {
    isAnyLoading: isAnyLoading,
    isLoading: isLoading,
    isDeleting: isDeleting,
    isFavourite: isFavourite,
    isEditMode: isEditMode,
    isInDev: isInDev,
    isStarted: isStarted,
    courseData: course,
    customLabel: customLabel,
    cover: cover,
    isInSpace,
    spaceId,
    isAdmin,
    isSpaceAdmin: false,
    toggleFav: toggleFav,
    onClick: onClick ? onClick : openDetails,
    openDetails: openDetails,
    handleImageUpload,
    handleDuplication,
    handleToggleDev: () => {},
    handleOpenConfirmationModal: () => {},
    handleOpenEditModal: () => {},
    openEditorInSpace: () => {},
  };

  if (isAuth) {
    courseCardMininum.isSpaceAdmin = Boolean(isSpaceAdmin);
    courseCardMininum.handleToggleDev = handleToggleDev;
    courseCardMininum.handleOpenConfirmationModal = handleOpenConfirmationModal;
    courseCardMininum.handleOpenEditModal = handleOpenEditModal;
    if (openEditorInSpace) courseCardMininum.openEditorInSpace = openEditorInSpace;
  }

  // const ComponentIsMobile = isMobile ? CourseCardMobile : CourseCardDesktop;
  // const Component = course.isMini ? CourseCardMini : ComponentIsMobile;

  const classNameGiven = isMobile && !course.isMini ? classNameMobile : className;
  const isSpaceRelatedAndAllowed = isInSpace && isSpaceAdmin;
  const isAllowed = isAdmin || isSpaceRelatedAndAllowed;
  const isEditingCourseBase = isOpenEditModal && isAllowed;

  try {
    return (
      <>
        <ConfirmationModal isOpen={confirmModal} isLoading={isDeleting} onClose={handleCloseConfirmationModal} deleteCourse={handleCourseDeletion} />
        {isEditingCourseBase && (
          <CourseForm open={isOpenEditModal} editCourseData={course} onClose={handleCloseEditModal} onSubmitHandler={handleSubmitEditModal} />
        )}
        <CourseCardMini className={clsx(styles.container, styles[theme], classNameGiven)} {...courseCardMininum} />
      </>
    );
  } catch (ex) {
    console.error(ex);
    return <>CourseCard error</>;
  }
};
