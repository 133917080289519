export enum ESelectionInputType {
  CHECKBOX = 'checkbox',
  RADIO = 'radio',
}

export type TSelectionInputProps = {
  type?: ESelectionInputType;
  checked: boolean;
  label: React.ReactNode;
  additionalInfo?: React.ReactNode;
  onChange: () => void;
};
