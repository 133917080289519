import { motion } from 'framer-motion';
import { BasicButtonV1 } from 'shared/ui';
import styled, { keyframes } from 'styled-components';
import firstPlaceLine from './assets/1st.svg';
import secondPlaceLine from './assets/2nd.svg';
import thirdlaceLine from './assets/3rd.svg';
import firstPlaceLineMobile from './assets/1st-mobile.svg';
import secondPlaceLineMobile from './assets/2nd-mobile.svg';
import thirdlaceLineMobile from './assets/3rd-mobile.svg';
import bgDesktop from './assets/bg-desktop.png';
import bgMobile from './assets/bg-mobile.png';
import { THEMES } from 'app/constants';

const linesByPlace = [firstPlaceLine, secondPlaceLine, thirdlaceLine];
const mobileLinesByPlace = [firstPlaceLineMobile, secondPlaceLineMobile, thirdlaceLineMobile];

export const Container = styled(motion.div)`
  width: 100%;
  height: 150px;
  border-radius: 10px;
  background: #000;
  background-image: url(${bgDesktop});
  background-size: cover;
  position: relative;
  background-position: center;
  @media screen and (max-width: 992px) {
    overflow: visible;
    background-image: url(${bgMobile});
    margin-bottom: 23px;
  }
`;

export const Box = styled.div`
  padding-left: 28px;
  height: 100%;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
  @media screen and (max-width: 992px) {
    padding: 0;
  }
`;

export const Left = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const Title = styled.p`
  color: white;
  font-size: 26px;
  line-height: 32.24px;
  font-weight: 500;
`;

export const LButton = styled(BasicButtonV1)`
  background: #f14f63 !important;
  width: 140px !important;
  height: 54px !important;
  cursor: pointer;
  transition: 0.3s;
  border-radius: 100px !important;
  &:hover {
    filter: brightness(1.2);
  }
`;

export const UserList = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding-right: 38px;
  gap: 51px;
  justify-content: flex-end;
  @media screen and (max-width: 992px) {
    padding: 0;
    justify-content: center;
    gap: 43px;
  }
`;

const lineAppear = keyframes`
    from {
        transform: translate(-10%, 50%);
    }
    to {
        transform: translate(-50%, -50%);
    }
`;

export const UserItem = styled.div<{ $place: number; $animate?: boolean }>`
  display: flex;
  position: relative;
  gap: 18px;
  padding-top: 7px;
  padding-bottom: 19px;
  height: 100%;
  &::before {
    position: absolute;
    height: 150px;
    width: 400px;
    top: 50%;
    left: calc(50% + 74px);
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    background-image: url(${(p) => linesByPlace[p.$place]});
    background-size: cover;
    background-position: center;
    pointer-events: none;
    content: '';
    display: block;
    ${(p) => p.$animate && `animation: ${lineAppear} 1s ease-in-out;`}
    animation-delay: calc(0.1s * ${(p) => p.$place});
  }
  @media screen and (max-width: 992px) {
    padding-top: 33px;
    &::before {
      width: 150px;
      left: calc(50% + 3px);
      background-image: url(${(p) => mobileLinesByPlace[p.$place]});
    }
  }
`;

export const UserPlaceIcon = styled.img`
  width: 66px;
  height: 66px;
  object-fit: contain;
  margin-bottom: auto;
  @media screen and (max-width: 992px) {
    width: 62px;
    height: 62px;
  }
`;

export const UserInfo = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  width: 117px;
  @media screen and (max-width: 992px) {
    width: 72px;
  }
`;

export const Nickname = styled.p`
  color: white;
  font-size: 16px;
  line-height: 100%;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 9px;
  white-space: nowrap;
  text-align: center;
  @media screen and (max-width: 992px) {
    height: 34px;
    white-space: normal;
    font-size: 14px;
    line-height: 17.36px;
    margin-bottom: 5px;
    margin-top: 2px;
  }
`;
export const Exp = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 19px;
  font-weight: 500;
  color: white;
  & img {
    width: 31px;
    height: 31px;
  }
  @media screen and (max-width: 992px) {
    font-size: 18px;
    & img {
      width: 28px;
      height: 28px;
    }
  }
`;
export const MobileNumList = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  gap: 43px;
  justify-content: center;
  transform: translateY(-50%);
  @media screen and (max-width: 996px) {
    display: flex;
  }
`;
export const MobileNumBox = styled.div`
  width: 72px;
  height: 62px;
  padding-right: 10px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 18px;
  color: white;
  transform: translateX(-27px);
  & ${UserPlaceIcon} {
    position: absolute;
    aspect-ratio: 1 / 1;
    left: 0;
    top: 0;
    z-index: -1;
  }
`;

export const MobileTitle = styled.p<{ $theme?: THEMES }>`
  font-size: 26px;
  font-weight: 500;
  margin-bottom: 43px;
  color: ${(p) => (p.$theme === THEMES.DARK ? 'white' : 'var(--mighty-blue)')};
`;
