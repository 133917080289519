import { BsChevronRight } from 'react-icons/bs';
import ChestImageAnimated from '../screen/ChestAnimated';
import * as S from './styles';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';

const ReferralsToast = () => {
  const theme = useSelector(getCurrentTheme);
  const navigate = useNavigate();

  return (
    <S.Container onClick={() => navigate(ROUTES.REFERRALS)}>
      <ChestImageAnimated containerClassName="chest" size={100} smallerGlow />
      <S.Col>
        <S.Text $theme={theme}>Earn rewards for every friend joined</S.Text>
        <S.Link>
          Learn more <BsChevronRight />
        </S.Link>
      </S.Col>
    </S.Container>
  );
};

export default ReferralsToast;
