import * as S from './styles';
import { IRewardMinimal, getUserData } from 'store';
import { IDatabaseItem } from 'interface';
import { useMemo } from 'react';
import { ReactComponent as MinusIcon } from '../../assets/minus.svg';
import { ReactComponent as PlusIcon } from '../../assets/plus.svg';
import { useSelector } from 'react-redux';
import { USER_ROLES } from 'app/constants';
import { useGetCurrentTheme } from 'hooks';

type Props = {
  item: IDatabaseItem;
  updateReward: (id: number, quantity: number) => void;
  rewards: IRewardMinimal[];
};

const RewardItem = ({ item, updateReward, rewards }: Props) => {
  const { roles } = useSelector(getUserData);
  const theme = useGetCurrentTheme();

  const isAdmin = useMemo(() => roles && roles.includes(USER_ROLES.ADMIN), [roles]);

  const rewardItem = useMemo(() => rewards.find((el) => el.id === item.id), [item, rewards]);
  const expCount = useMemo(() => rewards.find((el) => el.id === 0)?.quantity || 0, [rewards]);
  const rewsLen = useMemo(() => rewards.filter((el) => !!el.quantity).length, [rewards]);
  const increment = () => {
    if (!isAdmin) {
      if (rewardItem?.id === 0 && rewardItem?.quantity >= 10) {
        return;
      } else if (rewardItem?.id === 0 && expCount > 0) {
        updateReward(item.id, rewardItem ? rewardItem.quantity + 1 : 1);
        return;
      } else if ((rewardItem?.quantity || 0) >= 1) {
        return;
      }
      if (rewsLen >= 2) {
        return;
      }
      updateReward(item.id, rewardItem ? rewardItem.quantity + 1 : 1);
    } else {
      updateReward(item.id, rewardItem ? rewardItem.quantity + 1 : 1);
    }
  };

  const decrement = () => {
    updateReward(item.id, rewardItem && rewardItem?.quantity > 0 ? rewardItem.quantity - 1 : 0);
  };

  return (
    <S.Container $theme={theme}>
      <S.Image src={item.image} />
      <S.NameCol $grade={item.grade}>{item.name}</S.NameCol>
      <S.Amount>
        <S.Button $theme={theme} onClick={increment}>
          <PlusIcon />
        </S.Button>
        <S.Num>{rewardItem?.quantity || 0}</S.Num>
        <S.Button $theme={theme} onClick={decrement}>
          <MinusIcon />
        </S.Button>
      </S.Amount>
    </S.Container>
  );
};

export default RewardItem;
