import { ROUTES } from 'app/constants';
import { useGetPartnerId } from 'hooks';
import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

export const useBackButtonClick = () => {
  const { state, key } = useLocation();
  const navigate = useNavigate();
  const partnerId = useGetPartnerId();

  return useCallback(() => {
    if (partnerId) {
      navigate(`/${partnerId}?partnerID=${partnerId}`);
      return;
    }
    if (state && state.toMain) {
      navigate(ROUTES.MAIN);
      return;
    }
    if (key !== 'default') {
      navigate(-1);
      return;
    }
    navigate(ROUTES.MAIN);
  }, [state, partnerId, key]);
};
