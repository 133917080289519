import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { InlineAnswersEditor } from './vanila/core';
import { InlineAnswersBubbleExtension } from './vanila/extensions/bubble';
import { AnswerEditorStyles, ButtonsRow } from './styles';
import { InlineAnswersAnswerInputExtension } from './vanila/extensions/answerInput';
import { InlineAnswersAnswerBadgeExtension } from './vanila/extensions/answerBadge';
import { TTestSlideProps } from '../../types';
import { useSlideContext } from '../../../../../SlideContext';
import { html2JSON, json2HTML } from '../../../../../../../../../utils/contenteditable';
import { extractAnswersFromDomJson } from '../InlineAnswers/utils/answers';
import InfoBadge from '../../../../../../../infoBadge';
import { EInfoBadgeTypes } from '../../../../../../../infoBadge/types';
import { Buttonv2 } from '../../../../../../../buttonv2';
import { AiOutlinePlus } from 'react-icons/ai';
import { exampleTemplate } from '../InlineAnswers/utils/exampleTemplate';
import { CreateInvalidAnswerButton } from './CreateInvalidAnswerButton';
import { ESlideTypes, ESlideWidgetTypes } from 'store';
import { WrongAnswers } from './WrongAnswers';
import TextContent from 'components/pages/study/courses/material-pages/SlideContent/components/Text';

const getHtml = (nodes?: string) => {
  if (!nodes) {
    return exampleTemplate();
  }

  try {
    const jsonInHTML = JSON.parse(nodes);
    return json2HTML(jsonInHTML).innerHTML;
  } catch (e) {
    return exampleTemplate();
  }
};

export const AnswersEditor: FC<TTestSlideProps> = ({ answers, rightAnswers, onChange, type }) => {
  const { slide } = useSlideContext();
  const {
    slideType: { nodes = '' },
  } = slide;

  const [isAnswersEmpty, setIsAnswersEmpty] = useState(!answers.length);
  const [isAddingWrongAnswer, setIsAddingWrongAnswer] = useState(false);
  const [addingWrongAnswerError, setAddingWrongAnswerError] = useState('');

  const editorRef = useRef<InlineAnswersEditor | null>(null);
  const $editorContainerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef(getHtml(nodes));
  const $updateTimerId = useRef<number | null>();

  const _incorrectAnswers = useMemo(() => {
    const answersInText = answers.map((item) => item.text);
    const rightAnswersInText = rightAnswers.map((item) => item.text);

    return answersInText.filter((item) => !rightAnswersInText.includes(item));
  }, [answers, rightAnswers]);

  const handleAddInvalidAnswer = useCallback(
    (answer: string) => {
      onChange({
        ...slide,
        slideType: {
          ...slide.slideType,
          answers: [...answers, { text: answer }],
          // @ts-ignore
          rightAnswers,
          nodes,
        },
      });
    },
    [slide, answers, rightAnswers, nodes, onChange]
  );

  const handleDeleteInvalidAnswer = useCallback(
    (answer: string) => {
      onChange({
        ...slide,
        slideType: {
          ...slide.slideType,
          answers: answers.filter((item) => item.text !== answer),
          // @ts-ignore
          rightAnswers,
          nodes,
        },
      });
    },
    [slide, answers, rightAnswers, nodes, onChange]
  );

  const _handleChange = useCallback(() => {
    if (!editorRef.current) return;

    const htmlInJson = html2JSON(editorRef.current.getRoot());
    const rightAnswers = extractAnswersFromDomJson(htmlInJson).map((text) => ({ text }));
    const nodes = JSON.stringify(htmlInJson);

    if (!rightAnswers.length) {
      setIsAnswersEmpty(true);
      return;
    } else {
      setIsAnswersEmpty(false);
    }

    const totalAnswers = [...rightAnswers, ..._incorrectAnswers.map((item) => ({ text: item }))];

    onChange({
      ...slide,
      slideType: {
        ...slide.slideType,
        answers: totalAnswers,
        // @ts-ignore
        rightAnswers,
        nodes,
      },
    });
  }, [onChange, slide, _incorrectAnswers]);

  const handleAddAnswer = useCallback(
    (answer: string) => {
      if (!editorRef.current) return;

      const answerBadgeExtension = editorRef.current.getExtension<InlineAnswersAnswerBadgeExtension>('answer-badge');
      answerBadgeExtension.insertAnswer(answer);

      _handleChange();
    },
    [_handleChange]
  );

  const _handleAddAnswerClick = useCallback(() => {
    if (!editorRef.current) return;

    editorRef.current.focus();

    const answerInputExtension = editorRef.current.getExtension<InlineAnswersAnswerInputExtension>('answer-input');
    answerInputExtension.mount(true);
  }, []);

  const initializeEditor = useCallback(() => {
    if (!$editorContainerRef.current || editorRef.current) {
      return;
    }

    const newEditor = new InlineAnswersEditor($editorContainerRef.current);

    newEditor.attachExtension({
      Extension: InlineAnswersBubbleExtension,
      props: { onAddAnswer: handleAddAnswer },
      id: 'bubble',
    });
    newEditor.attachExtension({
      Extension: InlineAnswersAnswerInputExtension,
      props: { onAddAnswer: handleAddAnswer },
      id: 'answer-input',
    });
    newEditor.attachExtension({ Extension: InlineAnswersAnswerBadgeExtension, props: {}, id: 'answer-badge' });

    newEditor.getRoot().innerHTML = contentRef.current;
    editorRef.current = newEditor;
  }, [handleAddAnswer]);

  useEffect(() => {
    initializeEditor();
    return () => {
      if (editorRef.current) {
        editorRef.current.destroy();
        editorRef.current = null;
      }
    };
  }, [initializeEditor]);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    if (contentRef.current !== getHtml(nodes)) {
      contentRef.current = getHtml(nodes);
      editorRef.current.getRoot().innerHTML = contentRef.current;
    }
  }, [nodes]);

  useEffect(() => {
    if (!editorRef.current) {
      return;
    }

    const handleInput = () => {
      if ($updateTimerId.current) {
        window.clearTimeout($updateTimerId.current);
      }

      $updateTimerId.current = window.setTimeout(() => {
        _handleChange();
      }, 3000);
    };

    editorRef.current.getRoot().addEventListener('input', handleInput);

    return () => {
      editorRef.current?.getRoot().removeEventListener('input', handleInput);
    };
  }, [_handleChange]);

  const editorContent = useMemo(() => {
    if (type === ESlideTypes.FILLSPACES_CODE || type === ESlideTypes.FILLBLOCKS_CODE) {
      return <TextContent type={ESlideWidgetTypes.CODE} content={<div ref={$editorContainerRef} className="inline-answers-v2 code" />} />;
    }
    return <div ref={$editorContainerRef} className="inline-answers-v2" />;
  }, [type]);

  return (
    <>
      <AnswerEditorStyles />
      <div className="inline-answers-v2-wrapper">
        <div className="inline-answers-v2-placeholder">Press "/" or select word to add an answer</div>
        {editorContent}
      </div>
      <ButtonsRow>
        <Buttonv2 onClick={_handleAddAnswerClick}>
          <AiOutlinePlus /> Answer
        </Buttonv2>
        {type && [ESlideTypes.FILLBLOCKS, ESlideTypes.FILLBLOCKS_CODE].includes(type) && (
          <CreateInvalidAnswerButton
            rightAnswers={rightAnswers}
            onError={setAddingWrongAnswerError}
            onCreatingModeChange={setIsAddingWrongAnswer}
            onAddInvalidAnswer={handleAddInvalidAnswer}
          />
        )}
      </ButtonsRow>
      {addingWrongAnswerError && (
        <InfoBadge style={{ marginTop: '15px' }} type={EInfoBadgeTypes.WARNING}>
          {addingWrongAnswerError}
        </InfoBadge>
      )}
      {isAnswersEmpty && (
        <InfoBadge type={EInfoBadgeTypes.WARNING} style={{ marginTop: '15px' }}>
          Please add at least 1 answer
        </InfoBadge>
      )}
      {Boolean(_incorrectAnswers.length) && type && [ESlideTypes.FILLBLOCKS, ESlideTypes.FILLBLOCKS_CODE].includes(type) && (
        <WrongAnswers wrongAnswers={_incorrectAnswers} onDelete={handleDeleteInvalidAnswer} />
      )}
    </>
  );
};
