import { SpeechTextToSpeech } from 'features/TextToSpeech/ui/SpeechTextToSpeech';
import * as S from '../styles';
import { dispatch, useGetCurrentTheme, useGetUserData } from 'hooks';
import { setCurrentTheme } from 'store';
import { ROUTES, THEMES } from 'app/constants';
import { useNavigate, useParams } from 'react-router-dom';

import expImg from '../assets/exp.png';
import { ReactComponent as LessonsIcon } from '../assets/lessons.svg';
import { ReactComponent as MoonIcon } from '../assets/moon.svg';
import { ReactComponent as SunIcon } from '../assets/sun.svg';
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import { Avatar } from 'components/library/Avatar';
import { DisableInSafari } from 'features/DisableInSafari';
import { useMemo } from 'react';
import { useGetEffectsColorData } from 'shared/ui/effect-indicator/data';
import LessonPageVolumeControl from 'features/lessonPageVolume';
import { Effects } from 'components/pages/study/courses/material-pages/effects';

type Props = {
  isHidden?: boolean;
  openLessons: () => void;
};

const HorizontalMobileMenu = ({ isHidden, openLessons }: Props) => {
  const { nickname, avatar, exp } = useGetUserData();

  const {
    balance: { amount },
    energy,
    limit: { maxEnergy },
  } = exp;

  const currentEnergy = useMemo(() => Math.floor((energy / maxEnergy) * 100), [maxEnergy, energy]);

  const theme = useGetCurrentTheme();
  const toggleTheme = () => dispatch(setCurrentTheme(theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK));
  const { color } = useGetEffectsColorData('energy');

  const navigate = useNavigate();

  const navToSetting = () => navigate('/profile?screen=Settings');
  const navToProfile = () => navigate('/profile');

  return (
    <S.Menu>
      {/* <DisableInSafari>
        <S.ButtonWrapper>
          <SpeechTextToSpeech $larger />
          <S.Sub>Audio</S.Sub>
        </S.ButtonWrapper>
      </DisableInSafari> */}
      <S.ButtonWrapper>
        <LessonPageVolumeControl closeOn={isHidden} />
        <S.Sub>Volume</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper onClick={openLessons}>
        <S.CircleButton $theme={theme}>
          <LessonsIcon className="lessons" />
        </S.CircleButton>
        <S.Sub>Lessons</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper>
        <S.CircleButton $theme={theme}>
          <img src={expImg} className="exp" />
        </S.CircleButton>
        <S.Sub $noMaxWidth>{amount} EXP</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper>
        <div style={{ pointerEvents: 'none' }}>
          <Effects $larger />
        </div>
        <S.Sub $color={color}>{currentEnergy}%</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper>
        <S.CircleButton $theme={theme} onClick={toggleTheme}>
          {theme === THEMES.DARK ? <SunIcon /> : <MoonIcon />}
        </S.CircleButton>
        <S.Sub>{theme === THEMES.DARK ? 'Light' : 'Dark'}</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper onClick={navToSetting}>
        <S.CircleButton $theme={theme}>
          <SettingsIcon />
        </S.CircleButton>
        <S.Sub>Settings</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper onClick={navToProfile}>
        <Avatar userName={nickname} src={avatar} size={48} />
        <S.Sub>Profile</S.Sub>
      </S.ButtonWrapper>
    </S.Menu>
  );
};

export default HorizontalMobileMenu;
