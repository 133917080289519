import { Premium } from './premium';
import { NoEnergy } from './no-energy';
import { Energy } from './energy';
import { NoPremium } from './no-premium';

export const DURATION = 0.25;
export const DELAY = 0;
export const variants = {
  show: {
    width: 123,
    opacity: 1,
  },
  hide: {
    width: 0,
    opacity: 0,
  },
};
export const transition = {
  duration: DURATION,
  delay: DELAY,
};

export const data: Record<string, (...props: any) => JSX.Element | null> = {
  energy: Energy,
  'no-energy': NoEnergy,
  'no-premium': NoPremium,
  'scholar-coin': Premium,
  'emperor-badge': Premium,
  'emperor-key': Premium,
  'emperor-crown': Premium,
  Premium: Premium,
};
