import styled, { css } from 'styled-components';
import { TTogglerColor } from './types';

export const SToggler = styled.div<{ $color: TTogglerColor }>(
  ({ $color }) => css`
    display: flex;
    padding: 4px;
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.1);

    div {
      display: flex;
      padding: 10px 20px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      cursor: pointer;

      &.toggler__option-active {
        border-radius: 49px;

        ${$color === 'red' &&
        css`
          background: var(--red-color);
        `}

        ${$color === 'blue' &&
        css`
          background: var(--mighty-blue);
        `}

        span {
          opacity: 1;
        }
      }

      span {
        color: #fff;
        font-size: 16px;
        font-weight: 500;
        line-height: 110%;
        opacity: 0.7;
      }
    }
  `
);
