import { ReactComponent as CodeIcon } from 'assets/icons/code.svg';
import { ReactComponent as ImagePlusIcon } from 'assets/icons/image-plus.svg';
import { ReactComponent as TypeIcon } from 'assets/icons/type_answer.svg';
import { TDropdownMenuItem } from '../../../../../../dropdownMenu/types';
import { SettingsIcon } from 'assets/icons/icons';
import { TAIGeneratedTestType, ESlideTypes } from 'store';

export type TBlocksDropdownItemsProps = {
  onMakeFillspaces: () => void;
  onMakeFillspacesCode: () => void;
  onMakeFillblocks: () => void;
  onMakeFillblocksCode: () => void;
  onMakeMultichoice: () => void;
  onMakeTypeAnswer: () => void;
  onMakeGeneratedTest: (type: TAIGeneratedTestType, isRandomTestType?: boolean) => void;
};

export const getTestsBlocksDropdownItems = (props: TBlocksDropdownItemsProps): TDropdownMenuItem[] => {
  const defaultItems: TDropdownMenuItem[] = [
    {
      title: 'Fillspaces',
      icon: <CodeIcon />,
      onClick: props.onMakeFillspaces,
      type: ESlideTypes.FILLSPACES,
    },
    {
      title: 'Fillspaces as code',
      icon: <CodeIcon />,
      onClick: props.onMakeFillspacesCode,
      type: ESlideTypes.FILLSPACES_CODE,
    },
    {
      title: 'Fillblocks',
      icon: <CodeIcon />,
      onClick: props.onMakeFillblocks,
      type: ESlideTypes.FILLBLOCKS,
    },
    {
      title: 'Fillblocks as code',
      icon: <CodeIcon />,
      onClick: props.onMakeFillblocksCode,
      type: ESlideTypes.FILLBLOCKS_CODE,
    },
    {
      title: 'Multichoice',
      icon: <ImagePlusIcon />,
      onClick: props.onMakeMultichoice,
      type: ESlideTypes.MULTICHOICE,
    },
    {
      title: 'Type answer',
      icon: <TypeIcon />,
      onClick: props.onMakeTypeAnswer,
      type: ESlideTypes.TYPEANSWER,
    },
  ];

  const items = [
    {
      title: 'Generate test',
      icon: <SettingsIcon />,
      menu: {
        // items: defaultItems.map((item) => ({
        //   ...item,
        //   onClick: () => {
        //     if (item.type) props.onMakeGeneratedTest(item.type);
        //   },
        // })),
        items: [
          {
            title: 'Multichoice',
            icon: <ImagePlusIcon />,
            onClick: () => {
              props.onMakeGeneratedTest(ESlideTypes.MULTICHOICE);
            },
            type: ESlideTypes.MULTICHOICE,
          },
          {
            title: 'Fill spaces',
            icon: <ImagePlusIcon />,
            onClick: () => {
              props.onMakeGeneratedTest(ESlideTypes.FILLSPACES);
            },
            type: ESlideTypes.FILLSPACES,
          },
          {
            title: 'Fill blocks',
            icon: <ImagePlusIcon />,
            onClick: () => {
              props.onMakeGeneratedTest(ESlideTypes.FILLBLOCKS);
            },
            type: ESlideTypes.FILLBLOCKS,
          },
          {
            title: 'Type answer',
            icon: <ImagePlusIcon />,
            onClick: () => {
              props.onMakeGeneratedTest(ESlideTypes.TYPEANSWER);
            },
            type: ESlideTypes.TYPEANSWER,
          },
          {
            title: 'Random',
            icon: <ImagePlusIcon />,
            onClick: () => {
              props.onMakeGeneratedTest(ESlideTypes.MULTICHOICE, true);
            },
            type: ESlideTypes.MULTICHOICE,
          },
        ],
      },
    },
    ...defaultItems,
  ];
  return items;
};
