import { FC, useEffect, useState } from 'react';
import { BsArrowRight } from 'react-icons/bs';
import BasicButton from 'shared/ui/basic-button/button';
import AvatarPic from '../../../../../../../library/utils/AvatarPic/avatar';
import styles from './styles.module.scss';
import InventoryItem from '../../../../../../../library/cards/inventory/InventoryItem';
import { Value } from '../../ChangerList/types/types';
import { IChanges } from '../ChangerModal/ChangerModal';
import { THEMES } from 'app/constants';
import hexToRgba from 'hex-to-rgba';
import ChangesNickname from './components/ChangesNickname';

interface IProps {
  text: string;
  value: Value;
  nickname: any;
  avatar: any;
  onClose: () => void;
  inventoryItem: number;
  quantity: number;
  changes: IChanges;
  theme: THEMES;
  customization: any;
}

const CongratulationModal: FC<IProps> = ({ text, nickname, avatar, value, onClose, inventoryItem, quantity, changes, theme, customization }) => {
  const [currentChange, setCurrentChange] = useState(changes.oldValue);

  function glowAnimate() {
    if (value === 'glow') {
      return {
        initial: {
          scale: 0.25,
          opacity: 0,
        },
        animate: {
          scale: 1,
          boxShadow: [
            `0 0 20px 10px ${hexToRgba(changes?.oldValue === 'default' ? changes?.newValue ?? '' : changes?.oldValue, 0.7)}`,
            `0 0 20px 10px ${hexToRgba(changes?.oldValue === 'default' ? changes?.newValue ?? '' : changes?.oldValue, 0.5)}`,
            `0 0 20px 10px ${hexToRgba(changes?.oldValue === 'default' ? changes?.newValue ?? '' : changes?.oldValue, 0.3)}`,
            `0 0 20px 10px ${hexToRgba(changes?.newValue ?? '', 0.7)}`,
          ],
          opacity: [0, 1],
          transition: {
            type: 'spring',
            stiffness: 130,
            damping: 6,
            times: [0, 0.25, 0.5, 1],
          },
        },
      };
    }
  }

  useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrentChange(changes?.newValue);
    }, 1700);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className={styles.wrap}>
      <h2>Congratulations!</h2>
      <p>{text}</p>
      {value === 'glow' ? (
        <AvatarPic
          isFramed={false}
          className={styles.avatar}
          nickname={nickname}
          avatar={avatar}
          customization={customization}
          animation={glowAnimate()}
        />
      ) : (
        <ChangesNickname
          value={value}
          currentChange={currentChange}
          customization={customization}
          theme={theme}
          changes={changes}
          nickname={nickname}
        />
      )}
      <div className={styles.item}>
        <InventoryItem amount={quantity} itemId={inventoryItem} isRightInfo isCongrats={true} isShowTooltip={false} isSmall />
      </div>
      <BasicButton isLabelFirst icon={<BsArrowRight />} label="Continue" variant="cool" className={styles.button} onClick={() => onClose()} />
    </div>
  );
};

export default CongratulationModal;
