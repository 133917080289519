import BasicButton from 'shared/ui/basic-button/button';

import styles from './styles.module.css';

export default function AddConfirmed({ close, name }) {
  const theme = localStorage.getItem('theme');

  return (
    <div className={`glass-div basic ${theme} friend-finder_conf --confirmed`}>
      <p id="header">Request has been sent</p>
      <p>
        Wait for <span>{name}</span> to confirm your application
      </p>
      <BasicButton variant="outline" label="Okay" className={styles.button} onClick={close} />
    </div>
  );
}
