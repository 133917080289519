import { FC } from 'react';
import { IShopItem } from 'interface';
import { IItemAction } from '../../components/ItemActions/data';
import { shallowEqual, useSelector } from 'react-redux';
import { getItems, getUserData } from 'store';
import { ITEM_IDS } from 'app/constants';
import { getItemEffectType } from '../data';

import { ReactComponent as Coin } from 'assets/icons/coin.svg';
import { ItemActions, ItemDesc } from '../../components';
import BasicButton from 'shared/ui/basic-button/button';

import styles from '../styles.module.scss';

interface IInfoProps {
  item: IShopItem;
  handleBuy: (goodsId: number, id: number, amount: number) => Promise<void> | any;
  isLoading: boolean;
  setSelectedAction: (action: IItemAction | undefined) => void;
  selectedAction: IItemAction | undefined;
}

export const Info: FC<IInfoProps> = ({ item, handleBuy, isLoading, setSelectedAction, selectedAction }) => {
  const {
    exp: {
      balance: { amount: userExpBalance },
    },
  } = useSelector(getUserData);
  const items = useSelector(getItems, shallowEqual);

  const {
    quantity,
    item: { id: itemId },
    userBalance = 0,
  } = item;

  const goodsId = item?.id ?? 0;
  const isSoldOut = item && quantity !== null && quantity < 1 && item.item.id !== ITEM_IDS.EXP_TOKEN;
  const isNotAllowed = itemId === ITEM_IDS.EXP_TOKEN || isLoading || isSoldOut;
  const coinImage = items[0].image;

  const {
    item: { image, grade, name, type: itemType },
  } = item;
  const itemDescType = itemType === 'fungible' ? 'item' : 'partner';

  const handlePurchase = () => {
    if (!isNotAllowed && item) {
      handleBuy(goodsId, item.item.id, 1);
    }
  };

  const price = 'price' in item ? item.price : 0;
  const itemEffectType = getItemEffectType(item.item);

  return (
    <>
      <ItemDesc image={image} name={name} grade={grade} userBalance={userBalance} quantity={quantity} type={itemDescType} isLoading={isLoading} />
      <ItemActions
        userBalance={userBalance}
        item={item.item}
        setSelectedAction={setSelectedAction}
        selectedAction={selectedAction}
        type={itemEffectType}
      />
      {price > 0 && !isSoldOut && (
        <div className={styles.menu}>
          <div className={styles.coin}>
            <img src={coinImage} alt="" />
            <p>{price} EXP</p>
          </div>

          <BasicButton
            isDisabled={isLoading || Math.floor(price) > userExpBalance || isSoldOut}
            loading={isLoading}
            icon={<Coin width={'22px'} height={'22px'} />}
            className={styles.button}
            onClick={handlePurchase}
            label="Buy"
            variant="cool"
          />
        </div>
      )}
    </>
  );
};
