import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { AnchorHTMLAttributes, DetailedHTMLProps } from 'react';
import clsx from 'clsx';

import * as Styled from './styles';

interface IProps extends DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
  className?: string;
  label?: string | React.ReactElement;
  icon?: any;
}

export function CircleLink({ label, icon, className = '', ref, ...rest }: IProps) {
  const theme = useSelector(getCurrentTheme);

  return (
    <Styled.Container className={clsx(theme, { [className]: !!className })} {...rest}>
      {icon}
      {label}
    </Styled.Container>
  );
}
