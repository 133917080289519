import transferImg from '../../assets/transferItem.png';
import styles from './styles.module.scss';

export const Approval = () => {
  return (
    <div className={styles.approval}>
      <img src={transferImg} alt={''} />
      <h3>Please wait until we approve your withdrawal</h3>
      <p>
        Because we are still in beta and still testing our systems, withdrawing NFTs still requires a manual approval from a member of staff. This
        process can take up to 24 hours
      </p>
    </div>
  );
};
