import * as Styles from './styles';

interface IProps {
  items: string[];
  className?: string;
  active: number;
  setActive: (index: number) => void;
}

export default function InfoPageSwitch({ items, active, setActive, className = '' }: IProps) {
  return (
    <Styles.Container className={className}>
      {items.map((item, index) => (
        <Styles.Item key={'ds-item' + index} className={`${active === index && 'active'} bright--hover`} onClick={() => setActive(index)}>
          <p>{item}</p>
        </Styles.Item>
      ))}
    </Styles.Container>
  );
}
