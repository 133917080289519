import { useCallback, useState } from 'react';

import CheckAnswerButton from '../utils/CheckAnswerButton/CheckAnswerButton';

import styles from './styles.module.css';
import { NodesView } from './NodesView';
import { useGetCustomNav } from '../utils/useGetCustomNav';

const Fillblocks = ({ answers = [], slideId, nodes, right, updateRight, elementWithCondition }) => {
  const [userInput, setUserInput] = useState([]);

  const handleAnswersChange = useCallback((answers) => {
    setUserInput(answers);
  }, []);

  const buttonElement = useGetCustomNav(
    <CheckAnswerButton
      className="lesson-button"
      isActive={Boolean(userInput[0])}
      userAnswers={userInput}
      slideId={slideId}
      right={right}
      updateRight={updateRight}
    />,
    elementWithCondition
  );

  return (
    <>
      <div className={styles.gaps}>
        <div className="lesson-content-space">
          <NodesView rootNode={nodes} answers={answers} onChange={handleAnswersChange} right={right} updateRight={updateRight} />
        </div>
      </div>
      {buttonElement}
    </>
  );
};

export default Fillblocks;
