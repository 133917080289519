import { ButtonProps } from './types';
import * as S from './styles';
import { useGetCurrentTheme } from 'hooks';
import ResizebleSkeleton from 'shared/ui/course-card/CourseCard/utils/ui/skeletons/resizebleSkeleton';

export const DashboardButton = ({ label, onClick, icon, isLoading }: ButtonProps) => {
  const theme = useGetCurrentTheme();

  if (isLoading) {
    return <ResizebleSkeleton $width={'100%'} $height={'44px'} $radius={'8px'} />;
  }

  return (
    <S.BlueButton onClick={onClick} className={theme}>
      {!!icon && <S.IconBox>{icon}</S.IconBox>}
      {label}
    </S.BlueButton>
  );
};
