import { useEffect, useState } from 'react';

import { ReactComponent as Spinner } from 'assets/icons/spinner--pie.svg';

import './styles/settings-styles.css';
import { useUpdateUserDripMutation } from 'store';
import MightyInputField from 'components/pages/study/courses/material-pages/tests/utils/MightyInputField';

const NicknameChanger = ({ open, nickname, close }) => {
  const [input, setInput] = useState(nickname);
  const [updateDrip, { isLoading: isDrippingUp }] = useUpdateUserDripMutation();
  const theme = localStorage.getItem('theme');

  useEffect(() => {
    const listener = (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        event.preventDefault();
        handleChangeNickname();
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [input]);

  if (!open) return null;

  async function handleChangeNickname() {
    if (input !== nickname) {
      await updateDrip({ nickname: input });
    }
    close();
  }

  return (
    <div className="nick-changer">
      <MightyInputField
        autoFocus
        style={{ background: `var(--basic-${theme}-bg)` }}
        type="text"
        value={input}
        onChange={(e) => setInput(e.target.value)}
        minLength="1"
        maxLength="60"
      />
      <div className="btns">
        <button className={`cancel glass-div basic ${theme}`} onClick={close}>
          Cancel
        </button>
        <button
          style={{
            background: `radial-gradient(60.13% 88.58% at 84.97% 21.43%, rgba(98, 126, 234, 0.5) 0%, rgba(0, 0, 0, 0) 100%), var(--basic-${theme}-bg)`,
          }}
          className={`done`}
          onClick={handleChangeNickname}
        >
          {isDrippingUp ? <Spinner className="spinner" width={20} height={20} /> : 'Done'}
        </button>
      </div>
    </div>
  );
};

export default NicknameChanger;
