import { FC, ReactNode } from 'react';
import { SRow, STitle } from './styles';

interface ISettingsCardProps {
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  title: string | ReactNode;
  onClick?: () => void;
}

export const SettingsCard: FC<ISettingsCardProps> = ({ leftIcon, rightIcon, title, onClick }) => {
  return (
    <SRow onClick={onClick}>
      {leftIcon && leftIcon}
      <STitle>{title}</STitle>
      {rightIcon && rightIcon}
    </SRow>
  );
};
