import { FC, HTMLAttributes, useState } from 'react';
import clsx from 'clsx';
import styles from '../../styles.module.css';
import svgbg from '../../../../backgrounds/svgs.module.css';
import { LazyImage } from '../../../../../../shared/ui';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { motion } from 'framer-motion';
import flipStyles from './styles.module.scss';

const spring = {
  duration: 0.35,
};

interface IFlipInventoryItem extends HTMLAttributes<HTMLDivElement> {
  name: string;
  description: string;
  grade: string;
  image: string;
  amount: number | null;
}

export const FlipInventoryItem: FC<IFlipInventoryItem> = ({ amount, image, name, description, grade, className = '', onClick }) => {
  const [isFlipped, setIsFlipped] = useState(false);
  const theme = useSelector(getCurrentTheme);

  return (
    <motion.div
      transition={spring}
      style={{
        perspective: '1200px',
        transformStyle: 'preserve-3d',
      }}
      onHoverStart={() => setIsFlipped(true)}
      onHoverEnd={() => setIsFlipped(false)}
      onClick={() => setIsFlipped((prevState) => !prevState)}
      className={className}
    >
      <motion.div
        transition={spring}
        style={{
          width: '100%',
          height: '100%',
        }}
      >
        <div
          style={{
            perspective: '1200px',
            transformStyle: 'preserve-3d',
            width: '100%',
            height: '100%',
          }}
        >
          <motion.div
            animate={{ rotateY: isFlipped ? -180 : 0 }}
            transition={spring}
            style={{
              width: '100%',
              height: '100%',
              zIndex: isFlipped ? 0 : 1,
              backfaceVisibility: 'hidden',
              position: 'absolute',
            }}
          >
            <div
              className={clsx(styles.container, svgbg.mightybg, svgbg.item, svgbg[theme], svgbg[grade], {
                [className]: !!className,
              })}
              onClick={onClick}
            >
              <LazyImage src={image} className={styles.image} alt="Item" />
              <p className={styles.quantity} style={{ color: `var(--grade-${theme}-color-${grade})` }}>
                {amount}
              </p>
            </div>
          </motion.div>
          <motion.div
            initial={{ rotateY: 180 }}
            animate={{ rotateY: isFlipped ? 0 : 180 }}
            transition={spring}
            style={{
              width: '100%',
              height: '100%',
              zIndex: isFlipped ? 1 : 0,
              backfaceVisibility: 'hidden',
              position: 'absolute',
            }}
          >
            <div
              className={clsx(styles.container, svgbg.mightybg, svgbg.item, svgbg[theme], svgbg[grade], {
                [className]: !!className,
              })}
              onClick={onClick}
            >
              <div className={flipStyles.back} style={{ color: `var(--grade-${theme}-color-${grade})` }}>
                <h3 className={flipStyles.name}>{name}</h3>
                <div className={flipStyles.desc}>{description}</div>
              </div>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </motion.div>
  );
};
