import rgba from 'hex-to-rgba';
import styled, { css, keyframes } from 'styled-components';

const slideDown = keyframes`
  0% {
    top: calc(100% - 20px);
    opacity: 0.5;
  }

  100% {
    top: calc(100% - 10px);
    opacity: 1;
  }
`;

export const SRoot = styled.div(
    () =>
      css`
        position: relative;
        width: calc(100% - 35px);
      `
  ),
  SContent = styled.button(
    ({ theme }) => css`
      border: none;
      outline: none;
      display: flex;
      gap: 10px;
      width: 100%;
      height: 50px;
      padding: 12px 6px;
      align-items: center;
      border-radius: 4px;
      background: ${rgba(theme.theme.basicBlue, 0.1)};
      color: ${theme.theme.fontColor};

      &:hover {
        background: ${rgba(theme.theme.basicBlue, 0.15)};
      }

      span {
        white-space: nowrap;
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `
  ),
  SImageContainer = styled.div(
    () => css`
      width: auto;
      min-width: 20px;
      height: 100%;
      min-height: 20px;

      & > img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    `
  ),
  PreviewContainer = styled.div(
    ({ theme }) => css`
      position: relative;
      display: flex;
      justify-content: center;
      width: auto;
      height: 200px;
      max-height: 200px;
      background: ${rgba(theme.theme.fontColor, 0.1)};
      border-radius: 5px;
    `
  ),
  Img = styled.img(
    () => css`
      object-fit: contain;
      max-height: 100%;
      max-width: 100%;
    `
  ),
  Overlay = styled.div(
    () => css`
      position: absolute;
      top: 0;
      left: 0;
      border-radius: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;
      width: 100%;
      height: 100%;
      z-index: 2;
      transition: all 0.2s ease-in-out;
      background: rgba(0, 0, 0, 0.3);
      opacity: 0;

      &:hover {
        opacity: 1;
      }
      & > * {
        width: 60px;
        height: 60px;
        border-radius: 30px;
        display: none;
        padding: 10px;
        border-radius: inherit;
      }
      & > *:hover {
        color: var(--font-color-strong);
        background: rgba(255, 255, 255, 0.2);
      }
      &:hover > * {
        display: block;
      }
    `
  ),
  Label = styled.label(
    () => css`
      & > svg {
        width: 100%;
        height: auto;
        color: var(--font-color-strong);
      }
    `
  ),
  SDropdown = styled.div(
    ({ theme }) => css`
      position: absolute;
      z-index: 1;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 400px;
      padding: 20px 15px;
      background: ${theme.theme.basicBG};
      border: 2px solid ${rgba(theme.theme.fontColor, 0.1)};
      border-radius: 5px;
      animation: 0.1s ${slideDown} ease-out;
    `
  ),
  SDropdownContentWrapper = styled.div(
    () => css`
      padding-top: 20px;
    `
  );
