import { useSelector } from 'react-redux';
import * as S from './styles';
import { getCurrentTheme } from 'store';
import { CopyButton } from 'pages/coursesConstructor/parts/dashboard/components/parts';

const CopyBlock = ({ refLink }: { refLink: string }) => {
  const theme = useSelector(getCurrentTheme);

  const handleCopy = () => {
    navigator.clipboard.writeText(refLink);
  };

  return (
    <S.CopyBox>
      <S.CopyBlock onClick={handleCopy} $theme={theme}>
        <span>{refLink}</span>
      </S.CopyBlock>
      <CopyButton
        handleCopy={handleCopy}
        style={{
          width: 48,
          minWidth: 48,
          height: 48,
          borderRadius: 4,
        }}
      />
    </S.CopyBox>
  );
};

export default CopyBlock;
