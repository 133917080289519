import styled from 'styled-components';
import hexToRgba from 'hex-to-rgba';

export const SRoot = styled.div(
  ({ theme }) => `
  padding: 11px 13px;
  border-radius: 5px;
  background: ${hexToRgba(theme.theme.basicYellow, 0.3)};
  backdrop-filter: blur(2px);
  color: var(--font-color);
  display: flex;
  align-items: center;
`
);
