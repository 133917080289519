import clsx from 'clsx';
import { HiArrowRight } from 'react-icons/hi';
import { BUTTON_STATES, IDailyRewardsProps } from './types';
import { useDailyRewards } from './hooks/useDailyRewards';
import { useDisplay } from 'hooks';

import { DailyRewardsCard } from './components/DailyRewardsCard/DailyRewardsCard';
import { CurrentRewards, NextRewards, Streak } from './components/ui';
import BasicButton from 'shared/ui/basic-button/button';
import { DailyRewardsPanel } from './components/DailyRewardsPanel/DailyRewardsPanel';
import { MultipurposeBannerLoader } from 'shared/ui';
import { DailyRewardsContext } from './context';

import { ReactComponent as BannerBg } from './assets/banner-bg.svg';
import { ReactComponent as PanelBg } from './assets/panel-bg.svg';
import { ReactComponent as Spinner } from 'assets/icons/spinner--pie.svg';

import styles from './styles.module.css';
import stylesToast from './stylesToast.module.css';

export const DailyRewardsAuth = ({ onMPBannerClose = () => null, isBannerOnly = false, className = '' }: IDailyRewardsProps) => {
  const { rewardUser, dailis, userDailis, isRewardable, buttonState, isLoading, streak } = useDailyRewards();

  const { isMobile, isMedium } = useDisplay();

  function handleButton() {
    if (buttonState === BUTTON_STATES.COLLECT) return rewardUser();
    if (buttonState === BUTTON_STATES.CONTINUE) return onMPBannerClose();
  }

  if (dailis?.length < 1) {
    return (
      <div className={clsx(styles.loaderWrap, 'mobile-padding')}>
        <MultipurposeBannerLoader className={clsx(styles.loader, { [styles.bannerOnly]: isBannerOnly })} label="Loading daily rewards..." />
      </div>
    );
  }

  const label = isLoading ? 'Collecting reward…' : isRewardable ? 'Collect your rewards every day' : 'Reward collected!';

  return (
    <DailyRewardsContext.Provider value={{ currentDailyRewards: dailis, isRewardable, rewardUser, isLoading, userDailis, streak }}>
      {isMobile ? (
        <DailyRewardsCard className={className} />
      ) : (
        <>
          <div className={clsx(styles.container, { [styles.bannerOnly]: isBannerOnly, [className]: !!className })}>
            <div className={styles.content}>
              <CurrentRewards
                handleProcessStep={async () => {}}
                rewards={dailis}
                userDailis={userDailis}
                isRewardable={isRewardable}
                variant={'banner'}
                onlyToday={isMedium}
              />
              <NextRewards rewards={dailis} variant={'banner'} label={label} fontSize={'26px'} userDailis={userDailis} isRewardable={isRewardable} />
              <div className={styles.streak}>
                <Streak variant={'banner'} />
                {isRewardable && (
                  <BasicButton
                    isLabelFirst
                    label={'Collect'}
                    variant={'cool'}
                    icon={<HiArrowRight />}
                    fullWidth
                    onClick={handleButton}
                    loading={isLoading}
                    loaderIcon={<Spinner className={clsx('spinner', styles.spinner)} />}
                    className={styles.button}
                  />
                )}
              </div>
            </div>
            <BannerBg className={styles.bannerBg} />
          </div>
          {!isBannerOnly && (
            <div className={clsx(stylesToast.container, { [stylesToast.bannerOnly]: isBannerOnly, [className]: !!className })}>
              <DailyRewardsPanel className={stylesToast.content} onClose={onMPBannerClose} />
              <PanelBg className={stylesToast.panelBg} />
            </div>
          )}
        </>
      )}
    </DailyRewardsContext.Provider>
  );
};
