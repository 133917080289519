import { useCallback, useEffect, useState } from 'react';
import { Modal } from 'shared/ui';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { object, string } from 'yup';
import { useDebounce, useGetCurrentTheme } from 'hooks';

import SpaceFormInput, { SpaceFormArea } from '../../SpaceCreation/components/ui/Input';
import TagsPicker from '../../SpaceCreation/components/TagsPicker';
import { EGroupTag, LANGS, THEMES } from 'app/constants';
import SlideSaver from 'components/library/messages/SlideSaver';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { toBase64 } from 'shared/utils/files/toBase64';
import { useGetUserRelatedSpaceInfo } from '../../hooks';
import { ERRORS, SpaceNameRegExp, WebsiteRegExp } from '../../SpaceCreation/stages/common';
import { useSpacePageContext } from '../../context';

import { ReactComponent as ChangeImageIcon } from '../../assets/change-image.svg';
import SpaceAdmins from './admins';
import { ISpace, useLazyGetIfSpaceWithThisNameExistsQuery, useUpdateSpaceImagesMutation, useUpdateSpaceMutation } from 'store';
import Answer from 'components/library/messages/Answer';

import { SpaceFormBox, SpaceFormError, SpaceFormLabel } from '../../SpaceCreation/components/ui/styles';
import * as S from './styles';
import styles from './modal.module.scss';

type Props = {
  isVisible: boolean;
};

const SpaceEditingModal = ({ isVisible }: Props) => {
  const debounce = useDebounce();
  const theme = useGetCurrentTheme();
  const [tags, setTags] = useState<EGroupTag[]>([]);
  const [initialized, setIsInit] = useState(false);
  const [updateInfo, { isLoading: isUpdatingInfo }] = useUpdateSpaceMutation();
  const [updateImage, { isLoading: isUpdatingImage }] = useUpdateSpaceImagesMutation();
  const [checkName] = useLazyGetIfSpaceWithThisNameExistsQuery();
  const { space, updateSpace, setModal } = useSpacePageContext();

  const navigate = useNavigate();

  const handleClose = () => setModal(null);

  const { isAbleToEdit } = useGetUserRelatedSpaceInfo(space || null);
  const formSchema = object({
    name: string()
      .required(ERRORS.name)
      .max(50, ERRORS.nameLen)
      .matches(SpaceNameRegExp, ERRORS.nameFormat)
      .test(
        'name',
        ERRORS.nameDuplicate,
        (value) =>
          new Promise((resolve) => {
            if (value === space?.name) {
              resolve(true);
              return;
            }
            if (!value) {
              resolve(false);
              return;
            }
            checkName(value)
              .unwrap()
              .then(() => resolve(false))
              .catch(() => {
                resolve(true);
              });
          })
      ),
    link: string().required(ERRORS.webiste).matches(WebsiteRegExp, ERRORS.websiteFormat),
    description: string().required(ERRORS.bio).max(250, ERRORS.bioMaxLen),
  });

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Pick<ISpace, 'link' | 'name'> & { description: string }>({
    resolver: yupResolver(formSchema),
    defaultValues: space
      ? {
          name: space.name,
          link: space.link,
          description: space.description.find((el) => el.language === LANGS.EN)?.title || '',
        }
      : {
          name: '',
          link: '',
          description: '',
        },
  });

  const handleGoodResult = (arg: { result: ISpace }) => {
    if (arg.result.name !== space?.name) {
      navigate('/' + arg.result.name.replaceAll(' ', '_'));
    }
    updateSpace(arg.result);
    toast.info(<SlideSaver isLoading={isUpdatingInfo} />, { autoClose: 1000 });
  };

  const handleBadResult = () => toast.error(<Answer label="Oops" subtext="Failed to save changes" />);
  const onTagSelect = (tag: EGroupTag) => {
    setTags((tags) => (tags.find((t) => t === tag) ? tags.filter((t) => t !== tag) : [...tags, tag]));
  };

  useEffect(() => {
    const debouncedUpdate = debounce(() => {
      if (tags.length && space && isAbleToEdit && isVisible && (tags.length !== space.tags.length || space.tags[0] !== tags[0])) {
        const { link, name, _id, description } = space;
        updateInfo({ link, name, spaceId: _id, description, tags }).unwrap().then(handleGoodResult).catch(handleBadResult);
      }
    }, 500);
    debouncedUpdate();
  }, [tags, isVisible]);

  useEffect(() => {
    if (space) {
      setTags(space.tags);
    }
  }, [isVisible, space]);

  useEffect(() => {
    if (!initialized && space) {
      setValue('name', space.name);
      setValue('link', space.link);
      setValue('description', space.description.find((el) => el.language === LANGS.EN)?.title || '');
      setIsInit(true);
    }
  }, [space, initialized]);

  const onFormChange = (data: Pick<ISpace, 'link' | 'name'> & { description: string }) => {
    const { description, link, name } = data;
    if (space && description && link && name && tags.length) {
      updateInfo({ link, name: name.trim(), spaceId: space._id, description: [{ language: LANGS.EN, title: description }], tags })
        .unwrap()
        .then(handleGoodResult)
        .catch(handleBadResult);
    }
  };

  const handleUpdateImage = useCallback(
    (target: 'image' | 'imageThumb') => async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files ? e.target.files[0] : '';
      const tileReady = await toBase64(file as File);
      if (space && !!file && !isUpdatingImage) {
        updateImage({ [target]: tileReady || '', spaceId: space._id })
          .unwrap()
          .then(handleGoodResult)
          .catch(handleBadResult);
      }
    },
    [space, isUpdatingImage, updateImage]
  );

  return (
    <Modal
      closeButtonLocation="outside"
      style={{ overflow: 'auto' }}
      className={`${styles.spaceModal} ${theme === THEMES.LIGHT ? styles.light : ''}`}
      containerClassName={styles.SpaceModalContainer}
      open={isVisible}
      onClose={handleClose}
    >
      <S.Back onClick={handleClose} />
      <S.Container>
        <S.BG image={space?.image}>
          <S.HiddenImgInput type="file" accept="image/*" onChange={handleUpdateImage('image')} />
          <S.ChangeBgBox>
            <S.ChangeBgButton>
              <ChangeImageIcon /> Change cover
            </S.ChangeBgButton>
            Banner size 1028x190px
          </S.ChangeBgBox>
        </S.BG>
        <S.ImagePlaceholder>
          <S.HiddenImgInput type="file" accept="image/*" onChange={handleUpdateImage('imageThumb')} />
          {!!space && space.imageThumb ? <S.Image src={space.imageThumb} /> : <S.PhotoIcon />}
          {!!space && space.imageThumb && <S.PhotoIcon $isAbsolute />}
        </S.ImagePlaceholder>
        <S.SpaceName>{space?.name}</S.SpaceName>
        <S.Box>
          <S.SpaceForm onChange={debounce(handleSubmit(onFormChange), 1500)}>
            <SpaceFormInput register={register} label="name" error={!!errors['name']} errorText={errors['name']?.message} labelToShow="Space Name" />
            <SpaceFormInput
              register={register}
              label="link"
              error={!!errors['link']}
              errorText={errors['link']?.message}
              labelToShow="Your Website"
            />
            <SpaceFormArea
              register={register}
              label="description"
              error={!!errors['description']}
              errorText={errors['description']?.message}
              labelToShow="Space bio"
              noPencil
            />
          </S.SpaceForm>
          <SpaceFormBox style={{ marginBottom: '32px' }}>
            <SpaceFormLabel>Categories</SpaceFormLabel>
            <TagsPicker theme={theme} in2cols tags={tags} handleTagSelect={onTagSelect} />
            <SpaceFormError $error={!tags.length}>Select at least 1 category</SpaceFormError>
          </SpaceFormBox>
          <SpaceAdmins />
        </S.Box>
      </S.Container>
    </Modal>
  );
};

export default SpaceEditingModal;
