import { FC, useRef, memo } from 'react';
import type { IResizableImageProps } from './types';
import { SImage, SWrapper, SImageControl, SImageWrapper, SControls, SContainer, SControlItem } from './styled';
import { useResizableImage } from './useResizableImage';

import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as OptionsIcon } from 'assets/icons/options.svg';
import { TbEdit } from 'react-icons/tb';

import { DropdownMotion } from 'shared/ui';

import Tabs from '../../../../../../../tabs';
import { IoIosResize } from 'react-icons/io';

export const ResizableImage: FC<IResizableImageProps> = memo(({ width, justifyContent = 'flex-start', src, alt, tabs, onChange, onDelete }) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const { widthState, position, percentWidth, setPosition, handleDragStart, handleDrag, handleDragEnd } = useResizableImage({
    width,
    justifyContent,
    onChange,
    containerRef,
  });

  return (
    <SWrapper ref={containerRef} $justifyContent={position}>
      <SContainer>
        <SImageWrapper $width={widthState} $height={'auto'} className={'root-transition'}>
          <SImage src={src} alt={alt} />
          <SImageControl draggable onDragStart={handleDragStart} onDrag={handleDrag} onDragEnd={handleDragEnd} $isLeft={false} $isGrabbing={true}>
            <IoIosResize />
          </SImageControl>
        </SImageWrapper>
        <SControls $isSmall={Number(percentWidth) <= 20}>
          <DropdownMotion
            triggerNode={
              <SControlItem $isSmall={Number(percentWidth) <= 20}>
                <OptionsIcon />
              </SControlItem>
            }
            items={[
              { title: 'start', onClick: () => setPosition('flex-start') },
              { title: 'center', onClick: () => setPosition('center') },
              { title: 'end', onClick: () => setPosition('flex-end') },
            ]}
          />
          <DropdownMotion
            triggerNode={
              <SControlItem $isSmall={Number(percentWidth) <= 20}>
                <TbEdit />
              </SControlItem>
            }
            variant={'secondary'}
          >
            <Tabs tabs={tabs} />
          </DropdownMotion>
          <SControlItem $isSmall={Number(percentWidth) <= 20} onClick={onDelete}>
            <TrashIcon />
          </SControlItem>
        </SControls>
      </SContainer>
    </SWrapper>
  );
});
