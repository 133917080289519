import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { Button } from './styles';
import useClickOutside from 'hooks/useClickOutside';
import clsx from 'clsx';

type Props = {
  icon?: any;
  className?: string;
  onClick: () => any;
};

export default function ConfirmingButton({ icon, className = '', onClick }: Props) {
  const theme = useSelector(getCurrentTheme);
  const [readyState, setReadyState] = useState(false);

  const ref = useClickOutside<HTMLButtonElement>(() => setReadyState(false));

  const handleClick = () => {
    if (readyState) {
      onClick();
    } else {
      setReadyState(true);
    }
  };

  return (
    <Button ref={ref} className={clsx(`glass-div linear ${theme} ${readyState && 'ready'}`, { [className]: !!className })} onClick={handleClick}>
      {icon}
    </Button>
  );
}
