export const strong_password_regs = {
  no_number: /^[^0-9]*$/, // строка не содержит цифр
  no_upper: /^[^A-Z]*$/, // строка не содержит заглавных букв
  no_lower: /^[^a-z]*$/, // строка не содержит строчных букв
  middle: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{5,}$/, // строка содержит миниму одну строчную и минимум одну заглавную букву, минимум одну цифру, и строка не менее 5 символов длиной
  strong: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*_\-\\\/()]).{7,}$/, // как middle, но дополнительно содержит хотя бы один специальный символ и длина строки не менее 7 символов
};

export type LowerPassword = 'no_lower' | 'no_upper' | 'no_number' | 'bad_length';

export interface IPasswordStrong {
  strong: 'none' | LowerPassword | 'middle' | 'high';
  title: string;
  backgroundColor: string;
}

export const getPasswordStrong = (value: string, translate: { [key: string]: string }): IPasswordStrong => {
  const { strong, middle, no_number, no_upper, no_lower } = strong_password_regs;

  const levels = [
    { name: 'high', reg: strong, title: translate.strong, backgroundColor: '#29AB88', minLength: 7 },
    { name: 'middle', reg: middle, title: translate.middle, backgroundColor: '#FFBF66', minLength: 5 },
    { name: 'no_lower', reg: no_lower, title: translate.no_lower, backgroundColor: '#F14F63', minLength: 0 },
    { name: 'no_upper', reg: no_upper, title: translate.no_upper, backgroundColor: '#F14F63', minLength: 0 },
    { name: 'no_number', reg: no_number, title: translate.no_number, backgroundColor: '#F14F63', minLength: 0 },
  ];

  for (let level of levels) {
    if (value?.length >= level.minLength && level.reg.test(value)) {
      return {
        strong: level.name as IPasswordStrong['strong'],
        title: level.title,
        backgroundColor: level.backgroundColor,
      };
    }
  }

  if (value?.length < 5) {
    return {
      strong: 'bad_length',
      title: translate.bad_length,
      backgroundColor: '#F14F63',
    };
  }

  return {
    strong: 'none',
    title: '',
    backgroundColor: 'transparent',
  };
};
