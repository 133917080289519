import { Fragment, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { Await, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { getUserData, profileApi, useLazyVisitUserProfileQuery, store, coursesApi } from 'store';
import { IProfile } from 'interface';
import { FRIEND_STATUS, ROUTES, THEMES } from 'app/constants';

import FriendsSmall from './FriendsSmall';
import ProfileCard from './profileCard/profile-card';
import Inventory from './Inventory/inventory';
import Courses from './Courses/courses';
import ShopBanner from './ShopBanner/banner';
import InventoryInfo from './InventoryInfo/invinfo';
import ItemsTransfer from './DiscordInvite/discord';
import Pockets from './Pockets';
import { useGlobalLoader } from 'shared/utils/GlobalLoader/useGlobalLoader';
import { ErrorHandler } from 'shared/utils/ErrorHandler';

import styles from './styles.module.scss';
import './styles/userprofile.css';
import { expTokenItem } from 'utils/items';

export const otherUserProfileLoader = ({ params }: any): any => {
  const { userId }: { userId: string } = params;

  const profileByIdLoader = store.dispatch(profileApi.endpoints.visitUserProfile.initiate(userId));
  const coursesStartedFromLoader = store.dispatch(coursesApi.endpoints.getStartedCourses.initiate({ userId, max: 4 }));

  try {
    const responseCourses = coursesStartedFromLoader.unwrap().catch(ErrorHandler);
    const responseProfile = profileByIdLoader.unwrap().catch(ErrorHandler);

    return {
      coursesStartedFromLoader: responseCourses,
      profileByIdLoader: responseProfile,
    };
  } catch (e) {
    throw new Error('An error occurred while loading courses.');
  } finally {
    profileByIdLoader.unsubscribe();
    coursesStartedFromLoader.unsubscribe();
  }
};

const OtherUserProfile = () => {
  // @ts-ignore
  const { profileByIdLoader, coursesStartedFromLoader } = useLoaderData();

  const { userId } = useParams();
  const navigate = useNavigate();
  const theme = useSelector(getCurrentTheme);
  const [, { isLoading }] = useLazyVisitUserProfileQuery();
  const { _id: currentUserId } = useSelector(getUserData);

  const figure = '/assets/bg_shapes_blue.png';
  const figureLight = '/assets/bg_shapes_light.png';

  useEffect(() => {
    if (currentUserId === userId) {
      navigate(ROUTES.MAIN, { replace: true });
    }
  }, [profileByIdLoader, userId]);

  useGlobalLoader({ dependency: [isLoading] });

  const userFriends = useCallback(
    (userProfile: IProfile) =>
      userProfile.friends.filter((i) => [FRIEND_STATUS.FRIENDS, FRIEND_STATUS.PENDING].includes(i.status)).map((fr) => fr.recipient),
    []
  );

  return (
    <Await
      resolve={Promise.all([coursesStartedFromLoader, profileByIdLoader]).then((values) => ({
        coursesStarted: values[0],
        userProfile: values[1],
      }))}
    >
      {(values) => {
        const { userProfile, coursesStarted } = values;
        const expItem = { ...expTokenItem, quantity: userProfile.exp.balance.amount };
        const inventory = [expItem, ...userProfile.exp.items];

        return (
          <Fragment>
            <div className={styles.figureWrapper}>
              <img alt="profile-bg" src={theme === THEMES.LIGHT ? figureLight : figure} />
            </div>
            <div className="base profile">
              <ProfileCard userProfile={userProfile} />
              <Pockets
                leftPockets={[
                  {
                    label: 'Inventory',
                    node: <Inventory inventory={inventory} />,
                  },
                ]}
                rightPockets={[
                  { label: 'Courses', node: <Courses coursesGiven={coursesStarted} onCourseCapture={() => {}} /> },
                  {
                    label: 'Friends',
                    node: (
                      <div className="mobile-padding">
                        <FriendsSmall friends={userFriends(userProfile)} />
                      </div>
                    ),
                  },
                ]}
              />
              <div className="banners mobile-padding">
                <ShopBanner />
                <InventoryInfo />
                <ItemsTransfer />
              </div>
            </div>
          </Fragment>
        );
      }}
    </Await>
  );
};

export default OtherUserProfile;
