import { FC, ReactNode } from 'react';
import { motion } from 'framer-motion';
import { useDisplay } from 'hooks';
import { ICustomDivMotionComponent } from 'shared/ui/motion';

export interface IInViewProps extends ICustomDivMotionComponent {
  children: ReactNode;
  forcedOnMobile?: boolean;
}

export const InView: FC<IInViewProps> = ({ children, whileInView = { opacity: 1, x: 0 }, forcedOnMobile = false, ...rest }) => {
  const { isMobile } = useDisplay();

  return (
    <motion.div
      style={{ willChange: 'opacity transform' }}
      initial={isMobile && !forcedOnMobile ? {} : { opacity: 0, x: 100 }}
      whileInView={isMobile && !forcedOnMobile ? {} : whileInView}
      viewport={isMobile && !forcedOnMobile ? {} : { once: true, amount: 0.3 }}
      transition={isMobile && !forcedOnMobile ? {} : { type: 'just' }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
