import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      &,
      &:focus {
        height: 18px;
        aspect-ratio: 2 / 1;
        overflow: hidden;
        border-radius: 50vh;
        transition: all 0.15s ease-in-out;
      }
      &:hover {
        filter: brightness(1.2);
      }

      &.xl {
        height: 30px;
        width: 54px;
      }

      &:has(input:disabled) {
        opacity: 0.6;
      }

      .xl span:before {
        width: 25px;
        height: 25px;
        top: 2px;
        left: 2px;
        border-radius: 50%;
      }
    `
  ),
  Label = styled.label(
    () => css`
      position: relative;

      & input:checked + span {
        background: linear-gradient(90deg, rgba(100, 135, 239, 1), rgba(212, 184, 255, 1));
      }

      & input:checked + span::before {
        left: calc(100% - 1.5px);
        transform: translateX(-100%);
        background: white;
      }
    `
  ),
  Input = styled.input(() => css``),
  Span = styled.span(
    () => css`
      display: flex;
      cursor: pointer;
      width: 100%;
      height: 100%;
      background: #36373f;
      position: relative;
      transition: 0.2s;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        translate: 0 -50%;
        left: 4%;
        width: auto;
        height: 87%;
        aspect-ratio: 1 / 1;
        border-radius: 100%;
        transition: 0.2s;
        background: #afb4b8;
      }
      &.light::before {
        background: #fff;
      }
      &:active::before {
        width: 55%;
      }
    `
  );
