import { useFetchSearchItems } from 'hooks/useFetchSearchItems';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export const useSearch = () => {
  const { fetch: fetchSearch, isLoading } = useFetchSearchItems();
  const [searchedItems, setSearchedItems] = useState<any[]>([]);
  const [showSearch, setShowSearch] = useState(false);
  const [userInput, setUserInput] = useState('');

  const navigate = useNavigate();

  async function giveItems() {
    if (!userInput) {
      setShowSearch(false);
      setSearchedItems([]);
      return;
    }

    const result = await fetchSearch(userInput);
    if (result) {
      setSearchedItems(result);
    }
  }

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => setUserInput(e.target.value);

  const goToItem = useCallback(
    (link: string, params: any) => {
      setShowSearch(false);

      navigate(link, { state: { ...params, input: userInput } });
    },
    [navigate, userInput]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowSearch(true);
      giveItems();
    }, 1000);

    return () => clearTimeout(timeout);
  }, [userInput]);

  function handleSearchFocus() {
    setShowSearch(true);
  }

  useEffect(() => {
    const listener = (event: KeyboardEvent) => {
      if ((event.code === 'Enter' || event.code === 'NumpadEnter') && userInput) {
        event.preventDefault();
        setShowSearch(false);
        let inp = userInput;
        setUserInput('');
        navigate('/search', { state: { input: inp } });
      }
    };
    document.addEventListener('keydown', listener);
    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, [userInput, goToItem, searchedItems, navigate]);

  return { handleSearchFocus, isLoading, handleInput, goToItem, searchedItems, showSearch, setShowSearch, userInput };
};
