import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';
import CountUp from 'react-countup';
import { ICustomization } from 'interface';

import coin from 'components/library/images/ShopInventory/exp_token_200x200.png';

import styles from './styles.module.css';
import { IColor } from '../../../components/pages/menu/screens/profile/components/ChangerList/types/types';
import { LazyImage } from '../lazyImage';

interface IProps {
  className?: string;
  exp: number;
  isShowImage?: boolean;
  background?: IColor;
  customization: ICustomization;
}

export default function UserExpLabel({ className = '', exp, isShowImage = true, background, customization }: IProps) {
  const theme = useSelector(getCurrentTheme);

  const prevCountRef = useRef<number>(0);

  useEffect(() => {
    prevCountRef.current = exp;
  }, [exp]);

  return (
    <div className={clsx(styles.container, styles[theme], { [className]: !!className }, `fancy-border bw--2`)}>
      {isShowImage && <LazyImage className={styles.coin} src={coin} alt="" />}
      <CountUp className={clsx(styles.exp, styles[theme], { [styles.newColor]: background })} useEasing={true} duration={3} suffix=" EXP" end={exp} />
    </div>
  );
}
