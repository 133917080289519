import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      position: relative;
      width: var(--toast-width);
      height: 103px;
      display: flex;
      align-items: center;
      padding: 0 21px 0 5px;
      backdrop-filter: blur(5px);

      .activated__message-bg {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
      }
      &.mobile {
        width: 100%;
        .activated__message-bg {
          width: 100%;
        }
      }
    `
  ),
  Content = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 6px;
      width: 100%;
    `
  ),
  ImgWrap = styled.div(
    () => css`
      position: relative;
      width: 86px;
      height: 86px;

      img {
        width: 100%;
      }

      span {
        position: absolute;
        bottom: 5px;
        right: 4px;
        max-width: 55px;

        padding: 4px 6px;
        border-radius: 5px;
        background: #fff;

        color: #25262f;
        font-size: 14px;
        line-height: 100%;
        text-align: center;
        white-space: pre;
      }
    `
  ),
  Desc = styled.div(
    () => css`
      flex: 1;
      height: 100%;
      align-self: flex-start;
      margin-top: 5px;

      h5 {
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
        display: inline;
      }

      p,
      span {
        color: #fdfdfd;
        font-size: 14px;
        font-weight: 400;
        line-height: 125%;
      }

      p {
        margin-top: 5px;
        max-width: 141px;
      }
    `
  );
