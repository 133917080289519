import styled from 'styled-components';
import { motion } from 'framer-motion';

import { IGeneralProps } from './types';
import { UIParent } from './parent';

interface IProps extends IGeneralProps {
  gridTemplateColumns?: string;
  gridTemplateRows?: string;
  gap?: string;
}

export const Grid = styled(UIParent).attrs<IProps>(({ as = 'div' }) => ({ as }))<IProps>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns || 'auto'};
  grid-template-rows: ${({ gridTemplateRows }) => gridTemplateRows || 'auto'};
  gap: ${({ gap }) => gap || '0'};
`;

export const MotionGrid = motion(Grid);
