import { ICourse, IResultCourse } from 'interface/courses';
import { memo, TouchEvent, TouchEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce, useDisplay, useGetCurrentTheme, useGetIsAuth, useSwipe } from 'hooks';
import { useLazyGetCourseWithProgressQuery } from 'store';
import { useParams } from 'react-router-dom';

import { LessonChaptersProgress } from './chapters';

import * as S from './styles';
import clsx from 'clsx';

type Props = {
  resultCourse: IResultCourse;
  course: ICourse;
  changeMobileMenuState: (arg: 'progress' | 'menu' | null) => void;
  menuState: 'progress' | 'menu' | null;
};

const LessonProgressItself = ({ course, resultCourse, menuState }: Props & { forceHovered?: boolean }) => {
  const { chapterId, lessonId } = useParams();
  const [containerHeight, setContainerHeight] = useState(0);
  const [scrollTop, setScrollTop] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const current = ref?.current;
    if (!current) return;
    const handler = () => {
      setScrollTop(current?.scrollTop ?? 0);
    };
    current.addEventListener('scroll', handler);
    return () => current.removeEventListener('scroll', handler);
  }, [ref]);

  useEffect(() => {
    const current = ref?.current;
    if (!current) return;
    setContainerHeight(current.getBoundingClientRect().height);
  }, [ref]);

  const resultLesson = useMemo(() => {
    const resChapter = resultCourse.resultChapters.find((el) => el.chapterId === chapterId);
    if (!resChapter) return;
    const resLesson = resChapter.resultLessons.find((el) => el.lessonId === lessonId);
    if (!resLesson) return;
    return resLesson;
  }, [resultCourse, lessonId, chapterId]);

  const lesson = useMemo(() => {
    if (!course) return;
    const chapter = course.chapters.find((el) => el._id === chapterId);
    if (!chapter) return;
    const lesson = chapter.lessons.find((el) => el._id === lessonId);
    if (!lesson) return;
    return lesson;
  }, [course, lessonId, chapterId]);

  if (!course || !resultLesson || !lesson) {
    return null;
  }

  const preventSwipe: TouchEventHandler<HTMLDivElement> = (e: TouchEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
  };

  return (
    <S.Container ref={ref} onTouchMove={preventSwipe} className={clsx({ isOpen: menuState === 'progress' })}>
      <LessonChaptersProgress
        key={'chaps-progress'}
        parentHeight={containerHeight - 50} // cause of padding bottom
        scrollTop={scrollTop}
        chapters={course.chapters}
        resultCourse={resultCourse}
      />
    </S.Container>
  );
};

const LessonProgressMobile = (props: Props) => {
  const theme = useGetCurrentTheme();
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    direction: 'vertical',
    handleLeftTop: () => props.changeMobileMenuState('progress'),
    handleRightBottom: () => props.changeMobileMenuState(null),
    minSwipeDistance: 150,
  });

  const isOpen = props.menuState === 'progress';

  return (
    <S.MobileContainer
      $theme={theme}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={onTouchEnd}
      initial={{ y: 446 }}
      animate={{ y: isOpen ? 0 : 446 }}
      $isOpen={isOpen}
      transition={{ bounce: 0 }}
      className='blur-30'
    >
      <LessonProgressItself {...props} forceHovered />
    </S.MobileContainer>
  );
};

const LessonProgress = (props: Props) => {
  const [getCourse] = useLazyGetCourseWithProgressQuery();
  const [course, setCourse] = useState<null | ICourse>(null);
  const debounce = useDebounce();
  const { lessonId } = useParams();
  const { isMobile } = useDisplay();
  const isAuth = useGetIsAuth();

  const refetch = useCallback(
    debounce(() => {
      getCourse(props.course._id)
        .unwrap()
        .then((res) => setCourse(res))
        .catch(console.error);
    }, 500),
    [course, props.resultCourse, isAuth, getCourse, lessonId]
  );

  useEffect(() => {
    refetch();
  }, [props.resultCourse, isAuth, lessonId]);

  if (!course) {
    return null;
  }

  return isMobile ? <LessonProgressMobile {...props} course={course} /> : <LessonProgressItself {...props} course={course} />;
};

export default memo(LessonProgress);
