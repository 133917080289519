import styled, { css } from 'styled-components';

export const ExpTokens = styled.div(
  () => css`
    position: relative;
    width: 100px;
    height: 100px;

    img {
      &:nth-child(1) {
        width: 100%;
      }

      &:nth-child(2) {
        position: absolute;
        top: -14px;
        right: -10px;
        max-width: 49px;
        transform: rotate(49deg);
      }

      &:nth-child(3) {
        position: absolute;
        bottom: -5px;
        right: -2px;
        max-width: 35px;
        transform: rotate(-62deg);
      }
    }
  `
);
