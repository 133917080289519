import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      gap: 6px;
      overflow-x: scroll;
      overflow-y: hidden;
      padding: 5px var(--padding-mobile) 5px;
      box-sizing: content-box;
      /* padding-right: var(--padding-mobile); */
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
    `
  ),
  Item = styled.div(
    () => css`
      height: 100%;
      text-transform: capitalize;
      background: rgba(255, 255, 255, 0.2);
      backdrop-filter: blur(9px);
      border-radius: 46px;
      padding: 9px 21px;
      color: white;
      transition: all 0.1s ease-in-out;
      user-select: none;
      white-space: pre;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &.active {
        background: rgba(255, 255, 255, 0.45);
      }
      &:active {
        background: rgba(255, 255, 255, 0.55);
      }

      &.icon {
        padding: 8px;

        svg {
          height: 100%;
          max-height: 100%;
          width: auto;
        }
      }
    `
  ),
  Counter = styled.span(
    () => css`
      position: absolute;
      top: -4px;
      right: -4px;
      width: 18px;
      height: 18px;
      background: var(--red-color);
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--font-color-strong);
      font-size: 12px;
      font-weight: 500;
      line-height: 100%;
    `
  );
