import * as Styled from './styles';

export const NoEnergy = () => {
  return (
    <Styled.Container>
      <Styled.Header>No energy :(</Styled.Header>
      <Styled.Span>Learn more</Styled.Span>
    </Styled.Container>
  );
};
