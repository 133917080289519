import { FC, useMemo } from 'react';

import styles from './styles.module.css';
import { parseIfJson } from '../../../../../../../../utils/object';
import { TFileMetadata } from '../types';
import { buildStaticUrl } from '../../utils/media-helpers';

type TVideoProps = {
  meta: string;
};

enum EVideoTypes {
  COMMON = 'common',
  YOUTUBE = 'youtube',
}

const Video: FC<TVideoProps> = ({ meta }) => {
  const content = parseIfJson<TFileMetadata>(meta) || meta;

  const videoType = useMemo(() => {
    if (typeof content === 'string' && /https:\/\/(www\.)?youtube.com/gi.test(content)) {
      return EVideoTypes.YOUTUBE;
    }

    return EVideoTypes.COMMON;
  }, [content]);

  const getYoutubeLink = (link: string) => {
    const url = new URL(link);
    if (url.pathname.includes('embed')) {
      return link;
    }

    let result = `${url.origin}/embed/${url.searchParams.get('v')}`;

    return result;
  };

  return (
    <div className={styles.container}>
      {videoType === EVideoTypes.COMMON ? (
        <video src={buildStaticUrl(content)} controls title={typeof content !== 'string' ? content.name : 'Video'} />
      ) : videoType === EVideoTypes.YOUTUBE ? (
        <iframe title="Video" id="ytplayer" width="640" height="360" src={getYoutubeLink(content as string)} />
      ) : null}
    </div>
  );
};

export default Video;
