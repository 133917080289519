import { IChapter, ICourse, ISlideFull } from 'interface/courses';
import { useEffect, useState } from 'react';
import { ESlideWidgetTypes, ISlideWidget, coursesConstructorAPI } from 'store';

export const useGetFirstTaskWidgetByCourseId = (courseId: string) => {
  // Temporary. Returns nothing if there is no task in 1st slide
  const [isLoading, setLoading] = useState(true);
  const [isError, setError] = useState(false);
  const [lessonId, setLessonId] = useState<string | null>(null);
  const [widget, setWidget] = useState<null | ISlideWidget>(null);
  const { data: course, isLoading: isLoadingCourse } = coursesConstructorAPI.useFetchCourseQuery(courseId);
  const [fetchChapter] = coursesConstructorAPI.useLazyFetchChapterQuery();
  const [fetchSlides] = coursesConstructorAPI.useLazyFetchSlidesQuery();

  useEffect(() => {
    setError(false);
    setLoading(true);
    if (course && course.chapters.length === 1) {
      fetchChapter((course as ICourse).chapters[0]._id)
        .unwrap()
        .then((res: IChapter) => {
          console.log(res, 'CHAPTER');
          if (res.lessons.length !== 1) {
            setError(true);
            return;
          }
          const lessonId = res.lessons[0]._id;
          fetchSlides(lessonId)
            .unwrap()
            .then((res: { slides: ISlideFull[] }) => {
              console.log(res, 'LESSON');
              const { slides } = res;
              if (slides.length !== 1) {
                setError(true);
                return;
              }
              const widget = slides[0].content.find((el) => el.type === ESlideWidgetTypes.TASK);

              if (!widget) {
                setError(true);
                return;
              }
              setWidget(widget);
              setLessonId(lessonId);
            })
            .catch(() => setError(true));
        })
        .catch(() => setError(true))
        .finally(() => setLoading(false));
    }
  }, [course]);

  useEffect(() => {
    if (!course && !isLoadingCourse) {
      setError(true);
    }
  }, [isLoadingCourse, course]);

  return { isLoading, isError, task: widget, lessonId };
};

export function RGBAToHexA(rgba: string, forceRemoveAlpha = false) {
  return (
    '#' +
    rgba
      .replace(/^rgba?\(|\s+|\)$/g, '') // Get's rgba / rgb string values
      .split(',') // splits them at ","
      .filter((string, index) => !forceRemoveAlpha || index !== 3)
      .map((string) => parseFloat(string)) // Converts them to numbers
      .map((number, index) => (index === 3 ? Math.round(number * 255) : number)) // Converts alpha to 255 number
      .map((number) => number.toString(16)) // Converts numbers to hex
      .map((string) => (string.length === 1 ? '0' + string : string)) // Adds 0 when length of one number is 1
      .join('')
  ); // Puts the array to togehter to a string
}

export const colorToHex = (color: string, forceRemoveAlpha = false) => {
  if (color.includes('rgb')) {
    return RGBAToHexA(color, forceRemoveAlpha);
  } else {
    return color;
  }
};
