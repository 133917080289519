import styled, { css } from 'styled-components';

export const SRoot = styled.div(() => css``),
  SInputWrapper = styled.div(
    () => css`
      font-size: 16px;
      height: 34px;
      background: var(--basic-blue);
      border-radius: 5px;
      display: flex;
      align-items: center;
      padding: 0 10px;
      color: var(--font-color-strong);
      font-weight: bold;
      gap: 5px;

      .invalid-answers__input {
        font-weight: bold;
        color: var(--font-color-strong);
      }
    `
  );
