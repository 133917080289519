import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      min-width: 128px;
      width: 100%;
      max-width: 100%;
      min-height: 33px;
      height: 100%;
      position: relative;
      border-radius: 65px;
      padding: 0 8px;
      background: rgba(255, 255, 255, 0.1);
      transition: background-color 0.25s ease-in-out;
      transition: all 0.3s ease;

      &.checkbox {
        border-radius: 15px;
        padding: 0 2px;
      }

      &:has(input[type='checkbox']:checked),
      &:has(input[type='radio']:checked) {
        background: #6487ef;
      }
      &:has(input[type='checkbox']:checked).right-false,
      &:has(input[type='radio']:checked).right-false {
        background: #f14f63;
      }
      &:has(input[type='checkbox']:checked).right-true,
      &:has(input[type='radio']:checked).right-true {
        background: #26a17b;
      }
      &:has(input[type='checkbox']:checked) span,
      &:has(input[type='radio']:checked) span {
        color: white;
      }
    `
  ),
  Label = styled.label(
    () => css`
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      min-height: 66px;
      width: 100%;
      padding: 20px 30px;
      position: relative;
      cursor: pointer;
      line-break: anywhere;

      & > input {
        position: absolute;
        z-index: -1;
        opacity: 0;
      }
    `
  ),
  Input = styled.input(
    () => css`
      height: 38px;
      width: 38px;
      min-height: 38px;
      min-width: 38px;
      color: var(--basic-dark);

      & > svg {
        width: 100%;
        height: 100%;
        color: var(--color);
      }
    `
  ),
  Span = styled.span(
    () => css`
      display: inline-flex;
      align-items: center;
      gap: 10px;
      user-select: none;
      font-style: normal;
      font-weight: 400;
      font-size: 1.5rem;
      line-height: 1.5;
      line-break: auto;
      color: var(--font-color);
    `
  );
