import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 37px;
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 19px;
`;

export const Topic = styled.p<{ $theme: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.DARK ? '#e0e0e0' : '#111')};
  font-size: 31px;
  line-height: 100%;
`;

export const SpacesList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  gap: 10px;
`;

export const NewSpace = styled.div<{ $theme: THEMES }>`
  height: 120px;
  width: 100%;
  color: ${(p) => (p.$theme === THEMES.DARK ? '#ffffff66' : '#11111166')};
  background: ${(p) => (p.$theme === THEMES.DARK ? '#ffffff0f' : '#1111110f')};
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  gap: 6px;
  font-size: 20px;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
    filter: brightness(1.01);
  }
  @media screen and (max-width: 992px) {
    height: 100%;
    min-height: 96px;
    max-height: 159px;
    flex-direction: column;
    align-items: center;
  }
`;

export const ItemContainer = styled.div<{ color?: string }>`
  height: 120px;
  border-radius: 8px;
  display: grid;
  /* justify-content: center; */
  grid-template-columns: 72px auto;
  gap: 16px;
  align-items: center;
  transition: 0.3s;
  padding: 0px 17px;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  cursor: pointer;

  &.${THEMES.DARK} {
    background: ${(p) => p.color || 'rgba(255,255,255,0.1)'};
  }
  &.${THEMES.LIGHT} {
    border: ${(p) => `2px solid ${p.color}`};
  }
  &:hover {
    filter: brightness(1.01);
  }

  @media screen and (max-width: 992px) {
    height: auto;
    max-height: 159px;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
  }
`;

export const ItemImage = styled.img`
  width: 76px;
  height: 76px;
  border-radius: 50%;
  object-fit: cover;
`;

export const ItemInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  @media screen and (max-width: 992px) {
    align-items: center;
  }
`;

export const ItemName = styled.p<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  gap: 5px;
  line-height: 100%;
  & > span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 120px;
    color: ${(p) => (p.$theme === THEMES.DARK ? '#fff' : '#111')};
  }
  @media screen and (max-width: 992px) {
    justify-content: center;
    & > span {
      text-align: center;
      width: 150px;
    }
  }
`;

export const ItemBottom = styled(motion.div)<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${(p) => (p.$theme === THEMES.DARK ? '#fdfdfd' : '#111')};
  & path {
    stroke: ${(p) => (p.$theme === THEMES.DARK ? '#fdfdfd' : '#111')};
  }
`;

export const EditButton = styled.div<{ $theme: THEMES }>`
  width: 100%;
  height: 41px;
  padding: 0px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;
  cursor: pointer;
  color: ${(p) => (p.$theme === THEMES.DARK ? 'white' : '#111')};
  border-radius: 50px;
`;
