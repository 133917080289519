import { IAnswer } from 'store/answers/types';
import * as S from './styles';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { ReactComponent as QuestionIcon } from '../assets/questionMark.svg';
import { useMemo, useState } from 'react';
import { IRewardMinimal, getCurrentTheme } from 'store';
import RewardsPicker from './RewardsPicker';
import { AnimatePresence } from 'framer-motion';
import { BasicButtonV1 } from 'shared/ui';
import { useSelector } from 'react-redux';
import { useArchiveAnswerMutation } from 'store/answers/answers.api';
import { getFormatDate } from 'utils/date';
import { useNavigate, useParams } from 'react-router-dom';

type Props = {
  answer: IAnswer;
  removeAnswer: (_id: string) => void;
};

const Answer = ({ answer, removeAnswer }: Props) => {
  const [rewards, setRewards] = useState<IRewardMinimal[]>([]);
  const [isHover, setHover] = useState(false);
  const { spaceName } = useParams();
  const navigate = useNavigate();

  const totalRewards = useMemo(() => rewards.reduce((acc, el) => (acc += el.quantity), 0), [rewards]);
  const theme = useSelector(getCurrentTheme);
  const [archive, { isLoading }] = useArchiveAnswerMutation();

  const handleRewardsChange = (id: number, quantity: number) => {
    setRewards((s) => {
      const restOfstate = s.filter((el) => el.id !== id);
      const item = { id, quantity };
      return [...restOfstate, item].sort((a, b) => a.id - b.id);
    });
  };

  const goToSlide = () => {
    if (spaceName) {
      navigate(`/${spaceName}/lesson/${answer.lessonId}#${answer.slideId}`);
    } else {
      navigate(`/courses/edit/lessons/${answer.lessonId}#${answer.slideId}`);
    }
  };

  const handleApprove = () => {
    archive({ id: answer._id, rewards: rewards.filter((el) => !!el.quantity), slideId: answer.slideId })
      .unwrap()
      .then(() => {
        setRewards([]);
        removeAnswer(answer._id);
      })
      .catch(console.error);
  };

  const handleDecline = () => {
    archive({ id: answer._id, slideId: answer.slideId })
      .unwrap()
      .then(() => {
        console.log('Answer declined');
        removeAnswer(answer._id);
      })
      .catch(console.error);
  };

  const question = useMemo(() => {
    return answer.possibleAnswers.map((el, index) => <span key={el.text + index}>{el.text}</span>);
  }, [answer.possibleAnswers]);

  return (
    <S.Container $theme={theme} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
      <S.Path onClick={goToSlide} $theme={theme}>
        {answer.path}
      </S.Path>
      <S.Box $theme={theme}>
        <S.ProfileBox>
          <AvatarPic avatar={answer.userId.avatar} nickname={answer.userId.nickname} />
          <S.ProfileInfo>
            <S.Nickname $theme={theme}>{answer.userId.nickname}</S.Nickname>
            <S.Mail $theme={theme}>{answer.userId.email}</S.Mail>
            <S.Date $theme={theme}>{getFormatDate(new Date(answer.createdAt), true)}</S.Date>
          </S.ProfileInfo>
        </S.ProfileBox>
        <S.FlexColBox>
          <QuestionIcon />
          <S.Content isQuestion $theme={theme}>
            {/* @ts-ignore */}
            {question}
          </S.Content>
        </S.FlexColBox>
        <S.FlexColBox nm>
          <S.Label $theme={theme}>Student's answer</S.Label>
          <S.Content $theme={theme}>{answer.userAnswer}</S.Content>
        </S.FlexColBox>
        <AnimatePresence>
          {(isHover || isLoading) && (
            <S.Footer
              animate={{ height: 'auto', opacity: 1, marginTop: '20px' }}
              initial={{ height: 0, opacity: 0, marginTop: 0 }}
              exit={{ height: 0, opacity: -1, marginTop: 0 }}
            >
              <S.Label $theme={theme}>award designation </S.Label>
              <RewardsPicker rewards={rewards} handleRewardsChange={handleRewardsChange} />
              <S.Buttons>
                <BasicButtonV1
                  onClick={handleApprove}
                  disabled={!totalRewards}
                  isLoading={isLoading}
                  style={{ width: 'fit-content' }}
                  variant="solid"
                  size="sm"
                >
                  Approve & gift reward
                </BasicButtonV1>
                <BasicButtonV1 isLoading={isLoading} onClick={handleDecline} style={{ width: 'fit-content' }} variant="outline" size="sm">
                  Decline
                </BasicButtonV1>
              </S.Buttons>
            </S.Footer>
          )}
        </AnimatePresence>
      </S.Box>
    </S.Container>
  );
};

export default Answer;
