import { DetailedHTMLProps, forwardRef, HTMLInputTypeAttribute, InputHTMLAttributes } from 'react';
import styles from './styles.module.css';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import TransparentTextarea from 'components/library/transparentTextarea';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  icon?: any;
  isError?: boolean;
  type?: 'textarea' | HTMLInputTypeAttribute;
  maxWidth?: number | string;
}

export const Input = forwardRef<HTMLInputElement, IProps>(
  ({ icon: Icon, isError, value = '', className = '', type = 'input', maxWidth, ...rest }, forwardedRef) => {
    const theme = useSelector(getCurrentTheme);
    const ref = forwardedRef as React.MutableRefObject<HTMLInputElement | null>;

    const onInputFocus = () => {
      if (ref && ref.current) {
        ref.current.focus();
      }
    };

    const customOnChange = (e: any) => {
      if (rest.onChange) {
        if (type === 'number') {
          const sanitizedValue = e.target.value.replace(/[^0-9]/g, '');
          const { min, max } = rest;
          const clampedValue = Math.min(Math.max(sanitizedValue, Number(min) || -Infinity), Number(max) || Infinity);

          e.target.value = clampedValue.toString();

          rest.onChange(e.target.value);
        }
        return rest.onChange(e);
      }
    };

    return (
      <div
        style={{ maxWidth }}
        onClick={onInputFocus}
        className={`${clsx(styles.container, {
          [className]: !!className,
          [styles.error]: isError,
          [styles.hasValue]: value,
          [styles[theme]]: theme,
        })} glass-div basic ${theme}`}
      >
        {Icon && <Icon className={styles.icon} />}
        {type === 'textarea' ? (
          // @ts-ignore
          <TransparentTextarea ref={ref} className={styles.input} {...rest} />
        ) : (
          <input ref={ref} type={type} className={clsx(styles.input, styles[theme])} onInput={customOnChange} {...rest} />
        )}
      </div>
    );
  }
);
