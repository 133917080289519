import * as S from './styles';
import chestImg from '../../assets/chest.png';
import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';

const ChestsTab = () => {
  const handleLink = () => {
    window.open('https://discord.gg/7cdjn7QZ6M', '_blank');
  };

  return (
    <S.Tab initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      <S.Image src={chestImg} />
      <S.Title>Your company does not have any chests</S.Title>
      <S.Subtitle>Please contact us at discord to discuss the terms and conditions for creating your unique chest</S.Subtitle>
      <S.DiscordButton onClick={handleLink} icon={DiscordIcon}>
        Contact
      </S.DiscordButton>
    </S.Tab>
  );
};

export default ChestsTab;
