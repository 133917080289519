import { FC, ReactNode } from 'react';
import { createPortal } from 'react-dom';
import { SWrapper, SContainer, STitle, SSubtitle, SRewardsWrapper } from './styles';
import BasicButton from '../../ui/basic-button/button';
import LittleItemsCarousel from '../../ui/little-items-carousel';
import InventoryItem from 'components/library/cards/inventory/InventoryItem';
import styles from './styles.module.scss';
import { useDisplay } from 'hooks';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { IDatabaseItemQuantified } from 'interface';
import { EPortals } from 'app/constants';

const wrapperVariants = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  },
};
const containerVariants = {
  initial: {
    scale: 0.5,
  },
  animate: {
    scale: 1,
    transition: { type: 'spring', stiffness: 300 },
  },
  exit: {
    scale: 0.5,
    transition: {
      duration: 0.3,
    },
  },
};

interface IFullScreenCongratulationsProps {
  title?: string | ReactNode;
  subtitle?: string | ReactNode;
  secondSubtitle?: string | ReactNode;
  rewards: IDatabaseItemQuantified[];
  buttonTitle?: string;
  open?: boolean;
  onClickButton: () => void;
  portal?: EPortals;
}
export const FullscreenCongratulations: FC<IFullScreenCongratulationsProps> = ({
  portal = EPortals.FULLSCREEN,
  title = 'Congratulations!',
  subtitle = "Here's what you get:",
  secondSubtitle = '',
  rewards,
  buttonTitle = 'Continue',
  open = false,
  onClickButton,
}) => {
  const { isMobile } = useDisplay();

  return createPortal(
    <AnimatePresence>
      {open && (
        <SWrapper as={motion.div} variants={wrapperVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
          <SContainer as={motion.div} variants={containerVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
            <STitle>{title}</STitle>
            <SSubtitle>{subtitle}</SSubtitle>
            <SSubtitle>{secondSubtitle}</SSubtitle>
            {isMobile ? (
              <SRewardsWrapper className={clsx(styles.rewardsWrapper, 'tiny-sb', rewards.length === 1 ? 'singleChild' : '')}>
                {[...rewards].reverse().map((reward, index) => (
                  <InventoryItem
                    key={reward.createdAt.toString() + index}
                    amount={reward.quantity}
                    itemId={reward.id}
                    className={styles.reward}
                    navigateOnClick={false}
                    variantTooltip={'cool'}
                  />
                ))}
              </SRewardsWrapper>
            ) : (
              <LittleItemsCarousel
                width={'100%'}
                items={[...rewards].reverse().map((reward, index) => (
                  <InventoryItem
                    key={'congrat-reward' + index}
                    amount={reward.quantity}
                    itemId={reward.id}
                    className={styles.reward}
                    navigateOnClick={false}
                    variantTooltip={'cool'}
                    withAnimation={'flip'}
                  />
                ))}
                className={styles.rewardsWrapper}
                itemClassName={styles.itemCarousel}
                buttonClassName={clsx(styles.buttonCarousel, rewards.length <= 4 && styles.hidden)}
              />
            )}
            <BasicButton label={buttonTitle} variant={'white'} onClick={onClickButton} className={styles.button} />
          </SContainer>
        </SWrapper>
      )}
    </AnimatePresence>,
    document.querySelector(`#${portal}`)!
  );
};
