export enum ERewardsTabs {
  ALL = 'All',
  ORIGINAL = 'Mighty Originals',
  CHESTS = 'Treasure Chests',
  CERTIFICATES = 'Certificates',
}

export const REWARDS_TABS_COLORS = {
  [ERewardsTabs.ALL]: {
    color: '#627EEA',
    bg: 'white',
  },
  [ERewardsTabs.ORIGINAL]: {
    color: 'white',
    bg: '#8578D6',
  },
  [ERewardsTabs.CHESTS]: {
    color: 'white',
    bg: '#29AB88',
  },
  [ERewardsTabs.CERTIFICATES]: {
    color: 'white',
    bg: '#F14F63',
  },
};
