import { FC } from 'react';
import { useDisplay } from 'hooks';
import clsx from 'clsx';
import { TGrade } from 'interface';
import { getAppSettings, getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { iconsUrls } from 'shared/utils';
import { EBlockchain, blockchainNativeName } from 'app/constants';

import { ReactComponent as ArrowDown } from 'assets/icons/arrow-down.svg';
// import { ReactComponent as WalletIcon } from 'assets/icons/wallet.svg';
import { Select } from 'shared/ui';
import { ReactComponent as Check } from 'assets/icons/check-fill-mighty.svg';

import styles from './styles.module.scss';

interface ITransactionProps {
  isConnectedWallet?: boolean;
  rules?: boolean;
  success?: boolean;
  name: string;
  image: string;
  grade: TGrade;
  contract: string | null;
  blockchain: EBlockchain | null;
  address: string;
  handleAddressInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  connectWallet?: () => void;
}

export const Transaction: FC<ITransactionProps> = ({
  isConnectedWallet,
  rules,
  success,
  connectWallet,
  name,
  image,
  grade,
  blockchain,
  contract,
  address,
  handleAddressInput,
}) => {
  const { displayedName } = useSelector(getAppSettings);
  const theme = useSelector(getCurrentTheme);
  const { isMobile } = useDisplay();
  const style = { color: `var(--grade-${theme}-color-${grade})` };
  const chainName = blockchain ? blockchainNativeName[blockchain] : '';
  const iconsUrl = blockchain ? iconsUrls[blockchain] : null;

  if (success) {
    return (
      <>
        <div className={styles.done}>
          <Check />
          <p>Transaction is done</p>
        </div>
        {isMobile && <p className={styles.label}>{displayedName} Inventory</p>}
        <div className={styles.inventory} style={{ margin: 'unset' }}>
          <img src={image} alt={name} />
          <div>
            <h3 style={style}>{name}</h3>
            <p style={style}>Digital Collectible</p>
          </div>
        </div>
        <div className={styles.wrap}>
          Send To
          <div className={styles.arrow}>
            <ArrowDown width={'18px'} height={'18px'} />
          </div>
        </div>
        <div className={styles.sendTo}>
          Account
          <span>{address}</span>
        </div>
      </>
    );
  }

  if (rules) {
    return (
      <>
        <p className={styles.label}>{displayedName} Inventory</p>
        <div className={styles.inventory}>
          <img src={image} alt="" />
          <div>
            <h3 style={style}>{name}</h3>
            <p style={style}>Digital Collectible</p>
          </div>
          <div className={styles.arrow}>
            <ArrowDown width={'20px'} height={'20px'} />
          </div>
        </div>
        <p className={styles.label}>Send To</p>
        <div className={styles.sendTo}>
          Account
          <span>{address}</span>
        </div>
        <span className={styles.rules}>Please double-check the address you are sending your collectible to</span>
      </>
    );
  }

  return (
    <>
      <p className={styles.label}>{displayedName} Inventory</p>
      <div className={styles.inventory}>
        <img src={image} alt="" />
        <div>
          <h3 style={style}>{name}</h3>
          <p style={style}>Digital Collectible</p>
        </div>
        <div className={styles.arrow}>
          <ArrowDown width={'20px'} height={'20px'} />
        </div>
      </div>
      {isConnectedWallet && (
        <>
          <p className={styles.label}>
            Connected Wallets <span onClick={connectWallet}>Connect New</span>
          </p>
          <Select
            options={[{ value: 'hadlhdalhadhlad...sfb', label: 'Account', icon: image }]}
            value={'hadlhdalhadhlad...sfb'}
            defaultValue={'1'}
            onChange={() => null}
            fullWidth
            variant={'filled'}
            className={styles.select}
            detailed
          />
        </>
      )}
      <p className={styles.label}>Your Web3 Wallet</p>
      <div className={styles.address}>
        {iconsUrl && (
          <label className={styles.addressInputLabel}>
            <img
              className={clsx({ [styles.nobg]: blockchain && ![EBlockchain.BNB, EBlockchain.Avalanche].includes(blockchain) })}
              src={iconsUrl}
              alt={chainName}
            />
            <input value={address} onChange={handleAddressInput} type="text" placeholder={`Enter ${chainName} address`} />
          </label>
        )}
      </div>
      {/* {!isConnectedWallet && (
        <>
          <BasicButton
            label={'Connect Wallet'}
            icon={<WalletIcon width={'20px'} height={'20px'} />}
            className={styles.connect}
            onClick={connectWallet}
          />
          <div className={styles.warn}>
            <span>Please use only addresses on {chainName} blockchain. If you enter the address from the wrong chain your item might be lost.</span>
          </div>
        </>
      )} */}
    </>
  );
};
