import { ReactComponent as Search } from 'assets/icons/search.svg';

import * as Styled from './styles';
import { GroupCardToggle } from '../../group-cards/group-card';
import { useGetCourseGroups } from 'hooks';

type Props = {
  activeGroups?: string[];
  isSearchIcon?: boolean;
  handleGroups(arg: string): void;
  handleOpen(): void;
  className?: string;
  counters?: number[];
  groups?: string[];
  isCourses?: boolean;
};

const Groups = ({
  isSearchIcon = true,
  handleGroups,
  activeGroups = [],
  handleOpen,
  className = '',
  counters = [],
  groups: groupsGiven = [],
  isCourses = false,
}: Props) => {
  const groups = groupsGiven ?? [];
  const { tags } = useGetCourseGroups();

  return (
    <Styled.Container className={className}>
      {isSearchIcon && (
        <Styled.Item role="button" className="icon" onClick={handleOpen}>
          <Search />
        </Styled.Item>
      )}
      {groups.map((group, index) =>
        isCourses ? (
          <GroupCardToggle key={group} group={tags[group]} isOpen={activeGroups.includes(group)} onClick={() => handleGroups(group)} />
        ) : (
          <Styled.Item key={group} role="button" className={`${activeGroups.includes(group) ? 'active' : ''}`} onClick={() => handleGroups(group)}>
            {group}
            {counters.length > 0 && counters[index] !== 0 && <Styled.Counter>{counters[index]}</Styled.Counter>}
          </Styled.Item>
        )
      )}
    </Styled.Container>
  );
};

export default Groups;
