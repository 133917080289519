import { useSelector } from 'react-redux';
import { getPartnerId } from 'store';

const clientStrings = [
  { s: 'Android', r: /Android/ },
  { s: 'iOS', r: /(iPhone|iPad|iPod)/ },
] as const;

export const useClientDetection = () => {
  const partnerId = useSelector(getPartnerId);
  const userAgent = navigator.userAgent;
  const client = {
    os: '',
    browser: '',
    isInstalledApp: false,
    isTelegram: window.location.href.includes('tgWebAppData'),
    isPartnerApp: partnerId,
  };

  if (process.env.NODE_ENV === 'development') {
    return client;
  }

  for (let index in clientStrings) {
    const cs = clientStrings[index];
    if (cs.r.test(userAgent)) {
      client.os = cs.s;
      break;
    }
  }

  // @ts-ignore
  // Brave
  if (navigator.brave !== undefined) {
    client.browser = 'Brave';
  } else if (userAgent.indexOf('Telegram') !== -1) {
    client.browser = 'Telegram';
    client.isTelegram = true;
  }
  // Yandex Browser
  else if (userAgent.indexOf('YaBrowser') !== -1) {
    client.browser = 'Yandex';
  }
  // Samsung Browser
  else if (userAgent.indexOf('SamsungBrowser') !== -1) {
    client.browser = 'Samsung';
  }
  // UC Browser
  else if (userAgent.indexOf('UCBrowser') !== -1) {
    client.browser = 'UC Browser';
  }
  // Opera Next
  else if (userAgent.indexOf('OPR') !== -1) {
    client.browser = 'Opera';
  }
  // Opera
  else if (userAgent.indexOf('Opera') !== -1) {
    client.browser = 'Opera';
  }
  // Legacy Edge
  else if (userAgent.indexOf('Edge') !== -1) {
    client.browser = 'Microsoft Legacy Edge';
  }
  // Edge (Chromium)
  else if (userAgent.indexOf('Edg') !== -1) {
    client.browser = 'Microsoft Edge';
  }
  // MSIE
  else if (userAgent.indexOf('MSIE') !== -1) {
    client.browser = 'Microsoft Internet Explorer';
  }
  // Chrome
  else if (userAgent.indexOf('Chrome') !== -1) {
    client.browser = 'Chrome';
  }
  // Safari
  else if (userAgent.indexOf('Safari') !== -1) {
    client.browser = 'Safari';
  }
  // Firefox
  else if (userAgent.indexOf('Firefox') !== -1) {
    client.browser = 'Firefox';
  }
  // MSIE 11+
  else if (userAgent.indexOf('Trident/') !== -1) {
    client.browser = 'Microsoft Internet Explorer';
  }

  // Other browsers
  else if (userAgent.lastIndexOf(' ') + 1 < userAgent.lastIndexOf('/')) {
    client.browser = userAgent.substring(userAgent.lastIndexOf(' ') + 1, userAgent.lastIndexOf('/'));
  }

  // @ts-ignore
  if (navigator.standalone) {
    client.isInstalledApp = true;
  }

  if (window.matchMedia('(display-mode: standalone)').matches) {
    client.isInstalledApp = true;
  }

  return client;
};
