import { dispatch } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { expandFeed, getCurrentFeedState, setFeedRewards, useLazyGetFeedQuery } from 'store';

const useGetQuestsFeed = (limit = 10, forcePage?: number) => {
  const { quests, total, page, started, totalRewards } = useSelector(getCurrentFeedState);
  const [getFeed, { isFetching, isLoading }] = useLazyGetFeedQuery();
  const isFinished = useMemo(() => quests.length >= total && started, [total, quests.length]);

  const [isInititated, setIsInitiated] = useState(false);
  const [isFeedEmpty, setIsFeedEmpty] = useState(false);

  const initiate = useCallback(() => {
    if (!isFinished && !isFeedEmpty && !isFetching && !isInititated) {
      setIsInitiated(true);
    }
  }, [isFinished, isFeedEmpty, isFetching, isInititated]);

  useEffect(() => {
    if (!started) {
      initiate();
    }
  }, [started]);

  useEffect(() => {
    if (isInititated) {
      getFeed({ limit, page: forcePage ?? page })
        .unwrap()
        .then((res) => {
          if (res.total === 0 && quests.length === 0) {
            setIsFeedEmpty(true);
            return;
          }
          dispatch(expandFeed({ quests: res.items, total: res.total, forcePage: !!forcePage }));
          if (!totalRewards.length) {
            dispatch(setFeedRewards(res.totalRewards));
          }
        })
        .catch(console.error)
        .finally(() => setIsInitiated(false));
    }
  }, [isInititated]);

  return { initiate, isFetching, isFinished, quests, total, page, isFeedEmpty, isLoading };
};

export default useGetQuestsFeed;
