import useClickOutside from 'hooks/useClickOutside';
import { IExpEffectWidgetData } from 'shared/ui/effect-indicator/types';
import { useDisplay } from 'hooks';

import { BsChevronLeft } from 'react-icons/bs';

import { Head } from './head';
import { Effects } from './effects';
import { Description } from './description';
import { NullButton } from 'shared/ui';

import * as Styled from './styles';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getItems } from 'store';

interface IProps {
  effect: IExpEffectWidgetData;
  onClose(): void;
}

export const EffectPage = ({ effect, onClose }: IProps) => {
  const items = useSelector(getItems);
  const item = useMemo(() => items.find((item) => item.fungibleType === effect.effect.name), [items, effect.effect.name]);
  const ref = useClickOutside<HTMLDivElement>(onClose);
  const { isMobile } = useDisplay();

  return (
    <Styled.Container className={clsx({ mobile: isMobile })} ref={ref} key={effect.effect.expiredAt}>
      <Styled.Chevron className={clsx({ mobile: isMobile })} as={NullButton} onClick={onClose}>
        <BsChevronLeft />
      </Styled.Chevron>
      <Styled.Block>
        <Head effect={effect} />
      </Styled.Block>
      <Styled.Blocks className={clsx('tiny-sb', { mobile: isMobile })}>
        <Styled.Block>
          <Styled.Title>Effects:</Styled.Title>
          <Effects effect={effect} />
        </Styled.Block>
        <Styled.Block>
          <Styled.Title>Description:</Styled.Title>
          <Description description={item?.description ?? '-'} />
        </Styled.Block>
      </Styled.Blocks>
    </Styled.Container>
  );
};
