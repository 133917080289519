import { BasicButtonV1 } from 'shared/ui';
import * as S from './styles';
import { IRewardMinimal } from 'store';
import { useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import { motion } from 'framer-motion';
import RewardItem from './item';
import { useGetCurrentTheme } from 'hooks';

type Props = {
  rewards: IRewardMinimal[];
  onSubmit: () => void;
  updateRewards: (id: number, quantity: number) => void;
};

const variants = {
  initial: {
    y: 20,
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
  },
  exit: {
    opacity: -2,
  },
};

const RewardsList = ({ rewards, onSubmit, updateRewards }: Props) => {
  const theme = useGetCurrentTheme();
  const filtered = useMemo(() => {
    return rewards.filter((el) => !!el.quantity);
  }, [rewards]);

  const deleteReward = (id: number) => updateRewards(id, 0);

  return (
    <S.Container>
      <AnimatePresence>
        {!!filtered.length && (
          <S.List $theme={theme} variants={variants} initial={'initial'} animate={'animate'} exit={'exit'}>
            <AnimatePresence>
              {filtered.map((el) => (
                <RewardItem key={'RewardItem' + el.id} deleteReward={deleteReward} {...el} />
              ))}
            </AnimatePresence>
          </S.List>
        )}
      </AnimatePresence>
      <AnimatePresence>
        <motion.div variants={variants} initial={'initial'} animate={'animate'} exit={'exit'} transition={{ delay: 0.2 }}>
          <BasicButtonV1 onClick={onSubmit} variant="rounded" style={{ width: 'fit-content', color: 'white' }}>
            {filtered.length ? 'Add to slide' : 'Save without rewards'}
          </BasicButtonV1>
        </motion.div>
      </AnimatePresence>
    </S.Container>
  );
};

export default RewardsList;
