import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { EToggleSize } from './types';
import * as Styled from './styles';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  className?: string;
  toggleSize?: EToggleSize;
}

export const Toggle = forwardRef<HTMLInputElement, IProps>(({ className = '', toggleSize = EToggleSize.SM, ...rest }, ref) => {
  const theme = useSelector(getCurrentTheme);

  return (
    <Styled.Container
      className={clsx({ [className]: !!className, [EToggleSize.SM]: toggleSize === EToggleSize.SM, [EToggleSize.XL]: toggleSize === EToggleSize.XL })}
    >
      <Styled.Label>
        <Styled.Input hidden type="checkbox" {...rest} ref={ref} />
        <Styled.Span className={clsx(theme)}></Styled.Span>
      </Styled.Label>
    </Styled.Container>
  );
});
