import { useClock } from 'hooks';
import { Container } from './styles';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';

// extend dayjs with the duration plugin
dayjs.extend(duration);

type Props = {
  time: number;
  substructor?: number;
  className?: string;
};

const formatDuration = (duration: duration.Duration) => {
  const days = Math.floor(duration.asDays());
  const hours = duration.hours();
  const minutes = duration.minutes();
  let outputString = '';

  if (days) {
    outputString += `${days}d `;
  }
  if (hours && days <= 10) {
    outputString += `${hours}h `;
  }
  if (minutes && days < 1) {
    outputString += `${minutes}m`;
  }

  return outputString.trim();
};

export const CountDown = ({ time, substructor = 1000, className = '' }: Props) => {
  const { clock } = useClock({ startFrom: time, substructor });
  const duration = dayjs.duration(clock, 'seconds');

  return <Container className={className}>{formatDuration(duration)}</Container>;
};
