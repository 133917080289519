import { useDisplay, useGetCurrentTheme, useGetUserData } from 'hooks';
import * as S from '../styles';
import { facebookShareLink, instagramShareLink, telegramShareLink, twitterShareLinkPost, useReferralsShareText } from '../utils';

import { ReactComponent as XIcon } from '../../assets/x.svg';
import { ReactComponent as TelegramIcon } from '../../assets/telegram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
import { CSSProperties } from 'react';
import { Environment } from 'app/constants';
import MobileSocialsBlock from './mobile-socials-block';

type Props = {
  title?: string;
  titleStyles?: CSSProperties;
};

const RefsSocialsBlock = ({ title, titleStyles }: Props) => {
  const {
    referrals: { referralCode },
  } = useGetUserData();
  const shareText = useReferralsShareText();
  const theme = useGetCurrentTheme();
  const { isMobile } = useDisplay();

  const refLink = `${window.location.origin}/ref/${referralCode}`;
  const tgRefLink = `https://t.me/${Environment.TelegramBotName}/${Environment.TelegramMiniAppName}?startapp=refCode_${referralCode}`;

  const socialsBlock = (
    <>
      <S.SocialSub $theme={theme} style={titleStyles || {}}>
        {title || 'Share:'}
      </S.SocialSub>
      <S.Socials $theme={theme}>
        {/* <S.SocialButton>
              <DiscordIcon />
            </S.SocialButton> */}
        <S.SocialButton onClick={twitterShareLinkPost(refLink, shareText)}>
          <XIcon />
        </S.SocialButton>
        <S.SocialButton onClick={telegramShareLink(tgRefLink, shareText)}>
          <TelegramIcon />
        </S.SocialButton>
        <S.SocialButton onClick={facebookShareLink(refLink, shareText)}>
          <FacebookIcon />
        </S.SocialButton>
        <S.SocialButton onClick={instagramShareLink(refLink, shareText)}>
          <InstagramIcon />
        </S.SocialButton>
      </S.Socials>
    </>
  );

  return isMobile ? <MobileSocialsBlock refLink={refLink} tgRefLink={tgRefLink} title={title} titleStyles={titleStyles} /> : socialsBlock;
};

export default RefsSocialsBlock;
