import { Fragment, PropsWithChildren } from 'react';

export const DisableInSafari = ({ children }: PropsWithChildren) => {
  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const isOtherReason = !!!window.speechSynthesis;
  if (isSafari) {
    console.log('SpeechSynthesisUtterance is disabled in Safari for this app.');
    return null;
  }
  if (isOtherReason) {
    console.log('SpeechSynthesisUtterance is not supported in your browser.');
    return null;
  }
  return <Fragment>{children}</Fragment>;
};
