import * as S from './style';
import { ReactComponent as LinkIcon } from '../../assets/icons/link-icon.svg';
import UserLink from './user-link';
import { LinkModal, UnlinkModal } from './linkModal';
import { useMemo, useState } from 'react';
import { LINK_AUTH_METHODS } from './types';
import { EAuthMethods } from 'interface';
import { useDisplay, useGetCurrentTheme, useGetUserData } from 'hooks';
import { useGetPartnerId } from '../../hooks/useGetStoreGeneralData';

const UserLinks = () => {
  const partnerId = useGetPartnerId();
  const { isMobile } = useDisplay();
  const theme = useGetCurrentTheme();
  const [selectedLinkMethod, setSelectedLinkMethod] = useState<EAuthMethods | null>(null);
  const [selectedToUnlink, setSelectedToUnlink] = useState<EAuthMethods | null>(null);
  const { authMethods } = useGetUserData();

  const handleClose = () => {
    setSelectedLinkMethod(null);
    setSelectedToUnlink(null);
  };

  const linkAuthMethods = useMemo(() => {
    if (isMobile && !partnerId) {
      const userHasEVM = authMethods.some((el) => el.method === EAuthMethods.EVM);
      const evm = LINK_AUTH_METHODS.find((el) => el.method === EAuthMethods.EVM);
      const methods = LINK_AUTH_METHODS.filter((el) => el.method !== EAuthMethods.EVM);
      if (userHasEVM && evm) {
        methods.push(evm);
      }
      return methods;
    }
    return LINK_AUTH_METHODS;
  }, [authMethods, isMobile, partnerId]);

  return (
    <S.Container>
      <S.Title $theme={theme}>
        <LinkIcon /> Your Links
      </S.Title>
      <S.List>
        {linkAuthMethods.map((el) => (
          <UserLink
            key={el.method}
            selectForLinking={() => setSelectedLinkMethod(el.method)}
            selectForUnlinking={() => setSelectedToUnlink(el.method)}
            {...el}
          />
        ))}
      </S.List>
      <LinkModal selectedMethod={selectedLinkMethod} clearMethod={handleClose} />
      {selectedToUnlink && <UnlinkModal selectedMethod={selectedToUnlink} clearMethod={handleClose} />}
    </S.Container>
  );
};

export default UserLinks;
