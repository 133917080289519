import { THEMES } from 'app/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div<{ $variant: 'banner' | 'panel'; $isRewardable: boolean; $isSmall: boolean }>(
    ({ $variant, $isRewardable, $isSmall }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--font-color-strong);
      font-size: 20px;
      font-weight: 700;
      line-height: 100%;

      ${Streak} {
        border: ${$isRewardable ? '' : '1px solid #EEC384'};

        ${$isSmall &&
        css`
          width: fit-content;
          height: 38px;
          background: unset;

          img {
            width: 15px;
          }

          p {
            font-size: 18px;
            white-space: pre;
          }
        `}
      }

      ${Great} {
        background: ${$isRewardable ? 'linear-gradient(rgba(12, 40, 161, 1), rgba(100, 135, 239, 1), rgba(83, 37, 155, 1))' : 'var(--gold-bg)'};
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      ${$variant === 'banner' &&
      css`
        flex-direction: column;
        gap: 4px;

        ${Great} {
          font-size: 28px;
        }
      `}

      ${$variant === 'panel' &&
      css`
        gap: 20px;

        @media screen and (max-width: 992px) {
          justify-content: flex-start;
        }

        ${Great} {
          font-size: 18px;
        }
      `}
    `
  ),
  Streak = styled.div(
    ({ theme }) => css`
      display: flex;
      align-items: center;
      padding: 10px 19px;
      border-radius: 4px;
      background: linear-gradient(
        283deg,
        rgba(var(--basic-${theme.dark ? THEMES.DARK : THEMES.LIGHT}-rgb), 0.1) 28.94%,
        rgba(var(--basic-${theme.dark ? THEMES.DARK : THEMES.LIGHT}-rgb), 0.1) 143.24%
      );
      width: 205px;
      height: 45px;

      img {
        width: 21px;
        margin-right: 4px;
      }

      p {
        color: inherit;
        font-size: inherit;
        font-weight: inherit;
        line-height: inherit;
      }
    `
  ),
  Great = styled.p(
    () => css`
      font-weight: 500;
      line-height: 145%;
    `
  );
