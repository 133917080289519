import clsx from 'clsx';

import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import { ReactComponent as Confirm } from 'assets/icons/check-fill-green.svg';
import { ReactComponent as Deny } from 'assets/icons/cross-fill-red.svg';

import styles from 'shared/ui/course-card/CourseCard/ConfirmationModal/styles.module.css';

import { FaSpinner } from 'react-icons/fa';
import { Input } from 'shared/ui';
import { courseParserAPI } from 'store/courseParser';
import { CourseParserAPIParams } from 'store/courseParser/courseParser.api';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { SubmitHandler, useForm } from 'react-hook-form';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { useGetCurrentTheme } from 'hooks';

type CourseParams = {
  courseId: string;
  chapterId: string | null;
};

type Props = {
  isOpen: boolean;
  onClose: () => any;
  title?: string;
  courseParams: CourseParams;
  refetchCourse?: () => void;
};

type FormData = {
  url: string;
};

export function CourseParserModal({ isOpen, onClose, title = 'Import Course?', courseParams, refetchCourse }: Props) {
  const theme = useGetCurrentTheme();
  const [parseCourse, { isLoading }] = courseParserAPI.useParseCourseFromUrlMutation();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>();

  const onSubmit: SubmitHandler<FormData> = (data) => {
    handleParseUrl({ ...data, courseId: courseParams.courseId, chapterId: courseParams.chapterId });
  };

  const handleParseUrl = ({ url, courseId, chapterId }: CourseParserAPIParams) => {
    parseCourse({ url, courseId, chapterId })
      .unwrap()
      .catch((ex) => {
        toast.error(<Answer subtext={ex?.data?.message ?? 'Something went wrong'} type="incorrect" />);
      })
      .finally(() => {
        if (refetchCourse) refetchCourse();
        onClose();
      });
  };

  if (!isOpen) return null;

  return (
    <CenteredModal isClosing={true} closeButtonLocation="outside" show={isOpen} onClose={onClose}>
      <div className={clsx(styles.container, styles[theme], 'glass-div')}>
        <h1 className={styles.title}>{title}</h1>
        {isLoading && <MightyLoaderMini />}
        <div style={{ marginBottom: '51px' }}></div>
        <span>Enter Url for import</span>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div style={{ marginBottom: 10 }}></div>
          <Input {...register('url', { required: 'URL is required' })} placeholder="Enter URL" />
          {errors.url && <span className={styles.error}>{errors.url.message}</span>}
          <div style={{ marginBottom: 20 }}></div>
          <div className={styles.buttons}>
            {isLoading ? (
              <FaSpinner className={clsx('spinner', styles.spinner)} />
            ) : (
              <>
                <button
                  type="submit"
                  style={{
                    background: 'transparent',
                    outline: 'none',
                    border: 'none',
                  }}
                >
                  <Confirm />
                </button>
                <Deny className={styles.button} role="button" onClick={onClose} />
              </>
            )}
          </div>
        </form>
      </div>
    </CenteredModal>
  );
}
