import { FC, useMemo, useState } from 'react';
import { TTabsProps } from './types';
import Tab from './components/tab';

import * as Styled from './styles';

const Tabs: FC<TTabsProps> = ({ tabs }) => {
  const [selectedTabIdx, setSelectedTabIdx] = useState(0);

  const handleTabClick = (idx: number) => () => {
    setSelectedTabIdx(idx);
  };

  const activeTab = useMemo(() => tabs[selectedTabIdx], [tabs, selectedTabIdx]);

  if (!tabs.length) {
    return null;
  }

  return (
    <div>
      <Styled.STabsRow>
        {tabs.map((tab, idx) => (
          <Tab key={tab.title} tab={tab} active={selectedTabIdx === idx} onClick={handleTabClick(idx)} />
        ))}
      </Styled.STabsRow>
      {activeTab.content}
    </div>
  );
};

export default Tabs;
