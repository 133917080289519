import { STATIC_HOST_PATH } from 'app/constants/path';

import * as Styled from './styles';

interface Props {
  className?: string;
  name: string;
  image: string;
  onClick(): any;
}

export const SearchResultCardSmall = ({ image = '', name = '', className = '', onClick }: Props) => {
  const cover = STATIC_HOST_PATH + image;

  return (
    <Styled.Container className={className} role="button" onClick={onClick}>
      <Styled.ImageContainer>
        <img src={cover} alt={name} />
      </Styled.ImageContainer>
      <Styled.Body>
        <Styled.CourseName>{name}</Styled.CourseName>
      </Styled.Body>
    </Styled.Container>
  );
};
