import styled, { css } from 'styled-components';
import { TJustifyContent } from 'components/library/courseConstructor/components/Widget/components/FileWidget/ui/ResizableImage/types';

export const SWrapper = styled.div<{ $justifyContent: TJustifyContent }>(
    ({ $justifyContent }) => css`
      display: flex;
      justify-content: ${$justifyContent};
      align-items: center;
      width: 100%;
    `
  ),
  SContainer = styled.div(
    () => css`
      position: relative;
    `
  ),
  SControls = styled.div<{ $isSmall: boolean }>(
    ({ $isSmall }) => css`
      position: absolute;
      top: 0;
      right: 0;
      display: flex;
      align-items: center;
      padding: ${$isSmall ? '5px' : '10px'};
      gap: ${$isSmall ? '5px' : '16px'};

      svg {
        width: ${$isSmall ? '20px' : '24px'};
        height: ${$isSmall ? '20px' : '24px'};
        cursor: pointer;
      }
    `
  ),
  SControlItem = styled.button<{ $isSmall: boolean }>(
    ({ $isSmall }) => css`
      padding: 5px;
      width: 36px;
      height: 36px;
      background-color: rgba(255, 255, 255, 0.4);
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      transition: background-color 0.25s ease-in-out;

      &:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
    `
  ),
  SImageWrapper = styled.div<{ $width: string | number; $height: string | number }>(
    ({ $width, $height }) => css`
      position: relative;
      width: ${$width + 'px'};
      height: ${$height};
      display: flex;
      align-items: center;
      background-color: #000;
      border-radius: 13px;
      overflow: hidden;
    `
  ),
  SImage = styled.img(
    () => css`
      width: 100%;
    `
  ),
  SImageControl = styled.div<{ $isLeft: boolean; $isGrabbing: boolean }>`
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 10px;
    right: 10px;
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 100%;
    cursor: grab;
    user-select: none;
    will-change: width;
    transition: width, background-color 0.2s ease;
    padding: 5px;

    & svg {
      height: 100%;
      width: 100%;
      color: white;
    }
    &:hover svg {
      filter: brightness(1.2);
    }
    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }

    &:active .image {
      /* height: 50%; */
    }

    ${({ $isLeft }) =>
      $isLeft &&
      css`
        left: 10px;
      `}
    ${({ $isGrabbing }) =>
      $isGrabbing &&
      css`
        cursor: grabbing;
      `}
  `;
