import { Dispatch, SetStateAction, createContext, useContext } from 'react';

export type TDashboardContext = {
  slideId?: string;
  tab?: '' | 'Comments' | 'Answers';
  setSlideId?: (slideId: string) => void;
  setTab?: (arg: 'Comments' | 'Answers' | '') => void;
  answersCount: number;
  setAnswersCount: Dispatch<SetStateAction<number>> | (() => void);
  commentsCount: number;
  setCommentsCount: Dispatch<SetStateAction<number>> | (() => void);
};

export const DashboardContext = createContext<TDashboardContext>({
  answersCount: 0,
  commentsCount: 0,
  setAnswersCount: () => {},
  setCommentsCount: () => {},
});

export const useDashboardContext = () => {
  const { slideId, setSlideId, tab, setTab, answersCount, commentsCount, setAnswersCount, setCommentsCount } = useContext(DashboardContext);

  return { slideId, setSlideId, tab, setTab, answersCount, commentsCount, setAnswersCount, setCommentsCount };
};
