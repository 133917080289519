import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      --right: 30px;
      position: fixed !important;
      right: 0 !important;
      top: 50% !important;
      transform: translateY(-50%) !important;
      z-index: 1000;
      cursor: pointer;

      @media screen and (max-width: 1440px) {
        right: 0px !important;
      }
      @media screen and (min-width: 992px) and (max-width: 1440px) {
        right: -150px !important;
        transition: 0.3s;
        &:hover {
          right: 0px !important;
          .wrapper {
            right: 0 !important;
          }
          .chevron {
            left: 150% !important;
          }
        }
      }
    `
  ),
  Wrapper = styled.div(
    () => css`
      position: relative !important;
      transition: right 0.2s ease-in-out;
      /* right: -150%; */
      right: 30px;
      max-height: 90vh;
      overflow-y: auto;

      .switch {
        max-width: 200px;
      }

      @media screen and (min-width: 992px) and (max-width: 1440px) {
        right: -150%;
        padding: 20px;
        border-radius: 10px 0px 0px 10px;
        background: #ffffff11;
        backdrop-filter: blur(10px);
      }
    `
  ),
  Chevron = styled.div(
    () => css`
      position: absolute !important;
      left: 0;
      top: calc(50% - 100px) !important;
      transition: left 0.3s ease-in-out;
      width: fit-content;
      height: 40px;

      display: none;
      align-items: center;
      gap: 10px;

      svg {
        height: 100%;
        flex-shrink: 0;
      }

      .switch {
        max-width: 200px;
      }

      @media screen and (min-width: 992px) and (max-width: 1440px) {
        display: flex;
      }
    `
  );
