import items from 'testCourses/testItems';
import clsx from 'clsx';
import { EEffectsListItemBadgeType } from '../types';
import { TExpEffectWidgetDataBadge } from 'shared/ui/effect-indicator/types';

import { CountDown } from 'shared/ui'; // Ensure this uses dayjs as shown in the previous example

import { ReactComponent as Lightning } from 'assets/icons/lightning.svg';
import { ReactComponent as LightningUp } from 'assets/icons/energy-up.svg';
import { ReactComponent as LightningDown } from 'assets/icons/energy-down.svg';
import { ReactComponent as Clock } from 'assets/icons/clock.svg';

import { Container, Icon, Label, Image } from './styles';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import duration from 'dayjs/plugin/duration';

// extend dayjs with necessary plugins
dayjs.extend(relativeTime);
dayjs.extend(duration);
dayjs.extend(utc);

interface Props {
  data: TExpEffectWidgetDataBadge;
  className?: string;
}

const typeData: Record<EEffectsListItemBadgeType, any> = {
  energy_pool: <Lightning />,
  max_spending: <LightningDown />,
  energy_recovery: <LightningUp />,
  exp_recieved: items.find((item) => item.name === 'EXP Token')?.img,
  expires_in: <Clock />,
};

export const Badge = ({ data, className = '' }: Props) => {
  const { effectName, type, grade } = data;
  let labelProcessed;

  if (type === 'countdown') {
    const expiredAt = dayjs(data.expiredAt).utc();
    const now = dayjs().utc();

    const timeRemaining = expiredAt.diff(now, 'seconds');

    labelProcessed = <CountDown time={timeRemaining} substructor={1} />;
  } else {
    const percent = data.percent;
    labelProcessed = `+${Math.abs(percent - 100)}%`;
  }

  const icon = typeData[effectName];

  return (
    <Container className={clsx(grade, { [effectName]: effectName, [className]: !!className })}>
      <Icon>{typeof icon === 'string' ? <Image className={clsx({ [type]: type })} src={icon} alt={effectName.toLocaleString()} /> : icon}</Icon>
      <Label>{labelProcessed}</Label>
    </Container>
  );
};
