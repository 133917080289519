import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme, useLazyFindUserByInputQuery } from 'store';
import { IProfile } from 'interface';
import AvatarPic from '../utils/AvatarPic/avatar';
import SearchbarSmall from '../utils/Searchbars/SearchbarSmall/SearchbarSmall';
import { ReactComponent as Add } from 'components/library/images/icons/add-user.svg';

import styles from './user-finder.module.css';
import { useDisclosure } from 'hooks';
import { Modal } from 'shared/ui';
import { toast } from 'react-toastify';
import Answer from '../messages/Answer';

type Props = {
  onAddUser: (id: string, onSuccess: () => void) => void;
  Trigger: FC<any>;
};

export default function UserFinder({ onAddUser, Trigger }: Props) {
  const theme = useSelector(getCurrentTheme);
  const [findUserByInput, { isLoading: findLoading }] = useLazyFindUserByInputQuery();
  const [users, setUsers] = useState<IProfile[]>([]);
  const [query, setQuery] = useState('');

  const { close, open, isOpen } = useDisclosure(false);

  useEffect(() => {
    async function searchFriends() {
      // const input = query.replace(/\W/g, '');
      const input = encodeURIComponent(query);
      if (input) {
        findUserByInput(input)
          .unwrap()
          .then((friends) => {
            setUsers(friends as unknown as IProfile[]);
          })
          .catch((ex) => {
            console.error(ex);
            toast.error(<Answer label={'Oops:/'} subtext={ex?.data?.message ?? 'Something went wrong...'} type="incorrect" />);
          });
      }
    }

    const timeout = setTimeout(() => {
      if (query) {
        searchFriends();
      } else {
        setUsers([]);
      }
    }, 450);

    return () => clearTimeout(timeout);
  }, [query]);

  function closeFinder() {
    setUsers([]);
    setQuery('');
    close();
  }

  return (
    <>
      <Trigger onClick={open} />
      <Modal
        closeButtonLocation="outside"
        open={isOpen}
        onClose={closeFinder}
        className={styles.userFinderModal}
        containerClassName={styles.userFinderModalContainer}
      >
        <div className="friend-finder__wrap">
          <SearchbarSmall
            loading={findLoading}
            input={query}
            autoFocus
            handleInput={(e: any) => setQuery(e.target.value)}
            clearInput={() => setQuery('')}
            placeholder={'Search Users'}
          />
          <div
            style={{ height: users.length > 3 ? `${users.length * 70 + 5}px` : '215px' }}
            className={`glass-div basic ${theme} friend-finder__results-area`}
          >
            {users.length > 0 ? (
              users.map((friend: IProfile, index: number) => {
                const { nickname } = friend;
                return (
                  <div className="friend-finder_friend" key={'search-friend-' + index}>
                    <div className="friend-finder__person">
                      <AvatarPic smallGlow className={styles.avatarMain} isFramed={false} isOnlineMarker={false} {...friend} />
                      <p>{nickname.length > 15 ? nickname.slice(0, 13) + '...' : nickname}</p>
                    </div>
                    <div className="friend-finder__manage">
                      <button onClick={() => onAddUser(friend._id, close)}>
                        <Add />
                      </button>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="friend-finder_friend">
                <p>{query ? 'No users founds for this search' : 'Type something to search...'}</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
}
