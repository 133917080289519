import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      & > div {
        width: 100%;
        position: relative;
        top: 0;
        left: 0;
        transform: none;
        .drawer {
          padding: 0 !important;
        }
        .switch {
          width: 100%;
          max-width: none;
        }
      }
    `
  ),
  Button = styled.button(
    () => css`
      position: fixed;
      aspect-ratio: 1 / 1;
      right: 10px;
      top: 40px;
      width: 60px;
      transform: translateY(-50%);
      z-index: 10;
      padding: 10px;
      background: none;
      outline: none;
      border: none;
      border-radius: 100%;
      color: var(--font-color-strong);

      & svg {
        width: 70%;
        height: auto;
      }
    `
  );
