import { Editor } from '@tiptap/react';
import { FC, useCallback, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import ColorItem from './components/ColorItem';

import './styles.css';

type TColorsMenuProps = {
  editor: Editor;
  colors: { color: string; title: string }[];
  onClose: () => void;
};

const ColorsMenu: FC<TColorsMenuProps> = ({ editor, colors, onClose }) => {
  const $menuRef = useRef<HTMLDivElement>(null);
  const theme = useSelector(getCurrentTheme);

  const handleClickOutside = useCallback((evt: MouseEvent) => {
    const target = evt.target;

    if (target === $menuRef.current || $menuRef.current?.contains(target as Node)) {
      return;
    }

    onClose();
  }, []);

  const handleColorClick = useCallback(
    (color: string) => () => {
      editor.chain().focus().setColor(color).run();
      onClose();
    },
    [editor]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={`tiptapEditor-colorsMenu glass-div basic ${theme}`} ref={$menuRef}>
      <div className="tiptapEditor-colorsMenu__title">colors</div>
      <div className="tiptapEditor-colorsMenu__colors">
        {colors.map((item) => (
          <ColorItem
            key={item.color}
            color={item.color}
            title={item.title}
            onClick={handleColorClick(item.color)}
            selected={editor.isActive('textStyle', { color: item.color })}
          />
        ))}
      </div>
    </div>
  );
};

export default ColorsMenu;
