import { ButtonHTMLAttributes, DetailedHTMLProps } from 'react';
import { ReactComponent as Spinner } from 'assets/components/spinner.svg';
import styles from './styles.module.css';
import clsx from 'clsx';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';

export interface IButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: React.ReactNode;
  variant?: 'primary' | 'bordered' | 'rounded' | 'solid' | 'outline' | 'golden';
  color?: 'primary' | 'transparent';
  isLoading?: boolean;
  icon?: string | any;
  fullWidth?: boolean;
  size?: 'sm' | 'md' | 'lg';
}

export const BasicButtonV1 = ({
  children,
  isLoading,
  icon: Icon,
  className = '',
  variant = 'primary',
  color,
  fullWidth = true,
  size = 'md',
  ...rest
}: IButtonProps) => {
  const theme = useSelector(getCurrentTheme);

  let providedIcon;

  if (Icon) {
    providedIcon = typeof Icon === 'string' ? <img src={Icon} alt={''} className={styles.icon} /> : <Icon className={styles.icon} />;
  }

  return (
    <button
      className={clsx(styles.button, styles[theme], {
        [className]: !!className,
        [styles.primary]: variant === 'primary' || color === 'primary',
        [styles.bordered]: variant === 'bordered',
        [styles.rounded]: variant === 'rounded',
        [styles.solid]: variant === 'solid',
        [styles.outline]: variant === 'outline',
        'mighty--golden': variant === 'golden',
        [styles.loading]: isLoading,
        [styles.fullWidth]: fullWidth,
        [styles.size_sm]: size === 'sm',
        [styles.size_md]: size === 'md',
        [styles.size_lg]: size === 'lg',
      })}
      {...rest}
    >
      {isLoading ? (
        <Spinner className={styles.spinner} />
      ) : (
        <>
          {Icon && providedIcon}
          {children}
        </>
      )}
    </button>
  );
};
