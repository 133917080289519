import { useState } from 'react';
import { IOnetimerComponent } from 'shared/ui/onetime-notifications/types';
import { PageBackground as PBG, Wrapper } from '../interests-picker/styles';
import { Greeting } from './components';
import { InterestsPicker } from '../interests-picker';
import { AnimatePresence } from 'framer-motion';
import GreeterButtons from './components/stageAnim';
import { useDisplay, useGetPartnerId } from 'hooks';

export const FirstTimer = ({ onClose }: IOnetimerComponent) => {
  const [isPicker, setIsPicker] = useState<boolean>(true);
  const { isMobile } = useDisplay();
  const partnerId = useGetPartnerId();

  if (!!partnerId) return null;

  return (
    <Wrapper>
      {isMobile && <GreeterButtons stage={isPicker ? 0 : 1} />}

      <AnimatePresence exitBeforeEnter>
        {isPicker ? (
          <InterestsPicker key={'interests'} isBackButton={false} onClose={onClose} onSubmit={() => setIsPicker(false)} />
        ) : (
          <Greeting key={'greetings'} onClose={onClose} />
        )}
      </AnimatePresence>
      {!isMobile && <GreeterButtons stage={isPicker ? 0 : 1} />}
    </Wrapper>
  );
};
