import styled from 'styled-components';
import { ReactComponent as Dots } from '../assets/dots.svg';
import { THEMES } from 'app/constants';

export const Container = styled.div`
  display: flex;
  width: 1034px;
  gap: 17px;
  flex-wrap: wrap;
  position: relative;
`;

export const DragItem = styled.div`
  position: relative;
`;
export const DragDots = styled(Dots)<{ $theme: THEMES }>`
  top: 10px;
  left: 10px;
  color: ${(p) => (p.$theme === THEMES.DARK ? 'rgb(175, 180, 184)' : '#111')};
  transform: translate(-50%, -50%);
  position: absolute;
  cursor: grab;
  width: 20px;
  height: 23px;
  z-index: 2;
  padding-right: 10px;
  padding-bottom: 10px;
  box-sizing: content-box;
`;
