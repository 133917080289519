import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  100% {
    background-position-x: -100%;
  }
`;
const SKELETON_BG = 'linear-gradient(-45deg, rgba(100, 135, 239, 0.1) 40%, rgba(100, 135, 239, 0.2) 50%, rgba(100, 135, 239, 0.1) 60%)';

export const ParentElement = styled.div`
  border-radius: 4px;
  background: ${SKELETON_BG};
  animation: ${shimmer} 2s infinite linear;
  background-size: 300%;
  background-position-x: 200%;
`;

export const TagLoader = styled(ParentElement)`
  width: 100px;
  height: 28px;
  @media screen and (max-width: 992px) {
    width: 50px;
  }
`;

export const BGLoader = styled(ParentElement)`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  border-radius: 10px;
  left: 0;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const ImageLoader = styled(ParentElement)`
  width: 164px;
  height: 164px;
  border-radius: 50%;
  @media screen and (max-width: 992px) {
    width: 128px;
    height: 128px;
  }
`;

export const Name = styled(ParentElement)`
  height: 33px;
  width: 150px;
  border-radius: 4px;
  @media screen and (max-width: 992px) {
    height: 26px;
  }
`;

export const Link = styled(ParentElement)`
  width: 100px;
  height: 18px;
`;

export const Desc = styled(ParentElement)`
  height: 22px;
  width: 50%;
  &:first-of-type {
    width: 70%;
    margin-bottom: 5px;
  }
`;

export const Num = styled(ParentElement)`
  height: 30px;
  width: 25px;
`;

export const Sub = styled(ParentElement)`
  width: 65px;
  height: 16px;
`;
