import { FC } from 'react';
import { TInfoSlideTypeFormProps, TTestSlideProps } from './types';
import { ESlideTypes } from 'store';

import Multichoice from './components/Multichoice';
import Typeanswer from './components/Typeanswer';
import { AnswersEditor } from './components/AnswersEditor';

import styles from './styles.module.css';

const slideTypeFormsMap: Record<ESlideTypes, React.FC<TTestSlideProps>> = {
  [ESlideTypes.INFO]: () => <div>Empty</div>,
  [ESlideTypes.TEST]: Multichoice,
  [ESlideTypes.MULTICHOICE]: Multichoice,
  [ESlideTypes.TYPEANSWER]: Typeanswer,
  [ESlideTypes.FILLBLOCKS]: AnswersEditor,
  [ESlideTypes.FILLBLOCKS_CODE]: AnswersEditor,
  [ESlideTypes.FILLSPACES]: AnswersEditor,
  [ESlideTypes.FILLSPACES_CODE]: AnswersEditor,
};

const TestSlideForm: FC<TInfoSlideTypeFormProps> = ({ type, answers, rightAnswers, onChange, slideId }) => {
  if (!slideTypeFormsMap[type]) {
    return null;
  }

  const Component = slideTypeFormsMap[type];

  return (
    <div>
      <div className={styles.form}>
        <Component answers={answers} rightAnswers={rightAnswers} slideId={slideId} onChange={onChange} type={type} />
      </div>
    </div>
  );
};

export default TestSlideForm;
