import { useEffect, useReducer } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { setCurrentMobilePanel, getCurrentMobilePanel, getActiveUserMenuPanel, setActiveUserMenuPanel } from 'store';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { IState, TScreens } from './types';
import { dispatch, useDisplay, useGetIsAuth, useGetUserData } from 'hooks';

import { ReactComponent as Shop } from 'assets/icons/shopping-bag.svg';
import { ReactComponent as Options } from 'assets/icons/options.svg';
import { ReactComponent as Learn } from 'assets/icons/learn.svg';

import Notifications from 'components/library/notifications/Notifications';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { MobileModal } from '../modal';
import { MobileModal as NotificationsModal } from 'shared/ui/modal';
import { Effects, NavList } from './screens';
import MobileUsermenu from 'components/library/navbar/UserMenu/mobile/mobile-usermenu';

import styles from './styles.module.css';

export default function MobileNavbar() {
  const navigate = useNavigate();
  const isAuth = useGetIsAuth();
  const { customization, avatar, nickname } = useGetUserData();
  const panel = useSelector(getCurrentMobilePanel);
  const usermenuPanel = useSelector(getActiveUserMenuPanel);
  const { pathname } = useLocation();
  const { isMobile } = useDisplay();

  const hideOn: string[] = [ROUTES.AUTH, ROUTES.SIGN_UP, ROUTES.LOGIN, ROUTES.RESET_PASSWORD, ROUTES.AUTHEMAIL, ROUTES.FEED];

  const hide = hideOn.some((path) => matchPath(path, pathname));

  const [{ isShown, activeScreen }, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), {
    isShown: true,
    activeScreen: 'none',
  });

  useEffect(() => {
    const re = new RegExp(`^${ROUTES.MAIN}/.*/.*`);
    updateState({ isShown: !(re.test(pathname) || pathname.includes('chests')) });
    handleModalClose();
  }, [pathname]);

  useEffect(() => {
    if (usermenuPanel === 'effects') {
      updateState({ activeScreen: 'effects' });
    }
  }, [usermenuPanel]);

  function handleLinksModal() {
    updateState({ activeScreen: activeScreen === 'links' ? 'none' : 'links' });
    dispatch(setCurrentMobilePanel(panel === 'links' ? 'none' : 'links'));
  }

  function handleMenu() {
    updateState({ activeScreen: activeScreen === 'menu' ? 'none' : 'menu' });
  }

  function handleShopNav() {
    handleModalClose();
    navigate(ROUTES.SHOP);
  }

  function handleStudyNav() {
    handleModalClose();
    navigate(ROUTES.MAIN);
  }

  function handleModalClose() {
    updateState({ activeScreen: 'none' });
    if (usermenuPanel === 'effects') {
      dispatch(setActiveUserMenuPanel('none'));
    }
  }

  function handleUserProfileLink() {
    updateState({ activeScreen: 'none' });
  }

  function handleNotifModal(value: TScreens) {
    return () => updateState({ activeScreen: value });
  }

  if (!isShown || !isMobile || hide) {
    return null;
  }

  return (
    <>
      <div className={clsx(styles.container)}>
        <Options role="button" onClick={handleLinksModal} />
        <Learn role="button" onClick={handleStudyNav} style={{ strokeWidth: '2px', height: '34px' }} />
        <Shop role="button" onClick={handleShopNav} style={{ height: '34px' }} />
        <div className={styles.avatar} onClick={handleMenu}>
          {isAuth ? (
            <AvatarPic smallGlow className={styles.pic} customization={customization} avatar={avatar} nickname={nickname} />
          ) : (
            <div onClick={() => navigate(ROUTES.AUTH)}>
              <AvatarPic className={styles.pic} avatar={''} nickname={'??'} />
            </div>
          )}
        </div>
      </div>
      <MobileModal zIndex={1003} isOpen={activeScreen === 'menu'} onClose={handleModalClose}>
        <MobileUsermenu onEffectsOpen={handleNotifModal('effects')} onClose={() => {}} />
      </MobileModal>
      <MobileModal zIndex={1003} isOpen={activeScreen === 'links'} onClose={handleModalClose}>
        <NavList onClose={() => {}} handleNotifModal={handleNotifModal('notifs')} handleUserProfileLink={handleUserProfileLink} />
      </MobileModal>
      {isAuth && (
        <>
          <NotificationsModal isOpen={activeScreen === 'notifs'} handleClose={handleModalClose} header={'Notifications'} zIndex={200}>
            <Notifications onClose={() => {}} />
          </NotificationsModal>
          <MobileModal isOpen={activeScreen === 'effects'} onClose={handleModalClose}>
            <Effects />
          </MobileModal>
        </>
      )}
    </>
  );
}
