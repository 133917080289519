import { useGetLessonMethods, useLessonForCompletion } from './hooks';
import * as S from './styles';
import Slide from './components/slide/Slide';
import LessonHeader from './components/header';
import { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { BsChevronUp } from 'react-icons/bs';
import { ESlideWidgetTypes, getPartnerId, IRewardMinimal } from 'store';
import { EnergyGoneModal } from 'shared/ui';
import { FullscreenCongratulations } from 'shared/utils/FullscreenCongratulations/FullscreenCongratulations';
import { useDebounce, useDisplay, useGetContainerSize, useGetCurrentTheme, useGetIsAuth, useReduceClaimedRewards } from 'hooks';
import { sleep } from 'shared/utils';
import LessonProgress from './components/progress';
import { SideButtons } from '../sideButtons';
import { ReactComponent as Cross } from './assets/cross.svg';
import ResetCourseButton from '../resetResultcourseButton';
import { GlobalLoader } from 'shared/utils';
import LessonBottom from './components/LessonBottom';
import LessonMobileMenu from 'shared/utils/mobile/mobileMenu/lesson';
import { useSelector } from 'react-redux';

export interface ILessonState {
  isEnergyModal: boolean; // show energy modal if true
  isRewardsModal: boolean; // show rewards modal if true
  isLoading: boolean; // is completing slide or checking answer
  claimedRewards: IRewardMinimal[]; // rewards claimed in current lesson
  scrollTop: number; // page's scroll top (differs from window's scrollTop)
  isAnimationStarted: boolean; // starts to show placeholder's animation
  isNavigatingToNextLesson: boolean; // makes rewards modal to navigate to next lesson instead of overview if true
  isPlaceholder: boolean; // shows "COMPLETE THE TEST BLAh Blah blah" placeholder if true
  isSidePanelOn: boolean;
  mobileMenuState: 'progress' | 'menu' | null; // which mobile thing is displaying
}
const defaultState: ILessonState = {
  claimedRewards: [],
  isAnimationStarted: false,
  isEnergyModal: false,
  isLoading: false,
  isNavigatingToNextLesson: false,
  isRewardsModal: false,
  scrollTop: 0,
  isPlaceholder: false,
  isSidePanelOn: true,
  mobileMenuState: null,
};

const stateReducer = (state: ILessonState, arg: Partial<ILessonState> | { type: 'REWARDS_UPDATE'; payload: IRewardMinimal[] }) => {
  if ('type' in arg && arg.type === 'REWARDS_UPDATE') return { ...state, claimedRewards: [...state.claimedRewards, ...arg.payload] };
  return { ...state, ...arg };
};

const VerticalLessonPage = () => {
  const isAuth = useGetIsAuth();
  const debounce = useDebounce();
  const { isMobile } = useDisplay();
  const partnerId = useSelector(getPartnerId);
  const {
    chapter,
    course,
    imageLogo,
    isLoading: isLoadingCourse,
    isQuest,
    lesson,
    resultCourse,
    setResultCourse,
    completedSlides,
    rewardedSlides,
  } = useLessonForCompletion();
  const [state, updateState] = useReducer(stateReducer, defaultState);

  const screenRef = useRef<HTMLDivElement | null>(null);
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const { height: screenHeight } = useGetContainerSize(isMobile ? wrapperRef.current : screenRef.current);

  const theme = useGetCurrentTheme();

  const {
    claimedRewards,
    isAnimationStarted,
    isEnergyModal,
    isNavigatingToNextLesson,
    isRewardsModal,
    scrollTop,
    isPlaceholder,
    isSidePanelOn,
    mobileMenuState,
  } = state;
  const setIsLoading = (isLoading: boolean) => updateState({ isLoading });
  const setIsEnergyModal = (isEnergyModal: boolean) => updateState({ isEnergyModal });
  const setMobileMenuState = (arg: 'progress' | 'menu' | null) => updateState({ mobileMenuState: arg });

  const { navToOverview, nextLessonId, navToNextLesson } = useGetLessonMethods({
    course,
    chapter,
  });

  const handleNavToNextLesson = useCallback(() => {
    if (!!claimedRewards.length) {
      updateState({ isRewardsModal: true, isNavigatingToNextLesson: true });
      return;
    }
    navToNextLesson();
  }, [claimedRewards, navToNextLesson]);

  const startSlideUnlockAnimation = async () => {
    updateState({ isAnimationStarted: true });
    await sleep(2300, updateState, { isAnimationStarted: false });
  };

  const finishLesson = useCallback(() => {
    if (claimedRewards.length > 0) {
      updateState({ isRewardsModal: true });
      return;
    }
    navToOverview();
  }, [navToOverview, claimedRewards.length]);

  const slidesToShowIds = useMemo(() => {
    if (!lesson || !isAuth) return [];
    let list = [...completedSlides];
    for (let slide of lesson.slides) {
      if (!completedSlides.includes(slide._id)) {
        list.push(slide._id);
        if (
          (slide.typeOfSlide !== 'Info' && !slide.slideType.isOptional) ||
          (slide.typeOfSlide === 'Info' && slide.content.find((el) => el.isRequired))
        ) {
          break;
        }
      }
    }
    return list;
  }, [completedSlides, lesson?.slides, isAuth]);

  const slidesToShow = useMemo(() => {
    if (!lesson) return [];
    if (!isAuth) return lesson.slides;

    return lesson.slides.filter((el) => slidesToShowIds.includes(el._id));
  }, [slidesToShowIds, lesson, isAuth]);

  const testsSlidesIds = useMemo(() => {
    if (!lesson) {
      return [];
    }
    return lesson.slides.filter((el) => el.typeOfSlide !== 'Info').map((el) => el._id);
  }, [lesson]);

  const isLastSlideIsRequiredTask = useMemo(() => {
    const slide = slidesToShow[slidesToShow.length - 1];
    if (!slide) return false;
    if (
      slide.typeOfSlide === 'Info' &&
      slide.content.find((el) => el.isRequired && el.type == ESlideWidgetTypes.TASK) &&
      !completedSlides.includes(slide._id)
    ) {
      return true;
    }
    return false;
  }, [slidesToShow, completedSlides]);

  const formatedRewards = useReduceClaimedRewards({ rewards: claimedRewards });

  const lastSlideType = useMemo(() => slidesToShow[slidesToShow.length - 1]?.typeOfSlide ?? 'Info', [slidesToShow]);

  const isCompleted = useMemo(() => !!lesson && completedSlides.length === lesson.slides.length, [completedSlides, lesson]);

  const spaceCourseEditLink = useMemo(() => {
    if (!course || !lesson || !course.spaceInfo?.name) {
      return undefined;
    }
    return `/${course.spaceInfo.name.replaceAll(' ', '_')}/lesson/${lesson._id}`;
  }, [course, lesson]);

  const updateScrollTop = useCallback(
    debounce(() => {
      const scrollTop = screenRef?.current?.scrollTop ?? 0;
      updateState({ scrollTop });
    }, 150),
    []
  );

  const handleScreenRef = useCallback(
    (node: HTMLDivElement) => {
      if (screenRef.current) {
        screenRef.current.removeEventListener('scroll', updateScrollTop);
      }
      if (node) {
        node.addEventListener('scroll', updateScrollTop);
        screenRef.current = node;
      } else {
        screenRef.current = null;
      }
    },
    [updateScrollTop]
  );

  useEffect(() => {
    // reset on lesson change
    if (lesson?._id) {
      updateState(defaultState);
      if (screenRef.current) {
        screenRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
    }
    return () => {
      updateState(defaultState);
    };
  }, [lesson?._id]);

  const handleUpDownNav = useCallback(() => {
    const current = screenRef.current;
    if (!current) return;

    if (scrollTop >= 50) {
      current.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      current.scrollTo({ top: current.scrollHeight, behavior: 'smooth' });
    }
  }, [scrollTop, screenRef.current]);

  const handleShowPlaceholder = useCallback(
    (lastSlideType: string, isTask: boolean) => {
      if ((lastSlideType !== 'Info' || isTask) && !isCompleted) {
        updateState({ isPlaceholder: true });
      } else {
        updateState({ isPlaceholder: false });
      }
    },
    [isCompleted]
  );

  const addClaimedRewards = (payload: IRewardMinimal[]) => updateState({ type: 'REWARDS_UPDATE', payload });

  useEffect(() => {
    if (isCompleted) {
      handleShowPlaceholder('Info', false); // hide placeholder
      return;
    }
    handleShowPlaceholder(lastSlideType, isLastSlideIsRequiredTask);
  }, [lastSlideType, isLastSlideIsRequiredTask, isCompleted]);

  if (!course || isLoadingCourse) {
    return (
      <S.Animation>
        <GlobalLoader isLoading={!course || isLoadingCourse} onLoad={() => updateState({ isLoading: false })} />
      </S.Animation>
    );
  }

  return (
    <S.Page ref={handleScreenRef} $theme={theme}>
      {!isMobile && isAuth && (
        <SideButtons
          menuState={mobileMenuState}
          setMenuState={setMobileMenuState}
          preparedLink={spaceCourseEditLink}
          sidePanelClassName={'vertical-lesson-sidepanel'}
          isShown={isSidePanelOn}
          onClose={() => updateState({ isSidePanelOn: !isSidePanelOn })}
          course={course}
        />
      )}
      <EnergyGoneModal isShown={isEnergyModal} onClose={() => updateState({ isEnergyModal: false })} />
      <FullscreenCongratulations
        open={isRewardsModal}
        rewards={formatedRewards}
        onClickButton={isNavigatingToNextLesson ? navToNextLesson : navToOverview}
        subtitle={
          isCompleted ? (
            <>
              You have completed <span className={'lesson_name'}>{lesson?.name}</span>
            </>
          ) : (
            <></>
          )
        }
        secondSubtitle={'Rewards you earned from this lesson:'}
      />
      <LessonHeader
        courseName={course.name}
        image={imageLogo}
        navToOverview={finishLesson}
        subText={`Chapter ${(chapter?.index ?? 0) + 1} Lesson ${(lesson?.index ?? 0) + 1}`}
        scrollTop={scrollTop}
      />
      {isMobile && !!lesson && !!resultCourse && !partnerId && (
        <LessonMobileMenu
          menuState={mobileMenuState}
          changeMenuState={setMobileMenuState}
          lesson={lesson}
          resultCourse={resultCourse}
          scrollTop={scrollTop}
          screenHeight={screenHeight}
        />
      )}
      {!isQuest && !!resultCourse && (
        <LessonProgress menuState={mobileMenuState} changeMobileMenuState={setMobileMenuState} course={course} resultCourse={resultCourse} />
      )}
      <S.Wrapper ref={wrapperRef}>
        {slidesToShow.map((el) => (
          <Slide
            startSlideUnlockAnimation={startSlideUnlockAnimation}
            key={'slide' + el._id}
            addClaimedRewards={addClaimedRewards}
            setIsLoading={setIsLoading}
            handleEnergyModal={setIsEnergyModal}
            completedSlides={completedSlides}
            resultCourse={resultCourse || null}
            setResultCourse={setResultCourse}
            slide={el}
            testsSlidesIds={testsSlidesIds}
            rewardedSlidesIds={rewardedSlides}
          />
        ))}
        <LessonBottom
          finishLesson={finishLesson}
          toNextLesson={handleNavToNextLesson}
          isAnimationStarted={isAnimationStarted}
          isCompleted={isCompleted}
          isLastSlideInfo={lastSlideType === 'Info'}
          isPlaceholder={isPlaceholder}
          isQuest={!!isQuest}
          isRequiredTask={isLastSlideIsRequiredTask}
          isSpace={!!course.spaceInfo}
          nextLessonId={nextLessonId}
        />
      </S.Wrapper>
      {!isQuest && (
        <S.ScrollTopButton className={`${theme}`} $reversed={scrollTop < 50} onClick={handleUpDownNav}>
          <BsChevronUp strokeWidth={1.5} size={16} />
        </S.ScrollTopButton>
      )}
      {isMobile && (
        <S.LessonExitButton
          onClick={finishLesson}
          initial={{ y: -100, opacity: 0 }}
          animate={scrollTop <= 50 ? { y: -100, opacity: 0 } : { y: 0, opacity: 1 }}
        >
          <Cross />
        </S.LessonExitButton>
      )}
      {!!resultCourse && <ResetCourseButton style={{ position: 'fixed', right: 0, bottom: 0 }} resultCourseId={resultCourse._id} />}
    </S.Page>
  );
};

export default VerticalLessonPage;
