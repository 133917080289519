import { Fragment, useEffect } from 'react';
import styles from './styles.module.css';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { ILogInForm, ISignUpForm } from 'interface';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { BasicButtonV1, Input, InputPassword } from 'shared/ui';
import { ReactComponent as MailIcon } from 'assets/components/mail.svg';
import { ReactComponent as PassIcon } from 'assets/components/password.svg';
import { Error } from 'features/auth/ui';
import { useTranslation } from 'react-i18next';
import { LOCAL_STORAGE_KEYS } from 'app/constants';

interface IProps {
  isLoading: boolean;
  error?: string;
  onSubmitHandler: (data: ILogInForm) => void;
}

export const LogInForm = ({ onSubmitHandler, error, isLoading }: IProps) => {
  const { email, password } = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SIGNUP_TEMP) ?? '{}') as ISignUpForm;
  const { t } = useTranslation(['auth', 'common']);
  const schema = yup.object().shape({
    email: yup.string().email(t('common:formErrors.email')).required(t('common:formErrors.requiredEmail')),
    password: yup
      .string()
      .min(5, t('common:formErrors.minLength', { count: 5 }))
      .max(32, t('common:formErrors.maxLength', { count: 32 }))
      .required(t('common:formErrors.requiredPassword')),
  });
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
  } = useForm<ILogInForm>({
    mode: 'all',
    resolver: yupResolver(schema),
    reValidateMode: 'onChange',
    defaultValues: { email: '', password: '' },
  });

  const onSubmit = (data: ILogInForm) => {
    onSubmitHandler(data);
  };

  useEffect(() => {
    if (Boolean(email && password)) {
      setValue('email', email);
      setValue('password', password);
    }
  }, [email, password, setValue]);

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Fragment>
            <Input
              type="email"
              icon={MailIcon}
              placeholder={t('logIn.form.email')}
              className={styles.input}
              isError={!!errors?.email?.message}
              defaultValue={email}
              {...field}
            />
          </Fragment>
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <Fragment>
            <InputPassword
              defaultValue={password}
              icon={PassIcon}
              placeholder={t('logIn.form.password')}
              isError={!!errors?.password?.message}
              {...field}
            />
          </Fragment>
        )}
      />
      <div className={styles.errorContainer}>
        <Error title={errors?.email?.message || errors?.password?.message || error} />
      </div>
      <BasicButtonV1 isLoading={isLoading} disabled={isLoading || !isValid} type={'submit'}>
        {t('logIn.form.submit')}
      </BasicButtonV1>
    </form>
  );
};
