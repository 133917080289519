import { FC } from 'react';
import { nanoid } from 'nanoid';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as AngleIcon } from 'assets/icons/angle.svg';

import styles from './styles.module.css';
import { TBreadcrumbChunk } from '../../../utils/breadcrumbs';

type TBreadcrumbsProps = {
  chunks: TBreadcrumbChunk[];
};

export const Breadcrumbs: FC<TBreadcrumbsProps> = ({ chunks }) => {
  const navigate = useNavigate();

  function handleNav(index: number, path?: string) {
    if (!path) return;

    navigate(path);
  }

  return (
    <div className={styles.root}>
      {chunks.map((chunk, idx) => {
        const { path, label } = chunk;

        return (
          <div key={nanoid()}>
            <button className={styles.chunk} onClick={() => handleNav(idx, path)}>
              {label}
            </button>
            {idx !== chunks.length - 1 && <AngleIcon className={styles.delimiter} />}
          </div>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
