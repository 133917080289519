import { FC } from 'react';
import * as Styled from './styled';
import { getFormatDate } from 'utils/date';
import { IDailyReward } from '../../../types';
import { nanoid } from 'nanoid';
import clsx from 'clsx';
import { useDisplay } from 'hooks';

interface IProps {
  rewards: IDailyReward[];
  variant?: 'banner' | 'panel';
  label?: string;
  className?: string;
  classNameContainer?: string;
  fontSize?: string;
  userDailis: any[];
  isRewardable: boolean;
  max?: number;
  labelMargin?: string;
  isRealNext?: boolean;
}

export const NextRewards: FC<IProps> = ({
  rewards,
  variant = 'banner',
  label,
  className = '',
  classNameContainer = '',
  fontSize,
  userDailis,
  max = 6,
  labelMargin,
  isRewardable,
  isRealNext = false,
}) => {
  const startIndex = userDailis.length + (isRewardable ? 2 : 1);
  const displayedRewardsCount = max - 1;
  const placeholdersCount = max - displayedRewardsCount;

  return (
    <Styled.Wrap className={className}>
      {label && (
        <Styled.Label $fontSize={fontSize} $margin={labelMargin}>
          {label}
        </Styled.Label>
      )}
      <Styled.Container $variant={variant} className={classNameContainer}>
        {rewards.slice(startIndex, startIndex + displayedRewardsCount).map((reward, index) => {
          const date = new Date();
          date.setDate(date.getDate() + index + 2 - Number(isRealNext));

          return <Reward reward={reward} key={reward.name + reward.createdAt.toString() + index} date={date} />;
        })}
        {Array.from({ length: placeholdersCount }).map((_, index) => {
          const date = new Date();
          date.setDate(date.getDate() + displayedRewardsCount + index + Number(isRealNext));
          return <RewardPlaceholder date={date} key={nanoid()} />;
        })}
      </Styled.Container>
    </Styled.Wrap>
  );
};

type Props = {
  reward: IDailyReward;
  date: Date;
};

const Reward = ({ reward, date }: Props) => {
  const { isMobile } = useDisplay();
  return (
    <Styled.RewardWrap>
      <Styled.Reward $grade={reward.grade}>
        <img src={reward.image} alt={reward.name} />
      </Styled.Reward>
      <Styled.Date className={clsx({ mobile: isMobile })}>{getFormatDate(date)}</Styled.Date>
    </Styled.RewardWrap>
  );
};

const RewardPlaceholder = ({ date }: { date: Date }) => {
  const { isMobile } = useDisplay();
  return (
    <Styled.RewardWrap>
      <Styled.Reward $grade={'common'}>
        <p className={clsx({ mobile: isMobile })}>?</p>
      </Styled.Reward>
      <Styled.Date className={clsx({ mobile: isMobile })}>{getFormatDate(date)}</Styled.Date>
    </Styled.RewardWrap>
  );
};
