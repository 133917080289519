import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;

export const List = styled(motion.div)<{ $theme?: THEMES }>`
  display: flex;
  width: 100%;
  align-items: center;
  height: 100px;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? 'rgb(var(--glass-bg--rgb))' : 'rgba(255, 255, 255, 0.04)')};
  border: 1px solid ${(p) => (p.$theme === THEMES.LIGHT ? 'rgba(17, 17, 17, 0.2)' : 'rgba(255, 255, 255, 0.2)')};
  backdrop-filter: blur(98.4463px);
  border-radius: 10px;
  gap: 6px;
  padding: 0px 14px;
`;

export const Amount = styled.p<{ $theme?: THEMES }>`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: var(--font-color-strong);
  background: ${(p) => (p.$theme === THEMES.LIGHT ? '#1111110F' : '#ffffff0F')};
  width: 100%;
  border-radius: 4px;
  transition: 0.3s;
`;

export const ItemWrapper = styled(motion.div)`
  cursor: pointer;
`;

export const DeleteView = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: #11111166;
  border-radius: 4px;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 50%;
    height: 50%;
  }
`;

export const Item = styled(motion.div)<{ $theme?: THEMES }>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 75px;
  align-items: center;
  transition: 0.3s;
  border-radius: 4px;
  &:hover {
    background: ${(p) => (p.$theme === THEMES.LIGHT ? '#1111110F' : '#ffffff0F')};
    & ${Amount} {
      background: transparent;
    }
    & ${DeleteView} {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const Img = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;
