import styles from './styles.module.css';
import { SignUpForm } from 'features/auth';
import { AnimateWrapper } from 'features/auth/ui';
import { ISignUpForm } from 'interface';

interface IProps {
  isLoading: boolean;
  error?: string;
  onSubmitHandler: (data: ISignUpForm) => void;
  openWalletModal: () => void;
}

export const SignUp = ({ onSubmitHandler, isLoading, error, openWalletModal }: IProps) => {
  return (
    <AnimateWrapper>
      <div className={styles.container}>
        <SignUpForm error={error} onSubmitHandler={onSubmitHandler} isLoading={isLoading} />
      </div>
    </AnimateWrapper>
  );
};
