import { dispatch, useDisplay } from 'hooks';
import { setActiveUserMenuPanel } from 'store';

import { EffectsWidget } from 'shared/ui';

import * as Styled from './styles';
import clsx from 'clsx';

export const Effects = () => {
  const { isMobile } = useDisplay();
  // тут будет баг, так как при клике на эффект отработает две функции - эта, и эффекта
  const handleEffectsPanelOpen = () => dispatch(setActiveUserMenuPanel('effects'));

  return (
    <Styled.Container className={clsx({ mobile: isMobile })} role="button" onClick={handleEffectsPanelOpen}>
      <EffectsWidget />
    </Styled.Container>
  );
};
