import { Modal } from 'shared/ui';
import { SpaceForm } from '../SpaceEditingModal/styles';
import { useForm } from 'react-hook-form';
import { SpaceFormBox } from '../../SpaceCreation/components/ui/styles';
import SpaceFormInput from '../../SpaceCreation/components/ui/Input';
import { object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { ModalBox, ModalButtons, ModalTitle } from './styles';
import styles from './modal.module.scss';
import { TCreateCourseDTO, getCurrentTheme, useCreateQuestMutation } from 'store';
import { DIFFICULTIES, GRADES, LANGS, THEMES } from 'app/constants';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { SpaceGradientButton } from '../../SpaceCreation/styles';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import Answer from 'components/library/messages/Answer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  spaceId: string;
};

const CreateQuestModal = ({ isOpen, onClose, spaceId }: Props) => {
  const theme = useSelector(getCurrentTheme);
  const { spaceName } = useParams();
  const formSchema = object({
    name: string().required('Please add quest name'),
  });

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<{ name: string }>({
    resolver: yupResolver(formSchema),
  });

  const [createQuest, { isLoading }] = useCreateQuestMutation();

  const onSubmit = ({ name }: { name: string }) => {
    const newQuest: TCreateCourseDTO = {
      //@ts-ignore
      spaceId,
      name,
      category: 'quests',
      description: [{ language: LANGS.EN, title: 'desc' }],
      difficulty: DIFFICULTIES.BEGINNER,
      grade: GRADES.COMMON,
      //@ts-ignore
      estimatedTime: '1s',
      // imageBanner: 'a',
      groups: ['web3'],
    };

    createQuest(newQuest)
      .unwrap()
      .then((res) => {
        reset();
        toast.success(<Answer type="correct" label="Quest created!" />, { autoClose: 1000 });
        navigate(`/${spaceName}/lesson/${res.chapters[0]!.lessons[0]!._id}`);
        onClose();
      })
      .catch(() => {
        toast.error(<Answer type="incorrect" label="Failed to create quest" />, { autoClose: 1000 });
      });
  };

  return (
    <Modal
      closeButtonLocation="outside"
      open={isOpen}
      className={`${styles.createQuestModal} ${theme === THEMES.LIGHT ? styles.light : ''}`}
      onClose={onClose}
    >
      <ModalBox>
        <ModalTitle $theme={theme}>Add new Quest</ModalTitle>
        <SpaceForm onSubmit={handleSubmit(onSubmit)}>
          <SpaceFormBox>
            <SpaceFormInput
              placeholder="Quest name"
              register={register}
              label="name"
              disabled={isLoading}
              error={!!errors['name']}
              errorText={errors['name']?.message}
            />
          </SpaceFormBox>
          <ModalButtons>
            <SpaceGradientButton>{isLoading ? <MightyLoaderMini style={{ width: 25, height: 25 }} /> : 'Continue'}</SpaceGradientButton>
          </ModalButtons>
        </SpaceForm>
      </ModalBox>
    </Modal>
  );
};

export default CreateQuestModal;
