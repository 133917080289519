import styles from './styles.module.scss';

type Props = {
  stage: string;
  blinking?: boolean;
};

const ChainAnimation = ({ stage, blinking = true }: Props) => {
  const currentGradiId = stage === 'progress' ? 'initial' : stage;

  return (
    <div className={styles.animBox}>
      <svg
        width="44"
        height="54"
        viewBox="0 0 44 54"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${styles.chain1} ${styles[stage]} ${blinking ? styles.blinking : ''}`}
      >
        <path
          d="M33.9885 12.3185C33.6296 11.142 33.3079 10.3075 32.8974 9.59643C30.8878 6.11567 27.1739 3.97143 23.1546 3.97143C21.6735 3.97143 19.9892 4.42273 16.6207 5.32533C13.2521 6.22794 11.5678 6.67924 10.2852 7.41979C6.80441 9.42941 4.66017 13.1433 4.66017 17.1626C4.66017 18.6437 5.11147 20.3279 6.01408 23.6965L9.89637 38.1854C10.799 41.554 11.2503 43.2382 11.9908 44.5209C14.0004 48.0017 17.7144 50.1459 21.7336 50.1459C23.2147 50.1459 24.899 49.6946 28.2675 48.792C31.6361 47.8894 33.3204 47.4381 34.603 46.6975C38.0838 44.6879 40.228 40.974 40.228 36.9548C40.228 36.1337 40.0893 35.2501 39.8119 34.0518"
          stroke={`url(#chain1-${currentGradiId})`}
          strokeOpacity="0.95"
          strokeWidth="7.5"
          strokeLinecap="round"
          style={{ mixBlendMode: 'overlay' }}
        />
        <defs>
          <linearGradient id="chain1-initial" x1="-7.70048" y1="34.9423" x2="26.5531" y2="-3.18087" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.7" />
            <stop offset="1" stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            xmlns="http://www.w3.org/2000/svg"
            id="chain1-success"
            x1="74.34"
            y1="53.8536"
            x2="-2.74529"
            y2="30.4992"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6487EF" />
            <stop offset="1" stopColor="#6487EF" stopOpacity="0.19" />
          </linearGradient>
          <linearGradient
            xmlns="http://www.w3.org/2000/svg"
            id="chain1-error"
            x1="-7.70048"
            y1="34.9423"
            x2="26.5531"
            y2="-3.18087"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F14F63" />
            <stop offset="1" stopColor="#F14F63" stopOpacity="0.1" />
          </linearGradient>
        </defs>
      </svg>

      <svg
        width="52"
        height="52"
        viewBox="0 0 52 52"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={`${styles.chain2} ${styles[stage]} ${blinking ? styles.blinking : ''}`}
      >
        <path
          d="M7.72235 23.7211C6.88295 24.6202 6.32108 25.316 5.91053 26.0271C3.90091 29.5079 3.90092 33.7963 5.91053 37.2771C6.65108 38.5598 7.88406 39.7927 10.35 42.2587C12.816 44.7247 14.049 45.9576 15.3316 46.6982C18.8124 48.7078 23.1009 48.7078 26.5816 46.6982C27.8643 45.9576 29.0973 44.7247 31.5632 42.2587L42.1698 31.6521C44.6358 29.1861 45.8688 27.9532 46.6093 26.6705C48.6189 23.1897 48.6189 18.9013 46.6093 15.4205C45.8688 14.1378 44.6358 12.9048 42.1698 10.4389C39.7039 7.97293 38.4709 6.73995 37.1882 5.9994C33.7075 3.98978 29.419 3.98978 25.9382 5.9994C25.2271 6.40994 24.5313 6.97182 23.6323 7.81122"
          stroke={`url(#chain2-${currentGradiId})`}
          strokeOpacity="0.95"
          strokeWidth="7.5"
          strokeLinecap="round"
          style={{ mixBlendMode: 'overlay' }}
        />
        <defs>
          <linearGradient id="chain2-initial" x1="-7.70048" y1="34.9423" x2="26.5531" y2="-3.18087" gradientUnits="userSpaceOnUse">
            <stop stopColor="white" stopOpacity="0.7" />
            <stop offset="1" stopColor="white" stopOpacity="0.1" />
          </linearGradient>
          <linearGradient
            xmlns="http://www.w3.org/2000/svg"
            id="chain2-success"
            x1="74.34"
            y1="53.8536"
            x2="-2.74529"
            y2="30.4992"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#6487EF" />
            <stop offset="1" stopColor="#6487EF" stopOpacity="0.19" />
          </linearGradient>
          <linearGradient
            xmlns="http://www.w3.org/2000/svg"
            id="chain2-error"
            x1="-7.70048"
            y1="34.9423"
            x2="26.5531"
            y2="-3.18087"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#F14F63" />
            <stop offset="1" stopColor="#F14F63" stopOpacity="0.1" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default ChainAnimation;
