import { setIsMainMenu, getIsAuth, getCurrentTheme } from 'store';
import { dispatch, useDisplay, useNavigateToAuth } from 'hooks';
import { EMenuScreens } from 'app/constants';
import { useSelector } from 'react-redux';

import { LazyImage } from 'shared/ui/lazyImage';

import crate from 'assets/images/crate-of-items-1.png';

import styles from './styles.module.css';

export default function SlideOne() {
  const isAuth = useSelector(getIsAuth);
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);
  const navToAuth = useNavigateToAuth();

  function navToInv() {
    if (isAuth) {
      if (!isMobile) {
        dispatch(setIsMainMenu({ isMainMenu: true, screen: EMenuScreens.INVENTORY }));
      }
    } else {
      navToAuth();
    }
  }

  return (
    <div className={`${styles.container} ${styles[theme]}`} onClick={navToInv}>
      <LazyImage src={crate} alt="crate of items" className={styles.image} />
      <h4 className={styles.header}>My Inventory</h4>
      <p className={styles.text}>Inventory keeps track of what items you have in your wallet and how many EXP tokens you have</p>
    </div>
  );
}
