import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { STATIC_HOST_PATH } from 'app/constants/path';
import { ROUTES } from 'app/constants';
import useAverageColorFromImageUrl from 'hooks/useAverageColorFromImageUrl';
import { TResultCourseWithFullCourseId } from 'interface/courses';

import SmallProgressBar from 'shared/ui/progress-bar/SmallProgressBar';

import * as Styled from './styles';

interface Props extends TResultCourseWithFullCourseId {
  className?: string;
  onClick?: () => void;
}

export const ProfileCourseCard = ({ _id, completion, name, imageCover, className = '', onClick }: Props) => {
  const navigate = useNavigate();
  const courseName = name?.length < 25 ? name : name?.slice(0, 23) + '...';
  const cover = STATIC_HOST_PATH + imageCover;
  const bgColor = useAverageColorFromImageUrl(cover, { opacity: '0.1' });

  function goToCourse() {
    navigate(`${ROUTES.COURSES}/${_id}`);
  }
  const handleClick = () => {
    onClick ? onClick() : goToCourse();
  };

  return (
    <Styled.Container
      className={clsx(`profile-course bright--hover`, { [className]: !!className })}
      onClick={handleClick}
      style={{ backgroundColor: bgColor }}
    >
      <Styled.ImageContainer>
        <img src={cover} alt={name} />
      </Styled.ImageContainer>
      <Styled.Body>
        <Styled.CourseName>{courseName}</Styled.CourseName>
        <SmallProgressBar labelType="big" completion={completion} />
      </Styled.Body>
    </Styled.Container>
  );
};
