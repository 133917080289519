import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import * as Styled from './styles';

interface Props {
  label: string;
  onChange: () => any;
  checked: boolean;
  prefix?: string;
  className?: string;
  right?: any;
  type?: 'radio' | 'checkbox';
  children?: any;
}

export const MightyCheckboxRadio = ({
  label,
  onChange,
  checked = false,
  prefix = '',
  right = null,
  type = 'radio',
  children,
  className = '',
  ...rest
}: Props) => {
  const theme = useSelector(getCurrentTheme);

  return (
    <Styled.Container {...rest} className={`fancy-border bw--2 glass-div ${theme} ${type} linear right-${right} ${className}`}>
      <Styled.Label>
        <Styled.Input type={type} checked={checked} value={label} onChange={onChange} />
        <Styled.Span>{prefix + label}</Styled.Span>
      </Styled.Label>
      {children}
    </Styled.Container>
  );
};
