import styled, { css } from 'styled-components';

export const CountUpWrap = styled.div<{ $color?: string; $gap?: string }>(
    ({ $color, $gap }) => css`
      display: flex;
      align-items: center;
      gap: ${$gap};

      span {
        color: ${$color ? $color : 'var(--font-color-strong)'};
        font-size: 55px;
        font-weight: 500;
        line-height: 110%;
        transition: color 0.2s;
      }

      img {
        max-width: 74px;
      }
    `
  ),
  Percent = styled.div<{ $color: string; $textShadowColor: string; $textAlign?: string }>(
    ({ $color, $textShadowColor, $textAlign = 'center' }) => css`
      color: ${$color};
      text-shadow: 0 0 19px ${$textShadowColor};
      font-size: 36px;
      font-weight: 700;
      line-height: 125%;
      letter-spacing: 1px;
      text-align: ${$textAlign};
      margin: 2px 0 6px 0;

      span {
        color: inherit;
        font-size: 28px;
        line-height: 125%;
      }
    `
  ),
  ActivationWrap = styled.div<{ $margin?: string; $gap?: string }>(
    ({ $margin = '34px 0 31px 0', $gap = '7px' }) => css`
      display: flex;
      align-items: center;
      gap: ${$gap};
      margin: ${$margin};
    `
  ),
  Time = styled.div(
    () => css`
      display: flex;
      align-items: center;
      gap: 4px;
      padding: 4px 8px;

      border-radius: 4px;
      background: radial-gradient(379.3% 165.53% at 89.41% 9.32%, rgba(149, 216, 245, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(81deg, rgba(50, 53, 71, 0.47) -2.6%, rgba(40, 42, 57, 0.8) 100.19%);

      span {
        color: #95d8f5;
        font-size: 18px;
        font-weight: 500;
        line-height: 125%;
      }
    `
  );
