import { useDisclosure } from 'hooks';
import { HuePicker, ColorResult } from 'react-color';
import { useState } from 'react';
import * as S from './styles';
import { ReactComponent as CrossIcon } from './assets/cross.svg';

interface ColorPickerProps {
  onChange: (arg: { color: string }) => void;
  color: string;
  closePicker: () => void;
}

const ColorPicker: React.FC<ColorPickerProps> = ({ onChange, color, closePicker }) => {
  const { isOpen, toggle, close } = useDisclosure(false);
  const [newColor, setNewColor] = useState<string>(color);

  const handleChooseColor = () => {
    onChange({ color: newColor });
    close();
  };

  const handleColorChange = (colorResult: ColorResult) => {
    setNewColor(colorResult.hex);
  };

  return (
    <>
      <S.PickerMenu>
        <S.PickerBox>
          <S.PickerBlock>
            <S.PickerHex>HEX</S.PickerHex>
          </S.PickerBlock>
          <S.PickerBlock>
            <S.PickerPreview onClick={toggle} color={newColor} role="button" aria-label="Toggle color picker" />
            {newColor}
          </S.PickerBlock>
        </S.PickerBox>
        <S.PickerBtn onClick={handleChooseColor}>Choose</S.PickerBtn>
        <S.ColorButton onClick={closePicker} aria-label="Close color picker">
          <CrossIcon />
        </S.ColorButton>
      </S.PickerMenu>
      {isOpen && (
        <HuePicker
          color={newColor}
          onChange={handleColorChange}
          width="100%"
          styles={{
            input: {
              hue: {
                borderRadius: '4px',
                width: '100%',
              },
            },
            wrap: {
              hue: {
                width: '100%',
              },
            },
          }}
        />
      )}
    </>
  );
};

export default ColorPicker;
