import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
    `
  ),
  Tab = styled.span(
    () => css`
      width: 100%;
      margin-right: 3px;
      border-radius: 4px;
      background: rgba(255, 255, 255, 0.2);

      &.light {
        background: rgba(0, 0, 0, 0.25);
      }

      &.active {
        background: #627eea;
      }

      &:last-of-type {
        margin-right: 0;
      }
    `
  );
