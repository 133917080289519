import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 10px 0;
    `
  ),
  Span = styled.span`
    color: var(--grade-dark-color-uncommon);
    font-size: 14px;
    white-space: pre;
  `,
  Income = styled.span`
    font-size: 14px;
    white-space: pre;
  `;
