import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';
import CountUp from 'react-countup';

import AvatarPic from 'components/library/utils/AvatarPic/avatar';

import { ReactComponent as Lightning } from 'assets/icons/lightning.svg';

import * as Styled from './styles';

type Props = {
  targetRef: React.RefObject<HTMLDivElement>;
  exp: number;
};

export const MiniUser = ({ targetRef, exp }: Props) => {
  const [currentExp, setCurrentExp] = useState(0);
  const { nickname, avatar, customization } = useSelector(getUserData);

  useEffect(() => {
    setCurrentExp(exp);
  }, [exp]);

  return (
    <Styled.Container>
      <Styled.Body>
        <Styled.Exp ref={targetRef}>
          <CountUp useEasing={true} start={0} duration={3} suffix=" EXP" end={currentExp} />
        </Styled.Exp>
        <Styled.Energy>
          <Lightning /> 100%
        </Styled.Energy>
      </Styled.Body>
      <AvatarPic nickname={nickname} avatar={avatar} customization={customization} />
    </Styled.Container>
  );
};
