import { motion } from 'framer-motion';
import { Flex } from 'shared/ui';
import styled, { css } from 'styled-components';

export const Wrapper = styled(motion.div)(
  () =>
    css`
      width: 458px;
    `
);

export const Root = styled(Flex)(
  () =>
    css`
      width: 458px;
    `
);

export const Body = styled(Flex)(
  () => css`
    position: relative;
    border-radius: 5px;
    overflow: hidden;
    width: 458px;
    min-height: 84px;
    padding: 16px;
    background: radial-gradient(153.5% 253.97% at 100% 4.78%, rgba(100, 135, 239, 0.4) 0%, rgba(100, 135, 239, 0) 100%);
    border-radius: 8px;
    transition: 0.3s;
  `
);

export const Name = styled.span(
  () => css`
    display: inline-flex;
    align-items: center;
    gap: 8px;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 6px;
    height: 24px;
  `
);

export const ChapterName = styled.span(
  () => css`
    font-size: 24px;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.5px;
    color: var(--basic-blue);
  `
);

export const Description = styled.form<{ $isEdit: boolean }>(
  ({ $isEdit }) => css`
    font-size: 24px;
    font-weight: 500;
    color: var(--basic-blue);
    width: calc(100% - 42px);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    height: 27px;
    ${$isEdit &&
    css`
      overflow: auto;
      text-overflow: visible;
      white-space: normal;
      resize: none;
      height: fit-content;
    `}
  `
);

export const Controls = styled(Flex)(
  () => css`
    margin-top: 15px;
    gap: 15px;
  `
);

export const ErrorMessage = styled.div(
  () => css`
    font-size: 14px;
    margin-top: 15px;
  `
);

export const WarningBadge = styled.div(
  () => css`
    margin-bottom: 15px;
  `
);

export const CircleArrowButton = styled.button<{ $isOpen: boolean }>(
  ({ $isOpen }) => css`
    cursor: pointer;
    transition: transform 0.3s ease-in-out;
    height: 32px;
    width: 32px;
    padding: 10px;
    background: #ffffff26;
    border-radius: 50%;
    position: absolute;
    right: 16px;
    top: 28px;

    ${$isOpen &&
    css`
      transform: rotate(180deg);
    `}
  `
);
