import clsx from 'clsx';

import { ReactComponent as Star } from 'assets/icons/star-0deg.svg';
import { ReactComponent as StarActive } from 'assets/icons/star-0deg--active.svg';

import styles from './styles.module.css';

interface IProps {
  className?: string;
  isFavourite?: boolean;
  disabled?: boolean;
  onClick: () => any;
}

export function AddFavouriteButton({ className = '', isFavourite = false, onClick, disabled }: IProps) {
  return (
    <div
      role="button"
      onClick={() => (disabled ? {} : onClick())}
      className={clsx(styles.container, { [className]: !!className, [styles.fav]: isFavourite })}
    >
      {isFavourite ? <StarActive /> : <Star />}
    </div>
  );
}
