import styles from './styles.module.scss';

type Props = {
  stage: string;
};
const RoundSVG = ({ stage }: Props) => {
  const currentGradiId = stage === 'progress' ? 'initial' : stage;

  return (
    <svg width="91" height="90" viewBox="0 0 91 90" fill="none" className={`${styles.round} ${styles[stage]}`} xmlns="http://www.w3.org/2000/svg">
      <circle
        cx="45.1523"
        cy="45"
        r="43"
        stroke={`url(#round-${currentGradiId})`}
        strokeOpacity="0.95"
        strokeWidth="4"
        style={{ mixBlendMode: 'overlay' }}
      />
      <defs>
        <linearGradient id="round-initial" x1="90.2411" y1="90.6333" x2="-12.9958" y2="68.8033" gradientUnits="userSpaceOnUse">
          <stop stopColor="white" stopOpacity="0.7" />
          <stop offset="1" stopColor="white" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          xmlns="http://www.w3.org/2000/svg"
          id="round-success"
          x1="90.2411"
          y1="90.6333"
          x2="-12.9958"
          y2="68.8033"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#6487EF" />
          <stop offset="1" stopColor="#6487EF" stopOpacity="0.1" />
        </linearGradient>
        <linearGradient
          xmlns="http://www.w3.org/2000/svg"
          id="round-error"
          x1="-7.70048"
          y1="34.9423"
          x2="26.5531"
          y2="-3.18087"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F14F63" />
          <stop offset="1" stopColor="#F14F63" stopOpacity="0.1" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default RoundSVG;
