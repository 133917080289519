import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { ReactComponent as MenuClosedSVG } from './assets/menu-closed.svg';
import { ReactComponent as MenuOpenedSVG } from './assets/menu-opened.svg';

export const Container = styled.div<{ $theme: THEMES }>`
  position: fixed;
  z-index: 3002;
  left: 0;
  width: 100%;
  height: 108px;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? 'rgba(28, 29, 38, 0.4)' : 'rgba(217, 217, 217, 0.4)')};
  transition: 0.5s ease-in-out;
  bottom: 0px;
  transform: translateY(0);
  &.isHidden {
    transform: translateY(104px);
  }
`;

export const Progress = styled(motion.div)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: #fdfdfd26;
  border-radius: 25px;
  height: 4px;
`;

export const ProgressLine = styled(Progress)`
  background: #6487ef;
  width: 1px;
  transform-origin: 0 0;
  transition: 0.5s;
`;

export const ToggleButton = styled.div`
  position: absolute;
  bottom: calc(100% + 16px);
  right: 16px;
  width: 48px;
  height: 48px;
  border: 4px solid #6487ef;
  border-radius: 50%;
  background: radial-gradient(45% 45% at 50% 50%, rgba(100, 135, 239, 0.8) 0%, rgba(100, 135, 239, 0) 100%);
  filter: drop-shadow(0px 0px 3.2145px rgba(100, 135, 239, 0.4));
  cursor: pointer;
`;
export const ToggleIconClosed = styled(MenuClosedSVG)<{ $visible: boolean }>`
  width: 20px;
  height: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  rotate: ${({ $visible }) => ($visible ? '0deg' : '45deg')};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition-timing-function: ease-in-out;
  transition: 0.5s;
`;

export const ToggleIconOpened = styled(MenuOpenedSVG)<{ $visible: boolean }>`
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  rotate: ${({ $visible }) => ($visible ? '0deg' : '-45deg')};
  opacity: ${({ $visible }) => ($visible ? 1 : 0)};
  transition-timing-function: ease-in-out;
  transition: 0.5s;
`;

export const Menu = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding-top: 12px;
  padding: 12px 20px 16px;
  overflow: auto;
  width: 100%;
  height: 100%;
  position: relative;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const ButtonWrapper = styled.div<{ $cursor?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  height: fit-content;
  gap: 8px;
  cursor: ${({ $cursor = 'pointer' }) => $cursor};

  & > * {
    margin: 0 !important;
    padding: 0 !important;
  }
`;

export const CircleButton = styled(motion.div)<{ $theme: THEMES }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
  overflow: hidden;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? '#ffffff26' : 'var(--basic-light-bg)')};
  border-radius: 50%;

  & > svg {
    color: ${({ $theme }) => ($theme === THEMES.DARK ? 'white' : 'var(--mighty-blue)')};
  }
  & > .lessons {
    width: 26px;
    height: 20px;
  }
  & > .exp {
    width: 44px;
    height: 44px;
  }
  & > .theme,
  & > .settings {
    width: 24px;
    height: 24px;
  }
`;

export const Sub = styled.p<{ $color?: string, $noMaxWidth?: boolean }>`
  color: ${({ $color }) => $color ?? 'var(--font-color-strong)'};
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  display: flex;
  justify-content: center;
  white-space: nowrap;
  line-height: 100%;
  max-width: ${({$noMaxWidth}) => $noMaxWidth ? 'none' : '50px'};
`;
