import { motion } from 'framer-motion';

export const EnergyRecoveryAnimation = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="56" viewBox="0 0 36 56" fill="none">
      <path
        d="M0.158552 34.0571C0.263811 34.2355 0.416459 34.3839 0.600928 34.4871C0.785396 34.5903 0.995083 34.6447 1.20859 34.6448L17.0195 34.6448L14.3688 54.0954C14.3403 54.3557 14.4039 54.6176 14.5493 54.839C14.6947 55.0604 14.9135 55.2283 15.1704 55.3158C15.4273 55.4033 15.7073 55.4052 15.9655 55.3212C16.2236 55.2372 16.4448 55.0722 16.5935 54.8529L35.8212 22.5017C35.9337 22.3261 35.9953 22.1248 35.9997 21.9187C36.0042 21.7126 35.9513 21.509 35.8466 21.3291C35.7418 21.1491 35.589 20.9992 35.4038 20.8949C35.2187 20.7905 35.0079 20.7355 34.7932 20.7355L19.2186 20.7355L21.3192 1.25561C21.3399 0.996191 21.2696 0.737591 21.1195 0.520961C20.9695 0.304332 20.7483 0.142117 20.4912 0.0601222C20.2341 -0.0218723 19.9559 -0.018931 19.7007 0.0684729C19.4455 0.155877 19.2281 0.322726 19.0831 0.542471L0.173032 32.8902C0.0633752 33.0661 0.00426216 33.2667 0.00171778 33.4717C-0.000826593 33.6767 0.0532923 33.8787 0.158552 34.0571Z"
        fill="#95D8F5"
        fillOpacity="0.15"
      />
      <mask id="mask0_20495_35384" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="0" width="36" height="56">
        <path
          d="M0.158552 34.0571C0.263811 34.2355 0.416459 34.3839 0.600928 34.4871C0.785396 34.5903 0.995083 34.6447 1.20859 34.6448L17.0195 34.6448L14.3688 54.0954C14.3403 54.3557 14.4039 54.6176 14.5493 54.839C14.6947 55.0604 14.9135 55.2283 15.1704 55.3158C15.4273 55.4033 15.7073 55.4052 15.9655 55.3212C16.2236 55.2372 16.4448 55.0722 16.5935 54.8529L35.8212 22.5017C35.9337 22.3261 35.9953 22.1248 35.9997 21.9187C36.0042 21.7126 35.9513 21.509 35.8466 21.3291C35.7418 21.1491 35.589 20.9992 35.4038 20.8949C35.2187 20.7905 35.0079 20.7355 34.7932 20.7355L19.2186 20.7355L21.3192 1.25561C21.3399 0.996191 21.2696 0.737591 21.1195 0.520961C20.9695 0.304332 20.7483 0.142117 20.4912 0.0601222C20.2341 -0.0218723 19.9559 -0.018931 19.7007 0.0684729C19.4455 0.155877 19.2281 0.322726 19.0831 0.542471L0.173032 32.8902C0.0633752 33.0661 0.00426216 33.2667 0.00171778 33.4717C-0.000826593 33.6767 0.0532923 33.8787 0.158552 34.0571Z"
          fill="#95D8F5"
        />
      </mask>
      <g mask="url(#mask0_20495_35384)">
        <motion.path
          animate={{
            d: [
              'M28.7014 61.4361C36.2927 61.9148 41.5117 56.6487 41.5117 56.6487V87.2871H-1.66388V58.5636C-1.66388 58.5636 3.49659 55.2812 9.66454 55.2812C20.2288 55.2812 19.2307 60.8389 28.7014 61.4361Z',
              'M28.9451 9.15363C36.6813 10.0212 42 0.478049 42 0.478049V56H-2V3.94817C-2 3.94817 3.25901 -2 9.54472 -2C20.3107 -2 19.2936 8.07132 28.9451 9.15363Z',
            ],
          }}
          transition={{ duration: 2, delay: 0.3 }}
          fill="#95D8F5"
          fillOpacity="0.6"
        />
        <motion.path
          animate={{
            d: [
              'M9.30643 60.3828C1.71512 60.8615 -3.50391 56.6493 -3.50391 56.6493V87.2877H39.6717V60.3828C39.3554 58.1487 33.6774 53.4609 27.5095 53.4609C20.3926 53.4609 18.7771 59.7855 9.30643 60.3828Z',
              'M9.05494 6.68682C1.31868 7.56426 -4 -0.156144 -4 -0.156144V56H40V6.68682C39.6777 2.5921 33.8913 -6 27.6055 -6C20.3528 -6 18.7064 5.59216 9.05494 6.68682Z',
            ],
          }}
          transition={{ duration: 2, delay: 0.5 }}
          fill="#95D8F5"
        />
      </g>
      <motion.path
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 2 }}
        d="M27.0603 8.48786C27.0865 8.53233 27.1246 8.56933 27.1706 8.59507C27.2166 8.62081 27.2688 8.63437 27.3221 8.63437L31.264 8.63437L30.6031 13.4837C30.596 13.5486 30.6119 13.6139 30.6481 13.6691C30.6844 13.7243 30.7389 13.7662 30.803 13.788C30.867 13.8098 30.9368 13.8103 31.0012 13.7893C31.0655 13.7684 31.1207 13.7273 31.1577 13.6726L35.9515 5.60692C35.9796 5.56313 35.9949 5.51296 35.996 5.46157C35.9971 5.41017 35.984 5.35943 35.9578 5.31456C35.9317 5.26969 35.8936 5.23233 35.8475 5.20632C35.8013 5.1803 35.7487 5.16659 35.6952 5.16658L31.8122 5.16658L32.336 0.309943C32.3411 0.245267 32.3236 0.180794 32.2862 0.126785C32.2488 0.0727757 32.1936 0.0323328 32.1295 0.0118903C32.0654 -0.00855217 31.996 -0.00781886 31.9324 0.0139723C31.8688 0.0357634 31.8146 0.0773614 31.7784 0.132147L27.0639 8.19692C27.0365 8.24078 27.0218 8.29081 27.0212 8.34192C27.0205 8.39303 27.034 8.44339 27.0603 8.48786Z"
        fill="#95D8F5"
      />
      <motion.path
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ delay: 2 }}
        d="M2.88652 46.6414C2.90449 46.6718 2.93054 46.6972 2.96203 46.7148C2.99351 46.7324 3.0293 46.7417 3.06574 46.7417L5.76433 46.7417L5.31191 50.0615C5.30705 50.1059 5.31791 50.1507 5.34273 50.1884C5.36755 50.2262 5.40488 50.2549 5.44873 50.2698C5.49257 50.2847 5.54037 50.2851 5.58443 50.2707C5.62849 50.2564 5.66624 50.2282 5.69162 50.1908L8.97339 44.6691C8.99258 44.6392 9.0031 44.6048 9.00386 44.5696C9.00462 44.5344 8.9956 44.4997 8.97772 44.469C8.95984 44.4383 8.93376 44.4127 8.90215 44.3949C8.87055 44.3771 8.83457 44.3677 8.79793 44.3677L6.13966 44.3677L6.49821 41.0429C6.50173 40.9986 6.48973 40.9545 6.46413 40.9175C6.43852 40.8805 6.40077 40.8528 6.35689 40.8388C6.313 40.8248 6.26551 40.8253 6.22195 40.8403C6.1784 40.8552 6.1413 40.8836 6.11654 40.9212L2.88899 46.4422C2.87028 46.4722 2.86019 46.5065 2.85975 46.5415C2.85932 46.5765 2.86856 46.611 2.88652 46.6414Z"
        fill="#95D8F5"
      />
    </svg>
  );
};
