import { Flex } from 'shared/ui';
import { Container, MenuButton, Item } from './styles';
import { TrashIcon, PencilIcon, LockUnlockedIcon, LockIcon } from 'assets/icons/icons';
import { IRewardMinimal } from 'store';
import { MenuWrap } from '../../styles';
import clsx from 'clsx';
import { useRef } from 'react';
import { CourseCardRewardsTooltip } from 'shared/ui/course-card/CourseCard';

type Props = {
  onDelete: () => void;
  onIsInEditModeChange: () => void;
  rewards: IRewardMinimal[];
  onLock: () => void;
  isLocked: boolean;
  isShown: boolean;
  isAbsolutePos?: boolean;
};

export const Menu = ({ onIsInEditModeChange, onDelete, rewards, onLock, isLocked, isShown, isAbsolutePos }: Props) => {
  const ref = useRef<HTMLButtonElement | null>(null);
  const handleEdit = (e: React.MouseEvent) => {
    e.stopPropagation();
    onIsInEditModeChange();
  };

  const handleDelete = (e: React.MouseEvent) => {
    e.stopPropagation();
    onDelete();
  };
  const handleLock = (e: React.MouseEvent) => {
    e.stopPropagation();
    onLock();
  };

  return (
    <MenuWrap className={clsx({ absolute: isAbsolutePos })} initial={{ opacity: 0 }} animate={{ opacity: Number(isShown) }}>
      <Container>
        <MenuButton onClick={handleEdit}>
          <Flex $alignItems="center" $justifyContent="center">
            <PencilIcon />
          </Flex>
        </MenuButton>
        <MenuButton onClick={handleLock}>
          <Flex $alignItems="center" $justifyContent="center">
            {isLocked ? <LockUnlockedIcon /> : <LockIcon />}
          </Flex>
        </MenuButton>

        <MenuButton onClick={handleDelete}>
          <Flex $alignItems="center" $justifyContent="center">
            <TrashIcon />
          </Flex>
        </MenuButton>
        {!!rewards.length && (
          <MenuButton ref={ref}>
            <Flex $alignItems="center" $justifyContent="center">
              <Item
                withBackground={false}
                amount={rewards[0]?.quantity || 0}
                itemId={rewards[0]?.id || 0}
                isAmountShown={false}
                isCircle
                isSmall
                isShowTooltip={false}
              />
            </Flex>
            <CourseCardRewardsTooltip triggerRef={ref} rewards={rewards} />
          </MenuButton>
        )}
      </Container>
    </MenuWrap>
  );
};
