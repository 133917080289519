import styled, { css } from 'styled-components';

export const Text = styled.div(
  () => css`
    & svg {
      vertical-align: middle;
      color: var(--grade-dark-color-uncommon);
    }
  `
);
