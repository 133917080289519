import { PreviewContext } from './utils/previewContext';

import Preview from './Preview';

import './styles/material-page.scss';
import './styles/lesson-content.scss';
import LessonPageWrapper from './LessonPageWrapper';

const MaterialPage = ({ courseIdProp = '', chapterIdProp = '', lessonIdProp = '', slideIndex = '', slideId = '', onClose = () => {} }) => {
  const isPreview = courseIdProp && chapterIdProp && lessonIdProp && Boolean(slideIndex);

  return (
    <PreviewContext.Provider value={isPreview}>
      {isPreview ? (
        <Preview
          courseId={courseIdProp}
          chapterId={chapterIdProp}
          lessonId={lessonIdProp}
          slideIndex={slideIndex}
          slideId={slideId}
          onClose={onClose}
        />
      ) : (
        <LessonPageWrapper />
      )}
    </PreviewContext.Provider>
  );
};

export default MaterialPage;
