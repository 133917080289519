import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { BUTTON_STATES, IDailyReward } from '../types';
import { useGetMultipliedExp } from 'hooks';
import {
  getItems,
  getUserData,
  useDailyRewardUserMutation,
  useLazyGetDailyRewardsQuery,
  useLazyGetIfUserRewardableQuery,
  useLazyRefetchExpAfterDailyRewardingQuery,
} from 'store';
import { getItemByKey } from 'utils';
import { useEffect, useReducer } from 'react';
import Answer from 'components/library/messages/Answer';

interface IState {
  dailis: IDailyReward[];
  isLoading: boolean;
  buttonState: BUTTON_STATES;
  isRewardable: boolean;
  streak: number;
}

const defaultState: IState = {
  dailis: [],
  isLoading: false,
  buttonState: BUTTON_STATES.CONTINUE,
  isRewardable: false,
  streak: 0,
};

export const useDailyRewards = () => {
  const { dailis: userDailis = [] } = useSelector(getUserData, shallowEqual);
  const { getMultipliedExp } = useGetMultipliedExp();
  const items = useSelector(getItems);
  const [dailyRewardUser] = useDailyRewardUserMutation();
  const [getDailies] = useLazyGetDailyRewardsQuery();
  const [getIfRewardable] = useLazyGetIfUserRewardableQuery();
  const [refetchUserInventory] = useLazyRefetchExpAfterDailyRewardingQuery();
  const [state, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), defaultState);

  useEffect(() => {
    (async () => {
      try {
        const { isRewardable, streak } = await getIfRewardable().unwrap();
        await getDailies(userDailis.length)
          .unwrap()
          .then((dailyRewards) => {
            if (dailyRewards) {
              const dailis = dailyRewards.rewards.map(({ ...rew }) => {
                rew.quantity = getMultipliedExp(rew.id, rew.quantity);
                const item = getItemByKey('id', rew.id, items);
                const reward: IDailyReward = { ...item, ...rew };
                return reward;
              });
              updateState({ streak, buttonState: isRewardable ? BUTTON_STATES.COLLECT : BUTTON_STATES.CONTINUE, dailis, isRewardable });
            }
          });
      } catch (er) {
        console.log(er);
      }
    })();
  }, [getIfRewardable, getDailies, items]);

  async function rewardUser() {
    updateState({ isLoading: true });

    await dailyRewardUser()
      .unwrap()
      .then(() =>
        getIfRewardable()
          .unwrap()
          .then((res) => updateState({ ...res, buttonState: BUTTON_STATES.CONTINUE }))
      )
      .then(() =>
        setTimeout(() => {
          refetchUserInventory();
        }, 1000)
      )
      .catch((error) => {
        if (error.status !== 400) {
          toast.error(<Answer type="incorrect" label={error?.error?.message ?? 'Oops'} />, {
            type: 'error',
            closeOnClick: true,
            autoClose: 10000,
          });
        }
      })
      .finally(() => updateState({ isLoading: false }));
  }

  return { userDailis, rewardUser, ...state };
};
