import { RefObject, useEffect, useState } from 'react';
import { getScrollbarWidth } from '../../../utils/getScrollbarWidth';
import { useDisplay } from '../../../hooks';
import { useMNClickOutside } from '../../../hooks/useMNClickOutside';

interface IUseTooltip {
  isMovable?: boolean;
  triggerRef: RefObject<any>;
  tooltipRef: RefObject<any>;
  position?: '' | 'cool';
  margin: number;
  clickable: boolean;
  isRight: boolean;
}

export const useTooltip = ({ tooltipRef, triggerRef, position = '', isMovable = false, margin, clickable, isRight }: IUseTooltip) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const { isMobile } = useDisplay();

  useMNClickOutside(() => setShowTooltip(false), triggerRef, tooltipRef);

  useEffect(() => {
    const { current: trigger } = triggerRef;

    if (!trigger) return;

    const showTT = () => {
      setShowTooltip(true);
    };
    const trackTT = (e: MouseEvent) => {
      setMousePosition({ x: e.offsetX, y: e.offsetY });
    };
    const hideTT = (e: MouseEvent) => {
      setShowTooltip(false);
    };

    if (!isMobile) {
      trigger.addEventListener('mouseenter', showTT);
      if (!clickable) {
        trigger.addEventListener('mouseleave', hideTT);
      }
      trigger.addEventListener('mousemove', trackTT);
      trigger.addEventListener('click', showTT);
      window.addEventListener('scroll', getCurrentPosition);
    }

    return () => {
      if (!isMobile) {
        trigger.removeEventListener('mouseenter', showTT);
        trigger.removeEventListener('mouseleave', hideTT);
        trigger.removeEventListener('mousemove', trackTT);
        trigger.removeEventListener('click', showTT);
        window.removeEventListener('scroll', getCurrentPosition);
      }
    };
  }, [isMobile, triggerRef]);

  useEffect(() => {
    if (showTooltip) {
      placeTooltip();
      if (clickable) {
        tooltipRef.current.addEventListener('mouseleave', () => setShowTooltip(false));
        window.addEventListener('scroll', placeTooltip);
      }
    }
  }, [showTooltip]);

  useEffect(() => {
    if (showTooltip && isMovable) handleMovableTooltip();
  }, [mousePosition, showTooltip, isMovable]);

  function handleMovableTooltip() {
    const { current: tooltip } = tooltipRef;
    const { current: trigger } = triggerRef;
    const { height: triggerHeight, width: triggerWidth } = trigger.getBoundingClientRect();

    const { top, left, isRightSpaced, isTopSpaced } = getCurrentPosition();

    if (isTopSpaced) {
      tooltip.style.top = `${top + mousePosition.y}px`;
    } else {
      tooltip.style.top = `${top - triggerHeight + mousePosition.y}px`;
    }
    if (isRightSpaced) {
      tooltip.style.left = `${left - triggerWidth + mousePosition.x - 10}px`;
    } else {
      tooltip.style.left = `${left + mousePosition.x + 10}px`;
    }
  }

  function placeTooltip() {
    const { current: tooltip } = tooltipRef;
    if (!tooltip) return;

    tooltip.style.visibility = 'visible';
    if (isMovable) {
      handleMovableTooltip();
    } else {
      const { top, left } = getCurrentPosition();
      tooltip.style.top = `${top}px`;
      tooltip.style.left = `${left}px`;
    }
  }

  function getCurrentPosition(): { top: number; left: number; isTopSpaced: boolean; isRightSpaced: boolean } {
    const { current: tooltip } = tooltipRef;
    const { current: trigger } = triggerRef;
    let top = 0,
      left = 0,
      isTopSpaced = false,
      isRightSpaced = false;

    if (!tooltip || !trigger) return { top, left, isTopSpaced, isRightSpaced };

    const body = document.body;
    const html = document.documentElement;
    const docHeight = html.clientHeight;
    const docWidth = Math.max(body.scrollWidth, body.offsetWidth, html.clientWidth, html.scrollWidth, html.offsetWidth);
    const { height: tooltipHeight, width: tooltipWidth } = tooltip.getBoundingClientRect();
    const { height: triggerHeight, width: triggerWidth, top: triggerTop, left: triggerLeft } = trigger.getBoundingClientRect();
    const scrollbarWidth = getScrollbarWidth();

    if (position === 'cool') {
      top = triggerTop - triggerHeight + 70;
      left = triggerLeft + triggerWidth - 60;
    } else {
      top = triggerTop + triggerHeight + margin;
      if (triggerTop + triggerHeight + tooltipHeight + margin > docHeight) {
        isTopSpaced = true;
        top = triggerTop - tooltipHeight - margin;
      }

      if (isRight) {
        left = triggerLeft - (tooltipWidth - triggerWidth);
      } else {
        left = triggerLeft;
        if (triggerLeft + tooltipWidth + scrollbarWidth > docWidth) {
          left = triggerLeft - (tooltipWidth - triggerWidth);
          isRightSpaced = true;
        }
      }
    }

    return { top, left, isTopSpaced, isRightSpaced };
  }

  return showTooltip;
};
