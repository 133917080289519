import styled, { keyframes } from 'styled-components';
import { ReactComponent as TokenSvg } from '../../assets/usdc.svg';

export const Container = styled.div<{ $size: number }>`
  width: ${(p) => p.$size}px;
  height: ${(p) => p.$size}px;
  /* margin-top: -100px; */
  /* margin-bottom: -10px; */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  pointer-events: none;
  @media screen and (max-width: 992px) {
  }
`;
const rotate = keyframes`
    0% {
        transform: scale(1) translate(-50%, -50%) ;
    }
    25% {
        transform: scale(0.8) translate(-50%, -50%) ;
    }
    50% {
        transform: scale(1.1) translate(-50%, -50%) ;
    }
    75% {
        transform: scale(0.7) translate(-50%, -50%) ;
    }
    100% {
        transform: scale(1) translate(-50%, -50%) ;
    }
`;
export const ChestImage = styled.img`
  width: 100%;
  height: 100%;
`;
export const Glow1 = styled.div<{ $noAnim?: boolean }>`
  z-index: -1;
  background: #7fd337;
  filter: blur(10px);
  mix-blend-mode: hard-light;
  opacity: 0.5;
  box-shadow: 0px 0px 1482px #7fd337, 0px 0px 846.858px #7fd337, 0px 0px 494.001px #7fd337, 0px 0px 247px #7fd337, 0px 0px 70.5715px #7fd337,
    0px 0px 35.2858px #7fd337;
  width: 40%;
  height: 32%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  animation: ${rotate} 20s infinite;
  ${(p) => p.$noAnim && 'animation: none; scale: 0.5;'}
`;

export const Glow2 = styled.div`
  z-index: -1;
  width: 19%;
  height: 25%;
  background: #7fd337;
  filter: blur(20px);
  mix-blend-mode: hard-light;
  box-shadow: 0px 0px 184.078px #7fd337;
  position: absolute;
  border-radius: 50%;
  top: 55%;
  left: 55%;
  transform: translate(-50%, -50%);
  animation: ${rotate} 15s infinite reverse;
`;

export const Token = styled(TokenSvg)`
  position: absolute;
`;

export const Token1 = styled(Token)`
  z-index: -1;
  rotate: 15deg;
  width: 12.5% !important;
  height: 12.5% !important;
  top: 19%;
  left: 44%;
`;

export const Token2 = styled(Token)`
  width: 15.5% !important;
  height: 15.5% !important;
  rotate: 33deg;
  bottom: 8%;
  right: 24%;
`;

export const Token3 = styled(Token)`
  width: 10% !important;
  height: 10% !important;
  top: 43%;
  left: 8.5%;
  rotate: -20deg;
`;
