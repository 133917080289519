import { AnimateWrapper } from 'features/auth/ui';
import styles from './styles.module.css';
import { NavLink } from 'react-router-dom';
import { Modal } from 'shared/ui';
import { ISendCodeForm } from 'interface';
import { ROUTES } from 'app/constants';
import { SendCodeForm } from './sendCodeForm/sendCodeForm';
import { useTranslation } from 'react-i18next';
import { useDisplay } from 'hooks';
import { ReactComponent as ArrowIcon } from '../assets/arrow-left.svg';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

interface IProps {
  open: boolean;
  isLoading: boolean;
  onSubmitHandler: (data: ISendCodeForm) => void;
  onCloseModalHandler: () => void;
  openWalletModal: () => void;
  error: string;
}

export const SendCodeModal = ({ open, isLoading, error, openWalletModal, onSubmitHandler, onCloseModalHandler }: IProps) => {
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);
  const { t } = useTranslation('auth');

  return (
    <Modal
      closeButtonVariant={isMobile ? 'none' : undefined}
      className={`${styles.modal} ${styles[theme]}`}
      open={open}
      onClose={onCloseModalHandler}
    >
      <AnimateWrapper>
        <div className={styles.container}>
          <div className={styles.title}>
            <ArrowIcon className={styles.arrow} onClick={onCloseModalHandler} /> {t('resetPassword.sendCode.title')}
          </div>
          <div className={styles.subTitle}>{t('resetPassword.sendCode.subTitle')}</div>
          <SendCodeForm error={error} onSubmitHandler={onSubmitHandler} isLoading={isLoading} />
          {/* <div className={styles.subText}>{t('or')}</div>
          <Button onClick={openWalletModal} className={styles.walletButton} icon={WalletIcon} variant={'bordered'}>
            {t('resetPassword.sendCode.wallet')}
          </Button> */}
          <NavLink className={styles.sigUpLink} to={ROUTES.SIGN_UP}>
            {t('resetPassword.sendCode.signUp')}
          </NavLink>
        </div>
      </AnimateWrapper>
    </Modal>
  );
};
