import step1 from '../../assets/instructions/step-1.png';
import step2 from '../../assets/instructions/step-2.png';
import step3 from '../../assets/instructions/step-3.png';
import step4 from '../../assets/instructions/step-4.png';

export const data = [
  {
    image: step1,
    text: (
      <>
        Install the Steam application. You can download it{' '}
        <a href={'https://store.steampowered.com/about/'} target={'_blank'}>
          here
        </a>
        .
      </>
    ),
    path: {
      width: '235',
      height: '56',
      d: 'M208.814 1.93075C-48.5272 -0.0287735 -44.5734 54.1682 107.867 54.1682C278.103 54.1682 289.198 -2.39031 45.2432 1.93089',
      mobile: {
        width: '138',
        height: '35',
        d: 'M121.972 2.57373C-26.9387 1.43986 -24.6508 32.8009 63.5587 32.8009C162.066 32.8009 168.486 0.0733548 27.3215 2.57382',
      },
    },
  },
  {
    image: step2,
    text: (
      <>
        After you download and launch the app, sign in with your Steam login details, and select <span>View my wallet</span> from the drop-down menu
        as shown in the picture below.
      </>
    ),
    path: {
      width: '89',
      height: '24',
      d: 'M78.1299 2.61798C-16.6665 1.89615 -15.21 21.8606 40.9442 21.8606C103.654 21.8606 107.741 1.02623 17.8756 2.61803',
    },
  },
  {
    image: step3,
    text: (
      <>
        After you are taken to the Wallet page, select <span>Redeem a Steam Gift Card or Wallet Code</span>.
      </>
    ),
    path: {
      width: '143',
      height: '36',
      d: 'M126.71 1.89212C-28.6318 0.709271 -26.2451 33.4248 65.7741 33.4248C168.535 33.4248 175.233 -0.71625 27.9719 1.8922',
    },
  },
  {
    image: step4,
    text: (
      <>
        Copy the activation key and paste it in the Steam Wallet Code field, then click <span>Continue</span>.
      </>
    ),
    path: {
      width: '245',
      height: '60',
      d: 'M217.921 2.61387C-51.7198 0.560695 -47.5771 57.3481 112.149 57.3481C290.522 57.3481 302.147 -1.91372 46.5324 2.61402',
    },
  },
];
