import * as S from './style';
import { ReactComponent as LinkIcon } from 'assets/icons/link-icon.svg';
import { ILinkAuthMethod } from './types';
import { FaChevronRight } from 'react-icons/fa';
import { useGetCurrentTheme, useGetUserData } from 'hooks';

interface Props extends ILinkAuthMethod {
  selectForLinking: () => void;
  selectForUnlinking: () => void;
}

const UserLink = ({ color, icon, method, title, inactiveColor, selectForLinking, selectForUnlinking }: Props) => {
  const theme = useGetCurrentTheme();
  const { authMethods } = useGetUserData();
  const isActive = !!authMethods.find((el) => el.method === method && !!el.token);
  // TODO: add error handling
  const isError = false;

  const handleClick = () => {
    if (!isActive) {
      selectForLinking();
    } else {
      selectForUnlinking();
    }
  };

  return (
    <S.LinkContainer onClick={handleClick} $color={isActive ? color : inactiveColor} $theme={theme} $isActive={isActive}>
      <S.IconContainer>{icon}</S.IconContainer>
      <S.LinkTitle>{title}</S.LinkTitle>
      {!isActive && <S.LinkSub>Link</S.LinkSub>}
      {isError && <S.LinkSub>Error while Linking</S.LinkSub>}
      {isError || !isActive ? <FaChevronRight /> : <LinkIcon style={{ marginLeft: isError ? '0' : 'auto' }} />}
    </S.LinkContainer>
  );
};

export default UserLink;
