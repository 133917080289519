import { EActivateItemType, TActivateItemType } from '../types/types';
import { Energy, EnergyRecovery, ExpReceived } from '../components';
import { EItemFungibleType } from 'interface';

import { NoEffect } from '../components/Activation/NoEffect';

export const components: Record<TActivateItemType, any> = {
  [EActivateItemType.NOEFFECT]: NoEffect,
  [EActivateItemType.ENERGY]: Energy,
  [EActivateItemType.ENERGYRECOVERY]: EnergyRecovery,
  [EActivateItemType.EXPRECEIVED]: ExpReceived,
};

export const effectTypes = {
  [EActivateItemType.NOEFFECT]: [
    EItemFungibleType.EnergyShard,
    EItemFungibleType.LifeShard,
    EItemFungibleType.LuckShard,
    EItemFungibleType.UnknownLiquid,
    EItemFungibleType.ExperienceEssenceShard,
  ],
  [EActivateItemType.ENERGYRECOVERY]: [EItemFungibleType.SmallEnergyStone, EItemFungibleType.LargeEnergyStone],
  [EActivateItemType.ENERGY]: [EItemFungibleType.SmallEnergyPotion, EItemFungibleType.LargeEnergyPotion],
  [EActivateItemType.EXPRECEIVED]: [
    EItemFungibleType.ExpPotion,
    EItemFungibleType.EnhancedExpPotion,
    EItemFungibleType.Amethyst,
    EItemFungibleType.YellowDiamond,
  ],
};
