import { useDisplay } from 'hooks';
import * as S from './styles';

export const SpaceShimmerLoader = () => {
  return (
    <>
      <S.TitleLoader />
      <S.SpacesSkeletonBox>
        <S.SpaceCardSkeleton className="small" />
        <S.SpaceCardSkeleton className="small" />
        <S.SpaceCardSkeleton className="small" />
        <S.SpaceCardSkeleton className="small" />
        <S.SpaceCardSkeleton className="small" />
        <S.SpaceCardSkeleton className="small" />
      </S.SpacesSkeletonBox>
    </>
  );
};

export const SpaceSliderLoader = () => {
  const { isMobile } = useDisplay();
  return (
    <>
      {isMobile ? (
        <S.MobileSliderBox>
          <S.MobileSlideSkeleton />
          <S.MobileSlideSkeleton />
          <S.MobileSlideSkeleton />
        </S.MobileSliderBox>
      ) : (
        <S.SliderSkeletonBox>
          <S.SpaceCardSkeleton />
          <S.SpaceCardSkeleton />
        </S.SliderSkeletonBox>
      )}
    </>
  );
};

export const QuestSliderLoader = () => {
  return (
    <>
      <S.TitleLoader />
      <S.QuestSliderSkeleton>
        <S.QuestSkeleton />
        <S.QuestSkeleton />
        <S.QuestSkeleton />
        <S.QuestSkeleton />
        <S.QuestSkeleton />
        <S.QuestSkeleton />
        <S.QuestSkeleton />
        <S.QuestSkeleton />
        <S.QuestSkeleton />
      </S.QuestSliderSkeleton>
    </>
  );
};

export const SpaceCoursesLoader = () => {
  const { isMobile } = useDisplay();
  return (
    <>
      {!isMobile && <S.TitleLoader />}
      <S.CourseLoaderBox>
        <S.CourseCardLoader />
        <S.CourseCardLoader />
        <S.CourseCardLoader />
        <S.CourseCardLoader />
        <S.CourseCardLoader />
        <S.CourseCardLoader />
        <S.CourseCardLoader />
        <S.CourseCardLoader />
      </S.CourseLoaderBox>
    </>
  );
};
