import styled, { css } from 'styled-components';

export const Container = styled.a(
  () => css`
    height: 44px;
    width: 44px;
    border-radius: 50%;
    color: var(--font-color);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0.04);
    backdrop-filter: blur(37px);
    padding: 10px;
    transition: background-color 0.15s ease-in-out;
    /* border: 1px solid var(--basic-border-color); */
    border: none;

    &:hover {
      background-color: rgba(255, 255, 255, 0.1);
    }

    &.light {
      color: var(--mighty-blue);
    }
  `
);
