import { GRADES } from 'app/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      cursor: pointer;
      border-radius: 5px;
      transition: all 0.15s ease-in-out;

      &:hover {
        background-color: rgba(255, 255, 255, 0.03);
        padding: 5px 0;
      }
    `
  ),
  Widget = styled.div(
    () => css`
      height: 50px;
      width: 50px;
      margin-right: 14px;
      flex-shrink: 0;
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
    `
  ),
  Header = styled.div(
    () => css`
      margin-bottom: 6px;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.5px;

      &.${GRADES.LEGENDARY} {
        background: linear-gradient(180deg, #fadd9d 0%, #d48f52 100%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
      &.${GRADES.UNCOMMON} {
        color: var(--grade-dark-color-${GRADES.UNCOMMON});
      }
      &.${GRADES.EPIC} {
        color: var(--grade-dark-color-${GRADES.EPIC});
      }
      &.${GRADES.RARE} {
        color: var(--grade-dark-color-${GRADES.RARE});
      }
    `
  );
