import { FC, useState } from 'react';
import { FaArrowLeft } from 'react-icons/fa6';
import { BsChevronLeft } from 'react-icons/bs';
import { createPortal } from 'react-dom';
import { useActivateItem, useDisplay } from 'hooks';

import { ItemDesc } from '../../components';
import BasicButton from 'shared/ui/basic-button/button';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import { Instructions } from './Instructions/Instructions';
import { TooltipSmall } from 'shared/ui/tooltip';
import Answer from 'components/library/messages/Answer';

import { IShopItem } from 'interface';

import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as SteamIcon } from 'assets/icons/socials/steam--icon.svg';
import { ReactComponent as Check } from 'assets/icons/checked.svg';

import styles from './styles.module.scss';
import { toast } from 'react-toastify';
import { ActivatedCodes } from '../../../../../features/activateCodes';

interface IActivateCodeProps {
  item: IShopItem;
  handleClose: () => void;
}

export const ActivateCode: FC<IActivateCodeProps> = ({ item, handleClose }) => {
  const { activate } = useActivateItem();
  const [isActivate, setIsActivate] = useState(false);
  const [code, setCode] = useState<string | null>(null);
  const [isCopied, setIsCopied] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { isMobile } = useDisplay();

  const {
    item: { imageThumb, name, grade },
  } = item;

  const handleClickBackButton = () => {
    handleClose();
  };

  const handleClickButton = async () => {
    if (isActivate) {
      if (code) {
        navigator.clipboard?.writeText(code);
      }
      setIsCopied(true);
    } else {
      setIsLoading(true);
      await activate(item.item.id)
        .then((result) => {
          if (result.result.digital) {
            setCode(result.result.digital.code);
            setIsActivate(true);
          }
        })
        .catch((ex) => {
          console.log(ex);
          if (ex?.data?.message?.message === 'Cant find available digital for current item. Try again later.') {
            toast.error(<Answer type="incorrect" label={ex?.data?.message?.message} />, { autoClose: 4000 });
          } else {
            toast.error(<Answer type="incorrect" label={'Something went wrong...'} />, { autoClose: 4000 });
          }
        })
        .finally(() => setIsLoading(false));
    }
  };

  return (
    <>
      {isMobile &&
        createPortal(
          <div className={styles.back} onClick={handleClickBackButton}>
            <BsChevronLeft strokeWidth={'1px'} />
          </div>,
          document.getElementById('root')!
        )}
      <ItemDesc
        image={imageThumb}
        name={name}
        grade={grade}
        userBalance={item.userBalance ?? 1}
        type={'partner'}
        quantity={item.quantity}
        isActivated={isActivate}
      />
      <div className={styles.activateCode}>
        {isActivate ? (
          <>
            <div className={styles.copyWrap}>
              <p>Copy this code and activate in Steam</p>
              <TooltipSmall text={'Redeem in Steam'} direction={'bottom'}>
                <SteamIcon />
              </TooltipSmall>
            </div>
            <div className={styles.code}>
              <Check /> Item is activated
            </div>
          </>
        ) : (
          <div className={styles.codeWrap}>
            <span>Activate to get the Steam code</span>
          </div>
        )}

        <div className={styles.buttonsWrap}>
          {!isMobile && !isActivate && (
            <BasicButton
              label={'Back'}
              icon={<FaArrowLeft width={'16px'} height={'17px'} />}
              onClick={handleClickBackButton}
              className={styles.button}
              style={{ background: 'rgba(255, 255, 255, 0.10)', color: 'var(--font-color-strong)' }}
            />
          )}
          {isActivate ? (
            <ActivatedCodes variant={'mighty-basic'} />
          ) : (
            <BasicButton
              label={isActivate ? 'Copy' : 'Activate'}
              variant={'cool'}
              icon={isActivate ? <CopyIcon /> : ''}
              onClick={handleClickButton}
              className={styles.button}
              isDisabled={isLoading}
            />
          )}
        </div>
        {isActivate && (
          <span
            className={styles.helpText}
            style={isCopied ? { textDecoration: 'none' } : { cursor: 'pointer' }}
            onClick={() => !isCopied && setShowInstructions(true)}
          >
            {isCopied ? 'Code copied to clipboard' : 'Help'}
          </span>
        )}
      </div>
      <CenteredModal
        show={showInstructions}
        onClose={() => setShowInstructions(false)}
        closeButtonPosition={{ top: '-52px' }}
        isClosing={!isMobile}
        backgroundColor={isMobile ? 'rgba(255, 255, 255, 0.05)' : 'rgba(28, 29, 38, 0.50)'}
        blur={isMobile ? 30 : 5}
        portal={isMobile ? 'root' : 'portal'}
        zIndex={isMobile ? [1002, 1002] : [1000, 900]}
        padding={'unset'}
      >
        <Instructions onClose={() => setShowInstructions(false)} />
      </CenteredModal>
    </>
  );
};
