import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    display: flex;
    flex-wrap: wrap;
    gap: 6px;
    margin-bottom: 24px;
  `
);
