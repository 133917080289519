import { FC } from 'react';
import { motion } from 'framer-motion';

interface IEnergyAnimationProps {
  isEnd: boolean;
}

export const EnergyAnimation: FC<IEnergyAnimationProps> = ({ isEnd }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="42" height="63" viewBox="0 0 42 63" fill="none">
      <mask id="mask0_20484_27708" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="1" y="1" width="40" height="61">
        <path
          d="M1.56582 38.7853C1.68041 38.9795 1.84657 39.141 2.04738 39.2534C2.24819 39.3658 2.47645 39.425 2.70887 39.425L19.9203 39.425L17.0347 60.5985C17.0038 60.8819 17.073 61.167 17.2313 61.408C17.3896 61.649 17.6277 61.8318 17.9074 61.9271C18.187 62.0223 18.4919 62.0243 18.7729 61.9329C19.0539 61.8415 19.2947 61.6619 19.4565 61.4231L40.3874 26.2063C40.5098 26.0151 40.5769 25.7961 40.5818 25.5717C40.5866 25.3473 40.5291 25.1257 40.415 24.9298C40.301 24.7339 40.1346 24.5708 39.9331 24.4572C39.7315 24.3436 39.502 24.2837 39.2683 24.2837L22.3141 24.2837L24.6009 3.07832C24.6233 2.79592 24.5468 2.51442 24.3835 2.2786C24.2202 2.04278 23.9794 1.8662 23.6995 1.77694C23.4197 1.68769 23.1167 1.69089 22.839 1.78603C22.5612 1.88118 22.3245 2.06281 22.1666 2.30202L1.58159 37.515C1.46222 37.7065 1.39787 37.9249 1.3951 38.1481C1.39233 38.3713 1.45124 38.5911 1.56582 38.7853Z"
          fill="#95D8F5"
        />
      </mask>
      <g mask="url(#mask0_20484_27708)">
        <motion.path
          animate={{
            d: [
              'M32.3948 63.2505C40.6585 63.7716 46.3398 58.0391 46.3398 58.0391V91.3913H-0.660156V60.1236C-0.660156 60.1236 5.12556 58.3914 11.8398 58.3914C23.3398 58.3914 22.0852 62.6004 32.3948 63.2505Z',
              'M32.0549 7.15657C40.3187 8.17219 46 -3 46 -3V62H-1V1.0625C-1 1.0625 4.78571 -2.31333 11.5 -2.31333C23 -2.31333 21.7454 5.88951 32.0549 7.15657Z',
            ],
          }}
          transition={{ duration: 2, delay: 0.3 }}
          fill="#527F7F"
        />
        <motion.path
          animate={{
            d: [
              'M11.2849 62.125C3.02116 62.6461 -2.66016 58.0608 -2.66016 58.0608V91.4131H44.3398V60.1453C43.9955 57.7134 38.3666 55.625 31.6523 55.625C23.9051 55.625 21.5945 61.4749 11.2849 62.125Z',
              'M10.9451 8.8056C2.68132 9.7521 -3 1.42406 -3 1.42406V62H44V5.21006C43.6557 0.793061 38.0268 -3 31.3125 -3C23.5652 -3 21.2546 7.62478 10.9451 8.8056Z',
            ],
          }}
          transition={{ duration: 2, delay: 0.3 }}
          fill="#B8DA76"
        />
      </g>
      <path
        d="M1.56582 38.7853C1.68041 38.9795 1.84657 39.141 2.04738 39.2534C2.24819 39.3658 2.47645 39.425 2.70887 39.425L19.9203 39.425L17.0347 60.5985C17.0038 60.8819 17.073 61.167 17.2313 61.408C17.3896 61.649 17.6277 61.8318 17.9074 61.9271C18.187 62.0223 18.4919 62.0243 18.7729 61.9329C19.0539 61.8415 19.2947 61.6619 19.4565 61.4231L40.3874 26.2063C40.5098 26.0151 40.5769 25.7961 40.5818 25.5717C40.5866 25.3473 40.5291 25.1257 40.415 24.9298C40.301 24.7339 40.1346 24.5708 39.9331 24.4572C39.7315 24.3436 39.502 24.2837 39.2683 24.2837L22.3141 24.2837L24.6009 3.07832C24.6233 2.79593 24.5468 2.51442 24.3835 2.2786C24.2202 2.04278 23.9794 1.8662 23.6995 1.77694C23.4197 1.68768 23.1167 1.69089 22.839 1.78603C22.5612 1.88118 22.3245 2.06281 22.1666 2.30202L1.58159 37.515C1.46222 37.7065 1.39787 37.9249 1.3951 38.1481C1.39233 38.3713 1.45124 38.5911 1.56582 38.7853Z"
        stroke={isEnd ? '#B8DA76' : '#E0E0E0'}
        strokeWidth="2"
      />
    </svg>
  );
};
