import clsx from 'clsx';
import { GRADES } from 'app/constants';

import { ReactComponent as Common } from 'assets/inventoryGradeIcons/common.svg';
import { ReactComponent as Uncommon } from 'assets/inventoryGradeIcons/uncommon.svg';
import { ReactComponent as Rare } from 'assets/inventoryGradeIcons/rare.svg';
import { ReactComponent as Epic } from 'assets/inventoryGradeIcons/epic.svg';
import { ReactComponent as Legendary } from 'assets/inventoryGradeIcons/legendary.svg';
import { ReactComponent as Mythical } from 'assets/inventoryGradeIcons/mythical .svg';

import styles from './styles.module.css';

interface IProps {
  label: GRADES;
}
export const InventoryGradeInfo = ({ label }: IProps) => {
  const icons = {
    [GRADES.COMMON]: <Common className={styles.icon} />,
    [GRADES.UNCOMMON]: <Uncommon className={styles.icon} />,
    [GRADES.RARE]: <Rare className={styles.icon} />,
    [GRADES.EPIC]: <Epic className={styles.icon} />,
    [GRADES.LEGENDARY]: <Legendary className={styles.icon} />,
    [GRADES.MYTHICAL]: <Mythical className={styles.icon} />,
    [GRADES.DIVINE]: <></>,
  };

  const cnContainer = clsx(styles.text, styles[label]);

  return (
    <div className={styles.container}>
      {icons[label]}
      <span className={cnContainer}>{label}</span>
    </div>
  );
};
