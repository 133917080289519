import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import bg from 'assets/backgrounds/main--backgrounds/spaces/space-creation-bg.webp';

export const Wrapper = styled.div(
    () => css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      display: flex;
      align-items: center;
      flex-direction: column;
      background-image: url(${bg});
      background-repeat: no-repeat;
      background-size: cover;
      @media screen and (max-width: 992px) {
        overflow: hidden scroll;
        &::-webkit-scrollbar {
          display: none;
        }

        padding-bottom: 90px;
      }
    `
  ),
  Container = styled(motion.div)(
    () => css`
      z-index: 1001;
      width: 100%;
      height: fit-content;
      /* position: absolute; */
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 80px;
      @media screen and (max-width: 992px) {
        padding: 0;
      }
    `
  ),
  Header = styled.h1(
    () => css`
      font-size: 48px;
      font-weight: 500;
      line-height: 114%;
      text-align: center;
      color: #fff;
      margin-bottom: 46px;
      @media screen and (max-width: 992px) {
        font-size: 36px;
        margin-bottom: 23px;
        max-width: 273px;
        text-align: center;
      }
    `
  ),
  Groups = styled.div(
    () => css`
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      margin-bottom: auto;
      @media screen and (max-width: 992px) {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0px 15px;
        overflow: hidden auto;
        /* max-height: 40vh; */
      }
    `
  ),
  GroupCard = styled.div(
    () => css`
      width: 245px;
      height: 68px;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    `
  ),
  BackButton = styled.button(
    () => css`
      position: absolute;
      top: 40px;
      left: 24px;
    `
  ),
  ContinueBtn = styled.button(
    () => css`
      width: fit-content !important;
      height: 56px !important;
      min-height: 56px !important;
      border-radius: 40px !important;
      margin-top: min(40px, 5vh);
      padding-left: 40px !important;
      padding-right: 40px !important;
      font-size: 20px !important;
      font-weight: 500 !important;
      line-height: 20px !important;
      @media screen and (max-width: 992px) {
        margin-top: 40px;
      }
    `
  ),
  Figure = styled.img(
    () => css`
      margin-bottom: 87px;
    `
  ),
  PageBackground = styled.div(
    () => css`
      background-size: cover;
      height: 100% !important;
      display: none;
    `
  );
