import { ReactNode } from 'react';

import Settings from '../../../menu/screens/Settings';
import Design from '../../../menu/screens/Design/Design';
import InventoryScreen from '../../../menu/screens/Inventory/Inventory';
import Profile from '../Profile';
import Spaces from 'components/pages/menu/screens/Spaces';

const screens: Record<string, { name: string; screen: ReactNode }> = {
  Profile: {
    name: 'Profile',
    screen: <Profile />,
  },
  Inventory: {
    name: 'Inventory',
    screen: (
      <div className="mobile-padding">
        <InventoryScreen variant={'mobile'} />
      </div>
    ),
  },
  Settings: {
    name: 'Settings',
    screen: (
      <div className="mobile-padding">
        <Settings variant={'mobile'} />
      </div>
    ),
  },
  Design: {
    name: 'Design',
    screen: (
      <div className="mobile-padding">
        <Design />
      </div>
    ),
  },
  Spaces: {
    name: 'Spaces',
    screen: (
      <div className="mobile-padding">
        <Spaces />
      </div>
    ),
  },
};

export default screens;
