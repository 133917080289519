import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { BsChevronLeft } from 'react-icons/bs';

const variants = {
  open: { y: 0, opacity: 1 },
  close: { y: '100%', opacity: 0 },
};

interface Props {
  children: React.ReactNode;
  isOpen?: boolean;
  title?: string;
  rightIcon?: React.ReactNode;
  onClose?: () => void;
  handleClickIcon?: () => void;
  spacing?: number;
  className?: string;
  haveHead?: boolean;
  zIndex?: number;
}

export const MobileModalMotion: FC<Props> = ({
  children,
  isOpen = false,
  title,
  rightIcon,
  onClose,
  handleClickIcon,
  spacing = 60,
  className = '',
  haveHead = true,
  zIndex = 300,
}) => {
  const [show, setShow] = useState(isOpen);

  useEffect(() => {
    setShow(isOpen);
  }, [isOpen]);

  useEffect(() => {
    const body = document.querySelector<HTMLHtmlElement>('body')!;
    show ? (body.style.overflow = 'hidden') : (body.style.overflow = 'unset');

    return () => {
      body.style.overflow = 'unset';
    };
  }, [show]);

  const theme = useSelector(getCurrentTheme);

  const currentStyle = {
    paddingLeft: spacing === 0 ? 0 : 35,
    paddingRight: spacing === 0 ? 0 : 35,
    paddingTop: spacing,
    width: '100%',
  };

  function handleClose() {
    if (onClose) {
      return onClose();
    }
    return setShow(false);
  }

  return (
    <AnimatePresence initial={false} exitBeforeEnter={true} onExitComplete={() => null}>
      {show && (
        <motion.div
          variants={variants}
          initial={'close'}
          animate={'open'}
          exit={'close'}
          transition={{ duration: 0.3 }}
          className={clsx(styles.modal, theme === 'dark' ? '' : styles.lightTheme)}
          style={{ willChange: 'transform', zIndex }}
        >
          {haveHead && (
            <div className={styles.head}>
              <button className={styles.button} onClick={handleClose}>
                <BsChevronLeft size={18} strokeWidth={1} />
              </button>
              {title && <h4 className={styles.title}>{title}</h4>}
              {rightIcon && (
                <button className={styles.button} onClick={handleClickIcon}>
                  {rightIcon}
                </button>
              )}
            </div>
          )}
          <div style={currentStyle} className={className}>
            {children}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
