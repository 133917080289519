import { persistRootKeyPrefix } from 'app/constants';

export function clearOldPersistedKeys(currentKey: string) {
  const curKey = `persist:${currentKey}`;
  const keysToRemove: string[] = [];
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);
    if (key && key.startsWith(`persist:${persistRootKeyPrefix}`) && key !== curKey) {
      keysToRemove.push(key);
    }
  }

  keysToRemove.forEach((key) => {
    localStorage.removeItem(key);
  });
}
