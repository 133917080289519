import { createContext, useContext } from 'react';
import { ESlideTypes } from 'store';
import { TSlideConvertationType } from '../WidgetsList/types';
import { ISlideFull } from 'interface/courses';

export type TSlideContextState = {
  slide: ISlideFull;
  attachmentAction: {
    add: (props: { slideId: string; widgetId: string; form: FormData }) => Promise<void>;
    delete: (props: { slideId: string; widgetId: string }) => Promise<void>;
    convert: (to: TSlideConvertationType, type: ESlideTypes, slideId: string, isGenerated?: boolean, isRandomTestType?: boolean) => Promise<void>;
    toggleAnyCorrect: (slideId: string) => Promise<void> | any;
    toggleIsOptional: (slideId: string) => Promise<void> | any;
  };
  loading: boolean;
};

export const CourseConstructorSlideContext = createContext<TSlideContextState>(null as any);

export const useSlideContext = () => useContext(CourseConstructorSlideContext);
