import { useSelector } from 'react-redux';
import * as S from './styles';
import { getLessonPageVolume, setLessonPageVolume } from 'store';
import { dispatch, useGetCurrentTheme } from 'hooks';
import { useEffect, useState } from 'react';
import { ReactComponent as VolumeFull } from './assets/volume-full.svg';
import { ReactComponent as VolumeMed } from './assets/volume-med.svg';
import { ReactComponent as VolumeOff } from './assets/volume-off.svg';
import { RangeSlider } from 'shared/ui/RangeSlider';
import clsx from 'clsx';

type Props = {
  onChange?: (arg: number) => void;
  closeOn?: boolean;
};

const LessonPageVolumeControl = ({ onChange, closeOn }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useGetCurrentTheme();
  const lpVolume = useSelector(getLessonPageVolume);

  const handleVolumeChange = (volume: number) => {
    dispatch(setLessonPageVolume(volume));
    !!onChange && onChange(volume);
  };

  useEffect(() => {
    if (!!closeOn) {
      setIsOpen(false);
    }
  }, [closeOn]);

  return (
    <S.Container $theme={theme} onClick={() => setIsOpen((s) => !s)}>
      <S.VolumeIcon $theme={theme}>
        {lpVolume > 50 && <VolumeFull />}
        {lpVolume <= 50 && lpVolume > 0 && <VolumeMed />}
        {lpVolume === 0 && <VolumeOff />}
      </S.VolumeIcon>
      <S.SliderContainer onClick={(e) => e.stopPropagation()} className={clsx({ isOpen })}>
        <RangeSlider myValue={lpVolume} handler={handleVolumeChange} knobSize={8} knobColor="#6487EF" />
      </S.SliderContainer>
    </S.Container>
  );
};

export default LessonPageVolumeControl;
