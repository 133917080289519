import { SlideContent } from '../../SlideContent';

// неиспользованные переменные используются в качестве заглушки для ts
const Lesson = ({ slide, testsIds, isCompleted, isRewarded }) => {
  return (
    <div className="lesson-type lesson-content-space">
      <h3>{slide.name}</h3>
      <SlideContent slide={slide} />
    </div>
  );
};

export default Lesson;
