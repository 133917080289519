import { ECourseSpaceVisibility, THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';
import { BsChevronDown } from 'react-icons/bs';
import { NullButton } from 'shared/ui';

const bgDark = '#FFFFFF1A';
const bgLight = '#1111111A';

export const IconBox = styled.div`
  display: flex;
  width: 22px;
  height: 22px;
  & > svg {
    width: 100%;
    height: 100%;
  }
`;

export const Block = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  height: 100%;
  width: fit-content;
  gap: 8px;
`;

export const ButtonBox = styled.div`
  align-items: center;
  height: 44px;
  width: fit-content;
  position: relative;
  display: grid;
  grid-template-columns: 157px 133px;
`;

export const ButtonLeft = styled.div<{ $theme: THEMES }>`
  font-size: 16px;
  color: ${({ $theme }) => ($theme === THEMES.DARK ? '#ffffffB3' : '#111111B3')};
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px 0px 0px 8px;
  padding: 0px 20px;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? bgDark : bgLight)};
  white-space: nowrap;
  height: 44px;
  backdrop-filter: blur(30px);
`;

export const ButtonRight = styled(ButtonLeft)`
  position: relative;
  border-radius: 0px 8px 8px 0px;
  padding: 0px 14px;
  cursor: pointer;
  border: none;
  color: var(--font-color-strong);
  &:hover {
    filter: brightness(0.9);
  }
`;

export const DropdownContainer = styled.div`
  display: flex;
  width: 133px;
  height: 44px;
  position: relative;
`;

export const DropdownButton = styled(ButtonRight)<{ $customColor?: string; $isOpened?: boolean }>`
  transition: 0.3s;
  z-index: 1;
  &._${ECourseSpaceVisibility.Private} {
    background: #ca47ce4d;
  }
  &._${ECourseSpaceVisibility.Visible} {
    background: #29ab884d;
  }
  &._${ECourseSpaceVisibility.Invisible} {
    background: #afb4b8dd;
    color: var(--glass-dark);
  }
`;

export const DropdownLabel = styled.span`
  color: inherit;
  margin-right: auto;
  margin-left: 6px;
`;

export const DropdownOptions = styled(motion.div)<{ $theme: THEMES }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% - 8px);
  right: 0;
  overflow: hidden;
  border-radius: 0px 0px 8px 8px;
  padding-top: 8px;
  width: 100%;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? bgDark : bgLight)};
`;

export const DropdownOption = styled(ButtonRight)<{ $theme: THEMES }>`
  border-radius: 0px;
  background: none;
  &:not(:last-child) {
    border-bottom: 1px solid ${({ $theme }) => ($theme === THEMES.DARK ? bgDark : bgLight)};
  }
`;

export const Chevron = styled(BsChevronDown)<{ $isOpen?: boolean }>`
  rotate: ${({ $isOpen }) => ($isOpen ? '180deg' : '0deg')};
  color: inherit;
  width: 18px;
  height: 18px;
  transition: 0.3s;
`;

export const SecondaryButton = styled.button`
  flex-basis: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 44px;
  border-radius: 8px;
  cursor: pointer;
  transition: 0.3s;
  overflow: hidden;
  color: var(--font-color-strong);
  background: ${bgDark};
  &.light {
    background: ${bgLight};
    color: var(--mighty-blue);
  }

  &:hover {
    opacity: 0.8;
  }
  & > div,
  & svg {
    width: 22px;
    height: 22px;
  }
`;

export const CopyWrapper = styled.div`
  position: absolute;
  left: calc(100% + 4px);
  top: 0;
`;

export const BlueButton = styled(NullButton)`
  background: #2a2d3c;
  min-width: 266px;
  height: 44px;
  color: white;
  border-radius: 8px;
  &.light {
    color: var(--mighty-blue);
    background: #1111110a;
  }
  display: flex;
  align-items: center;
  padding: 0px 14px;
  font-size: 16px;
  transition: 0.2s;
  gap: 8px;
  & > ${IconBox} {
    color: var(--mighty-blue);
    width: 24px;
    height: 24px;
  }
  &:hover {
    opacity: 0.9;
  }
  &:active {
    opacity: 0.8;
    transition: 0.05s;
  }
`;
