import { BsArrowRight } from 'react-icons/bs';
import styles from './styles.module.css';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useNavigate } from 'react-router-dom';
import BasicButton from 'shared/ui/basic-button/button';
import { InView } from 'shared/ui/motion';

export default function ShopBanner() {
  const theme = useSelector(getCurrentTheme);
  const navigate = useNavigate();
  return (
    <InView forcedOnMobile className={`glass-div basic ${theme} ${styles.container}`}>
      <div className={styles.info}>
        <h4 className={styles.header}>But what can EXP tokens buy you?</h4>
        <p className={styles.description}>
          EXP tokens can be exchanged to items from a variety of games, as well as gift cards, coupons and other specials
        </p>
        <BasicButton
          isLabelFirst
          icon={<BsArrowRight />}
          className={styles.button}
          onClick={() => navigate('shop')}
          label="Go To Shop"
          variant="cool"
        />
      </div>
    </InView>
  );
}
