import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const Container = styled.div<{ $theme?: THEMES }>`
    width: 410px;
    height: 230px;
    display: flex;
    flex-direction: column;
    gap: 9px;
    align-items: center;
    justify-content: center;
    background: ${(p) => (p.$theme === THEMES.LIGHT ? '#99B3FF' : '#404040')};
    border-radius: 6px;
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    z-index: 1;
    ${(p) => p.$theme === THEMES.LIGHT && '& > * {color: #ffffff;}'}
  `,
  Label = styled.label<{ $theme?: THEMES }>`
    cursor: pointer;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3;
  `,
  Span = styled.span<{ $theme?: THEMES }>`
    font-size: 16px;
    line-height: 16.8px;
    text-align: center;
    color: rgba(253, 253, 253, 1);
    opacity: 0.4;
  `,
  ImgContainer = styled.div<{ $theme?: THEMES }>`
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Img = styled.img`
    width: 100%;
    max-width: 100%;
    object-fit: cover;
  `,
  Placeholder = styled.div<{ $theme?: THEMES }>`
    padding: 16px 24px 16px 24px;
    gap: 10px;
    border-radius: 31px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.15);
  `,
  PlaceholderImg = styled.svg`
    width: 24px;
    height: 24px;
  `;
