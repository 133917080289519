import { IExpEffectBadgePercents, IExpEffectWidgetData, TExpEffectWidgetDataBadge } from 'shared/ui/effect-indicator/types';
import { nanoid } from 'nanoid';

import { Badge } from '../badges/badge';

import * as Styled from './styles';

type Props = {
  effects: IExpEffectWidgetData[];
};

interface IBadgeCounted {
  item: TExpEffectWidgetDataBadge;
  total: number;
}

export const ActiveSum = ({ effects }: Props) => {
  const badges: IBadgeCounted[] = effects
    .flatMap((effect) => effect.badges ?? [])
    .filter((badge): badge is IExpEffectBadgePercents => badge.type === 'percents')
    .map((badge) => ({ item: badge, total: badge.percent }))
    .reduce((acc: IBadgeCounted[], current: IBadgeCounted) => {
      const item = acc.find((i) => i.item.effectName === current.item.effectName);

      if (item) {
        const currentItem = current.item as IExpEffectBadgePercents;
        item.total += currentItem.percent - 100;
      } else {
        return acc.concat(current);
      }

      return acc;
    }, [])
    .map((i) => ({ ...i, item: { ...i.item, percent: i.total } }));

  return (
    <Styled.Container>
      {badges.map((badge) => (
        <Badge key={nanoid()} data={badge.item} />
      ))}
    </Styled.Container>
  );
};
