import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      position: relative;

      .background {
        position: absolute;
        bottom: -2px;
        border-radius: 40px;
        width: 100%;
      }

      .letter {
        position: absolute;
        top: -80px;
        left: 10px;
        margin: 0 auto;
        width: 100%;
      }

      .premium {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: clamp(200px, 51.2%, 260px);
      }

      @media screen and (min-width: 992px) {
        border-radius: 12px;
        width: 525px;
        max-height: 485px;
        padding-bottom: 58px;

        .premium {
          top: -45px;
          left: 50%;
          transform: translateX(-50%);
          width: 277px;
        }

        .background {
          /* left: -2px; */
          background: var(--basic-bg);
          border-radius: 12px;
          width: unset;
        }
      }
    `
  ),
  Content = styled.div(
    () => css`
      color: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 200px;
      position: relative;
      z-index: 1;
      color: var(--font-color-strong);

      @media screen and (max-width: 992px) {
        height: 50vh;
        padding: 100px 20px 55px;
      }

      .button {
        width: 157px;
        height: 58px;
        font-weight: 500;
        margin-bottom: 14px;
      }
    `
  ),
  Title = styled.h2<{ $fontSize?: string }>(
    ({ $fontSize = '32px' }) => css`
      font-size: ${$fontSize};
      font-weight: 500;
      line-height: 115%;
      letter-spacing: 0.5px;
      margin-bottom: 8px;
      color: inherit;

      @media screen and (max-width: 992px) {
        font-size: ${$fontSize};
        margin-bottom: 13px;
      }
    `
  ),
  Text = styled.p<{ $maxWidth?: string }>(
    ({ $maxWidth = '480px' }) => css`
      font-weight: 300;
      line-height: 124%;
      margin-bottom: 32px;
      color: inherit;
      max-width: ${$maxWidth};
      text-align: center;

      @media screen and (max-width: 992px) {
        margin-bottom: 36px;
        width: 80%;
        max-width: 500px;
      }
    `
  ),
  Mark = styled.span<{ $color: string; $fontWeight?: number }>(
    ({ $color, $fontWeight }) => css`
      color: ${$color};
      font-weight: ${$fontWeight};
    `
  ),
  Time = styled.div(
    () => css`
      color: inherit;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      gap: 5px;
    `
  ),
  See = styled.div(
    () => css`
      color: inherit;
      font-size: 16px;
      line-height: 100%;
      text-decoration-line: underline;
      opacity: 0.4;
      cursor: pointer;
    `
  );
