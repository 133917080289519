import { useSelector } from 'react-redux';
import { getIsAuth } from 'store';
import { DailyRewardsAuth } from './daily-rewards-auth';
import { DailyRewardsUnAuth } from './daily-rewards-unauth';
import { IDailyRewardsProps } from './types';

export function DailyRewards({ ...rest }: IDailyRewardsProps) {
  const isAuth = useSelector(getIsAuth);
  return isAuth ? <DailyRewardsAuth {...rest} /> : <DailyRewardsUnAuth {...rest} />;
}
