import React, { FC, memo, useCallback, useEffect, useRef, useState } from 'react';
import { DndContext, closestCenter, MouseSensor, useSensor, useSensors, DragEndEvent, PointerSensor } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import CourseConstructorWidget from '../Widget';
import { v4 as uuid } from 'uuid';
import { ReactComponent as DotsIcon } from 'assets/icons/dots-double-vertical.svg';

import { TWidgetsListProps } from './types';
import { ESlideTypes, ESlideWidgetTypes } from 'store';
import { useSlideContext } from '../SlideContext';
import Dropdowns from './Dropdowns';
import { ETaskWidgetPreset, TaskWidgetPresets } from '../Widget/components/TaskWidget';
import { useDebounce, useDisclosure } from 'hooks';
import { Flex } from 'shared/ui';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

const SortableItem = memo(({ id, children }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleX: 1,
        scaleY: 1,
      }
    ),
    transition,
    position: 'relative' as const,
    touchAction: 'none',
  };

  return (
    <div ref={setNodeRef} style={style}>
      {React.cloneElement(children as React.ReactElement, {
        dragHandle: <DotsIcon {...attributes} {...listeners} />,
      })}
    </div>
  );
});

const WidgetsList: FC<TWidgetsListProps> = ({ items: widgets, onChange }) => {
  const debounce = useDebounce();
  const { slide, attachmentAction, loading } = useSlideContext();
  const $initialized = useRef(false);
  const [localWidgets, setLocalWidgets] = useState(widgets);

  const { isOpen: isLoadingLocal, open: onLoadingLocalOpen, close: onLoadingLocalClose } = useDisclosure(false);
  const isLoading = isLoadingLocal && loading;

  const sensors = useSensors(
    useSensor(MouseSensor, {
      // Only activate drag after delay
      activationConstraint: {
        delay: 100,
        tolerance: 5,
      },
    })
  );

  useEffect(() => {
    setLocalWidgets(widgets);
  }, [widgets]);

  const handleWidgetChange = useCallback(
    (widgetIdx: number) =>
      debounce((value: any) => {
        const widgetsCopy = structuredClone(localWidgets);
        if (typeof value === 'string') {
          widgetsCopy[widgetIdx].content = value;
        }
        if (typeof value === 'object') {
          widgetsCopy[widgetIdx] = { ...widgetsCopy[widgetIdx], ...value };
        }
        onChange(widgetsCopy);
      }, 500),
    [localWidgets, onChange]
  );

  const handleWidgetDelete = (widgetId: string) => async () => {
    const widget = widgets.find((item) => item.id === widgetId);
    if (!widget) {
      return;
    }

    if (widget.type === ESlideWidgetTypes.FILE || widget.type === ESlideWidgetTypes.IMAGE || widget.type === ESlideWidgetTypes.VIDEO) {
      await attachmentAction.delete({ widgetId, slideId: slide._id });
    }

    onChange(widgets.filter((item) => item.id !== widgetId));
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    if (!over || active.id === over.id) return;

    const copy = structuredClone(localWidgets);
    const oldIndex = copy.findIndex((item) => item.id === active.id);
    const newIndex = copy.findIndex((item) => item.id === over.id);

    const newItems = arrayMove(copy, oldIndex, newIndex);
    setLocalWidgets(newItems);
    onChange(newItems);
  };

  const handleWidgetClone = (widgetIdx: number) => () => {
    const widget = localWidgets[widgetIdx];
    if (!widget) {
      return;
    }

    const widgetsCopy = structuredClone(localWidgets);

    const newWidget = { ...widget, id: uuid() };
    widgetsCopy.splice(widgetIdx, 0, newWidget);

    setLocalWidgets(widgetsCopy);
    onChange(widgetsCopy);
  };

  const handleAddNewWidget = (widgetIdx?: number) => (type: ESlideWidgetTypes) => {
    const newWidget = {
      type,
      content: ``,
      id: uuid(),
    };

    if (typeof widgetIdx === 'undefined') {
      onChange([...localWidgets, newWidget]);
      return;
    }

    const widgetsCopy = structuredClone(localWidgets);
    widgetsCopy.splice(widgetIdx + 1, 0, newWidget);
    onChange(widgetsCopy);
  };

  const handleAddTaskWidget = (widgetIdx?: number) => (preset: ETaskWidgetPreset) => {
    if (!preset) {
      handleAddNewWidget()(ESlideWidgetTypes.TASK);
      return;
    }
    const { color, content, icon, link, taskType } = TaskWidgetPresets[preset];

    const newTaskWidget = {
      type: ESlideWidgetTypes.TASK,
      content,
      icon,
      color,
      id: uuid(),
      link,
      taskType,
    };

    if (typeof widgetIdx === 'undefined') {
      onChange([...localWidgets, newTaskWidget]);
      return;
    }

    const widgetsCopy = structuredClone(localWidgets);
    widgetsCopy.splice(widgetIdx + 1, 0, newTaskWidget);
    onChange(widgetsCopy);
  };

  const handleMakeTest = (type: ESlideTypes, isGenerated?: boolean, isRandomTestType?: boolean) => {
    onLoadingLocalOpen();
    if (slide.typeOfSlide === ESlideTypes.INFO) {
      attachmentAction
        .convert('toTest', type, slide._id, isGenerated, isRandomTestType)
        .catch()
        .finally(() => {
          onLoadingLocalClose();
        });
    } else {
      attachmentAction
        .convert('toInfo', ESlideTypes.INFO, slide._id)
        .catch()
        .finally(() => {
          onLoadingLocalClose();
        });
    }
  };

  useEffect(() => {
    if (!$initialized.current) {
      $initialized.current = true;
      return;
    }

    setLocalWidgets(widgets);
  }, [widgets]);

  return (
    <div>
      {Boolean(localWidgets.length) && (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={localWidgets.map((w) => w.id)} strategy={verticalListSortingStrategy}>
            {localWidgets.map((item, index) => (
              <SortableItem key={item.id} id={item.id}>
                <CourseConstructorWidget
                  id={item.id}
                  type={item.type}
                  value={item.content}
                  icon={item.icon}
                  color={item.color}
                  image={item.image}
                  title={item.title}
                  link={item.link}
                  author={item.author}
                  isRequired={item.isRequired}
                  draggingLabel={'Click to open menu, hold to start dragging'}
                  onAddWidget={handleAddNewWidget(index)}
                  onChange={handleWidgetChange(index)}
                  onDuplicate={handleWidgetClone(index)}
                  onDelete={handleWidgetDelete(item.id)}
                  onAddTaskWidget={handleAddTaskWidget(index)}
                />
              </SortableItem>
            ))}
          </SortableContext>
        </DndContext>
      )}
      <Dropdowns handleAddNewWidget={handleAddNewWidget} handleMakeTest={handleMakeTest} handleAddTaskWidget={handleAddTaskWidget} />
      {isLoading && <Loader />}
    </div>
  );
};

const Loader = () => {
  return (
    <Flex $justifyContent="center" $alignItems="center">
      <MightyLoaderMini isLoading />
    </Flex>
  );
};

export default WidgetsList;
