import styled, { css } from 'styled-components';

export const SRoot = styled.div(() => css``),
  STabsRow = styled.div(
    () => css`
      display: flex;
      gap: 15px;
      margin-bottom: 10px;
    `
  );
