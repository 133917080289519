import { Button, Grid } from 'shared/ui';
import styled from 'styled-components';
import InventoryItem from 'components/library/cards/inventory/InventoryItem';

export const Container = styled(Grid)`
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 4px;
  height: 84px;
  width: 84px;
`;

export const MenuButton = styled(Button)`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  color: var(--font-color-strong);
  background: linear-gradient(0deg, rgba(159, 159, 159, 0.19), rgb(114 116 141 / 42%));
  background-blend-mode: overlay, normal;

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const Item = styled(InventoryItem)`
  width: 40px;
  height: 40px;
  max-width: 40px;
  max-height: 40px;
`;
