import { FC, HTMLProps, useCallback } from 'react';
import { useEditor, EditorContent, Editor, Extensions, Content } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import clsx from 'clsx';
import './styles.css';
import styles from './styles.module.scss';
import { parseIfJson } from '../../../utils/object';

type TTiptapEditorProps = {
  className?: HTMLProps<HTMLDivElement>['className'];
  bubble?: (editor: Editor) => React.ReactNode;
  extensions?: Extensions;
  content?: Content;
  onChange?: (jsonValue: string, textValue: string) => void;
  editable?: boolean;
  placeholder?: string;
};

const TiptapEditor: FC<TTiptapEditorProps> = ({ className, bubble, extensions, content, onChange, editable = true, placeholder }) => {
  const callChange = useCallback(
    (jsonValue: string, textValue: string) => {
      onChange?.(jsonValue, textValue);
    },
    [onChange]
  );

  const editor = useEditor({
    editable: editable,
    extensions: extensions ?? [StarterKit.configure({ bold: false, italic: false })],
    content: parseIfJson(content) ?? placeholder,
    onUpdate: ({ editor }) => {
      callChange(JSON.stringify(editor.getJSON()), editor.getText());
    },
  });

  return (
    <div className={clsx(styles.root, className)}>
      <EditorContent editor={editor} className="tiptapEditor-root" />
      {editor && bubble?.(editor)}
    </div>
  );
};

export default TiptapEditor;
