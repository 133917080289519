import { TNode } from 'utils/contenteditable';

export const extractAnswersFromDomJson = (jsonDom: TNode) => {
  const answers: string[] = [];

  const answer = jsonDom.attributes['data-mighty-answer'];
  if (answer) {
    answers.push(answer);
  }

  jsonDom.children.forEach((node) => {
    answers.push(...extractAnswersFromDomJson(node));
  });

  return answers;
};

export const extractRightAnswersFromJSONDOM = (jsonDOM: string) => {
  try {
    const domObj = JSON.parse(jsonDOM);

    const answers = extractAnswersFromDomJson(domObj);
    return answers;
  } catch (e) {
    return [];
  }
};

export const extractIncorrectAnswersFromJSONDOM = (jsonDOM: string, answers: string[]) => {
  try {
    const rightAnswers = extractRightAnswersFromJSONDOM(jsonDOM);
    return answers.filter((item) => !rightAnswers.includes(item));
  } catch (e) {
    return [];
  }
};
