import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  width: 90%;
  max-width: 372px;
  padding: 0px 48px 0px 16px;
  color: var(--font-color);
  background: #ffffff0a;
  white-space: nowrap;
  &.light {
    background: #1111110a;
  }
  border-radius: 10px;
  gap: 12px;
  position: relative;
  margin: 0 auto;
  font-size: 18px;
  margin-top: 56px;
  @media screen and (max-width: 992px) {
    margin-top: 22px;
  }
`;
