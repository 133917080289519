import { CgFileDocument } from 'react-icons/cg';
import { useNavigate } from 'react-router-dom';

import './document.css';

export default function Document({ name, link, isLarge }) {
  const lang = localStorage.getItem('i18nextLng').slice(0, 2) || 'en';
  const theme = localStorage.getItem('theme');
  const navigate = useNavigate();
  try {
    let title = (name.find((i) => i.language === lang) || name.find((i) => i.language === 'en')).title;

    return (
      <div className={`document ${isLarge ? `glass-div basic ${theme} large` : ''}`} role="button" onClick={() => navigate(link)}>
        <CgFileDocument />
        {title}
      </div>
    );
  } catch (err) {
    console.log(err);
    return <>{"что-то сломалось:'\\    "}</>;
  }
}
