import { useReducer } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getUserData } from 'store';
import clsx from 'clsx';
import { useGetAuthMethodsData } from 'hooks';

import { BsCheck } from 'react-icons/bs';
import { ReactComponent as Edit } from 'assets/icons/edit.svg';
import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg';
// import { ReactComponent as Copy } from 'assets/icons/copy.svg';
import { ReactComponent as Logout } from 'assets/icons/logout.svg';

import { Languages } from '../languages';
import NicknameChanger from 'shared/utils/nickname-changer/NicknameChanger.jsx';
import { EnergyWidget } from 'shared/ui';
import { EffectsWidget } from './widgets/effects';
import BlockModal from 'components/library/utils/BlockModal';
import { TooltipSmall } from 'shared/ui/tooltip';

import styles from '../../styles.module.css';
import * as Styled from './styles';

interface IState {
  isEffects: boolean;
  nickChange: boolean;
}

const defaultState: IState = {
  isEffects: true,
  nickChange: false,
};

type Props = {
  handleMainToEffects(): any;
  handleMainMenu(value: string): any;
  handleAddressCopy(): any;
  handleLogOut(): any;
  isAddressActivated: boolean;
  addressCopy: boolean;
  className?: string;
};

export const MainPanel = ({
  className = '',
  handleMainMenu,
  handleAddressCopy,
  handleLogOut,
  handleMainToEffects,
  isAddressActivated,
  addressCopy,
}: Props) => {
  const [state, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), defaultState);
  const { nickname } = useSelector(getUserData, shallowEqual);
  const { getEmail: getIEmail } = useGetAuthMethodsData();
  const { isEffects, nickChange } = state;

  const handleState = (value: Partial<IState>) => () => updateState(value);

  return (
    <Styled.Container
      className={clsx({
        [className]: !!className,
      })}
    >
      {isEffects && (
        <div className={clsx(styles.block, styles.regularBlock)} role="button">
          <Styled.Effect as={EffectsWidget} handleOpenEffects={handleMainToEffects} />
        </div>
      )}
      <div className={clsx(styles.block, styles.regularBlock, styles.nickname)} role="button">
        <EnergyWidget className="containable" />
      </div>
      <BlockModal show={nickChange} hide={handleState({ nickChange: false })}>
        <NicknameChanger open={nickChange} nickname={nickname} close={handleState({ nickChange: false })} />
      </BlockModal>
      <div className={clsx(styles.block, styles.regularBlock, styles.nickname)} role="button" onClick={handleState({ nickChange: true })}>
        <div className={styles.editNickname}>
          {nickname}
          <Edit className="util" style={{ marginLeft: 5 }} />
        </div>
        <div className="email">{getIEmail() ?? 'No email set'}</div>
      </div>
      <div className={styles.iconsMenu}>
        <div className={clsx(styles.block, styles.picBlock)} role="button" onClick={handleMainMenu('Profile')}>
          <TooltipSmall className={styles.tooltip} text={'Profile'}>
            <User />
          </TooltipSmall>
        </div>

        <div className={clsx(styles.block, styles.picBlock)} role="button" onClick={handleMainMenu('Settings')}>
          <TooltipSmall className={styles.tooltip} text={'Settings'}>
            <Settings />
          </TooltipSmall>
        </div>
        <div className={clsx(styles.block, styles.picBlock)} role="button" onClick={handleMainMenu('Inventory')}>
          <TooltipSmall className={styles.tooltip} text={'Inventory'}>
            <Wallet style={{ stroke: 'none' }} />
          </TooltipSmall>
        </div>
      </div>
      <div className={clsx(styles.block, styles.regularBlock, styles.wallet)} role="button" onClick={handleAddressCopy}>
        Wallet address
        <div className="wal">
          {!isAddressActivated && <p style={{ opacity: 0.5 }}>Not confirmed</p>}
          {isAddressActivated && addressCopy && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              copied to clipboard
              <BsCheck size={20} color="var(--font-color)" />
            </div>
          )}
          {/* TODO decide what to render here as address */}
          {/* {isAddressActivated && !addressCopy && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {getShortStr(userProfile?.address, { fStart: 4, lStart: -4 })}
              <Copy className="util" />
            </div>
          )} */}
        </div>
      </div>
      {/* <div className={clsx(styles.block, styles.regularBlock, styles.chain)} onClick={handleChainsToggle}>
              Blockchain network
              <div className={styles.chainBtn}>
                <div className={styles.chainLogo}>
                  <ETH style={{ stroke: 'none' }} />
                </div>
                Ethereum
                <BsChevronRight size={12} strokeWidth={1} />
              </div>
            </div> */}
      <div className={styles.iconsMenu}>
        <Languages className={clsx(styles.block, styles.picBlock)} />
        <div className={clsx(styles.block, styles.picBlock)} role="button" onClick={handleLogOut}>
          <Logout /> Logout
        </div>
      </div>
    </Styled.Container>
  );
};
