import clsx from 'clsx';

import { NullButton } from 'shared/ui';

import { BsChevronRight } from 'react-icons/bs';

import * as Styled from './styles';
import { EffectsWidget as UserEffectsWidget } from 'shared/ui/effects-widget';

type Props = {
  handleOpenEffects(): void;
  className?: string;
  isChevron?: boolean;
};

export const EffectsWidget = ({ handleOpenEffects, className = '', isChevron = true }: Props) => {
  return (
    <Styled.Container className={clsx({ [className]: !!className })} onClick={handleOpenEffects}>
      <Styled.Effects as={UserEffectsWidget} />
      <Styled.Chevron className={clsx({ visible: isChevron })} as={NullButton}>
        <BsChevronRight />
      </Styled.Chevron>
    </Styled.Container>
  );
};
