import { IDatabaseItem } from 'interface';

export interface IDailyRewardsProps {
  onMPBannerClose?: () => void;
  isBannerOnly?: boolean;
  className?: string;
  modalCardsSize?: CARDS_SIZES;
  toastCardsSize?: CARDS_SIZES;
  bannerCardsSize?: CARDS_SIZES;
}

export interface IDailyReward extends IDatabaseItem {
  day: number;
  quantity: number;
  _id: string;
}

export enum BUTTON_STATES {
  COLLECT = 'Collect',
  CONTINUE = 'Continue',
}
export enum CARDS_SIZES {
  ISSMALL = 'isSmall',
  ISMEDIUM = 'isMedium',
  ISDEFAULT = 'isDefault',
}

// daily rewarding animation states
export enum EAnimationStates {
  // initial
  INIT = 0,
  // item gets bigger, stats go away
  PRE_LOADING = 1,
  // button - loader
  LOADING = 2,
  // button - label 'continue', item name appears, golden check appears
  SUCCESS = 3,
  FAIL = 4,
  // item name disappears, golden check appears and item going back, stats go away
  END = 5,
}
export enum EANIMATION_TIMINGS {
  START = 1400,
  SUCCESS = 1000,
  END = 1000,
}
