import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

const bgDark = 'radial-gradient(70.54% 120.87% at 99.3% 0%, rgba(98, 126, 234, 0.2) 0%, rgba(0, 0, 0, 0.02) 100%), #1C1D26;';
const bgLight = '#FDFDFD';

export const Container = styled.div<{ $theme: THEMES }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? bgLight : bgDark)};
  @media screen and (max-width: 992px) {
    padding-top: 167px;
    overflow: auto;
    & > .refs-back-button {
      position: absolute;
      top: 63px;
      left: 16px;
    }
  }
`;

export const Content = styled(motion.div)`
  max-width: 1110px;
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin: 0 auto;
  position: relative;
  & > .refs-back-button {
    position: absolute;
    left: 0;
    top: 76px;
  }
  @media screen and (max-width: 992px) {
    flex-direction: column-reverse;
    width: 100%;
    height: fit-content;
    padding: 90px 16px 50px;
    border-radius: 40px 40px 0px 0px;
    border-top: 2px solid white;
    & > .chest {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  & .chest {
    margin-top: -80px;
    margin-bottom: -10px;
  }
  &.left {
    max-width: 490px;
  }
  &.right {
    max-width: 475px;
  }
  @media screen and (max-width: 992px) {
    max-width: none !important;
    width: 100%;
  }
`;

export const Sub = styled.p<{ $theme: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#fff')};
  font-size: 28px;
  font-weight: 500;
  @media screen and (max-width: 992px) {
    font-size: 16px;
    font-weight: 400;
  }
`;

export const Blue = styled.span`
  background: linear-gradient(90deg, #6487ef 0%, #53259b 125.56%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  color: transparent;
`;

export const Title = styled.h1<{ $theme: THEMES }>`
  font-size: 64px;
  line-height: 67.84px;
  font-weight: 500;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#fff')};
  margin-bottom: 42px;
  @media screen and (max-width: 992px) {
    font-size: 28px;
    line-height: 34.72px;
    margin-bottom: 24px;
  }
`;

export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 20px 0px 42px;
  width: 100%;
  @media screen and (max-width: 992px) {
    margin-bottom: 32px;
  }
`;

export const ListNum = styled.span<{ $theme: THEMES }>`
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#fff')};
  margin-top: -5px;
  background: #ffffff1a;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 100%;
  font-size: 18px;
  @media screen and (max-width: 992px) {
    width: 26px;
    height: 26px;
    min-width: 26px;
    font-size: 14px;
    margin-top: -4px;
  }
`;

export const ListItem = styled.p<{ $theme: THEMES }>`
  display: flex;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#fff')};
  padding-top: 5px;
  gap: 10px;
  font-size: 16px;
  line-height: 21.12px;
  @media screen and (max-width: 992px) {
    padding-top: 4px;
  }
`;

export const SocialSub = styled.p<{ $theme: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#fff')};
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 14px;
`;

export const SocialButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  transition: ease-in-out 0.3s;
  cursor: pointer;
  & > svg {
    max-width: 27px;
    height: auto;
  }
  &:hover {
    opacity: 0.8;
  }
`;

export const Socials = styled.div<{ $theme: THEMES }>`
  display: flex;
  gap: 16px;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#1C1D26' : '#fff')};
  & ${SocialButton} {
    background: ${(p) =>
      p.$theme === THEMES.LIGHT ? '#627EEA26' : 'linear-gradient(69.22deg, rgba(255, 255, 255, 0.1) 9.87%, rgba(255, 255, 255, 0) 133.36%);'};
  }
  @media screen and (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

export const Row = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Tip = styled.div<{ $theme: THEMES }>`
  background: #627eea24;
  & > img,
  & > svg {
    display: inline-block;
    margin-right: 3px;
  }
  & > span {
    color: inherit;
    margin: 0px 5px;
  }
  padding: 0px 10px;
  border-radius: 6px;
  height: 36px;
  display: flex;
  align-items: center;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#627EEA' : 'white')};
`;

export const ShareButtonIcons = styled.div`
  display: flex;
  align-items: center;
  & > svg {
    &:not(:last-child) {
      margin-right: -15px;
    }
    height: 30px;
    width: 30px;
  }
`;
