import { nanoid } from 'nanoid';
import { Badge } from '../../badges/badge';
import { IExpEffectWidgetData } from 'shared/ui/effect-indicator/types';

import * as Styled from './styles';

interface IProps {
  effect: IExpEffectWidgetData;
}

export const Effects = ({ effect }: IProps) => {
  const { badges } = effect;
  const filteredBadges = badges?.filter((b) => b?.effectName !== 'expires_in') ?? [];

  return (
    <Styled.Container>
      <Styled.Badges>
        {filteredBadges.map((badge) => (
          <Styled.Item key={nanoid()}>
            <Styled.ItemBadge as={Badge} data={badge} />
          </Styled.Item>
        ))}
      </Styled.Badges>
      <Styled.Descs>
        {filteredBadges.map((badge) => (
          <Styled.Item key={nanoid()}>
            <Styled.Header>{badge.effectName.replaceAll('_', ' ')}</Styled.Header>
          </Styled.Item>
        ))}
      </Styled.Descs>
    </Styled.Container>
  );
};
