import { ReactComponent as Upload } from 'assets/icons/image-plus.svg';
import * as Styled from './styles';
import { THEMES } from 'app/constants';

type Props = {
  src?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => any;
  theme?: THEMES;
};

export const Cover = ({ src, onChange, theme }: Props) => {
  return (
    <Styled.Container $theme={theme}>
      <Styled.Label $theme={theme}>
        <input type="file" hidden onChange={onChange} accept=".jpg, .png" />
      </Styled.Label>
      {src ? (
        <Styled.ImgContainer>
          <Styled.Img src={src} alt="cover" />
        </Styled.ImgContainer>
      ) : (
        <>
          <Styled.Placeholder $theme={theme}>
            <Styled.PlaceholderImg as={Upload} />
            Add cover
          </Styled.Placeholder>
          <Styled.Span $theme={theme}>
            Banner size 500x280px, 16:9 <br />
            .jpg or .png
          </Styled.Span>
        </>
      )}
    </Styled.Container>
  );
};
