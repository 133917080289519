import { ROUTES } from 'app/constants';
import { useNavigate } from 'react-router-dom';
import BasicButton from 'shared/ui/basic-button/button';
import { useFlushCourseMutation } from 'store';

type Props = {
  resultCourseId: string;
  style: any;
};

const ResetCourseButton = ({ resultCourseId, style }: Props) => {
  const [flushCurrentCourse] = useFlushCourseMutation();
  const navigate = useNavigate();
  const flushCourse = () => {
    flushCurrentCourse({ resultCourseId }).finally(() => {
      navigate(ROUTES.MAIN);
    });
  };

  if (process.env.NODE_ENV === 'development') {
    return <BasicButton label="[DEV] flush course" style={{ opacity: '.3', width: 'fit-content', ...style }} onClick={flushCourse} />;
  }

  return null;
};

export default ResetCourseButton;
