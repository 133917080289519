import * as S from './style';
import { useDisplay } from 'hooks';
import { useGetLeaderboardUsersBySpace } from 'hooks/useGetLeaderboardUsers';
import { useMemo, useState } from 'react';

import leaderboard1PlaceIcon from './assets/1stIcon.png';
import leaderboard2PlaceIcon from './assets/2ndIcon.png';
import leaderboard3PlaceIcon from './assets/3rdIcon.png';
import leaderboardNonleaderIcon from './assets/4thAndHigher.png';
import expIcon from './assets/exp.png';
import { Avatar } from 'components/library/Avatar';
import { IFormattedLeaderboardUser } from 'store/leaderboard';
import { MultipurposeBanner } from 'shared/ui';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getIsAuth } from 'store';

import './style.css';

const placeIcons = [leaderboard1PlaceIcon, leaderboard2PlaceIcon, leaderboard3PlaceIcon, leaderboardNonleaderIcon];

type Props = {
  spaceName: string;
};

type PreviewProps = {
  users: IFormattedLeaderboardUser[];
  hide?: () => void;
  spaceName: string;
};

const SpaceLeaderboardPreview = ({ users, spaceName }: PreviewProps) => {
  const { isMobile, viewportWidth } = useDisplay();
  const isAuth = useSelector(getIsAuth);
  const navigate = useNavigate();

  const maxUsersShownMobile = useMemo(() => {
    return Math.max(Math.floor((viewportWidth - 100) / 100), 3);
  }, [viewportWidth, isMobile]);

  const handleNavigate = () => {
    navigate(`/play/leaderboard/${spaceName.replaceAll(' ', '_')}`);
  };

  return (
    <S.Container onClick={isMobile ? handleNavigate : () => {}}>
      {isMobile && (
        <S.MobileNumList>
          {users.map((el, i) => {
            if (i + 1 > maxUsersShownMobile) {
              return null;
            }
            if (i === 0) {
              return (
                <S.MobileNumBox key={el.nickname}>
                  <S.UserPlaceIcon src={leaderboard1PlaceIcon} />
                </S.MobileNumBox>
              );
            }
            if (i === 1) {
              return (
                <S.MobileNumBox key={el.nickname}>
                  <S.UserPlaceIcon src={leaderboard2PlaceIcon} />
                </S.MobileNumBox>
              );
            }
            if (i === 2) {
              return (
                <S.MobileNumBox key={el.nickname}>
                  <S.UserPlaceIcon src={leaderboard3PlaceIcon} />
                </S.MobileNumBox>
              );
            }

            return (
              <S.MobileNumBox key={el.nickname}>
                <S.UserPlaceIcon src={leaderboardNonleaderIcon}></S.UserPlaceIcon>
                {i + 1}
              </S.MobileNumBox>
            );
          })}
        </S.MobileNumList>
      )}
      <S.Box>
        <S.Left>
          <S.Title>Leaderboards</S.Title>
          <S.LButton size="sm" onClick={handleNavigate}>
            Open
          </S.LButton>
        </S.Left>
        <S.UserList>
          {users.map((el, i) => {
            const icon = placeIcons[i];
            if (isMobile && i + 1 > maxUsersShownMobile) {
              return null;
            }
            return (
              <S.UserItem $place={i} key={el.extId}>
                {!isMobile && <S.UserPlaceIcon src={icon} />}
                <S.UserInfo>
                  <Avatar userName={el.nickname} src={el.avatar} size={isMobile ? 32 : 46} />
                  <S.Nickname>{el.nickname}</S.Nickname>
                  <S.Exp>
                    {' '}
                    <img src={expIcon} /> {el.balance}
                  </S.Exp>
                </S.UserInfo>
              </S.UserItem>
            );
          })}
        </S.UserList>
      </S.Box>
    </S.Container>
  );
};

const SpaceLeadersWinners = ({ spaceName }: Props) => {
  const { users } = useGetLeaderboardUsersBySpace({ spaceName, isFriends: false, isMonthly: false });
  const [, setShow] = useState(true);
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);

  const preparedUsers = useMemo(() => {
    if (users.length >= 3) {
      return users.slice(0, 3);
    } else {
      return null;
    }
  }, [users.length]);

  const usersMobile = useMemo(() => {
    if (users.length >= 4) {
      return users.slice(0, Math.max(4, Math.min(7, users.length)));
    } else if (preparedUsers) {
      return preparedUsers;
    } else {
      return null;
    }
  }, [preparedUsers, users]);

  if (!preparedUsers) {
    return null;
  }

  if (!isMobile) {
    return (
      <MultipurposeBanner
        slides={[
          {
            component: SpaceLeaderboardPreview,
            name: 'SpaceWinners',
            props: { users: preparedUsers, spaceName },
          },
        ]}
        className="space-leaders-banner"
        isBannerOnly={true}
      />
    );
  }

  if (!usersMobile) {
    return null;
  }

  return (
    <>
      <S.MobileTitle $theme={theme}>Leaderboards</S.MobileTitle>
      <SpaceLeaderboardPreview spaceName={spaceName} users={usersMobile} hide={() => setShow(false)} />
    </>
  );
};

export default SpaceLeadersWinners;
