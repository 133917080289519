export const formatAnswers = (node: HTMLElement) => {
  const answers = node.querySelectorAll('[data-mighty-type="mighty-answer-slot"]');

  answers.forEach((answer, idx) => {
    const input = document.createElement('input');
    input.setAttribute('data-answer-idx', idx.toString());
    input.setAttribute('data-type', 'mighty-answer-field');
    input.setAttribute('size', answer?.innerHTML?.length?.toString() ?? '');
    answer.replaceWith(input);
  });

  return node;
};
