import { SignUp } from './signUp';
import { ISignUpForm } from 'interface';
import { useRegisterMutation } from 'store';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { useGlobalLoader } from 'shared/utils/GlobalLoader/useGlobalLoader';
import { LOCAL_STORAGE_KEYS, RESIGNUP_MESSAGE, ROUTES } from 'app/constants';
import { useNavigate } from 'react-router-dom';

export const SignUpPage = () => {
  const [register, { isLoading, error }] = useRegisterMutation();
  const navigate = useNavigate();

  useGlobalLoader({ dependency: [false] });

  const onSubmitHandler = async (data: ISignUpForm) => {
    localStorage.setItem(LOCAL_STORAGE_KEYS.SIGNUP_TEMP, JSON.stringify(data));
    const referralCode = localStorage.getItem('referralCode');

    return register({ ...data, referralCode: referralCode || undefined })
      .unwrap()
      .then(() => {
        navigate(ROUTES.ACTIVATION_CODE);
      })
      .catch((ex) => {
        console.log(ex);
        if (ex.data.message === RESIGNUP_MESSAGE) {
          toast.error(<Answer label="Seems like you registered, but did not activate you account" type="incorrect" />);
          navigate(ROUTES.ACTIVATION_CODE);
        }
      });
  };

  const openWalletModal = () =>
    toast.error(<Answer label="Wallet Connect not available in closed beta - please use email to sign-up" type="incorrect" />);

  return <SignUp error={(error as any)?.data?.message} onSubmitHandler={onSubmitHandler} isLoading={isLoading} openWalletModal={openWalletModal} />;
};
