import styled, { css } from 'styled-components';

export const SModalActivatedCodes = styled.div(
    () => css`
      height: 75vh;
      padding: 26px 13px 0 13px;
      h4 {
        color: var(--font-color-strong);
        font-size: 28px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 13px;

        @media (max-width: 992px) {
          margin-bottom: 18px;
          text-align: center;
          font-weight: 500;
          font-size: 24px;
          line-height: 26.4px;
        }
      }
    `
  ),
  ContainerCodes = styled.div(
    () => css`
      overflow-y: auto;
      overflow-x: hidden;
      max-height: calc(76vh - 90px);
      margin-top: 18px;
      padding-right: 10px;
    `
  );
