import { useEffect, useMemo } from 'react';
import { ESlideWidgetTypes, getUserData, useLazyCheckTONBalanceTaskQuery, useLazyCheckTONBridgeTaskQuery } from 'store';
import { useSelector } from 'react-redux';
import { EAuthMethods } from 'interface';
import { ETask } from 'components/library/courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/constants';

type Props = {
  slide: any;
  incrementCompletedTasksCount: () => {};
  setTasksLeft(arg: number): void;
};

export const useGetMappedContent = ({ slide, incrementCompletedTasksCount, setTasksLeft }: Props) => {
  const [checkBridgeTask, { isLoading: isCheckingBridgeTask }] = useLazyCheckTONBridgeTaskQuery();
  const [checkBalanceTask, { isLoading: isCheckingBalanceTask }] = useLazyCheckTONBalanceTaskQuery();
  const { authMethods = [] } = useSelector(getUserData);
  const TONAuthMethod = authMethods?.find((i) => i.method === EAuthMethods.TON);
  const TONAddress = TONAuthMethod?.token;

  const mappedContent = useMemo(() => {
    if (slide) {
      return slide.content.map((el: any) => {
        if (el.type === ESlideWidgetTypes.TASK && el.isRequired) {
          if (Object.values(ETask).includes(el.taskType)) {
            if (!TONAddress) {
              return {
                ...el,
                onTaskComplete: () => {
                  return { stopAction: 'link-modal--TON', status: false, isStopped: true };
                },
              };
            }

            if (el.taskType === ETask.TONBridgeETH) {
              return {
                ...el,
                onTaskComplete: () =>
                  checkBridgeTask('ETH')
                    .unwrap()
                    .then((res) => {
                      if (res.status === 'done') {
                        incrementCompletedTasksCount();
                      }
                      return { status: res.status === 'done' };
                    }),
              };
            } else if (el.taskType === ETask.TONBridgeBSC) {
              return {
                ...el,
                onTaskComplete: () =>
                  checkBridgeTask('BSC')
                    .unwrap()
                    .then((res) => {
                      if (res.status === 'done') {
                        incrementCompletedTasksCount();
                      }
                      return { status: res.status === 'done' };
                    }),
              };
            } else if (el.taskType === ETask.TONDeposit) {
              return {
                ...el,
                onTaskComplete: () =>
                  checkBalanceTask()
                    .unwrap()
                    .then((res) => {
                      if (res.status === 'done') {
                        incrementCompletedTasksCount();
                      }
                      return { status: res.status === 'done' };
                    }),
              };
            }
          } else {
            return {
              ...el,
              onTaskComplete: () => {
                incrementCompletedTasksCount();
                return { status: true };
              },
            };
          }
        }
        return {
          ...el,
          onTaskComplete: () => {
            return { status: true };
          },
        };
      });
    }
  }, [slide, incrementCompletedTasksCount, TONAddress, checkBridgeTask, checkBalanceTask]);

  useEffect(() => {
    const tasksCount = mappedContent.reduce((acc: any, el: any) => (acc += el.type === ESlideWidgetTypes.TASK && el.isRequired ? 1 : 0), 0);
    setTasksLeft(tasksCount);
  }, [mappedContent]);

  return { mappedContent, isCheckingBridgeTask, isCheckingBalanceTask };
};
