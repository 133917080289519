import { useTonConnectUI } from '@tonconnect/ui-react';
import { useTONBackendAuth } from './useTONBackendAuth';
import { TemplateButton } from '../authButtons';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { ReactComponent as TONIcon } from 'assets/icons/tasks/on-chain/ton-naked--white.svg';
import { useGetIsAuth } from 'hooks';

export const TONAuthButton = () => {
  const [tonConnectUI] = useTonConnectUI();
  const isAuth = useGetIsAuth();
  const { isLoading } = useTONBackendAuth();

  if (isAuth || isLoading) {
    return (
      <TemplateButton
        isChevron={false}
        icon={
          <div style={{ width: '100%' }}>
            <MightyLoaderMini style={{ height: '40px', width: 'auto', margin: '0 auto' }} />
          </div>
        }
        variant={'email'}
      />
    );
  }

  const onClick = () => {
    tonConnectUI.openModal();
  };

  return <TemplateButton onClick={onClick} title={'Connect wallet'} icon={<TONIcon style={{ height: '36px', width: '36px' }} />} variant={'email'} />;
};
