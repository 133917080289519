import { GRADES, ROUTES, USER_ROLES } from 'app/constants';
import Answer from 'components/library/messages/Answer';
import { useGetItems, useGetPartnerId, useGetUserData } from 'hooks';
import { IDatabaseItem } from 'interface';
import { useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ESpaceRoles, ISpace, useGetGeolocationDataQuery, useGetSpaceRewardsQuery, useLazyGetSpaceByNameQuery, useLazyGetSpacesQuery } from 'store';

export const useGetUserRelatedSpaceInfo = (space: ISpace | null) => {
  const user = useGetUserData();

  const isMightyAdmin = useMemo(() => {
    if (!user || !user.roles) {
      return false;
    }
    return !!user.roles.includes(USER_ROLES.ADMIN);
  }, [user]);

  const isSpaceAdminOrOwner = useMemo(() => {
    if (!space) {
      return false;
    }
    const spaceUser = !!user && !!space ? space.users.find((el) => el.userId._id === user._id) : null;
    if (spaceUser && spaceUser.roles.includes(ESpaceRoles.OWNER)) {
      return true;
    }
    if (spaceUser && spaceUser.roles.includes(ESpaceRoles.ADMIN)) {
      return true;
    }

    return false;
  }, [space, user]);

  const isAbleToEdit = useMemo(() => isMightyAdmin || isSpaceAdminOrOwner, [isMightyAdmin, isSpaceAdminOrOwner]);

  return { isAbleToEdit };
};

export const useGetSpaceRewards = (spaceId: string, skip?: boolean) => {
  const items = useGetItems();
  const { data: spaceRewards, isLoading: isLoadingRewards } = useGetSpaceRewardsQuery(spaceId, { skip });
  const [rewards, setRewards] = useState<IDatabaseItem[]>([]);
  const commonItems = useMemo(() => items.filter((el) => el.grade === GRADES.COMMON && el.imageThumb && el.type === 'fungible'), [items]);

  const nonInRewardsItems = useMemo(() => {
    if (rewards.length < 6) {
      return commonItems.filter((item) => !rewards.find((rew) => rew.id === item.id));
    }
    return [];
  }, [rewards, commonItems]);

  useEffect(() => {
    if (spaceRewards) {
      const newRewards = spaceRewards.items.map((rew) => items.find((i) => i.id === rew.id)).filter((el) => !!el) as IDatabaseItem[];
      setRewards(newRewards);
    }
    return () => {
      setRewards([]);
    };
  }, [spaceRewards]);

  return { rewards, isLoadingRewards, restCommonItems: nonInRewardsItems, total: [...rewards, ...nonInRewardsItems] };
};

interface IState {
  spaces: ISpace[];
  page: number;
  total: number;
  isInitiated: boolean;
  isEmptyResponse: boolean;
}

export const useGetSpaces = (verified = true, limit = 3) => {
  const [{ spaces, page, isInitiated, total, isEmptyResponse }, updateState] = useReducer(
    (prev: IState, next: Partial<IState>) => ({ ...prev, ...next }),
    {
      spaces: [],
      page: 1,
      isInitiated: false,
      total: 0,
      isEmptyResponse: false,
    }
  );

  const [fetch, { isFetching, isLoading }] = useLazyGetSpacesQuery();
  const initiate = () => updateState({ isInitiated: true });

  const isFinished = (spaces.length >= total && total !== 0) || isEmptyResponse;

  useEffect(() => {
    initiate();
  }, []);

  useEffect(() => {
    if (isInitiated && !isFinished && !isFetching) {
      fetch({ page, verified, limit })
        .unwrap()
        .then((res) => {
          if (res.total === 0) {
            updateState({ isEmptyResponse: true });
            return;
          }
          updateState({
            isInitiated: false,
            spaces: [...spaces, ...res.items],
            total: res.total,
            page: page + 1,
          });
        })
        .catch(console.error)
        .finally(() => {
          updateState({ isInitiated: false });
        });
    }
  }, [isInitiated, isFinished]);

  return { spaces, isFetching, initiate, isLoading, isFinished };
};

const getUKCountryCode = (geolocationCountryCode: string) => {
  if (!geolocationCountryCode) {
    return '';
  }
  if (['UK', 'GB'].includes(geolocationCountryCode)) {
    return 'UK';
  }
  return geolocationCountryCode;
};

export const useGetSpaceGeolocation = () => {
  const navigate = useNavigate();
  const [spaceNameState, setSpaceNameState] = useState({ spaceName: '', isPartner: false, isProcessed: false });
  const { spaceName } = useParams();
  const [getSpace] = useLazyGetSpaceByNameQuery();
  const partnerId = useGetPartnerId();
  const { data: geolocationData, isFetching: isFetchingGeolocation } = useGetGeolocationDataQuery({ format: 'json' });

  const checkAndRedirect = useCallback(async () => {
    if (isFetchingGeolocation || !spaceName) {
      return;
    }
    const geolocationCountryCode = geolocationData?.country_code;

    if (!partnerId) {
      setSpaceNameState({ spaceName: spaceName.replaceAll('_', ' '), isPartner: false, isProcessed: true });
    } else if (geolocationCountryCode) {
      // for some legal reasons, we need to redirect UK user to UK space
      const countryCodeException = 'UK';
      const userCountryCode = getUKCountryCode(geolocationCountryCode);
      const isSpaceNameAlreadyHasCountryCode = spaceName.slice(-2) === userCountryCode;
      if (countryCodeException === userCountryCode && !isSpaceNameAlreadyHasCountryCode) {
        try {
          const spaceNameWithUserCountryCode = spaceName.concat(userCountryCode);
          const space = await getSpace(spaceNameWithUserCountryCode).unwrap();
          setSpaceNameState({ spaceName: space.name.replaceAll('_', ' '), isPartner: true, isProcessed: true });
          navigate(`/${space.name.replaceAll('_', ' ')}?partnerID=${partnerId}`);
        } catch (error) {
          setSpaceNameState({ spaceName: '', isPartner: true, isProcessed: true });
          toast.error(<Answer label="Oops" subtext={`Space for ${countryCodeException} was not found`} />);
        }
      } else {
        setSpaceNameState({ spaceName: '', isPartner: false, isProcessed: true });
      }
    } else {
      navigate(ROUTES.AUTH);
    }
  }, [getSpace, isFetchingGeolocation, partnerId, geolocationData?.country_code]);

  useEffect(() => {
    checkAndRedirect();
  }, [checkAndRedirect]);

  useEffect(() => {
    if (!spaceNameState.spaceName && spaceNameState.isProcessed) {
      navigate(ROUTES.COURSES_CONSTRUCTOR);
    }
  }, [spaceNameState.spaceName, spaceNameState.isProcessed]);

  return spaceNameState;
};
