// universal text component for UI kit based on styled components

import styled from 'styled-components';
import { motion } from 'framer-motion';

import { IGeneralProps } from './types';
import { UIParent } from './parent';

interface IProps extends IGeneralProps {
  fontWeight?: string;
  color?: string;
  textAlign?: string;
  fontSize?: string; // Add the fontSize property
}

export const Text = styled(UIParent).attrs<IProps>(({ as = 'p' }) => ({ as }))<IProps>`
  font-size: ${({ fontSize }) => fontSize || '1rem'};
  font-weight: ${({ fontWeight }) => fontWeight || 'normal'};
  color: ${({ color }) => color || 'black'};
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

export const MotionText = motion(Text);
