import { USER_ROLES } from 'app/constants';
import { useGetUserRelatedSpaceInfo } from 'components/pages/spaces/hooks';
import { useGetUserData } from 'hooks';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ISpace, useLazyGetSpaceByNameQuery } from 'store';

export const useGetIfUserCanEditCourse = (authors: string[]) => {
  const [space, setSpace] = useState<ISpace | null>(null);
  const { _id: userId, roles } = useGetUserData();

  const { spaceName } = useParams();
  const namePrep = spaceName?.replaceAll('_', ' ') || '';
  const [getSpace, { isFetching: isSpaceLoading }] = useLazyGetSpaceByNameQuery();

  useEffect(() => {
    if (namePrep)
      getSpace(namePrep)
        .unwrap()
        .then((res) => setSpace(res));
  }, [namePrep, getSpace]);

  const { isAbleToEdit } = useGetUserRelatedSpaceInfo(space);
  const isAllowed = useMemo(
    () => authors.includes(userId) || (roles && roles.includes(USER_ROLES.ADMIN)) || isAbleToEdit,
    [authors, userId, roles, isAbleToEdit]
  );

  return { isAllowed, isSpaceLoading };
};
