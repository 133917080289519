import clsx from 'clsx';

import { IDescription } from 'interface/courses';
import { getAppSettings, getCurrentLanguage } from 'store';
import useAverageColorFromImageUrl from 'hooks/useAverageColorFromImageUrl';
import { useSelector } from 'react-redux';
import { useDisplay, useGetUserData } from 'hooks';

import BasicButton from 'shared/ui/basic-button/button';
import SideChapters from '../../material-pages/side-panel/SideChapters';
import { HorizontalScrollContainer } from 'shared/ui';
import { Logo } from 'shared/utils';

import { ReactComponent as Clock } from 'assets/icons/clock.svg';
import { ReactComponent as Tests2 } from 'assets/icons/tests2.svg';
import { ReactComponent as Lessons } from 'assets/icons/learn.svg';
import { HiArrowLeft } from 'react-icons/hi';
import { ReactComponent as MobileBg } from 'assets/backgrounds/mobile-modal-bg.svg';

import styles from './styles.module.scss';

const Details = ({ className = '', cover, course, seeChaptersList, setSeeChaptersList, goToCourse }: any) => {
  const { displayedName } = useSelector(getAppSettings);
  const { resultCourses } = useGetUserData();
  const lang = useSelector(getCurrentLanguage);
  const { isMobile } = useDisplay();
  const color = useAverageColorFromImageUrl(cover, { opacity: '1' });
  const isStarted = resultCourses?.some((res) => res?.courseId === course?._id);

  if (!course?.chapters) {
    return null;
  }

  const estimatedTimeClean = course?.estimatedTime?.toString();
  const [timeValue, timeUnits] = estimatedTimeClean ? estimatedTimeClean.split(' ') : ' '.split(' ');

  return (
    <div className={clsx(styles.details, { [className]: !!className })}>
      {
        [
          <>
            <div className={styles.coverWrap}>
              <img src={cover} className={styles.cover} alt={course.name} />
            </div>
            <h4 className={styles.name}>{course.name}</h4>
            <HorizontalScrollContainer className={clsx(styles.stats, styles.horizontalList)}>
              <div className={styles.stat}>
                <div className={styles.icon}>
                  <Clock />
                </div>
                <div className={styles.data}>
                  <p className={styles.value}>~{timeValue}</p>
                  <p className={styles.title}>{timeUnits}</p>
                </div>
              </div>
              <div className={styles.stat}>
                <div className={styles.icon}>
                  <Tests2 />
                </div>
                <div className={styles.data}>
                  <p className={styles.value}>{course.lessonsCount}</p>
                  <p className={styles.title}>lessons</p>
                </div>
              </div>
              <div className={styles.stat}>
                <div className={styles.icon}>
                  <Lessons />
                </div>
                <div className={styles.data}>
                  <p className={styles.value}>{course.testsCount}</p>
                  <p className={styles.title}>tests</p>
                </div>
              </div>
            </HorizontalScrollContainer>
            <p className={styles.desc}>
              {course.description.find((i: IDescription) => i.language === lang)?.title ||
                course.description.find((i: IDescription) => i.title)?.title}
            </p>
            {!isMobile && (
              <div className={styles.seeChapters}>
                <span onClick={() => setSeeChaptersList(true)}>See Chapter List</span>
              </div>
            )}
            <div className={styles.authors}>
              <h4>Authors:</h4>
              <HorizontalScrollContainer className={clsx(styles.authorsContainer, styles.horizontalList)}>
                <div className={styles.author}>
                  <Logo containerClassName={styles.logo} size="xsmall" width={48} />
                  <div>
                    <div className={styles.authorName}>
                      {displayedName} Originals {<Logo size="xsmall" width={20} />}
                    </div>
                    <div className={styles.editedDate}>Edited {new Date(course.createdAt).toLocaleDateString('en-US')}</div>
                  </div>
                </div>
                <div className={styles.author}>
                  <Logo containerClassName={styles.logo} size="xsmall" width={48} />
                  <div>
                    <div className={styles.authorName}>
                      {displayedName} Originals {<Logo size="xsmall" width={20} />}
                    </div>
                    <div className={styles.editedDate}>Edited {new Date(course.createdAt).toLocaleDateString('en-US')}</div>
                  </div>
                </div>
              </HorizontalScrollContainer>
            </div>
            <BasicButton onClick={goToCourse} className={styles.start} label={isStarted ? 'Continue' : 'Start to learn'} variant="cool" />
          </>,
          <>
            <div className={styles.contentsBtn} role="button" onClick={() => setSeeChaptersList(false)}>
              <HiArrowLeft size={30} />
              <p className={styles.modalHeader}>Contents</p>
            </div>
            <SideChapters toggleIsOpen={false} onCloseToggle={false} course={course} isToggle={false} className={styles.chapters} />
          </>,
        ][seeChaptersList ? 1 : 0]
      }
      {isMobile && <MobileBg className={styles.courseBg} color={color} />}
    </div>
  );
};

export default Details;
