import styled, { css } from 'styled-components';
import editIcon from 'assets/icons/EditIcon.svg';

export const Textarea = styled.textarea(
  () => css`
    min-width: 100%;
    height: fit-content;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    border-color: transparent;
    resize: none;
    line-height: 100%;
    letter-spacing: 0.5px;
    color: var(--font-color-strong);
    font-size: 18px;
    padding: 27px 36px 30px 65px;
    min-height: 176px;
    background-image: url('${editIcon}');
    background-repeat: no-repeat;
    background-position-x: 36px;
    background-position-y: 24px;
    background-blend-mode: difference;
    transition: all 0.25s ease-in-out;

    &:focus {
      outline: none;
      border-color: #6487ef;
      background: rgba(100, 135, 239, 0.1);
      padding-left: 36px;
      background-position-y: 24px;
    }
    &.light {
      border: var(--basic-border);
      background-color: rgba(var(--mighty-blue--rgb), 0.1);
    }
    &.light:focus {
      border-color: rgba(67, 27, 224, 0.35);
    }

    &.right-true {
      border: 1px solid #29ab88;
      background-color: rgba(var(--basic-green--rgb), 0.1);
    }

    &.right-false {
      border: 1px solid #f14f63;
      background-color: rgba(241, 79, 99, 0.1);
    }
    @media screen and (max-width: 992px) {
      max-width: 100%;
    }
  `
);
