import { Content } from '@tiptap/react';

export const parseIfJson = <T = Record<string, any>>(value?: Content): T | null => {
  try {
    if (!value) {
      return null;
    }

    const parsedContent = JSON.parse(value.toString());
    return parsedContent;
  } catch (e) {
    return null;
  }
};

export const deepCopy = <T>(obj: T): T => {
  return JSON.parse(JSON.stringify(obj));
};

export const isObjectsEquals = (obj1: unknown, obj2: unknown) => {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
};
