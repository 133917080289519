import { useState } from 'react';
import { getIsMainMenu, setIsMainMenu } from 'store';
import { shallowEqual, useSelector } from 'react-redux';
import { dispatch } from 'hooks';
import { EMenuScreens } from 'app/constants';

import { ReactComponent as Right } from '../images/Interface/NavRight.svg';
import { ReactComponent as Left } from '../images/Interface/NavLeft.svg';
import { ReactComponent as Settings } from './assets/pictures/settings.svg';
import { ReactComponent as Bell } from './assets/pictures/bell.svg';

import SearchMain from './Search/SearchMain';
import Notifications from '../notifications/Notifications';
import Menu from 'components/pages/menu/Menu';
import UserMenu from './UserMenu/usermenu';
import MiniUser from './MiniUser/widget';
import { Logo } from 'shared/utils';

import './styles/navbar-style.css';
import { Nav, NavIconWrapper, NavWrapper, SearchbarWrapper } from './styles';
import { useGetInitialNotifsCount } from './hooks/useGetInitialNotifsCount';

export const NavbarAuth = () => {
  const [openNotifications, setOpenNotifications] = useState(false);
  const isMainMenu = useSelector(getIsMainMenu, shallowEqual);
  const { counter, refetch } = useGetInitialNotifsCount();

  const toggleMainMenu = (screen: EMenuScreens) => {
    dispatch(setIsMainMenu({ isMainMenu: !isMainMenu, screen }));
  };

  const handleOpenNotifModal = () => {
    setOpenNotifications(true);
    refetch();
  };

  return (
    <NavWrapper className="navbar">
      <Logo withLabel height={38} size="xsmall" />
      <SearchbarWrapper>
        <Left style={{ margin: '0px', minWidth: 24 }} />
        <Nav className="glass-div">
          <SearchMain />
          <NavIconWrapper
            className={`nav-notifications ${counter > 0 && !openNotifications ? 'show-nots' : ''} bright--hover ${openNotifications && 'active'}`}
            onClick={handleOpenNotifModal}
            data-nots={openNotifications ? null : counter > 99 ? '99' : counter}
          >
            <Bell />
          </NavIconWrapper>
          <NavIconWrapper className="bright--hover" onClick={() => toggleMainMenu(EMenuScreens.SETTINGS)}>
            <Settings className="settings" />
          </NavIconWrapper>
        </Nav>
        <Right style={{ margin: '0px', minWidth: 24 }} />
        <Notifications isOpen={openNotifications} onClose={() => setOpenNotifications(false)} />
      </SearchbarWrapper>
      <MiniUser />
      <UserMenu setActiveAndShow={toggleMainMenu} />
      <Menu showMenu={isMainMenu} setShowMenu={toggleMainMenu} />
    </NavWrapper>
  );
};
