import styled, { css } from 'styled-components';
import alertIcon from 'assets/icons/alert__semi-rounded.svg';
import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';

export const Wrap = styled.div(
    () => css`
      --output-width: 484px;
      --gap: 30px;
      --width: 772px;
      --output-width: 400px;
      --transition: all 0.4s ease-in-out;
      width: 100%;
      position: relative;

      .editor {
        transition: width 0.4s ease-in-out;
      }

      &.user-mode.open {
        display: flex;
        gap: var(--gap);

        .editor.open {
          width: var(--width);
          min-width: var(--width);
          max-width: var(--width);
        }
        .output.open {
          height: 100%;
          position: absolute;
          width: var(--output-width);
          min-width: var(--output-width);
          max-width: var(--output-width);
        }

        @media screen and (min-width: 992px) {
          --width: 510px;
          --output-width: 340px;
          .editor.open {
            margin-left: 50px;
          }
          .output.open {
            right: -10px;
          }
        }
        @media screen and (min-width: 1240px) {
          --width: 650px;
          --output-width: 340px;
          .editor.open {
            margin-left: 0;
          }
          .output.open {
            right: -100px;
          }
        }
        @media screen and (min-width: 1380px) {
          --width: 672px;
          --output-width: 400px;
          .output.open {
            right: -180px;
          }
        }

        @media screen and (min-width: 1560px) {
          --width: 772px;
          --output-width: 400px;

          .output.open {
            right: -280px;
          }
        }
      }

      &.user-mode.mobile.open {
        transform: none;
        width: 100%;
        .editor.open {
          --width: 100%;
          flex: 1;
        }
        .output.open {
          width: auto;
          flex: 1;
        }
      }
    `
  ),
  OutputContainer = styled.div(
    () => css`
      display: none;

      &.open {
        display: flex;
        flex-direction: column;
        gap: 15px;
        background: rgb(51, 51, 55);
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 40vh;
        z-index: 2;
        padding: 20px;
        border-radius: 20px 20px 0 0;

        &.light {
          background: white;
          border: 2px solid rgb(100, 135, 239, 0.4);
        }

        > button {
          width: 100%;
          border-radius: 5px;
          color: white;
          background-color: var(--mighty-blue);
        }

        &.mobile {
          height: fit-content;
          max-height: 40vh;
          border-radius: 10px;
        }
      }
    `
  ),
  TextRoot = styled.div<{ $theme?: THEMES; $isShadowed?: boolean }>(
    ({ $theme, $isShadowed }) => css`
      width: 100%;
      line-height: 1.42;
      background: #000000;
      border-radius: 10px;
      font-family: 'Inconsolata', monospace;
      box-shadow: -50px 0px 100px 0px ${$isShadowed ? ($theme === THEMES.LIGHT ? '#FFFFFF99' : '#00000066') : 'transparent'};
    `
  ),
  CodeHeader = styled.div(
    () => css`
      border-bottom: 1px solid rgba(255, 255, 255, 0.15);
      width: 100%;
      height: 48px;
      max-height: 48px;
      margin: 0;

      & > div {
        height: 100%;
        display: inline-flex;
        align-items: center;
        padding: 0 16px;
        position: relative;

        span {
          display: inline-flex;
          align-items: center;
          gap: 5px;
          color: #ffffff;
          line-height: 1;
        }

        &.consoleHeaderTab__active:after {
          content: '';
          position: absolute;
          background-color: #ffffff;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 1px;
        }
      }
    `
  ),
  IconButton = styled.button(
    () => css`
      border: none;
      background-color: rgba(255, 255, 255, 0.1);
      border-radius: 5px;
      transition: background-color 0.15s ease-in-out;
      color: #fff;
      width: 30px;
      height: 30px;
      padding: 3px 0 0 0;
      margin-left: 10px;

      svg {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    `
  ),
  TypeText = styled.div(
    () => css`
      width: 100%;
      height: fit-content;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding: 20px;

      & > div {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        gap: 10px;
      }

      & > span {
        background: inherit;
        color: inherit;
        line-height: 2;
        width: 100%;
      }

      & > textarea {
        padding: 0;
        width: 100%;
        height: fit-content;
        text-align: left;
        outline: none;
        border: none;
        resize: none;
        background: inherit;
        color: inherit;
        line-height: 2;
      }
      & pre {
        line-break: anywhere;
        white-space: break-spaces;
        overflow: auto;
        max-width: 100%;
      }

      &::before {
        width: 20px;
        height: 100%;
        display: flex;
        margin-right: 10px;
      }

      &.warning {
        background: linear-gradient(77.35deg, rgba(255, 225, 82, 0.2) 0.89%, rgba(255, 212, 53, 0.2) 52.67%, rgba(255, 211, 65, 0.2) 128.34%);
        border-radius: 10px;
        padding: 9px 25px 14px 9px;
      }

      &.warning p {
        color: var(--font-color-strong);
      }

      &.warning::before {
        content: url(${alertIcon});
      }

      &.bold {
        font-weight: 900;
        padding: 10px 0;
      }

      &.code {
        font-family: 'Inconsolata', monospace;
        letter-spacing: 0.5px;
        background: #000;
        border-radius: 0 0 10px 10px;
        padding: 16px;

        textarea {
          max-height: 300px;
        }

        pre {
          max-height: 300px;
          width: 100%;
        }

        span,
        textarea,
        input {
          color: white;
        }
      }

      &.code__webEditor {
        padding-left: 0;
      }

      & .inline-answers-v2 {
        background: none;
        padding: inherit;

        &.code {
          color: white;
        }
      }

      & .nodes-container {
        padding: 0;
        background: none;
        line-height: 1;
        white-space: pre-wrap;

        span {
          display: inline-flex;
          align-items: center;
          justify-content: center;
        }
      }
    `
  ),
  CloseButton = styled(motion.div)`
    // temp till MotionFlex fix
    position: absolute;
    z-index: 20;
    left: -84px;
    top: 50%;
    translate: 0 -50%;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #ffffff26;
    backdrop-filter: blur(10px);
    cursor: pointer;
    & > svg {
      width: 37px;
      height: 37px;
      color: var(--font-color-strong);
    }
  `;
