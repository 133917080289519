import { FC } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';
import { IItem } from '../ChangerList/types/types';

// import { ReactComponent as Times } from 'assets/icons/times.svg';
// import { ReactComponent as Pencil } from 'assets/icons/pencil.svg';

import Nickname from 'shared/ui/nickname/Nickname';
import InventoryItem from 'components/library/cards/inventory/InventoryItem';
import { SettingsCard, SettingsCardWrapper } from 'shared/ui';

import styles from './styles.module.scss';

interface IProps {
  item: IItem;
  onClick: () => void;
}

const ChangerItem: FC<IProps> = ({ item, onClick }) => {
  const { customization } = useSelector(getUserData);
  return (
    <div>
      {/*<div className={styles.title}>{item.title}</div>*/}
      {/*<div className={styles.wrap}>*/}
      {/*  <div className={clsx(styles.circle, styles.default)} style={{ background: item.circleColor }} onClick={onClick}>*/}
      {/*    {item.title === 'Name color' && <Pencil />}*/}
      {/*    {item.title === 'Name font' && <Nickname nickname={'abc'} customization={customization} className={styles.fontSize} />}*/}
      {/*  </div>*/}
      {/*  <Times className={styles.separator} />*/}
      {/*  <InventoryItem itemId={item.inventoryItem} amount={item.amount} isSmall isShowTooltip={false} />*/}
      {/*</div>*/}
      <SettingsCardWrapper className={styles.cardWrapper} onClick={onClick}>
        <SettingsCard
          title={item.title}
          rightIcon={
            item.title === 'Name font' ? (
              <div className={clsx(styles.circle, styles.default)}>
                <Nickname nickname={'abc'} customization={customization} className={styles.fontSize} />
              </div>
            ) : (
              <InventoryItem
                itemId={item.inventoryItem}
                amount={item.quantity}
                isAmountShown={false}
                isSmall
                isShowTooltip={false}
                navigateOnClick={false}
                className={styles.item}
              />
            )
          }
        />
      </SettingsCardWrapper>
    </div>
  );
};

export default ChangerItem;
