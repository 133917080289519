import { useDisclosure } from 'hooks';
import { createContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Drawer from 'shared/ui/drawer/Drawer';
import { ISpace } from 'store';
import { useGetUserRelatedSpaceInfo } from '../../hooks';
import CoursesConstructorChaptersList from 'pages/coursesConstructor/parts/chaptersList';
import CoursesConstructorLessonsPage from 'pages/coursesConstructor/CoursesConstructorLessonsPage';
import CoursesConstructorSlidesPage from 'pages/coursesConstructor/CoursesConstructorSlidesPage';

type Props = {
  space: ISpace | null;
};

export const SpaceCourseConstructorContext = createContext<ISpace | null>(null);

const SpaceCourseConstructor = ({ space }: Props) => {
  const { isAbleToEdit } = useGetUserRelatedSpaceInfo(space || null);
  const [isFullscreen, setFullscreen] = useState(false);
  const { lessonId, chapterId, courseId } = useParams();
  const navigate = useNavigate();
  const { open, close, isOpen } = useDisclosure(false, {
    onClose: () => navigate('/' + (space && space.name ? space.name.replaceAll(' ', '_') : '')),
    onOpen: () => {},
  });

  useEffect(() => {
    if (lessonId || chapterId || courseId) {
      open();
    }
  }, [lessonId, chapterId, courseId]);

  useEffect(() => {
    if (isOpen && !!space && !isAbleToEdit) {
      close();
      navigate('/' + space.name.replaceAll(' ', '_'));
    }
    if (!isOpen) {
      setFullscreen(false);
    }
  }, [isOpen, space, isAbleToEdit]);

  const content = useMemo(() => {
    if (courseId) {
      return <CoursesConstructorChaptersList />;
    }
    if (chapterId) {
      return <CoursesConstructorLessonsPage />;
    }
    if (lessonId) {
      return <CoursesConstructorSlidesPage />;
    }

    return null;
  }, [lessonId, chapterId, courseId]);

  if (!isAbleToEdit) {
    return null;
  }

  return (
    <Drawer
      width={isFullscreen ? '100%' : !!lessonId ? '1300px' : '1000px'}
      isVisible={isOpen}
      position="right"
      onToggleFullPage={() => setFullscreen((s) => !s)}
      onClose={close}
    >
      <SpaceCourseConstructorContext.Provider value={space}>{content}</SpaceCourseConstructorContext.Provider>
    </Drawer>
  );
};

export default SpaceCourseConstructor;
