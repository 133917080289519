import { coursesApi } from 'store';
import { ErrorHandler } from 'shared/utils/ErrorHandler';
import { ICourseFull, IResultCourse } from 'interface/courses';

const userProfileLoader = (store: any, dispatch: any) => async () => {
  const userId = store.getState().profile.user._id;

  if (userId) {
    const coursesStartedFromLoader = dispatch(coursesApi.endpoints.getStartedCourses.initiate({ userId }));

    try {
      const responseCourses: Promise<{ course: ICourseFull; resultCourse: IResultCourse }> = coursesStartedFromLoader
        .unwrap()
        .catch(ErrorHandler)
        .catch(ErrorHandler);

      return {
        coursesStartedFromLoader: responseCourses,
      };
    } catch (e) {
      throw new Error('An error occurred while loading profile.');
    } finally {
      coursesStartedFromLoader.unsubscribe();
    }
  }
  return {};
};

export default userProfileLoader;
