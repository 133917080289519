import { FC } from 'react';
import clsx from 'clsx';

import { ITogglerProps } from './types';

import * as Styled from './styled';

export const Toggler: FC<ITogglerProps> = ({ options, className = '', value, onChange, color = 'red' }) => {
  return (
    <Styled.SToggler className={className} $color={color}>
      {options.map((option) => (
        <div key={option.value} className={clsx(value === option.value && 'toggler__option-active')} onClick={() => onChange(option.value)}>
          <span>{option.label}</span>
        </div>
      ))}
    </Styled.SToggler>
  );
};
