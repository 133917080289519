import { useEffect, useRef, useState } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getAppSettings, getCurrentTheme } from 'store';

import { ReactComponent as Circle } from 'assets/images/loader-ellipse.svg';

import styles from './styles.module.css';
import { Logo } from 'shared/utils';

interface IProps {
  className?: string;
  isLabel?: boolean;
  width?: string | number;
  height?: string | number;
}

export default function LogoLoader({ className = '', isLabel = true, width, height }: IProps) {
  const theme = useSelector(getCurrentTheme);
  const [fontSize, setFontSize] = useState<number>(34);
  const { displayedName } = useSelector(getAppSettings);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (ref.current) {
        const { width } = ref.current.getBoundingClientRect();
        setFontSize(Math.floor(width / 12));
      }
    }, 100);
    return () => clearTimeout(timeout);
  }, [ref]);

  if (displayedName === 'blank') {
    return <></>;
  }

  return (
    <div className={clsx(styles.container, styles[theme], { [className]: !!className })} style={{ width, height }} ref={ref}>
      <Circle className={clsx(styles.ellipse, 'spinner')} />
      <Logo containerClassName={styles.logo} />
      {isLabel && (
        <p style={{ fontSize }} className={styles.text}>
          {displayedName}
        </p>
      )}
    </div>
  );
}
