import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      padding-bottom: 24px;
      font-size: 18px;
    `
  ),
  Header = styled.h5(
    () => css`
      font-size: inherit;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.5px;
      color: var(--font-color-strong);
    `
  );
