import { useGetSubscribersQuery } from 'store';
import { useGetUserRelatedSpaceInfo } from '../../hooks';
import { LANGS, THEMES } from 'app/constants';
import { useAveregeColorWText } from '../hooks';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import { shortenLink } from '../utils';
import { useSpacePageContext } from '../../context';

import { SpaceFollowButton } from '../../SpaceCreation/components/followButton';
import SpaceTopContentMobile from './TopContentMobile';
import { ScreensMenu } from '../components';

import * as S from './styles';
import * as Loaders from './loaders.styles';
import { useMemo } from 'react';

type Props = {
  coursesLength?: number;
};

const SpacePageTop = ({ coursesLength }: Props) => {
  const { isMobile, viewportWidth } = useDisplay();
  const { space, setModal } = useSpacePageContext();
  const { data: followers } = useGetSubscribersQuery(space?._id || '', { skip: !space });
  const theme = useGetCurrentTheme();
  const { color, textColor, isBright } = useAveregeColorWText({
    imagePath: space?.imageThumb || '',
    options: { defaultColor: theme === THEMES.DARK ? [255, 255, 255, 0.1] : [17, 17, 17, 0.1] },
  });

  const { isAbleToEdit } = useGetUserRelatedSpaceInfo(space || null);

  const description = space?.description.find((el) => el.language === LANGS.EN)?.title || '';
  const isMenuOn = useMemo(() => !!space && isAbleToEdit && !isMobile, [space, isAbleToEdit, isMobile]);

  return (
    <S.SpaceContainer>
      {!!space ? <S.SpaceBG $bg={space?.image} /> : <Loaders.BGLoader />}
      <S.Tags $color={textColor} style={{ justifyContent: viewportWidth > 400 ? 'flex-end' : 'flex-start' }}>
        {!!space ? (
          <>
            {space.tags.map((el, i) =>
              i > 1 && isMobile ? null : (
                <S.Tag
                  $theme={theme}
                  $lightBg={!color ? 'rgba(255,255,255,0.1)' : color === 'rgba(255,255,255,1)' ? 'rgba(255,255,255,0.1)' : color}
                  key={i + el}
                >
                  <S.TagText>{el}</S.TagText>
                </S.Tag>
              )
            )}
          </>
        ) : (
          <>
            <Loaders.TagLoader />
            <Loaders.TagLoader />
          </>
        )}
      </S.Tags>
      <S.SpaceInfo $isLoading={!space} $color={color !== 'rgba(255,255,255,1)' ? color : ''} $theme={theme}>
        {isMobile ? (
          <SpaceTopContentMobile
            {...{
              isAbleToEdit,
              space,
              textColor,
              theme,
              viewportWidth,
              color,
              followers,
              isBright,
              isMobile,
              setModalVisible: () => setModal('edit'),
              coursesLength,
            }}
          />
        ) : (
          <S.SpaceInfoTop>
            <S.ImageBox $isLoading={!space} color={color !== 'rgba(255,255,255,1)' ? color : ''}>
              {!!space ? <S.Image $dropShadow={isBright && theme !== THEMES.DARK} src={space.imageThumb} /> : <Loaders.ImageLoader />}
            </S.ImageBox>
            <S.Name $color={textColor} $isVerified={space?.isVerified}>
              {!!space ? (
                <>
                  <span>{space.name}</span>{' '}
                  <S.Link rel={'noreferrer'} href={space.link} target={'_blank'}>
                    {shortenLink(space.link)}
                  </S.Link>
                </>
              ) : (
                <>
                  <Loaders.Name />
                  <Loaders.Link />
                </>
              )}
            </S.Name>
            <div style={{ display: 'flex', gap: 14, alignItems: 'center' }}>
              <S.InfoContainer>
                <S.InfoBox>
                  {!!space ? (
                    <>
                      <S.InfoNum $color={textColor}>{followers ? followers.items.length : '...'}</S.InfoNum>
                      <S.InfoTopic $color={textColor}>Students</S.InfoTopic>
                    </>
                  ) : (
                    <>
                      <Loaders.Num />
                      <Loaders.Sub />
                    </>
                  )}
                </S.InfoBox>
                <S.InfoBox>
                  {!!space ? (
                    <>
                      <S.InfoNum $color={textColor}>{coursesLength}</S.InfoNum>
                      <S.InfoTopic $color={textColor}>Courses</S.InfoTopic>
                    </>
                  ) : (
                    <>
                      <Loaders.Num />
                      <Loaders.Sub />
                    </>
                  )}
                </S.InfoBox>
              </S.InfoContainer>
              <S.SpaceEditBox animate={{ y: 0, opacity: 1 }} initial={{ y: 20, opacity: 0 }} exit={{ y: 20, opacity: -1 }}>
                {!isAbleToEdit && !!space && <SpaceFollowButton large={!isMobile} spaceId={space._id} />}
                <S.OpenLink $color={textColor} onClick={() => window.open(space?.link || '')} />
              </S.SpaceEditBox>
            </div>
          </S.SpaceInfoTop>
        )}
        <S.SpaceInfoBottom>
          {!!space ? (
            <S.Description $color={textColor}>{description}</S.Description>
          ) : (
            <div style={{ width: '100%' }}>
              <Loaders.Desc />
              <Loaders.Desc />
            </div>
          )}
          {isMenuOn && <ScreensMenu textColor={textColor} onSpaceEditorOpen={() => setModal('edit')} onAnalyticsOpen={() => setModal('analytics')} />}
        </S.SpaceInfoBottom>
      </S.SpaceInfo>
    </S.SpaceContainer>
  );
};

export default SpacePageTop;
