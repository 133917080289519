import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div<{ $theme: THEMES }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  overflow: hidden;
`;

export const Path = styled.div<{ $theme: THEMES }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  border-radius: 2px;
  height: 28px;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? '#1111111a' : '#1c1d26')};
  font-size: 16px;
  font-weight: 400;
  color: white;
  width: fit-content;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.8;
  }
`;

export const Box = styled.div<{ $theme: THEMES }>`
  display: flex;
  flex-direction: column;
  /* gap: 20px; */
  padding: 26px 20px;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? '#11111112' : '#ffffff12')};
  border-radius: 4px;
`;

export const ProfileBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;

export const ProfileInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
`;

export const Nickname = styled.p<{ $theme: THEMES }>`
  font-size: 16px;
  font-weight: 500;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111111' : '#ffffff')};
  line-height: 100%;
`;

export const Mail = styled.p<{ $theme: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#11111166' : '#afb4b8')};
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
`;

export const Date = styled.p<{ $theme: THEMES }>`
  opacity: 40%;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111111' : '#afb4b8')};
  font-size: 14px;
  line-height: 100%;
  font-weight: 40;
`;

export const FlexColBox = styled.div<{ nm?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: ${(p) => (p.nm ? 0 : '20px')};
`;

export const Footer = styled(motion.div)`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-top: 20px;
`;

const contentColors = {
  [THEMES.LIGHT]: {
    question: '#627eea;',
    answer: '#111',
  },
  [THEMES.DARK]: {
    question: '#B1BFF5',
    answer: '#FFFFFF',
  },
};

const contentBgs = {
  [THEMES.LIGHT]: {
    question: '#627EEA40',
    answer: '#FFFFFF26',
  },
  [THEMES.DARK]: {
    question: '#627EEA40',
    answer: '#FFFFFF26',
  },
};

export const Content = styled.div<{ isQuestion?: boolean; $theme: THEMES }>`
  color: ${(p) => (p.isQuestion ? contentColors[p.$theme].question : contentColors[p.$theme].answer)};
  background: ${(p) => (p.isQuestion ? contentBgs[p.$theme].question : contentBgs[p.$theme].answer)};

  border-radius: 4px;
  padding: 12px;
  font-size: 16px;
  line-height: 20px;
`;
export const Label = styled.p<{ $theme: THEMES }>`
  text-transform: uppercase;
  color: #6f7279;
  font-size: 14px;
  font-weight: 400;
`;
export const RewardsPreview = styled.div`
  display: flex;
  gap: 15px;
  align-items: center;
  width: 100%;
`;

export const RewardItem = styled.div<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: 24px;
  line-height: 100%;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111111d0' : 'white')};
`;
export const RewardIcon = styled.img`
  width: 40px;
  height: 40px;
  object-fit: contain;
`;

export const EditButton = styled.button<{ $theme: THEMES }>`
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 28px;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111111d0' : 'white')};
  font-weight: 400;
  gap: 5px;
  background: #ffffff1a;
  padding: 0px 8px;
  border-radius: 4px;
  margin-left: auto;
`;

export const EditList = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  gap: 8px;
`;
export const RewardEdit = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const RewardQuantityEdit = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 32px;
  min-width: 105px;
  color: white;
  gap: 10px;
  background: #ffffff1a;
  border-radius: 4px;
  padding: 0px 6px;
`;
export const QuantityInput = styled.input<{ $theme: THEMES }>`
  border: none;
  outline: none;
  padding: 0;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111111d0' : 'white')};
  font-size: 24px;
  line-height: 100%;
  width: 35px;
  text-align: center;
  background: none;
`;

export const AddBtn = styled.div<{ $disabled?: boolean }>`
  cursor: pointer;
  width: 19px;
  height: 19px;
  background: #d9d9d94d;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.1s;
  &:hover {
    filter: brightness(1.2);
  }
  &:active {
    filter: brightness(0.9);
  }
  ${({ $disabled }) => $disabled && 'pointer-events: none; opacity: 0.5;'}
  & > svg {
    width: 60%;
    height: auto;
  }
`;
export const Buttons = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 18px;
`;
