import { createSelector, createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { IProfile } from 'interface';
import { RootStateType } from 'store';
import { profileApi } from './profile.api';
import { authApi } from '../auth/auth.api';
import { coursesApi } from '../course/course.api';
import { shopApi } from '../shop/shop.api';
import { ITEM_IDS, LOCAL_STORAGE_KEYS, STANDARD_ENERGY_FEE } from 'app/constants';
import { expTokenItem } from 'utils/items';
import { AVATAR_SHAPES } from 'app/constants';
import { tokenService } from 'services';
import { IResultCourse } from 'interface/courses';

interface IInitialState {
  user: IProfile;
  isLoaded: boolean;
}

const initialState: IInitialState = {
  user: {} as IProfile,
  isLoaded: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUserEnergy: (state, action: PayloadAction<number>) => {
      state.user.exp.energy = action.payload;
    },
    setUserEvents: (state, action: PayloadAction<any>) => {
      state.user.history.events = action.payload;
    },
    setUserInterests: (state, action: PayloadAction<any>) => {
      state.user.interests = action.payload;
    },
    setAddOneToUserResultCourses: (state, action: PayloadAction<IResultCourse>) => {
      state.user.resultCourses = [...state.user.resultCourses, action.payload];
    },
    setCurrentAvatarShape: (state, action: PayloadAction<AVATAR_SHAPES>) => {
      localStorage.setItem(LOCAL_STORAGE_KEYS.AVATAR_SHAPE, action.payload);
      state.user.customization.avatarShape = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(authApi.endpoints.logOut.matchFulfilled, (state, _) => {
        state.user = {} as IProfile;
      })
      .addMatcher(coursesApi.endpoints.checkAnswer.matchFulfilled, (state, action: PayloadAction<any>) => {
        if (action.payload.message !== 'Not enough energy') {
          state.user.exp.energy = action.payload.energy;
        }
      })
      .addMatcher(coursesApi.endpoints.checkAnswer.matchRejected, (state, action) => {
        if (action.payload?.status === 400) {
          state.user.exp.energy -= STANDARD_ENERGY_FEE * 2;
        }
      })
      .addMatcher(coursesApi.endpoints.getCourseForCompletion.matchFulfilled, (state, action) => {
        if (action.payload?.resultCourse && state.user._id) {
          let oldResult = !!state.user.resultCourses.find((el) => el._id === action.payload.resultCourse._id);
          if (!oldResult) {
            state.user.resultCourses = [...state.user.resultCourses, action.payload.resultCourse];
          }
        }
      })
      .addMatcher(coursesApi.endpoints.completeSlide.matchFulfilled, (state, action) => {
        const data = action.payload;
        if (data && state.user._id) {
          if ('energy' in data) {
            state.user.exp.energy = data.energy;
          }
          if (data.resultCourse) {
            const foundOne = state.user.resultCourses.find((el) => el._id === data.resultCourse._id);
            if (!foundOne) {
              state.user.resultCourses = [...state.user.resultCourses, data.resultCourse];
            } else {
              state.user.resultCourses = state.user.resultCourses.map((el) => {
                if (el._id === data.resultCourse._id) {
                  return data.resultCourse;
                }
                return el;
              });
            }
          }
        }
      })
      .addMatcher(coursesApi.endpoints.completeSlide.matchRejected, (state, action) => {
        const data: any = action.payload?.data;
        if (data) {
          if (!!data.energy) {
            state.user.exp.energy = data.energy;
          }
        }
      })
      .addMatcher(coursesApi.endpoints.flushCourse.matchFulfilled, (state, action) => {
        state.user.resultCourses = state.user.resultCourses.filter((course) => course._id !== action.meta.arg.originalArgs.resultCourseId);
      })
      .addMatcher(coursesApi.endpoints.flushMyCourses.matchFulfilled, (state, action) => {
        state.user.resultCourses = [];
      })
      .addMatcher(profileApi.endpoints.readNotifs.matchFulfilled, (state, _) => {
        state.user.history.events = [];
      })
      .addMatcher(profileApi.endpoints.getUserData.matchFulfilled, (state, action: PayloadAction<IProfile>) => {
        state.user = {
          ...action.payload,
          expTokenBalance: action.payload.exp.balance?.amount ?? 0,
        };
        state.isLoaded = true;
      })
      .addMatcher(profileApi.endpoints.toggleFavourite.matchFulfilled, (state, action: PayloadAction<Pick<IProfile, 'favouriteCourses'>>) => {
        state.user.favouriteCourses = action.payload.favouriteCourses;
      })
      .addMatcher(profileApi.endpoints.getUserData.matchRejected, (_, action: any) => {
        if (action?.payload?.data?.message === 'You did not activate your account') {
          tokenService.setRemoveTokens();
        }
      })
      .addMatcher(profileApi.endpoints.refetchResultCourses.matchFulfilled, (state, action) => {
        state.user.resultCourses = action.payload.resultCourses;
      })
      .addMatcher(profileApi.endpoints.refetchFriends.matchFulfilled, (state, action) => {
        state.user.friends = action.payload.friends;
      })
      .addMatcher(profileApi.endpoints.getMyInventory.matchFulfilled, (state, action) => {
        const { exp } = action.payload;
        state.user.exp = exp;
      })
      .addMatcher(profileApi.endpoints.getMyParts.matchFulfilled, (state, action) => {
        Object.keys(action.payload).forEach((key) => {
          if (key in state.user) {
            // @ts-ignore
            state.user[key] = action.payload[key];
          }
        });
      })
      .addMatcher(profileApi.endpoints.refetchExpAfterDailyRewarding.matchFulfilled, (state, action) => {
        const { exp, dailis } = action.payload;
        state.user.exp = exp;
        state.user.dailis = dailis;
        state.user.expTokenBalance = exp.balance.amount;
      })
      .addMatcher(profileApi.endpoints.updateUserDrip.matchFulfilled, (state, action) => {
        const { avatar, nickname } = action.payload;
        if (avatar) {
          state.user.avatar = avatar;
        }
        if (nickname) {
          state.user.nickname = nickname;
        }
      })
      .addMatcher(profileApi.endpoints.customizeMe.matchFulfilled, (state, action) => {
        state.user.customization = action.payload.customization;
        const index = state.user.exp.items.findIndex((i) => i.item.id === action.payload.item.item.id);
        if (index && index >= 0) {
          state.user.exp.items[index] = action.payload.item;
        }
      })
      .addMatcher(profileApi.endpoints.refetchAfterActivityCompletion.matchFulfilled, (state, action) => {
        const { exp, stats, cooldowns } = action.payload;
        state.user.exp = exp;
        state.user.stats = stats;
        state.user.cooldowns = cooldowns;
      })
      .addMatcher(
        isAnyOf(
          profileApi.endpoints.getUserData.matchFulfilled,
          profileApi.endpoints.getMyInventory.matchFulfilled,
          profileApi.endpoints.refetchExpAfterDailyRewarding.matchFulfilled,
          shopApi.endpoints.buyItem.matchFulfilled,
          shopApi.endpoints.activateItem.matchFulfilled,
          shopApi.endpoints.openChest.matchFulfilled
        ),
        (state, _) => {
          if (state?.user?.exp?.items?.length > 0) {
            if (state.user.exp.items[0]?.id !== ITEM_IDS.EXP_TOKEN) {
              state.user.exp.items = [{ ...expTokenItem, quantity: state.user.exp.balance.amount }, ...state.user.exp.items];
            }
          } else {
            state.user.exp.items = [{ ...expTokenItem, quantity: state.user.exp.balance.amount }];
          }
        }
      )
      .addMatcher(shopApi.endpoints.activateItem.matchFulfilled, (state, { payload }) => {
        if (payload.newUserData) {
          state.user.exp = { ...state.user.exp, ...payload.newUserData };
        }
      })
      .addMatcher(shopApi.endpoints.buyItem.matchFulfilled, (state, { payload }) => {
        if (payload.exp) {
          state.user.exp = { ...state.user.exp, ...payload.exp };
        }
      });
  },
});

export const { setUserEnergy, setCurrentAvatarShape, setUserEvents, setUserInterests, setAddOneToUserResultCourses } = profileSlice.actions;

const getUserDataForSelector = (state: RootStateType) => state.profile.user;
const getUserCooldownsForSelector = (state: RootStateType) => state.profile.user.cooldowns;
const getUserEnergyForSelector = (state: RootStateType) => state.profile.user.exp.energy;
const getUserIdForSelector = (state: RootStateType) => state.profile.user?._id;
const getCurrentAvatarShapeForSelector = (state: RootStateType): AVATAR_SHAPES =>
  state?.profile?.user?.customization?.avatarShape ?? AVATAR_SHAPES.ROUND;
const getUserIsLoadedForSelector = (state: RootStateType) => state.profile.isLoaded;

// Мемоизированные селекторы
export const getUserData = createSelector(getUserDataForSelector, (user) => user);
export const getUserCooldowns = createSelector(getUserCooldownsForSelector, (cooldowns) => cooldowns);
export const getUserEnergy = createSelector(getUserEnergyForSelector, (energy) => energy);
export const getUserId = createSelector(getUserIdForSelector, (id) => id);
export const getCurrentAvatarShape = createSelector(getCurrentAvatarShapeForSelector, (avatarShape) => avatarShape);
export const getUserIsLoaded = createSelector(getUserIsLoadedForSelector, (isLoaded) => isLoaded);

export default profileSlice.reducer;
