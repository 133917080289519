import styles from '../styles.module.scss';
import hexToRgba from 'hex-to-rgba';
import { AnimatePresence, AnimateSharedLayout, motion } from 'framer-motion';
import Nickname from 'shared/ui/nickname/Nickname';
import { FC } from 'react';
import { Value } from '../../../ChangerList/types/types';
import { IChanges } from '../../ChangerModal/ChangerModal';

interface Props {
  value: Value;
  currentChange: any;
  customization: any;
  theme: any;
  changes: IChanges;
  nickname: string;
}

const ChangesNickname: FC<Props> = ({ value, currentChange, theme, customization, changes, nickname }) => {
  const variants = {
    slideToCenter: {
      y: -100,
    },
    visible: {
      y: 0,
      opacity: [0, 1],
    },
    slideToBottom: {
      y: 100,
      opacity: [1, 0],
    },
  };

  function currentBg(pos: number): any {
    try {
      const color = hexToRgba(value === 'name' ? currentChange[theme] : customization.name[theme], 0.7);
      return `radial-gradient(70.76% 150.55% at ${pos}% 50.47%, ${color} 0%, rgba(0, 0, 0, 0) 100%)`;
    } catch (error) {
      return `radial-gradient(70.76% 150.55% at ${pos}% 50.47%, var(--font-color) 0%, rgba(0, 0, 0, 0) 100%)`;
    }
  }

  function glowAnimate(): any {
    return {
      background: [currentBg(250), currentBg(-100)],
      transition: {
        type: 'spring',
        stiffness: 100,
        repeat: Infinity,
        duration: 2,
      },
    };
  }

  return (
    <AnimateSharedLayout>
      <motion.div layout className={styles.nickname}>
        <motion.div style={{ willChange: 'background' }} animate={glowAnimate()} className={styles.gradient} />
        <AnimatePresence exitBeforeEnter>
          {currentChange === changes?.newValue[theme] || currentChange === changes?.newValue ? (
            <motion.div
              key={'new'}
              variants={variants}
              initial="slideToCenter"
              animate="visible"
              exit="slideToBottom"
              transition={{ ease: 'linear', duration: 0.6 }}
              style={{ willChange: 'transform' }}
            >
              <Nickname
                nickname={nickname}
                color={value === 'name' && changes?.newValue}
                customization={{ ...customization, font: value === 'font' ? changes?.newValue : customization.font }}
              />
            </motion.div>
          ) : (
            <motion.div
              key={'old'}
              variants={variants}
              initial="slideToCenter"
              animate="visible"
              exit="slideToBottom"
              transition={{ ease: 'linear', duration: 0.6 }}
              style={{ willChange: 'transform' }}
            >
              <Nickname
                nickname={nickname}
                color={value === 'name' && changes?.oldValue}
                customization={{ ...customization, font: value === 'font' ? changes?.oldValue : customization.font }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </motion.div>
    </AnimateSharedLayout>
  );
};

export default ChangesNickname;
