import { RefObject } from 'react';
import { ESlideWidgetTypes } from 'store';

import Text from '@tiptap/extension-text';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';
import TiptapEditor from 'components/library/tiptapEditor';

import Video from '../components/Video';
import File from '../components/File';
import { LazyImage } from 'shared/ui/lazyImage';
import Warning from '../components/Warning';
import TextContent from '../components/Text';
import { buildStaticUrl } from './media-helpers';
import { parseIfJson } from 'utils/object';
import { TFileMetadata } from '../components/types';
import { SelectedText } from 'features/selectedText';
import CodeWidget from 'components/library/courseConstructor/components/Widget/components/CodeWidget';
import { isValidJSON } from 'utils/isValidJSON';

import { TVariantWarningIcon } from 'components/library/courseConstructor/components/Widget/components/WarningWidget/WarningIcons/types';

import styles from '../styles.module.scss';
import TaskWidget, { TTaskWidgetProps } from 'components/library/courseConstructor/components/Widget/components/TaskWidget/TaskWidget';
import QuoteWidget, { TQuoteWidgetprops } from 'components/library/courseConstructor/components/Widget/components/QuoteWidget';

type Props = {
  type: string;
  content: any;
  id: string;
  icon: TVariantWarningIcon;
  color: string;
  image: string;
  link: string;
  author: string;
  isRequired: boolean;
  onTaskComplete: () => Promise<void> | void;
};

export const getContentContainerByType = (props: Props) => {
  switch (props.type) {
    case ESlideWidgetTypes.TEXT:
      return (
        <TiptapEditor
          key={props.id}
          content={props.content}
          extensions={[StarterKit, Text, TextStyle, Color, Underline, Link]}
          bubble={(editor) => <SelectedText editor={editor} id={props.id} />}
          editable={false}
        />
      );
    case ESlideWidgetTypes.WARNING:
      return (
        <Warning key={props.id} color={props.color} type={props.icon} image={props.image}>
          <TiptapEditor
            content={props.content}
            extensions={[StarterKit, Text, TextStyle, Color, Underline, Link]}
            bubble={(editor) => <SelectedText editor={editor} id={props.id} />}
            editable={false}
          />
        </Warning>
      );
    case ESlideWidgetTypes.TASK:
      return <TaskWidget key={props.id} {...(props as TTaskWidgetProps)} editable={false} />;
    case ESlideWidgetTypes.QUOTE:
      return <QuoteWidget key={props.id} {...(props as TQuoteWidgetprops)} editable={false} />;
    case ESlideWidgetTypes.CODE:
      if (isValidJSON(props.content)) {
        return (
          <CodeWidget
            className={'user-mode'}
            key={props.id}
            content={props.content}
            webEditorSetup={{ mode: 'onPressButtonCompile', withOutput: true, withConsole: true, withConstructor: false, readOnly: false }}
            onChange={() => null}
          />
        );
      }
      return (
        <TextContent
          content={props.content}
          key={props.id}
          type={props.type}
          comment={(trigger: RefObject<HTMLDivElement>) => <SelectedText id={props.id} trigger={trigger} />}
        />
      );
    case ESlideWidgetTypes.IMAGE:
      const { align, width } = parseIfJson(props.content) ?? (props.content as TFileMetadata);
      return Boolean(props.content) ? (
        <div key={props.id} className={styles[props.type.toLowerCase()]} style={{ justifyContent: align }}>
          <LazyImage style={{ width: `${Number(width)}%` }} src={buildStaticUrl(props.content)} alt="" />
        </div>
      ) : null;
    case ESlideWidgetTypes.VIDEO:
      return Boolean(props.content) ? (
        <div className={styles.media} key={props.id}>
          <Video meta={props.content} />
        </div>
      ) : null;
    case ESlideWidgetTypes.FILE:
      return Boolean(props.content) ? (
        <div key={props.id}>
          <File meta={props.content} />
        </div>
      ) : null;

    default:
      return null;
  }
};
