//  create a button component for my UI kit

import styled from 'styled-components';
import { NullButton } from '../button';
import { IGeneralProps } from './types';
import { motion } from 'framer-motion';

export const Button = styled(NullButton)<IGeneralProps>`
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;

export const MotionButton = motion(Button);
