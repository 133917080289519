import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { InView } from 'shared/ui/motion';
import { SpaceItem } from './ui';
import * as S from './spaces.styles';
import { ISpace } from 'store';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import { useMemo } from 'react';

type Props = { title: string; moreCols?: boolean; spacesList: ISpace[]; isLoading?: boolean; hideUntilLoaded?: boolean };

const SpacesList = ({ spacesList, title, isLoading, moreCols, hideUntilLoaded }: Props) => {
  const { isMobile } = useDisplay();
  const theme = useGetCurrentTheme();

  const variants = useMemo(
    () => ({
      initial: {
        opacity: 0,
        x: isMobile ? -50 : -220,
      },
      whileInView: {
        opacity: 1,
        x: 0,
      },
      transition: (index: number) => ({ delay: index * 0.02, type: 'spring' }),
    }),
    [isMobile]
  );

  if (!spacesList.length && !isLoading) {
    return null;
  }

  if (isLoading && hideUntilLoaded) {
    return null;
  }

  return (
    <S.Section>
      <S.Subtitle $theme={theme}>{title}</S.Subtitle>
      <S.Grid $moreCols={moreCols}>
        {isLoading ? (
          <MightyLoaderMini />
        ) : (
          <>
            {spacesList.map((el, index) => (
              <InView
                style={{ display: 'inline-grid' }}
                key={el._id.toString()}
                initial={variants.initial}
                whileInView={variants.whileInView}
                forcedOnMobile
                transition={variants.transition(index)}
              >
                <SpaceItem forcedMobile={moreCols} space={el} />
              </InView>
            ))}
          </>
        )}
      </S.Grid>
    </S.Section>
  );
};

export default SpacesList;
