import { FC, PropsWithChildren, ReactNode } from 'react';

import * as Styled from './styled';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';

interface IProps extends PropsWithChildren {
  headSlot?: ReactNode;
  padding?: string;
  background?: string;
  headBorder?: string;
  headPadding?: string;
  className?: string;
}

export const FeatureCard: FC<IProps> = ({ headSlot, children, padding, headBorder, background, headPadding, className = '' }) => {
  const theme = useSelector(getCurrentTheme);

  return (
    <Styled.Wrap className={className}>
      {headSlot && (
        <Styled.Head $border={headBorder} $padding={headPadding}>
          {headSlot}
        </Styled.Head>
      )}
      <Styled.Body className={clsx(theme, 'blur-50')} $padding={padding} $background={background}>
        {children}
      </Styled.Body>
    </Styled.Wrap>
  );
};
