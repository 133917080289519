import { memo, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import clsx from 'clsx';
import { BANNERS_ENTITY, LOCAL_STORAGE_KEYS } from 'app/constants';
import { IMultipurposeBannerSlide } from './types';
import { getCooldown } from 'utils/cooldowns';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';

import { ReactComponent as CrossIcon } from 'assets/components/cross.svg';

import { CircleButton } from '../circle-button';

import stylesBanner from './stylesBanner.module.scss';
import stylesPanel from './stylesPanel.module.scss';

interface IProps {
  isBannerOnly?: boolean;
  isPanelOnly?: boolean;
  horizontalClassName?: string;
  panelClassName?: string;
  slides?: IMultipurposeBannerSlide[];
  rest?: any;
  onClose?: () => void;
  className?: string;
  classNameCloseButton?: string;
  withAnimation?: boolean;
  getCurrentSlides?: (slides: IMultipurposeBannerSlide[]) => void;
  isForcedHidden?: boolean;
}

function MultipurposeBanner({
  isBannerOnly = false,
  isPanelOnly = false,
  horizontalClassName = '',
  panelClassName = '',
  slides = [],
  onClose,
  className = '',
  classNameCloseButton = '',
  withAnimation = true,
  getCurrentSlides,
  isForcedHidden = false,
}: IProps) {
  const theme = useSelector(getCurrentTheme);
  const [currentSlides, setCurrentSlides] = useState<IMultipurposeBannerSlide[]>(slides),
    active = currentSlides.length - 1,
    slidesNames = currentSlides.map((i) => i.name),
    banners: BANNERS_ENTITY = useMemo(() => {
      const bannersRaw = localStorage.getItem(LOCAL_STORAGE_KEYS.BANNERS),
        bannersParsed: BANNERS_ENTITY = bannersRaw ? JSON.parse(bannersRaw) : {},
        bannersKeys = Object.keys(bannersParsed);

      if (bannersKeys.length > 0) {
        const result: BANNERS_ENTITY = {};
        slidesNames
          .filter((slidesName) => bannersKeys.includes(slidesName) || !Boolean(bannersParsed[slidesName]))
          .forEach((key) => (result[key] = bannersParsed[key] ?? Date.now()));

        return result;
      }
      return {};
    }, [slidesNames]);

  useEffect(() => {
    const currentTime = Date.now();
    if (Object.keys(banners).length < 1) {
      const newBanners: BANNERS_ENTITY = {};
      slidesNames.forEach((name) => (newBanners[name] = currentTime));
      const currentBanners = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.BANNERS) ?? '{}');
      localStorage.setItem(LOCAL_STORAGE_KEYS.BANNERS, JSON.stringify({ ...currentBanners, ...newBanners }));
    } else {
      setCurrentSlides(() => slides.filter(({ name }) => banners[name] < currentTime));
    }
    getCurrentSlides && getCurrentSlides(currentSlides);
  }, [currentSlides.length, slides]);

  if (currentSlides.length < 1) return null;

  const handleClose = (bannerName: string) => () => {
    if (onClose) {
      onClose();
    } else {
      // timeout closed banner
      const currentBannerEndTime = banners[bannerName] ?? Date.now();
      if (bannerName && !['Details'].includes(bannerName)) {
        if (bannerName === 'DailyRewards') {
          const { fiveAmUtcTomorrow } = getCooldown(new Date(currentBannerEndTime));
          const fiveAmUtcTomorrowTime = fiveAmUtcTomorrow.valueOf();
          const newCurrentBannerEndTime = fiveAmUtcTomorrowTime; // 5AM next day

          banners[bannerName] = newCurrentBannerEndTime;
        } else {
          banners[bannerName] = Date.now() + 1000 * 60 * 20; // 20 mins timeout
        }

        const currentBanners = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.BANNERS) ?? '{}');
        localStorage.setItem(LOCAL_STORAGE_KEYS.BANNERS, JSON.stringify({ ...currentBanners, ...banners }));
        setCurrentSlides((prevSlides) => prevSlides.filter((i) => i.name !== bannerName));
      }
    }
  };

  return (
    <>
      {currentSlides.map(({ component: Slide, name, props }, index) => {
        if (active > index || active < 0 || isForcedHidden) {
          return null;
        }
        const isActive = active === index;

        return (
          <div className={stylesBanner.wrapper} key={'mp' + index}>
            <div
              className={clsx('banner', stylesBanner.container, className, {
                [horizontalClassName]: !!horizontalClassName,
                [stylesBanner.bannerOnly]: isBannerOnly && !isPanelOnly,
                [stylesBanner.panelOnly]: !isBannerOnly && isPanelOnly,
                [stylesBanner.hidden]: !isActive,
                [stylesBanner.withAnimation]: withAnimation,
              })}
            >
              <Slide {...props} isBannerOnly={isBannerOnly} isActive={isActive} onMPBannerClose={handleClose(name)} />
              <CircleButton
                className={clsx(stylesBanner.close, classNameCloseButton, stylesBanner[theme])}
                icon={<CrossIcon />}
                onClick={handleClose(name)}
              />
            </div>
            {createPortal(
              <div
                className={clsx('panel', stylesPanel.container, className, {
                  [panelClassName]: !!panelClassName,
                  [stylesPanel.bannerOnly]: isBannerOnly && !isPanelOnly,
                  [stylesPanel.panelOnly]: !isBannerOnly && isPanelOnly,
                  [stylesPanel.hidden]: !isActive,
                })}
              >
                <Slide {...props} isBannerOnly={isBannerOnly} isActive={isActive} onMPBannerClose={handleClose(name)} />
                <CircleButton
                  className={clsx(stylesPanel.close, classNameCloseButton, stylesBanner[theme])}
                  icon={<CrossIcon />}
                  onClick={handleClose(name)}
                />
              </div>,
              document.getElementById('root')!
            )}
          </div>
        );
      })}
    </>
  );
}

export default memo(MultipurposeBanner);
