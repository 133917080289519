import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      margin: 29px 0 28px 0;

      @media screen and (max-width: 992px) {
        margin: 30px 0 38px 0;
      }
    `
  ),
  Energy = styled.div<{ $increasing?: boolean }>(
    ({ $increasing }) => css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      filter: ${$increasing && 'drop-shadow(0px 0px 10px rgba(var(--basic-green--rgb), 0.59))'};

      .energy__data {
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        padding: 0 9px 0 12px;

        span {
          color: #e0e0e0;
          font-size: 16px;
          margin-right: -5px;
        }
      }
    `
  );
