import { AnimatePresence, PanInfo } from 'framer-motion';
import * as S from './styles';
import { useGetCurrentTheme, useSwipe } from 'hooks';
import { ReactComponent as DiscordIcon } from 'assets/icons/socials/one-color/discord.svg';
import { ReactComponent as FacebookIcon } from 'assets/icons/socials/one-color/facebook.svg';
import { ReactComponent as TelegramIcon } from 'assets/icons/socials/one-color/telegram.svg';
import { ReactComponent as InstagramIcon } from 'assets/icons/socials/one-color/instagram.svg';
import { ReactComponent as XIcon } from 'assets/icons/socials/one-color/x.svg';
import { useLocation } from 'react-router-dom';
import { useMemo } from 'react';

type Props = {
  type: 'facebook' | 'instagram' | 'telegram' | 'x' | 'discord';
  isOpen: boolean;
  onClose: () => void;
  content: {
    title: string;
    sub: string;
    buttonTitle: string;
    onClick: () => void;
  }[];
};

const ICONS_BY_TYPE = {
  discord: <DiscordIcon />,
  instagram: <InstagramIcon />,
  x: <XIcon />,
  telegram: <TelegramIcon />,
  facebook: <FacebookIcon />,
};

const SocialsSubMenu = ({ isOpen, onClose, type, content }: Props) => {
  const theme = useGetCurrentTheme();
  const { pathname } = useLocation();
  const { onTouchStart, onTouchMove, onTouchEnd } = useSwipe({
    direction: 'vertical',
    handleLeftTop: () => {},
    handleRightBottom: () => onClose(),
    minSwipeDistance: 150,
  });

  function handleDragEnd(event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) {
    if (info.offset.y > 100 || info.velocity.y > 500) {
      onClose();
    }
  }

  const isBiggerPadding = useMemo(() => !pathname.includes('referrals'), [pathname]);

  return (
    <AnimatePresence>
      {isOpen ? (
        <S.Container
          initial={{ y: 500 }}
          animate={{ y: 0 }}
          exit={{ y: 900 }}
          dragTransition={{
            bounceStiffness: 900,
            bounceDamping: 10,
          }}
          drag={'y'}
          dragConstraints={{ top: 0 }}
          $theme={theme}
          onTouchStart={onTouchStart}
          onTouchMove={onTouchMove}
          onTouchEnd={onTouchEnd}
          onDragEnd={handleDragEnd}
          $biggerPadding={isBiggerPadding}
        >
          {content.map(({ title, sub, onClick, buttonTitle }) => (
            <S.Block>
              <S.Title>{title}</S.Title>
              <S.Subtitle>{sub}</S.Subtitle>
              <S.Button $type={type} onClick={onClick}>
                {ICONS_BY_TYPE[type]}
                {buttonTitle}
              </S.Button>
            </S.Block>
          ))}
        </S.Container>
      ) : null}
    </AnimatePresence>
  );
};

export default SocialsSubMenu;
