import { useReadNotifsMutation } from 'store';

export const useReadNotifs = ({ updateState }: any) => {
  const [readNots, { isLoading: isReading }] = useReadNotifsMutation();

  const handleRead = () => {
    return readNots()
      .unwrap()
      .then((res) => {
        updateState({ items: [] });
        return res;
      });
  };

  return { readNotifitations: handleRead, isLoading: isReading };
};
