import { useDisplay } from 'hooks';
import { FC, PropsWithChildren, useEffect } from 'react';
import { AuthPageViewMobile } from './mobile';
import { AuthPageViewDesktop } from './desktop';

export const AuthPageView: FC<PropsWithChildren> = ({ children }) => {
  const { isMobile } = useDisplay();

  useEffect(() => {
    const browForIPhones = document.querySelector<HTMLDivElement>('.brow');
    if (browForIPhones) {
      browForIPhones.style.display = 'none';
    }
    return () => {
      if (browForIPhones) {
        browForIPhones.style.display = 'block';
      }
    };
  }, []);

  if (isMobile) {
    return <AuthPageViewMobile>{children}</AuthPageViewMobile>;
  }
  return <AuthPageViewDesktop>{children}</AuthPageViewDesktop>;
};
