import styled, { css } from 'styled-components';

export const STooltip = styled.div<{ $size: string; $maxWidth?: number }>`
  position: relative;
  left: 0;
  top: 0;
  padding: 15px;
  border-radius: 6px;
  border: var(--basic-border);
  backdrop-filter: var(--standard-blur);

  ${({ $maxWidth, $size }) => css`
    max-width: ${$maxWidth ? `${$maxWidth}px` : $size === 'large' ? '257.5px' : '211px'};
  `}
`;
