import { FC } from 'react';
import { IDatabaseItem } from 'interface';
import { ReactComponent as MessageBg } from './assets/message-bg.svg';
import { ReactComponent as Check } from 'assets/icons/check-outline-rounded-mighty.svg';
import * as Styled from './styled';
import { useDisplay } from 'hooks';
import clsx from 'clsx';

interface IActivatedProps {
  item: IDatabaseItem;
  gradeColor: string;
  value: string;
  desc: string;
}

export const Activated: FC<IActivatedProps> = ({ item, gradeColor, value, desc }) => {
  const { isMobile } = useDisplay();

  return (
    <Styled.Container className={clsx({ mobile: isMobile })}>
      <Styled.Content>
        <Styled.ImgWrap>
          <img src={item.image} alt={item.name} />
          <span>{value}</span>
        </Styled.ImgWrap>
        <Styled.Desc>
          <h5 style={{ color: gradeColor }}>{item.name}</h5> <span>Activated!</span>
          <p>{desc}</p>
        </Styled.Desc>
        <Check />
      </Styled.Content>
      <MessageBg className={'activated__message-bg'} color={gradeColor} />
    </Styled.Container>
  );
};
