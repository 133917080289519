import { AnimatePresence, motion } from 'framer-motion';

interface IProps {
  children: React.ReactNode;
}

export const AnimateWrapper = ({ children }: IProps) => {
  return (
    <AnimatePresence initial={true}>
      <motion.div initial={{ opacity: 0, width: '100%' }} animate={{ opacity: 1, width: '100%' }} transition={{ duration: 0.7 }}>
        {children}
      </motion.div>
    </AnimatePresence>
  );
};
