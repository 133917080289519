import { ChangeEvent, FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { ESlideWidgetTypes } from 'store';
import { isValidJSON } from 'utils/isValidJSON';
import { useDebounce, useDisplay, useGetCurrentTheme } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';
import { IWebLanguage } from './CodeWidgetWeb/model';
import { ReactComponent as Cross } from 'assets/icons/cross.svg';
import { CodeWidgetWeb } from './CodeWidgetWeb';
import { CodeHeader } from 'components/pages/study/courses/material-pages/SlideContent/components/Text';
import BasicButton from 'shared/ui/basic-button/button';

import * as Styled from 'components/pages/study/courses/material-pages/SlideContent/components/Text/styles';
import { createPortal } from 'react-dom';

export type TContentWebCodeWidget = {
  tabs: IWebLanguage[];
  activeTab?: IWebLanguage;
  content: TContentWebWidget;
};

export type TContentWebWidget = {
  html: string;
  css: string;
  js: string;
  python: string | null;
};

export type TWebWidgetSetup = {
  mode?: 'onKeyCompile' | 'onPressButtonCompile';
  withOutput: boolean;
  withConsole: boolean;
  withConstructor: boolean;
  readOnly?: boolean;
};

type TCodeWidgetProps = {
  content: string;
  onChange: (value: string) => void;
  webEditorSetup?: TWebWidgetSetup;
  comment?: any;
  className?: string;
};

const CodeWidget: FC<TCodeWidgetProps> = ({ content, onChange, webEditorSetup, comment, className = '' }) => {
  const debounce = useDebounce();
  const [outputContainer, setOutputContainer] = useState<(props: any) => JSX.Element>(() => () => <></>);
  const { isMobile } = useDisplay();
  const theme = useGetCurrentTheme();
  const parsedContent: string | TContentWebCodeWidget = isValidJSON(content) ? JSON.parse(content) : content;

  const contentIsString = typeof parsedContent !== 'object';
  const isUsualJSONContent = !contentIsString && !parsedContent?.content;

  const currentActiveTab = (): IWebLanguage | undefined => {
    if (!contentIsString) {
      return parsedContent.activeTab ? parsedContent.activeTab : parsedContent.tabs ? parsedContent.tabs[0] : undefined;
    }
    return undefined;
  };

  const [localData, setLocalData] = useState(contentIsString ? content : isUsualJSONContent ? JSON.stringify(parsedContent, null, 2) : 'Type code');
  const [activeEditorName, setActiveEditorName] = useState(currentActiveTab);
  const [tabs, setTabs] = useState(contentIsString ? [] : parsedContent.tabs);
  const [isOutputIsVisible, setOutputIsVisible] = useState(false);

  const $areaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    if ($areaRef.current) {
      $areaRef.current.style.height = 'inherit';
      const scrollHeight = $areaRef.current.scrollHeight;
      const lineHeight = $areaRef.current.style.lineHeight;
      $areaRef.current.style.height = scrollHeight + lineHeight + 'px';
    }
  }, [localData]);

  const handleChange = useCallback(
    (evt: ChangeEvent<HTMLTextAreaElement>) => {
      setLocalData(evt.target.value);
      onChange(evt.target.value);
    },
    [onChange]
  );

  const handleChangeWebEditor = debounce((data: any) => {
    const currentContent = Object.keys(data).reduce((acc, key: any) => {
      if (tabs.includes(key)) {
        // @ts-ignore
        acc[key] = data[key];
      }
      return acc;
    }, {});

    const currentData = {
      tabs,
      content: { ...currentContent },
    };

    onChange(JSON.stringify(currentData));
  }, 500);

  const onTabClick = (editorName: IWebLanguage) => {
    setActiveEditorName(editorName);
  };

  const onTabChange = (newTabs: IWebLanguage[]) => {
    if (webEditorSetup?.withConstructor) {
      setTabs(newTabs);
      setActiveEditorName(newTabs[0]);
    }
  };

  const editorVariants = useMemo(
    () =>
      isMobile
        ? {
            initial: {
              x: 0,
            },
            animate: {
              x: 0,
            },
            exit: {
              x: 0,
            },
          }
        : {
            initial: {
              x: 0,
            },
            animate: {
              x: '-155px',
            },
            exit: {
              x: 0,
            },
          },
    [isMobile]
  );

  const variants = useMemo(
    () =>
      isMobile
        ? {
            initial: {
              y: '100%',
            },
            animate: {
              y: 0,
            },
            exit: {
              y: '100%',
            },
          }
        : {
            initial: {
              x: 0,
            },
            animate: {
              x: 0,
            },
            exit: {
              x: 0,
            },
          },
    [isMobile]
  );

  return (
    <Styled.Wrap className={clsx('root-transition', { [className]: !!className, open: isOutputIsVisible, mobile: isMobile })}>
      <Styled.TextRoot
        $theme={theme}
        $isShadowed={isOutputIsVisible}
        as={motion.div}
        initial={'initial'}
        animate={isOutputIsVisible ? 'animate' : 'exit'}
        exit={'exit'}
        variants={editorVariants}
        transition={{ duration: 0.35, ease: 'easeInOut' }}
        className={clsx('editor', { open: isOutputIsVisible, mobile: isMobile })}
      >
        <AnimatePresence>
          {isOutputIsVisible && !isMobile && (
            <Styled.CloseButton
              initial={{ opacity: 0, x: 100 }}
              animate={{ opacity: 1, x: 0, transition: { delay: 0.5 } }}
              exit={{ opacity: -1, x: 100 }}
              onClick={() => setOutputIsVisible(false)}
            >
              <Cross />
            </Styled.CloseButton>
          )}
        </AnimatePresence>
        <CodeHeader
          tabs={tabs}
          activeTab={activeEditorName}
          withConstructor={webEditorSetup?.withConstructor}
          onChange={(newTabs: IWebLanguage[]) => onTabChange(newTabs)}
          onClick={(tab: IWebLanguage) => onTabClick(tab)}
        />
        <Styled.TypeText className={clsx(tabs?.length ? 'code__webEditor' : ESlideWidgetTypes.CODE.toLowerCase())}>
          {tabs?.length > 0 && activeEditorName !== undefined ? (
            <CodeWidgetWeb
              activeEditorName={activeEditorName}
              content={contentIsString ? { html: '', css: '', js: '', python: null } : parsedContent.content}
              config={webEditorSetup!}
              onChange={handleChangeWebEditor}
              comment={comment}
              isOutputIsVisible={isOutputIsVisible}
              setOutputIsVisible={setOutputIsVisible}
              setOutputContainer={setOutputContainer}
            />
          ) : (
            <textarea ref={$areaRef} onChange={handleChange} rows={localData.split('\n').length} value={localData} />
          )}
        </Styled.TypeText>
      </Styled.TextRoot>
      {isMobile ? (
        <>
          {createPortal(
            <AnimatePresence exitBeforeEnter>
              {isOutputIsVisible && (
                <Styled.OutputContainer
                  as={motion.div}
                  initial={'initial'}
                  animate={isOutputIsVisible ? 'animate' : 'exit'}
                  exit={'exit'}
                  variants={variants}
                  transition={{ duration: 0.35, ease: 'easeInOut' }}
                  className={clsx('output-container', theme, { open: isOutputIsVisible, mobile: isMobile })}
                >
                  {outputContainer({
                    className: clsx('output-mobile', { open: isOutputIsVisible }),
                  })}
                  <BasicButton onClick={() => setOutputIsVisible(false)} label="Close" />
                </Styled.OutputContainer>
              )}
            </AnimatePresence>,
            document.getElementById('supreme-portal')!
          )}
        </>
      ) : (
        isOutputIsVisible &&
        outputContainer({
          className: clsx('output', { open: isOutputIsVisible }),
        })
      )}
    </Styled.Wrap>
  );
};

export default CodeWidget;
