import { FC, useState } from 'react';
import BasicButton from 'shared/ui/basic-button/button';
import { Header, Info, Transaction, Approval } from './components';
import { useDisplay } from 'hooks';
import styles from './styles.module.scss';
import { IDatabaseItem } from 'interface';
import { useCreateTransferRequestMutation } from 'store';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';

interface ITransferItem {
  item: IDatabaseItem;
  handleClose: () => void;
}

export const TransferItem: FC<ITransferItem> = ({ item, handleClose }) => {
  const [currentStep, setCurrentStep] = useState(0);
  const [isConnectedWallet] = useState(false);
  const { isMobile } = useDisplay();
  const [address, setAddress] = useState('');
  const [createRequest, { isLoading }] = useCreateTransferRequestMutation();

  const handleAddressInput = (e: React.ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);

  const handleChangeStep = (index: number) => {
    if (index < 0) {
      return handleClose();
    }
    setCurrentStep(index);
  };

  const handleCreateRequest = () =>
    createRequest({
      transferAddress: address,
      itemId: item.id,
    })
      .unwrap()
      .then(() => {
        setCurrentStep((prev) => prev + 1);
      })
      .catch((ex) => {
        console.error(ex);
        handleClose();
        toast.error(<Answer type="incorrect" label={'Oops'} subtext={ex.data.message ?? 'Failed to create transfer request'} />);
      });

  return (
    <div className={styles.container}>
      {
        [
          <>
            <Header title={'Transfer item'} marginBottom={'25px'} handleClick={() => handleChangeStep(currentStep - 1)} />
            <Info name={item.name} contract={item.contract} image={item.image} grade={item.grade} />
            <BasicButton label={'Send'} variant={'cool'} className={styles.button} onClick={() => handleChangeStep(currentStep + 1)} />
          </>,
          <>
            <Header
              title={isConnectedWallet ? 'Send items' : 'Transfer item'}
              marginBottom={isMobile ? '30px' : '19px'}
              handleClick={() => handleChangeStep(currentStep - 1)}
            />
            <Transaction
              handleAddressInput={handleAddressInput}
              address={address}
              name={item.name}
              image={item.image}
              grade={item.grade}
              contract={item.contract}
              blockchain={item.blockchain}
              isConnectedWallet={isConnectedWallet}
              connectWallet={() => handleChangeStep(currentStep + 1)}
            />
            <BasicButton
              isDisabled={!Boolean(address)}
              label={'Next'}
              variant={'cool'}
              className={styles.button}
              onClick={() => handleChangeStep(isConnectedWallet ? currentStep + 2 : currentStep + 1)}
            />
          </>,
          <>
            <Header title={'Send items'} marginBottom={isMobile ? '30px' : '19px'} handleClick={() => handleChangeStep(currentStep - 2)} />
            <Transaction
              handleAddressInput={handleAddressInput}
              address={address}
              name={item.name}
              contract={item.contract}
              image={item.image}
              grade={item.grade}
              rules={true}
              blockchain={item.blockchain}
            />
            <BasicButton
              isDisabled={isLoading}
              loading={isLoading}
              label={'Send'}
              variant={'cool'}
              className={styles.button}
              onClick={handleCreateRequest}
            />
          </>,
          <>
            <Approval />
            <BasicButton
              label={'Okay, Thanks'}
              variant={'cool'}
              className={styles.button}
              onClick={handleClose}
              style={{ margin: isMobile ? 'auto auto -10px auto' : '56px auto auto' }}
            />
          </>,
        ][currentStep]
      }
    </div>
  );
};
