import clsx from 'clsx';
import { STATIC_HOST_PATH } from 'app/constants/path';
import { ILeftOffLesson } from 'components/pages/study/courses/left-off-lesson';
import { useLazyGetLeftOffLessonByCourseIdQuery, useLazyGetLeftOffLessonQuery } from 'store';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetItemGetterByKey, useGetItems } from 'hooks';

import BasicButton from 'shared/ui/basic-button/button';
import ProfileCoursesPlaceholder from 'components/pages/profile/profile/Courses/ProfileCoursesPlaceholder/ProfileCoursesPlaceholder';
import { FeatureCard } from 'shared/ui';

import slidesStyles from '../slides.module.scss';
import styles from './styles.module.scss';
import { ROUTES } from 'app/constants';

export const SlideLessonLeftOff = () => {
  const getItemByKey = useGetItemGetterByKey();
  const { courseId: currentCourseId } = useParams();
  const items = useGetItems();
  const [getLeftOffLesson, { isFetching: isFetchingLesson }] = useLazyGetLeftOffLessonQuery();
  const [getLeftOffLessonByCourseId, { isFetching: isFetchingLessonById }] = useLazyGetLeftOffLessonByCourseIdQuery();
  const [lessonData, setLessonData] = useState<ILeftOffLesson>();
  const navigate = useNavigate();
  const isLoading = isFetchingLesson || isFetchingLessonById;

  useEffect(() => {
    (async () => {
      let leftOffLesson;
      if (currentCourseId) {
        leftOffLesson = await getLeftOffLessonByCourseId(currentCourseId)
          .unwrap()
          .then((res) => structuredClone(res))
          .catch(console.log);
      } else {
        leftOffLesson = await getLeftOffLesson()
          .unwrap()
          .then((res) => structuredClone(res))
          .catch(console.log);
      }
      if (leftOffLesson?.lessonId) {
        setLessonData(leftOffLesson);
      }
    })();
  }, [currentCourseId, getLeftOffLesson, getLeftOffLessonByCourseId]);

  if (!lessonData || isLoading) return <ProfileCoursesPlaceholder isLoading={isLoading} />;

  const { chapterId, index, lessonId, lessonName, courseId, courseImageCover, courseName, rewards } = lessonData;

  const toLesson = () => {
    navigate(`${ROUTES.COURSES}/${courseId}/${chapterId}/${lessonId}`);
  };

  const cover = STATIC_HOST_PATH.concat(courseImageCover);

  return (
    <FeatureCard
      headSlot={
        <div className={slidesStyles.head}>
          <p>Continue studying</p>
        </div>
      }
      padding={'21px 23px'}
      background={'linear-gradient(0deg, rgba(32, 32, 107, 0.30) 0%, rgba(32, 32, 107, 0.30) 100%), rgba(255, 255, 255, 0.03)'}
    >
      <div className={clsx(slidesStyles.slideWrap, styles.wrap)}>
        <div className={clsx(slidesStyles.left, styles.left)}>
          <img className={styles.courseLogo} src={cover} alt={courseName} />
          <div className={clsx(slidesStyles.leftContent, styles.rewards)}>
            {rewards.map((reward, index) => {
              const rew = getItemByKey('id', reward.id);
              return <img key={'left-off-reward' + index} src={rew.image} alt={rew.name} style={{ zIndex: index }} />;
            })}
          </div>
        </div>
        <div className={styles.right}>
          <div className={styles.header}>
            <h2 className={styles.title}>
              {courseName}: Lesson {index + 1}
            </h2>
            <p>Continue where you left off:</p>
            <div className={styles.fullTitle}>
              <span className={styles.courseName}>{courseName}</span>
              <span className={styles.lesson}>
                Lesson {index + 1}: <span className={styles.lessonName}>{lessonName}</span>
              </span>
            </div>
          </div>
          <BasicButton label={'Continue'} variant={'cool'} fullWidth onClick={toLesson} className={styles.button} />
        </div>
      </div>
    </FeatureCard>
  );
};
