import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { getCurrentTheme, setCurrentTheme } from 'store';
import { dispatch } from 'hooks';
import { THEMES } from 'app/constants';

import { ReactComponent as BG } from 'assets/icons/toggler-icons/toggle-bg.svg';
import { ReactComponent as Light } from 'assets/icons/toggler-icons/light.svg';
import { ReactComponent as Dark } from 'assets/icons/toggler-icons/dark.svg';
import { ReactComponent as LightBG } from 'assets/icons/toggler-icons/light-bg.svg';
import { ReactComponent as DarkBG } from 'assets/icons/toggler-icons/dark-bg.svg';

const TogglerContainer = styled.div`
  position: relative;
  height: 50px;
  width: 100px;

  & .toggler-bg {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    height: 0;
    width: 0;
  }

  & .toggler-bg svg {
    fill: ${({ theme }) => theme.theme.body};
  }

  & label {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
    cursor: pointer;
  }

  & .stuff {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 2;
  }

  & .stuff div {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }

  & .dark {
    position: absolute;
    left: 52px;
    width: 50%;
    stroke: none;
  }

  & .tt-bg {
    stroke: none;
  }

  & .tt-pic.light {
    stroke: #fdfdfd;
  }
  & .tt-pic.dark {
    stroke: var(--font-color);
  }

  & .light {
    position: absolute;
    width: 50%;
    stroke: none;
  }

  & input {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }

  & #switch + .stuff .light.tt-bg,
  & #switch + .stuff .dark.tt-bg {
    transition: all 300ms ease-in-out;
  }

  & #switch + .stuff .light.tt-bg,
  & #switch:checked + .stuff .dark.tt-bg {
    opacity: 0;
    height: 0;
  }

  & #switch + .stuff .dark.tt-bg,
  & #switch:checked + .stuff .light.tt-bg {
    opacity: 1;
    height: 75%;
  }

  & #switch:checked + .stuff .dark.tt-bg {
    transform: translateX(-100%);
  }

  & #switch:not(:checked) + .stuff .light.tt-bg {
    transform: translateX(100%);
  }
`;

const ThemeToggler = () => {
  const theme = useSelector(getCurrentTheme);

  function toggle() {
    dispatch(setCurrentTheme(theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK));
  }

  return (
    <TogglerContainer className="theme-toggler">
      <label htmlFor="switch" />
      <input id="switch" type="radio" onChange={() => {}} onClick={toggle} checked={theme === 'light' ? true : false} />
      <div className="stuff">
        <div className="dark-stuff">
          <DarkBG className={`tt-bg dark`} />
          <Dark className={`tt-pic dark`} />
        </div>
        <div className="light-stuff">
          <LightBG className={`tt-bg light`} />
          <Light stroke="red" className={`tt-pic light`} />
        </div>
      </div>
      <div className="toggler-bg">
        <BG className="tt-bg" />
      </div>
    </TogglerContainer>
  );
};

export default ThemeToggler;
