import styled, { css } from 'styled-components';

export const Container = styled.div<{ $larger?: boolean }>(
    ({ $larger }) => css`
      display: flex;
      ${!$larger && 'padding: 7px 0;'}
      cursor: pointer;

      &.effects__active {
        border-radius: 400px;
        background: rgba(255, 255, 255, 0.06);
        padding: 7px;
      }

      .effects__effect-indicator {
        width: ${$larger ? '48px' : '44px'};
        height: ${$larger ? '48px' : '44px'};
        ${$larger && 'max-height: 48px !important;'}
        &:not(:first-of-type) {
          margin-left: -25px;
        }
      }
    `
  ),
  SeeAll = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;

      span {
        color: #fff;
        text-align: right;
        font-size: 16px;
        line-height: 110%;
        width: min-content;
      }
    `
  );
