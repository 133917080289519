import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const Container = styled.div`
  @media screen and (max-width: 992px) {
    margin-top: 12px;
  }
`;

export const Title = styled.p<{ $theme?: THEMES }>`
  display: flex;
  align-items: center;
  color: ${(p) => (p.$theme === THEMES.DARK ? 'white' : '#111111')};
  gap: 10px;
  font-size: 20px;
  margin-bottom: 16px;
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const linkBgs = {
  [THEMES.DARK]: '#FFFFFF1C',
  [THEMES.LIGHT]: '#1111111C',
};

export const LinkContainer = styled.div<{ $isError?: boolean; $color?: string; $isActive?: boolean; $theme: THEMES }>`
  display: flex;
  align-items: center;
  height: 56px;
  border-radius: 45px;
  background: ${(p) => (p.$color ? p.$color : linkBgs[p.$theme])};
  gap: 6px;
  color: white;
  padding: 0px 10px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    /* ${(p) => (p.$isActive ? 'filter: brightness(1.1);' : 'opacity: 0.85;')} */
    /* filter: brightness(1.1); */
    opacity: 0.8;
  }
`;

const Paragraph = styled.p`
  color: inherit;
  font-size: 18px;
  font-weight: 400;
`;

export const LinkTitle = styled(Paragraph)``;
export const LinkSub = styled(Paragraph)`
  margin-left: auto;
`;

export const IconContainer = styled.div`
  display: flex;
  svg {
    width: 36px;
    height: 36px;
  }
`;
