import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { getAppSettings } from 'store';

export const usePageTitle = (title: string | null, addSiteName = true) => {
  const { displayedName } = useSelector(getAppSettings);
  const defaultName = useMemo(() => `${displayedName} Study`, [displayedName]);
  const { pathname } = useLocation();

  useEffect(() => {
    document.title = defaultName;
  }, [pathname]);

  useEffect(() => {
    const noTitleText = defaultName;
    const titleText = title ? title + (addSiteName ? ` | ${defaultName}` : '') : '';
    document.title = title ? titleText : noTitleText;

    return () => {
      document.title = defaultName;
    };
  }, [addSiteName, title, addSiteName]);
};
