import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      position: relative;
      margin-bottom: 22px;

      .inview {
        &.mobile {
          &.regular {
            width: 100%;
          }
          width: fit-content;
        }
      }

      @media screen and (min-width: 992px) {
        margin-bottom: 60px;
      }
    `
  ),
  Courses = styled.div(
    () => css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      gap: 22px 10px;
      margin-bottom: 22px;
      align-items: stretch;

      &.minis.mobile {
        flex-wrap: nowrap;
        flex-direction: row;
        justify-content: flex-start;
        overflow-y: auto;
        padding-bottom: 10px;
      }

      @media screen and (min-width: 576px) {
        justify-content: flex-start;
      }
      @media screen and (min-width: 992px) {
        gap: 30px 20px;
      }
    `
  ),
  Header = styled.h4(
    () => css`
      font-weight: 500;
      font-size: 35px;
      line-height: 135%;
      color: var(--font-color-strong);
      text-transform: capitalize;
      margin-bottom: 16px;

      &.hidden {
        display: none;
      }

      @media screen and (max-width: 992px) {
        font-size: 27px;
      }
    `
  ),
  Description = styled.p(
    () => css`
      font-size: 20px;
      line-height: 135%;
      letter-spacing: 0.5px;
      opacity: 0.7;
      margin-bottom: 23px;

      @media screen and (max-width: 992px) {
        font-size: 15px;
      }
    `
  );
