import { EGroupTag } from 'app/constants';
import Answer from 'components/library/messages/Answer';
import { dispatch } from 'hooks';
import { toast } from 'react-toastify';
import { setUserInterests, useUpdateInterestsMutation } from 'store';

export const useUpdateInterests = () => {
  const [update, { isLoading }] = useUpdateInterestsMutation();

  const handler = async (interests: EGroupTag[]) => {
    return update(interests)
      .unwrap()
      .then(() => {
        dispatch(setUserInterests(interests));
        return true;
      })
      .catch((ex) => {
        console.error(ex);
        toast.error(<Answer type="incorrect" label={'Oops:/'} subtext={'some error occured, try again or contact a support'} />);
      });
  };

  return { handler, isLoading };
};
