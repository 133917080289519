import { ICertificate } from 'interface';
import { GRADES } from 'app/constants';

import certificate from './assets/images/certificate.png';
import certificateHtml from './assets/images/certificate-html.png';

export const certificatesData: ICertificate[] = [
  {
    _id: '1',
    image: certificate,
    name: 'Java Scrrrrrript1',
    date: new Date(),
    grade: GRADES.MYTHICAL,
    progress: 10,
    isMine: false,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
  {
    _id: '2',
    image: certificate,
    name: 'Java Scrrrrrript2',
    date: new Date(),
    grade: GRADES.RARE,
    progress: 99,
    isMine: false,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
  {
    _id: '3',
    image: certificate,
    name: 'Java Scrrrrrript3',
    date: new Date(),
    grade: GRADES.LEGENDARY,
    progress: 100,
    isMine: true,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
  {
    _id: '4',
    image: certificate,
    name: 'Java Scrrrrrript3',
    date: new Date(),
    grade: GRADES.UNCOMMON,
    progress: 100,
    isMine: true,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
  {
    _id: '5',
    image: certificateHtml,
    name: 'HTML Course',
    date: new Date(),
    grade: GRADES.COMMON,
    progress: 10,
    isMine: false,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
  {
    _id: '6',
    image: certificateHtml,
    name: 'HTML Course',
    date: new Date(),
    grade: GRADES.MYTHICAL,
    progress: 0,
    isMine: false,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
  {
    _id: '7',
    image: certificateHtml,
    name: 'HTML Course',
    date: new Date(),
    grade: GRADES.EPIC,
    progress: 100,
    isMine: true,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
  {
    _id: '8',
    image: certificateHtml,
    name: 'HTML Course',
    date: new Date(),
    grade: GRADES.EPIC,
    progress: 100,
    isMine: true,
    description: 'This item gives you +1 heart in every challenge by default.',
  },
];
