import * as Styled from './styles';

type Props = {
  className?: string;
};

export const Head = ({ className = '' }: Props) => {
  return (
    <Styled.Container className={className}>
      <Styled.Header>My Stats</Styled.Header>
    </Styled.Container>
  );
};
