import styled, { css } from 'styled-components';

export const Span = styled.span(
  () => css`
    font-size: 20px;
    font-size: inherit;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    letter-spacing: 0.5px;
    background: linear-gradient(180deg, #fadd9d 0%, #d48f52 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-transform: capitalize;
  `
);
