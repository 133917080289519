import * as S from '../styles';
import UniversalSlider from 'components/library/universal-slider';
import useGetQuestsFeed from './useGetQuestsFeed';
import { useCallback, useMemo } from 'react';
import Quest from '../quest';
import { useDebounce, useDisplay, useGetCurrentTheme, useGetIsAuth, useObserver } from 'hooks';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { QuestSliderLoader } from '../../SpaceShimmerLoader/spaceShimmerLoader';

const QuestsFeed = () => {
  const isAuth = useGetIsAuth();
  const theme = useGetCurrentTheme();
  const { isMobile } = useDisplay();
  const { initiate, isFetching, quests, total, isLoading, isFinished } = useGetQuestsFeed();

  const debounce = useDebounce();

  const loaderHandler = useCallback(
    debounce((isVisible: boolean) => {
      if (!isFinished && isVisible) {
        initiate();
      }
    }, 200),
    [isFinished, initiate]
  );

  const setObserverRef = useObserver({ handler: loaderHandler });

  const questsSlides = useMemo(
    () =>
      quests.map((el) => (
        <Quest
          isInSlider
          spaceImage={el.spaceInfo?.imageThumb || ''}
          key={el._id}
          isOwner={false}
          course={el}
          isFinished={false}
          onDelete={() => {}}
        />
      )),
    [quests]
  );

  const slidesWithLoader = useMemo(
    () => [...questsSlides].concat(isFetching ? [<MightyLoaderMini style={{ width: 100, height: 100 }} />, <div></div>] : [<div></div>]),
    [questsSlides, isFetching]
  );

  const refetch = () => {
    if (!isFetching) {
      initiate();
    }
  };

  if (isLoading) {
    return <QuestSliderLoader />;
  }

  if (!isFetching && !quests.length) {
    return null;
  }

  return (
    <S.Container style={{ marginBottom: isMobile ? 30 : 64 }}>
      <S.Title style={{ marginBottom: 24, position: 'relative', zIndex: 10 }} $theme={theme}>
        {isAuth ? 'Continue Quests:' : 'New Quests'}
      </S.Title>
      {!!quests.length &&
        (isMobile ? (
          <S.ScrollList>
            {questsSlides}
            {!isFinished && (
              <div ref={setObserverRef}>
                <MightyLoaderMini style={{ width: 100, height: 100 }} />
              </div>
            )}
          </S.ScrollList>
        ) : (
          <UniversalSlider
            listOverflow="visible"
            gap={16}
            slides={slidesWithLoader}
            arrows={isMobile ? 'none' : 'buttonsSides'}
            slidesToScroll={isMobile ? 1 : 9}
            slidesToShow={isMobile ? 3 : 9}
            variableWitdh
            slideWidth={100}
            arrowsOffset={70}
            spaced
            shadowed={!isMobile}
            shadowsWidth={5}
            removeSpacing={isMobile ? undefined : 'removeX'}
            containerDivProps={{ style: { marginBottom: 30 } }}
            refetchOptions={{
              refetchFn: refetch,
              isFetching,
              totalItems: total,
            }}
          />
        ))}
    </S.Container>
  );
};

export default QuestsFeed;
