import styles from './styles.module.css';

import coinIcon from 'testCourses/testItems';
import { LazyImage } from '../../../../../../shared/ui/lazyImage';

interface IProps {
  expToken: number;
}

export const CoinsQuantity = ({ expToken }: IProps) => {
  return (
    <div className={styles.container}>
      <p className={styles.title}>Coins:</p>
      <div className={styles.quantity}>
        <LazyImage className={styles.icon} src={coinIcon[0].img} alt="" />
        {expToken} EXP
      </div>
    </div>
  );
};
