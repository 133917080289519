import { toast } from 'react-toastify';
import Message from '../components/library/messages/Message/Message';
import { IRewardMinimal } from 'store';

export default async function rewardUserLocal(rewards: IRewardMinimal[]) {
  const rews = structuredClone(rewards);
  const sorted = rews.sort((a, b) => a.id - b.id);
  sorted.forEach((rew, idx) => {
    const { id, quantity } = rew;

    toast.success(<Message itemId={id} amount={quantity} status="complete" type="receive" />, {
      autoClose: 10000,
      delay: idx + idx * 500,
    });
  });
}
