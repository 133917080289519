import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IRewardMinimal, getItems, getUserData } from 'store';
import List from '../List';
import { GRADES, USER_ROLES } from 'app/constants';

type Props = {
  updateReward: (id: number, quantity: number) => void;
  rewards: IRewardMinimal[];
  search?: string | null;
};

const OriginalsRewardsTab = ({ rewards, updateReward, search }: Props) => {
  const items = useSelector(getItems);
  const { roles } = useSelector(getUserData);

  const isAdmin = useMemo(() => roles && roles.includes(USER_ROLES.ADMIN), [roles]);

  const originalItems = useMemo(
    () => items.filter((el) => el.type === 'fungible' && !el.fungibleType?.includes('-chest') && (isAdmin ? true : el.grade === GRADES.COMMON)),
    [items]
  );

  return <List search={search} items={originalItems} rewards={rewards} updateReward={updateReward} />;
};

export default OriginalsRewardsTab;
