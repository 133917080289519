import { ESlideTypes } from 'store';
import { FC, useCallback, useState } from 'react';
import BasicButton from 'shared/ui/basic-button/button';
import clsx from 'clsx';

import oneOf4ImgPath from './assets/one_of_4.png';
import severalOf4ImgPath from './assets/several_of_4.png';
import textImgPath from './assets/text.png';
import gapsImgPath from './assets/gaps.png';
import dropImgPath from './assets/drop.png';

import { ReactComponent as BackgroundSvg } from './assets/selected-background.svg';

import styles from './styles.module.css';
import * as Elements from './styles';
import { LazyImage } from 'shared/ui/lazyImage';

type TTestSlideTypeFormProps = {
  onChange: (type: ESlideTypes) => void;
};

type TTestSlideOption = {
  label: string;
  asset: React.ReactNode;
  type: string;
};

const testSlideOptions: Record<ESlideTypes, TTestSlideOption> = {
  [ESlideTypes.INFO]: {
    label: '',
    asset: <div>Empty</div>,
    type: ESlideTypes.INFO,
  },
  [ESlideTypes.TEST]: {
    label: 'One answer out of four',
    asset: <LazyImage src={oneOf4ImgPath} alt={ESlideTypes.TEST} />,
    type: ESlideTypes.TEST,
  },
  [ESlideTypes.MULTICHOICE]: {
    label: 'Several answer choices out of 4',
    asset: <LazyImage src={severalOf4ImgPath} alt={ESlideTypes.MULTICHOICE} />,
    type: ESlideTypes.MULTICHOICE,
  },
  [ESlideTypes.TYPEANSWER]: {
    label: 'Write an answer to the question',
    asset: <LazyImage src={textImgPath} alt={ESlideTypes.TYPEANSWER} />,
    type: ESlideTypes.TYPEANSWER,
  },
  [ESlideTypes.FILLSPACES]: {
    label: 'Fill in the gaps in the dough by hand',
    asset: <LazyImage src={gapsImgPath} alt={ESlideTypes.FILLSPACES} />,
    type: ESlideTypes.FILLSPACES,
  },
  [ESlideTypes.FILLSPACES_CODE]: {
    label: 'Fill in the gaps in the dough by hand, but looks like code',
    asset: <LazyImage src={gapsImgPath} alt={ESlideTypes.FILLSPACES_CODE} />,
    type: ESlideTypes.FILLSPACES_CODE,
  },
  [ESlideTypes.FILLBLOCKS]: {
    label: 'Fill in the blanks in the answer choices test',
    asset: <LazyImage src={dropImgPath} alt={ESlideTypes.FILLBLOCKS} />,
    type: ESlideTypes.FILLBLOCKS,
  },
  [ESlideTypes.FILLBLOCKS_CODE]: {
    label: 'Fill in the blanks in the answer choices test, but looks like code',
    asset: <LazyImage src={dropImgPath} alt={ESlideTypes.FILLBLOCKS_CODE} />,
    type: ESlideTypes.FILLBLOCKS_CODE,
  },
};

const TestSlideTypeForm: FC<TTestSlideTypeFormProps> = ({ onChange }) => {
  const [selectedOptionKey, setSelectedOptionKey] = useState(ESlideTypes.TEST);

  const handleClick = useCallback(
    (key: string) => () => {
      setSelectedOptionKey(key as ESlideTypes);
    },
    []
  );

  const isItemSelected = (key: string) => key === selectedOptionKey;

  const handleContinue = () => {
    onChange(selectedOptionKey);
  };

  return (
    <div>
      <div className={styles.scrollbox}>
        {Object.entries(testSlideOptions)
          .filter(([key]) => key !== ESlideTypes.INFO)
          .map(([key, option]) => (
            <Elements.SWrapper key={key}>
              {isItemSelected(key) && (
                <Elements.SSelectedBg>
                  <BackgroundSvg />
                </Elements.SSelectedBg>
              )}
              <button className={clsx(styles.option, isItemSelected(option.type) && styles.selected)} onClick={handleClick(option.type)}>
                <div>{option.asset}</div>
                <div>{option.label}</div>
              </button>
            </Elements.SWrapper>
          ))}
      </div>
      <div className={styles.controls}>
        <BasicButton label="Continue" variant="cool" onClick={handleContinue} />
      </div>
    </div>
  );
};

export default TestSlideTypeForm;
