import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import { BsChevronDown } from 'react-icons/bs';
import styled, { css } from 'styled-components';

const HEADING_BGS = {
  dark: '#ffffff06',
  light: '#11111106',
  completed: 'linear-gradient(90.36deg, rgba(41, 171, 136, 0.2) 0%, rgba(41, 171, 136, 0.1) 100%)',
  wrong: 'linear-gradient(90.36deg, rgba(241, 79, 99, 0.2) 0%, rgba(241, 79, 99, 0.1) 100%)',
};

export const Container = styled.div(
    () => css`
      display: flex;
      flex-direction: column;

      width: 100%;
      height: 100%;

      border-radius: 20px;

      position: relative;
    `
  ),
  Heading = styled.div<{ $theme: THEMES }>(
    ({ $theme }) => css`
      width: 100%;
      height: 60px;
      display: flex;
      align-items: center;
      color: var(--font-color-strong);
      font-size: 18px;
      line-height: 100%;
      white-space: nowrap;
      margin-top: 24px;
      transition: 0.3s;
      padding: 0px 20px;
      border-radius: 10px;
      background: ${HEADING_BGS[$theme]};
      gap: 4px;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      &.isWrong {
        background: ${HEADING_BGS.wrong};
      }
      &.isCompleted {
        color: #29ab88;
        background: ${HEADING_BGS.completed};
      }

      &:hover {
        filter: brightness(1.1);
      }
      @media screen and (max-width: 992px) {
        margin-top: 24px;
      }
    `
  ),
  Chevron = styled(BsChevronDown)<{ $isOpen: boolean }>(
    ({ $isOpen }) => css`
      transform: rotate(${!$isOpen ? '0deg' : '180deg'});
      transition: 0.3s;
      margin-left: auto;
    `
  ),
  RewsImages = styled(motion.div)<{ $items: number }>`
    display: flex;
    align-items: center;
    margin-left: -10px;
    width: ${({ $items }) => $items * 25}px;
    margin-right: 40px;
    position: relative;
  `,
  RewsImage = styled(motion.img)`
    width: 50px;
    height: 50px;
    margin-right: -30px;
  `,
  RewsBgEffect = styled(motion.div)`
    position: absolute;
    top: 0;
    left: 25px;
    background: radial-gradient(#29ab88, #ffffff00);
    transform: translate(0%, 0%);
    width: 50px;
    height: 50px;
    filter: blur(30px);
    z-index: -1;
    animation: rotate 1s infinite;
    @keyframes rotate {
      0% {
        rotate: 360deg;
      }
    }
  `,
  ContentBox = styled.div(
    () => css`
      display: grid;
      grid-template-rows: 0fr;
      transition: 0.3s ease-in-out;
      &.isOpen {
        grid-template-rows: 1fr;
        & > ${ContentOverflowWrap} {
          opacity: 1;
          transform: scale(1);
          margin-top: 24px;
        }
      }
    `
  ),
  ContentOverflowWrap = styled.div`
    opacity: 0;
    transform: scale(0.95);
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    will-change: transform opacity;
  `,
  Body = styled.div(
    () => css`
      width: 100%;
      display: flex;
      align-items: center;
      padding-top: 24px;
    `
  ),
  Content = styled.div(
    () => css`
      .content-area {
        display: flex;
        flex-direction: column;
        gap: 22px;

        padding: 20px 0 0 0;

        @media screen and (max-width: 992px) {
          padding: 35px 0 0;
        }
      }

      .header {
        display: flex;
        flex-direction: column;
        gap: 22px;
        margin-bottom: 23px;
      }

      .header h1 {
        margin: 0;

        font-weight: 500;
        font-size: 2.8rem;
        line-height: 1;

        @media screen and (max-width: 992px) {
          font-size: 18px;
        }
      }

      .header p {
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 145%;

        margin: 0;
      }

      .header .pics {
        display: flex;
        flex-wrap: wrap;
      }

      .lesson-content-space img {
        width: 180px;
        height: auto;

        margin-bottom: 20px;
      }

      .multi {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 20px;

        @media screen and (max-width: 992px) {
          .answer {
            grid-column: span 1;
          }
          .answer.big {
            grid-column: span 2;
          }
        }
      }
      .lesson-content-space:last-of-type {
        padding-top: 0;
      }
      .check-answer-button {
        bottom: -35px;
      }
    `
  ),
  RewardsClaimed = styled(motion.div)<{ $isRewards?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    color: #29ab88;
    font-size: 18px;
    font-weight: 500;
    margin: 48px auto 0px;
    width: fit-content;
    padding: 0px 0px 0px 20px;
    height: 60px;
    background: #29ab881a;
    border-radius: 10px;
    ${({ $isRewards }) => !$isRewards && 'background: none;'}
    @media screen and (max-width: 992px) {
        margin-top: 36px;
    }
  `,
  ClosingGap = styled.div`
    margin-top: 0px;
    height: 1px;
    animation: shrink 0.5s ease-in-out;

    @keyframes shrink {
      from {
        margin-top: 98px;
      }
      to {
        margin-top: 0px;
      }
    }
  `;

export const rewsClaimedVariants = {
  initial: {
    opacity: 0,
    scale: 0.8,
  },
  animate: {
    opacity: 1,
    scale: 1,
    transition: { delay: 0.3 },
  },
  exit: {
    opacity: 0,
    scale: 0.3,
  },
};

export const getRewsImageVariants = (delay: number) => ({
  initial: {
    y: 10,
    opacity: 0,
    scale: 0.9,
  },
  animate: {
    y: 0,
    opacity: 1,
    scale: 1,
    transition: { delay },
  },
});
