import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { nanoid } from 'nanoid';
import { IUser, IUserNoAvatar } from 'interface';
import { getPeopleOnline } from 'store';

import FriendFinder from './finder-card';
import ProfileFriendsPlaceholder from './ProfileFriendsPlaceholder/placeholder';
import FriendCard from './FriendCard';
import LittleItemsCarousel from 'shared/ui/little-items-carousel';

import './friend-finder.css';
import styles from './styles.module.css';
import RefsSocialsBlock from 'components/pages/referrals/screen/socialsBlock';
import { ReferralsToast } from 'components/pages/referrals';

interface IProps {
  friends: IUser[] | IUserNoAvatar[];
  className?: string;
}

const FriendsSmall = ({ friends = [], className = '' }: IProps) => {
  const peopleOnline = useSelector(getPeopleOnline);

  const insertedItems = [<FriendFinder key={nanoid()} className={styles.finder} />];

  const friendsToShow = friends.map((friend) => (
    <FriendCard key={friend?._id} isInteractive isFramed={false} {...friend} isOnline={peopleOnline.includes(friend?._id)} />
  ));

  return (
    <>
      <div className={clsx('friends', { [className]: !!className })}>
        {friends.length > 0 ? (
          <LittleItemsCarousel insertedItems={insertedItems} className={styles.carousel} items={friendsToShow} />
        ) : (
          <ProfileFriendsPlaceholder />
        )}
      </div>
      <div className={clsx('invite-friends')}>
        <div>
          <RefsSocialsBlock title="Invite friends:" titleStyles={{ fontSize: 22, marginBottom: 24, fontWeight: 500 }} />
        </div>
        <ReferralsToast />
      </div>
    </>
  );
};

export default FriendsSmall;
