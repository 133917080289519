import { useNavigate, useParams } from 'react-router-dom';

import { BsChevronDown, BsCheck2 } from 'react-icons/bs';
import { ReactComponent as Lock } from '../../../../../library/chaptersGrid/assets/lock.svg';

import InventoryItem from '../../../../../library/cards/inventory/InventoryItem';
import { useDisplay } from '../../../../../../hooks';
import { motion, AnimatePresence } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

const SideChapter = ({ chapter, isOpen, openThis, order }) => {
  const navigate = useNavigate();
  const theme = useSelector(getCurrentTheme);
  const { courseId } = useParams();
  const { isMobile } = useDisplay();
  const { _id, name, lessons, isLocked } = chapter;

  const accordionVariants = {
    open: { opacity: 1, height: 'auto', overflow: 'hidden' },
    collapsed: { opacity: 0, height: 0 },
    exit: { opacity: 0, height: 0 },
  };

  function handleNav(lessonId) {
    if (!isLocked && courseId && name && lessonId) navigate(`../courses/${courseId}/${_id}/${lessonId}`);
  }

  return (
    <div className={`sidepanel__chapter ${isOpen ? 'open' : ''}`}>
      <div className={`sc__chaptername ${theme}`} role="button" onClick={() => openThis(order)}>
        {name}
        <BsChevronDown strokeWidth={2} style={{ transform: isOpen && 'rotateX(180deg)', height: '10px' }} />
      </div>
      <AnimatePresence exitBeforeEnter>
        {isOpen && (
          <motion.div variants={accordionVariants} initial="collapsed" animate="open" exit="collapsed" transition={{ duration: 0.5 }}>
            <div className={`sidechapter__lessons ${theme}`}>
              {lessons.map(({ name: lessonName, type, rewards, isLocked, isDone, index: lessonIndex, _id: lessonId }, index) => {
                let label = (
                  <span>
                    <span style={{ whiteSpace: 'pre', marginRight: '.5rem', fontWeight: '500' }}>
                      {type} {(lessonIndex || index) + 1}:
                    </span>
                    {lessonName}
                  </span>
                );

                return (
                  <div className={`sidechapter__lesson ${isLocked ? 'locked' : ''}`} key={'sidechapter__lesson' + index}>
                    {isLocked ? (
                      <div className={`sc__lessonname ${isLocked ? 'locked' : ''}`}>
                        <Lock />
                        <p>{label}</p>
                      </div>
                    ) : (
                      <div className={`sc__lessonname ${!isDone && 'in-progress'}`} onClick={() => handleNav(lessonId)} role="button">
                        <BsCheck2 />
                        <p>{label}</p>
                      </div>
                    )}
                    {!!rewards && rewards.length > 0 && (
                      <div className="sc__rewards">
                        {rewards.map(({ id, quantity }, index) => (
                          <InventoryItem key={'sc__reward' + index} itemId={id} amount={quantity} isSmall isShowTooltip={!isMobile} />
                        ))}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default SideChapter;
