import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      margin-top: 46px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      grid-area: lower;
      & .rewards {
        max-width: 300px;
        overflow-x: auto;
        padding-bottom: 5px;
      }
      & .rewards.mobile > div {
        margin-right: -30px;

        &:last-of-type {
          margin-right: 0;
        }
      }
    `
  ),
  Label = styled.div(
    () => css`
      display: flex;
      align-items: center;
    `
  ),
  Text = styled.p(
    () => css`
      border-radius: 30px;
      display: flex;
      width: fit-content;
      height: 49px;
      padding: 16px 20px;
      align-items: center;
      margin-right: -10px;

      @media screen and (max-width: 428pt) {
        display: none;
      }
    `
  );
