import { EItemFungibleType } from 'interface';

type ItemData = {
  value: number;
  desc: string[];
  getFullDesc(): string;
};

type DataMap = {
  [key in EItemFungibleType]?: ItemData;
};

function createItemData(value: number, desc: string[]) {
  return {
    value,
    desc,
    getFullDesc() {
      return this.desc.join(' ');
    },
  };
}

export const useGetEffectData = (fungibleType: EItemFungibleType) => {
  const data: DataMap = {
    [EItemFungibleType.SmallEnergyPotion]: createItemData(10, ['+10%', 'Energy']),
    [EItemFungibleType.LargeEnergyPotion]: createItemData(20, ['+20%', 'Energy']),
    [EItemFungibleType.SmallEnergyStone]: createItemData(50, ['+50%', 'for 2 hours']),
    [EItemFungibleType.LargeEnergyStone]: createItemData(100, ['+100%', 'for 2 hours']),
    [EItemFungibleType.ExpPotion]: createItemData(20, ['+20%', 'EXP for 30 minutes']),
    [EItemFungibleType.EnhancedExpPotion]: createItemData(30, ['+30%', 'EXP for 30 minutes']),
    [EItemFungibleType.Amethyst]: createItemData(10, ['+10%', 'EXP for 3 days']),
    [EItemFungibleType.YellowDiamond]: createItemData(20, ['+20%', 'EXP for 3 days']),
  };

  return data[fungibleType] ? data[fungibleType] : data[EItemFungibleType.SmallEnergyPotion];
};
