import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';

import { shallowEqual, useSelector } from 'react-redux';
import { getIsAuth, setIsMainMenu } from '../../../store';
import { EMenuScreens, ROUTES } from 'app/constants';
import { dispatch } from 'hooks';

const SidebarItem = styled.div`
  transition: all 350ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 11px;

  list-style: none;
  text-decoration: none;

  height: 24px;
  width: fit-content;

  cursor: pointer;
  &:hover {
    transform: translateX(10px);
  }

  & * {
    stroke: ${({ theme }) => theme.theme.fontColor};
  }
`;

const SidebarLink = styled.span`
  transition: all 350ms ease-in-out;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 11px;

  list-style: none;
  text-decoration: none;

  height: 24px;
  width: fit-content;
  cursor: pointer;

  &.active {
    transition: all 350ms ease-in-out;
    span,
    svg * {
      color: ${({ theme }) => theme.theme.basicBlue};
      stroke: ${({ theme }) => theme.theme.basicBlue};
    }
    &:hover {
      transform: translateX(0);
    }
  }

  &:hover {
    transform: translateX(10px);
  }
`;

const SidebarSpan = styled.span`
  transition: all 150ms ease-in-out 0.1s;
  width: 100%;

  display: flex;
  justify-content: flex-start;
  gap: 11px;

  overflow: hidden;
`;

const DropdownLink = styled.span`
  transition: all 350ms ease-in-out;
  & svg {
    fill: ${({ theme }) => theme.theme.fontColor};
  }

  height: 24px;
  width: fit-content;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  padding-left: 20px;

  text-decoration: none;

  cursor: pointer;

  &.active {
    & * {
      color: ${({ theme }) => theme.theme.basicBlue};
      fill: ${({ theme }) => theme.theme.basicBlue};
    }
    &:hover {
      transform: translateX(0);
    }
  }

  &:hover {
    transform: translateX(10px);
  }
`;

const IconWrapper = styled.div`
  width: 30px;
  height: 30px;
  min-width: 30px;
  min-height: 30px;

  display: flex;
  justify-content: center;
  align-items: center;

  & * {
    color: ${({ theme }) => theme.theme.fontColor};
    stroke: ${({ theme }) => theme.theme.fontColor};
  }
`;

const SubMenu = ({ isOpen, item }) => {
  const isAuth = useSelector(getIsAuth, shallowEqual);

  const [subnav, setSubnav] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const showSubnav = () => setSubnav(!subnav);
  const { t } = useTranslation();

  const { subNav, path, icon, iconClosed, translationKey, publicPath } = item;

  const getIsActive = (path) => path === location.pathname;

  const currentPath = isAuth ? path : publicPath;

  const handleNav = (path) => () => {
    if (path === ROUTES.SETTINGS) {
      dispatch(setIsMainMenu({ isMainMenu: true, screen: EMenuScreens.SETTINGS }));
      return;
    }
    if (location.pathname !== path) {
      navigate(path);
    }
  };

  const handleClickSubNav = (path) => {
    if (!isAuth) {
      return handleNav(path);
    }
    return subNav && showSubnav;
  };

  if (subNav) {
    return (
      <>
        <SidebarItem className="sidebar-item" onClick={handleClickSubNav(currentPath)}>
          <IconWrapper className="icon-wrapper">{icon}</IconWrapper>
          <SidebarSpan className="sidebar-label" id="label">
            {t('sidebar.' + translationKey)}
            <div
              className="icon-close"
              style={{
                transform: `${subnav ? 'rotate(180deg)' : ''}`,
              }}
            >
              {iconClosed}
            </div>
          </SidebarSpan>
        </SidebarItem>

        {isOpen &&
          subnav &&
          subNav.map((item, index) => (
            <DropdownLink
              className={`dropdown-link ${getIsActive(item.path) ? 'active' : ''}`}
              id="dropdown_link"
              onClick={handleNav(item.path)}
              key={'dropdown-link' + index}
            >
              {item.icon}
              <SidebarSpan className="sidebar-label" id="sidebar_label">
                {t('sidebar.' + item.title.toLowerCase())}
              </SidebarSpan>
            </DropdownLink>
          ))}
      </>
    );
  }
  return (
    <SidebarLink className={`sidebar-link ${getIsActive(currentPath) ? 'active' : ''}`} onClick={handleNav(currentPath)}>
      <IconWrapper className="icon-wrapper" id="icon_wrapper">
        {icon}
      </IconWrapper>
      <SidebarSpan className="sidebar-label" id="label">
        {t('sidebar.' + translationKey)}
      </SidebarSpan>
    </SidebarLink>
  );
};

export default SubMenu;
