import styled, { css } from 'styled-components';

export const CustomSlide = styled.div<{ $width?: number; $height?: number; $gap?: number; $decreaseWidth?: number; $hideOverflow?: boolean }>(
  ({ $width, $gap, $decreaseWidth, $height, $hideOverflow }) => css`
    ${$width &&
    `
            min-width: ${$width}px !important;
            width: ${$width}px !important;
        `}
    ${$height &&
    `
            min-height: ${$height}px !important;
            height: ${$height}px !important;
        `}
        ${$decreaseWidth &&
    `
            margin-right: -${$decreaseWidth}px !important;
            margin-left: ${$decreaseWidth}px !important;
        `}
        display: flex !important;
    justify-content: center;
    align-items: center;
    padding-right: ${$decreaseWidth ?? $gap ?? 0}px;
    box-sizing: content-box;
    overflow: ${$hideOverflow ? 'hidden' : 'visible'};
  `
);
