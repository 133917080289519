import { useEffect, useRef, useState } from 'react';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

type Props = {
  isFetching: boolean;
  isFinished: boolean;
  initiate: () => void;
};

const PagLoader = ({ initiate, isFetching, isFinished }: Props) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [isInit, setInit] = useState(false);

  const handleScroll = () => {
    if (ref.current && !isInit && !isFinished) {
      const windowPos = window.scrollY + window.innerHeight;
      const refPos = ref.current.getBoundingClientRect().top;

      if (windowPos >= refPos) {
        initiate();
        setInit(true);
        setTimeout(() => {
          setInit(false);
        }, 2000);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);

    return () => document.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div style={{ height: '200px' }} ref={ref}>
      {isFetching && <MightyLoaderMini />}
    </div>
  );
};

export default PagLoader;
