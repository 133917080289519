import { useEffect, useState } from 'react';
import { useLazyGetDailyRewardsQuery } from 'store';
import clsx from 'clsx';
import { CARDS_SIZES, IDailyReward, IDailyRewardsProps } from './types';

import Header from './header';
import { MultipurposeBannerLoader } from 'shared/ui';

import styles from './styles.module.css';

export function DailyRewardsUnAuth({ isBannerOnly = false, className = '', bannerCardsSize = CARDS_SIZES.ISSMALL }: IDailyRewardsProps) {
  const [getDailis] = useLazyGetDailyRewardsQuery();
  const [dailis, setDailis] = useState<IDailyReward[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    async function getRewards() {
      setIsLoading(true);
      await getDailis()
        .unwrap()
        .then((re) => setDailis(re.rewards));
      setIsLoading(false);
    }
    getRewards();
    return () => setDailis([]);
  }, []);

  if (isLoading || dailis.length < 1) {
    return <MultipurposeBannerLoader label="Loading daily rewards..." />;
  }

  return (
    <div className={clsx(styles.container, { [styles.bannerOnly]: isBannerOnly, [className]: !!className })} role="button">
      <Header />
      {/* <div className={styles.rewards}>
        {dailis.map((rew, index: number) => {
          return (
            <CalendarCard
              key={rew._id + index}
              amount={rew.quantity}
              day={rew.day}
              itemId={rew.id}
              {...{ [bannerCardsSize]: !!bannerCardsSize }}
              isCollected={index < 2}
            />
          );
        })}
      </div> */}
    </div>
  );
}
