export const opacityVariants = {
  show: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
  },
};

export const rotateVariants = {
  animate: {
    rotate: 360,
  },
};
