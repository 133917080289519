import { FC, useState } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { setActiveUserMenuPanel } from 'store';
import { dispatch, useGetEffectsData } from 'hooks';

import { EffectIndicator } from 'shared/ui';

import { ReactComponent as Angle } from 'assets/icons/angle.svg';
import * as Styled from './styled';

export const Effects: FC<{ $larger?: boolean }> = ({ $larger = false }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { effects } = useGetEffectsData();

  const showSeeAll = isHovered && effects.length > 1;

  return (
    <Styled.Container
      as={motion.div}
      onClick={() => dispatch(setActiveUserMenuPanel('effects'))}
      onHoverStart={() => setIsHovered(true)}
      onHoverEnd={() => setIsHovered(false)}
      className={clsx(showSeeAll && 'effects__active')}
      $larger={$larger}
    >
      {effects.slice(0, 3).map(({ effect, type, amount }, index) => (
        <EffectIndicator
          key={type + index}
          effect={effect}
          type={type}
          amount={amount}
          zIndex={10 - index}
          showAmount={isHovered && index === 0}
          className={'effects__effect-indicator'}
        />
      ))}
      <AnimatePresence>
        {showSeeAll && (
          <Styled.SeeAll as={motion.div} initial={{ opacity: 0, width: 0 }} animate={{ opacity: 1, width: '70px' }} exit={{ opacity: 0, width: 0 }}>
            <span>See All</span>
            <Angle width={'14px'} height={'14px'} strokeWidth={'1.2px'} color={'var(--font-color-strong)'} />
          </Styled.SeeAll>
        )}
      </AnimatePresence>
    </Styled.Container>
  );
};
