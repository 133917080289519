import { MouseEvent, useMemo, useReducer } from 'react';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { useGetEffectsData } from 'hooks';

import { EffectIndicator } from 'shared/ui';
import { HoverData } from 'shared/ui/effect-indicator';

import { IState, IProps } from './types';
import { defaultState, variants, getIsAllowed, transition } from './data';

import * as Styled from './styles';

export const EffectsWidget = ({ className = '' }: IProps) => {
  const { effects, handleTimeout } = useGetEffectsData();
  const [{ isAnimating, label }, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), defaultState);

  const handleUpdateState = (value: Partial<IState>) => updateState(value);

  const handleAnimation = (value: boolean) => () => {
    if (!!label !== value) {
      handleUpdateState({ isAnimating: value });
    }
  };

  const handleMouseLeave = () => handleUpdateState({ label: null });

  const handleMouseEnter = (name: any) => (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (getIsAllowed(name)) {
      handleUpdateState({ label: name });
    }
  };

  const activeIndex = useMemo(() => effects.map((i) => i.type).findIndex((eff) => eff === label), [effects, label]);

  return (
    <Styled.Container className={clsx({ [className]: !!className })}>
      {effects.map(({ type: effectName, ...rest }, index) => {
        const isActive = index === activeIndex;
        const isRightToActive = index - 1 === activeIndex;
        return (
          <Styled.Indicator
            className={clsx('effect', { animated: isAnimating, active: isActive, next: isRightToActive })}
            key={effectName + index}
            onMouseEnter={handleMouseEnter(effectName)}
            onMouseLeave={handleMouseLeave}
          >
            <EffectIndicator
              {...rest}
              className={clsx('indicator')}
              isClickable
              onTimeout={handleTimeout(effectName)}
              zIndex={10 - index}
              type={effectName}
            />
            <AnimatePresence exitBeforeEnter>
              {label === effectName && (
                <Styled.HoverItem
                  as={motion.div}
                  variants={variants}
                  initial={'hide'}
                  animate={isActive ? 'show' : 'hide'}
                  exit={'hide'}
                  transition={transition}
                  onAnimationStart={handleAnimation(Boolean(label))}
                >
                  <HoverData type={label} />
                </Styled.HoverItem>
              )}
            </AnimatePresence>
          </Styled.Indicator>
        );
      })}
    </Styled.Container>
  );
};
