import { FC, memo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';

import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import { ReactComponent as CheckIcon } from 'assets/icons/check-outline-rounded.svg';
import { ReactComponent as WalletM } from '../../../../library/images/ShopInventory/wallet-minimal.svg';
import { ReactComponent as Shop } from 'assets/icons/sidebar-icons/shop.svg';

import styles from './styles.module.scss';

interface IItemDescProps {
  image: string;
  name: string;
  grade?: string;
  userBalance: number;
  quantity: number | null;
  type?: 'item' | 'partner';
  isActivated?: boolean;
  isLoading?: boolean;
}

export const ItemDescUI: FC<IItemDescProps> = ({ image, name, grade, userBalance, type = 'item', isActivated, quantity, isLoading }) => {
  const theme = useSelector(getCurrentTheme, shallowEqual);
  const color = grade ? `var(--grade-${theme}-color-${grade})` : '';

  return (
    <div className={clsx(styles.itemdesc, styles[type])}>
      <div className={styles.imgWrap}>
        <img className={styles.img} src={image} alt={name} loading={'lazy'} />
        {isActivated && (
          <div className={styles.activated}>
            <CheckIcon width={'19px'} height={'19px'} />
            Item is activated
          </div>
        )}
      </div>
      <div className={styles.info}>
        <div className={styles.namegrade}>
          <h4 style={{ color }}>{name}</h4>
          <p style={{ color }}>{grade}</p>
        </div>
        <span className={styles.quantity}>
          <WalletM className={styles.wallet} />
          {userBalance} in inventory
        </span>
        {quantity !== null && (
          <span className={styles.quantity}>
            <Shop className={styles.wallet} />
            {isLoading ? <MightyLoaderMini className={styles.loader} /> : quantity} in shop
          </span>
        )}
      </div>
    </div>
  );
};

export const ItemDesc = memo(ItemDescUI);
