import { FC, useMemo } from 'react';
import { useDisplay } from 'hooks';
import { ESlideTypes } from 'store';
import { DndContext, closestCenter, MouseSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import clsx from 'clsx';
import { useEditorFunctions } from './hooks/useEditorFunctions';
import { ISlideFull } from 'interface/courses';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as DotsIcon } from 'assets/icons/dots-double-vertical.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';

import EmptyPlaceholder from 'components/library/emptyPlaceholder';
import TestCourseConstructorContainer from 'components/library/courseConstructor/components/TestConstructorContainer';
import InfoCourseConstructorContainer from 'components/library/courseConstructor/components/InfoConstructorContainer';
import { CourseConstructorSlideContext } from 'components/library/courseConstructor/components/SlideContext';
import SlideContainer from 'components/library/courseConstructor/components/SlideContainer';
import TestSlideTypeForm from 'components/library/courseConstructor/components/TestConstructorContainer/components/TestSlideTypeForm';
import CourseConstructor from 'components/library/courseConstructor';
import Layout from 'pages/coursesConstructor/components/Layout';
import Paginator from './Paginator';
import { BasicButtonV1 } from 'shared/ui';
import MobileWrapper from './MobileWrapper';
import LogoLoader from 'shared/ui/loader/MightyLoader';

import { buildBreadcrumbs } from 'utils/breadcrumbs';

import styles from './styles.module.scss';

const SortableItem: FC<{ id: string; index: number; children: React.ReactNode; isReordering: boolean }> = ({ id, index, children, isReordering }) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id, disabled: isReordering });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: 999 - index,
  };

  return (
    <div ref={setNodeRef} style={style} className={styles.dndItem}>
      <div className={styles.dragIcon} {...attributes} {...listeners}>
        <DotsIcon />
      </div>
      {children}
    </div>
  );
};

const CoursesEditLesson: FC = () => {
  const {
    spaceName,
    isError,
    slides,
    isSlidesLoading,
    isCreateSlideLoading,
    isSlideUpdating,
    isGeneratingTest,
    isDuplicationLoading,
    isAddAttachmentLoading,
    isDeleteAttachmentLoading,
    isReordering,
    breadcrumbs,
    testSlideActiveIndex,
    allRewards,
    isQuest,
    handleCreateSlide,
    handleCreateEmptyTestSlide,
    handleDeleteSlide,
    handleTestSlideAdd,
    handleUpdateSlide,
    handleAddAttachment,
    handleDeleteAttachment,
    handleDuplicateSlide,
    handleDragEnd,
    handleSlideConvertation,
    toggleAnyCorrect,
    toggleIsOptional,
  } = useEditorFunctions();

  const sensors = useSensors(useSensor(MouseSensor));

  const breadcrumbsPrep = useMemo(() => (isQuest ? [] : buildBreadcrumbs(breadcrumbs, spaceName)), [isQuest, breadcrumbs, spaceName]);
  const title = useMemo(() => (breadcrumbs?.lesson?.name ? `Edit ${breadcrumbs?.lesson?.name}` : 'Loading lesson...'), [breadcrumbs?.lesson?.name]);

  if (isError) {
    return <div>Something went wrong...</div>;
  }

  return (
    <Layout title={title} breadcrumbs={breadcrumbsPrep}>
      {!isQuest && <Pagination slides={slides} />}
      {isSlidesLoading ? (
        <LogoLoader className={styles.loader} />
      ) : (
        Boolean(slides) && (
          <>
            <div className={styles.wrapper}>
              <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
                <SortableContext items={slides.map((slide) => slide._id)} strategy={verticalListSortingStrategy}>
                  {Boolean(slides.length) &&
                    slides.map((item: any, index: number) => (
                      <CourseConstructorSlideContext.Provider
                        key={item._id}
                        value={{
                          slide: item,
                          loading: isAddAttachmentLoading || isDeleteAttachmentLoading || isDuplicationLoading || isSlideUpdating || isGeneratingTest,
                          attachmentAction: {
                            add: handleAddAttachment,
                            delete: handleDeleteAttachment,
                            convert: handleSlideConvertation,
                            toggleAnyCorrect,
                            toggleIsOptional,
                          },
                        }}
                      >
                        <SortableItem id={item._id} index={index} isReordering={isReordering}>
                          <CourseConstructor index={index} slideId={item._id}>
                            {item.typeOfSlide === ESlideTypes.INFO ? (
                              <InfoCourseConstructorContainer
                                path={{ ...breadcrumbs, slide: index }}
                                allRewards={allRewards}
                                onChange={handleUpdateSlide}
                                onSlideDelete={handleDeleteSlide(item._id)}
                                isQuest={isQuest}
                              />
                            ) : (
                              <TestCourseConstructorContainer
                                path={{ ...breadcrumbs, slide: index }}
                                allRewards={allRewards}
                                onChange={handleUpdateSlide}
                                onSlideDelete={handleDeleteSlide(item._id)}
                                isQuest={isQuest}
                              />
                            )}
                          </CourseConstructor>
                          {!isQuest && (
                            <div className={styles.controls}>
                              <BasicButtonV1
                                className={styles.menuButton}
                                fullWidth={false}
                                size="sm"
                                variant="rounded"
                                disabled={isCreateSlideLoading}
                                onClick={handleCreateSlide(ESlideTypes.INFO, index)}
                              >
                                <PlusIcon className={styles.controls__icon} />
                                Add a new slide
                              </BasicButtonV1>
                              <BasicButtonV1
                                className={styles.menuButton}
                                fullWidth={false}
                                size="sm"
                                variant="rounded"
                                disabled={isCreateSlideLoading}
                                onClick={handleTestSlideAdd(index)}
                              >
                                <PlusIcon className={styles.controls__icon} />
                                Add a new test slide
                              </BasicButtonV1>
                              <BasicButtonV1
                                className={styles.menuButton}
                                fullWidth={false}
                                size="sm"
                                variant="rounded"
                                disabled={isDuplicationLoading}
                                onClick={handleDuplicateSlide(index)}
                              >
                                <CopyIcon className={styles.controls__icon} />
                                Duplicate
                              </BasicButtonV1>
                            </div>
                          )}
                          {testSlideActiveIndex === index && (
                            <SlideContainer>
                              <TestSlideTypeForm onChange={handleCreateEmptyTestSlide(index)} />
                            </SlideContainer>
                          )}
                        </SortableItem>
                      </CourseConstructorSlideContext.Provider>
                    ))}
                </SortableContext>
              </DndContext>
              {!slides.length && (
                <>
                  {testSlideActiveIndex === 0 && (
                    <SlideContainer>
                      <TestSlideTypeForm onChange={handleCreateEmptyTestSlide(0)} />
                    </SlideContainer>
                  )}
                  {testSlideActiveIndex < 0 && (
                    <>
                      <EmptyPlaceholder>There are no slides</EmptyPlaceholder>
                      <div className={clsx(styles.controls, styles.default)}>
                        <BasicButtonV1
                          fullWidth={false}
                          size="sm"
                          variant="rounded"
                          disabled={isCreateSlideLoading}
                          onClick={handleCreateSlide(ESlideTypes.INFO, 0)}
                        >
                          <PlusIcon className={styles.controls__icon} />
                          Add a new slide
                        </BasicButtonV1>
                        <BasicButtonV1 fullWidth={false} size="sm" variant="rounded" disabled={isCreateSlideLoading} onClick={handleTestSlideAdd(0)}>
                          <PlusIcon className={styles.controls__icon} />
                          Add a new test slide
                        </BasicButtonV1>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </>
        )
      )}
    </Layout>
  );
};

export default CoursesEditLesson;

const Pagination = ({ slides }: { slides: ISlideFull[] }) => {
  const { isMobile } = useDisplay();
  return isMobile ? <MobileWrapper slides={slides} /> : <Paginator items={slides} />;
};
