import styles from './styles.module.css';
import { CheckCodeForm } from './checkCodeForm/checkCodeForm';
import { AnimateWrapper } from '../../ui';
import { ICheckCodeForm } from 'interface';
import { Modal } from 'shared/ui';
import { useTranslation } from 'react-i18next';

interface IProps {
  open: boolean;
  isLoading: boolean;
  error: string;
  onSubmitHandler: (data: ICheckCodeForm) => void;
  onCloseModalHandler: () => void;
  getCodeAgainHandler: () => Promise<void>;
}

export const CheckCodeModal = ({ open, isLoading, error, onSubmitHandler, onCloseModalHandler, getCodeAgainHandler }: IProps) => {
  const { t } = useTranslation('auth');
  return (
    <Modal className={styles.modal} open={open} onClose={onCloseModalHandler}>
      <AnimateWrapper>
        <div className={styles.container}>
          <div className={styles.title}>{t('resetPassword.checkCode.title')}</div>
          <div className={styles.subTitle}>{t('resetPassword.checkCode.subTitle')}</div>
          <CheckCodeForm getCodeAgainHandler={getCodeAgainHandler} error={error} onSubmitHandler={onSubmitHandler} isLoading={isLoading} />
        </div>
      </AnimateWrapper>
    </Modal>
  );
};
