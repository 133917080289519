import { useGetCurrentTheme } from 'hooks';
import * as S from '../../styles';
import { ReactNode, useMemo } from 'react';
import { AnimatePresence } from 'framer-motion';
import ResizebleSkeleton from 'shared/ui/course-card/CourseCard/utils/ui/skeletons/resizebleSkeleton';

type Props = {
  title: string;
  number: number;
  requiresCheck?: boolean;
  onClick?: () => void;
  isLoading?: boolean;
  icon: ReactNode;
};

const bannerVariants = {
  animate: {
    opacity: 1,
    height: 27,
  },
  initial: {
    opacity: 0,
    height: 0,
  },
  exit: {
    opacity: 0,
    height: 0,
  },
};

const InfoBox = ({ number, onClick, title, isLoading, requiresCheck, icon }: Props) => {
  const theme = useGetCurrentTheme();

  const formattedNumber = useMemo(() => (number > 1999 ? `${(number - (number % 1000)) / 1000}K` : number), [number]);

  return (
    <S.Box $theme={theme} onClick={onClick} $active={requiresCheck}>
      {isLoading ? (
        <ResizebleSkeleton
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
          }}
        />
      ) : (
        <>
          <S.BoxTitle $theme={theme}>{title}</S.BoxTitle>
          <S.IconBox $active={requiresCheck}>{icon}</S.IconBox>
          <S.Num $theme={theme}>{formattedNumber}</S.Num>
          <AnimatePresence>
            {!isLoading && requiresCheck && (
              <S.RequiresCheck variants={bannerVariants} animate={'animate'} exit={'exit'} initial={'initial'}>
                Check required
              </S.RequiresCheck>
            )}
          </AnimatePresence>
        </>
      )}
    </S.Box>
  );
};

export default InfoBox;
