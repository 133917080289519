import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import clsx from 'clsx';
import styles from './iconButton.module.css';

interface TIconButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  icon: React.ReactNode;
  size?: number;
}

const IconButton: FC<TIconButtonProps> = ({ icon, size = 30, className = '', ...props }) => {
  return (
    <button {...props} style={{ width: `${size}px`, height: `${size}px` }} className={clsx(styles.root, { [className]: !!className })}>
      {icon}
    </button>
  );
};

export default IconButton;
