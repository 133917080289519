import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import BasicButton from 'shared/ui/basic-button/button';
import { useDebounce, useGetIsAuth, useNavigateToAuth } from 'hooks';
import { Loader, Placeholder } from './placeholders';
import * as S from '../styles';

type Props = {
  isCompleted: boolean;
  isQuest: boolean;
  isSpace: boolean;
  nextLessonId: string | null;
  isPlaceholder: boolean;
  isLastSlideInfo: boolean;
  isAnimationStarted: boolean;
  isRequiredTask: boolean;
  finishLesson: () => void;
  toNextLesson: () => void;
};

const LessonBottom = ({
  isCompleted,
  isQuest,
  isSpace,
  nextLessonId,
  isPlaceholder,
  isLastSlideInfo,
  isAnimationStarted,
  finishLesson,
  toNextLesson,
  isRequiredTask,
}: Props) => {
  const [currentState, setCurrentState] = useState<'buttons' | 'placeholder' | 'loader' | null>(null);
  const navToAuth = useNavigateToAuth();
  const isAuth = useGetIsAuth();
  const debounce = useDebounce();

  const nextState: 'buttons' | 'placeholder' | 'loader' | null = useMemo(() => {
    if (!isAuth) {
      if (isLastSlideInfo) return 'buttons';
      if (isPlaceholder) return 'placeholder';
    }
    if ((isPlaceholder || isRequiredTask) && !isCompleted) return 'placeholder';
    if (isLastSlideInfo && !isCompleted) return 'loader';

    if (isCompleted) return 'buttons';

    return null;
  }, [isAuth, isPlaceholder, isLastSlideInfo, isRequiredTask, isCompleted]);

  const handleNextStateChange = useCallback(
    debounce(() => setCurrentState(nextState), 200),
    [nextState]
  );

  useEffect(() => {
    return () => {
      setCurrentState(null);
    };
  }, []);

  useEffect(() => {
    handleNextStateChange();
  }, [nextState]);

  const buttons = useMemo(
    () => (
      <>
        {isAuth && (
          <S.LessonBottom key="lesson-buttons">
            {!isQuest && !isSpace && !nextLessonId && (
              <BasicButton onClick={finishLesson} className="vertical-lesson-button" variant="white" label="Go to courses" />
            )}
            {(isQuest || isSpace) && !nextLessonId && (
              <BasicButton onClick={finishLesson} className="vertical-lesson-button" variant="white" label="Back to space" />
            )}
            {!isQuest && !!nextLessonId && (
              <BasicButton onClick={toNextLesson} className="vertical-lesson-button" variant="cool" label="Next Lesson" />
            )}
          </S.LessonBottom>
        )}
        {!isAuth && (
          <S.LessonBottom key="lesson-buttons">
            <BasicButton onClick={() => navToAuth()} className="vertical-lesson-button" variant="cool" label="Continue studying" />
          </S.LessonBottom>
        )}
      </>
    ),
    [isAuth, isQuest, isSpace, finishLesson, nextLessonId, isCompleted, navToAuth]
  );

  const placeholder = useMemo(
    () => <Placeholder key="test-placeholder" isUnlocking={isAnimationStarted} isTask={isRequiredTask} />,
    [isAnimationStarted, isRequiredTask]
  );
  const loader = useMemo(() => <Loader key={'lesson-loader'} isLoading={true} />, []);
  return (
    <AnimatePresence exitBeforeEnter>
      {currentState === 'buttons' && buttons}
      {currentState === 'placeholder' && placeholder}
      {currentState === 'loader' && loader}
    </AnimatePresence>
  );
};

export default React.memo(LessonBottom);
