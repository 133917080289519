import { FC, useCallback, useMemo, useRef } from 'react';

import * as Styled from './styles';
import { TWidgetFile } from 'store';
import { STATIC_HOST_PATH } from 'app/constants/path';
import IconButton from 'components/library/iconButton';
import { TimesIcon } from 'assets/icons/icons';
import CustomizationMenu from 'components/library/courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/NewCustomizationMenu';
import { EIcons } from 'components/library/courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/constants';
import { MenuIcons } from 'components/library/courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/ui';
import { TVariantWarningIcon } from 'components/library/courseConstructor/components/Widget/components/WarningWidget/WarningIcons/types';
import { useGetCurrentTheme } from 'hooks';

type TWarningProps = {
  children: any;
  onClick?: () => void;
  type?: TVariantWarningIcon;
  color?: string;
  image?: string;
  ref?: any;
  handleSelect?: (changes: any) => void;
  onDeleteImage?: () => void;
  onUploadImage?: () => void;
};

const Warning: FC<TWarningProps> = ({ children, onClick, type, image: plainImage, color, handleSelect, onDeleteImage, onUploadImage }) => {
  const iconRef = useRef(null);
  const theme = useGetCurrentTheme();
  const image = useMemo(() => {
    if (!plainImage) {
      return null;
    }

    try {
      return JSON.parse(plainImage) as TWidgetFile;
    } catch (err) {
      return null;
    }
  }, [plainImage]);

  const handleDeleteImage = useCallback(
    (evt: React.MouseEvent) => {
      evt.preventDefault();
      evt.stopPropagation();

      onDeleteImage?.();
    },
    [onDeleteImage]
  );

  const Element = ({ onClick }: any) => {
    const imageString = `url("${STATIC_HOST_PATH + image?.filePath.replace(/\\/gi, '/')}")`;

    return image ? (
      <Styled.Image
        ref={iconRef}
        onClick={onClick ? onClick : () => {}}
        style={{
          backgroundImage: imageString,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      >
        {Boolean(handleSelect) && (
          <IconButton icon={<TimesIcon style={{ width: 15, height: 15 }} />} size={30} className="warning-image-close" onClick={handleDeleteImage} />
        )}
      </Styled.Image>
    ) : (
      <Styled.IconContainer style={{ position: 'relative' }} ref={iconRef} onClick={onClick ? onClick : () => {}} color={color}>
        {/* <WarningIcons type={type ? type : 'Info'} /> */}
        <MenuIcons type={(type as EIcons) || EIcons.INFO} />
      </Styled.IconContainer>
    );
  };

  return (
    <Styled.WarningRoot $theme={theme} onClick={onClick} color={color} $withImage={Boolean(image)}>
      {handleSelect ? (
        <CustomizationMenu
          current={{ color: color || '#FFFFFF', icon: type || EIcons.INFO }}
          onSelect={handleSelect}
          onUploadImage={onUploadImage}
          disableSpecials
          Element={Element}
        />
      ) : (
        <Element />
      )}
      <Styled.Content $withImage={Boolean(image)}>{children}</Styled.Content>
    </Styled.WarningRoot>
  );
};

export default Warning;
