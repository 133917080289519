import { THEMES } from 'app/constants';
import styled from 'styled-components';
import arrow from '../assets/chevron-left.svg';
import arrowRight from '../assets/chevron-right.svg';
import arrowBlue from '../assets/arr-left-blue.svg';
import arrowBlueRight from '../assets/arr-right-blue.svg';

export const SliderArrows = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-end;
  gap: 6px;
  position: relative;
  z-index: 100;
`;

const arrows = {
  [THEMES.DARK]: {
    left: arrow,
    right: arrowRight,
  },
  [THEMES.LIGHT]: {
    left: arrowBlue,
    right: arrowBlueRight,
  },
};

export const SliderArrow = styled.div<{ $next?: boolean; $disabled?: boolean; hidden?: boolean; $theme: THEMES }>`
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-image: url(${(p) => (p.$next ? arrows[p.$theme].right : arrows[p.$theme].left)});
  background-position: center;
  background-size: 32px 32px;
  background-repeat: no-repeat;
  background-color: ${(p) => (p.$theme === THEMES.DARK ? '#ffffff0a' : '#627EEA1A')};

  &:hover {
    filter: brightness(1.1);
    /* transform: scale(1.1); */
  }
  ${(p) => p.$disabled && 'opacity: .4; pointer-events: none;'}
  transition: all .3s;

  cursor: pointer;
  ${(p) => p.hidden && 'display: none;'}
`;

export const SliderButtonSide = styled(SliderArrow)<{ $offset?: number }>`
  position: absolute;
  ${(p) => (p.$next ? 'right: 0;' : 'left: 0;')}
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translate(${(p) => (p.$offset || 0) * (p.$next ? 1 : -1) + 'px'}, -50%);
`;
