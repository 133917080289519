import styled, { css } from 'styled-components';
import lightning from './assets/lightning-premium.png';

export const Wrapper = styled.div<{ $margin?: string; $direction: string; $justifyContent?: string }>(
    ({ $margin, $direction, $justifyContent }) => css`
      display: flex;
      flex-direction: ${$direction};
      align-items: center;
      justify-content: ${$justifyContent};
      margin: ${$margin};
    `
  ),
  Container = styled.div<{ $maxWidth: number }>(
    ({ $maxWidth }) => css`
      position: relative;
      max-width: ${$maxWidth}px;
    `
  ),
  PremiumIcon = styled.img(
    () => css`
      width: 100%;
      position: relative;
      z-index: 2;
    `
  ),
  PremiumTitle = styled.h2<{ $margin: string; $fontSize?: string }>(
    ({ $margin, $fontSize }) => css`
      font-size: ${$fontSize ? $fontSize : '48px'};
      font-weight: 500;
      line-height: 115%;
      letter-spacing: 0.5px;
      background: var(--gold-bg);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      margin: ${$margin};
      position: relative;
      z-index: 2;

      @media screen and (max-width: 992px) {
        font-size: ${$fontSize ? $fontSize : '34px'};
      }
    `
  ),
  Lightning = styled.div<{ $options?: { top: string; left: string; width: string; height: string } }>(
    ({ $options = { top: '-61%', left: '-30%', width: '370px', height: '370px' } }) => css`
      background-image: url(${lightning});
      background-size: cover;
      background-repeat: no-repeat;
      position: absolute;
      width: ${$options.width};
      height: ${$options.height};
      top: ${$options.top};
      left: ${$options.left};
      mix-blend-mode: screen;
      pointer-events: none;
      filter: contrast(0.8);

      &.light {
        background-image: none;
        background-size: none;
        background-repeat: none;
      }
    `
  );
