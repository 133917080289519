import { EAuthMethods } from 'interface';
import TONLinkButton from './TONLinkButton';
import TempBlankButton from './temp-blank-button';
import GoogleLinkButton from './GoogleLinkButton';
import EVMLinkButton from './EVMLinkButton';
// import TelegramLinkButton from './TelegramLinkButton';

export const BUTTONS_BY_METHOD = {
  [EAuthMethods.TON]: TONLinkButton,
  [EAuthMethods.Email]: TempBlankButton,
  [EAuthMethods.Google]: GoogleLinkButton,
  [EAuthMethods.Metamask]: EVMLinkButton,
  [EAuthMethods.EVM]: EVMLinkButton,
  [EAuthMethods.Telegram]: null,
};
