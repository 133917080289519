import clsx from 'clsx';
import { dispatch } from 'hooks';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChevronDown } from 'react-icons/bs';
import { getCurrentLanguage, setCurrentLanguage } from 'store';
import { LANGUAGES } from 'utils/handleLanguageChange';

import { ReactComponent as Language } from '../../../assets/pictures/language.svg';

import styles from './styles.module.css';
import { useSelector } from 'react-redux';

interface IProps {
  className?: string;
}

export const Languages = ({ className = '' }: IProps) => {
  const currentLang = useSelector(getCurrentLanguage);
  const [isLangs, setIsLangs] = useState(false);
  const { i18n } = useTranslation();

  const theme = localStorage.getItem('theme');

  function handleLanguage(lang: any) {
    dispatch(setCurrentLanguage(lang));
    setIsLangs(false);
  }

  return (
    <div
      className={clsx(styles.container, { [className]: !!className }, `${isLangs ? 'active' : ''}`)}
      onClick={() => setIsLangs(!isLangs)}
      role="button"
    >
      <Language style={{ fill: 'var(--font-color)', stroke: 'none' }} />
      {LANGUAGES[currentLang as keyof typeof LANGUAGES].nativeName}
      <BsChevronDown strokeWidth={1} className={clsx(styles.toggleBtn, { [styles.open]: isLangs })} />
      {isLangs && (
        <div className={clsx(styles.langs, `glass-div basic ${theme}`)}>
          {Object.keys(LANGUAGES).map((lang) => (
            <button type="submit" key={lang} onClick={() => handleLanguage(lang)} disabled={i18n.resolvedLanguage === lang}>
              {LANGUAGES[lang as keyof typeof LANGUAGES].nativeName}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
