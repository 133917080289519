import { FC } from 'react';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import styles from './styles.module.scss';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { TGrade } from 'interface';

interface IProps {
  name: string;
  contract: string | null;
  image: string;
  grade: TGrade;
}

export const Info: FC<IProps> = ({ name, contract, image, grade }) => {
  const theme = useSelector(getCurrentTheme);

  const handleCopy = () => contract && navigator.clipboard.writeText(contract);

  return (
    <>
      <div className={styles.body}>
        <div className={styles.image}>
          <img src={image} alt={name} />
        </div>
        <h3 style={{ color: `var(--grade-${theme}-color-${grade})` }}>{name}</h3>
        <p style={{ color: `var(--grade-${theme}-color-${grade})` }}>Digital Collectible</p>
      </div>
      <div className={styles.addressWrap}>
        <p className={styles.addressLabel}>Contract address</p>
        <div className={styles.address}>
          <p>{contract}</p>
          <div className={styles.copy} role="button" onClick={handleCopy}>
            <CopyIcon />
          </div>
        </div>
      </div>
    </>
  );
};
