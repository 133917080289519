import { FC, useMemo, useState } from 'react';
import { IDatabaseItem } from 'interface';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';

import LittleItemsCarousel from 'shared/ui/little-items-carousel';
import { ArtifactWindow } from 'features/inventoryWindowItem/windowItem';
import { useDisplay } from 'hooks';
import { useNavigate } from 'react-router-dom';

import { actions, type IItemAction } from './data';
import { variants } from './motion';

import { ReactComponent as TransparentCross } from 'assets/components/transparent-cross.svg';

import { EActivateItemType } from '../../features/ActivateItem/types/types';
import { ROUTES } from 'app/constants';
import styles from './styles.module.scss';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

interface IItemActionsProps {
  item?: IDatabaseItem;
  setSelectedAction: (action: IItemAction | undefined) => void;
  selectedAction: IItemAction | undefined;
  type: EActivateItemType;
  userBalance: number;
}
export const ItemActions: FC<IItemActionsProps> = ({ item, selectedAction, setSelectedAction, type, userBalance }) => {
  const theme = useSelector(getCurrentTheme, shallowEqual);
  const [showDesc, setShowDesc] = useState(false);
  const [showInventory, setShowInventory] = useState(false);
  const { isMobile } = useDisplay();
  const navigate = useNavigate();
  const desc = item?.description;
  const codeActive = true;

  const isChest = item?.fungibleType?.includes('-chest');

  const handleClick = useMemo(
    () => (action: IItemAction) => {
      if (action.name === 'itemDetails' || action.name === 'itemLore') {
        setShowDesc(true);
      }
      if (action.name === 'seeInShop') {
        navigate(ROUTES.SHOP);
      }
      if (action.name === 'seeInInventory') {
        setShowInventory(true);
      }
      if (isChest && action.name === 'activateChest') {
        navigate(`/fullscreen/shop/chests/${item?.fungibleType}`);
      }
      setSelectedAction(action);
    },
    [setSelectedAction, isChest]
  );

  const handleCloseDesc = () => {
    setShowDesc(false);
    setSelectedAction(undefined);
  };

  const panelActions = useMemo(() => {
    let filteredActions = actions.filter((action) => {
      if (action.type === item?.type) {
        return true;
      } else if (isChest && action.type === 'chest') {
        return true;
      } else if (!action.type) {
        return true;
      }
      return false;
    });
    if ((type === EActivateItemType.NOEFFECT && item?.type === 'fungible') || userBalance < 1) {
      const activateChestActionIndex = filteredActions.findIndex((a) => a.name === 'activateChest');
      const activateItemActionIndex = filteredActions.findIndex((a) => a.name === 'activateItem');
      const seeCodeItemActionIndex = filteredActions.findIndex((a) => a.name === 'activateCode');
      const transferItemActionIndex = filteredActions.findIndex((a) => a.name === 'transferItem');
      !isChest || (userBalance < 1 && activateChestActionIndex >= 0 && filteredActions.splice(activateChestActionIndex, 1));
      activateItemActionIndex >= 0 && filteredActions.splice(activateItemActionIndex, 1);
      seeCodeItemActionIndex >= 0 && filteredActions.splice(seeCodeItemActionIndex, 1);
      transferItemActionIndex >= 0 && filteredActions.splice(transferItemActionIndex, 1);
    }
    const mappedActions = filteredActions.map((action) => (
      <motion.div
        variants={variants}
        initial={'initial'}
        animate={'animate'}
        exit={'initial'}
        key={action.name}
        className={clsx(styles.action, styles[action.name], styles[theme])}
        onClick={() => handleClick(action)}
      >
        <img src={action.image} alt={action.name} />
        <p className={styles.actionTitle}>{action.name === 'activateCode' && codeActive ? 'See code' : action.title}</p>
      </motion.div>
    ));

    return mappedActions;
  }, [handleClick, item?.type, type, userBalance, codeActive, theme, isChest]);

  return (
    <>
      <div className={styles.actions}>
        <AnimatePresence initial={false}>
          {showDesc && selectedAction ? (
            <motion.div
              variants={variants}
              initial={'initial'}
              animate={'animate'}
              exit={'initial'}
              className={clsx(styles.action, styles.actionActive, styles[selectedAction.name])}
            >
              <img src={selectedAction.image} alt={selectedAction.name} />
              <TransparentCross color={'var(--font-color-strong)'} width={'18px'} height={'18px'} onClick={handleCloseDesc} />
              <p className={styles.actionTitle}>{selectedAction.title}</p>
              <div className={clsx(styles.details, 'tiny-sb')}>
                <div className={styles.dets}>
                  <p className={styles.desc}>{desc}</p>
                </div>
              </div>
            </motion.div>
          ) : isMobile ? (
            <>{panelActions}</>
          ) : (
            <LittleItemsCarousel
              width={'100%'}
              height={'115px'}
              translate={103}
              className={styles.carousel}
              itemClassName={styles.item}
              items={panelActions}
            />
          )}
        </AnimatePresence>
      </div>
      <ArtifactWindow closeWindow={() => setShowInventory(false)} isOpen={showInventory} windowItem={item} />
    </>
  );
};
