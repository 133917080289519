import { AnimatePresence } from 'framer-motion';
import { useDisclosure, useDisplay, useGetContainerSize, useGetCurrentTheme } from 'hooks';
import { useCallback, useMemo, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { ReactComponent as CircleEmpty } from 'assets/icons/circle-empty.svg';
import { ReactComponent as CircleDone } from 'assets/icons/completed.svg';

import clsx from 'clsx';

import { IChapter, IResultChapter, IResultCourse } from 'interface/courses';

import LockedCoursePartWarningModal from 'components/pages/study/courses/structures/accessRejector';

import * as S from './styles';
import { useSelector } from 'react-redux';
import { getPartnerId } from 'store';

type Props = {
  resultCourse: IResultCourse;
  chapters: IChapter[];
  scrollTop: number;
  parentHeight: number;
};

export const LessonChaptersProgress = ({ chapters, resultCourse, parentHeight, scrollTop }: Props) => {
  const getResultChapter = (chapterId: string) => resultCourse.resultChapters.find((el) => el.chapterId === chapterId) || null;
  const { isMobile } = useDisplay();
  const ref = useRef<HTMLDivElement | null>(null);
  const { height: containerHeight } = useGetContainerSize(ref.current);
  const getMaskPercentage = (topDiff: number, bottomDiff: number) =>
    `linear-gradient(180deg, 
      transparent 0%, 
      transparent ${topDiff}%, 
      #000000 ${topDiff + topDiff / 2}%, 
      #000000 ${100 - (bottomDiff + bottomDiff / 2)}%, 
        transparent ${100 - bottomDiff}%, transparent 100%)`;

  const topDiffPercent = useMemo(() => Math.floor((scrollTop / containerHeight) * 100), [scrollTop, containerHeight]);

  const bottomDiffPercent = useMemo(
    () => Math.floor(((containerHeight - (parentHeight + scrollTop)) / containerHeight) * 100),
    [scrollTop, parentHeight, containerHeight]
  );

  const maskGradient = useMemo(() => getMaskPercentage(topDiffPercent, bottomDiffPercent), [topDiffPercent, bottomDiffPercent]);

  return (
    <S.Container style={{ maskImage: isMobile ? undefined : maskGradient }} ref={ref}>
      <AnimatePresence exitBeforeEnter>
        {chapters.map((chapter, index) => (
          <Chapter key={chapter._id} chapterIndex={index + 1} chapter={chapter} resultChapter={getResultChapter(chapter._id)} />
        ))}
      </AnimatePresence>
    </S.Container>
  );
};

type ChapterProps = {
  chapter: IChapter;
  resultChapter: IResultChapter | null;
  chapterIndex: number;
};

const Chapter = ({ chapter, resultChapter, chapterIndex }: ChapterProps) => {
  const { lessonId, chapterId, courseId } = useParams();
  const navigate = useNavigate();
  const theme = useGetCurrentTheme();
  const partnerId = useSelector(getPartnerId);

  const { isOpen: isLockModalOpen, open: openLockModal, close: closeLockModal } = useDisclosure(false);
  const { isOpen: isLessonsOpenDefault, toggle: toggleLessons } = useDisclosure(!chapter.isLocked);

  const isLessonsOpen = useMemo(() => isLessonsOpenDefault && !chapter.isLocked, [chapter, isLessonsOpenDefault]);

  const firstLesson = useMemo(() => chapter.lessons[0] ?? null, [chapter]);

  const handleChapterClick = useCallback(() => {
    if (chapter.isLocked) {
      openLockModal();
      return;
    }
    if (!!firstLesson && chapter._id !== chapterId) {
      navigate(`/courses/${courseId}/${chapter._id}/${firstLesson._id}`);
    }
  }, [chapterId, chapter, firstLesson]);

  const handleToggleLessons = (e: any) => {
    e.stopPropagation();
    toggleLessons();
  };

  const handleLessonClick = useCallback(
    (targetLessonId: string, targetChapterId: string, isLocked: boolean) => (e: any) => {
      if (lessonId === targetLessonId) {
        return;
      }
      if (isLocked || chapter.isLocked) {
        openLockModal();
        return;
      }
      if (partnerId) {
        navigate(`/courses/${courseId}/${targetChapterId}/${targetLessonId}?partnerID=${partnerId}`);
        return;
      }
      navigate(`/courses/${courseId}/${targetChapterId}/${targetLessonId}`);
    },
    [lessonId, courseId, navigate, openLockModal, partnerId]
  );

  return (
    <>
      <LockedCoursePartWarningModal open={isLockModalOpen} close={closeLockModal} />
      <AnimatePresence>
        <S.ChapterBox initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: chapterIndex * 0.05 } }} key={chapter._id + 'progress'}>
          <S.Chapter className={clsx({ isOpen: !!resultChapter, isLessonsOpen })} $theme={theme}>
            <S.ChapterNavContainer onClick={handleChapterClick}>
              {/* <S.ChapterNum $theme={theme}>{chapter?.isLocked ? <S.Icon as={LockIcon} /> : chapterIndex > 9 ? chapterIndex : '0' + chapterIndex}</S.ChapterNum> */}
              <S.ChapterName>{chapter.name}</S.ChapterName>
            </S.ChapterNavContainer>
            {chapter.isLocked ? (
              <S.Icon as={LockIcon} />
            ) : (
              <S.LessonsToggle onClick={handleToggleLessons}>
                <S.Chevron className={clsx({ isReversed: isLessonsOpen })} />
              </S.LessonsToggle>
            )}
          </S.Chapter>
          <S.ChapterLessons className={clsx({ isOpen: isLessonsOpen })}>
            <S.LessonsNested className={clsx({ isOpen: isLessonsOpen })}>
              {!!chapter.lessons.length &&
                chapter.lessons.map((el) => {
                  const Icon = el.isLocked ? LockIcon : (el?.completion ?? 0) >= 100 ? CircleDone : CircleEmpty;
                  return (
                    <S.LessonBox
                      key={el._id}
                      $theme={theme}
                      className={clsx({ isActive: el._id === lessonId })}
                      onClick={handleLessonClick(el._id, el.chapterId, Boolean(el?.isLocked))}
                    >
                      <S.LessonHead className={clsx({ isActive: el._id === lessonId })}>
                        <S.Icon as={Icon} $small />
                        <S.LessonName>{el.name}</S.LessonName>
                      </S.LessonHead>
                    </S.LessonBox>
                  );
                })}
            </S.LessonsNested>
          </S.ChapterLessons>
        </S.ChapterBox>
      </AnimatePresence>
    </>
  );
};
