import { useSelector } from 'react-redux';
import { ESpaceRoles, getCurrentTheme, getUserData, useGetMySpacesQuery } from 'store';
import { ReactComponent as PlusIcon } from '../../assets/plus-darker.svg';
import * as S from './styles';
import { NavigateOptions, useNavigate } from 'react-router-dom';
import { useMemo } from 'react';
import { SpaceItem } from './spaceItem';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import Answer from 'components/library/messages/Answer';
import { toast } from 'react-toastify';
import { USER_ROLES } from 'app/constants';

type Props = {
  setShowMenu?: any;
};

const Spaces = ({ setShowMenu }: Props) => {
  // const { data: spaces, isLoading } = useGetSpacesQuery();
  const { data: spaces = [], isLoading } = useGetMySpacesQuery();
  const { _id: userId, roles } = useSelector(getUserData);
  const navigate = useNavigate();
  const theme = useSelector(getCurrentTheme);
  const handleNavigate = (url: string, navigateArgs?: NavigateOptions) => {
    navigate(url, navigateArgs);
    !!setShowMenu && setShowMenu(false);
  };

  const mySpaces = useMemo(
    () =>
      spaces.filter((el) =>
        el.users.find((user) => user.userId._id === userId && (user.roles.includes(ESpaceRoles.OWNER) || user.roles.includes(ESpaceRoles.ADMIN)))
      ),
    [spaces]
  );

  const followedSpaces = useMemo(
    () =>
      spaces.filter(
        (el) =>
          !!el.users.find(
            (user) => user.userId._id === userId && !(user.roles.includes(ESpaceRoles.OWNER) || user.roles.includes(ESpaceRoles.ADMIN)) && user.isSub
          )
      ),
    [spaces]
  );

  const handleCreateSpace = () => {
    if (!roles.includes(USER_ROLES.ADMIN)) {
      toast.warning(<Answer type="declined" label={'Please hold on - we are getting this functionality ready for you <3'} />);
      return;
    }
    handleNavigate('/space/create');
  };

  return (
    <S.Container>
      {isLoading ? (
        <MightyLoaderMini />
      ) : (
        <>
          <S.Col>
            <S.Topic $theme={theme}>My Spaces</S.Topic>
            <S.SpacesList>
              {mySpaces.map((el) => (
                <SpaceItem key={el._id} handleNavigate={handleNavigate} space={el} />
              ))}
              <S.NewSpace $theme={theme} onClick={handleCreateSpace}>
                <PlusIcon />
                Create space
              </S.NewSpace>
            </S.SpacesList>
          </S.Col>
          {!!followedSpaces.length && (
            <S.Col>
              <S.Topic $theme={theme}>Following Spaces</S.Topic>
              <S.SpacesList>
                {followedSpaces.map((el) => (
                  <SpaceItem key={el._id} handleNavigate={handleNavigate} space={el} />
                ))}
              </S.SpacesList>
            </S.Col>
          )}
        </>
      )}
    </S.Container>
  );
};

export default Spaces;
