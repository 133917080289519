import { FC, useState } from 'react';
import ChangerItem from '../ChangerItem/ChangerItem';
import styles from './styles.module.scss';
import { NAME_COLORS } from 'app/constants';
import ChangerModal from '../modals/ChangerModal/ChangerModal';
import { IContent, ICustomizationItem, IItem, Value } from './types/types';
import { changerContent } from './data';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getCustomizationItems, getUserData, useCustomizeMeMutation } from 'store';
import { GLOWS } from 'app/constants';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { useDisplay } from 'hooks';
import { EItemFungibleType } from 'interface';

const ChangerList: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [content, setContent] = useState<IContent>();
  const {
    customization,
    exp: { items: userItems },
  } = useSelector(getUserData);
  const theme = useSelector(getCurrentTheme);
  const customizationItems = structuredClone(useSelector(getCustomizationItems));
  const [customizeMe, { isLoading }] = useCustomizeMeMutation();
  const { isMobile } = useDisplay();

  const userNamePowder: ICustomizationItem =
      customizationItems.find((item) => {
        if (NAME_COLORS[item.fungibleType as EItemFungibleType]) {
          return (
            NAME_COLORS[item.fungibleType as EItemFungibleType]!![theme] === customization.name[theme] ||
            NAME_COLORS[item.fungibleType as EItemFungibleType]!![theme] === 'default'
          );
        }
        return false;
      }) ?? customizationItems.find((item) => item.fungibleType === EItemFungibleType.GrayLuminaryPowder)!,
    userGlowBottle: ICustomizationItem =
      customizationItems.find(
        (item) =>
          item.fungibleType &&
          (GLOWS[item.fungibleType as EItemFungibleType] === customization.glow || GLOWS[item.fungibleType as EItemFungibleType] === 'default')
      ) ?? customizationItems.find((item) => item.fungibleType === EItemFungibleType.GrayInkBottle)!;
  // TODO finish this
  // userFontBottle = customizationItems.find((item) => item.grade);

  if (userNamePowder) userNamePowder.quantity = userItems.find((item) => item.item.fungibleType === userNamePowder.fungibleType)?.quantity ?? 0;
  if (userGlowBottle) userGlowBottle.quantity = userItems.find((item) => item.item.fungibleType === userGlowBottle.fungibleType)?.quantity ?? 0;
  // if (userFontBottle) userFontBottle.amount = userItems.find((item) => item.item._id === userFontBottle._id)?.amount ?? 0;

  const nameColor = customization.name[theme];
  const glowColor = customization.glow === GLOWS[EItemFungibleType.GrayInkBottle] ? undefined : customization.glow;
  // const font = customization.font;

  const items: IItem[] = [
    {
      title: 'Name color',
      circleColor: nameColor,
      inventoryItem: userNamePowder?.id ?? customizationItems[0].id,
      value: 'name',
      quantity: userNamePowder?.quantity ?? 0,
    },
    {
      title: 'Glow color',
      circleColor: glowColor,
      inventoryItem: userGlowBottle?.id ?? customizationItems[0].id,
      value: 'glow',
      quantity: userGlowBottle?.quantity ?? 0,
    },
    // {
    //   title: 'Name font',
    //   font: font,
    //   inventoryItem: userFontBottle?.itemId ?? customizationItems[0].itemId,
    //   value: 'font',
    //   amount: userFontBottle?.amount ?? 0,
    // },
  ];

  function handleClickItem(value: Value) {
    const props: Record<Value, ICustomizationItem> = {
      name: userNamePowder,
      glow: userGlowBottle,
      font: userNamePowder,
    };
    const selectedItem = props[value];

    setContent({
      ...changerContent[value],
      inventoryItem: selectedItem?.id ?? customizationItems[0].id,
      quantity: selectedItem?.quantity ?? 0,
    });
    setIsOpen(true);
  }

  async function handleCustomization(fungibleType: EItemFungibleType) {
    customizeMe({ fungibleType });
  }

  function onClose() {
    setIsOpen(false);
    setContent(undefined);
  }

  return (
    <div className={styles.list}>
      <CenteredModal show={isOpen} onClose={() => onClose()} zIndex={[1200, 1100]} isClosing={!isMobile} className={styles.modal}>
        <ChangerModal content={content!} handleCustomization={handleCustomization} onClose={onClose} />
      </CenteredModal>
      {isLoading ? (
        <MightyLoaderMini />
      ) : (
        items.map((item) => <ChangerItem key={item.title} item={item} onClick={() => handleClickItem(item.value)} />)
      )}
    </div>
  );
};

export default ChangerList;
