import { FC, useState } from 'react';
import ReactDom from 'react-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ReactComponent as CrossIcon } from 'assets/components/cross.svg';
import clsx from 'clsx';

import '../loader.css';
import styles from './styles.module.scss';

interface Props {
  show: boolean;
  children: React.ReactNode;
  onClose?: () => void;
  zIndex?: number[];
  isClosing?: boolean;
  className?: string;
  closeButtonLocation?: 'inside' | 'outside';
  closeButtonPosition?: { top: string };
  closeButtonVariant?: 'regular' | 'red-light';
  blur?: number;
  backgroundColor?: string;
  hasOverlay?: boolean;
  overflowUnset?: boolean;
  mixBlendMode?: string;
  portal?: 'portal' | 'root';
  direction?: 'x' | 'y';
  padding?: string;
  classNameModal?: any;
}

const overlay = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.3,
    },
  },
  exit: {
    transition: {
      duration: 0.3,
    },
  },
};

const modal = {
  hidden: (direction: 'x' | 'y') => ({
    ...(direction === 'y' ? { y: '-100vh' } : { x: '100vw' }),
    opacity: 0,
  }),
  visible: (direction: 'x' | 'y') => ({
    ...(direction === 'y' ? { y: 0 } : { x: 0 }),
    opacity: 1,
    transition: {
      duration: 0.3,
      type: 'spring',
      damping: 15,
      stiffness: 150,
    },
  }),
  exit: (direction: 'x' | 'y') => ({
    ...(direction === 'y' ? { y: '100vh' } : { x: '-100vw' }),
    opacity: 0,
    transition: {
      duration: 0.3,
    },
  }),
};

const CenteredModal: FC<Props> = ({
  show,
  onClose,
  children,
  zIndex = [1000, 900],
  isClosing = true,
  closeButtonLocation = 'outside',
  closeButtonPosition,
  closeButtonVariant = 'regular',
  className = '',
  blur = 2,
  backgroundColor = 'rgba(28, 29, 38, 0.5)',
  hasOverlay = true,
  overflowUnset = false,
  mixBlendMode = '',
  portal = 'portal',
  direction = 'y',
  padding,
  classNameModal,
}) => {
  const [isOpen, setIsOpen] = useState(show);

  function handleClose() {
    if (onClose) {
      onClose();
    }
    setIsOpen(false);
  }

  const MODAL_STYLES: any = {
    zIndex: zIndex[0],
  };

  const OVERLAY_STYLES: any = {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: backgroundColor,
    backdropFilter: `blur(${blur}px)`,
    zIndex: zIndex[1],
    padding: '40px 15px',
    willChange: 'opacity',
  };

  return ReactDom.createPortal(
    <AnimatePresence exitBeforeEnter>
      {(isOpen || show) && (
        <>
          {hasOverlay && (
            <motion.div variants={overlay} initial="hidden" animate="visible" exit="hidden" style={OVERLAY_STYLES} onClick={() => handleClose()} />
          )}
          <div className={clsx(styles.modal, classNameModal)} style={{ ...MODAL_STYLES, mixBlendMode }}>
            <motion.div variants={modal} initial="hidden" animate="visible" exit="exit" style={{ willChange: 'transform' }} custom={direction}>
              {isClosing && (
                <div
                  onClick={onClose}
                  className={clsx(styles.crossIcon, styles[closeButtonLocation], styles[closeButtonVariant])}
                  style={closeButtonPosition && { ...closeButtonPosition }}
                >
                  <CrossIcon />
                </div>
              )}
              <div
                className={clsx(styles.content, styles[closeButtonLocation], {
                  [className]: !!className,
                  [styles.overflowUnset]: overflowUnset,
                })}
                style={padding ? { padding } : {}}
              >
                {children}
              </div>
            </motion.div>
          </div>
        </>
      )}
    </AnimatePresence>,
    document.getElementById(portal)!
  );
};

export default CenteredModal;
