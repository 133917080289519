import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';
import { useDisplay } from 'hooks';
import { AnimatePresence, motion } from 'framer-motion';

import { MobileSideChapter } from './mobile';
import SideChapter from './SideChapter';
import { Toggle } from 'shared/ui/toggle';
import { HiOutlineCog6Tooth } from 'react-icons/hi2';

import './styles/sidechapters.scss';
import styles from './styles/styles.module.css';

const SideChapters = ({ course, isToggle = true, className = '', toggleIsOpen, onCloseToggle, page = 'default' }) => {
  const theme = useSelector(getCurrentTheme);
  const [sidePanelCourse, setSidePanelCourse] = useState(course);
  const [isToggleShown, setIsToggleShown] = useState(false);
  const [isCompleteHidden, setIsCompleteHidden] = useState(false);
  const [isRewsHidden, setIsRewsHidden] = useState(false);
  const [openChapter, setOpenChapter] = useState(() => getLast());

  const { isMobile } = useDisplay();

  const pageVariants = {
    initial: {
      opacity: 0,
      x: -300,
      transition: {
        ease: 'easeOut',
        duration: 0.5,
        delay: 0.25,
      },
    },
    animate: {
      opacity: 1,
      x: 0,
    },
    exit: {
      opacity: 0,
    },
  };

  useEffect(() => {
    // setting sidepanel course data
    setSidePanelCourse((prev) => {
      prev = structuredClone(course);
      if (isCompleteHidden) {
        prev.chapters = prev.chapters.filter((c) => !c.isDone);
        for (const chapter of prev.chapters) chapter.lessons = chapter.lessons.filter((m) => !m.isDone);
      }
      if (isRewsHidden) {
        for (const chapter of prev.chapters)
          for (const lessons of chapter.lessons) {
            lessons.rewards = [];
          }
      }
      return prev;
    });
  }, [course, isCompleteHidden, isRewsHidden]);

  function handleToggle(index) {
    let toggle = index === openChapter ? -1 : index;
    setOpenChapter(toggle);
  }

  function getLast() {
    let indexOfLast = sidePanelCourse?.chapters?.find((i) => i.lessons.find((b) => b.isLast))?.index;
    return indexOfLast > 0 ? indexOfLast : 0;
  }

  const TogglePage = () => (
    <div className={clsx(styles.toggles, styles.newToggle)}>
      <h5>Edit chapter list</h5>
      <div className={styles.option}>
        <p>Show Completed</p>
        <Toggle className={styles.toggle} checked={!isCompleteHidden} onChange={() => setIsCompleteHidden(!isCompleteHidden)} toggleSize={'xl'} />
      </div>
      <div className={styles.option}>
        <p>Hide Rewards</p>
        <Toggle className={styles.toggle} checked={isRewsHidden} onChange={() => setIsRewsHidden(!isRewsHidden)} toggleSize={'xl'} />
      </div>
    </div>
  );

  const DefaultPage = () => (
    <>
      <div className={clsx('sidepanel__chapters tiny-sb', { [className]: !!className }, className === 'fullscreen' && styles.chapters)}>
        {sidePanelCourse?.chapters?.map((chapter, index) => (
          <SideChapter key={'sidepanel__chapter' + index} order={index} chapter={chapter} isOpen={index === openChapter} openThis={handleToggle} />
        ))}
      </div>
      {isToggle && (
        <>
          <HiOutlineCog6Tooth
            className={clsx(styles.toggleCaller, { [styles.togglesUp]: isToggleShown })}
            role="button"
            onClick={() => setIsToggleShown(!isToggleShown)}
          />
          {isToggleShown && (
            <>
              <div className={clsx(styles.toggles, `glass-div basic ${theme}`)}>
                <div className={styles.option}>
                  <p>Show Completed</p>
                  <Toggle className={styles.toggle} checked={!isCompleteHidden} onChange={() => setIsCompleteHidden(!isCompleteHidden)} />
                </div>
                <div className={styles.option}>
                  <p>Hide Rewards</p>
                  <Toggle className={styles.toggle} checked={isRewsHidden} onChange={() => setIsRewsHidden(!isRewsHidden)} />
                </div>
              </div>
            </>
          )}
        </>
      )}
    </>
  );

  if (isMobile) {
    return (
      <MobileSideChapter
        sidePanelCourse={sidePanelCourse}
        className={className}
        handleToggle={handleToggle}
        isCompleteHidden={isCompleteHidden}
        isRewsHidden={isRewsHidden}
        isToggle={isToggle}
        openChapter={openChapter}
        setIsCompleteHidden={() => setIsCompleteHidden(!isCompleteHidden)}
        setIsRewsHidden={() => setIsRewsHidden(!isRewsHidden)}
        toggleIsOpen={toggleIsOpen}
        onCloseToggle={onCloseToggle}
      />
    );
  }

  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {page === 'toggle' ? (
        <motion.div key={page} variants={pageVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
          <TogglePage />
        </motion.div>
      ) : (
        <motion.div key={page} variants={pageVariants} initial={'initial'} animate={'animate'} exit={'exit'}>
          <DefaultPage />
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default SideChapters;
