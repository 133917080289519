import { useEffect, useReducer } from 'react';
import { useSelector } from 'react-redux';
import { IProfile, IUser } from 'interface';
import { dispatch, useAddFriend, useGetCurrentTheme, useGetUserData } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { getIsFriendFinder, setIsFriendFinder, useLazyFindUserByInputQuery } from 'store';
import { defaultCustomization, ROUTES } from 'app/constants';
import useWebSocket from 'hooks/useWebSocket';
import { EVENTS } from 'app/constants';
import { toast } from 'react-toastify';

import AvatarPic from '../utils/AvatarPic/avatar';
import SearchbarSmall from '../utils/Searchbars/SearchbarSmall/SearchbarSmall';
import CenteredModal from '../utils/CenteredModal/CenteredModal';
import AddConfirm from 'components/pages/profile/profile/FriendsSmall/slides/AddConfirm';
import AddConfirmed from 'components/pages/profile/profile/FriendsSmall/slides/AddConfirmed';
import Answer from '../messages/Answer';

import { ReactComponent as VS } from 'components/library/images/icons/challenge.svg';
import { ReactComponent as Chat } from 'components/library/images/icons/messages.svg';
import { ReactComponent as Add } from 'components/library/images/icons/add-user.svg';
import { ReactComponent as SeeProfile } from 'assets/icons/profile/see_profile.svg';

import styles from './styles.module.css';

interface State {
  query: string;
  friend: IUser;
  friends: IUser[];
  slide: '' | 'addConfirming' | 'addConfirmed';
}

const defaultState: State = {
  query: '',
  friend: { address: '', avatar: '', nickname: '', _id: '', customization: defaultCustomization },
  friends: [],
  slide: '',
};

export default function FriendFinderMain() {
  const isShown = useSelector(getIsFriendFinder);
  const theme = useGetCurrentTheme();
  const userProfile = useGetUserData();
  const { sendMessage } = useWebSocket();
  const { addFriend } = useAddFriend();
  const [findUserByInput, { isLoading: findLoading }] = useLazyFindUserByInputQuery();
  const [state, updateState] = useReducer((prev: any, next: any) => ({ ...prev, ...next }), defaultState);

  const { query, friend } = state;

  const navigate = useNavigate();
  const userData = {
    friends: userProfile?.friends,
  };

  useEffect(() => {
    async function searchFriends() {
      const input = query.replace(/![a-zA-Z0-9._\-@]/g, '');

      if (input) {
        findUserByInput(input)
          .unwrap()
          .then((friends) => {
            updateState({ friends });
          })
          .catch((ex) => {
            console.error(ex);
            toast.error(<Answer label={'Oops:/'} subtext={ex?.data?.message ?? 'Something went wrong...'} type="incorrect" />);
          });
      }
    }

    const timeout = setTimeout(() => {
      if (query) {
        searchFriends();
      } else {
        updateState({ friends: [] });
      }
    }, 450);

    return () => clearTimeout(timeout);
  }, [query]);

  function closeFinder() {
    updateState(defaultState);
    dispatch(setIsFriendFinder(false));
  }
  function handleSeeProfile(id: string) {
    if (id) navigate(`${ROUTES.PROFILE}/${id}`, { replace: true });
  }
  function handleAddOpen(friend: IUser) {
    updateState({ friend, slide: 'addConfirming' });
  }
  function handleConfirmClose() {
    updateState({ slide: '', friend: defaultState.friend });
  }
  async function handleAddFriend() {
    await addFriend(friend._id).then(() => {
      sendMessage(EVENTS.USERFOLLOWED, { id: userProfile._id, targetId: state.friend._id });
      updateState({ slide: 'addConfirmed' });
    });
  }
  function handleAddConfirmClose() {
    updateState({ friend: defaultState.friend, slide: '' });
  }

  const slides = {
    addConfirming: <AddConfirm close={handleAddConfirmClose} accept={handleAddFriend} {...friend} />,
    addConfirmed: <AddConfirmed close={handleConfirmClose} {...friend} />,
  };

  if (!isShown) return null;

  return (
    <>
      <CenteredModal show={!!state.slide} onClose={handleConfirmClose}>
        {slides[state.slide as keyof typeof slides]}
      </CenteredModal>
      {!state.slide && (
        <CenteredModal show={isShown} onClose={closeFinder}>
          <div className="friend-finder__wrap">
            <SearchbarSmall
              loading={findLoading}
              input={query}
              autoFocus
              handleInput={(e: any) => updateState({ query: e.target.value })}
              clearInput={() => updateState({ query: '' })}
              placeholder={'Search Friends'}
            />
            <div
              style={{ height: state.friends.length > 3 ? `${state.friends.length * 70 + 5}px` : '215px' }}
              className={`glass-div basic ${theme} friend-finder__results-area`}
            >
              {state.friends.length > 0 ? (
                state.friends.map((friend: IProfile, index: number) => {
                  const { nickname, _id } = friend;
                  return (
                    <div className="friend-finder_friend" key={'search-friend-' + index}>
                      <div className="friend-finder__person">
                        <AvatarPic smallGlow className={styles.avatarMain} isFramed={false} isOnlineMarker={false} {...friend} />
                        <p>{nickname.length > 15 ? nickname.slice(0, 13) + '...' : nickname}</p>
                      </div>
                      <div className="friend-finder__manage">
                        {_id !== userProfile._id && !userData.friends.some(({ recipient }) => recipient._id === _id) && (
                          <button onClick={() => handleAddOpen(friend)}>
                            <Add />
                          </button>
                        )}
                        <button onClick={() => handleSeeProfile(_id)}>
                          <SeeProfile className={styles.profileIcon} />
                        </button>
                        <button>
                          <VS />
                        </button>
                        <button>
                          <Chat />
                        </button>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="friend-finder_friend">
                  <p>{query ? 'No friends founds for this search' : 'Type something to search...'}</p>
                </div>
              )}
            </div>
          </div>
        </CenteredModal>
      )}
    </>
  );
}
