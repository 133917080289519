import { toast } from 'react-toastify';
import { coursesConstructorAPI } from 'store';

export const useImageUpload = (slideId: string, widgetId: string) => {
  const [addAttachment] = coursesConstructorAPI.useAddAttachmentMutation();

  const uploadImage = (files: FileList | null) => {
    if (!files?.length) {
      toast('Please select an image', { type: 'error' });
      return;
    }

    const file = files[0];

    const form = new FormData();
    form.append('file', file);
    form.append('slideId', slideId);

    return addAttachment({ slideId, widgetId, form })
      .then((res) => res)
      .catch((err) => {
        console.dir(err);
        toast('Something went wrong. Please try again', { type: 'error' });
      });
  };

  return uploadImage;
};
