import styled, { css } from 'styled-components';
import bg from './assets/modal-bg.png';

export const Container = styled.div(
    () => css`
      display: flex;
      gap: 20px;
    `
  ),
  Wrap = styled.div<{ $isRewardable: boolean }>(
    ({ $isRewardable }) => css`
      display: flex;
      justify-content: ${$isRewardable ? 'space-between' : 'flex-start'};
      flex-direction: ${$isRewardable ? 'row' : 'column'};
      flex: 1;

      @media screen and (max-width: 625px) {
        flex-direction: column;
      }

      @media screen and (max-width: 475px) {
        max-width: 165px;
      }

      .next-rewards {
        @media screen and (max-width: 625px) {
          display: none;
        }
      }

      .button {
        height: 54px;
        font-weight: 500;
        line-height: 100%;
        align-self: flex-end;

        @media screen and (max-width: 625px) {
          align-self: flex-start;
        }
      }
    `
  ),
  Title = styled.h4(
    () => css`
      color: var(--font-color-strong);
      font-size: 26px;
      font-weight: 500;
      line-height: 100%;
      margin-bottom: 15px;

      @media screen and (max-width: 625px) {
        font-size: 18px;
        line-height: 124%;
        margin-bottom: 10px;
      }
    `
  ),
  ModalContent = styled.div(
    () => css`
      height: 90vh;
      width: 100vw;
      max-width: 375px;
      padding: 0 20px;
      margin: -275px auto 0 auto;
      position: relative;
    `
  ),
  Background = styled.div(
    () => css`
      background-image: url(${bg});
      background-color: rgba(10, 14, 35, 0.4);
      background-size: cover;
      background-blend-mode: multiply;
      width: 100vw;
      height: 328px;
      transform: translateY(-55px);

      &::before {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        background: linear-gradient(to bottom, transparent 0%, #0a0e23 85%);
      }
    `
  );
