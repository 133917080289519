export const clockVariants = {
  initial: {
    x: '30px',
    width: 0,
    opacity: 0,
  },
  animate: {
    x: 0,
    width: 'auto',
    opacity: 1,
  },
};

export const panelVariants = {
  initial: {
    x: 0,
    opacity: 1,
  },
  animate: {
    x: '100%',
    opacity: 0,
  },
};
