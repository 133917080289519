import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

import { ReactComponent as Button1Image } from '../assets/button-1.svg';
import { ReactComponent as Button2Image } from '../assets/button-2.svg';
import { ReactComponent as Button3Image } from '../assets/button-3.svg';
import { ReactComponent as CircleImage } from '../assets/circle.svg';

export const Container = styled(motion.div)<{ $active?: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  width: 210px;
  height: 210px;
  z-index: ${(p) => (p.$active ? 10 : -100)};
`;

export const SVGField = styled(CircleImage)<{ $theme?: THEMES }>`
  position: absolute;
  filter: invert(${(p) => (p.$theme === THEMES.LIGHT ? 1 : 0)});
  top: 0;
  right: 0;
`;

export const SVGButtonBg1 = styled(Button1Image)<{ $active?: boolean; $theme?: THEMES }>`
  opacity: ${(p) => (p.$active ? 1 : 0)};
  filter: invert(${(p) => (p.$theme === THEMES.LIGHT ? 1 : 0)});
  transition: 0.3s;
  position: absolute;
`;
export const SVGButtonBg2 = styled(Button2Image)<{ $active?: boolean; $theme?: THEMES }>`
  opacity: ${(p) => (p.$active ? 1 : 0)};
  left: 65%;
  filter: invert(${(p) => (p.$theme === THEMES.LIGHT ? 1 : 0)});
  transition: 0.3s;
  position: absolute;
  top: 9%;
`;
export const SVGButtonBg3 = styled(Button3Image)<{ $active?: boolean; $theme?: THEMES }>`
  opacity: ${(p) => (p.$active ? 1 : 0)};
  right: 0%;
  filter: invert(${(p) => (p.$theme === THEMES.LIGHT ? 1 : 0)});
  transition: 0.3s;
  position: absolute;
  bottom: 50%;
`;

export const Button = styled.div<{ $theme?: THEMES }>`
  position: absolute;
  transform: translate(-50%, -50%);
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  &._1 {
    width: 49px;
    height: 48px;
    border-radius: 10% 57% 31% 9% / 10% 46% 83% 31%;
    left: 61%;
    top: 13%;
  }
  &._2 {
    width: 48px;
    height: 48px;
    border-radius: 70% 71% 92% 36% / 92% 64% 77% 36%;
    left: 79%;
    top: 24%;
  }
  &._3 {
    width: 51px;
    height: 48px;
    border-radius: 100% 48% 0% 42% / 76% 61% 10% 43%;
    left: 87%;
    top: 40%;
  }
`;

export const Tooltip = styled.div<{ $isVisible: boolean }>`
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  display: block;
  color: white;
  font-size: 14px;
  font-weight: 400;
  transition: ${(p) => (p.$isVisible ? '.7s' : '.3s')};
  padding: 6px 11px;
  background: #000;
  border-radius: 2px;
  opacity: ${(p) => (p.$isVisible ? 1 : 0)};
`;

export const Fakehalf = styled.div`
  cursor: pointer;
  width: 47px;
  height: 94px;
  left: 0;
  top: 59px;
  z-index: 100;
  position: absolute;
  border-radius: 0px 500px 500px 0px;
`;
