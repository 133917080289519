import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      width: 100%;
      height: 2.7rem;
      flex-shrink: 0;
      display: flex;
      align-items: center;
    `
  ),
  ImageContainer = styled.div(
    () => css`
      width: 2.7rem;
      min-width: 2.7rem;
      max-width: 2.7rem;
      height: 2.7rem;
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;
      border-radius: 0.4rem;
      margin-right: 1.1rem;

      img {
        object-fit: contain;
        max-height: 100%;
      }
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      align-items: center;
      width: 100%;
    `
  ),
  CourseName = styled.p(
    () => css`
      color: var(--font-color-strong);
      font-size: 1.6rem;
      line-height: 100%;
    `
  );
