import { useNavigate } from 'react-router-dom';
import { useGetIsAuth } from 'hooks';
import { ROUTES } from 'app/constants/routes';
import { useGetInitialNotifsCount } from 'components/library/navbar/hooks/useGetInitialNotifsCount';
import clsx from 'clsx';

import { ReactComponent as Profile } from 'assets/icons/profile.svg';
import { ReactComponent as Learn } from 'assets/icons/learn.svg';
import { ReactComponent as Play } from 'assets/icons/play.svg';
import { ReactComponent as Shop } from 'assets/icons/shopping-bag.svg';
import { ReactComponent as Bell } from 'assets/icons/bell.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Inventory } from 'assets/icons/wallet.svg';
// import { ReactComponent as Design } from 'assets/icons/sunCircle.svg';
import { ReactComponent as Spaces } from 'assets/icons/planet.svg';

import styles from './styles.module.css';

interface IProps {
  onClose: () => void;
  handleNotifModal: () => void;
  handleUserProfileLink: () => void;
}

export function NavList({ onClose, handleNotifModal, handleUserProfileLink }: IProps) {
  const navigate = useNavigate();
  const isAuth = useGetIsAuth();
  const { counter: nots } = useGetInitialNotifsCount();

  const handleClose = () => {
    onClose();
  };

  const navs = [
    {
      label: 'Profile',
      icon: Profile,
      action: () => {
        navigate(ROUTES.PROFILE);
        handleUserProfileLink();
      },
    },
    {
      label: 'Learn',
      icon: Learn,
      action: () => {
        navigate(ROUTES.MAIN);
        handleClose();
      },
    },
    {
      label: 'Play',
      icon: Play,
      action: () => {
        navigate(ROUTES.PLAY);
        handleClose();
      },
    },
    {
      label: 'Shop',
      icon: Shop,
      action: () => {
        navigate(ROUTES.SHOP);
        handleClose();
      },
    },
    {
      label: 'Spaces',
      icon: Spaces,
      action: () => {
        navigate(ROUTES.SPACES);
        handleClose();
      },
    },
    {
      label: 'Notifications',
      icon: Bell,
      action: () => {
        handleNotifModal();
        handleClose();
      },
    },
    {
      label: 'Settings',
      icon: Settings,
      action: () => {
        navigate(ROUTES.PROFILE + '?screen=Settings');
        handleUserProfileLink();
      },
    },
    {
      label: 'Inventory',
      icon: Inventory,
      action: () => {
        navigate(ROUTES.PROFILE + '?screen=Inventory');
        handleUserProfileLink();
      },
    },
  ];

  const unAuthNavs = [
    {
      label: 'Learn',
      icon: Learn,
      action: () => {
        navigate(ROUTES.MAIN);
        handleClose();
      },
    },
    {
      label: 'Play',
      icon: Play,
      action: () => {
        navigate(ROUTES.PLAY);
        handleClose();
      },
    },
    {
      label: 'Shop',
      icon: Shop,
      action: () => {
        navigate(ROUTES.SHOP);
        handleClose();
      },
    },
    {
      label: 'Spaces',
      icon: Spaces,
      action: () => {
        navigate(ROUTES.SPACES);
        handleClose();
      },
    },
    {
      label: 'Log In / Sign Up',
      icon: Profile,
      action: () => {
        navigate(ROUTES.AUTH);
      },
      twoCols: true,
    },
  ];

  const currentNavs = isAuth ? navs : unAuthNavs;

  return (
    <div className={styles.wrap}>
      {currentNavs.map(
        (
          {
            label,
            icon: Icon,
            action,
            twoCols,
          }: { label: string; icon: React.FC<React.SVGProps<SVGSVGElement>>; action: () => void; twoCols?: boolean },
          index
        ) => (
          <div
            className={clsx(styles.link, {
              [styles.lastTwo]: navs.length - 1 === index || navs.length - 2 === index,
              [styles.twoCols]: twoCols,
              [styles.unauth]: !isAuth,
            })}
            key={'mobile-nav-' + label}
            role="button"
            onClick={action}
          >
            <Icon className={styles.icon} />
            <p className={styles.label}>{label}</p>
            {label === 'Notifications' && nots > 0 ? <p className={styles.nots}>{nots >= 100 ? '99' : nots}</p> : ''}
          </div>
        )
      )}
    </div>
  );
}
