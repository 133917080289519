import { THEMES } from 'app/constants';

export const SPACE_COLORS = {
  [THEMES.LIGHT]: {
    title: '#627EEA',
    spaceCardLargeTitle: '#1C1D26',
    spaceCardSmallTitle: '#1C1D26',
    subtitles: '#1C1D2680',
    spaceCardSmallDescription: '#111111',
    spaceCardLargeDescription: '#FFFFFF',
    followers: '#1C1D26',
  },
  [THEMES.DARK]: {
    title: '#FDFDFD',
    spaceCardLargeTitle: '#FDFDFD',
    spaceCardSmallTitle: '#FDFDFD',
    subtitles: '#FDFDFD',
    spaceCardSmallDescription: '#FDFDFD66',
    spaceCardLargeDescription: '#FDFDFD66',
    followers: '#FDFDFD',
  },
};
