import { FC } from 'react';
import rgba from 'hex-to-rgba';

import styles from './styles.module.css';
import { useTheme } from 'styled-components';

type TColorItemProps = {
  selected: boolean;
  color: string;
  title: string;
  onClick: () => void;
};

const ColorItem: FC<TColorItemProps> = ({ selected, color, title, onClick }) => {
  const theme = useTheme() as any;

  return (
    <button
      className={styles.root}
      style={{
        ...(selected ? { background: rgba(theme.theme.fontColor, 0.1) } : {}),
      }}
      onClick={onClick}
    >
      <div className={styles.colorItem} style={{ color }}>
        A
      </div>
      <div>{title}</div>
    </button>
  );
};

export default ColorItem;
