import { FC, PropsWithChildren, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentAvatarShape, getUserData, setCurrentAvatarShape } from 'store';
import { AVATAR_SHAPES, LOCAL_STORAGE_KEYS } from 'app/constants';
import { dispatch } from 'hooks';

export const PageWrapper: FC<PropsWithChildren> = ({ children }) => {
  const userProfile = useSelector(getUserData, shallowEqual);
  const currentAvatarShape = useSelector(getCurrentAvatarShape, shallowEqual);

  useEffect(() => {
    const localAvatarShape = localStorage.getItem(LOCAL_STORAGE_KEYS.AVATAR_SHAPE) as AVATAR_SHAPES;
    if (userProfile._id) {
      dispatch(setCurrentAvatarShape(localAvatarShape || currentAvatarShape));
    }
  }, [userProfile, currentAvatarShape]);

  return <>{children}</>;
};
