import { IRewardMinimal, useClaimRewardsMutation, useGetMyPartsQuery } from 'store';
import * as S from './styles';
import { useGetMultipliedExp, useGetUserData, useReduceClaimedRewards } from 'hooks';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { GRADES } from 'app/constants';
import { IResultCourse } from 'interface/courses';
import rewardUserLocal from 'utils/rewardUserLocal';
import { usePlayLessonPage } from '../../hooks';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { sleep } from 'shared/utils';
import { AnimatePresence, motion } from 'framer-motion';
import { Avatar } from 'components/library/Avatar';
import clsx from 'clsx';

const STORAGE_URL = process.env.REACT_APP_STATIC_HOST + '/animations/';

type Props = {
  rewards: IRewardMinimal[];
  slideId?: string;
  resultCourseId?: string;
  setResultCourse?: (arg: IResultCourse) => void;
  updateRewards?: (arg: IRewardMinimal[]) => void;
  disabled?: boolean;
  autoCompleteOn?: boolean;
  inTask?: boolean;
  handleCompletion?: () => void;
};

const PRIORITY_BY_GRADE = {
  [GRADES.COMMON]: 0,
  [GRADES.UNCOMMON]: 1,
  [GRADES.RARE]: 2,
  [GRADES.EPIC]: 3,
  [GRADES.LEGENDARY]: 4,
  [GRADES.MYTHICAL]: 5,
  [GRADES.DIVINE]: 6,
};

const ClaimRewardsButton = ({
  rewards,
  resultCourseId,
  slideId,
  setResultCourse,
  updateRewards,
  disabled,
  inTask,
  autoCompleteOn,
  handleCompletion,
}: Props) => {
  const [claimRewards, { isLoading }] = useClaimRewardsMutation();
  const { refetch } = useGetMyPartsQuery({ props: ['exp'] });
  const isClickedRef = useRef(false);
  const [isClaimed, setIsClaimed] = useState(false);
  const [isAnimationStarted, setAnimationStarted] = useState(false);
  const [isRewardsAnimEnded, setRewardsAnimEnded] = useState(false);
  const [videoStarted, setVideoStarted] = useState(false);
  const { nickname, avatar } = useGetUserData();

  const { playReward } = usePlayLessonPage();
  const { getMultipliedExp } = useGetMultipliedExp();

  const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  const extension = isSafari ? 'mov' : 'webm';
  const extensionFolder = isSafari ? 'hevc' : 'webm';

  const formatedRewards = useReduceClaimedRewards({ rewards })
    .sort((a, b) => PRIORITY_BY_GRADE[b.grade] - PRIORITY_BY_GRADE[a.grade])
    .slice(0, 3);

  const maxGrade = useMemo(() => {
    if (!formatedRewards.length) {
      return GRADES.COMMON;
    }

    return formatedRewards[0].grade;
  }, [formatedRewards]);

  const glowLink = useMemo(() => `${STORAGE_URL}rewards/glow/${extensionFolder}/${maxGrade}.${extension}`, [extension, extensionFolder, maxGrade]);
  const claimingLink = useMemo(
    () => `${STORAGE_URL}rewards/collect/${extensionFolder}/${maxGrade}.${extension}`,
    [extension, extensionFolder, maxGrade]
  );

  const reset = () => {
    setIsClaimed(false);
    setAnimationStarted(false);
    setRewardsAnimEnded(false);
    setVideoStarted(false);
    isClickedRef.current = false;
  };

  const handleAutocomplete = async () => {
    setAnimationStarted(true);
    await sleep(1000, rewardUserLocal, rewards);
    playReward();
    if (!!handleCompletion) {
      handleCompletion();
    }
  };

  useEffect(() => {
    if (!!autoCompleteOn) {
      handleAutocomplete();
    }
  }, [autoCompleteOn]);

  const handleClaimRewards = () => {
    if (isLoading || isClaimed || disabled || !resultCourseId || !slideId || !updateRewards || inTask || !!isClickedRef.current) {
      return;
    }
    isClickedRef.current = true;
    setAnimationStarted(true);
    claimRewards({ resultCourseId, slideId })
      .unwrap()
      .then((res) => {
        if (res.resultCourse) {
          sleep(2000, setResultCourse, res.resultCourse);
        }
        rewardUserLocal(rewards);
        updateRewards(rewards.map((r) => ({ ...r, quantity: getMultipliedExp(r.id, r.quantity) })));
        playReward();
        sleep(1500, setIsClaimed, true);
      })
      .catch((err) => {
        toast.error(<Answer label={'Oops...'} subtext={err?.data?.message ?? err.toString()} />, { autoClose: 1000 });
        reset();
      })
      .finally(refetch);
    // sleep(2300, reset)
  };

  return (
    <AnimatePresence>
      {!isClaimed && (
        <S.Container
          $alignItems="center"
          $justifyContent="center"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0, scale: 0 }}
          onClick={handleClaimRewards}
          className={clsx({ inTask })}
        >
          <S.Frame $alignItems="center" $justifyContent="center" $grade={maxGrade}>
            <S.AvatarBox className={clsx({ animated: videoStarted })}>
              <Avatar userName={nickname} src={avatar} />
            </S.AvatarBox>
            <AnimatePresence exitBeforeEnter>
              {!isAnimationStarted ? (
                <motion.video
                  playsInline
                  className="animation-before"
                  src={glowLink}
                  autoPlay={true}
                  loop={true}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  key={'anim-before'}
                />
              ) : (
                <motion.video
                  className="animation-claiming"
                  playsInline
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  src={claimingLink}
                  onEnded={() => {
                    setAnimationStarted(false);
                    setVideoStarted(false);
                  }}
                  onPlay={() => setVideoStarted(true)}
                  autoPlay={isAnimationStarted}
                  key={'anim-claiming'}
                />
              )}
            </AnimatePresence>
            <S.Light $grade={maxGrade} />
            <S.RewImages $alignItems="center" $justifyContent="center">
              {!isRewardsAnimEnded &&
                formatedRewards.map((el, index) => (
                  <Fragment key={'reward-' + index}>
                    <S.RewImg $delay={'0s'} className={clsx({ hidden: videoStarted })} src={el.image} />
                    <S.RewAnimatedBox
                      className={clsx({ animated: videoStarted })}
                      $delay={`${0.07 * index}s`}
                      onAnimationEnd={() => {
                        setRewardsAnimEnded(true);
                      }}
                    >
                      <S.RewImg className={clsx({ animated: videoStarted })} src={el.image} $delay={`${0.07 * index}s`} />
                    </S.RewAnimatedBox>
                  </Fragment>
                ))}
            </S.RewImages>
          </S.Frame>
          {!inTask && <S.Title $grade={maxGrade}>Claim Rewards</S.Title>}
        </S.Container>
      )}
    </AnimatePresence>
  );
};

export default ClaimRewardsButton;
