import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      z-index: 2;
      width: 150%;
      min-width: 230px;
    `
  ),
  Label = styled.label(
    () => css`
      position: relative;

      & input:checked + span {
        background: linear-gradient(90deg, rgba(100, 135, 239, 1), rgba(212, 184, 255, 1));
      }

      & input:checked + span::before {
        left: calc(100% - 1.5px);
        transform: translateX(-100%);
        background: white;
      }
    `
  ),
  Reward = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 5px;
    `
  ),
  Text = styled.p(
    () => css`
      position: relative;

      & input:checked + span {
        background: linear-gradient(90deg, rgba(100, 135, 239, 1), rgba(212, 184, 255, 1));
      }

      & input:checked + span::before {
        left: calc(100% - 1.5px);
        transform: translateX(-100%);
        background: white;
      }
    `
  ),
  Span = styled.span(
    () => css`
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 1.45;
      display: inline-flex;
    `
  ),
  Quantity = styled.span(
    () => css`
      margin-right: 5px;
      width: fit-content;
    `
  ),
  Image = styled.img(
    () => css`
      width: 30px;
      min-width: 30px;
      aspect-ratio: 1 / 1;
    `
  );
