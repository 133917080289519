import { IChanger } from '../types/types';

export const changerContent: IChanger = {
  name: {
    title: 'Change your name color',
    description: 'Use Ink bottle to change the color of your name on the platform',
    congratulation: 'Background glow changed successfuly!',
    value: 'name',
    inventoryItem: 1,
    quantity: 0,
  },
  glow: {
    title: 'Change your profile glow color',
    description: 'Use luminary powder to change the glow color on the platform',
    congratulation: 'Background glow changed successfuly!',
    value: 'glow',
    inventoryItem: 2,
    quantity: 0,
  },
  font: {
    title: 'Change your name font',
    description: 'Use luminary powder to change the glow color on the platform',
    congratulation: 'Background glow changed successfuly!',
    value: 'font',
    inventoryItem: 3,
    quantity: 0,
  },
};
