import { ICourseFull } from 'interface/courses';
import Quest from '../quest';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useRearrangeSpaceQuestsMutation } from 'store';
import { useDebounce, useGetCurrentTheme } from 'hooks';

import * as S from './questDnd.styles';
import { DndContext, DragEndEvent, useSensors, useSensor, PointerSensor } from '@dnd-kit/core';
import { SortableContext, arrayMove, useSortable, horizontalListSortingStrategy } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import Answer from 'components/library/messages/Answer';

type Props = {
  quests: ICourseFull[];
  completedQuests?: string[];
  onDelete?: (_id: string) => void;
  spaceImage: string;
  spaceId: string;
  FirstElem: React.ReactNode;
};

const SortableItem = ({
  quest,
  spaceImage,
  isFinished,
  onDelete,
}: {
  quest: ICourseFull;
  spaceImage: string;
  isFinished: boolean;
  onDelete?: (_id: string) => void;
}) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: quest._id });
  const theme = useGetCurrentTheme();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <S.DragItem ref={setNodeRef} style={style}>
      <div {...attributes} {...listeners}>
        <S.DragDots $theme={theme} />
      </div>
      <Quest spaceImage={spaceImage} key={quest._id} isOwner={true} course={quest} isFinished={isFinished} onDelete={onDelete} />
    </S.DragItem>
  );
};

const QuestDndGrid = ({ quests, spaceImage, completedQuests, onDelete, spaceId, FirstElem }: Props) => {
  const [rearrange] = useRearrangeSpaceQuestsMutation();
  const debounce = useDebounce();
  const [isInit, setInit] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<ICourseFull[]>([...quests].sort((a, b) => (a.indexNumber || 0) - (b.indexNumber || 0)));

  const sensors = useSensors(useSensor(PointerSensor));

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!isInit) {
      setInit(true);
    }

    if (!over) return;

    if (active.id !== over.id) {
      setCurrentOrder((items) => {
        const oldIndex = items.findIndex((item) => item._id === active.id);
        const newIndex = items.findIndex((item) => item._id === over.id);

        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  useEffect(() => {
    setInit(false);
    setCurrentOrder([...quests].sort((a, b) => (a.indexNumber || 0) - (b.indexNumber || 0)));
  }, [JSON.stringify(quests)]);

  useEffect(() => {
    const updateRecursive = async (i = 0) => {
      try {
        const result = await rearrange({ spaceId, ids: currentOrder.map((el) => el._id) }).unwrap();
        if (!result) {
          if (i >= 2) {
            toast.info(<Answer label="Failed to save new quest order" type="incorrect" />, { autoClose: 1000 });
            setCurrentOrder([...quests].sort((a, b) => (a.indexNumber || 0) - (b.indexNumber || 0)));
            return;
          } else {
            updateOrder(i + 1);
            return;
          }
        } else {
          toast.info(<Answer label="Saved!" type="correct" />, { autoClose: 700 });
        }
      } catch (e) {
        console.error(e);
      }
    };

    const updateOrder = debounce(updateRecursive, 500);

    if (isInit) {
      updateOrder();
    }
  }, [currentOrder]);

  const memoedSortableItems = useMemo(
    () =>
      currentOrder.map((quest) => (
        <SortableItem
          key={quest._id}
          quest={quest}
          spaceImage={spaceImage}
          isFinished={Boolean(completedQuests && completedQuests.includes(quest._id))}
          onDelete={onDelete}
        />
      )),
    [currentOrder, JSON.stringify(quests), JSON.stringify(completedQuests)]
  );

  return (
    <DndContext sensors={sensors} onDragEnd={handleDragEnd}>
      <SortableContext items={currentOrder.map((quest) => quest._id)} strategy={horizontalListSortingStrategy}>
        <S.Container>
          {FirstElem}
          {memoedSortableItems}
        </S.Container>
      </SortableContext>
    </DndContext>
  );
};

export default QuestDndGrid;
