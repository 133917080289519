import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      width: 100%;
      display: grid;
      grid-template-columns: auto 1fr;
      row-gap: 8px;
    `
  ),
  Badges = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      margin-right: 12px;
    `
  ),
  Descs = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
    `
  ),
  Item = styled.div(
    () => css`
      height: 25px;
      display: flex;
      align-items: center;
      margin-bottom: 9px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `
  ),
  ItemBadge = styled.div(() => css``),
  Header = styled.div(
    () => css`
      font-size: 14px;
      line-height: 100%;
      text-transform: capitalize;
      /* color: var(--font-color-strong); */
    `
  );
