import { useDisplay, useGetCurrentTheme } from 'hooks';
import clsx from 'clsx';
import { useLocation, useParams } from 'react-router-dom';
import { ROUTES } from 'app/constants';

import * as Styled from './styles';

export const TitleTransitionBar = () => {
  const { isMobile } = useDisplay();
  const theme = useGetCurrentTheme();
  const location = useLocation();
  const { spaceName } = useParams();

  const excludedPaths = [ROUTES.MAIN, ROUTES.SHOP, ROUTES.COURSES, ROUTES.PLAY, ROUTES.SPACES];

  const isExcludedPath = excludedPaths.includes(location.pathname as ROUTES);
  const isExcludedRegex = new RegExp(/courses\/[a-zA-Z0-9]*\/[a-zA-Z0-9]*\/[a-zA-Z0-9]*/gi).test(location.pathname);
  const isExcluded = isExcludedPath || isExcludedRegex;

  if (!isMobile || isExcluded || !!spaceName) {
    return null;
  }

  return <Styled.Container className={clsx('brow', theme)} />;
};
