import styled, { css } from 'styled-components';

export const ButtonShowActivatedCodes = styled.button(
    () => css`
      background-color: rgba(var(--basic-green--rgb), 0.4);
      width: 80%;
      height: 51px;
      border-radius: 28px;
      border: none;
      align-items: center;
      color: #fff;
      margin: auto;
      font-weight: 500;
      font-size: 18px;
      line-height: 18px;

      &.light {
        background-color: rgba(var(--basic-green--rgb), 1);
      }

      &.small {
        width: 250px;
        margin-top: 0;
      }

      svg {
        margin-right: 5px;
      }

      &:hover {
        background-color: rgba(33, 136, 110, 0.4);
      }
    `
  ),
  ContainerActivatedCodes = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 10vh;

      &.small {
        margin-top: 0;
      }
    `
  );
