import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    .header {
      font-size: 28px;
    }

    &.mobile {
      padding: 20px;
      padding-top: 28px;
    }
  `
);
