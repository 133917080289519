import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { useGetAuthMethodsData, useLogout } from 'hooks';
import { getUserData } from 'store';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';

import UserExpLabel from 'shared/ui/user-exp-label/label';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { MightyVersion } from 'components/shared/versionMighty';
import { EffectsWidget } from '../panels';

import { ReactComponent as Logout } from 'assets/icons/logout.svg';
import { BsChevronRight } from 'react-icons/bs';

import styles from './styles.module.scss';

interface IProps {
  onClose: () => void;
  onEffectsOpen(): void;
}

export default function MobileUsermenu({ onClose, onEffectsOpen }: IProps) {
  const { customization, avatar, nickname, expTokenBalance } = useSelector(getUserData);
  const { getEmail: getIEmail } = useGetAuthMethodsData();
  const logout = useLogout();

  const { t } = useTranslation('usermenu');
  const navigate = useNavigate();

  const logOutHandler = () => {
    logout()
      .then(() => {
        navigate(ROUTES.AUTH, { replace: true });
      })
      .catch(console.error);
  };

  function handleProfileNav() {
    navigate(ROUTES.MAIN);
    onClose();
  }

  return (
    <>
      <div className={styles.container}>
        <div className={clsx(styles.user)} onClick={handleProfileNav}>
          <div className={styles.avatar}>
            <AvatarPic className={styles.pic} customization={customization} avatar={avatar} nickname={nickname} />
          </div>
          <div className={styles.info}>
            <h4>{nickname}</h4>
            <p>{getIEmail() || 'Not set'}</p>
            <UserExpLabel className={styles.exp} exp={expTokenBalance} customization={customization} />
          </div>
        </div>
        <div className={styles.tab} role="button" onClick={onEffectsOpen}>
          <EffectsWidget isChevron={false} className={styles.effects} handleOpenEffects={() => {}} />
          <div className={styles.tabIcon}>
            <BsChevronRight size={20} />
          </div>
        </div>
        <div className={clsx(styles.logout, styles.tab, styles.logout)} role="button" onClick={logOutHandler}>
          <Logout className={styles.icon} />
          <p className={styles.label}>{t('logout')}</p>
        </div>
        <div style={{ opacity: 0.3 }}>
          <MightyVersion />
        </div>
      </div>
    </>
  );
}
