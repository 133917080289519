import { FC } from 'react';
import CountUp from 'react-countup';

import { EnergyRecoveryAnimation } from './components';

import { ReactComponent as EnergyIcon } from './assets/energy-recovery.svg';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { Percent, ActivationWrap, Time, CountUpWrap } from '../styled';
import { EItemFungibleType } from 'interface';
import { useGetEffectData } from '../../../hooks';

interface IEnergyRecoveryProps {
  isActive?: boolean;
  onEnd?: () => void;
  type: EItemFungibleType.SmallEnergyStone | EItemFungibleType.LargeEnergyStone;
}

export const EnergyRecovery: FC<IEnergyRecoveryProps> = ({ isActive, onEnd, type }) => {
  const handleEnd = () => {
    onEnd && setTimeout(() => onEnd(), 1000);
  };
  const data = useGetEffectData(type as EItemFungibleType);
  let value = 0,
    forTime = '';

  if (data) {
    [, forTime] = data.desc;
    value = data.value;
  }

  if (isActive) {
    return (
      <CountUpWrap $color={'#95D8F5'} $gap={'12px'}>
        <EnergyRecoveryAnimation />
        <CountUp duration={2.5} delay={0.3} start={0} end={value} suffix={'%'} prefix={'+'} onEnd={handleEnd} />
      </CountUpWrap>
    );
  }

  return (
    <ActivationWrap $margin={'37px 0 42px 0'} $gap={'19px'}>
      <EnergyIcon />
      <div>
        <Percent $color={'#95D8F5'} $textShadowColor={'rgba(149, 216, 245, 0.30)'} $textAlign={'start'}>
          +{value}
          <span>%</span>
        </Percent>
        <Time>
          <ClockIcon width={'18px'} height={'18px'} color={'#95D8F5'} />
          <span>{forTime}</span>
        </Time>
      </div>
    </ActivationWrap>
  );
};
