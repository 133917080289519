import { FC, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDisplay, useGetUserData } from 'hooks';
import { STATIC_HOST_PATH } from 'app/constants/path';

import Details from './Details';
import MobileBanner from './MobileBanner';
import { MultipurposeBanner } from 'shared/ui';

import styles from './styles.module.scss';
import { ICourse } from 'interface/courses';
import { useSelector } from 'react-redux';
import { getPartnerId } from 'store';

interface ICourseBanner {
  course: ICourse;
  open: boolean;
  setSelectedCourse: () => void;
  isReady?: boolean;
}

export const CourseBanner: FC<ICourseBanner> = memo(({ course, open, setSelectedCourse, isReady = true }) => {
  const navigate = useNavigate();
  const { isMobile } = useDisplay();
  const [seeChaptersList, setSeeChaptersList] = useState(false);
  const { resultCourses } = useGetUserData();
  const partnerId = useSelector(getPartnerId);

  const resultCourse = useMemo(() => {
    return course ? resultCourses?.find((el) => el.courseId === course._id) : null;
  }, [resultCourses, course]);

  const lastChapter = useMemo(() => (resultCourse ? resultCourse.resultChapters[resultCourse.resultChapters.length - 1] : null), [resultCourse]);
  const lastLesson = useMemo(() => (lastChapter ? lastChapter.resultLessons[lastChapter.resultLessons.length - 1] : null), [lastChapter]);

  useEffect(() => {
    setSeeChaptersList(false);
  }, [course]);

  const goToCourse = useCallback(() => {
    if (course.chapters?.length > 0 && !resultCourse) {
      const link =
        `/courses/${course._id}/${course.chapters[0]._id}/${course.chapters[0].lessons[0]._id}` + (!!partnerId ? `?partnerID=${partnerId}` : '');
      navigate(link);
      return;
    }
    if (resultCourse && lastChapter && lastLesson) {
      const link =
        `/courses/${resultCourse.courseId}/${lastChapter.chapterId}/${lastLesson.lessonId}` + (!!partnerId ? `?partnerID=${partnerId}` : '');
      navigate(link);
      return;
    }
  }, [lastChapter, lastLesson, partnerId, course, resultCourse]);

  const handleSetSeeChaptersList = (value: boolean) => () => {
    setSeeChaptersList(value);
  };

  const cover = course ? STATIC_HOST_PATH + (course.imageCover || course.imageBanner) : '';

  return isMobile ? (
    <MobileBanner
      isOpen={open}
      setSelectedCourse={setSelectedCourse}
      setSeeChaptersList={handleSetSeeChaptersList}
      seeChaptersList={seeChaptersList}
      course={course}
      cover={cover}
      goToCourse={goToCourse}
      isReady={isReady}
    />
  ) : (
    <MultipurposeBanner
      isForcedHidden={!open}
      onClose={setSelectedCourse}
      horizontalClassName={styles.horizontalClassName}
      panelClassName={styles.panelClassName}
      slides={[
        {
          component: Details,
          name: 'Details',
          props: {
            course,
            cover,
            seeChaptersList,
            goToCourse,
            setSeeChaptersList,
          },
        },
      ]}
      {...{ bannerCardsSize: 'isSmall' }}
    />
  );
});
