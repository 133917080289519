import { GRADES, GRADES_COLORS, THEMES } from 'app/constants';
import { TGrade } from 'interface';
import styled from 'styled-components';

export const Container = styled.div<{ $theme?: THEMES }>`
  display: flex;
  align-items: center;
  padding: 18px 0px;
  width: 100%;
  border-bottom: 1px solid ${(p) => (p.$theme === THEMES.LIGHT ? '#11111126' : '#FFFFFF26')};
`;

export const Image = styled.img`
  width: 50px;
  height: 50px;
  object-fit: contain;
  margin-right: 14px;
`;

export const NameCol = styled.div<{ $grade: GRADES | TGrade }>`
  display: flex;
  flex-direction: column;
  gap: 9px;
  color: ${({ $grade }) => GRADES_COLORS[$grade]};
  ${({ $grade }) => $grade === GRADES.COMMON && 'color: var(--font-color-strong) !important;'}
`;
export const Name = styled.p`
  color: white;
  font-size: 18px;
`;

export const Amount = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  margin-left: auto;
  min-width: 102px;
  justify-content: space-between;
`;

export const Num = styled.p`
  font-size: 18px;
  font-weight: 500;
`;

export const Button = styled.div<{ $theme: THEMES }>`
  width: 36px;
  height: 36px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? '#ffffff0f' : '#1111110f')};
  transition: 0.3s;
  color: var(--font-color-strong);
  &:hover {
    background: ${({ $theme }) => ($theme === THEMES.DARK ? '#ffffff16' : '#11111116')};
  }
  & > svg {
    height: 20px;
    width: 20px;
  }
`;
