import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: fit-content;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 160px 0px 51px;
  & > .chest {
    position: absolute;
    top: -27px;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 992px) {
    width: 100%;
    height: fit-content;
    padding-top: 124px;
    & > .chest {
      top: -3px;
    }
  }
`;

export const Title = styled.p<{ $theme: THEMES }>`
  font-size: 42px;
  line-height: 43.68px;
  text-align: center;
  margin-bottom: 22px;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  @media screen and (max-width: 992px) {
    font-size: 28px;
    line-height: 34.72px;
  }
`;

export const RewsBox = styled.div<{ $theme: THEMES }>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  height: 51px;
  padding: 0px 14px;
  background: #627eea24;
  border-radius: 6px;
  margin-bottom: 32px;
  width: fit-content;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  font-size: 20px;
  & > span {
    color: inherit;
  }
  & > img {
    width: 28px;
    height: 28px;
    &:first-child {
      width: 39px;
      height: 39px;
    }
  }
`;
