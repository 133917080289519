import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    padding-right: 40px;
    padding-bottom: 8px;
    max-height: calc(371px - var(--padding-y) * 3);
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .short {
      max-height: fit-content;
    }

    & > div {
      margin-bottom: 14px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  `
);
