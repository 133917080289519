import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    background: linear-gradient(
      180deg,
      rgba(var(--basic-dark--rgb), 1) 22%,
      rgba(var(--basic-dark--rgb), 0.8) 41.5%,
      rgba(var(--basic-dark--rgb), 0) 95%
    );
    width: 100vw;
    height: 31px;
    position: absolute;
    top: 0;
    z-index: 100;

    &.dark {
      background: linear-gradient(
        180deg,
        rgba(var(--basic-light--rgb), 1) 22%,
        rgba(var(--basic-light--rgb), 0.8) 41.5%,
        rgba(var(--basic-light--rgb), 0) 95%
      );
    }
  `
);
