export type State = {
  completedTasksCount: number;
  tasksLeft: number;
  isVisible: boolean;
  isRight: boolean | null;
};

export type Action = {
  type: 'TASKS_COUNT' | 'TASKS_LEFT';
  payload?: number;
};

export const defaultState: State = {
  completedTasksCount: 0,
  tasksLeft: 0,
  isVisible: false,
  isRight: null,
};

export const stateReducer = (state: State, arg: Partial<State> | Action): State => {
  if ('type' in arg) {
    switch (arg.type) {
      case 'TASKS_COUNT':
        return { ...state, completedTasksCount: (state.completedTasksCount += 1) };
      case 'TASKS_LEFT':
        if (arg.payload) return { ...state, tasksLeft: arg.payload };
        return state;
      default:
        return state;
    }
  }
  return { ...state, ...arg };
};
