import { useSelector } from 'react-redux';
import SearchGroup from './SearchGroup';
import { getCurrentTheme } from 'store';

import './styles/search-items-area.css';

export default function SearchItemsArea({ items, isInput, domNode, goToItem }) {
  const theme = useSelector(getCurrentTheme);

  return (
    <div className={`glass-div basic ${theme} search-items-area tiny-sb`} ref={domNode}>
      {items.length < 1 ? (
        isInput ? (
          <div className="search-group item-container">
            <p style={{ opacity: 0.5, textTransform: 'none' }}>No items found</p>
          </div>
        ) : (
          <div className="search-group item-container">
            <p style={{ opacity: 0.5, textTransform: 'none' }}>Type here to search...</p>
          </div>
        )
      ) : (
        <>
          {items.length > 0 &&
            items.map(([name, results], index) => (
              <SearchGroup key={'search-main-group-' + index} groupName={name} results={results} goToItem={goToItem} />
            ))}
          <div className="search-group item-container" onClick={() => goToItem('search')}>
            See all
          </div>
        </>
      )}
    </div>
  );
}
