import circleLight from 'assets/images/registration-success-circle--light.png';
import circleDark from 'assets/images/registration-success-circle--dark.png';
import BasicButton from 'shared/ui/basic-button/button';
import { MobileModal } from 'shared/utils/mobile';
import { THEMES } from 'app/constants';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';

interface IProps {
  open: boolean;
  onClose: () => void;
  title: string;
  subTitle: string;
}

export const SuccessAuthModal = ({ open, title, subTitle, onClose }: IProps) => {
  const theme = useGetCurrentTheme();
  const { isMobile } = useDisplay();

  return isMobile ? (
    <MobileModal classNameContainer={clsx(styles.modal, styles[theme], styles.mobile)} isOpen={open} onClose={() => {}}>
      <div className={styles.container}>
        <img src={theme === THEMES.DARK ? circleDark : circleLight} alt="circle of items" className={styles.circleImg} />
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subTitle}</div>
        <BasicButton variant="cool" label={'Go to Login'} style={{ color: 'white' }} className={styles.button} onClick={onClose} />
      </div>
    </MobileModal>
  ) : (
    <CenteredModal padding="150px 0 0" className={clsx(styles.modal, styles[theme], styles.desktop)} show={open} onClose={() => {}} isClosing={false}>
      <div className={styles.container}>
        <img src={theme === THEMES.DARK ? circleDark : circleLight} alt="circle of items" className={styles.circleImg} />
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{subTitle}</div>
        <BasicButton variant="cool" label={'Go to Login'} style={{ color: 'white' }} className={styles.button} onClick={onClose} />
      </div>
    </CenteredModal>
  );
};
