import { THEMES } from 'app/constants';
import { FastAverageColorOptions } from 'fast-average-color';
import useAverageColorFromImageUrl from 'hooks/useAverageColorFromImageUrl';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { RGBAToHexA } from './SpaceQuest/hooks';

/**
 * get brightness of color
 * @param color color in #rrggbb format
 */

export const getBrightness = (color: string) => {
  var c = color.substring(1);
  var rgb = parseInt(c, 16);
  var r = (rgb >> 16) & 0xff;
  var g = (rgb >> 8) & 0xff;
  var b = (rgb >> 0) & 0xff;

  var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b;

  return luma;
};

/**
 *  get preferred text color based on given color's brightness and current theme of the app
 *  @param color - color as string (autoconverts to HEX without alpha)
 *  @param theme - current app theme
 *  @param tolerance - number value to be added to 180 of 255 of brightness
 */

export const getTextColor = (color: string, theme: THEMES, tolerance?: number) => {
  const hexColor = color.includes('rgb') ? RGBAToHexA(color, true) : color.slice(0, 7);

  if (theme === THEMES.DARK) {
    return '#ffffff';
  }

  if (color) {
    const brightness = getBrightness(hexColor);
    if (brightness > 180 + (tolerance || 0)) {
      return '#111111';
    }
    return '#ffffff';
  }

  return '#111111';
};

type Props = { imagePath: string; options?: FastAverageColorOptions; tolerance?: number };

/**
 * get average color based on image && preferred text color based on average color's brightness
 * @param tolerance - number value to be added to 180 of 255 of brightness
 */

export const useAveregeColorWText = ({ imagePath, options = {}, tolerance }: Props) => {
  const theme = useSelector(getCurrentTheme);
  const fullColor = useAverageColorFromImageUrl(imagePath, { ...options });

  const color = useMemo(() => (theme === THEMES.DARK ? fullColor.replace(',1)', ', 0.1)') : fullColor), [theme, fullColor]);
  const isBright = useMemo(() => getBrightness(RGBAToHexA(color, true)) > 180, [color]);
  const textColor = useMemo(() => getTextColor(color, theme, tolerance), [color, theme, fullColor]);

  return { color, textColor, isBright };
};
