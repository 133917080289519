import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { useDisplay, useGetCurrentTheme } from 'hooks';

import { ROUTES } from 'app/constants';
import * as Styled from './styled';

type TBG = 'profile' | 'courses' | 'spaces' | 'space' | null;

interface Props {
  bg?: TBG;
  className?: string;
}

export const PageBackground = ({ bg = null, className = '' }: Props) => {
  const theme = useGetCurrentTheme(true);
  const { pathname } = useLocation();
  const { isMobile } = useDisplay();

  if (isMobile && !Boolean(bg)) return <></>;

  return (
    <Styled.Container
      className={clsx({
        'page-background__profile': pathname === ROUTES.PROFILE || bg === 'profile',
        'page-background__courses': pathname === ROUTES.MAIN || bg === 'courses',
        'page-background__spaces': pathname === ROUTES.SPACES || pathname === ROUTES.SPACE || bg === 'spaces',
        'page-background__space-creation': pathname === ROUTES.SPACE_CREATION || bg === 'space',
        light: theme === 'light',
        [className]: !!className,
      })}
    ></Styled.Container>
  );
};
