import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      margin-bottom: 23px;

      &.mobile {
        flex-direction: column;
        align-items: center;
      }
    `
  ),
  Indicator = styled.div(
    () => css`
      margin-right: 14px;
      width: 83px;
      min-width: 83px;
      &.mobile {
        margin-right: 0;
        width: 124px;
        min-width: 124px;
      }
    `
  ),
  Header = styled.span(
    () => css`
      margin-bottom: 6px;
      &.mobile {
        margin-bottom: 10px;
        font-size: 28px;
      }
    `
  ),
  Info = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      &.mobile {
        align-items: center;
      }
    `
  ),
  EffectBadges = styled.div(
    () => css`
      &.mobile {
        justify-content: center;
      }
    `
  );
