import { IDatabaseItemQuantified } from 'interface';
import { ReactElement, createContext, useContext } from 'react';

export type TSlideContextState = {
  navigateUser: (arg?: string) => any;
  navForward: () => any;
  progressCourse: (arg: string[]) => Promise<any | void>;
  slideId: string;
  rewards: IDatabaseItemQuantified[];
  isCompleting: boolean;
  isInProgress: boolean;
  customNav?: ReactElement | null;
  hideButtonOnRight?: boolean;
};

export const MaterialPageContext = createContext<TSlideContextState>(null as any);

export const useMaterialPageContext = () => {
  return useContext(MaterialPageContext);
};
