import { toast } from 'react-toastify';
import { ESpaceRoles, ISpaceUser, useToggleSpaceRoleMutation } from 'store';
import { useMemo, useState } from 'react';
import { useSpacePageContext } from '../../context';

import UserFinder from 'components/library/friends-finder/user-finder';
import { Avatar } from 'components/library/Avatar';
import { BasicButtonV1, Modal } from 'shared/ui';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { THEMES } from 'app/constants';
import Answer from 'components/library/messages/Answer';
import { useGetCurrentTheme } from 'hooks';

import styles from './modal.module.scss';
import { SpaceFormBox, SpaceFormLabel } from '../../SpaceCreation/components/ui/styles';
import * as S from './styles';

const SpaceAdmins = () => {
  const { space, updateSpace } = useSpacePageContext();
  const [addUser] = useToggleSpaceRoleMutation();
  const [userToDelete, setUserToDelete] = useState<{ _id: string; nickname: string } | null>(null);
  const theme = useGetCurrentTheme();

  const onUserSelect = (userId: string, onSuccess?: any) => {
    if (space) {
      addUser({ role: ESpaceRoles.ADMIN, spaceId: space?._id, userId })
        .unwrap()
        .then((res) => {
          updateSpace({ users: res });
          toast.info(<Answer type="declined" label="Success!" subtext="Admin added" />, { autoClose: 1000 });
          onSuccess && onSuccess();
        })
        .catch(() => toast.error(<Answer type="incorrect" label="Oops" subtext="Failed to add admin" />));
    }
  };

  const handleModalClose = (users?: ISpaceUser[]) => {
    if (users) {
      updateSpace({ users });
    }
    setUserToDelete(null);
  };

  const admins = useMemo(() => (space ? space.users.filter((el) => el.roles.includes(ESpaceRoles.ADMIN)) : []), [space?.users]);

  if (!space) {
    return null;
  }

  return (
    <>
      <SpaceFormBox>
        <SpaceFormLabel>Admins</SpaceFormLabel>
        <S.Users>
          <UserFinder
            Trigger={(props: { onClick: any }) => (
              <S.UserBox $theme={theme} onClick={props.onClick}>
                <S.AvatarPlaceholder $theme={theme} />
                Add New
              </S.UserBox>
            )}
            onAddUser={onUserSelect}
          />
          {admins
            .filter((el) => el.roles.includes(ESpaceRoles.ADMIN))
            .map((el) => (
              <S.UserBox $theme={theme} key={el.userId._id}>
                <S.AvatarBox onClick={() => setUserToDelete({ _id: el.userId._id, nickname: el.userId.nickname })}>
                  <S.AvatarDeletePic />
                  <Avatar size={58} userName={el.userId.nickname} src={el.userId.avatar} />
                </S.AvatarBox>
                {el.userId.nickname}
              </S.UserBox>
            ))}
        </S.Users>
      </SpaceFormBox>
      <ConfirmAdminDeleteModal onClose={handleModalClose} userId={userToDelete} spaceId={space._id} />
    </>
  );
};

const ConfirmAdminDeleteModal = ({
  userId,
  onClose,
  spaceId,
}: {
  userId?: { _id: string; nickname: string } | null;
  onClose: (arg?: ISpaceUser[]) => void;
  spaceId: string;
}) => {
  const [deleteUser, { isLoading }] = useToggleSpaceRoleMutation();
  const theme = useGetCurrentTheme();

  if (!userId) {
    return null;
  }

  const onUserSelect = () => {
    if (userId) {
      deleteUser({ role: ESpaceRoles.ADMIN, spaceId: spaceId, userId: userId._id })
        .unwrap()
        .then((res) => {
          toast.info(<Answer label="Success!" subtext="Admin deleted" />, { autoClose: 1000 });
          onClose(res);
        })
        .catch(() => toast.error(<Answer type="incorrect" label="Oops" subtext="Failed to delete admin" />));
    }
  };

  return (
    <Modal closeButtonLocation="outside" open={!!userId} onClose={() => onClose()} className={`${styles.adminDeleteModal} ${styles[theme]}`}>
      <S.AdminModalContainer>
        <S.AdminModalTitle $theme={theme}>
          Are you sure you want to delete <S.Span>{userId?.nickname}</S.Span> from admins?
        </S.AdminModalTitle>
        <S.AdminModalButtons>
          {isLoading ? (
            <MightyLoaderMini />
          ) : (
            <>
              <BasicButtonV1
                onClick={() => onClose()}
                style={{ background: theme === THEMES.LIGHT ? '#1111111A' : '#FFFFFF1A' }}
                variant="solid"
                color="transparent"
                size="sm"
                disabled={isLoading}
              >
                Cancel
              </BasicButtonV1>
              <BasicButtonV1 variant="solid" size="sm" onClick={onUserSelect}>
                Confirm
              </BasicButtonV1>
            </>
          )}
        </S.AdminModalButtons>
      </S.AdminModalContainer>
    </Modal>
  );
};

export default SpaceAdmins;
