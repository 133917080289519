import clsx from 'clsx';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.css';
import { TermsPrivacyModal } from './modal';
import useTerms from './useTerms';

export const Terms = () => {
  const { t } = useTranslation('auth');
  const { page, handleChoose, handleClose } = useTerms();

  return (
    <>
      <div className={styles.container}>
        <span className={styles.by}>{t('terms.by')}</span>
        <div className={clsx(styles.link, styles.terms)} onClick={() => handleChoose('terms')}>
          {t('terms.terms')}
        </div>
        <span className={styles.and}>{t('terms.and')}</span>
        <div className={clsx(styles.link, styles.privacy)} onClick={() => handleChoose('policy')}>
          {t('terms.privacy')}
        </div>
      </div>
      {page && <TermsPrivacyModal onClose={handleClose} page={page} />}
    </>
  );
};
