import { Modal } from 'shared/ui';
import styles from './modal.module.scss';
import * as S from './styles';
import RewardsFilters from './components/Filters';
import { ERewardsTabs } from './types';
import { IRewardMinimal } from 'store';
import { useMemo, useReducer } from 'react';
import OriginalsRewardsTab from './components/Tabs/Originals';
import RewardsList from './components/RewardsList';
import ChestsTab from './components/Tabs/Chests';
import CertificatesTab from './components/Tabs/Certificates';
import clsx from 'clsx';
import { useGetCurrentTheme } from 'hooks';
import { AnimatePresence } from 'framer-motion';

type Props = {
  onSubmit: (arg: IRewardMinimal[]) => void;
  slideRewards: IRewardMinimal[];
  isOpen: boolean;
  handleClose: () => void;
};

type State = {
  tab: ERewardsTabs;
  search: string | null;
  rewards: IRewardMinimal[];
};

const initialState: State = {
  tab: ERewardsTabs.ALL,
  search: null,
  rewards: [],
};

const NewRewardsModal = ({ handleClose, isOpen, onSubmit, slideRewards }: Props) => {
  const theme = useGetCurrentTheme();
  const [state, updateState] = useReducer((prev: State, next: Partial<State>) => ({ ...prev, ...next }), {
    ...initialState,
    rewards: slideRewards.length ? structuredClone(slideRewards) : [],
  });
  const { rewards, search, tab } = state;

  const updateReward = (id: number, quantity: number) => {
    const rews = [...rewards];
    const needed = rews.find((el) => el.id === id);

    if (needed) {
      needed.quantity = quantity;
      updateState({ rewards: [...rews] });
    } else {
      updateState({ rewards: [{ id, quantity }, ...rews] });
    }
  };

  const handleSubmit = () => {
    onSubmit(rewards.filter((el) => !!el.quantity));
    handleClose();
  };

  const TABS = useMemo(
    () => ({
      [ERewardsTabs.ALL]: <OriginalsRewardsTab key="all" search={search} rewards={rewards} updateReward={updateReward} />,
      [ERewardsTabs.ORIGINAL]: <OriginalsRewardsTab key={'origs'} search={search} rewards={rewards} updateReward={updateReward} />,
      [ERewardsTabs.CHESTS]: <ChestsTab key="chests" />,
      [ERewardsTabs.CERTIFICATES]: <CertificatesTab key="certfs" />,
    }),
    [rewards, updateReward, search]
  );

  return (
    <Modal
      closeButtonLocation="outside"
      className={clsx(styles.modal, styles[theme])}
      containerClassName={styles.container}
      open={isOpen}
      onClose={handleClose}
    >
      <S.Container>
        <S.Header>Add Rewards</S.Header>
        <RewardsFilters search={search} tab={tab} updateState={updateState} />
        <AnimatePresence exitBeforeEnter>{TABS[tab]}</AnimatePresence>
      </S.Container>
      <RewardsList updateRewards={updateReward} onSubmit={handleSubmit} rewards={rewards} />
    </Modal>
  );
};

export default NewRewardsModal;
