import { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { CodeWebEditor } from './ui/CodeWebEditor';
import { EditorContainer, SCompileButton } from './style/styled';
import { CodeWebOutput } from './ui/CodeWebOutput/CodeWebOutput';
import { IWebLanguage } from './model';
import { iframeDocLayout } from './data';
import { AnimatePresence } from 'framer-motion';
import { TContentWebWidget, TWebWidgetSetup } from '../index';
import { useCompilePythonCodeMutation } from 'store/compile/compile.api';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { useDebounce } from 'hooks';

interface Props extends PropsWithChildren {
  activeEditorName: IWebLanguage;
  content: TContentWebWidget;
  config: TWebWidgetSetup;
  onChange?: (data: any) => void;
  comment?: any;
  setOutputIsVisible: (bool: boolean) => void;
  setOutputContainer: React.Dispatch<React.SetStateAction<(props: any) => JSX.Element>>;
  isOutputIsVisible?: boolean;
}

export const CodeWidgetWeb: FC<Props> = ({
  activeEditorName,
  content,
  config,
  onChange,
  comment,
  setOutputIsVisible,
  setOutputContainer,
  isOutputIsVisible,
}) => {
  const debounce = useDebounce();
  const [html, setHtml] = useState(content?.html ? content.html : '');
  const [css, setCss] = useState(content?.css ? content.css : '');
  const [js, setJs] = useState(content?.js ? content.js : '');

  const [python, setPython] = useState(content?.python ? content?.python : null);
  const [compiledLang, setCompiledLang] = useState<any>(null);
  const [compileCode, { isLoading: isCompilingPython }] = useCompilePythonCodeMutation();

  const [output, setOutput] = useState<any[]>([]);

  const [srcDoc, setSrcDoc] = useState(` `);

  const $trigger = useRef(null);

  useEffect(() => {
    function handleMessage(event: MessageEvent) {
      if (event.data.log) {
        // Handle the log
        setOutput((prevOutput) => [...prevOutput, event.data.log]);
      } else if (event.data.error) {
        // Handle the error
        setOutput([{ error: event.data.error.message }]);
      }
    }

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    let timeOut: any;
    if (config.mode === 'onKeyCompile') {
      if (html || css || js) {
        setOutputIsVisible(true);
      }

      timeOut = setTimeout(() => {
        preparationSrcDoc();
      }, 250);
    }
    return () => clearTimeout(timeOut);
  }, [html, css, js]);

  useEffect(() => {
    setOutputContainer(() => (props: any) => (
      <AnimatePresence>
        {config.withOutput && isOutputIsVisible && (
          <CodeWebOutput {...props} srcDoc={srcDoc} output={output.flat()} config={config} compiledLang={compiledLang} />
        )}
      </AnimatePresence>
    ));
  }, [config, isOutputIsVisible, output, setOutputContainer, srcDoc, compiledLang]);

  const handleSrcDoc = () => {
    preparationSrcDoc();

    if (html || css || js) {
      setOutputIsVisible(true);
    }
  };

  const handleCompilePython = debounce(() => {
    const setResult = ({ result, error }: any) => {
      setCompiledLang(result ? { result } : { error });
      setOutputIsVisible(true);
    };
    if (python) {
      compileCode(python)
        .unwrap()
        .then((result) => setResult({ result }))
        .catch(({ status, data }) => {
          if (status === 500) {
            setResult({ error: data.message });
          }
        });
    }
  }, 200);

  const updateImagePaths = (htmlContent: string) => {
    // const baseUrl = process.env.REACT_APP_STATIC_HOST;
    const baseUrl = 'https://test.mighty.study';
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlContent, 'text/html') as any;
    const images = doc.getElementsByTagName('img');

    for (const img of images) {
      let src = img.getAttribute('src');
      if (src && !src.startsWith('http')) {
        // Prepend the base URL
        src = baseUrl + (src.startsWith('/') ? '' : '/') + src;
        img.setAttribute('src', src);
      }
    }

    const serializer = new XMLSerializer();
    return serializer.serializeToString(doc);
  };

  function preparationSrcDoc() {
    setOutput([]);

    const updatedHtml = updateImagePaths(html);

    const doc = iframeDocLayout(updatedHtml, css, js);
    const blob = new Blob([doc], { type: 'text/html' });
    const url = URL.createObjectURL(blob);
    setSrcDoc(url);
  }

  const handleOnChange = useCallback(
    (state: Record<string, string | null>) => {
      if (onChange) {
        onChange(state);
      }
    },
    [onChange]
  );

  const handleStateChange = useCallback(
    (lang: string) => (value: string) => {
      const state = {
        html,
        css,
        js,
        python,
      };

      switch (lang) {
        case 'html':
          state.html = value;
          setHtml(value);
          break;
        case 'css':
          state.css = value;
          setCss(value);
          break;
        case 'js':
          state.js = value;
          setJs(value);
          break;
        case 'python':
          state.python = value;
          setPython(value);
          break;
      }
      handleOnChange(state);
    },
    [html, css, js, python, handleOnChange]
  );

  const mapEditor = {
    html: () => <CodeWebEditor readOnly={config.readOnly} language="html" value={html} onChange={handleStateChange('html')} />,
    css: () => <CodeWebEditor readOnly={config.readOnly} language="css" value={css} onChange={handleStateChange('css')} />,
    js: () => <CodeWebEditor readOnly={config.readOnly} language="javascript" value={js} onChange={handleStateChange('js')} />,
    python: () => <CodeWebEditor readOnly={config.readOnly} language="python" value={python ?? ''} onChange={handleStateChange('python')} />,
  };

  function handleRunCode() {
    if (activeEditorName === 'python') {
      return handleCompilePython();
    }
    return handleSrcDoc();
  }

  const debouncedRunCode = debounce(handleRunCode, 350);

  return (
    <EditorContainer ref={$trigger}>
      {mapEditor[activeEditorName]()}
      {config.mode === 'onPressButtonCompile' && (
        <SCompileButton disabled={isCompilingPython} onClick={debouncedRunCode}>
          {isCompilingPython ? <MightyLoaderMini style={{ width: '50%', margin: 'auto' }} /> : 'Run'}
        </SCompileButton>
      )}
      {comment && comment($trigger)}
    </EditorContainer>
  );
};
