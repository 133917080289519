import { forwardRef, useCallback, useEffect, useRef } from 'react';
import { TTransparentTextareaProps } from './types';
import clsx from 'clsx';
import styles from './styles.module.css';

const TransparentTextarea = forwardRef<HTMLTextAreaElement, TTransparentTextareaProps>(({ className, value, ...props }, ref) => {
  const localRef = useRef<HTMLTextAreaElement>(null);
  const combinedRef = ref || localRef;

  const changeHeight = useCallback(() => {
    if (combinedRef && 'current' in combinedRef && combinedRef.current) {
      combinedRef.current.style.height = '0px';
      combinedRef.current.style.height = combinedRef.current.scrollHeight + 'px';
    }
  }, [combinedRef]);

  useEffect(() => {
    if (!combinedRef || !('current' in combinedRef) || !combinedRef.current) {
      return;
    }

    changeHeight();
  }, [value, changeHeight, combinedRef]);

  return <textarea {...props} className={clsx(styles.root, className)} onInput={changeHeight} value={value} ref={combinedRef} />;
});

export default TransparentTextarea;
