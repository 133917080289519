import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      .next-rewards {
        margin: 34px 0 32px 0;
        height: 101px;
      }

      .streak.mobile,
      .next-rewards.mobile {
        color: #fff;
      }
    `
  ),
  Title = styled.h4(
    () => css`
      color: var(--font-color-strong);
      font-size: 30px;
      font-weight: 500;
      line-height: 110%;
      margin-bottom: 52px;

      @media screen and (max-width: 992px) {
        color: #fff;
        text-align: center;
        font-size: 38px;
        font-weight: 500;
        line-height: 114%;
        letter-spacing: -0.38px;
      }
    `
  ),
  ButtonWrap = styled.div(
    () => css`
      display: flex;
      align-items: center;
      margin: 32px 0 18px 0;

      .button {
        height: 60px;
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
      }

      .spinner {
        width: 40px;
        height: 40px;

        path {
          stroke: #ffffff;
        }
      }
    `
  ),
  ClockWrap = styled.div(
    () => css`
      color: var(--font-color-strong);
      display: flex;
      gap: 4px;
      align-items: center;
      margin-left: 13px;

      p {
        color: #fff;
        font-size: 20px;
        font-weight: 500;
        line-height: 100%;
      }
    `
  ),
  Text = styled.p(
    () => css`
      color: var(--font-color-strong);
      text-align: center;
      font-size: 14px;
      line-height: 125%;
      opacity: 0.25;

      @media screen and (max-width: 992px) {
        color: #fff;
      }
    `
  );
