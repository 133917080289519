import { ChangeEvent, FC, Fragment, useCallback, useEffect, useMemo, useState } from 'react';
import * as yup from 'yup';
import { useSlideContext } from '../../../SlideContext';
import { parseIfJson } from 'utils/object';
import { TFileMetadata } from 'components/pages/study/courses/material-pages/SlideContent/components/types';
import { ESlideWidgetTypes } from 'store';
import { TFileWidgetProps, TFileWidgetType, TImageSetup } from './types';
import { STATIC_HOST_PATH } from 'app/constants/path';

import { ECourseConstructorWidgetTypes } from 'components/library/courseConstructor/types';
import FileButton from 'components/library/fileButton';
import Tabs from 'components/library/tabs';
import { BasicButtonV1, DropdownMotion, Input } from 'shared/ui';
import { ResizableImage } from './ui/ResizableImage';

import { ReactComponent as PictureIcon } from 'assets/icons/picture.svg';
import { ReactComponent as UploadIcon } from 'assets/icons/upload.svg';
import { ReactComponent as FilmIcon } from 'assets/icons/film.svg';
import { FiUpload } from 'react-icons/fi';

import styles from './styles.module.css';
import * as Styled from './styles';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';

function isJsonString(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

const widgetTypeMetadataMap: Record<
  TFileWidgetType,
  {
    title: string;
    icon: React.ReactElement;
    buttonTitle: string;
    accept: string;
  }
> = {
  [ECourseConstructorWidgetTypes.FILE]: {
    title: 'Embed or upload a file',
    icon: <UploadIcon />,
    buttonTitle: 'Choose a file',
    accept: '.json,.txt,.ts,.js,.tsx,.jsx,.py,.md,.java,.cpp,.html,.css,.scss,.sass',
  },
  [ECourseConstructorWidgetTypes.IMAGE]: {
    title: 'Add an image',
    icon: <PictureIcon />,
    buttonTitle: 'Choose an image',
    accept: '.png,.jpg,.jpeg,.svg',
  },
  [ECourseConstructorWidgetTypes.VIDEO]: {
    title: 'Embed or upload a video',
    icon: <FilmIcon />,
    buttonTitle: 'Choose a video',
    accept: 'video/mp4,video/x-m4v,video/*',
  },
};

const FileWidget: FC<TFileWidgetProps> = ({ type, onChange, onDelete, id, value }) => {
  const [imageSetup, setImageSetup] = useState<TImageSetup>({
    width: '50',
    align: 'start',
  });
  const [isSized, setIsSized] = useState(false);

  const { slide, attachmentAction } = useSlideContext();

  const localValue = useMemo(() => parseIfJson(value) || value, [value]);
  const metadata = widgetTypeMetadataMap[type];
  const content = parseIfJson<TFileMetadata>(localValue) ?? localValue;

  // Embed value
  const [embedValue, setEmbedValue] = useState('');
  const [embedError, setEmbedError] = useState('');
  const embedValidationSchema = yup.object().shape({
    link: yup.string().required().url(),
  });

  const handleFileUpload = useCallback(
    (evt: ChangeEvent<HTMLInputElement>) => {
      try {
        if (!evt.target?.files) {
          return;
        }

        const file = evt.target.files[0],
          mbAllowed = 3,
          sizeAllowed = mbAllowed * 1024,
          imageSize = file?.size / 1024;
        if (sizeAllowed < imageSize) {
          toast.error(<Answer type="incorrect" label="Your image is too large!" subtext={`Allowed size is ${mbAllowed}mb`} />);
        } else {
          const form = new FormData();
          form.append('file', file);
          form.append('slideId', slide._id);

          attachmentAction
            .add({ slideId: slide._id, widgetId: id, form })
            .then(() => {})
            .catch(() => {
              alert('File uploading failed.');
            });
        }
      } catch (error) {
        toast.error(<Answer type="incorrect" label="Something went wrong...:/" />);
        console.log(error);
      }
    },
    [id, attachmentAction, slide._id]
  );

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (value && isSized) {
        onChange(JSON.stringify({ ...JSON.parse(value), ...imageSetup }));
        setIsSized(false);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [imageSetup, isSized, attachmentAction, id, slide._id, onChange, value]);

  const handleChangeEmbedLink = async (evt: ChangeEvent<HTMLInputElement>) => {
    const value = evt.target.value;

    try {
      await embedValidationSchema.validate({ link: value });
      if (embedError) {
        setEmbedError('');
      }
    } catch (e: any) {
      setEmbedError(e.errors[0]);
    } finally {
      setEmbedValue(value);
    }
  };

  const handleEmbedSubmit = () => {
    if (embedError) {
      return;
    }

    onChange(embedValue);
  };

  const handleUpdateImage = (setup: TImageSetup) => {
    setIsSized(true);
    return setImageSetup(setup);
  };

  const tabs: any[] = [];

  const file: string | null = useMemo(() => {
    let cover;
    const imageRaw = slide.content.find((cont) => cont.id === id);
    if (imageRaw?.content) {
      if (isJsonString(imageRaw?.content)) {
        const imageJSONed = JSON.parse(imageRaw.content);
        cover = STATIC_HOST_PATH + imageJSONed.filePath;
      } else {
        cover = imageRaw?.content;
      }
    }
    return cover ?? '';
  }, [id, slide.content]);

  if (type !== ESlideWidgetTypes.VIDEO) {
    tabs.push({
      title: 'Upload',
      content: (
        <Styled.SDropdownContentWrapper>
          {file ? (
            <Styled.PreviewContainer>
              <Styled.Overlay>
                <Styled.Label>
                  <input type="file" hidden onChange={handleFileUpload} />
                  <FiUpload />
                </Styled.Label>
              </Styled.Overlay>
              {type === ESlideWidgetTypes.IMAGE && <Styled.Img src={file} alt="preview" />}
            </Styled.PreviewContainer>
          ) : (
            <FileButton onChange={handleFileUpload} id="fileWidgetFile" accept={metadata.accept}>
              {metadata.buttonTitle}
            </FileButton>
          )}
        </Styled.SDropdownContentWrapper>
      ),
    });
  }
  tabs.push({
    title: 'Embed link',
    content: (
      <Styled.SDropdownContentWrapper>
        <Input autoFocus className={styles.embdedLink} placeholder="Paste link" onChange={handleChangeEmbedLink} value={embedValue} />
        <BasicButtonV1 onClick={handleEmbedSubmit} disabled={Boolean(embedError) || !embedValue}>
          {type === ESlideWidgetTypes.IMAGE ? 'Upload file' : 'Embed video'}
        </BasicButtonV1>
      </Styled.SDropdownContentWrapper>
    ),
  });

  const { width = 40, align = 'flex-start' } = content as TFileMetadata;

  if (type === ESlideWidgetTypes.IMAGE) {
    return (
      <Fragment>
        {file ? (
          <ResizableImage
            width={Number(width)}
            justifyContent={align ?? 'flex-start'}
            src={file}
            alt={'preview'}
            tabs={tabs}
            onDelete={() => onDelete(id)}
            onChange={handleUpdateImage}
          />
        ) : (
          <Styled.SRoot>
            <DropdownMotion
              triggerNode={
                <Styled.SContent>
                  <Styled.SImageContainer>{file ? <img src={file} alt="preview" /> : metadata.icon}</Styled.SImageContainer>
                  <span>{(typeof content === 'string' ? content : content.name) || metadata.title}</span>
                </Styled.SContent>
              }
              variant={'secondary'}
            >
              <Tabs tabs={tabs} />
            </DropdownMotion>
          </Styled.SRoot>
        )}
      </Fragment>
    );
  }

  return (
    <Styled.SRoot>
      <DropdownMotion
        triggerNode={
          <Styled.SContent>
            <Styled.SImageContainer>{file ? <UploadIcon /> : metadata.icon}</Styled.SImageContainer>
            <span>{(typeof content === 'string' ? content : content.name) || metadata.title}</span>
          </Styled.SContent>
        }
        variant={'secondary'}
      >
        <Tabs tabs={tabs} />
      </DropdownMotion>
    </Styled.SRoot>
  );
};

export default FileWidget;
