import { NavBarUnAuth } from './navbar-unauth';
import { NavbarAuth } from './navbar-auth';
import { Logo } from 'shared/utils';
import { NavWrapper } from './styles';
import { useGetIsAuth, useGetUserData } from 'hooks';
import { useSelector } from 'react-redux';
import { getPartnerId } from 'store';

export function Navbar() {
  const isAuth = useGetIsAuth();
  const partnerId = useSelector(getPartnerId);
  const user = useGetUserData();
  const isUserReady = Boolean(user?.exp) && isAuth;

  if (partnerId) {
    return (
      <NavWrapper className={'navbar'} style={{ textAlign: 'center', margin: '15px auto', zIndex: 0, position: 'relative', height: 0 }}>
        <Logo withLabel height={38} size="xsmall" />
      </NavWrapper>
    );
  }
  if (isUserReady) {
    return <NavbarAuth />;
  }
  return <NavBarUnAuth />;
}
