import { useState } from 'react';
import { usePreviewContext } from '../../../utils/previewContext';
import { useMaterialPageContext } from '../../../materialPageContext';
import { useGetCurrentTheme, useGetIsAuth } from 'hooks';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';

import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import { MightyButton } from './styles';
import './style.css';
import styles from './styles.module.css';

const CheckAnswerButton = ({ userAnswers, isActive = false, className = '', updateRight, right }) => {
  const theme = useGetCurrentTheme();
  const isAuth = useGetIsAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { navForward, progressCourse, isInProgress, isCompleting } = useMaterialPageContext();
  const isPreview = usePreviewContext();

  const handleSubmit = async () => {
    if (!isAuth) {
      navigate(ROUTES.AUTH);
    }
    if (right) {
      updateRight(null);
      return navForward();
    }
    setIsLoading(true);
    progressCourse(userAnswers).finally(() => setIsLoading(false));
  };
  const isLoadingOrCompleting = (isCompleting || isLoading) && isAuth;

  return (
    <MightyButton disabled={isLoadingOrCompleting || isPreview} className={`${isActive && 'active'} ${className} ${theme}`} onClick={handleSubmit}>
      {isLoadingOrCompleting ? <MightyLoaderMini className={styles.loader} /> : right === false ? 'Try again' : 'Answer'}
    </MightyButton>
  );
};

export default CheckAnswerButton;
