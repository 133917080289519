import { useState } from 'react';
import { setIsMainMenu } from 'store';
import { dispatch, useGetCurrentTheme } from 'hooks';
import { EMenuScreens } from 'app/constants';
import clsx from 'clsx';

import { ReactComponent as User } from 'assets/icons/user.svg';
import { ReactComponent as Settings } from 'assets/icons/settings.svg';
import { ReactComponent as Wallet } from 'assets/icons/wallet.svg';

import styles from './styles.module.css';

export const blankEffects = [
  {
    itemId: 2,
    expiresAt: Date.now() + 60 * 60 * 24 * Math.floor(Math.random() * 9),
  },
  {
    itemId: 3,
    expiresAt: Date.now() + 60 * 60 * 24 * Math.floor(Math.random() * 9),
  },
  {
    itemId: 4,
    expiresAt: Date.now() + 60 * 60 * 24 * Math.floor(Math.random() * 9),
  },
  {
    itemId: 5,
    expiresAt: Date.now() + 60 * 60 * 24 * Math.floor(Math.random() * 9),
  },
  {
    itemId: 6,
    expiresAt: Date.now() + 60 * 60 * 24 * Math.floor(Math.random() * 9),
  },
];

const ShowThings = ({ className = '' }) => {
  const [showEffects] = useState(false);
  const theme = useGetCurrentTheme();

  function handleMainMenu({ screen }: { screen: EMenuScreens }) {
    dispatch(setIsMainMenu({ isMainMenu: true, screen }));
  }

  return (
    <div className={clsx(styles.container, { [className]: !!className })}>
      {/* 
      TODO bring it back shen it's functional
      <div
        style={{ 
          width: showEffects ? `${blankEffects.length * 53 + ((blankEffects.length - 1) * 10)}px` : 0
        }} 
        className={`effects-container ${ showEffects ? 'open' : 'close' }`}>
          {blankEffects.map(({ itemId, expiresAt }, index) =>
            <EffectsWidget
              key={"effects-container__effect-" + index} 
              itemId={itemId} 
              timer={expiresAt} />
          )}
      </div> */}
      <div className={clsx(styles.buttons, { [styles.expanded]: showEffects })}>
        <button className={`gray-button ${theme} ${styles.button}`} onClick={() => handleMainMenu({ screen: EMenuScreens.PROFILE })}>
          <User />
          Profile
        </button>
        <button className={`gray-button ${theme} ${styles.button}`} onClick={() => handleMainMenu({ screen: EMenuScreens.SETTINGS })}>
          <Settings />
          Settings
        </button>
        <button className={`gray-button ${theme} ${styles.button}`} onClick={() => handleMainMenu({ screen: EMenuScreens.INVENTORY })}>
          <Wallet style={{ marginLeft: '5px' }} width={20} />
          Inventory
        </button>
        {/* <button className={`gray-button ${theme} ${styles.button}`} onClick={() => setShowEffects(!showEffects)}>
          {showEffects ? <HiArrowLeft style={{ marginLeft: '3px' }}/> : <EffectsPic/>}
          {t(`profile.${!showEffects ? 'showEffects' : 'hideEffects'}`)}
        </button>
        <button className={`gray-button ${theme} ${styles.button} ${showEffects && 'squashed'}`}>
          <StatsPic/>
          {t("profile.showStats")}
        </button> */}
      </div>
    </div>
  );
};

export default ShowThings;
