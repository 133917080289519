import { ESlideTypes } from 'store';

export const createEmptySlide = (lessonId: string, typeOfSlide: ESlideTypes, name: string) => ({
  name: name,
  typeOfSlide: typeOfSlide,
  slideType: {
    name: typeOfSlide,
  },
  content: [],
  rewards: [],
  rewardExp: 0,
  hint: 'some hint',
  lessonId,
});
