import { STATIC_HOST_PATH } from 'app/constants/path';
import { TFileMetadata } from '../components/types';
import { parseIfJson } from 'utils/object';

export const buildStaticUrl = (content: string | TFileMetadata) => {
  let parsedContent = parseIfJson(content) ?? content;

  if (typeof parsedContent === 'string') {
    return parsedContent;
  }

  return `${STATIC_HOST_PATH}/${parsedContent.filePath}`;
};
