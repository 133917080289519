import { useEffect, useRef } from 'react';

import * as Styled from './styles';
import { useSelector } from 'react-redux';
import { getAppSettings } from 'store';

type Props = {};

export const Banner = (props: Props) => {
  const { displayedName, logos } = useSelector(getAppSettings);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (ref.current) {
      setTimeout(() => {
        ref?.current?.scrollIntoView({ behavior: 'smooth' });
      }, 300);
    }
  }, [ref]);

  return (
    <Styled.Container ref={ref}>
      <Styled.Header>
        <Styled.Span>Welcome</Styled.Span>
        <Styled.Span>
          to <Styled.Img src={logos.xsmall} alt={displayedName} /> {displayedName}
        </Styled.Span>
      </Styled.Header>
      <Styled.QuoteContainer>
        <Styled.Quote>Learn</Styled.Quote>
        <Styled.Quote>Play</Styled.Quote>
        <Styled.Quote>Win rewards</Styled.Quote>
      </Styled.QuoteContainer>
    </Styled.Container>
  );
};
