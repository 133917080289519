import { useEffect, useRef, useState } from 'react';
import CountUp from 'react-countup';
import { useSelector } from 'react-redux';
import { getAppSettings } from 'store';
import { motion, useAnimationControls } from 'framer-motion';
import { useGetUserData, useUserEnergyLevel } from 'hooks';

import coin from 'components/library/images/ShopInventory/exp_token_200x200.png';
import { ReactComponent as Lightning } from 'assets/icons/lightning.svg';
import { LazyImage } from '../../../../shared/ui';

import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { Tooltip } from '../../../../shared/ui';

import * as Styled from './styles';
import styles from './styles.module.css';

const variants = {
  initial: {
    opacity: 0,
    x: 100,
  },
  animate: {
    opacity: 1,
    x: 0,
  },
  exit: {
    opacity: 0,
    x: -100,
  },
};

type Props = {
  className?: string;
  isExpPic?: boolean;
  isAvatar?: boolean;
};

export default function MiniUser({ isExpPic = false, isAvatar = false, className = '' }: Props) {
  const {
    avatar,
    nickname,
    customization,
    exp: {
      balance: { amount },
    },
  } = useGetUserData();
  const { displayedName } = useSelector(getAppSettings);
  const [exp, setExp] = useState(amount);
  const { energyPersentage } = useUserEnergyLevel();
  const prevCountRef = useRef<number>(0);
  const $containerRef = useRef<HTMLDivElement>(null);
  const controls = useAnimationControls();

  useEffect(() => {
    prevCountRef.current = exp;
    if (prevCountRef.current !== amount) {
      controls.start(variants.animate);
    }
    setTimeout(() => {
      controls.start(variants.exit).then(() => {
        controls.set(variants.initial);
        setExp(amount);
      });
    }, 3000);
  }, [amount]);

  const countCoins = amount - exp;

  return (
    <>
      <Styled.Container className={className}>
        <Styled.Data ref={$containerRef}>
          <Styled.Info>
            {isExpPic && <LazyImage src={coin} alt="coin" className={'exp-pic'} />}
            <CountUp style={{ color: 'var(--font-color-strong)' }} useEasing={true} duration={3} start={exp} end={amount} />
            <LazyImage src={coin} alt="coin" width={30} height={30} />
          </Styled.Info>
          <Styled.EnergySpan>
            <Lightning /> <span>{energyPersentage}%</span>
          </Styled.EnergySpan>
          <Styled.CoinNotice as={motion.div} variants={variants} initial={'initial'} animate={controls} transition={{ duration: 0.3 }}>
            <div>
              {countCoins > 0 && '+'}
              {countCoins}
            </div>
            <LazyImage src={coin} alt="coin" width={27} />
          </Styled.CoinNotice>
        </Styled.Data>
        {isAvatar && <AvatarPic smallGlow className="avatar" avatar={avatar} nickname={nickname} customization={customization} />}
      </Styled.Container>
      <Tooltip triggerRef={$containerRef} className={styles.tooltipContainer} margin={28} isRight>
        <LazyImage src={coin} alt="coin" width={80} />
        <Styled.TTitle>You have {amount} EXP</Styled.TTitle>
        <Styled.TText $opacity={0.45} $margin={'0 0 14px 0'} $lineHeight={'100%'}>
          EXP Points are your personal points and can’t be sold / transferred
        </Styled.TText>
        <Styled.TText $margin={'0 0 45px 0'} $maxW={'260px'}>
          Points allow you to buy rewards in {displayedName} Store and much more!
        </Styled.TText>
        <Styled.TText $fontSize={'12px'} $lineHeight={'140%'} $opacity={0.45}>
          See full terms & conditions on <span>app.mighty.study/terms</span>
        </Styled.TText>
      </Tooltip>
    </>
  );
}
