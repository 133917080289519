import { useState } from 'react';

import CheckAnswerButton from '../utils/CheckAnswerButton/CheckAnswerButton';

import styles from './styles.module.css';
import TextContent from '../../SlideContent/components/Text';
import { NodesView } from '../Fillspaces/NodesView';
import { useGetCustomNav } from '../utils/useGetCustomNav';

const FillspacesCode = ({ slideId, nodes, right, updateRight, typeOfSlide = '', elementWithCondition }) => {
  const [userInput, setUserInput] = useState([]);

  const handleAnswerChange = (idx, value) => {
    updateRight(null);
    setUserInput((prev) => {
      const arrClone = structuredClone(prev);
      arrClone[idx] = value;
      return arrClone;
    });
  };

  const buttomElement = useGetCustomNav(
    <CheckAnswerButton
      className="lesson-button"
      isActive={Boolean(userInput[0])}
      userAnswers={userInput}
      slideId={slideId}
      right={right}
      updateRight={updateRight}
    />,
    elementWithCondition
  );

  return (
    <>
      <div className={styles.root}>
        <div className="lesson-content-space">
          <TextContent
            type={typeOfSlide}
            content={<NodesView rootNode={nodes} onAnswerChange={handleAnswerChange} right={right} updateRight={updateRight} />}
          />
        </div>
      </div>
      {buttomElement}
    </>
  );
};

export default FillspacesCode;
