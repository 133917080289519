import { useEffect, useRef } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { setPitch, setRate, setText, setVoice, setVolume, getTextToSpeech, setIsPaused, setIsPlaying } from 'store';
import { dispatch } from 'hooks';

export const useTextToSpeech = () => {
  const textToSpeech = useSelector(getTextToSpeech, shallowEqual);
  const { text, isPlaying, rate, volume, pitch, voice, isPaused } = textToSpeech;

  const utteranceRef = useRef<SpeechSynthesisUtterance | null>(null);

  useEffect(() => {
    if (text) {
      handleTextChange(text);
    }
    return () => {
      handleTextChange('');
    };
  }, [text]);

  useEffect(() => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(text);
    utteranceRef.current = utterance;
    utterance.addEventListener('end', handleStop);

    const voices = synth.getVoices();
    let initialVoice = voices.find((v) => v.lang === 'en-US');
    if (!initialVoice) {
      initialVoice = voices.find((v) => v.name === 'Microsoft Pavel - Russian (Russia)');
    }

    dispatch(setVoice(initialVoice?.name ? initialVoice?.name : voices[1]?.name));

    return () => {
      synth.cancel();
      utterance.removeEventListener('end', handleStop);
    };
  }, [text]);

  useEffect(() => {
    const setupUtterance = () => {
      if (utteranceRef.current) {
        const synth = window.speechSynthesis;
        const voices = synth.getVoices();
        const voiceCur = voices.find((v) => v.name === voice);
        if (voiceCur) {
          utteranceRef.current.voice = voiceCur;
        }
        utteranceRef.current.pitch = pitch;
        utteranceRef.current.rate = rate;
        utteranceRef.current.volume = volume;
      }
    };

    if (isPlaying) {
      handleStop();
      setupUtterance();
      handlePlay();
    } else {
      setupUtterance();
    }
  }, [voice, pitch, rate, volume, isPlaying]);

  const handlePlay = () => {
    dispatch(setIsPlaying(true));
    const synth = window.speechSynthesis;

    if (isPaused) {
      synth.resume();
    } else if (utteranceRef.current) {
      synth.speak(utteranceRef.current);
    }

    dispatch(setIsPaused(false));
  };

  const handlePause = () => {
    const synth = window.speechSynthesis;

    synth.pause();

    dispatch(setIsPlaying(false));
    dispatch(setIsPaused(true));
  };

  const handleStop = () => {
    const synth = window.speechSynthesis;

    synth.cancel();

    dispatch(setIsPaused(false));
    dispatch(setIsPlaying(false));
  };

  const handleVoiceChange = (name: any) => {
    dispatch(setVoice(name));
  };

  const handleTextChange = (value: string) => {
    dispatch(setText(value));
  };

  const handlePitchChange = (value: number) => {
    dispatch(setPitch(value));
  };

  const handleRateChange = (value: number) => {
    dispatch(setRate(value));
  };

  const handleVolumeChange = (value: number) => {
    dispatch(setVolume(value));
  };
  const onClick = () => (isPlaying ? handlePause() : handlePlay());

  return {
    settings: {
      voice,
      pitch,
      volume,
      rate,
    },
    isPlaying,
    handlePitchChange,
    handlePause,
    handleStop,
    onClick,
    handleVoiceChange,
    handleRateChange,
    handleVolumeChange,
    handleTextChange,
  };
};
