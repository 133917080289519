import styled, { css } from 'styled-components';
import { GRADES } from 'app/constants';
import { EEffectsListItemBadgeType } from '../types';

export const Container = styled.div(
    () => css`
      padding: 6px;
      height: 26px;
      width: fit-content;
      display: flex;
      align-items: center;
      border-radius: 4px;
      background: red;

      &.${EEffectsListItemBadgeType.ENERGY}, &.${EEffectsListItemBadgeType.ENERGY_CAP}, &.${EEffectsListItemBadgeType.ENERGY_RECOVERY} {
        background: rgba(41, 171, 136, 0.15);
      }
      &.${EEffectsListItemBadgeType.EXP} {
        background: rgba(170, 170, 170, 0.15);
      }
      &.${EEffectsListItemBadgeType.TIMER} {
        &.${GRADES.LEGENDARY} {
          background: rgba(255, 191, 102, 0.15);
        }
        &.${GRADES.EPIC} {
          background: rgba(202, 71, 206, 0.15);
        }
        &.${GRADES.RARE} {
          background: rgba(149, 216, 245, 0.15);
        }
        &.${GRADES.UNCOMMON} {
          background: rgba(41, 171, 136, 0.15);
        }
        &.${GRADES.COMMON} {
          background: rgba(170, 170, 170, 0.15);
        }
      }
    `
  ),
  Icon = styled.div(
    () => css`
      height: 100%;
      margin-right: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 12px;
      height: 12px;

      svg {
        max-height: 100%;
        object-fit: contain;
        color: var(--font-color-strong);
      }
    `
  ),
  Image = styled.img(
    () => css`
      max-height: 100%;
      height: 100%;
      object-fit: contain;

      &.${EEffectsListItemBadgeType.EXP} {
        height: 150%;
        max-height: 150%;
      }
    `
  ),
  Label = styled.p(
    () => css`
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 1;
      letter-spacing: 0.5px;
      color: var(--font-color-strong);
      white-space: pre;
    `
  );
