import { useRef } from 'react';
import { ESlideWidgetTypes } from 'store';
import { ReactComponent as ConsoleIcon } from 'assets/icons/consoleIcon.svg';
import * as Styled from './styles';
import { IWebLanguage } from 'components/library/courseConstructor/components/Widget/components/CodeWidget/CodeWidgetWeb/model';
import { PlusIcon, TrashIcon } from 'assets/icons/icons';
import { DropdownMotion } from 'shared/ui';

interface Props {
  type: ESlideWidgetTypes;
  content: any;
  comment?: any;
  onClick?: (tab: IWebLanguage) => void;
}

interface CodeHeaderTabs {
  tabs: IWebLanguage[];
  activeTab?: string;
  withConstructor?: boolean;
  onChange?: (newTabs: IWebLanguage[]) => void;
  onClick?: (tab: IWebLanguage) => void;
}

export function CodeHeader({ tabs = [], activeTab, withConstructor, onClick, onChange }: CodeHeaderTabs) {
  const listTabs: IWebLanguage[] = ['html', 'css', 'js', 'python'];

  const handleAddTabs = (newTab: IWebLanguage) => {
    if (onChange) {
      return onChange([...tabs, newTab]);
    }
  };

  const handleRemoveTabs = (removedTab: IWebLanguage) => {
    if (onChange) {
      return onChange(tabs.filter((tab) => tab !== removedTab));
    }
  };

  const currentListDropDown = () => {
    return listTabs
      .filter((lTab) => !tabs.includes(lTab))
      .map((lTab) => ({
        title: lTab,
        onClick: () => handleAddTabs(lTab),
      }));
  };

  if (tabs.length) {
    return (
      <Styled.CodeHeader>
        {tabs.map((tab: IWebLanguage) => (
          <div
            key={tab}
            style={{ cursor: 'pointer' }}
            className={activeTab === tab ? 'consoleHeaderTab__active' : ''}
            onClick={onClick ? () => onClick(tab) : () => null}
          >
            <span>
              <ConsoleIcon /> {tab}
            </span>
            {withConstructor && (
              <Styled.IconButton onClick={() => handleRemoveTabs(tab)}>
                <TrashIcon />
              </Styled.IconButton>
            )}
          </div>
        ))}
        {withConstructor && !!currentListDropDown().length && (
          <DropdownMotion
            triggerNode={
              <Styled.IconButton>
                <PlusIcon />
              </Styled.IconButton>
            }
            items={currentListDropDown()}
          />
        )}
      </Styled.CodeHeader>
    );
  }

  return (
    <Styled.CodeHeader>
      <div className={'consoleHeaderTab__active'}>
        <span>
          <ConsoleIcon /> code editor
        </span>
      </div>
      {withConstructor && (
        <DropdownMotion
          triggerNode={
            <Styled.IconButton>
              <PlusIcon />
            </Styled.IconButton>
          }
          items={currentListDropDown()}
        />
      )}
    </Styled.CodeHeader>
  );
}

export default function TextContent({ type, content, comment, onClick }: Props) {
  const $trigger = useRef(null);

  return (
    <Styled.TextRoot>
      <CodeHeader tabs={content?.tabs ?? []} activeTab={content?.activeTab} onClick={(tab: string) => onClick} />
      <Styled.TypeText className={`${type.toLowerCase()} code tiny-sb`}>
        <pre ref={$trigger}>{content}</pre>
        {comment && comment($trigger)}
      </Styled.TypeText>
    </Styled.TextRoot>
  );
}
