import { FC } from 'react';
import Text from '@tiptap/extension-text';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import Link from '@tiptap/extension-link';

import { TTextWidgetProps } from './types';

import TiptapEditor from 'components/library/tiptapEditor';
import Bubble from './components/Bubble';
import * as Elements from './styles';

const TextWidget: FC<TTextWidgetProps> = ({ content, onChange }) => {
  return (
    <Elements.SRoot className={'root-transition'}>
      <TiptapEditor
        extensions={[StarterKit, Text, TextStyle, Color, Underline, Link.configure({ openOnClick: true })]}
        bubble={(editor) => <Bubble editor={editor} />}
        content={content}
        onChange={onChange}
        placeholder="Type text"
      />
    </Elements.SRoot>
  );
};

export default TextWidget;
