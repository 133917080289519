import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGlobalLoader } from 'shared/utils/GlobalLoader/useGlobalLoader';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useFetchSearchItems } from 'hooks/useFetchSearchItems';

import { MdClose } from 'react-icons/md';
import BasicButton from 'shared/ui/basic-button/button';
import SearchResults from './SearchResults';
import LogoLoader from 'shared/ui/loader/MightyLoader';

import '../styles/search-more.css';
import styles from './styles.module.css';

export default function SearchMore() {
  const { state } = useLocation();
  const [input, setInput] = useState(state ? state.input : '');
  const [items, setItems] = useState([]);
  const [filters, setFilters] = useState([]);
  const navigate = useNavigate();
  const { fetch: fetchSearch, isLoading } = useFetchSearchItems();
  const theme = useSelector(getCurrentTheme);

  let givenInput = state ? state.input : '';
  let groupName = '';

  if (state) {
    groupName = state.groupName;
  }

  useEffect(() => {
    if (input !== givenInput) setInput(givenInput);
  }, [givenInput]);

  useEffect(() => {
    let ref = document.getElementById(groupName);
    if (ref) scrollToTarget(ref);
  }, [groupName, items]);

  useEffect(() => {
    if (!input && filters.length < 1) return setItems([]);
    async function get() {
      let searchString = input.length > 0 ? input : 'fillerString';
      if (filters.length > 0) {
        searchString += `?${filters.map((i) => `${i}=true`).join('&')}`;
      }
      const result = await fetchSearch(searchString);
      if (result) {
        setItems(result);
      }
    }

    const timeout = setTimeout(() => {
      get();
    }, 750);

    return () => clearTimeout(timeout);
  }, [filters, input]);

  function scrollToTarget(ref) {
    setTimeout(() => {
      ref.scrollIntoView({ behavior: 'smooth' });
    }, 500);
  }

  function handleInputDrop() {
    givenInput = '';
    setInput('');
  }

  function clearItems() {
    handleInputDrop();
    setFilters([]);
    setItems([]);
  }

  useGlobalLoader({ dependency: [false] });

  return (
    <div className="search-more">
      <div className="search-more__body">
        {(input || filters.length > 0) && (
          <div className="input-sample__wrap">
            {input && (
              <div role="button" onClick={handleInputDrop} className={`glass-div basic ${theme} input-sample`}>
                <p>{input}</p>
                <MdClose />
              </div>
            )}
            {(input || filters.length > 0) && (
              <button onClick={clearItems}>
                <p>Clear all</p>
              </button>
            )}
          </div>
        )}
        {isLoading ? (
          <LogoLoader />
        ) : items.length > 0 ? (
          <SearchResults items={items} />
        ) : input || filters.length > 0 ? (
          <div className="no-items">
            <p>No items founds for this search</p>
            <BasicButton variant="cool" className={styles.button} label="Back to Home" onClick={() => navigate('/')} />
          </div>
        ) : (
          <h3>Search page</h3>
        )}
      </div>
      {/* TODO add grade filtering */}
      {/* <SearchFilter loading={isLoading} filters={filters} handleFilter={handleFilter} /> */}
    </div>
  );
}
