import imageCompression from 'browser-image-compression';

export default async function uploadImage(e) {
  e.persist();

  if (!e.target || !e.target.files) {
    return;
  }

  try {
    const file = await compressImage(e);
    const fileContents = await handleImageUpload(file);
    // console.log(fileContents);

    return fileContents;
  } catch (e) {
    console.log(e);
  }

  function handleImageUpload(file) {
    let reader = new FileReader();

    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new DOMException('Problem parsing input file.'));
      };

      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(file);
    });
  }

  async function compressImage(event) {
    const imageFile = event.target.files[0];
    // console.log('originalFile instanceof Blob', imageFile instanceof Blob); // true
    // console.log(`originalFile size ${imageFile.size / 1024 / 1024} MB`);

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      // console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      // console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB

      // await uploadToServer(compressedFile); // write your own logic
      return compressedFile;
    } catch (error) {
      console.log(error);
    }
  }
}
