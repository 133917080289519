import { dispatch, useGetCurrentTheme } from 'hooks';
import { useSelector } from 'react-redux';
import { getMainMenuScreen, setIsMainMenu } from 'store';

import CenteredModal from '../../library/utils/CenteredModal/CenteredModal';
import MenuNav from './MenuNav';
import Inventory from './screens/Inventory/Inventory';
import Profile from './screens/profile/profile';
import Settings from './screens/Settings';
import Design from './screens/Design/Design';
// import Effects from "./screens/Effects";
// import Stats from "./screens/Stats";

import ProfilePic from './assets/Profile-pic.png';

import './styles/menu.scss';
import Spaces from './screens/Spaces';

const Menu = ({ showMenu, setShowMenu }) => {
  const theme = useGetCurrentTheme();
  const mainScreen = useSelector(getMainMenuScreen);
  const screens = [
    { name: 'Inventory', screen: <Inventory profileIcon={ProfilePic} /> },
    { name: 'Profile', screen: <Profile pic={ProfilePic} /> },
    // { name: "Effects", screen: <Effects pic={ProfilePic} /> },
    // { name: "Stats", screen: <Stats pic={ProfilePic} /> },
    {
      name: 'Settings',
      screen: <Settings />,
    },
    {
      name: 'Design',
      screen: <Design />,
    },
    {
      name: 'Spaces',
      screen: <Spaces setShowMenu={setShowMenu} />,
    },
  ];

  const setActiveMenu = ({ screen }) => {
    dispatch(setIsMainMenu({ isMainMenu: true, screen }));
  };

  return (
    <CenteredModal classNameModal="menu-modal" show={showMenu} isClosing={false} blur={4}>
      <div className={`menu-wrap`}>
        <div className="menu-fixed">
          <MenuNav active={mainScreen} onActiveMenu={setActiveMenu} setShowMenu={setShowMenu} />
        </div>

        {screens
          .filter((i) => i.name === mainScreen)
          .map((i, index) => (
            <div className={`menu-main-wrap ${theme} container`} key={'menu-main-wrap__screen-' + index}>
              {i.screen}
            </div>
          ))}
      </div>
    </CenteredModal>
  );
};

export default Menu;
