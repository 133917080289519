import styled from 'styled-components';
import bgImage from '../../assets/bg.webp';
import { ReactComponent as OpenIcon } from '../../assets/open.svg';
import { motion } from 'framer-motion';
import { THEMES } from 'app/constants';
import verifiedIcon from '../../assets/verified.svg';

export const SpaceContainer = styled.div`
  margin-bottom: 51px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 366px;
  border-radius: 10px;
  width: 100%;
  @media screen and (max-width: 992px) {
    min-height: auto;
    margin-bottom: 30px;
    height: auto;
    background: none;
  }
`;

export const SpaceBG = styled.div<{ $bg?: string }>`
  position: absolute;
  width: 100%;
  height: 50%;
  top: 0;
  border-radius: 10px;
  left: 0;
  background-image: url(${(p) => p.$bg || bgImage});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const Tag = styled.div<{ $theme: THEMES; $lightBg: string }>`
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0px 10px;
  border-radius: 4px;
  height: 28px;
  background: ${(p) => (p.$theme === THEMES.DARK ? 'rgba(255, 255, 255, 0.1)' : p.$lightBg || 'rgba(17, 17, 17, 0.2)')};
  backdrop-filter: blur(20px);
`;

export const TagText = styled.p`
  color: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  max-width: 250px;
  text-transform: uppercase;
  @media screen and (max-width: 992px) {
    max-width: 150px;
  }
`;

export const Tags = styled.div<{ $color: string }>`
  display: flex;
  gap: 10px;
  position: absolute;
  top: 19px;
  left: 19px;
  @media screen and (max-width: 992px) {
    position: static;
    width: 50%;
    margin-left: auto;
    margin-bottom: 16px;
    margin-top: -16px;
    padding-left: 15px;
    flex-wrap: wrap;
  }
  & > ${Tag} {
    color: ${(p) => p.$color};
  }
`;

const bgs = {
  [THEMES.LIGHT]: '#11111133',
  [THEMES.DARK]: '#FFFFFF1A',
};

export const SpaceInfo = styled.div<{ $color?: string; $theme: THEMES; $isLoading?: boolean }>`
  width: 100%;
  min-height: 198px;
  height: fit-content;
  backdrop-filter: blur(47.3408px);
  background: ${(p) => (p.$isLoading ? 'rgba(100, 135, 239, 0.2)' : p.$color ? p.$color : bgs[p.$theme])};
  border-radius: 10px;
  margin-top: auto;
  position: relative;
  z-index: 3;
  @media screen and (max-width: 992px) {
    min-height: auto;
  }
`;

export const SpaceInfoTop = styled.div`
  width: 100%;
  padding: 25px;
  border-bottom: 1px solid #ffffff1a;
  display: grid;
  grid-template-columns: 178px 362px auto;
  gap: 14px;
  height: 102px;
  align-items: center;
  @media screen and (max-width: 992px) {
    height: auto;
    display: grid;
    grid-template-columns: min-content min-content;
  }
`;

export const SpaceInfoTopMobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
  padding: 15px 19px 31px;
`;

export const Col = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 14px;
`;

export const SpaceEditBox = styled(motion.div)`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 14px;
  margin-left: auto;
`;

export const SpaceInfoBottom = styled.div`
  display: grid;
  grid-template-columns: 540px auto;
  align-items: center;
  padding: 25px;
  height: 100%;
  max-height: 50%;
  gap: 20px;
  @media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px 30px;
    height: fit-content;
    max-height: auto;
  }
`;

export const ImageBox = styled.div<{ $color?: string; $isLoading?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 178px;
  height: 178px;
  position: relative;
  margin-top: calc(-25px - 89px);
  z-index: 1;
  &::after {
    z-index: -2;
    border-radius: 300px 300px 0 0;
    backdrop-filter: blur(47.3408px);
    background: ${(p) => (p.$isLoading ? 'rgba(100, 135, 239, 0.2)' : p.$color ? p.$color : 'rgba(255,255,255,0.05)')};
    display: block;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 89px;
    position: absolute;
  }
  @media screen and (max-width: 992px) {
    width: 139px;
    min-width: 139px;
    height: 139px;
    margin-top: calc(-12px - 70px);

    &::after {
      height: 67px;
    }
  }
`;
export const Image = styled.img<{ $dropShadow?: boolean }>`
  width: 164px;
  height: 164px;
  border-radius: 50%;
  object-fit: cover;
  ${(p) => p.$dropShadow && 'box-shadow: 0px 10px 30px 0px #11111130;'}
  @media screen and (max-width: 992px) {
    width: 128px;
    height: 128px;
  }
`;
export const Name = styled.span<{ $isVerified?: boolean; $color: string }>`
  font-size: 30px;
  line-height: 100%;
  color: ${(p) => p.$color};
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: auto;
  position: relative;
  & > span {
    color: inherit;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 110%;
  }
  @media screen and (max-width: 992px) {
    /* height: 60px; */
    width: 100%;
    flex-direction: row;
    word-break: normal;
    font-size: 24px;
    align-items: flex-start;
    /* overflow: hidden; */
    & > span {
      -webkit-line-clamp: 2;
      /* autoprefixer: ignore next */
      -webkit-box-orient: vertical;
      white-space: normal;
      display: -webkit-box;
      overflow: hidden;
      word-break: break-word;
    }
  }
  ${(p) =>
    p.$isVerified &&
    `
    &::after {
      display: block;
      width: 25px;
      min-width: 25px;
      height: 25px;
      position: absolute;
      top: 30%;
      transform: translateY(-50%);
      right: -30px;
      content: '';
      background-image: url(${verifiedIcon});
      background-repeat: no-repeat;
      background-size: contain;
      @media screen and (max-width: 992px) {
        position: static;
        transform: none;
      }
    }
  `}
`;

export const Link = styled.a`
  color: #627eea;
  font-size: 16px;
  line-height: 100%;
  font-weight: 400;
  text-decoration: none;
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const InfoContainer = styled.div`
  display: flex;
  /* margin-right: auto; */
  align-items: center;
  gap: 29px;
  @media screen and (min-width: 993px) {
    margin-left: auto;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const InfoNum = styled.p<{ $color: string }>`
  color: ${(p) => p.$color};
  font-size: 30px;
  line-height: 30px;
`;
export const InfoTopic = styled.p<{ $color: string }>`
  color: ${(p) => p.$color};
  opacity: 0.4;
  font-size: 16px;
  line-height: 16px;
`;

export const Description = styled.p<{ $color: string }>`
  font-size: 16px;
  line-height: 22.08px;
  font-weight: 400;
  color: ${(p) => p.$color};
  overflow: hidden;
`;

export const EditButton = styled.button<{ $color: string }>`
  border: none;
  outline: none;
  height: 56px;
  background: #ffffff1a;
  color: ${(p) => p.$color};
  padding: 0px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 31px;
  box-shadow: 34px 21px 16px rgba(48, 49, 61, 0.01), 9px 5px 10px rgba(48, 49, 61, 0.01);
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

export const AdminButton = styled.button<{ $color: string }>`
  border: none;
  outline: none;
  height: 44px;
  background: #ffffff1a;
  color: ${(p) => p.$color};
  padding: 0px 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 6px;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: 0.85;
  }
`;

export const OpenLinkStyled = styled.div<{ $color: string }>`
  background: #ffffff1a;
  border-radius: 50%;
  height: 56px;
  width: 56px;
  color: ${(p) => p.$color};
  display: flex;
  justify-content: center;
  align-items: center;
  & > svg {
    width: 23px;
    height: 23px;
  }
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    opacity: 0.85;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

type OpenLinkProps = {
  $color: string;
  onClick: () => any;
};

export const OpenLink = ({ $color, onClick }: OpenLinkProps) => {
  return (
    <OpenLinkStyled $color={$color} onClick={onClick}>
      <OpenIcon />
    </OpenLinkStyled>
  );
};

export const BottomEditBox = styled.div`
  width: fit-content;
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 22px;
`;
