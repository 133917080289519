import exp from 'components/library/images/ShopInventory/exp_token_200x200.png';
import timeStone from 'components/library/images/ShopInventory/item_time_stone.png';
import expPotion from 'components/library/images/ShopInventory/item_exp_potion.png';
import amethyst from 'components/library/images/ShopInventory/item_amethyst.png';
import emperorKey from 'components/library/images/ShopInventory/item_emperor_key.png';
import bloodTalisman from 'components/library/images/ShopInventory/item_blood_talisman.png';
// large images
import expTokenLarge from 'components/library/images/itemPage/exp_token_lg.png';
import timeStoneLarge from 'components/library/images/itemPage/time_stone_lg.png';
import expPotionLarge from 'components/library/images/itemPage/exp_potion_lg.png';
import amethystLarge from 'components/library/images/itemPage/amethyst_lg.png';
import emperorKeyLarge from 'components/library/images/itemPage/emperor_key_lg.png';
import bloodTalismanLarge from 'components/library/images/itemPage/blood_talisman_lg.png';
// import purple from "components/library/cards/shop/assets/purple.png";
import { ReactComponent as Common } from 'components/library/cards/courses/assets/course_card_common.svg';
import { ReactComponent as Uncommon } from 'components/library/cards/courses/assets/course_card_uncommon.svg';
import { ReactComponent as Rare } from 'components/library/cards/courses/assets/course_card_rare.svg';
import { ReactComponent as Epic } from 'components/library/cards/courses/assets/course_card_epic.svg';
import { ReactComponent as Legendary } from 'components/library/cards/courses/assets/course_card_legendary.svg';
import { ReactComponent as Devine } from 'components/library/cards/courses/assets/course_card_devine.svg';
import { ReactComponent as Mythical } from 'components/library/cards/courses/assets/course_card_mythical.svg';

// import { ReactComponent as InventoryNoGrade } from "components/library/cards/inventory/assets/inventory_xs_nograde.svg";
import { ReactComponent as InventoryCommon } from 'components/library/cards/inventory/assets/inventory_xs_common.svg';
import { ReactComponent as InventoryUncommon } from 'components/library/cards/inventory/assets/inventory_xs_uncommon.svg';
import { ReactComponent as InventoryRare } from 'components/library/cards/inventory/assets/inventory_xs_rare.svg';
import { ReactComponent as InventoryEpic } from 'components/library/cards/inventory/assets/inventory_xs_epic.svg';
import { ReactComponent as InventoryLegendary } from 'components/library/cards/inventory/assets/inventory_xs_legendary.svg';
import { ReactComponent as InventoryMythical } from 'components/library/cards/inventory/assets/inventory_xs_mythical.svg';

import { default as calendarCommon } from 'components/library/cards/calendar/assets/dark/1.svg';
import { default as calendarUncommon } from 'components/library/cards/calendar/assets/dark/2.svg';
import { default as calendarRare } from 'components/library/cards/calendar/assets/dark/3.svg';
import { default as calendarEpic } from 'components/library/cards/calendar/assets/dark/4.svg';
import { default as calendarLegendary } from 'components/library/cards/calendar/assets/dark/5.svg';
import { default as calendarMythical } from 'components/library/cards/calendar/assets/dark/6.svg';

import { default as calendarCommonLight } from 'components/library/cards/calendar/assets/light/1.svg';
import { default as calendarUncommonLight } from 'components/library/cards/calendar/assets/light/2.svg';
import { default as calendarRareLight } from 'components/library/cards/calendar/assets/light/3.svg';
import { default as calendarEpicLight } from 'components/library/cards/calendar/assets/light/4.svg';
import { default as calendarLegendaryLight } from 'components/library/cards/calendar/assets/light/5.svg';
import { default as calendarMythicalLight } from 'components/library/cards/calendar/assets/light/6.svg';

import { ReactComponent as ShopCommon } from 'components/library/cards/shop/assets/1.svg';
import { ReactComponent as ShopUncommon } from 'components/library/cards/shop/assets/2.svg';
import { ReactComponent as ShopRare } from 'components/library/cards/shop/assets/3.svg';
import { ReactComponent as ShopEpic } from 'components/library/cards/shop/assets/4.svg';
import { ReactComponent as ShopLegendary } from 'components/library/cards/shop/assets/5.svg';
import { ReactComponent as ShopMythical } from 'components/library/cards/shop/assets/6.svg';

import { ReactComponent as ShopCommonLight } from 'components/library/cards/shop/assets/light/1.svg';
import { ReactComponent as ShopUncommonLight } from 'components/library/cards/shop/assets/light/2.svg';
import { ReactComponent as ShopRareLight } from 'components/library/cards/shop/assets/light/3.svg';
import { ReactComponent as ShopEpicLight } from 'components/library/cards/shop/assets/light/4.svg';
import { ReactComponent as ShopLegendaryLight } from 'components/library/cards/shop/assets/light/5.svg';
import { ReactComponent as ShopMythicalLight } from 'components/library/cards/shop/assets/light/6.svg';

import { ReactComponent as EffectsCommon } from 'components/library/cards/effects/large/common-large.svg';
import { ReactComponent as EffectsUncommon } from 'components/library/cards/effects/large/uncommon-large.svg';
import { ReactComponent as EffectsRare } from 'components/library/cards/effects/large/rare-large.svg';
import { ReactComponent as EffectsEpic } from 'components/library/cards/effects/large/epic-large.svg';
import { ReactComponent as EffectsLegendary } from 'components/library/cards/effects/large/legendary-large.svg';
import { ReactComponent as EffectsMythical } from 'components/library/cards/effects/large/mythical-large.svg';
import { ReactComponent as EffectsCommonLight } from 'components/library/cards/effects/large/common-large--light.svg';
import { ReactComponent as EffectsUncommonLight } from 'components/library/cards/effects/large/uncommon-large--light.svg';
import { ReactComponent as EffectsRareLight } from 'components/library/cards/effects/large/rare-large--light.svg';
import { ReactComponent as EffectsEpicLight } from 'components/library/cards/effects/large/epic-large--light.svg';
import { ReactComponent as EffectsLegendaryLight } from 'components/library/cards/effects/large/legendary-large--light.svg';
import { ReactComponent as EffectsMythicalLight } from 'components/library/cards/effects/large/mythical-large--light.svg';

import { GRADES } from 'app/constants';
import { LazyImage } from '../shared/ui/lazyImage';

const items = [
  {
    itemId: 1,
    effect: {},
    name: 'EXP Token',
    description: 'Token earned for completing activities on Mighty platform',
    translationKey: 'EXPToken',
    img: exp,
    img2: <LazyImage src={exp} alt="" className="image" />,
    grade: GRADES.COMMON,
    price: 1,
    backgrounds: {
      light: {
        message:
          'radial-gradient(82.8% 173.42% at 89.62% 6.6%, rgba(255, 255, 255, 0) 0%, rgba(61, 61, 61, 0.4) 100%), linear-gradient(76.31deg, rgba(255, 255, 255, 0.7) -1.86%, rgba(255, 255, 255, 0.13) 100.22%), var(--basic-light-bg)',
      },
      dark: {
        message:
          'radial-gradient(63.4% 154.53% at 85.62% 22.96%, rgba(0, 0, 0, 0) 0%, rgba(173, 173, 173, 0.4) 100%), linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) -1.86%, rgba(40, 42, 57, 0.8) 100.22%), var(--basic-dark-bg)',
      },
    },
    color: {
      light: '#8A8A8A',
      dark: '#FFFFFF',
      cards: {
        light: '#5A5A5A',
        dark: '#FFFFFF',
      },
    },
    item: <Common className="grade" />,
    item_xs: <InventoryCommon className="grade"></InventoryCommon>,
    item_lg: expTokenLarge,

    // Background and pic for large effects (in Menu)
    item_effect_large: {
      light: <EffectsCommonLight />,
      dark: <EffectsCommon />,
    },
    img_effect_large: <LazyImage src={exp} alt="" className="image-effect-large" />,

    calendar_item: calendarCommon,
    calendar_item_light: calendarCommonLight,
    shop_item: <ShopCommon />,
    shop_item_light: <ShopCommonLight />,
    get link() {
      return `/shop?itemId=${this.id}`;
    },
  },
  {
    itemId: 2,
    effect: { extraTenSeconds: 1 },
    name: 'Time Stone',
    description: 'When used, gives an extra 10 seconds to answer a question in Mind battle. Destroyed when used.',
    translationKey: 'TimeStone',
    img: timeStone,
    img2: <LazyImage src={timeStone} alt="" className="image" />,
    grade: GRADES.UNCOMMON,
    price: 10,
    backgrounds: {
      light: {
        message:
          'radial-gradient(63.4% 154.53% at 85.62% 22.96%, rgba(255, 255, 255, 0) 0%, rgba(32, 255, 205, 0.4) 100%), linear-gradient(76.31deg, rgba(255, 255, 255, 0.7) -1.86%, rgba(255, 255, 255, 0.13) 100.22%), var(--basic-light-bg)',
      },
      dark: {
        message:
          'radial-gradient(63.4% 154.53% at 85.62% 22.96%, rgba(0, 0, 0, 0) 0%, rgba(42, 171, 142, 0.4) 100%), linear-gradient(76.31deg, #292B39 -1.86%, #282A39 100.22%), var(--basic-dark-bg)',
      },
    },
    color: {
      light: '#00723B',
      dark: '#29AB88',
      cards: {
        light: '#08782E',
        dark: '#29AB88',
      },
    },
    item_xs: <InventoryUncommon className="grade" />,
    item: <Uncommon className="grade" />,
    item_lg: timeStoneLarge,

    // Background and pic for large effects (in Menu)
    item_effect_large: {
      light: <EffectsUncommonLight className="grade" />,
      dark: <EffectsUncommon className="grade" />,
    },
    img_effect_large: <LazyImage src={timeStone} alt="" className="image-effect-large" />,

    calendar_item: calendarUncommon,
    calendar_item_light: calendarUncommonLight,
    shop_item: <ShopUncommon />,
    shop_item_light: <ShopUncommonLight />,
    get link() {
      return `/shop?itemId=${this.id}`;
    },
  },
  {
    itemId: 3,
    effect: { expMultiplier: { value: 0.3, expiresAt: 3600000 } },
    name: 'Exp Potion',
    description:
      'Increases the amount of EXP received while playing / learning by 30% for 1 hour.  If you consume more than 1 bottle, the effect will still be the same ( 30%).',
    translationKey: 'ExpPotion',
    img: expPotion,
    img2: <LazyImage src={expPotion} alt="Exp Potion" className="image" />,
    grade: GRADES.RARE,
    price: 10,
    backgrounds: {
      light: {
        message:
          'radial-gradient(91.51% 132.77% at 93.4% 3.77%, rgba(150, 221, 246, 0.89) 0.24%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(255, 255, 255, 0.7) -1.86%, rgba(255, 255, 255, 0.13) 100.22%), var(--basic-light-bg)',
      },
      dark: {
        message: 'radial-gradient(71.72% 109.07% at 20.55% 69.42%, #415093 0%, #394578 12.5%, #272938 82.81%), var(--basic-dark-bg)',
      },
    },
    color: {
      light: '#2687DB',
      dark: '#627EEA',
      cards: {
        light: '#2C2C96',
        dark: '#627EEA',
      },
    },
    item_xs: <InventoryRare className="grade" />,
    item: <Rare className="grade" />,
    item_lg: expPotionLarge,

    // Background and pic for large effects (in Menu)
    item_effect_large: {
      light: <EffectsRareLight className="grade" />,
      dark: <EffectsRare className="grade" />,
    },
    img_effect_large: <LazyImage src={expPotion} alt="" className="image-effect-large" />,

    calendar_item: calendarRare,
    calendar_item_light: calendarRareLight,
    shop_item: <ShopRare />,
    shop_item_light: <ShopRareLight />,
    get link() {
      return `/shop?itemId=${this.id}`;
    },
  },
  {
    itemId: 4,
    effect: { expMultiplier: { value: 0.1, expiresAt: 2592000000 } },
    name: 'Amethyst Stone',
    description: 'Increases the amount of EXP received by 10% for 30 days. If you consume more than 1 stone, the effect will still be the same.',
    translationKey: 'AmethystStone',
    img: amethyst,
    img2: <LazyImage src={amethyst} alt="Amethyst" className="image" />,
    grade: GRADES.EPIC,
    price: 50,
    backgrounds: {
      light: {
        message:
          'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(255, 255, 255, 0) 0%, rgba(186, 0, 190, 0.4) 100%), linear-gradient(76.31deg, rgba(255, 255, 255, 0.7) -1.86%, rgba(255, 255, 255, 0.13) 100.22%), var(--basic-light-bg)',
      },
      dark: {
        message:
          'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(228, 92, 231, 0.4) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, #292B39 -1.86%, #282A39 100.22%), var(--basic-dark-bg)',
      },
    },
    color: {
      light: '#C947D3',
      dark: '#CA47CE',
      cards: {
        light: '#872E79',
        dark: '#CA47CE',
      },
    },
    item_xs: <InventoryEpic className="grade" />,
    item: <Epic className="grade" />,
    item_lg: amethystLarge,

    // Background and pic for large effects (in Menu)
    item_effect_large: {
      light: <EffectsEpicLight className="grade" />,
      dark: <EffectsEpic className="grade" />,
    },
    img_effect_large: <LazyImage src={amethyst} alt="" className="image-effect-large" />,

    calendar_item: calendarEpic,
    calendar_item_light: calendarEpicLight,
    shop_item: <ShopEpic />,
    shop_item_light: <ShopEpicLight />,
    get link() {
      return `/shop?itemId=${this.id}`;
    },
  },
  {
    itemId: 5,
    effect: { freeCourse: 1 },
    name: 'Emperor Key',
    description: 'Allows you to buy any course for free.',
    translationKey: 'EmperorKey',
    img: emperorKey,
    img2: <LazyImage src={emperorKey} alt="" className="image" />,
    grade: GRADES.LEGENDARY,
    price: 1000,
    backgrounds: {
      light: {
        message:
          'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(255, 255, 255, 0) 9.9%, rgba(255, 140, 0, 0.4) 100%), linear-gradient(76.31deg, rgba(255, 255, 255, 0.7) -1.86%, rgba(255, 255, 255, 0.13) 100.22%), var(--basic-light-bg)',
      },
      dark: {
        message:
          'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(0, 0, 0, 0) 0%, rgba(255, 182, 93, 0.4) 100%), linear-gradient(76.31deg, rgba(41, 43, 57, 0.33) -1.86%, #282A39 100.22%), var(--basic-dark-bg)',
      },
    },
    color: {
      light: '#FF8C00',
      dark: '#FFBF66',
      cards: {
        light: '#9A5300',
        dark: '#FFBF66',
      },
    },
    item_xs: <InventoryLegendary className="grade" />,
    item: <Legendary className="grade" />,
    item_lg: emperorKeyLarge,

    // Background and pic for large effects (in Menu)
    item_effect_large: {
      light: <EffectsLegendaryLight className="grade" />,
      dark: <EffectsLegendary className="grade" />,
    },
    img_effect_large: <LazyImage src={emperorKey} alt="" className="image-effect-large" />,

    calendar_item: calendarLegendary,
    calendar_item_light: calendarLegendaryLight,
    shop_item: <ShopLegendary />,
    shop_item_light: <ShopLegendaryLight />,
    get link() {
      return `/shop?itemId=${this.id}`;
    },
  },
  {
    itemId: 6,
    effect: { extraDailyQuest: 1 },
    name: 'Blood Talisman',
    description: 'Gives an extra Daily Quest. Cooldown: 14 days. Warning: use it only after quest has been used once.',
    translationKey: 'BloodTalisman',
    img: bloodTalisman,
    img2: <LazyImage src={bloodTalisman} alt="Blood talisman" className="image"></LazyImage>,
    grade: GRADES.MYTHICAL,
    price: 100,
    backgrounds: {
      light: {
        message:
          'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(255, 255, 255, 0) 9.9%, rgba(241, 61, 90, 0.4) 100%), linear-gradient(76.31deg, #FFFFFF -1.86%, rgba(255, 255, 255, 0) 100.22%), var(--basic-light-bg)',
      },
      dark: {
        message:
          'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(0, 0, 0, 0) 0%, rgba(241, 61, 90, 0.4) 100%), linear-gradient(76.31deg, #292B39 -1.86%, #282A39 100.22%), var(--basic-dark-bg)',
      },
    },
    color: {
      light: '#F4556D',
      dark: '#F14F63',
      cards: {
        light: '#811018',
        dark: '#F14F63',
      },
    },
    item_xs: <InventoryMythical className="grade" />,
    item: <Mythical className="grade" />,
    item_lg: bloodTalismanLarge,

    // Background and pic for large effects (in Menu)
    item_effect_large: {
      light: <EffectsMythicalLight className="grade" />,
      dark: <EffectsMythical className="grade" />,
    },
    img_effect_large: <LazyImage src={bloodTalisman} alt="" className="image-effect-large" />,

    calendar_item: calendarMythical,
    calendar_item_light: calendarMythicalLight,
    shop_item: <ShopMythical />,
    shop_item_light: <ShopMythicalLight />,
    get link() {
      return `/shop?itemId=${this.id}`;
    },
  },
  // 7 ИТЕМ УДАЛИТЬ - ИЛИ ПЕРЕДЕЛАЬБ НО ТАМ УЖЕ ПРОПОВ МАЛО
  {
    itemId: 7,
    effect: { freeCourse: 1 },
    name: 'Moon',
    description: 'Allows you to buy any course for free.',
    translationKey: 'Moon',
    img: emperorKey,
    img2: <LazyImage src={emperorKey} alt="" className="image" />,
    grade: GRADES.DIVINE,
    price: 1000,
    backgrounds: {
      light: {
        message:
          'radial-gradient(63.4% 154.53% at 85.62% 22.96%, rgba(0, 181, 230, 0.4) 0%, rgba(255, 255, 255, 0) 100%), linear-gradient(76.31deg, rgba(244, 248, 255, 0.56) -1.86%, rgba(193, 204, 244, 0.104) 100.22%), #61DAFB, var(--basic-light-bg)',
      },
      dark: {
        message:
          'radial-gradient(61.76% 150.55% at 83.99% 23.47%, rgba(92, 231, 222, 0.4) 0%, rgba(0, 0, 0, 0) 100%), linear-gradient(76.31deg, rgba(50, 53, 71, 0.472) -1.86%, rgba(40, 42, 57, 0.8) 100.22%), var(--basic-dark-bg)',
      },
    },
    color: {
      light: '#2C6396',
      dark: '#5CE7DE',
      cards: {
        light: '#2C6396',
        dark: '#5CE7DE',
      },
    },
    item_xs: <InventoryLegendary className="grade" />,
    item: <Devine className="grade" />,
    item_lg: emperorKeyLarge,
    item_effect_large: <EffectsLegendary className="grade" />,
  },
];
export default items;
