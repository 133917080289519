import hexToRgba from 'hex-to-rgba';
import styled, { css } from 'styled-components';

export const SButton = styled.button(
  ({ theme }) => css`
    display: flex;
    align-items: center;
    background: transparent;
    padding: 3px;
    padding-right: 10px;
    border: none;
    border-radius: 4px;
    color: ${theme.theme.fontColor};
    transition: background 0.3s ease;
    font-size: 18px;

    &:hover {
      background: ${hexToRgba(theme.theme.fontColor, 0.1)};
    }
  `
);
