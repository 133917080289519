import SearchGroupItem from './SearchGroupItem/SearchGroupItem';

export default function SearchGroup({ groupName, results, goToItem }) {
  if (results.length < 1) {
    return <></>;
  }
  return (
    <>
      <div className="search-group item-container group-name" role={'button'} onClick={() => goToItem(`search#${groupName}`, { groupName })}>
        <p className="group-name">{groupName}</p>
        <p className="group-name see-all">See all</p>
      </div>
      {results.slice(0, 5).map((item, index) => (
        <SearchGroupItem key={'search-main-group-' + index} item={item} groupName={groupName} />
      ))}
    </>
  );
}
