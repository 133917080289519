import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `
  ),
  Indicator = styled.div(
    () => css`
      height: 58px;
      display: flex;
      transition: all 0.25s ease-in-out;
      gap: 12px;

      &.shrinkable {
        margin: 0 -29px;
      }
      &:first-of-type,
      &.next {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }
      &.active {
        margin: 0;
      }

      .indicator {
        flex-shrink: 0;
      }
    `
  ),
  HoverItem = styled.div(
    () => css`
      height: 100%;
    `
  ),
  All = styled.span(
    () => css`
      font-size: 16px;
      line-height: 115%;
      letter-spacing: 0.5px;
      opacity: 0.7;
      position: absolute;
      top: 0;
      right: 0;
      transform: translateY(calc(-100% - 10px));
      padding: 10px;
      cursor: pointer;

      &.mobile {
        right: 10px;
      }
    `
  );
