import { createApi } from '@reduxjs/toolkit/dist/query/react';
import { customFetchBase } from 'store/utils';
import { ESpaceRoles, IAnalyticsCourse, IMySpacesResponseItem, ISpace, ISpaceDesc, ISpaceShort, ISpaceUser } from './types';
import { ICourseFull } from 'interface/courses';
import { IRewardMinimal, TCreateCourseDTO } from 'store/coursesConstructor';
import { EGroupTag } from 'app/constants';

export const spacesApi = createApi({
  reducerPath: 'spacesApi',
  baseQuery: customFetchBase,
  // refetchOnFocus: false,
  refetchOnReconnect: true,
  tagTypes: ['Spaces', 'Quests', 'MySpaces'],
  endpoints: (builder) => ({
    getSpaces: builder.query<
      { items: ISpace[]; total: number; page: number; totalPages: number },
      { page: number; verified: boolean; limit: number } | undefined
    >({
      query: (params) => ({
        url: '/space',
        params,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      }),
      providesTags: ['Spaces'],
    }),
    getMySpaces: builder.query<ISpace[], void>({
      query: () => ({
        url: '/space/my',
      }),
      transformResponse: (res: IMySpacesResponseItem[]) => {
        return res.map((space) => {
          return space as any as ISpace;
        });
      },
      providesTags: ['MySpaces'],
    }),
    getSpaceById: builder.query<ISpace, string>({
      query: (id) => ({
        url: `/space/${id}`,
      }),
      providesTags: ['Spaces'],
    }),
    getSpaceByName: builder.query<ISpace, string>({
      query: (name) => {
        const partnerKey = localStorage.getItem('partnerToken');
        if (partnerKey) {
          return {
            url: `/space/by-name/${name}?partner-key=${partnerKey}`,
          };
        }
        return {
          url: `/space/by-name/${name}`,
        };
      },
      providesTags: ['Spaces'],
    }),
    getSpaceAnalytics: builder.query<
      { items: IAnalyticsCourse[]; total: number; totalPages: number; page: number },
      { id: string; limit?: number; page?: number; type?: 'quest' | 'course' }
    >({
      query: ({ id, limit = 10, page = 1, type }) => ({
        url: `/space/analytics/${id}`,
        params: { limit, page, type },
      }),
    }),
    createSpace: builder.mutation<
      { result: ISpace; message: string },
      { name: string; description: ISpaceDesc[]; link: string; image?: string; imageThumb: string; tags: EGroupTag[] }
    >({
      query: (body) => ({
        url: '/space',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Spaces'],
    }),
    updateSpace: builder.mutation<
      { result: ISpace; message: string },
      { name: string; description: ISpaceDesc[]; link: string; tags: EGroupTag[]; isVerified?: boolean; spaceId: string }
    >({
      query: (body) => ({
        url: '/space',
        method: 'PUT',
        body,
      }),
    }),
    updateSpaceImages: builder.mutation<{ result: ISpace; message: string }, { image?: string; imageThumb?: string; spaceId: string }>({
      query: (body) => ({
        url: '/space/images',
        method: 'PUT',
        body,
      }),
    }),
    deleteSpace: builder.mutation<any, { spaceId: string }>({
      query: (body) => ({
        url: `/space`,
        method: 'DELETE',
        body,
      }),
      invalidatesTags: ['Spaces', 'MySpaces'],
    }),
    toggleSpaceRole: builder.mutation<ISpaceUser[], { role: ESpaceRoles; userId: string; spaceId: string }>({
      query: (body) => ({
        url: '/space/permissions/toggle-role',
        method: 'PUT',
        body,
      }),
    }),
    transferSpaceOwnership: builder.mutation<any, { role: ESpaceRoles; userId: string; spaceId: string }>({
      query: (body) => ({
        url: '/space/permissions/transfer-ownership',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Spaces'],
    }),
    subscribeToSpace: builder.mutation<any, { spaceId: string }>({
      query: (body) => ({
        url: '/space/members/subscribe',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MySpaces'],
    }),
    unsubscribeFromSpace: builder.mutation<any, { spaceId: string }>({
      query: (body) => ({
        url: '/space/members/unsubscribe',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['MySpaces'],
    }),
    getSubscribers: builder.query<{ items: any[] }, string>({
      query: (id) => ({ url: `/space/subscribers/${id}` }),
    }),
    getQuestsBySpaceId: builder.query<{ quests: ICourseFull[]; completed?: string[] }, string>({
      query: (id) => ({
        url: `/space/quests/${id}`,
      }),
      providesTags: ['Quests'],
    }),
    createQuest: builder.mutation<ICourseFull, TCreateCourseDTO>({
      query: (body) => ({
        url: `/space/quests`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['Quests'],
    }),
    getSpaceRewards: builder.query<{ items: IRewardMinimal[] }, string>({
      query: (id) => ({
        url: `/space/total-rewards/${id}`,
      }),
    }),
    rearrangeSpaceQuests: builder.mutation<any, { spaceId: string; ids: string[] }>({
      query: (body) => ({
        url: '/space/quests/rearrange',
        body,
        method: 'PUT',
      }),
      invalidatesTags: ['Quests'],
    }),
    getRandomSpace: builder.query<string, void>({
      query: () => ({
        url: '/space/random-name',
      }),
      transformResponse: (res: { name: string }) => {
        return res.name;
      },
    }),
    getSpacesShort: builder.query<ISpaceShort[], void>({
      query: () => ({
        url: 'space/short/leaderboards',
      }),
    }),
    getIfSpaceWithThisNameExists: builder.query<{ success: boolean }, string>({
      query: (spaceName) => ({
        url: `/space/by-name/is/${spaceName}`,
      }),
    }),
    getFeed: builder.query<
      { items: ICourseFull[]; page: number; total: number; totalPages: number; totalRewards: IRewardMinimal[] },
      { limit: number; page: number }
    >({
      query: (params) => ({
        url: `/space/quests/feed`,
        params,
      }),
    }),
    getSpaceCoursesBySpaceId: builder.query<
      { items: ICourseFull[]; page: number; total: number; totalPages: number; totalRewards: IRewardMinimal[] },
      { limit: number; page: number; spaceId: string }
    >({
      query: ({ spaceId, ...params }) => ({
        url: `/space/courses/${spaceId}`,
        params,
      }),
    }),
    getPartnerLogo: builder.query<string, string>({
      query: (partnerId: string) => ({
        url: `/space/partner-logo/by-name/${partnerId}`,
      }),
      transformResponse: (data: { image: string }) => {
        return data.image;
      },
    }),
  }),
});

export const {
  useCreateSpaceMutation,
  useGetSpaceByIdQuery,
  useGetSpacesQuery,
  useLazyGetSpacesQuery,
  useToggleSpaceRoleMutation,
  useTransferSpaceOwnershipMutation,
  useUpdateSpaceImagesMutation,
  useUpdateSpaceMutation,
  useGetSpaceByNameQuery,
  useGetSpaceAnalyticsQuery,
  useLazyGetSpaceAnalyticsQuery,
  useLazyGetSpaceByNameQuery,
  useSubscribeToSpaceMutation,
  useUnsubscribeFromSpaceMutation,
  useGetSubscribersQuery,
  useGetQuestsBySpaceIdQuery,
  useCreateQuestMutation,
  useGetSpaceRewardsQuery,
  useDeleteSpaceMutation,
  useGetMySpacesQuery,
  useLazyGetSpaceByIdQuery,
  useRearrangeSpaceQuestsMutation,
  useGetRandomSpaceQuery,
  useGetSpacesShortQuery,
  useLazyGetIfSpaceWithThisNameExistsQuery,
  useLazyGetFeedQuery,
  useLazyGetSpaceCoursesBySpaceIdQuery,
  useLazyGetPartnerLogoQuery,
} = spacesApi;
