import transferItem from '../assets/transferItem.png';
import activateItem from '../assets/activateItem.png';
import activateCode from '../assets/activateCode.png';
import itemDetails from '../assets/itemDetails.png';
import itemLore from '../assets/itemLore.png';
import seeInInventory from '../assets/seeInInventory.png';
import seeInShop from '../assets/seeInShop.png';

export interface IItemAction {
  name: 'transferItem' | 'activateItem' | 'activateCode' | 'itemDetails' | 'itemLore' | 'seeInInventory' | 'seeInShop' | 'activateChest';
  image: string;
  title: string;
  type?: 'fungible' | 'non-fungible' | 'digital' | 'chest';
}

export const actions: IItemAction[] = [
  { name: 'transferItem', image: transferItem, title: 'Transfer item', type: 'non-fungible' },
  { name: 'activateItem', image: activateItem, title: 'Activate item', type: 'fungible' },
  { name: 'activateChest', image: activateItem, title: 'Open chest', type: 'chest' },
  { name: 'activateCode', image: activateCode, title: 'Activate code', type: 'digital' },
  { name: 'itemDetails', image: itemDetails, title: 'Item Details' },
  { name: 'seeInInventory', image: seeInInventory, title: 'See in inventory' },
  { name: 'seeInShop', image: seeInShop, title: 'See In Shop' },
  { name: 'itemLore', image: itemLore, title: 'Item Lore' },
];
