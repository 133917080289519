import { FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { DIFFICULTIES, GRADES, LANGS, NAME_COLORS } from 'app/constants';
import { ICourse } from 'interface/courses';
import { string, boolean, object, array } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { toBase64 } from 'shared/utils/files/toBase64';
import { coursesConstructorAPI, getCurrentLanguage, getCurrentTheme, ECourseDifficulties, ECourseCategories } from 'store';
import { useSelector } from 'react-redux';
import { getDataByLanguage } from 'utils/language';
import clsx from 'clsx';
import { useGetCourseGroups } from 'hooks';
import { toast } from 'react-toastify';
import { useCreateQuestMutation } from 'store';

import { ReactComponent as WarningIcon } from 'assets/icons/warning.svg';

import { BasicButtonV1, Modal, NullButton, Tag, TagsGroup } from 'shared/ui';
import Answer from 'components/library/messages/Answer';
import { Input } from './ui/input';
import { Cover } from './ui/cover';

import styles from './styles.module.scss';
import GroupCard from 'components/pages/study/courses/group-cards/group-card/group-card';
interface IProps {
  open: boolean;
  onClose: () => void;
  editCourseData?: ICourse | any;
  onSubmitHandler: (newCourse?: Partial<ICourse>) => void;
  spaceId?: string;
  isMini?: boolean;
}

// enum ECourseType {
//   LargeCourse = 'Large course',
//   MiniCourse = 'Mini course',
//   Quest = 'Quest',
// }

export const CourseForm = ({ open, onClose, onSubmitHandler, editCourseData, spaceId, isMini: isDefaultMini }: IProps) => {
  const [isAllGroupShown, setIsAllGroupShown] = useState(false);
  const [createCourse, { isLoading: isLoadingCreateCourse }] = coursesConstructorAPI.useCreateCourseMutation();
  const [editCourse, { isLoading: isLoadingEditCourse }] = coursesConstructorAPI.useEditCourseMutation();
  const [editImage, { isLoading: isLoadingEditImage }] = coursesConstructorAPI.useEditCourseImageMutation();
  const [createQuest, { isLoading: isLoadingCreateQuest }] = useCreateQuestMutation();
  const currentLanguage = useSelector(getCurrentLanguage);
  const theme = useSelector(getCurrentTheme);
  const gradesValues = Object.values(GRADES);
  const { groupsNativeNames: groups, tags, favouritesKey } = useGetCourseGroups();
  const gradeColors = useMemo(
    () =>
      Object.values(NAME_COLORS)
        .map((i) => i[theme])
        .map((value, index) => ({
          color: value === 'default' ? 'gray' : value,
          value: gradesValues[index],
        })),
    [gradesValues, theme]
  );
  const MAX_ESTIMATEDTIME_LENGTH = 11;
  const nameRegex = /^[a-zA-Z0-9.:\s!&?()-_*;]+$/;

  const schema = object().shape({
    name: string()
      .min(3, 'Please come up with a name that is at least 3 characters long')
      .max(50, 'Please come up with a name that is no more than 50 characters long')
      .matches(nameRegex, 'Name can contain latin characters, numbers, and this set of symbols: .:;!?()-_*')
      .required('Please come up with a name that is no more than 50 characters long'),
    description: string().max(500, 'Please write description that is no more than 500 characters long'),
    grade: string().oneOf(gradesValues), // .required('Choose a grade'),
    difficulty: string().default(DIFFICULTIES.BEGINNER).required('Please choose difficulty'),
    category: string(), // .required('Choose a category'),
    groups: array().of(string().oneOf(groups)).min(1, 'Please choose at least one group').required('Please choose at least one group'),
    imageCover: string().required('Please upload a cover for your course'),
    estimatedTime: string().max(MAX_ESTIMATEDTIME_LENGTH), // .required('Input an estimated time'),
    isMini: boolean().default(false), // .required(),
    isQuest: boolean().default(false),
  });

  const getDataCourse = () => {
    if (editCourseData) {
      return {
        name: editCourseData.name,
        description: getDataByLanguage(editCourseData.description, currentLanguage),
        grade: editCourseData.grade,
        difficulty: editCourseData.difficulty,
        category: editCourseData.category,
        groups: editCourseData.groups,
        estimatedTime: editCourseData.estimatedTime,
        isMini: editCourseData.isMini ? editCourseData.isMini : false,
        isQuest: editCourseData.isQuest || false,
        imageCover: process.env.REACT_APP_STATIC_HOST + editCourseData.imageCover,
      };
    }
    return {
      difficulty: DIFFICULTIES.BEGINNER,
      description: getDataByLanguage([{ language: LANGS.EN, title: '' }], currentLanguage),
      grade: gradeColors[0].value,
      isMini: false,
      isQuest: false,
      estimatedTime: '1 minutes',
    };
  };

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    formState: { errors, isValid },
  } = useForm<any>({
    mode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: getDataCourse(),
  });

  useEffect(() => {}, [editCourseData]);

  const isLoading = isLoadingEditCourse || isLoadingCreateCourse || isLoadingEditImage || isLoadingCreateQuest;

  const isNotAllowed = !isValid || Object.keys(errors).length > 0 || isLoading;

  const handleGroup = (value: string) => {
    const groups: string[] = getValues('groups');
    if (!groups) {
      setValue('groups', [value]);
      trigger('groups');
      return;
    }
    if (groups.includes(value)) {
      const newGroups = groups.filter((g) => g !== value);
      setValue('groups', newGroups);
    } else {
      setValue('groups', groups.concat(value));
    }
    trigger('groups');
  };

  const onChangeImageHandler = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e?.currentTarget?.files?.length) {
      return;
    }
    const photos = Array.from(e.currentTarget.files);
    onUploadHandler('imageCover')(photos[0]);
  };

  const onUploadHandler = (path: string) => async (file: File) => {
    const sizeInKb = file.size / 1024;
    const maxSize = 1024 * 3;
    if (sizeInKb > maxSize) {
      setError(`${path}_size`, { type: 'custom', message: `Maximum size of ${path === 'imageLogo' ? 'logo' : 'cover'} - 3MB` });
    } else {
      clearErrors(`${path}_size`);
    }
    const base64Image = await toBase64(file);
    setValue(path, base64Image ?? '');
    trigger(path);
  };

  // const handleCourseType = (type: ECourseType) => {
  //   if (type === ECourseType.MiniCourse) {
  //     setValue('isMini', true);
  //     setValue('isQuest', false);
  //   } else if (type === ECourseType.Quest) {
  //     setValue('isMini', false);
  //     setValue('isQuest', true);
  //   } else {
  //     setValue('isMini', false);
  //     setValue('isQuest', false);
  //   }
  //   trigger(['isMini', 'isQuest']);
  // };

  const onSubmit = (data: any) => {
    if (isNotAllowed) {
      return;
    }
    const partsCover = data.imageCover?.split(';');
    const imageCoverData = partsCover && partsCover[1] && partsCover[1]?.split(',')[1] ? partsCover[1]?.split(',')[1] : '';

    if (editCourseData) {
      const { imageLogo, imageCover, estimatedTimeInput, estimatedTimeUnits, ...rest } = data;

      const courseEditForm = {
        ...rest,
        bundles: ['Others'],
        description: [{ language: LANGS.EN, title: data.description }],
      };

      if (data.imageCover?.includes('data:image')) {
        return editImage({ courseId: editCourseData._id, body: imageCoverData }).then(() => {
          editCourse({ courseId: editCourseData._id, body: courseEditForm }).then((result: any) => {
            if (result.error || !result.data?._id) {
              console.error(result.error);
              toast.error(<Answer type="incorrect" label={result.error?.data?.message ?? result.error?.message ?? 'error, check console'} />);
              return;
            }
            const { chapters, ...courseSterilized } = result.data;
            courseSterilized.rewards = editCourseData?.rewards;

            onSubmitHandler(courseSterilized);
          });
        });
      }
      return editCourse({ courseId: editCourseData._id, body: courseEditForm }).then((result: any) => {
        if (result.error || !result.data?._id) {
          console.error(result.error);
          toast.error(<Answer type="incorrect" label={result.error?.data?.message ?? result.error?.message ?? 'error, check console'} />);
          return;
        }
        const { chapters, ...courseSterilized } = result.data;
        courseSterilized.rewards = editCourseData?.rewards;

        onSubmitHandler(courseSterilized);
      });
    }

    const { estimatedTimeInput, estimatedTimeUnits, ...dataClean } = data;

    const courseForm = {
      ...dataClean,
      imageCover: imageCoverData,
      groups: data.groups,
      bundles: ['Others'],
      estimatedTime: '1 hours',
      grade: GRADES.RARE,
      category: ECourseCategories.WEB_DEVELOPMENT,
      description: [{ language: LANGS.EN, title: dataClean.description }],
      isMini: isDefaultMini || false,
    };
    const errorToast = (ex: any) =>
      toast.error(<Answer type="incorrect" label={ex?.data?.message ?? ex?.message ?? 'Some error occured! Try again or contact a support'} />);

    if (spaceId) {
      courseForm.spaceId = spaceId;
      if (courseForm.isQuest) {
        createQuest(courseForm)
          .unwrap()
          .then(onSubmitHandler)
          .catch((ex) => {
            console.error(ex);
            errorToast(ex);
          });
        return;
      }
    }

    createCourse(courseForm)
      .unwrap()
      .then(onSubmitHandler)
      .catch((ex) => {
        console.error(ex);
        errorToast(ex);
      });
  };

  return (
    <Modal
      isBg={false}
      open={open}
      onClose={onClose}
      className={styles.modal}
      containerClassName={styles.modalContainer}
      closeButtonVariant="filled"
      closeButtonForm="round"
      closeButtonLocation="inside"
      closeButtonClassName={styles.modalCloseButton}
    >
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="imageCover"
          control={control}
          render={() => <Cover theme={theme} onChange={onChangeImageHandler} src={getValues('imageCover')} />}
        />
        <div className={clsx(styles.wrapper, `glass-div basic ${theme} ${styles[theme]}`)}>
          <FieldContainer error={errors?.imageCover?.message as string}></FieldContainer>
          <FieldContainer label={'Course name'} error={errors?.name?.message as string}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  placeholder={'Enter a course name of up to 90 characters'}
                  maxLength={90}
                  isError={!!errors?.name?.message}
                  defaultValue={field.value}
                  {...field}
                  onChange={field.onChange}
                />
              )}
            />
          </FieldContainer>
          <FieldContainer label={'Description'} error={errors?.description?.message as string}>
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  onChange={field.onChange}
                  type="textarea"
                  maxLength={150}
                  placeholder="Enter course description up to 150 characters"
                  defaultValue={field.value}
                  isError={!!errors?.description?.message}
                />
              )}
            />
          </FieldContainer>
          <FieldContainer label="difficulty" error={errors?.difficulty?.message as string}>
            <TagsGroup columns={3}>
              <Controller
                name="difficulty"
                control={control}
                render={({ field }) => (
                  <>
                    {Object.values(ECourseDifficulties).map((diff) => (
                      <Tag
                        className={styles.tag}
                        key={diff}
                        label={diff}
                        onClick={() => field.onChange(diff)}
                        variant="gold"
                        isActive={getValues('difficulty') === diff}
                        theme={theme}
                      />
                    ))}
                  </>
                )}
              />
            </TagsGroup>
          </FieldContainer>
          {/* <FieldContainer childrenContainerClassName={styles.groups} label="Course type">
            <TagsGroup columns={3}>
              <Controller
                name="isMini"
                control={control}
                render={() => (
                  <>
                    {Object.values(ECourseType).map((type) => (
                      <Tag
                        className={styles.tag}
                        key={type}
                        label={type}
                        onClick={() => handleCourseType(type)}
                        isActive={
                          type === ECourseType.LargeCourse
                            ? !getValues('isQuest') && !getValues('isMini')
                            : type === ECourseType.Quest
                            ? getValues('isQuest')
                            : getValues('isMini')
                        }
                        theme={theme}
                      />
                    ))}
                  </>
                )}
              />
            </TagsGroup>
          </FieldContainer> */}
          <FieldContainer childrenContainerClassName={styles.groups} label="Categories" error={errors?.groups?.message as string}>
            <TagsGroup columns={2}>
              <Controller
                name="groups"
                control={control}
                render={() => (
                  <>
                    {groups
                      .filter((g) => g !== favouritesKey)
                      .slice(0, isAllGroupShown ? groups.length - 1 : 4)
                      .map((group) => (
                        <GroupCard
                          className={styles.tag}
                          key={group}
                          group={tags[group]}
                          onClick={() => handleGroup(group)}
                          isActive={getValues('groups')?.includes(group)}
                        />
                      ))}
                  </>
                )}
              />
            </TagsGroup>
            <NullButton
              className={styles.seeAllCatsBtn}
              onClick={(e) => {
                e.preventDefault();
                setIsAllGroupShown((prev) => !prev);
              }}
            >
              Show All Categories
            </NullButton>
          </FieldContainer>
          <BasicButtonV1 type="submit" className={styles.button} variant="rounded" isLoading={isLoading}>
            {editCourseData ? 'Edit' : 'Create'}
          </BasicButtonV1>
        </div>
      </form>
    </Modal>
  );
};

const FieldContainer: FC<PropsWithChildren<{ label?: string; childrenContainerClassName?: string; error?: string }>> = ({
  children,
  label,
  error,
  childrenContainerClassName = '',
}) => {
  return (
    <div className={clsx(styles.container, error)}>
      <h6 className={styles.label}>{label}</h6>
      <div className={clsx({ [childrenContainerClassName]: !!childrenContainerClassName })}>{children}</div>
      {error && <ErrorLabel label={error} />}
    </div>
  );
};

const ErrorLabel: FC<{ label?: string }> = ({ label }) => {
  return (
    <div className={clsx(styles.errorContainer)}>
      <WarningIcon />
      <span>{label}</span>
    </div>
  );
};
