import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      align-items: center;
      width: 335px;
      height: 134px;
      backdrop-filter: blur(51px);
      border-radius: 10px;

      @media screen and (max-width: 476px) {
        width: 100%;
      }
    `
  ),
  ImageContainer = styled.div(
    () => css`
      width: 134px;
      min-width: 134px;
      height: 134px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      position: relative;
      border-radius: 10px;

      .progress {
        backdrop-filter: var(--standard-blur);
        padding: 7px;
        position: absolute;
        bottom: 0;
      }

      img {
        object-fit: contain;
        max-height: 100%;
      }
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 12px 15px 15px;
      width: 100%;
      max-width: 100%;
      height: 100%;
      overflow: hidden;
    `
  ),
  Top = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
    `
  ),
  Rewards = styled.div(
    () => css`
      display: flex;
      align-items: center;
      height: 30px;

      & > img {
        margin-right: -12px;
        height: 100%;
        width: auto;
        scale: 1.25;
      }
      & > img:last-of-type {
        margin-right: 0;
      }
    `
  ),
  Name = styled.p(
    () => css`
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      line-height: 114%;
      color: var(--font-color-strong);
      margin-bottom: 10px;
      width: calc(100%);
      text-overflow: ellipsis;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    `
  ),
  Time = styled.span(
    () => css`
      display: inline-flex;
      align-items: center;
      gap: 2px;
      color: var(--font-color-strong);
      font-size: 14px;
      line-height: 100%;
      white-space: pre;
      svg {
        height: 15px;
      }
    `
  );
