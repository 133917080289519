import { shallowEqual, useSelector } from 'react-redux';
import { useUpdateUserDripMutation, getIsOpenSidePanel } from 'store';
import { useEffect, useState } from 'react';
import { useDisplay, useGetCurrentTheme, useGetUserData } from 'hooks';
import { useGlobalLoader } from 'shared/utils/GlobalLoader/useGlobalLoader';
import uploadImage from 'utils/uploadImage';
import clsx from 'clsx';
import styles from '../styles.module.scss';
import MenuNav from '../../../menu/MenuNav';
import ProfileCard from '../profileCard/profile-card';
import screens from '../utils/screens';
import Profile from '../Profile';

import { useLocation } from 'react-router-dom';
import { EMenuScreens } from 'app/constants';

export const profileAnimationDelay = 0.2;

export const UserLayout = () => {
  const { nickname, avatar, exp, customization, friends } = useGetUserData();
  const theme = useGetCurrentTheme();
  const [updateDrip] = useUpdateUserDripMutation();
  const { isMobile } = useDisplay();
  const [currentScreen, setCurrentScreen] = useState<EMenuScreens>(EMenuScreens.PROFILE);
  const { multiPanel, shopPanel } = useSelector(getIsOpenSidePanel, shallowEqual);
  const isOpenSidePanel = multiPanel || shopPanel;
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const screenParam = params.get('screen') as EMenuScreens;

  useGlobalLoader({ dependency: [false], isUserPage: true });

  useEffect(() => {
    if (screenParam) {
      setCurrentScreen(screenParam);
    }
  }, [screenParam]);

  useEffect(() => {
    if (currentScreen && isMobile) {
      const url = new URL(window.location.href);

      url.searchParams.set('screen', currentScreen);

      window.history.replaceState({}, '', url.toString());
    }
  }, [currentScreen]);

  async function handleImageUpload(e: React.ChangeEvent<HTMLInputElement>) {
    const avatar = await uploadImage(e);
    updateDrip({ avatar });
  }

  return (
    <>
      {isMobile && <div className={clsx(styles.mobileBg, styles[theme], styles[currentScreen])}></div>}
      <div className={`profile ${isOpenSidePanel ? '' : 'without-side-panel'}`}>
        {isMobile && (
          <MenuNav
            active={currentScreen}
            setShowMenu={() => null}
            onActiveMenu={({ screen }: { screen: EMenuScreens }) => setCurrentScreen(screen)}
            showCloseButton={false}
            showTooltip={false}
            variant={'mobile'}
            className={styles.menuNav}
          />
        )}
        <ProfileCard
          userProfile={{ nickname, avatar, exp, customization, friends }}
          handleImageUpload={handleImageUpload}
          className={styles.profileCard}
        />
        {isMobile ? screens[currentScreen].screen : <Profile />}
      </div>
    </>
  );
};
