import styled, { css } from 'styled-components';

export const Table = styled.table(
    () => css`
      width: 100%;
      border-collapse: collapse;
      color: #ffffff;
      border-radius: 8px;
      overflow: hidden;

      border-collapse: separate;
      border-spacing: 0 10px;

      &,
      & * {
        font-weight: 400;
      }

      thead {
        ${Tr} {
          border-radius: 5px;
          background-color: #31364e;
          ${Th} {
            &:first-child {
              border-top-left-radius: 5px;
              border-bottom-left-radius: 5px;
            }

            &:last-child {
              border-top-right-radius: 5px;
              border-bottom-right-radius: 5px;
            }
          }
        }
      }
    `
  ),
  Th = styled.th(
    () => css`
      padding: 12px;
      text-align: left;
    `
  ),
  Td = styled.td(
    () => css`
      padding: 12px;
      border-top: 1px solid #3c3c4e;

      &:first-child {
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      &:last-child {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    `
  ),
  Tr = styled.tr(
    () => css`
      background-color: #36373e;

      &:not(:last-child) {
        margin-bottom: 10px;
      }

      &:hover {
        background-color: #435185;
      }
    `
  );
