import { createContext, useContext } from 'react';
import { IAnalyticsCourse } from 'store';

type SpaceAnalyticsContextType = {
  spaceId: string;
  type?: 'quest' | 'course';
  setType: (type: 'quest' | 'course' | undefined) => void;
  isLoading: boolean;
  courses: IAnalyticsCourse[];
  displayCourses: IAnalyticsCourse[];
};

export const SpaceAnalyticsContext = createContext<SpaceAnalyticsContextType>({
  spaceId: '',
  type: undefined,
  setType: () => {},
  isLoading: true,
  courses: [],
  displayCourses: [],
});

export const useSpaceAnalyticsContext = () => useContext(SpaceAnalyticsContext);
