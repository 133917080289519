import { ReactComponent as HeartIcon } from './assets/icons/Heart.svg';
import { ReactComponent as AnalyticsIcon } from './assets/icons/Analytics.svg';
import { ReactComponent as CodeIcon } from './assets/icons/Code.svg';
import { ReactComponent as CompassIcon } from './assets/icons/Compass.svg';
import { ReactComponent as InfoIcon } from './assets/icons/Info.svg';
import { ReactComponent as ChartBoxIcon } from './assets/icons/ChartBox.svg';
import { ReactComponent as CheckBoxIcon } from './assets/icons/CheckBox.svg';
import { ReactComponent as GamepadIcon } from './assets/icons/Gamepad.svg';
import { ReactComponent as GauntletIcon } from './assets/icons/Gauntlet.svg';
import { ReactComponent as LockIcon } from './assets/icons/lock.svg';
import { ReactComponent as MessageIcon } from './assets/icons/Message.svg';
import { ReactComponent as MicrophoneIcon } from './assets/icons/Microphone.svg';
import { ReactComponent as PasswordIcon } from './assets/icons/Password.svg';
import { ReactComponent as PictureIcon } from './assets/icons/Picture.svg';
import { ReactComponent as PlanetIcon } from './assets/icons/Planet.svg';
import { ReactComponent as StarIcon } from './assets/icons/Star.svg';
import { ReactComponent as TimerIcon } from './assets/icons/Timer.svg';
import { ReactComponent as WalletIcon } from './assets/icons/Wallet.svg';
import { ReactComponent as QuoteIcon } from './assets/icons/quote.svg';
import discordPng from './assets/socials/discord.png';
import { ReactComponent as FacebookIcon } from './assets/socials/facebook.svg';
import { ReactComponent as InstagramIcon } from './assets/socials/instagram.svg';
import { ReactComponent as TelegramIcon } from './assets/socials/telegram.svg';
import { ReactComponent as MailIcon } from './assets/socials/mail.svg';
import { ReactComponent as TwiiterIcon } from './assets/socials/twitter.svg';
import { ReactComponent as WebsiteIcon } from './assets/socials/website.svg';
import { ReactComponent as YoutubeIcon } from './assets/socials/youtube.svg';
// on-chain
import { ReactComponent as TONIcon } from 'assets/icons/tasks/on-chain/TON.svg';
import { EIcons, ESpecialIcons } from './constants';

type Props = {
  type: EIcons | ESpecialIcons;
};

export const MENU_ICONS = {
  [EIcons.ANALYTICS]: <AnalyticsIcon />,
  [EIcons.CHARTBOX]: <ChartBoxIcon />,
  [EIcons.CHECKBOX]: <CheckBoxIcon />,
  [EIcons.CODE]: <CodeIcon />,
  [EIcons.COMPASS]: <CompassIcon />,
  [EIcons.GAMEPAD]: <GamepadIcon />,
  [EIcons.GAUNTLET]: <GauntletIcon />,
  [EIcons.HEART]: <HeartIcon />,
  [EIcons.INFO]: <InfoIcon />,
  [EIcons.LOCK]: <LockIcon />,
  [EIcons.MESSAGE]: <MessageIcon />,
  [EIcons.MICROPHONE]: <MicrophoneIcon />,
  [EIcons.PASSWORD]: <PasswordIcon />,
  [EIcons.PICTURE]: <PictureIcon />,
  [EIcons.PLANET]: <PlanetIcon />,
  [EIcons.STAR]: <StarIcon />,
  [EIcons.TIMER]: <TimerIcon />,
  [EIcons.WALLET]: <WalletIcon />,
  [EIcons.QUOTE]: <QuoteIcon />,
  [ESpecialIcons.YOUTUBE]: <YoutubeIcon />,
  [ESpecialIcons.DISCORD]: <img src={discordPng} alt="discord" />,
  [ESpecialIcons.FACEBOOK]: <FacebookIcon />,
  [ESpecialIcons.INSTAGRAM]: <InstagramIcon />,
  [ESpecialIcons.MAIL]: <MailIcon />,
  [ESpecialIcons.TELEGRAM]: <TelegramIcon />,
  [ESpecialIcons.TWITTER]: <TwiiterIcon />,
  [ESpecialIcons.WEBSITE]: <WebsiteIcon />,
  // on-chain
  [ESpecialIcons.TON]: <TONIcon />,
};

export const MenuIcons = ({ type }: Props) => {
  if (type) {
    return MENU_ICONS[type];
  }
  return <InfoIcon />;
};
