import { DetailedHTMLProps, InputHTMLAttributes } from 'react';
import styles from './styles.module.css';
import clsx from 'clsx';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
  containerClassName?: string;
  isError?: boolean;
}

export const Checkbox = ({ label, containerClassName = '', isError, ...rest }: IProps) => {
  return (
    <div
      className={clsx(styles.container, {
        [containerClassName]: !!containerClassName,
        [styles.error]: isError,
      })}
    >
      <input className={styles.checkbox} {...rest} type="checkbox" id="custom-checkbox" />
      <label className={styles.checkboxLabel} htmlFor="custom-checkbox" />
      <span className={styles.text}>{label}</span>
    </div>
  );
};
