import * as Elements from './styles';
import SocialTask from 'components/library/socialTask';
import { EIcons, ESpecialIcons } from './NewCustomizationMenu/constants';

export type TTaskWidgetProps = {
  icon?: EIcons | ESpecialIcons;
  color?: string;
  content?: string;
  title?: string;
  link?: string;
  editable?: boolean;
  onChange?: (arg: Partial<TTaskWidgetProps>) => void;
  onTaskComplete?: () => Promise<void> | void;
  isRequired?: boolean;
};

const TaskWidget = ({ color, content, icon, title, link, editable = true, onChange, isRequired, onTaskComplete }: TTaskWidgetProps) => {
  return (
    <Elements.SRoot className={'root-transition'}>
      <SocialTask
        onTaskComplete={onTaskComplete}
        onChange={onChange}
        color={color}
        content={content}
        icon={icon}
        title={title}
        link={link}
        editable={editable}
        isRequired={isRequired}
      />
    </Elements.SRoot>
  );
};

export default TaskWidget;
