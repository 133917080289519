import { FC, useCallback, useEffect, useState } from 'react';
import * as yup from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { motion, AnimatePresence } from 'framer-motion';

import { TChapterCardProps } from './types';
import TransparentTextarea from 'components/library/transparentTextarea';
import BasicButton from 'shared/ui/basic-button/button';
import InfoBadge from 'components/library/infoBadge';
import { EInfoBadgeTypes } from 'components/library/infoBadge/types';
import CoursesConstructorLessonsList from 'pages/coursesConstructor/parts/lessonsList';
import { BsChevronDown } from 'react-icons/bs';

import * as S from './styles';
import LockLabel from '../lock';
import { useGetCurrentTheme } from 'hooks';
import { THEMES } from 'app/constants';

type TChapterCardPropsOmit = Omit<TChapterCardProps, 'onDelete' | 'onRewardsShow' | 'onToggleLockChapter'>;

export const ChapterCard: FC<TChapterCardPropsOmit> = ({
  name,
  isInEditMode,
  id,
  index,
  isLoading,
  courseId,
  dependencies = [],
  onChange,
  onIsInEditModeChange,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const theme = useGetCurrentTheme();
  const schema = yup.object().shape({
    name: yup.string().min(2, 'Invalid min value').max(50, 'Invalid max value').required(),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset: resetForm,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    values: { name },
  });

  useEffect(() => {
    if (!isInEditMode) {
      resetForm();
    }
  }, [isInEditMode, resetForm]);

  const handleCollapse = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsCollapsed(!isCollapsed);
    },
    [isCollapsed]
  );

  return (
    <S.Wrapper initial={{ height: 0 }} animate={{ height: 'auto' }} exit={{ height: 0 }} transition={{ duration: 0.3 }}>
      <S.Root $justifyContent="space-between" $flexDirection="column">
        <S.Body $justifyContent="space-between" $flexDirection={'column'}>
          <S.Name>
            <LockLabel count={dependencies.length} />
            {'Chapter ' + (index + 1)}
          </S.Name>
          <S.Description className="tiny-sb" $isEdit={isInEditMode} onSubmit={handleSubmit(onChange)} id={`form:${id}`}>
            {isInEditMode ? (
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TransparentTextarea {...field} autoFocus minLength={3} maxLength={50} placeholder="Enter a new chapter name..." />
                )}
              />
            ) : (
              <S.ChapterName>{name}</S.ChapterName>
            )}
          </S.Description>
          {!isInEditMode && <S.CircleArrowButton strokeWidth={1.5} as={BsChevronDown} $isOpen={!isCollapsed} onClick={handleCollapse} />}
        </S.Body>
        {isInEditMode && (
          <S.Controls $justifyContent="flex-end">
            <BasicButton size="sm" onClick={onIsInEditModeChange} style={{color: theme === THEMES.LIGHT ? '#111' : undefined}} variant="outline" label="Cancel" />
            <BasicButton isDisabled={!isValid || isLoading} type="submit" form={`form:${id}`} size="sm" variant="cool" label="Save" />
          </S.Controls>
        )}
      </S.Root>

      {Boolean(Object.keys(errors).length) && (
        <S.ErrorMessage>
          {Object.keys(errors).map((key) => (
            <S.WarningBadge key={key} type={EInfoBadgeTypes.WARNING} as={InfoBadge}>
              &nbsp;{(errors as any)[key].message}
            </S.WarningBadge>
          ))}
        </S.ErrorMessage>
      )}
      <AnimatePresence>
        {!isCollapsed && (
          <motion.div
            key="content"
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            <CoursesConstructorLessonsList chapterIdProp={id} courseId={courseId} />
          </motion.div>
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
};
