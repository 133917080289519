import styled, { css } from 'styled-components';
import bgLight from './assets/bg-light.svg';
import bg from './assets/bg.svg';

export const Container = styled.div(
    () => css`
      border-radius: 10px;
      width: 560px;
      @media screen and (max-width: 992px) {
        width: 100%;
      }
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-align: center;
      padding: 45px 23px 58px;
      background: url(${bg}) no-repeat;
      background-position: 50% 40%;
      &.light {
        background-image: url(${bgLight});
      }

      button {
        width: 80%;
      }
    `
  ),
  Header = styled.h4(
    () => css`
      margin-bottom: 10px;
      font-weight: 500;
      font-size: 35px;
      line-height: 119%;
      color: var(--font-color-strong);
    `
  ),
  Text = styled.p(
    () => css`
      line-height: 125%;
      width: 80%;
      color: var(--font-color-strong);
      letter-spacing: 0.5px;
    `
  ),
  RewardsWrap = styled.div(
    () => css`
      margin-bottom: 26px;
      height: 261px;
      display: flex;
      align-items: center;
      justify-content: center;
    `
  ),
  Rewards = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: wrap;
      width: 260px;
      gap: 14px;
    `
  );
