import styled, { css } from 'styled-components';

export const SFlexColumn = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 8px;
  `
);

export const SSideButtonsRoot = styled.div(
  () => css`
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 50px;
  `
);
