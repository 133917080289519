import * as Styled from './styles';
import clsx from 'clsx';

type Props = {
  completion: number;
  className?: string;
  labelType?: 'small' | 'big' | 'none';
  height?: string;
  bgContainer?: string;
  bgBar?: string;
};

const SmallProgressBar = ({ className = '', completion = 0, labelType = 'none', height, bgContainer, bgBar }: Props) => {
  return (
    <Styled.Container className={clsx(labelType, { [className]: !!className })}>
      <Styled.BarContainer className={labelType} $height={height} $background={bgContainer}>
        <Styled.Bar style={{ width: `${completion + 2}%` }} $height={height} $background={bgBar}></Styled.Bar>
      </Styled.BarContainer>
      <Styled.Label className={labelType}>Progress</Styled.Label>
      {labelType !== 'none' && <Styled.Numbers className={labelType}>{Math.ceil(completion)}%</Styled.Numbers>}
    </Styled.Container>
  );
};

export default SmallProgressBar;
