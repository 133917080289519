import { Modal } from 'shared/ui';
import styles from './styles.module.css';
import { InventoryInfoItem } from './InventoryInfoItem/InventoryInfoItem';
import { GRADES } from 'app/constants';

interface IInventoryInfoModalProps {
  isOpen: boolean;
  onCloseModalHandler: () => void;
}

export enum INVENTORY {
  common = 1,
  uncommon = 2,
  rare = 3,
  epic = 4,
  legendary = 5,
  mythical = 6,
}

const inventory = [
  { itemId: INVENTORY.common, label: GRADES.COMMON },
  { itemId: INVENTORY.uncommon, label: GRADES.UNCOMMON },
  { itemId: INVENTORY.rare, label: GRADES.RARE },
  { itemId: INVENTORY.epic, label: GRADES.EPIC },
  { itemId: INVENTORY.legendary, label: GRADES.LEGENDARY },
  { itemId: INVENTORY.mythical, label: GRADES.MYTHICAL },
];

export const InventoryInfoModal = ({ isOpen, onCloseModalHandler }: IInventoryInfoModalProps) => {
  return (
    <Modal open={isOpen} onClose={onCloseModalHandler}>
      <div className={styles.container}>
        <div className={styles.title}>Item grade rankings</div>
        <div className={styles.subTitle}>Here are all possible item grades in the mighty universe</div>
        <div>
          {inventory.map(({ itemId, label }) => {
            return <InventoryInfoItem key={itemId} itemId={itemId} label={label} />;
          })}
        </div>
      </div>
    </Modal>
  );
};
