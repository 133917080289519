import AvatarPic from '../../../../../library/utils/AvatarPic/avatar';

import { CgClose } from 'react-icons/cg';
import styles from './styles.module.css';
import BasicButton from 'shared/ui/basic-button/button';

export default function AddConfirm({ name, avatar, accept, close, isLoading }) {
  const theme = localStorage.getItem('theme');

  return (
    <div className={`glass-div basic ${theme} friend-finder_conf --confirm`}>
      <p id="header">Add as a friend?</p>
      <div className={styles.friend}>
        <AvatarPic className={styles.avatar} isLarge nickname={name} avatar={avatar} />
        <p>{name}</p>
      </div>
      <BasicButton loading={isLoading} isDisabled={isLoading} variant="cool" label="Add as a friend" className={styles.button} onClick={accept} />
      <CgClose strokeWidth={0.1} className={styles.close} role={'button'} onClick={close} />
    </div>
  );
}
