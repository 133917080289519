import styled from 'styled-components';

export const Wrap = styled.div`
    transition: all 350ms ease-in-out;

    font-size: 2rem;

    position: relative;
    top: 0;

    display: flex;
    flex-direction: column;

    min-width: 111px;
    width: 204px;
    height: 688px;

    margin-right: 95px;

    & > svg {
      fill: var(--transbg-color);
      stroke: ${({ theme }) => theme.theme.border};
    }

    &.closed {
      width: 111px;

      .sidebar-label {
        width: 0;
      }

      .menu-large {
        & div,
        & span,
        & a {
          margin-left: 4px;
        }
      }
    }

    transform: translateX(0);

    @media screen and (max-width: 992px) {
      display: none;
      position: absolute;
      top: 70px;
      &.closed {
        transform: translateX(-100%);
      }
    }

    z-index: 10;

    & .arrow {
      stroke: ${({ theme }) => theme.theme.fontColor};
    }
  `,
  SidebarNavWrap = styled.div`
    transition: all 350ms ease-in-out;
    display: flex;
    justify-content: flex-start;
    border-radius: 0;

    width: 100%;
    height: 100%;

    border-right: ${({ theme }) => (theme.theme.dark ? '1px solid var(--border-color)' : '')};
  `,
  SidebarNav = styled.div`
    transition: all 350ms ease-in-out;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    z-index: 10;

    box-shadow: none;
    opacity: 1;
  `,
  MenuLarge = styled.div`
    transition: all 950ms ease-in-out;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 37px;

    width: 100%;

    margin-bottom: 30px;
    padding: 0 32px;
  `;
