import { facebookShareLink, instagramShareLink, telegramShareLink, twitterShareLinkPost, useMiniAppStoryMethods } from '../../utils';
import SocialsSubMenu from './subMenu';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  refLink: string;
  shareText: string;
};

export const TelegramSubMenu = ({ isOpen, onClose, refLink, shareText }: Props) => {
  const { isInMiniApp, shareStory, goToMiniApp } = useMiniAppStoryMethods(refLink, shareText);
  let content: any[] = [];

  if (isInMiniApp) {
    content.push({
      title: 'Post a Story',
      sub: 'Add the referral link to your story and engage more people',
      buttonTitle: 'Share to Telegram Stories',
      onClick: shareStory,
    });
  } else {
    content.push({
      title: 'Visit Mighty Mini App',
      sub: 'You can only share telegram stories only inside our Telegram Mini App',
      buttonTitle: 'Share to Telegram Stories',
      onClick: goToMiniApp,
    });
  }

  content.push({
    title: 'Send a message',
    sub: 'Send a message to your friends or followers',
    buttonTitle: 'Send Message',
    onClick: telegramShareLink(refLink, shareText),
  });

  return <SocialsSubMenu isOpen={isOpen} onClose={onClose} type="telegram" content={content} />;
};

export const InstagramSubMenu = ({ isOpen, onClose, refLink, shareText }: Props) => {
  return (
    <SocialsSubMenu
      isOpen={isOpen}
      onClose={onClose}
      type="instagram"
      content={[
        // {
        //   title: 'Make a Post',
        //   sub: 'Add the referral link to your post and engage more people',
        //   buttonTitle: 'Post on Instagram',
        //   onClick: () => {},
        // },
        // {
        //   title: 'Post a Story',
        //   sub: 'Add the referral link to your story and engage more people',
        //   buttonTitle: 'Share to Instagram Stories',
        //   onClick: () => {},
        // },
        {
          title: 'Send a message',
          sub: 'Send a message to your friends or followers',
          buttonTitle: 'Send Message',
          onClick: instagramShareLink(refLink, shareText),
        },
      ]}
    />
  );
};

export const XSubMenu = ({ isOpen, onClose, refLink, shareText }: Props) => {
  return (
    <SocialsSubMenu
      isOpen={isOpen}
      onClose={onClose}
      type="x"
      content={[
        {
          title: 'Make a Post',
          sub: 'Add the referral link to your post and engage more people',
          buttonTitle: 'Post on Twitter/X',
          onClick: twitterShareLinkPost(refLink, shareText),
        },
        // {
        //   title: 'Send a message',
        //   sub: 'Send a message to your friends or followers',
        //   buttonTitle: 'Send Message',
        //   onClick: twitterShareLinkMessage(refLink, shareText),
        // },
      ]}
    />
  );
};

export const FacebookSubMenu = ({ isOpen, onClose, refLink, shareText }: Props) => {
  return (
    <SocialsSubMenu
      isOpen={isOpen}
      onClose={onClose}
      type="facebook"
      content={[
        // {
        //   title: 'Make a Post',
        //   sub: 'Add the referral link to your post and engage more people',
        //   buttonTitle: 'Post on Facebook',
        //   onClick: () => {},
        // },
        // {
        //   title: 'Post a Story',
        //   sub: 'Add the referral link to your story and engage more people',
        //   buttonTitle: 'Share to Facebook Stories',
        //   onClick: () => {},
        // },
        {
          title: 'Send a message',
          sub: 'Send a message to your friends or followers',
          buttonTitle: 'Send Message',
          onClick: facebookShareLink(refLink, shareText),
        },
      ]}
    />
  );
};

export const DiscordSubMenu = ({ isOpen, onClose, refLink, shareText }: Props) => {
  return (
    <SocialsSubMenu
      isOpen={isOpen}
      onClose={onClose}
      type="discord"
      content={[
        {
          title: 'Send a message',
          sub: 'Send a message to your friends or followers',
          buttonTitle: 'Send Message',
          onClick: () => {},
        },
      ]}
    />
  );
};
