import { MouseEvent, useMemo } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getIsAuth, setShopItem } from 'store';
import { EBlockchain, EUrlTypeSource, EUrlType } from 'app/constants';

import { LazyImage } from 'shared/ui/lazyImage';
import { dispatch, useDisplay, useNavigateToAuth } from 'hooks';

import { IShopItem } from 'interface';

import styles from './styles.module.css';
import { iconsUrls } from 'shared/utils';

interface IProps {
  item: IShopItem;
  onClick: () => void;
}

export default function PartnerItem({ item, onClick }: IProps) {
  const theme = useSelector(getCurrentTheme);
  const isAuth = useSelector(getIsAuth);
  const navToAuth = useNavigateToAuth();
  const iconsUrl = item.item.blockchain ? iconsUrls[item.item.blockchain] : null;
  const {
    price,
    item: { imageThumb, name },
  } = item;

  const { isMobile } = useDisplay();

  function handleClick(e: MouseEvent<HTMLDivElement>) {
    e.stopPropagation();
    if (!isAuth) {
      navToAuth();
    }

    if (typeof onClick === 'undefined') {
      return dispatch(setShopItem({ itemId: item.item.id, type: 'item' }));
    }
    onClick();
  }

  const urlType = useMemo(() => {
    if (item.item.url.includes(EUrlTypeSource.SteamGame)) {
      return EUrlType.SteamGame;
    }
    return '???';
  }, [item.item.url]);

  return (
    <div className={clsx(styles.container, styles[theme])} onClick={handleClick}>
      <div className={clsx(styles.imgarea)}>
        <LazyImage className={styles.img} src={imageThumb} alt="partner item" />
        {!isMobile && (
          <>
            {iconsUrl && item.item.blockchain && (
              <img
                src={iconsUrl}
                alt={item.item.blockchain ?? 'blockchain'}
                className={clsx(styles.network, {
                  [styles.nobg]: ![EBlockchain.BNB, EBlockchain.Avalanche].includes(item.item.blockchain),
                })}
              />
            )}
          </>
        )}
      </div>
      <div className={styles.body}>
        {!isMobile && (
          <div className={styles.head}>
            <p className={styles.urlType}>{urlType}</p>
            <h4 className={styles.header}>{name}</h4>
          </div>
        )}
        <p className={styles.price}>{price} EXP</p>
      </div>
    </div>
  );
}
