import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { extractHTMLFromJsonDOM } from '../../../../../../../../utils/contenteditable';
import { formatAnswers } from '../utils/formatAnswers';
import styles from './styles.module.css';
import clsx from 'clsx';

type TNodeViewProps = {
  rootNode: string;
  onAnswerChange: (answerName: string, value: string) => void;
  right?: null | boolean;
  updateRight?: (param: null | boolean) => void;
  className?: string;
};

const MIN_SIZE = 5;

export const NodesView: FC<TNodeViewProps> = ({ rootNode, onAnswerChange, right, updateRight, className = '' }) => {
  const $containerRef = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);

  const handleInputChange = useCallback((evt: Event) => {
    const target = evt.target as HTMLInputElement;
    const idx = target.getAttribute('data-answer-idx');
    if (!idx) {
      return;
    }

    if (right !== undefined && updateRight) {
      if (right !== null) {
        updateRight(null);
      }
    }

    target.value.length > 0 ? target.classList.add(styles.filled) : (target.className = '');
    console.log(target.style);

    target.size = Boolean(target.value.length) ? target.value.length : MIN_SIZE;

    onAnswerChange(idx, target.value);
  }, []);

  useEffect(() => {
    if (!$containerRef.current || initialized) {
      return;
    }

    const DOMNode = extractHTMLFromJsonDOM(rootNode);
    if (!DOMNode) {
      return;
    }
    const formattedDOMNode = formatAnswers(DOMNode);
    $containerRef.current.innerHTML = formattedDOMNode.innerHTML;

    setInitialized(true);
  }, [rootNode]);

  useEffect(() => {
    if (!initialized || !$containerRef.current) {
      return;
    }

    const inputs = document.querySelectorAll('[data-type="mighty-answer-field"]');
    inputs.forEach((input: any, index) => {
      input.addEventListener('input', handleInputChange);
      input.size = MIN_SIZE;
    });

    return () => {
      inputs.forEach((input) => {
        input.removeEventListener('input', handleInputChange);
      });
    };
  }, [initialized]);

  useEffect(() => {
    if (!initialized || !$containerRef.current) {
      return;
    }
    const inputs = document.querySelectorAll('[data-type="mighty-answer-field"]') as NodeListOf<HTMLInputElement>;
    inputs.forEach((item) => {
      item.classList.add(styles['right-' + right]);
      item.value.length > 0 ? item.classList.add(styles.filled) : (item.className = '');
    });

    return () => {
      inputs.forEach((item) => {
        item.className = '';
      });
    };
  }, [right, initialized]);

  return (
    <div>
      <div
        ref={$containerRef}
        contentEditable="false"
        className={clsx('nodes-container', styles.container, styles['right-' + right], { [className]: !!className })}
      ></div>
    </div>
  );
};
