import styled, { css } from 'styled-components';

export const SControlWrapper = styled.div<{ $isOpen: boolean; $larger: boolean }>`
  transition: all 0.2s ease-in-out;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: -86px;
  border-radius: 20px;

  ${({ $isOpen }) =>
    $isOpen &&
    css`
      padding-left: 18px;
      width: 130px;
      background-color: rgba(255, 255, 255, 0.15);
    `}

  .play-button {
    min-width: ${({ $larger }) => ($larger ? '48px' : '44px')} !important;
    min-height: ${({ $larger }) => ($larger ? '48px' : '44px')} !important;
    width: ${({ $larger }) => ($larger ? '48px' : '44px')} !important;
    height: ${({ $larger }) => ($larger ? '48px' : '44px')} !important;
  }
`;
