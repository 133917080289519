import { ReactElement } from 'react';
import ReactDom from 'react-dom';
import clsx from 'clsx';

import { TbList } from 'react-icons/tb';
// import { ReactComponent as Top } from './assets/top.svg';
// import { ReactComponent as Bottom } from './assets/bottom.svg';
import { ReactComponent as CrossIcon } from 'assets/components/cross.svg';

import { getCurrentTheme, getIsMainMenu } from 'store';
import { useSelector } from 'react-redux';
import { useDisplay } from 'hooks';

import styles from './styles.module.scss';
import { HTMLMotionProps, motion } from 'framer-motion';

interface IProps extends HTMLMotionProps<'div'> {
  isShown: boolean;
  isOpener?: boolean;
  onClose: () => void;
  children: ReactElement;
  classNames?: string;
}

const Sidepanel = ({ isShown = false, onClose, children, isOpener = false, classNames = '', ...props }: IProps) => {
  //todo sidepanel is deprecated

  const theme = useSelector(getCurrentTheme);
  const isMainMenu = useSelector(getIsMainMenu);
  const { isMobile } = useDisplay();

  return ReactDom.createPortal(
    !isMobile && (
      <motion.div
        {...props}
        className={clsx(`${styles.container} ${isMainMenu && 'main'}`, styles[theme], { [classNames]: !!classNames, [styles.open]: isShown })}
      >
        {isOpener && (
          <div role="button" onClick={onClose} className={clsx(styles.sidebaron, 'glass-div bright--hover', { [styles.open]: isShown })}>
            <TbList size={28} style={{ transform: 'rotate(180deg)' }} strokeWidth={1.5} />
          </div>
        )}

        <div className={styles.body}>
          <div role="button" onClick={onClose} className={`${styles.sidebaroff} glass-div bright--hover`}>
            <CrossIcon />
          </div>

          {/*<Top className={styles.rounder} />*/}
          <div className={styles.content}>{children}</div>
          {/*<Bottom className={styles.rounder} />*/}
        </div>
      </motion.div>
    ),
    document.getElementById('portal')!
  );
};

export default Sidepanel;
