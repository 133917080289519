import { FC } from 'react';

import * as Styled from './styles';
import { TTabProps } from './types';

const Tab: FC<TTabProps> = ({ tab, active, onClick }) => {
  return (
    <Styled.SRoot active={active} onClick={onClick}>
      {tab.title}
    </Styled.SRoot>
  );
};

export default Tab;
