import { GRADES, GRADES_COLORS } from 'app/constants';
import { TGrade } from 'interface';
import { Flex, MotionFlex } from 'shared/ui';
import styled, { keyframes } from 'styled-components';

export const Container = styled(MotionFlex)`
  position: absolute;
  left: calc(100% + 50px);
  bottom: 23px;
  gap: 8px;
  cursor: pointer;
  flex-direction: column;
  align-items: center;
  & .animation-before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 155%;
    height: 155%;
    pointer-events: none;
    z-index: 1;
    transform: translate(-50%, -50%);
  }

  & .animation-claiming {
    position: absolute;
    bottom: -98%;
    width: 235%;
    pointer-events: none;
  }

  &.inTask {
    right: 0 !important;
    left: auto !important;
    top: 50% !important;
    bottom: auto !important;
    transform: translate(50%, -50%) !important;
    position: absolute !important;
  }

  @media screen and (max-width: 992px) {
    flex-direction: row-reverse;
    width: 100%;
    justify-content: space-between;
    left: auto;
    bottom: auto;
    margin-top: -48px;
    position: sticky;
    right: 0;
    transform: translateX(8px);
    bottom: 80px;
    top: 20px;
  }
`;

type TGradeProp = {
  $grade: GRADES | TGrade;
}; 

export const Title = styled.p<TGradeProp>`
  font-size: 16px;
  line-height: 19px;
  color: ${({ $grade }) => GRADES_COLORS[$grade]} !important;
  text-align: center;
  white-space: nowrap;
  @media screen and (max-width: 992px) {
    font-size: 18px;
    text-shadow: 0px 0px 18.432px ${({ $grade }) => GRADES_COLORS[$grade]};
    display: none;
  }
`;

export const Frame = styled(Flex)<TGradeProp>`
  width: 48px;
  height: 48px;
  border-radius: 50px;
  border: 2px solid;
  border-color: ${({ $grade }) => GRADES_COLORS[$grade]};
  background: rgba(255, 255, 255, 0.1);
  position: relative;
`;

export const Light = styled.div<TGradeProp>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 56px;
  height: 56px;
  border-radius: 50%;
  filter: blur(30px);
  background: ${({ $grade }) => GRADES_COLORS[$grade]};
  z-index: -1;
  opacity: 0.8;
`;

export const RewImages = styled(Flex)``;

const rewardAnimation = keyframes`
  0% {
    opacity: 1;
  }
  15% {
    transform: translateX(20%);
  }
  30% {
    transform: translateX(120%);
  }
  35% {
    opacity: 1;
    scale: 1;
  }
  41%, 100%{
    transform: translateX(80%);
    opacity: 0;
    scale: 0;
  }

`;

const rewBoxAnimation = keyframes`
  20% {
    transform: translateY(160%);
  }
  35%, 100% {
    transform: translateY(-160%);
  }
`;

export const RewAnimatedBox = styled.div<{ $delay: string }>`
  display: none;
  &:not(:first-child) {
    margin-left: -47px;
  }
  &.animated {
    display: block;
    animation: ${rewBoxAnimation} 2s ease-in;
    animation-delay: ${({ $delay }) => $delay};
    /* animation-timing-function: ease-in; */
  }
`;

export const RewImg = styled.img<{ $delay: string }>`
  height: 37px;
  width: 37px;
  &.animated {
    opacity: 0;
    animation: ${rewardAnimation} 2s ease-out;
    animation-delay: ${({ $delay }) => $delay};
    /* animation-timing-function: ease-out; */
    &:not(:first-child) {
      margin-left: -37px;
    }
  }
  &.hidden {
    opacity: 0;
  }
  &:not(:first-child) {
    margin-left: -20px;
  }
  &:nth-child(1) {
    z-index: 1;
  }
  &:nth-child(2) {
    z-index: 0;
  }
  &:nth-child(3) {
    z-index: -1;
  }
`;

const avatarAnimation = keyframes`
  0% {
    scale: 0;
  }
  12% {
    scale: 1.7;
  }
  35% {
    scale: 1;
  }
  50% {
    scale: 1.5;
  }
  100% {
    scale: 0;
  }

`;

export const AvatarBox = styled.div`
  position: absolute;
  scale: 0;
  width: 32px;
  height: 32px;
  z-index: 20;
  bottom: 152%;
  left: 28%;
  /* background: #00000050; */
  & > div {
    width: 100% !important;
    height: 100% !important;
    min-width: 100% !important;
    min-height: 100% !important;
    /* opacity: 0.5; */
  }

  &.animated {
    animation: ${avatarAnimation} 2s ease-in-out;
  }
`;
