import { DetailedHTMLProps, InputHTMLAttributes, forwardRef } from 'react';
import * as Styled from './styles';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

export interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  classNameContainer?: string;
  isError?: boolean;
}

export const Input = forwardRef<HTMLInputElement, IProps>(({ classNameContainer = '', isError, ...props }, ref) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <Styled.Container className={clsx({ [classNameContainer]: !!classNameContainer, error: isError }, theme)}>
      <Styled.Input {...props} ref={ref} />
    </Styled.Container>
  );
});
