import clsx from 'clsx';
import { useGroupImage } from '../../useGroupImage';
import { MouseEventHandler, useRef } from 'react';
import { ICourseFilteringGroup } from '../../../hooks';

import { useAverageColor, useDisplay } from 'hooks';

import * as S from './styles';

type Props = {
  group: ICourseFilteringGroup;
  isOpen: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
  className?: string;
};

export const GroupCardToggle = ({ isOpen, group, onClick, className = '' }: Props) => {
  const image = useGroupImage(group.native);
  const { isMobile } = useDisplay();
  const imageRef = useRef<HTMLImageElement>(null);
  const baseColor = useAverageColor(imageRef);
  const color = baseColor.replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1 0.1)');
  const borderColor = baseColor.replace(/(rgba\(\d+,\d+,\d+,).*\)/, '$1 0.5)');

  return (
    <S.Container role="button" onClick={onClick} className={clsx({ [className]: !!className, mobile: isMobile, open: isOpen })}>
      <S.ImageContainer>
        <S.Image src={image} ref={imageRef} />
      </S.ImageContainer>
      <S.TitleWrap>
        <S.Title>{group.label}</S.Title>
        <S.BG style={{ background: color, border: isOpen ? `2px solid ${borderColor}` : '' }} />
      </S.TitleWrap>
    </S.Container>
  );
};
