import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getUserData, setCurrentTheme } from 'store';
import { dispatch, useDisplay, useGetUserData, useGetCurrentTheme, useGetPartnerId } from 'hooks';
import { ROUTES, THEMES, USER_ROLES } from 'app/constants';
import { VolumeTextToSpeech } from 'features/TextToSpeech/ui/VolumeTextToSpeech';
import { SpeechTextToSpeech } from 'features/TextToSpeech/ui/SpeechTextToSpeech';
import type { TSideButtonsProp } from '../../model';
import { useParams } from 'react-router-dom';

import { ReactComponent as Sun } from 'assets/icons/sunCircle.svg';
import { ReactComponent as Marker } from 'assets/icons/marker.svg';
import { ReactComponent as Moon } from 'assets/icons/toggler-icons/dark.svg';

import { CircleLink, CircleButton } from 'shared/ui/circle-button';
import { Effects } from '../../../effects';

import { SFlexColumn, SSideButtonsRoot } from './styles';
import { DisableInSafari } from 'features/DisableInSafari';
import { TbList } from 'react-icons/tb';

export const SideButtons: FC<TSideButtonsProp> = ({ course, currentSlideId, preparedLink, menuState, setMenuState }) => {
  const theme = useSelector(getCurrentTheme);
  const { lessonId = '' } = useParams();
  const { roles } = useGetUserData();
  const [page, setPage] = useState('default');
  const { isMobile, viewportWidth } = useDisplay();
  const partnerId = useGetPartnerId();

  const isAdmin = roles?.includes(USER_ROLES.ADMIN);

  const toggleTheme = () => dispatch(setCurrentTheme(theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK));

  const handleSetPage = (value: 'toggle' | 'default') => () => setPage(value);

  const toggleLessonProgress = () => {
    if (!!setMenuState) {
      if (menuState === null) {
        setMenuState('progress');
      } else {
        setMenuState(null);
      }
    }
  };

  const linkRaw = `${window.location.origin}${ROUTES.COURSES_EDITOR_EDIT_LESSON.replace(':lessonId', lessonId)}`;
  const link = lessonId ? new URL(linkRaw, window.location.origin) : new URL('google.com', window.location.origin);

  return (
    <SSideButtonsRoot>
      <SFlexColumn>
        <DisableInSafari>
          {/* <SpeechTextToSpeech /> */}
          <VolumeTextToSpeech />
        </DisableInSafari>
        {!isMobile && viewportWidth < 1351 && (
          <CircleButton onClick={toggleLessonProgress} icon={<TbList size={28} style={{ transform: 'rotate(180deg)' }} strokeWidth={1.5} />} />
        )}
        {!partnerId && <CircleButton icon={theme === 'dark' ? <Sun /> : <Moon stroke={'var(--font-color)'} />} onClick={toggleTheme} />}
        {isAdmin && <CircleLink target="_blank" rel="noopener noreferrer" icon={<Marker />} href={preparedLink ?? link.toString()} />}
        <Effects />
      </SFlexColumn>
    </SSideButtonsRoot>
  );
};
