import { FC, Fragment, memo, useEffect, useRef } from 'react';
import { IDrawerProps } from './types';
import { SButton, SDrawer, SDrawerHead, SDrawerOverlay } from './styles';
import { AnimatePresence, motion } from 'framer-motion';
import { createPortal } from 'react-dom';
import { CustomScroll } from '../custom-scroll';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';
import { useDrawer } from './useDrawer';

import { ReactComponent as BackIcon } from './assets/back-icon.svg';
import { ReactComponent as FullPageIcon } from './assets/full-window.svg';
import { TooltipSmall } from '../tooltip';
import './tooltip-styles.css';

const Drawer: FC<IDrawerProps> = ({
  className = '',
  classNameOverlay = '',
  isVisible = false,
  position,
  children,
  secondIconNode,
  triggerNode,
  withOverlay = true,
  width,
  onClose,
  showHead = true,
  variant = 'default',
  delay,
  triggerValue,
  onToggleFullPage,
}) => {
  const theme = useSelector(getCurrentTheme);
  const $portalRef = useRef(document.getElementById('drawer-portal') as HTMLDivElement);
  const $bodyRef = useRef(document.querySelector('body') as HTMLBodyElement);
  const { isOpen, handleClose, setIsOpen } = useDrawer({ $portalRef, $bodyRef, isVisible, onClose });

  useEffect(() => {
    if (!!triggerValue) {
      setIsOpen(true);
    }
  }, [triggerValue]);

  const currentPosition = () => {
    if (position === 'left') {
      return {
        initial: { x: '-100%' },
        animate: { x: '0' },
        exit: { x: '-100%' },
      };
    }
    if (position === 'right') {
      return {
        initial: { x: '100%' },
        animate: { x: '0' },
        exit: { x: '100%' },
      };
    }
    if (position === 'top') {
      return {
        initial: { y: '-100%' },
        animate: { y: '0' },
        exit: { y: '-100%' },
      };
    }
    return {
      initial: { y: '100%' },
      animate: { y: '0' },
      exit: { y: '100%' },
    };
  };

  const variants = {
    initial: {
      ...currentPosition().initial,
      transition: {
        duration: 0.3,
        bounce: 0,
        damping: 0,
      },
    },
    animate: {
      ...currentPosition().animate,
      transition: {
        duration: 0.3,
        bounce: 0,
        damping: 0,
        delay: delay ? delay : 0,
      },
    },
    exit: {
      ...currentPosition().exit,
      transition: {
        duration: 0.3,
        bounce: 0,
        damping: 0,
      },
    },
  };

  return (
    <Fragment>
      {triggerNode && <div onClick={() => setIsOpen(true)}>{triggerNode}</div>}
      {createPortal(
        <AnimatePresence>
          {isOpen && (
            <Fragment>
              <SDrawerOverlay
                key={'overlay'}
                onClick={handleClose}
                as={motion.div}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                $isOpen={isOpen}
                $withOverlay={withOverlay}
                className={classNameOverlay}
              />
              <SDrawer
                key={'drawer'}
                as={motion.aside}
                variants={variants}
                initial={'initial'}
                animate={'animate'}
                exit={'exit'}
                className={clsx(theme, className)}
                $position={position}
                $isOpen={isOpen}
                $width={width}
                role={'dialog'}
                $variant={variant}
              >
                {showHead && (
                  <SDrawerHead>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                      {/* <BiChevronLeft size={25} /> Back */}
                      <TooltipSmall direction="bottom" className="drawer-tooltip" text="Back">
                        <SButton $theme={theme} onClick={handleClose}>
                          <BackIcon />
                        </SButton>
                      </TooltipSmall>
                      {!!onToggleFullPage && (
                        <TooltipSmall direction="bottom" className="drawer-tooltip" text="Open in full page">
                          <SButton $theme={theme} onClick={onToggleFullPage}>
                            <FullPageIcon />
                          </SButton>
                        </TooltipSmall>
                      )}
                    </div>
                    {secondIconNode && secondIconNode}
                  </SDrawerHead>
                )}
                <CustomScroll maxHeight={'calc(100vh - 90px)'}>{children}</CustomScroll>
              </SDrawer>
            </Fragment>
          )}
        </AnimatePresence>,
        $portalRef.current
      )}
    </Fragment>
  );
};

export default memo(Drawer);
