import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import * as Styled from './styles';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';

type Props = {
  label?: string;
  className?: string;
};

export const MultipurposeBannerLoader = ({ label, className = '' }: Props) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <Styled.Container className={clsx(theme, { [className]: !!className })}>
      <Styled.Image as={MightyLoaderMini}></Styled.Image>
      {label && <Styled.Text>{label}</Styled.Text>}
    </Styled.Container>
  );
};
