import useSidebarData from './useSidebarData';
import useClickOutside from 'hooks/useClickOutside';
import { shallowEqual, useSelector } from 'react-redux';
import { getIsAuth, getIsSidebar, getPartnerId, setIsSidebar } from 'store';
import { dispatch, useDisplay } from 'hooks';

import { ReactComponent as Left } from '../images/Interface/Arrow/left.svg';
import { ReactComponent as Right } from '../images/Interface/Arrow/right.svg';
import { ReactComponent as Top } from '../images/Interface/topSidebar.svg';
import { ReactComponent as Bottom } from '../images/Interface/bottomSidebar.svg';

import SubMenu from './SubMenu';
import ThemeToggler from './ThemeToggler';

import './styles/styles.css';
import { Wrap, MenuLarge, SidebarNavWrap, SidebarNav } from './styles';

const Sidebar = () => {
  const isAuth = useSelector(getIsAuth, shallowEqual);
  const isOpen = useSelector(getIsSidebar);
  const showSidebar = () => dispatch(setIsSidebar(!isOpen));
  const sidebarData = useSidebarData();
  const { isMobile } = useDisplay();
  const partnerId = useSelector(getPartnerId);

  const domNode = useClickOutside(() => dispatch(setIsSidebar(false)));
  if (partnerId) {
    return (
      <Wrap
        ref={isMobile ? domNode : null}
        className={`sidebar hidden w-0 `}
        onMouseEnter={() => dispatch(setIsSidebar(true))}
        onMouseLeave={() => dispatch(setIsSidebar(false))}
      />
    );
  }
  return (
    <Wrap
      ref={isMobile ? domNode : null}
      className={`sidebar ${isOpen ? '' : 'closed'}`}
      onMouseEnter={() => dispatch(setIsSidebar(true))}
      onMouseLeave={() => dispatch(setIsSidebar(false))}
    >
      <Top />

      <SidebarNavWrap className="glass-div sidebar-navwrap">
        <SidebarNav className="sidebar-nav">
          <MenuLarge className="menu-large">
            {sidebarData
              .filter((item) => (isAuth ? item : !!item.publicPath))
              .map((item, index) => (
                <SubMenu isOpen={isOpen} item={item} key={'submenu-item' + index} />
              ))}
          </MenuLarge>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
            <ThemeToggler />
            {isOpen === false ? (
              <Right onClick={showSidebar} size={25} className="arrow" />
            ) : (
              <Left onClick={showSidebar} size={25} className="arrow" />
            )}
          </div>
        </SidebarNav>
      </SidebarNavWrap>

      <Bottom />
    </Wrap>
  );
};

export default Sidebar;
