import styled from 'styled-components';

export const TagsGrid = styled.div<{ $in2cols?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: ${(p) => (p.$in2cols ? '1fr 1fr' : '1fr 1fr 1fr')};
  gap: 8px;
  position: relative;
  ${(p) =>
    !p.$in2cols &&
    `@media screen and (max-width: 992px) {
    display: flex;
    flex-direction: column;
    max-height: 35vh;
    overflow: scroll;
  }`}
`;

export const Tag = styled.div<{ active?: boolean }>`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 400;
  color: white;
  background: ${(p) => (p.active ? '#627EEA' : '#FFFFFF1C')};
  transition: 0.2s;
  cursor: pointer;
  width: 100%;
  height: 56px;
  min-height: 56px;
  padding: 0px 20px;
  border-radius: 8px;
`;
