import styled, { css } from 'styled-components';
import rgba from 'hex-to-rgba';

export const SWrapper = styled.div(
    ({ theme }) => css`
      position: relative;
      background: ${rgba(theme.theme.fontColorStrong, 0.08)};
      width: calc(50% - 10px);
      height: 140px;
      border-radius: 5px;
    `
  ),
  SSelectedBg = styled.div(
    () => css`
      position: absolute;
      left: 0px;
      right: 0px;
      top: 0px;
      bottom: 0px;

      svg {
        width: 100%;
        height: 100%;
      }
    `
  );
