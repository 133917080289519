import styled from 'styled-components';
import { ReactComponent as photoIcon } from '../../assets/camera.svg';
import plusIcon from '../../assets/plus.svg';
import defaultBg from '../../assets/bg.webp';
import arrLeft from '../../assets/chevron-left.svg';
import trashIcon from '../../assets/trash.svg';
import { THEMES } from 'app/constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 400px;
  height: fit-content;
  padding-top: 94px;
  padding-bottom: 44px;
  align-items: center;
  transition: 0.3s;
  @media screen and (max-width: 992px) {
    border: none;
    padding: 0px 0px 44px;
    border-radius: 40px 40px 0px 0px;
    background: rgba(19, 26, 41, 0.65);
    backdrop-filter: blur(100px);
    overflow: visible;
    margin-top: 160px;
    width: 100%;
    box-shadow: 16px -104px 42px rgba(22, 22, 29, 0.01), 9px -58px 35px rgba(22, 22, 29, 0.05), 4px -26px 26px rgba(22, 22, 29, 0.09),
      1px -6px 14px rgba(22, 22, 29, 0.1), 0px 0px 0px rgba(22, 22, 29, 0.1);
  }
`;

export const Box = styled.div`
  padding: 0px 47px;
  @media screen and (max-width: 992px) {
    padding: 0px 20px;
    width: 100%;
  }
`;

export const BG = styled.div<{ image?: string }>`
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 188px;
  border-radius: 9px;
  transition: 0.3s;
  overflow: hidden;
  &::after {
    background-image: url(${(p) => p.image || defaultBg});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: 0.3s;
    display: block;
    content: '';
    z-index: -1;
    position: absolute;
  }
  & > div {
    opacity: 0;
    pointer-events: none;
  }
  &:hover {
    filter: brightness(1.1);
    &::after {
      filter: blur(2px);
    }
  }
  &:hover > div {
    opacity: 1;
    pointer-events: all;
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;

export const ChangeBgBox = styled.div`
  display: flex;
  flex-direction: column;
  top: 21px;
  left: 50%;
  transform: translateX(-50%);
  color: #fdfdfd66;
  font-size: 16px;
  font-weight: 400;
  line-height: 22.08px;
  position: absolute;
  transition: 0.3s;
`;

export const ChangeBgButton = styled.div`
  position: relative;
  height: 44px;
  border-radius: 31px;
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), rgba(255, 255, 255, 0.34);
  box-shadow: 34px 21px 16px rgba(48, 49, 61, 0.01), 9px 5px 10px rgba(48, 49, 61, 0.01);
  backdrop-filter: blur(8px);
  color: #fdfdfd;
  font-size: 18px;
  font-weight: 400;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  gap: 6px;
  cursor: pointer;
`;

export const PhotoIcon = styled(photoIcon)<{ $isAbsolute?: boolean }>`
  width: 54px;
  height: 54px;
  transition: 0.3s;
  ${(p) => p.$isAbsolute && 'position: absolute; top: 50%; left: 50%; transform: translate(-50%, -50%); opacity: 0;'}
`;

export const Image = styled.img`
  width: 190px;
  height: 190px;
  border-radius: 50%;
  object-fit: cover;
  transition: 0.3s;
`;

export const ImagePlaceholder = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 190px;
  height: 190px;
  min-height: 190px;
  min-width: 190px;
  background: #ffffff33;
  border-radius: 50%;
  backdrop-filter: blur(50px);
  position: relative;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  &:hover > ${PhotoIcon} {
    opacity: 1;
  }
  &:hover > ${Image} {
    filter: blur(2px);
  }
  @media screen and (max-width: 992px) {
    width: 160px;
    height: 160px;
    min-height: 160px;
    min-width: 160px;
    margin-top: -80px;
  }
`;

export const SpaceName = styled.p`
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 36px;
  text-align: center;
  color: white;
`;

export const SpaceForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 20px;
`;

export const HiddenImgInput = styled.input`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
`;

export const Users = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 14px;
`;

export const UserBox = styled.div<{ $theme?: THEMES }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'rgba(255, 255, 255, 0.7)')};
`;

export const AvatarPlaceholder = styled.div<{ $theme?: THEMES }>`
  width: 58px;
  height: 58px;
  background-color: ${(p) => (p.$theme === THEMES.LIGHT ? 'rgb(230, 234, 250)' : '#3e3e48')};
  background-image: url(${plusIcon});
  background-position: center;
  background-size: 30px 30px;
  border-radius: 50%;
  background-repeat: no-repeat;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    filter: brightness(1.1);
  }
`;

export const AvatarDeletePic = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: #1c1d26a8;
  background-image: url(${trashIcon});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 24px;
  cursor: pointer;
  transition: 0.3s;
`;
export const AvatarBox = styled.div`
  width: 58px;
  height: 58px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  & > ${AvatarDeletePic} {
    opacity: 0;
  }
  &:hover > ${AvatarDeletePic} {
    opacity: 1;
  }
`;

export const Back = styled.div`
  width: 36px;
  height: 36px;
  z-index: 10;
  background-image: url(${arrLeft});
  position: absolute;
  top: 62px;
  left: 10px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  @media screen and (min-width: 993px) {
    display: none;
  }
`;

export const AdminModalContainer = styled.div`
  width: 280px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 22px 30px;
`;

export const AdminModalTitle = styled.p<{ $theme?: THEMES }>`
  font-weight: 500;
  font-size: 20px;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  line-height: 24.8px;
  text-align: center;
`;

export const Span = styled.span`
  color: #627eea;
`;

export const AdminModalButtons = styled.div`
  display: flex;
  gap: 14px;
  justify-content: center;
  width: 100%;
`;
