import { useEffect, useMemo, useState } from 'react';
import Drawer from 'shared/ui/drawer/Drawer';
import styles from '../../components/library/courseConstructor/components/SlideRightPanel/styles.module.css';
import './styles/style.scss';
import { SlideAnswers, SlideComments } from 'components/library/courseConstructor/components/Answers&Comments';
import { useGetAnswers, useGetComments } from './hooks';
import { useDashboardContext } from 'pages/coursesConstructor/parts/dashboard/DashboardContext';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

enum ETabs {
  ANSWERS = 'Answers',
  COMMENTS = 'Comments',
}

export const CommentsDrawer = () => {
  const theme = useSelector(getCurrentTheme);
  const { setSlideId, slideId, tab: contextTab, setTab: setContextTab } = useDashboardContext();
  const { answers, isLoading: answersLoading, removeAnswer } = useGetAnswers(slideId);
  const { comments, isLoading: commentsLoading, removeComment } = useGetComments(slideId);
  const [tab, setTab] = useState<ETabs>(ETabs.COMMENTS);

  useEffect(() => {
    if (contextTab) {
      setTab(contextTab as ETabs);
    }
  }, [contextTab]);

  const Content = useMemo(() => {
    if (tab === ETabs.ANSWERS) {
      return <SlideAnswers removeItem={removeAnswer} answers={answers} isLoading={answersLoading} />;
    } else if (tab === ETabs.COMMENTS) {
      return <SlideComments removeItem={removeComment} comments={comments} isLoading={commentsLoading} />;
    }
  }, [answers, comments, answersLoading, commentsLoading, slideId, tab]);

  const lengthCap = (len: number) => {
    if (len >= 100) {
      return '99+';
    }

    return len;
  };

  const onClose = () => {
    if (setSlideId) {
      setSlideId('');
    }
    if (setContextTab) {
      setContextTab('');
    }
  };

  const drawerChildren = useMemo(
    () => (
      <>
        <p className={`title ${theme}`}>Notifications</p>
        <div className={`tabs ${theme}`}>
          {Object.values(ETabs).map((el) => (
            <div key={el} className={`tab ${tab === el ? 'active' : ''}`} onClick={() => setTab(el)}>
              {el} <div className="tab__num">{el === ETabs.ANSWERS ? lengthCap(answers.length) : lengthCap(comments.length)}</div>
            </div>
          ))}
        </div>
        {Content}
      </>
    ),
    [theme, answers.length, comments.length, tab, Content]
  );

  return (
    <Drawer withOverlay={false} className={styles.drawer} triggerValue={slideId || contextTab} onClose={onClose} position={'right'} isVisible={false}>
      {drawerChildren}
    </Drawer>
  );
};
