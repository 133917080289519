import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      grid-area: effects;
      position: relative;
      min-width: 350px;
      max-width: 350px;
      &.mobile {
        min-width: unset;
        max-width: unset;
      }

      @media screen and (max-width: 992px) and (min-width: 626px) {
        ${Effects} {
          justify-content: flex-start;
        }

        ${Indicator} {
          gap: 15px;

          &.shrinkable {
            margin: unset;
          }
        }
      }
    `
  ),
  Effects = styled.div(
    () => css`
      display: flex;
      justify-content: space-between;
      align-items: center;
    `
  ),
  Indicator = styled.div(
    () => css`
      height: 58px;
      display: flex;
      gap: 12px;
      transition: all 0.25s ease-in-out;

      cursor: pointer;

      .indicator {
        flex-shrink: 0;
      }
    `
  ),
  Span = styled.span(
    () => css`
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
      letter-spacing: 0.5px;
      white-space: pre;
    `
  ),
  All = styled.span(
    () => css`
      display: block !important;
      right: -10px !important;
    `
  );
