import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: inherit;

    background: linear-gradient(-45deg, rgba(100, 135, 239, 0.1) 40%, rgba(100, 135, 239, 0.2) 50%, rgba(100, 135, 239, 0.1) 60%);
    background-size: 300%;
    background-position-x: 200%;

    animation: shimmer 2s infinite linear forwards;

    @keyframes shimmer {
      to {
        background-position-x: -100%;
      }
    }
  `
);
