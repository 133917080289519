// import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { getUserData } from 'store';
// import { useEffect, useMemo } from 'react';
// import { useGlobalLoader } from 'shared/utils/GlobalPageLoader/useGlobalLoader';
import { createPortal } from 'react-dom';
import VerticalLessonPage from './VerticalLessonPage';

const LessonPageWrapper = () => {
  // const { courseId } = useParams();
  // const { resultCourses } = useSelector(getUserData);
  // const resultCourse = useMemo(() => resultCourses && resultCourses.find((el) => el.courseId === courseId), [resultCourses, courseId]);

  // useEffect(() => {
  //   if (courseId && !resultCourse) {
  //     addCourseToUser({ courseId }).unwrap();
  //   }
  // }, [courseId]);

  // useGlobalLoader({ dependency: [!!resultCourse, false] });

  // if (courseId && !isAdding) {
  return createPortal(<VerticalLessonPage />, document.getElementById('fullscreen-portal')!);
  // }
  // return null;
};

export default LessonPageWrapper;
