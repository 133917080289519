import { ErrorHandler } from 'shared/utils/ErrorHandler';
import { store } from 'store';
import { battleApi } from 'store/battle/battle.api';

const mindBattleLoader = () => {
  const coursesFromLoader = store.dispatch(battleApi.endpoints.getTopicsForBattle.initiate());
  const historyFromLoader = store.dispatch(battleApi.endpoints.getTopicsForBattle.initiate());
  const pendingBattlesFromLoader = store.dispatch(battleApi.endpoints.getTopicsForBattle.initiate());

  try {
    const responseCourses = coursesFromLoader.unwrap().catch(ErrorHandler).catch(ErrorHandler);
    const responseHistory = coursesFromLoader.unwrap().catch(ErrorHandler).catch(ErrorHandler);
    const responsePendingBattles = coursesFromLoader.unwrap().catch(ErrorHandler).catch(ErrorHandler);

    return {
      coursesFromLoader: responseCourses,
      historyFromLoader: responseHistory,
      pendingBattlesFromLoader: responsePendingBattles,
    };
  } catch (e) {
    throw new Error('An error occurred while loading mindBattle.');
  } finally {
    coursesFromLoader.unsubscribe();
    historyFromLoader.unsubscribe();
    pendingBattlesFromLoader.unsubscribe();
  }
};

export default mindBattleLoader;
