import { useMemo, useRef } from 'react';
import AvatarPic from '../../../../../library/utils/AvatarPic/avatar';

import './friend-card.css';
import UserContextMenu from 'components/shared/UserContextMenu/user-context';
import { LazyImage } from '../../../../../../shared/ui/lazyImage';
import { useSelector } from 'react-redux';
import { getUserData } from 'store';
import { FRIEND_STATUS } from 'app/constants';

interface IFriendCard {
  avatar?: string;
  _id: string;
  nickname: string;
  address: string;
  isActive?: boolean;
  isSearcher?: boolean;
  isOnline?: boolean;
  isFramed?: boolean;
  handleOpponentCapture?: (arg0: object) => void;
  showSearch?: () => void;
  isInteractive?: boolean;
  isMenuOn?: boolean;
  loading?: boolean;
  customization?: any;
}

const FriendCard = ({
  isActive,
  avatar,
  _id,
  nickname,
  address,
  isSearcher,
  isOnline,
  isFramed,
  handleOpponentCapture,
  showSearch,
  isInteractive,
  isMenuOn = true,
  loading,
  customization,
}: IFriendCard) => {
  const domNode = useRef<HTMLDivElement>(null);
  const { _id: userId, friends } = useSelector(getUserData);

  function handleCapture() {
    if (handleOpponentCapture && typeof handleOpponentCapture === 'function') {
      handleOpponentCapture({ nickname, avatar, _id, address, isOnline, customization });
    }
  }

  const isFriend = useMemo(
    () =>
      friends.some(
        (friend) =>
          ((friend.recipient._id === _id && friend.requester === userId) || (friend.recipient._id === userId && friend.requester === _id)) &&
          friend.status === FRIEND_STATUS.FRIENDS
      ),
    [_id, friends, userId]
  );

  try {
    return (
      <UserContextMenu
        isInteractive={isMenuOn}
        isAddFriend={userId !== _id && !isFriend}
        toRemove
        user={{ avatar, _id, nickname, address, customization }}
        profileId={_id}
      >
        <div
          className={`friend-card ${isSearcher && 'searcher'} ${isActive && 'captured'}`}
          role="button"
          ref={domNode}
          onClick={isSearcher ? showSearch : isInteractive ? () => {} : handleCapture}
        >
          <div className="friend-pic">
            {isSearcher ? (
              <LazyImage src={avatar ?? ''} alt="" />
            ) : (
              <AvatarPic
                isFramed={isFramed}
                nickname={nickname}
                avatar={avatar}
                isOnline={isOnline}
                customization={customization}
                smallGlow={true}
                isOnlineMarker
              />
            )}
          </div>
          <p className="friend-name">{nickname && nickname.length < 14 ? nickname : nickname?.slice(0, 12) + '...'}</p>
        </div>
      </UserContextMenu>
    );
  } catch (ex) {
    console.log(ex);
    return <h4>Error</h4>;
  }
};

export default FriendCard;
