import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      min-height: 474px;

      @media screen and (max-width: 992px) {
        min-height: 400px;
      }

      .activate-item__button {
        width: 146px;
        height: 54px;
        font-weight: 500;
        line-height: 124%;
        margin-bottom: 8px;

        @media screen and (max-width: 992px) {
          margin-bottom: unset;
          height: 56px;
        }
      }

      .activate-item__checkbox {
        align-items: center;

        label:before,
        span {
          opacity: 1 !important;
        }
      }
    `
  ),
  Content = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      align-items: center;

      &.no-effect {
        min-height: 280px;
      }
    `
  ),
  Title = styled.h3(
    () => css`
      color: var(--font-color-strong);
      text-align: center;
      font-size: 35px;
      font-weight: 500;
      line-height: 110%;
      margin-bottom: 6px;
    `
  ),
  Desc = styled.p(
    () => css`
      color: var(--font-color-strong);
      text-align: center;
      line-height: 124%;
      opacity: 0.8;
      max-width: 265px;
    `
  ),
  Success = styled.div(
    () => css`
      h3 {
        text-align: center;
        font-size: 55px;
        font-weight: 500;
        line-height: 100%;
        margin-bottom: 8px;

        @media screen and (max-width: 992px) {
          margin-bottom: 13px;
        }
      }

      p {
        color: #fff;
        text-align: center;
        line-height: 100%;
      }
    `
  ),
  ButtonsWrap = styled.div(
    () => css`
      display: flex;
      justify-content: center;
      gap: 14px;
      margin-top: 39px;

      @media screen and (max-width: 992px) {
        gap: 24px;
        margin-top: 51px;

        button {
          height: 56px;
        }
      }
    `
  );
