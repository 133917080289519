import { IInlineAnswerExtension, TExtensionSlot } from './types';

export class InlineAnswersEditor {
  private _wrapper: Element;
  private _root;
  private _frozenRange: Range | null = null;

  private _extensions: Record<string, IInlineAnswerExtension<any>> = {};

  constructor(container: Element) {
    const wrapper = document.createElement('div');
    wrapper.style.position = 'relative';

    const root = document.createElement('div');
    root.setAttribute('contenteditable', 'true');

    wrapper.appendChild(root);

    this._wrapper = wrapper;
    this._root = root;

    container.appendChild(this._wrapper);

    this._bootstrap();
  }

  // public
  public focus = (toStart = false) => {
    this._root.focus();

    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    const range = new Range();
    range.selectNodeContents(this._root);
    range.collapse(toStart);

    selection.removeAllRanges();
    selection.addRange(range);
  };

  public getRoot = () => {
    return this._root;
  };

  public getWrapper = () => {
    return this._wrapper;
  };

  public attachExtension = <T>(slot: TExtensionSlot<T>) => {
    const extension = new slot.Extension(slot.props);

    extension.attach(this);

    this._extensions[slot.id] = extension;
  };

  public detachExtension = (id: string) => {
    const extension = this._extensions[id];
    if (!extension) {
      return;
    }

    extension.detach();
    delete this._extensions[id];
  };

  // private
  private _bootstrap = () => {
    this._initListeners();
  };

  private _initListeners = () => {
    this._root.addEventListener('keydown', this._handleKeyDown);
    this._root.addEventListener('input', this._handleInput);
  };

  private _handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === 'Tab') {
      evt.preventDefault();
      return;
    }
  };

  private _handleInput = () => {
    if (this._root.innerHTML === '') {
      this._root.innerHTML = '<div>&#x200b;</div>';
      return;
    }

    const selection = window.getSelection();

    const range = selection?.getRangeAt(0);
    if (!range) {
      return;
    }

    const container = range.startContainer;
    if (container.nodeType === Node.TEXT_NODE) {
      return;
    }

    const containerElement = container as Element;
    const html = containerElement.innerHTML;
    if (html.trim() === '<br>') {
      containerElement.innerHTML = '&#x200b;';
    }
  };

  public freezeSelection = () => {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) {
      return;
    }

    this._frozenRange = selection.getRangeAt(0);
  };

  public restoreSelection = () => {
    const selection = window.getSelection();

    if (!this._frozenRange || !selection) {
      return;
    }

    selection.removeAllRanges();

    selection.addRange(this._frozenRange);
    this._frozenRange = null;
  };

  public lock = () => {
    this._root.addEventListener('keydown', this._handleKeydownLock);
  };

  public unlock = () => {
    this._root.removeEventListener('keydown', this._handleKeydownLock);
  };

  private _handleKeydownLock = (evt: KeyboardEvent) => {
    evt.preventDefault();
  };

  public insertText = (text: string) => {
    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    const range = selection.rangeCount ? selection.getRangeAt(0) : new Range();

    const textNode = document.createTextNode(text);
    range.insertNode(textNode);

    selection.removeAllRanges();
    selection.addRange(range);
    selection.collapseToEnd();
  };

  public insertNode = (node: Element) => {
    const selection = window.getSelection();
    if (!selection) {
      return;
    }

    const range = selection.rangeCount ? selection.getRangeAt(0) : new Range();

    range.deleteContents();

    range.insertNode(node);

    selection.removeAllRanges();
    selection.addRange(range);
    selection.collapseToEnd();
  };

  public getExtension = <T>(id: string) => {
    const extension = this._extensions[id];
    if (!extension) {
      throw new Error(`Extension with id ${id} not registered`);
    }

    return extension as T;
  };

  public destroy = () => {
    if (!this._root) {
      return;
    }

    // Remove event listeners
    this._root.removeEventListener('keydown', this._handleKeyDown);
    this._root.removeEventListener('input', this._handleInput);
    this._root.removeEventListener('keydown', this._handleKeydownLock);

    // Remove the editor from the DOM
    if (this._wrapper.parentNode) {
      this._wrapper.parentNode.removeChild(this._wrapper);
    }

    // Clear references
    this._wrapper = null!;
    this._root = null!;
    this._frozenRange = null;
    this._extensions = {};
  };
}
