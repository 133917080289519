import { DropdownProps } from './types';
import * as S from './styles';
import { useDisclosure, useGetCurrentTheme } from 'hooks';
import { useMemo } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Flex } from 'shared/ui';

export const DashboardDropdown = ({ handleChange, options, currentValue, buttonClassName, customColor }: DropdownProps) => {
  const theme = useGetCurrentTheme();
  const { toggle, close, isOpen } = useDisclosure(false);
  const handleOptionClick = (val: string) => (e: any) => {
    e.stopPropagation();
    handleChange(val);
    close();
  };

  const currentOption = useMemo(() => options.find((el) => el.value === currentValue), [options, currentValue]);
  const otherOptions = useMemo(() => options.filter((el) => el.value != currentValue), [options, currentValue]);
  const currentHeight = useMemo(() => otherOptions.length * 44 + 8, [otherOptions.length]);
  if (!currentOption) {
    return null;
  }

  return (
    <S.DropdownContainer>
      <S.DropdownButton $theme={theme} $customColor={customColor} className={buttonClassName} onClick={toggle} $isOpened={isOpen}>
        <Flex $alignItems="center" width="100%">
          <S.IconBox>{currentOption.icon}</S.IconBox>
          <S.DropdownLabel>{currentOption.label}</S.DropdownLabel>
          <S.Chevron $isOpen={isOpen} />
        </Flex>
      </S.DropdownButton>
      <AnimatePresence>
        {isOpen && (
          <S.DropdownOptions
            initial={{ opacity: 0, height: 0, paddingTop: 0 }}
            animate={{ opacity: 1, height: currentHeight, paddingTop: 9 }}
            exit={{ opacity: 0, height: 0, paddingTop: 0 }}
            transition={{ bounce: false }}
            $theme={theme}
          >
            {otherOptions.map(({ icon, value, label }) => (
              <S.DropdownOption
                as={motion.div}
                key={label + value}
                $theme={theme}
                onClick={handleOptionClick(value)}
                initial={{ backdropFilter: 'none' }}
                animate={{ backdropFilter: 'blur(30px)' }}
              >
                <S.IconBox>{icon}</S.IconBox>
                <S.DropdownLabel>{label}</S.DropdownLabel>
              </S.DropdownOption>
            ))}
          </S.DropdownOptions>
        )}
      </AnimatePresence>
    </S.DropdownContainer>
  );
};
