import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { TSlideRightPanelProps } from './types';
import { useGetCommentsBySlideQuery } from 'store';
import { useGetAnswersBySlideIdQuery } from 'store/answers/answers.api';
import { createPortal } from 'react-dom';

import InventoryItem from 'components/library/cards/inventory/InventoryItem';
import IconButton from 'components/library/iconButton';
import MaterialPage from 'components/pages/study/courses/material-pages/MaterialPage';
import { useSlideContext } from '../SlideContext';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { useDashboardContext } from 'pages/coursesConstructor/parts/dashboard/DashboardContext';

import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as EyeIcon } from 'assets/icons/eye.svg';
import { ReactComponent as MessageDotsIcon } from 'assets/icons/message-dots.svg';

import styles from './styles.module.css';

const SlideRightPanel: FC<TSlideRightPanelProps> = ({ onSlideDelete, onChangeRewardsRequest, slideRewards, allRewards = [], path, isQuest }) => {
  const [previewIsOpen, setPreviewIsOpen] = useState(false);
  const { setSlideId, answersCount, commentsCount } = useDashboardContext();
  const {
    loading,
    slide: { _id },
  } = useSlideContext();
  const { data: answers, refetch: refetchAnswers, isFetching: isFetchingAnswers } = useGetAnswersBySlideIdQuery(_id);
  const { data: comments, refetch: refetchComments, isFetching: isFetchingComments } = useGetCommentsBySlideQuery(_id);

  const notificationCount = useMemo(() => {
    if (!!answers && !!comments) {
      return answers.items.length + comments.items.length;
    }

    return 0;
  }, [answers, comments]);

  useEffect(() => {
    if (!isFetchingAnswers) {
      refetchAnswers();
    }
  }, [answersCount]);

  useEffect(() => {
    if (!isFetchingComments) {
      refetchComments();
    }
  }, [commentsCount]);

  const courseId = path.course.id;
  const chapterId = path.chapter.id;
  const lessonId = path.lesson.id;
  const slideIndex = String(path.slide);

  const rewardsViews = useMemo(() => {
    const slideRewardsIds = slideRewards.map((item) => item.id);
    return slideRewardsIds
      .map((id, idx) => {
        const reward = allRewards.find((item) => item.id === id);
        if (!reward) {
          return null;
        }

        return {
          itemId: reward.id,
          amount: slideRewards[idx].quantity,
        };
      })
      .filter((item) => !!item);
  }, [allRewards, slideRewards]);

  function handleNavigatePreview() {
    setPreviewIsOpen(true);
  }

  return (
    <Fragment>
      <div className={styles.root}>
        <div>
          <div className={styles.rewardsTitle}>Slide Rewards</div>
          <div className={styles.rewards}>
            {rewardsViews.map((item, index) => (
              <InventoryItem
                key={`reward${item?.itemId}${index}`}
                isSmall
                itemId={item!.itemId}
                amount={item!.amount}
                isShowTooltip={false}
                navigateOnClick={false}
              />
            ))}
          </div>
          <IconButton size={50} icon={<PlusIcon />} className={styles.roundedButton} onClick={onChangeRewardsRequest} />
          <IconButton size={50} icon={<EyeIcon />} className={styles.roundedButton} onClick={handleNavigatePreview} />
          {/* <CommentsDrawer _id={_id} path={path} allRewards={allRewards} /> */}
          {/* <div className={styles.commentsButton}> */}
          {/* {isSuccess && countComments.numberOfComments !== 0 && <span className={styles.notification}>{countComments.numberOfComments}</span>} */}
          <div className={styles.commentsButton}>
            {!!notificationCount && <div className={styles.notification}>{notificationCount}</div>}
            <IconButton size={50} icon={<MessageDotsIcon />} className={styles.roundedButton} onClick={() => (setSlideId ? setSlideId(_id) : {})} />
          </div>
          {/* </div> */}
        </div>
        {!isQuest && (
          <div>
            <IconButton
              disabled={loading}
              size={50}
              icon={loading ? <MightyLoaderMini className={styles.loader} /> : <TrashIcon style={{ width: 30, height: 30 }} />}
              className={styles.roundedButton}
              onClick={onSlideDelete}
            />
          </div>
        )}
      </div>
      {createPortal(
        previewIsOpen && (
          <MaterialPage
            courseIdProp={courseId}
            chapterIdProp={chapterId}
            lessonIdProp={lessonId}
            slideIndex={slideIndex}
            slideId={_id}
            onClose={() => setPreviewIsOpen(false)}
          />
        ),
        document.getElementById('portal')!
      )}
    </Fragment>
  );
};

export default SlideRightPanel;
