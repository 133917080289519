type THandleEmptyLessonProps = {
  setLessons: React.Dispatch<React.SetStateAction<any[]>>;
  chapterId: string;
  type: string;
  id: string;
};
export const addEmptyLesson = ({ chapterId, id, setLessons, type }: THandleEmptyLessonProps) => {
  const newLesson = {
    _id: id,
    name: '',
    challengeRewards: [],
    type,
    estimatedTime: 0,
    lives: 0,
    chapterId,
  };

  setLessons((lessons) => [...lessons, newLesson]);
};
