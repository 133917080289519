import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { GRADES } from 'app/constants';

import { ProgressBar } from '../course-card';
import { EnergyIncomeIndicator } from './energy-income-countdown';

import * as Styled from './styles';
import { useUserEnergyLevel } from 'hooks';

type Props = {
  className?: string;
};

export function EnergyWidget({ className = '' }: Props) {
  const theme = useSelector(getCurrentTheme);
  const { energyPersentage } = useUserEnergyLevel();

  return (
    <Styled.Container className={`${theme} ${className}`}>
      <Styled.Header>
        <p>Energy renewal</p>
        <EnergyIncomeIndicator />
      </Styled.Header>
      <ProgressBar className="progressbar" isProgressAnimated completion={energyPersentage} grade={GRADES.UNCOMMON} />
    </Styled.Container>
  );
}
