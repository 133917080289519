import { EIcons, ESpecialIcons } from './NewCustomizationMenu/constants';

interface ITaskWidgetContent {
  title: string;
  content: string;
  icon?: ESpecialIcons | EIcons;
  color: string;
  link?: string;
  taskType?: ETask;
}

export type ITaskWidgets = Record<ETaskWidgetPreset, ITaskWidgetContent>;

export enum ETaskWidgetPreset {
  MAIL = 'Send the Email',
  WEBSITE = 'Visit the website',

  YT_WATCH = 'Watch a video on YouTube',
  YT_VISIT = 'Visit a Channel on YouTube',

  FACEBOOK = 'Visit a page on Facebook',
  X_FOLLOW = 'Follow X',
  X_LIKE = 'Like a Tweet',
  X_RETWEET = 'Retweet a Tweet',
  DISCORD = 'Join Discord',
  INSTAGRAM_VISIT = 'Visit a profile/post/reels/story',
  INSTAGRAM_SAVE = 'Save a profile/post/reels/story',
  INSTAGRAM_COMMENT = 'Comment a profile/post/reels/story',
  INSTAGRAM_LIKE = 'Like a profile/post/reels/story',
  // GITHUB = 'Github Contribute',
  TELEGRAM = 'Join Telegram',
  // on-chain
  ONCHAIN_DEPOSIT = 'Deposit tokens to your wallet',
  ONCHAIN_BRIDGE_TON_ETH = 'Bridge yourself ETH tokens using bridge.ton.org',
  ONCHAIN_BRIDGE_TON_BSC = 'Bridge yourself BSC tokens using bridge.ton.org',
  // ONCHAIN_STAKE_TON = 'Stake tokens using ton.app',
}

export type TOnChainTask = ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_BSC | ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_ETH | ETaskWidgetPreset.ONCHAIN_DEPOSIT;

export enum ETask {
  TONBridgeBSC = 'TON_bridgeBSC',
  TONBridgeETH = 'TON_bridgeETH',
  TONDeposit = 'TON_deposit',
}
