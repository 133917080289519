import { RefObject, useReducer } from 'react';
import { useNavigate } from 'react-router-dom';
import { IUser, IUserNoAvatar } from 'interface';
import { useAddFriend, useGetUserData } from 'hooks';
import useRemoveFriend from 'hooks/useRemoveFriend';

import { ReactComponent as Trash } from '../../library/images/icons/trash_round.svg';
import { ReactComponent as SeeProfile } from '../../../assets/icons/profile/see_profile.svg';
import { IoAdd } from 'react-icons/io5';

import AddConfirm from 'components/pages/profile/profile/FriendsSmall/slides/AddConfirm';
import AddConfirmed from 'components/pages/profile/profile/FriendsSmall/slides/AddConfirmed';
import RemoveConfirm from 'components/pages/profile/profile/FriendsSmall/slides/RemoveConfirm';
import DropdownMenu from 'components/library/dropdownMenu';
import { Modal } from 'shared/ui';
import UnfollowConfirm from 'components/pages/profile/profile/FriendsSmall/slides/UnfollowConfirm';

import './user-context-menu.css';
import { ROUTES } from 'app/constants';

interface IProps {
  isInteractive?: boolean;
  user: IUser | IUserNoAvatar;
  profileId: string;
  toRemove?: boolean | (() => Promise<void> | void);
  toUnfollow?: boolean;
  isAddFriend?: boolean;
  children: any;
  wrapperClassName?: string;
  modalRef?: RefObject<any>;
}

interface State {
  slide: '' | 'addConfirming' | 'addConfirmed' | 'removeConfirm' | 'unfollowConfirm';
}

const defaultState: State = {
  slide: '',
};

export default function UserContextMenu({
  isInteractive = true,
  profileId = '',
  toRemove,
  toUnfollow,
  user,
  children,
  wrapperClassName = '',
  isAddFriend = false,
  modalRef,
}: IProps) {
  const { _id: currentUserId } = useGetUserData();
  const { removeFriend, isLoading: isRemoveLoading } = useRemoveFriend();
  const { addFriend, isLoading: isAddLoading } = useAddFriend();

  const navigate = useNavigate();
  const [state, updateState] = useReducer((prev: any, next: any) => ({ ...prev, ...next }), defaultState);

  function handleToSeeProfile() {
    // e.stopPropagation();
    if (profileId) {
      navigate(`${ROUTES.PROFILE}/${profileId}`, { replace: true });
    }
  }
  function handleAddOpen() {
    updateState({ slide: 'addConfirming' });
  }
  function handleRemoveOpen() {
    updateState({ slide: 'removeConfirm' });
  }
  function handleUnfollowOpen() {
    updateState({ slide: 'unfollowConfirm' });
  }
  async function handleAddFriend() {
    await addFriend(user._id).then(() => {
      updateState({ slide: 'addConfirmed' });
    });
  }
  async function handleRemove() {
    if (typeof toRemove === 'function') {
      toRemove();
    } else if (toRemove) {
      await removeFriend(user._id).then(() => {
        updateState({ slide: '' });
      });
    }
  }
  async function handleUnfollow() {
    if (toUnfollow) {
      await removeFriend(user._id).then(() => {
        updateState({ slide: '' });
      });
    }
  }
  function handleConfirmClose() {
    updateState({ slide: '' });
  }

  const slides: Record<string, any> = {
    addConfirming: (
      <AddConfirm isLoading={isAddLoading} close={handleConfirmClose} name={user.nickname} avatar={user?.avatar} accept={handleAddFriend} />
    ),
    addConfirmed: <AddConfirmed close={handleConfirmClose} name={user.nickname} />,
    removeConfirm: <RemoveConfirm isLoading={isRemoveLoading} close={handleConfirmClose} name={user.nickname} accept={handleRemove} />,
    unfollowConfirm: <UnfollowConfirm close={handleConfirmClose} name={user.nickname} accept={handleUnfollow} />,
  };

  if (!!state.slide) {
    return (
      <Modal open={!!state.slide} onClose={handleConfirmClose}>
        {slides[state.slide]}
      </Modal>
    );
  }

  const items: any[] = [];

  if (profileId) {
    items.push({
      title: 'See profile',
      icon: <SeeProfile />,
      onClick: handleToSeeProfile,
    });
  }
  if (profileId !== currentUserId && !!toRemove) {
    items.push({
      title: 'Remove from Friends',
      icon: <Trash />,
      onClick: handleRemoveOpen,
    });
  }
  if (profileId !== currentUserId && toUnfollow) {
    items.push({
      title: 'Unfollow user',
      icon: <Trash />,
      onClick: handleUnfollowOpen,
    });
  }
  if (isAddFriend) {
    items.push({
      title: 'Add friend',
      icon: <IoAdd />,
      onClick: handleAddOpen,
    });
  }

  return isInteractive ? (
    <DropdownMenu
      toggle={
        <div className={wrapperClassName} role="button">
          {children}
        </div>
      }
      items={items}
      parentRef={modalRef}
    />
  ) : (
    children
  );
}
