import { useEffect, useRef, useState } from 'react';
import { useIsConnectionRestored, useTonConnectUI, useTonWallet } from '@tonconnect/ui-react';
import { useAttachAuthMethodMutation, useLazyGetMyPartsQuery } from 'store';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { Account, TonProofItemReplySuccess } from '@tonconnect/ui';
import { EAuthMethods } from 'interface';
import { useLinkModalContext } from '../../context';

const payloadTTLMS = 1000 * 60 * 15;

export function useLinkTon() {
  const isConnectionRestored = useIsConnectionRestored();
  const wallet = useTonWallet();
  const [tonConnectUI] = useTonConnectUI();
  const interval = useRef<ReturnType<typeof setInterval> | undefined>();
  const [token, setToken] = useState<null | string>(null);
  const [getUser, { isLoading: isUserLoading }] = useLazyGetMyPartsQuery();
  const { setStage } = useLinkModalContext();
  const [attach, { isLoading }] = useAttachAuthMethodMutation();
  const checkProofRequested = useRef(false);

  const generatePayload = () => ({ tonProof: Date.now().toString() });

  const checkProof = async (proof: TonProofItemReplySuccess['proof'], account: Account): Promise<string | undefined> => {
    try {
      const requestBody = {
        address: account.address,
        network: account.chain,
        proof: {
          ...proof,
          state_init: account.walletStateInit,
        },
      };

      const response = await attach({ type: EAuthMethods.TON, data: requestBody }).unwrap();

      return response?.accessToken;
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!isConnectionRestored) {
      return;
    }

    clearInterval(interval.current);

    if (!wallet) {
      setToken(null);

      const refreshPayload = async () => {
        tonConnectUI.setConnectRequestParameters({
          state: 'loading',
        });

        const value = generatePayload();

        if (!value) {
          tonConnectUI.setConnectRequestParameters(null);
        } else {
          tonConnectUI.setConnectRequestParameters({
            state: 'ready',
            value,
          });
        }
      };

      refreshPayload();
      interval.current = setInterval(refreshPayload, payloadTTLMS);
      return;
    }

    if (!isLoading && !isUserLoading && !token && !checkProofRequested.current) {
      if (wallet.connectItems?.tonProof && !('error' in wallet.connectItems.tonProof)) {
        setStage('progress');

        checkProofRequested.current = true;
        checkProof(wallet.connectItems.tonProof.proof, wallet.account).then((result) => {
          if (result) {
            getUser({ props: ['authMethods'] }).then(() => {
              setToken(result);
              setStage('success');
            });
          } else {
            setToken(null);
            checkProofRequested.current = false;
            toast.error(<Answer type="incorrect" label="Please try another wallet" />);
            tonConnectUI.disconnect();
            setStage('error');
          }
        });
      } else if (!token) {
        toast.error(<Answer type="incorrect" label="Please try another wallet" />);
        tonConnectUI.disconnect();
        setStage('error');
      }
    }

    return () => {};
  }, [wallet, token, isConnectionRestored, isLoading, isUserLoading, tonConnectUI]);
}
