import clsx from 'clsx';

import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useTranslation } from 'react-i18next';
import { dispatch } from 'hooks';
import { setIsFriendFinder } from 'store';

import styles from './styles.module.css';

export default function FriendFinder({ className = '' }) {
  const theme = useSelector(getCurrentTheme);
  const { t } = useTranslation();

  function openFinder() {
    dispatch(setIsFriendFinder(true));
  }

  return (
    <div className={clsx(styles.container, styles[theme], { [className]: !!className })} role="button" onClick={openFinder}>
      <p className={styles.text}>{t('profile.findFriend')}</p>
    </div>
  );
}
