import { FC } from 'react';
import { createPortal } from 'react-dom';
import { BsChevronLeft } from 'react-icons/bs';
import { useDisplay } from 'hooks';
import styles from './styles.module.scss';

interface IBackArrowProps {
  show: boolean;
  handleClose: () => void;
}

export const BackArrow: FC<IBackArrowProps> = ({ show, handleClose }) => {
  const { isMobile } = useDisplay();

  if (!show) {
    return <></>;
  }

  return (
    <>
      {isMobile ? (
        createPortal(
          <div onClick={handleClose} className={styles.back}>
            <BsChevronLeft strokeWidth={'1px'} size={'20px'} />
          </div>,
          document.getElementById('root')!
        )
      ) : (
        <div onClick={handleClose} className={styles.back}>
          <BsChevronLeft strokeWidth={'1px'} size={'20px'} />
        </div>
      )}
    </>
  );
};
