export const getCursorCoordinates = () => {
  const selection = window.getSelection();
  if (!selection) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  if (selection.rangeCount === 0) {
    return { x: 0, y: 0, width: 0, height: 0 };
  }

  const range = selection.getRangeAt(0);

  const rect = range.getBoundingClientRect();

  return { x: rect.left, y: rect.top, width: rect.width, height: rect.height };
};
