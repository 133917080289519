export const toBase64 = (file?: File): Promise<string | null> => {
  if (!file) {
    return Promise.resolve(null);
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      if (!reader.result) {
        resolve(null);
        return;
      }

      resolve(reader.result.toString());
      return;
    };
    reader.onerror = function (error) {
      resolve(null);
    };
  });
};
