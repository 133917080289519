import { FC, memo } from 'react';
import styles from './styles.module.css';
import { getAppSettings } from 'store';
import { useSelector } from 'react-redux';
import LogoLoader from 'shared/ui/loader/MightyLoader';

const MightyLoaderBig: FC = () => {
  const { displayedName } = useSelector(getAppSettings);

  if (displayedName === 'blank') {
    return null;
  }

  return (
    <div className={`${styles.container} main-loader`}>
      <LogoLoader className={styles.loaderMini} isLabel={false} />
    </div>
  );
};
export default memo(MightyLoaderBig);
