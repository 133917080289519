import { Modal } from 'shared/ui';
import { ReactComponent as RejDark } from './assets/rejDark.svg';
import { ReactComponent as RejLight } from './assets/rejLight.svg';

import './acc-rej.scss';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { THEMES } from 'app/constants';
import BasicButton from 'shared/ui/basic-button/button';

interface IAccesRejProps {
  open: boolean;
  close: () => void;
}

export default function LockedCoursePartWarningModal({ open, close }: IAccesRejProps) {
  const theme = useSelector(getCurrentTheme);

  return (
    <Modal open={open} onClose={close} className={`glass-div basic ${theme}`} closeButtonLocation="outside">
      <div className="acc-rej">
        <h2 id="header">Lesson Locked</h2>
        {theme === THEMES.LIGHT ? <RejLight /> : <RejDark />}
        <p id="desc">This lesson is unavailable, please complete all lessons before to unlock this lesson.</p>
        <BasicButton onClick={close} label="Continue" variant="outline" />
      </div>
    </Modal>
  );
}
