import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const FollowButton = styled.button<{ active?: boolean; $small?: boolean; $large?: boolean; $theme: THEMES }>`
  border: none;
  outline: none;
  cursor: pointer;
  color: ${(p) => (p.$theme === THEMES.DARK ? 'white' : '#1C1D26')};
  font-size: ${(p) => (p.$small ? '16px' : '20px')};
  height: ${(p) => (p.$small ? '41px' : p.$large ? '56px' : '52px')};
  padding: ${(p) => (p.$small ? '0px 20px' : '0px 30px')};
  border-radius: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s linear;
  width: 100%;
  min-width: fit-content;

  @media screen and (max-width: 992px) {
    padding: 0px 26px;
    width: 100%;
    max-width: 100%;
  }

  &:disabled {
    opacity: 1;
  }

  span {
    color: inherit;
    font-weight: 500;
  }
`;
