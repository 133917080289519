import clsx from 'clsx';
import { IEffectIndicatorProps } from '../types';
import { useGetEffectsColorData } from '../data';
import { useClock } from 'hooks';

import { CircleProgress } from 'shared/ui/progress-bar/circle-progress';

import dayjs from 'dayjs';

import * as Styled from './styles';
import { useMemo } from 'react';

export const CountDown = ({
  type = 'small-energy-potion',
  className = '',
  zIndex,
  onTimeout,
  isClickable = false,
  onClick,
  effect,
}: Pick<IEffectIndicatorProps, 'type' | 'className' | 'zIndex' | 'onTimeout' | 'isClickable' | 'onClick' | 'effect'>) => {
  const { color, image, grade } = useGetEffectsColorData(type);

  const currentTime = useMemo(() => dayjs(), []),
    startTime = useMemo(() => dayjs(effect.updatedAt), [effect.updatedAt]),
    endTime = useMemo(() => dayjs(effect.expiredAt), [effect.expiredAt]),
    totalDuration = useMemo(() => endTime.diff(startTime, 'second'), [endTime, startTime]),
    elapsedTime = useMemo(() => currentTime.diff(startTime, 'second'), [currentTime, startTime]);

  const { clock } = useClock({ startFrom: totalDuration - elapsedTime, substructor: 1, onTimeout });

  const percent = (clock / totalDuration) * 100;

  const handleClick = () => {
    if (isClickable && typeof onClick === 'function') {
      onClick();
    }
  };

  return (
    <Styled.Container
      className={clsx(type, grade, { [className]: !!className, clickable: isClickable })}
      style={{ zIndex }}
      role="button"
      onClick={handleClick}
    >
      <CircleProgress
        circle={{
          color,
        }}
        className="countdown"
        percent={percent}
      />
      <Styled.ItemImage className={type} src={image} />
    </Styled.Container>
  );
};
