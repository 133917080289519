import { IResultCourse } from 'interface/courses';
import { useMemo } from 'react';

type Props = {
  resultCourse: IResultCourse;
  chapterId: string;
  lessonId?: string;
};

export const getResultCourseParts = ({ chapterId, lessonId, resultCourse }: Props) => {
  const resultChapter = resultCourse.resultChapters.find((el) => el.chapterId === chapterId);
  const resultLesson = resultChapter && lessonId ? resultChapter.resultLessons.find((el) => el.lessonId === lessonId) : undefined;

  return { resultChapter, resultLesson };
};

export const useGetResultCourseParts = (props: Props) => {
  return useMemo(() => getResultCourseParts(props), [JSON.stringify(props)]);
};
