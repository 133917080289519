import styled from 'styled-components';

export const SettingContainer = styled.div`
  padding: 10px;
`;

export const SSelect = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  margin-bottom: 5px;
  border: 1px solid rgb(175, 180, 184);
  border-radius: 10px;
  margin-top: 10px;
`;
