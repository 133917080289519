import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      align-items: center;
      position: absolute;
      top: 13px;
      right: 13px;
      height: 38px;

      .menuItem {
        background: rgba(28, 29, 38, 0.25);
        border-radius: 38px;
        backdrop-filter: blur(3.5px);
        padding: 10px;
        margin-left: 5px;
        height: 38px;
        min-width: 38px;

        &.even {
          height: 38px;
          width: 38px;
        }
        &.icon {
          height: 38px;
          width: 38px;
          display: flex;
          justify-content: center;
          align-items: center;
          color: #ffffff;
        }
      }
    `
  ),
  Loader = styled.div(
    () => css`
      width: 38px !important;
      height: 38px !important;
      position: absolute !important;
      top: 25px;
      right: 25px;
    `
  );
