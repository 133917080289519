import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ROUTES } from 'app/constants';
import { useDispatch } from 'react-redux';
import { useDebounce, useGetItems } from 'hooks';
import { useStateSelector } from 'hooks/useStateSelector';
import { useGetIfUserCanEditCourse } from 'pages/coursesConstructor/hooks';
import { getRandomItemFromArray } from 'utils';
import { createEmptySlide } from '../helpers/createEmptySlide';

import { EMultichoiceType } from 'components/library/courseConstructor/components/TestConstructorContainer/components/InfoSlideTypeForm/components/Multichoice/types';
import { IDatabaseItemQuantified } from 'interface';
import { ISlideFull } from 'interface/courses';
import { TSlideConvertationType } from 'components/library/courseConstructor/components/WidgetsList/types';

import { DragEndEvent } from '@dnd-kit/core';
import Answer from 'components/library/messages/Answer';
import {
  coursesConstructorActions,
  coursesConstructorAPI,
  ESlideTypes,
  slideTypesForAIGeneratedTest,
  generateAnswersBySlideType,
  generateExampleNodes,
  NEW_ITEM_ID,
} from 'store';
import SlideSaver from 'components/library/messages/SlideSaver';
import { arrayMove } from '@dnd-kit/sortable';

export const useEditorFunctions = () => {
  const { slides } = useStateSelector((state) => state.coursesConstructor);
  const { lessonId, spaceName } = useParams<{ lessonId: string; spaceName?: string }>();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const debounce = useDebounce();
  const items = useGetItems();

  const [createSlide, { isLoading: isCreateSlideLoading }] = coursesConstructorAPI.useCreateSlideMutation();
  const [updateSlide, { isLoading: isSlideUpdating }] = coursesConstructorAPI.useUpdateSlideMutation();
  const [duplicateSlide, { isLoading: isDuplicationLoading }] = coursesConstructorAPI.useDuplicateSlideMutation();
  const [deleteSlide, { isLoading: isDeleting }] = coursesConstructorAPI.useDeleteSlideMutation();
  const [addAttachment, { isLoading: isAddAttachmentLoading }] = coursesConstructorAPI.useAddAttachmentMutation();
  const [deleteAttachment, { isLoading: isDeleteAttachmentLoading }] = coursesConstructorAPI.useDeleteAttachmentMutation();
  const [reorderSlides, { isLoading: isReordering }] = coursesConstructorAPI.useReorderSlidesMutation();
  const [generateTest, { isFetching: isGeneratingTest }] = coursesConstructorAPI.useLazyGenerateTestByTypeWithAiQuery();
  const {
    data: slidesData,
    isLoading: isSlidesLoading,
    isError,
  } = coursesConstructorAPI.useFetchSlidesQuery(lessonId as string, { refetchOnMountOrArgChange: true });
  const { data: allRewardsData, isLoading: isAllRewardsLoading } = coursesConstructorAPI.useFetchRewardsQuery({
    id: lessonId || '',
    subject: 'lesson',
  });
  const { data: courseData, isFetching: isCourseInfoLoading } = coursesConstructorAPI.useGetCourseInfoBySlideIdQuery(
    slides && slides[0] ? slides[0]._id : '',
    { skip: !slides[0] }
  );
  const isLoading =
    isCreateSlideLoading ||
    isSlideUpdating ||
    isDuplicationLoading ||
    isDeleting ||
    isAddAttachmentLoading ||
    isDeleteAttachmentLoading ||
    isReordering ||
    isSlidesLoading ||
    isAllRewardsLoading ||
    isGeneratingTest;

  const $slidesUpdateTimeoutMap = useRef<Record<string, number>>({});

  const breadcrumbs = slidesData?.breadcrumbs;
  const authors = slidesData?.authors ?? [];

  // State
  const [testSlideActiveIndex, setTestSlideActiveIndex] = useState(-1);
  const { isAllowed, isSpaceLoading } = useGetIfUserCanEditCourse(authors);
  const rews: IDatabaseItemQuantified[] = allRewardsData?.totalRewards ?? [];
  const allRewards = items.map((item) => ({
    ...item,
    quantity: rews.find((i) => i.id === item.id)?.quantity ?? 0,
  }));

  const isQuest = useMemo(() => (!!courseData ? courseData.isQuest : isCourseInfoLoading ? true : false), [courseData]);

  useEffect(() => {
    if (authors.length > 0) {
      if (!isAllowed && !isSpaceLoading) {
        navigate(ROUTES.COURSES_CONSTRUCTOR);
      }
    }
  }, [authors, isAllowed, isSpaceLoading]);

  const handleErrorToast = (message: string, error: any) => {
    console.error(error);
    toast.error(<Answer label={message} subtext={error?.data?.message} type="incorrect" />);
  };

  // Widget handlers
  const handleCreateSlide = (typeOfSlide: ESlideTypes, index: number) => () => {
    const name = 'Slide ' + (slides.length + 1);
    const emptySlide = createEmptySlide(lessonId as string, typeOfSlide, name);
    emptySlide.name = name;
    createSlide({ ...emptySlide, slideIndex: index + 1 })
      .unwrap()
      .catch((ex) => {
        handleErrorToast('Error creating slide', ex);
      });
  };

  const handleCreateEmptyTestSlide = (index: number) => (type: ESlideTypes) => {
    setTestSlideActiveIndex(-1);
    dispatch(coursesConstructorActions.createEmptyTestSlide({ type, lessonId, slideIndex: index + 1, name: 'Slide ' + (slides.length + 1) }));
  };

  const handleDeleteSlide = (slideId: string) => () => {
    deleteSlide(slideId)
      .unwrap()
      .catch((ex) => {
        handleErrorToast('Error deleting slide', ex);
      });
  };

  const handleTestSlideAdd = (index: number) => () => {
    setTestSlideActiveIndex(index);
  };

  const handleUpdateSlide = debounce((newSlide: ISlideFull) => {
    const slide = slides.find((item) => item._id === newSlide._id);
    if (!slide) {
      return;
    }

    if (slide._id === NEW_ITEM_ID) {
      createSlide(newSlide)
        .unwrap()
        .catch((ex) => {
          handleErrorToast('Error on slide create', ex);
        });
      return;
    }

    dispatch(coursesConstructorActions.updateSlide(newSlide));

    if ($slidesUpdateTimeoutMap.current[slide._id]) {
      window.clearTimeout($slidesUpdateTimeoutMap.current[slide._id]);
    }

    $slidesUpdateTimeoutMap.current[slide._id] = window.setTimeout(() => {
      updateSlide(newSlide)
        .unwrap()
        .then(() => {
          toast.info(<SlideSaver isLoading={isLoading} />, { autoClose: 2500 });
        })
        .catch((ex) => {
          handleErrorToast('Error updating slide', ex);
          dispatch(coursesConstructorActions.updateSlide(slide));
        });
    }, 1500);
  }, 400);

  const handleAddAttachment = async (props: any) => {
    await addAttachment(props);
    toast.info(<Answer label="Attachment uploaded" />, {
      autoClose: 2500,
      toastId: 'attachmentUploaded',
    });
  };

  const handleDeleteAttachment = async (props: any) => {
    await deleteAttachment(props);
    toast.info(<Answer label="Attachment deleted" />, {
      autoClose: 2500,
      toastId: 'attachmentDeleted',
    });
  };

  const handleDuplicateSlide = (index: number) => () => {
    const slideToCopy = slides[index];

    if (!slideToCopy) return;
    duplicateSlide({ ...slideToCopy, slideIndex: index })
      .unwrap()
      .catch((ex) => {
        handleErrorToast('Error duplicating slide', ex);
      });
    return;
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const slidesCopy = [...slides];
    const oldIndex = slidesCopy.findIndex((slide) => slide._id === active.id);
    const newIndex = slidesCopy.findIndex((slide) => slide._id === over.id);
    const newSlides = arrayMove(slidesCopy, oldIndex, newIndex);

    const slidesIds = newSlides.map((slide: ISlideFull) => slide._id);

    dispatch(coursesConstructorActions.reorderSlides(newSlides));
    reorderSlides({ lessonId: lessonId, slidesIds })
      .unwrap()
      .catch((ex) => {
        dispatch(coursesConstructorActions.reorderSlides(slidesCopy));
        handleErrorToast('Error reordering slides', ex);
      });
  };

  const getRandomTestType = () => getRandomItemFromArray(slideTypesForAIGeneratedTest);

  const handleSlideConvertation = useCallback(
    async (to: TSlideConvertationType, type: ESlideTypes, slideId: string, isGenerated?: boolean, isRandomTestType?: boolean) => {
      const slide = slides.find((slide) => slide._id === slideId);
      const convertedSlide = structuredClone(slide);
      if (convertedSlide?.slideType) {
        if (to === 'toInfo') {
          convertedSlide.slideType = {
            typeOfSlide: ESlideTypes.INFO,
            answers: [{ text: 'text' }],
            isAnyCorrect: false,
          };
          convertedSlide.typeOfSlide = ESlideTypes.INFO;
        } else {
          if (isGenerated) {
            if (isRandomTestType) {
              type = getRandomTestType();
            }
            await generateTest({ type, slideId })
              .unwrap()
              .then((result) => {
                const res = structuredClone(result);
                convertedSlide.slideType = res.slideType;
                convertedSlide.typeOfSlide = res.typeOfSlide;
                convertedSlide.content = res.content;
              })
              .catch((ex) => {
                handleErrorToast('Error generating test', ex);
              });
            await handleUpdateSlide(convertedSlide);
            return;
          }
          const { answers, rightAnswers } = generateAnswersBySlideType(type);
          const nodes = generateExampleNodes(type);
          convertedSlide.slideType.nodes = nodes;
          convertedSlide.slideType.answers = answers;
          convertedSlide.slideType.isOptional = true;
          //@ts-ignore
          convertedSlide.slideType.rightAnswers = rightAnswers;
          if (type === ESlideTypes.MULTICHOICE) {
            convertedSlide.slideType.type = rightAnswers.length > 1 ? EMultichoiceType.MULTI : EMultichoiceType.SINGLE;
          }
          convertedSlide.typeOfSlide = type;
        }

        await handleUpdateSlide(convertedSlide);
      }
    },
    [slides]
  );

  const toggleAnyCorrect = (slideId: string) => {
    const slide = slides.find((slide) => slide._id === slideId);
    if (slide) {
      const newSlide = structuredClone(slide);
      newSlide.slideType.isAnyCorrect = !slide?.slideType.isAnyCorrect;
      handleUpdateSlide(newSlide);
    }
  };

  const toggleIsOptional = (slideId: string) => {
    const slide = slides.find((slide) => slide._id === slideId);
    if (slide) {
      const newSlide = structuredClone(slide);
      newSlide.slideType.isOptional = !slide?.slideType.isOptional;
      handleUpdateSlide(newSlide);
    }
  };

  return {
    slides,
    isLoading,
    isCreateSlideLoading,
    isSlideUpdating,
    isDuplicationLoading,
    isDeleting,
    isAddAttachmentLoading,
    isDeleteAttachmentLoading,
    isReordering,
    isSlidesLoading,
    isAllRewardsLoading,
    isGeneratingTest,
    slidesData,
    allRewardsData,
    courseData,
    isError,
    breadcrumbs,
    testSlideActiveIndex,
    allRewards,
    isQuest,
    spaceName,
    handleCreateSlide,
    handleCreateEmptyTestSlide,
    handleDeleteSlide,
    handleTestSlideAdd,
    handleUpdateSlide,
    handleAddAttachment,
    handleDeleteAttachment,
    handleDuplicateSlide,
    handleDragEnd,
    handleSlideConvertation,
    toggleAnyCorrect,
    toggleIsOptional,
  };
};
