import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

export const SMenuItem = styled.button(
    ({ theme }) => css`
      background: transparent;
      outline: none;
      border: none;
      height: 30px;
      width: 100%;
      color: var(--font-color);
      text-align: left;
      padding-left: 15px;
      padding-right: 15px;
      white-space: nowrap;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      &.topic {
        text-transform: uppercase;
        color: #afb4b8;
        font-size: 12px;
        pointer-events: none;
      }

      &:hover {
        background: ${hexToRgba(theme.theme.fontColor, 0.1)};
      }

      svg {
        width: 16px;
        height: auto;
      }
    `
  ),
  SMenuItemContent = styled.div(
    () => css`
      display: flex;
      align-items: center;
    `
  ),
  SMenu = styled.div(
    ({ theme }) => css`
      border-radius: 5px;
      border: 2px solid rgba(255, 255, 255, 0.05);
      padding: 3px 0;
      background: ${theme.theme.background};
      min-width: 170px;
    `
  ),
  SMenuItemIcon = styled.span(
    () => css`
      margin-right: 10px;
    `
  );
