import styled, { css } from 'styled-components';
import { TDrawerPosition, TDrawerVariant } from './types';
import { THEMES } from 'app/constants';

export const SDrawerOverlay = styled.div<{ $isOpen: boolean; $withOverlay: boolean }>(
  ({ $isOpen, $withOverlay }) => css`
    position: fixed;
    top: 0;
    left: 0;
    min-width: 100vw;
    min-height: 100vh;
    background: ${$withOverlay ? 'rgba(0, 0, 0, 0.4)' : 'transparent'};
    z-index: -1;
  `
);

export const SDrawerHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
`;

export const SDrawer = styled.div<{ $position: TDrawerPosition; $isOpen: boolean; $width: string | undefined; $variant: TDrawerVariant }>`
  position: fixed;
  will-change: transform;
  z-index: 1011;
  color: var(--font-color);

  ${({ $variant }) =>
    $variant === 'default' &&
    css`
      background: radial-gradient(100% 60% at 100% 100%, rgba(98, 126, 234, 0.2) 0%, rgba(0, 0, 0, 0.02) 97.05%), #1c1d26;

      &.light {
        background: radial-gradient(73.04% 270.4% at 142.59% 81.5%, rgba(98, 126, 234, 0.2) 0%, rgba(0, 0, 0, 0.02) 97.05%), #fff;
      }
    `}

  ${({ $position, $isOpen, $width }) =>
    $position === 'right' &&
    css`
      right: 0;
      top: 0;
      width: ${$width ? $width : '344px'};
      height: 100%;
      padding: 30px 40px;
    `}

  ${({ $position, $isOpen, $width }) =>
    $position === 'left' &&
    css`
      left: 0;
      top: 0;
      width: ${$width ? $width : '344px'};
      height: 100%;
      padding: 30px 40px;
    `}
  
  ${({ $position, $isOpen, $width }) =>
    $position === 'top' &&
    css`
      top: 0;
      left: 0;
      right: 0;
      width: ${$width ? $width : '100%'};
      height: 30%;
      padding: 30px 40px;
    `}  
  
  ${({ $position, $isOpen, $width }) =>
    $position === 'bottom' &&
    css`
      bottom: 0;
      left: 0;
      right: 0;
      width: ${$width ? $width : '100%'};
      height: 30%;
      padding: 30px 40px;
    `}

  max-width: 100vw;
`;

export const SButton = styled.div<{ $theme?: THEMES }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 5px;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#11111166' : '#ffffff66')};
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    background: ${(p) => (p.$theme === THEMES.LIGHT ? '#11111126' : '#ffffff26')};
  }
  &:active {
    color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111111' : '#ffffff')};
    background: ${(p) => (p.$theme === THEMES.LIGHT ? '#11111126' : '#ffffff26')};
  }
  & > * {
    object-fit: contain;
  }
`;
