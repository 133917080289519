import * as Styled from './styles';
import PocketItem from './PocketItem';

type Props = {
  children: any;
};

export default function Pocket({ children }: Props) {
  return (
    <Styled.Container className="pocket">
      {children.map((el: any) => (
        <PocketItem key={el.label} label={el.label} className={el.className}>
          {el.node}
        </PocketItem>
      ))}
    </Styled.Container>
  );
}
