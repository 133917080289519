import { useSelector } from 'react-redux';
import * as S from './styles';
import { getItems } from 'store';
import { useMemo } from 'react';
import { ReactComponent as DeleteIcon } from 'assets/icons/trash.svg';
import { useGetCurrentTheme } from 'hooks';

type Props = {
  id: number;
  quantity: number;
  deleteReward: (id: number) => void;
};

export const RewardItem = ({ id, quantity, deleteReward }: Props) => {
  const theme = useGetCurrentTheme();
  const items = useSelector(getItems);
  const fullItem = useMemo(() => items.find((el) => el.id === id), [id, items]);

  const handleDelete = () => {
    deleteReward(id);
  };

  return (
    <S.ItemWrapper initial={{ x: -20, opacity: 0 }} animate={{ x: 0, opacity: 1 }} exit={{ x: -20, opacity: 0 }}>
      <S.Item $theme={theme}>
        <S.DeleteView onClick={handleDelete}>
          <DeleteIcon />
        </S.DeleteView>
        <S.Img src={fullItem?.image} />
        <S.Amount>{quantity}</S.Amount>
      </S.Item>
    </S.ItemWrapper>
  );
};

export default RewardItem;
