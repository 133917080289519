import * as Styled from './styles';
import Drawer from 'shared/ui/drawer/Drawer';
import { IoMdMenu } from 'react-icons/io';
import Paginator from '../Paginator';
import { INamedDatabaseEntity } from 'interface/courses';

type Props = {
  slides: INamedDatabaseEntity[];
};

export default function MobileWrapper({ slides }: Props) {
  return (
    <Drawer
      className={'drawer'}
      position="right"
      isVisible={false}
      triggerNode={
        <Styled.Button>
          <IoMdMenu />
        </Styled.Button>
      }
    >
      <Styled.Container>
        <Paginator items={slides} />
      </Styled.Container>
    </Drawer>
  );
}
