import { FC, Suspense, lazy } from 'react';
import ReactDOM from 'react-dom';
import { useDisplay } from 'hooks';
import { useWindowItem } from '../hooks';
import { IWindowItem, TArtifactWindowType, TArtifactWindowVariant } from '../types/types';

const DesktopWindow = lazy(() =>
  import(/* webpackChunkName: "inventoryDesktopWindow" */ './desktop/DesktopWindow').then(({ DesktopWindow }) => ({ default: DesktopWindow }))
);
const MobileWindow = lazy(() =>
  import(/* webpackChunkName: "inventoryMobileWindow" */ './mobile/MobileWindow').then(({ MobileWindow }) => ({ default: MobileWindow }))
);

interface ArtifactWindowProps {
  closeWindow: () => void;
  isOpen: boolean;
  windowItem?: IWindowItem;
  type?: TArtifactWindowType;
  variant?: TArtifactWindowVariant;
}

export const ArtifactWindow: FC<ArtifactWindowProps> = ({ closeWindow, isOpen, windowItem, type = 'item', variant = 'default' }) => {
  // return <>Under reconstruction</>;
  // вернуть, когда будут айтемы для кастомизации

  const { theme, activeSidebar, item, otherItems, toggleSidebar, toggleTheme, currentButtonBg, amountInInventory } = useWindowItem({
    windowItem,
    type,
  });
  const { isMobile } = useDisplay();

  const portalContainer = document.querySelector('body');

  if (!portalContainer || item === null) {
    return null;
  }

  if (!isMobile) {
    return ReactDOM.createPortal(
      <Suspense fallback={<></>}>
        <DesktopWindow
          isOpen={isOpen}
          theme={theme}
          activeSidebar={activeSidebar}
          item={item}
          otherItems={otherItems}
          toggleSidebar={toggleSidebar}
          currentButtonBg={currentButtonBg}
          closeWindow={closeWindow}
          toggleTheme={toggleTheme}
          amountInInventory={amountInInventory}
          isOutlined={item.type === 'non-fungible' || item.type === 'digital'}
          variant={variant}
          type={type}
        />
      </Suspense>,
      portalContainer
    );
  }
  return ReactDOM.createPortal(
    <Suspense fallback={<></>}>
      <MobileWindow
        isOpen={isOpen}
        theme={theme}
        activeSidebar={activeSidebar}
        item={item}
        otherItems={otherItems}
        toggleSidebar={toggleSidebar}
        currentButtonBg={currentButtonBg}
        closeWindow={closeWindow}
        toggleTheme={toggleTheme}
        amountInInventory={amountInInventory}
        isOutlined={item.type === 'non-fungible' || item.type === 'digital'}
        variant={variant}
        type={type}
      />
    </Suspense>,
    portalContainer
  );
};
