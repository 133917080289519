import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';

import * as Styled from './styles';

type Props = {
  tabsFilled: number;
  tabsTotal: number;
  className?: string;
};

const MiniTabsProgressBar = ({ className = '', tabsTotal = 0, tabsFilled = 0 }: Props) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <Styled.Container className={className}>
      {[...Array(tabsTotal).keys()].map((tab) => (
        <Styled.Tab className={`${tab < tabsFilled && 'active'} ${theme}`} key={'progress-tab-' + tab} />
      ))}
    </Styled.Container>
  );
};

export default MiniTabsProgressBar;
