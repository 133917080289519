import { ICourse } from 'interface/courses';
import type { CourseCardProps } from 'shared/ui/course-card';
import { useDisplay, useGetCourseGroups } from 'hooks';
import clsx from 'clsx';

import { CourseCard } from 'shared/ui/course-card';
import { InView } from 'shared/ui/motion';

import * as Styled from './styles';

type Props = {
  className?: string;
  bundleName: string;
  bundle: ICourse[];
  onClick: (courseId: string) => any;
  courseCardProps?: Partial<CourseCardProps>;
  isHeader?: boolean;
  isHeaderMinis?: boolean;
};

const coursesBundleDuration = 0.1;

const variantBundle = {
  desktop: {
    initial: {
      opacity: 0,
      x: -220,
    },
    whileInView: {
      opacity: 1,
      x: 0,
    },
    transition: (index: number) => ({ delay: index * coursesBundleDuration, type: 'spring' }),
  },
  mobile: {
    initial: {
      opacity: 0,
      x: '-30%',
    },
    whileInView: {
      opacity: 1,
      x: 0,
    },
    transition: (index: number) => ({ delay: index * coursesBundleDuration, type: 'spring' }),
  },
};

const CoursesBundle = ({ bundleName = '', className = '', bundle = [], onClick, courseCardProps, isHeader = true, isHeaderMinis = false }: Props) => {
  const { EGroupTag, partnerOriginals } = useGetCourseGroups();
  const { isMobile } = useDisplay(),
    regular: ICourse[] = bundle.filter((course) => !course.isMini),
    minis: ICourse[] = bundle.filter((course) => course.isMini),
    variant = isMobile ? variantBundle.mobile : variantBundle.desktop,
    displayedName = bundleName === EGroupTag.Originals ? partnerOriginals : bundleName;

  return (
    <Styled.Container className={className}>
      <Styled.Header className={clsx({ hidden: !isHeader })}>{displayedName}</Styled.Header>
      {Boolean(regular.length) && (
        <Styled.Courses>
          {regular.map((course, index) => (
            <InView
              className={clsx('inview regular', { mobile: isMobile })}
              forcedOnMobile
              key={course._id}
              initial={variant.initial}
              whileInView={variant.whileInView}
              transition={variant.transition(index)}
            >
              <CourseCard course={course} selectCourse={onClick(course._id)} {...courseCardProps} />
            </InView>
          ))}
        </Styled.Courses>
      )}
      <Styled.Header className={clsx({ hidden: !isHeaderMinis })}>{bundleName}</Styled.Header>
      {Boolean(minis.length) && (
        <Styled.Courses
          className={clsx('minis no-scroll-bar mobile-h-scrollable', {
            mobile: isMobile,
          })}
        >
          {minis.map((course, index) => (
            <InView
              className={clsx('inview', { mobile: isMobile })}
              forcedOnMobile
              key={course._id}
              initial={variant.initial}
              whileInView={variant.whileInView}
              transition={variant.transition(index)}
            >
              <CourseCard course={course} selectCourse={onClick(course._id)} {...courseCardProps} />
            </InView>
          ))}
        </Styled.Courses>
      )}
    </Styled.Container>
  );
};

export default CoursesBundle;
