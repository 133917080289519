export function getDataByLanguage(array: any[], language: string): string {
  const dataObj = array.find((desc) => desc.language === language);

  if (dataObj) {
    return dataObj.title;
  }

  const defaultData = array.find((desc) => desc.language === 'en');

  return defaultData ? defaultData.title : '';
}
