import clsx from 'clsx';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentTheme, getCurrentAvatarShape } from 'store';
import { IProfile } from 'interface';
import { useDisplay } from 'hooks';

import ShowThings from '../ShowThings/show-things';
import { ReactComponent as Camera } from '../../icons/camera.svg';
import UserExpLabel from 'shared/ui/user-exp-label/label';
import SocialsList from '../Socials/socials-list';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import Nickname from 'shared/ui/nickname/Nickname';
// import { PremiumIcon } from 'shared/ui';
import { SlowSlideX } from 'shared/ui/motion';

import styles from './styles.module.scss';
import { memo } from 'react';

interface IProps {
  handleImageUpload?: (e: React.ChangeEvent<HTMLInputElement>) => Promise<void>;
  userProfile: Pick<IProfile, 'nickname' | 'avatar' | 'exp' | 'customization' | 'friends'>;
  className?: string;
}

function ProfileCard({ handleImageUpload, userProfile, className }: IProps) {
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme, shallowEqual);
  const shape = useSelector(getCurrentAvatarShape, shallowEqual);
  // const { isPremium } = useGetIsPremium();
  const { nickname, avatar, exp, customization, friends } = userProfile;

  if (!nickname || !friends) return null;

  return (
    <SlowSlideX className={clsx(styles.container, styles[theme], className)}>
      <div className={styles.info}>
        <Nickname nickname={nickname} customization={customization} className={styles.nickname} />
        <UserExpLabel customization={customization} exp={exp.balance.amount} />
        {/* {isPremium && <PremiumIcon className={styles.premiumIcon} />} */}
        <SocialsList friends={friends} />
        {!isMobile && <ShowThings />}
      </div>

      <div className={clsx(styles.avatar, `avatar--${shape}`)}>
        <AvatarPic isFramed={false} className={styles.avacontainer} customization={customization} nickname={nickname} avatar={avatar} />
        {handleImageUpload && (
          <div className={styles.downloaderWrapper}>
            <label className={styles.downloader} title="Change avatar">
              <input onChange={handleImageUpload} type="file" name="img" accept=".jpg, .png, .svg"></input>
              <Camera />
            </label>
          </div>
        )}
      </div>
    </SlowSlideX>
  );
}

export default memo(ProfileCard);
