import * as S from './styles';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { AnimatePresence } from 'framer-motion';
import { useRive } from '@rive-app/react-canvas';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebounce, useGetCurrentTheme } from 'hooks';

export const Loader = ({ isLoading }: { isLoading?: boolean }) => {
  return (
    <>
      {isLoading && (
        <S.LoaderBox initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.5 } }} exit={{ opacity: 0 }}>
          <MightyLoaderMini />
        </S.LoaderBox>
      )}
    </>
  );
};

type Props = {
  isUnlocking: boolean;
  isTask?: boolean;
};

export const Placeholder = ({ isTask, isUnlocking }: Props) => {
  const [isAnimationLoaded, setAnimationLoaded] = useState(false);
  const theme = useGetCurrentTheme();
  const animSrc = useMemo(() => process.env.REACT_APP_STATIC_HOST + `/animations/lock_${theme}.riv`, [theme]);

  const { rive, RiveComponent } = useRive({
    src: animSrc,
    onLoad: () => setAnimationLoaded(true),
    autoplay: false,
  });

  useEffect(() => {
    if (isUnlocking && isAnimationLoaded) {
      rive && rive.play();
    }
    if (!isUnlocking) {
      rive && rive.reset();
    }
  }, [rive, isUnlocking, isAnimationLoaded]);

  return (
    <S.Box
      initial={{ opacity: 0, filter: 'blur(10px)' }}
      animate={{ opacity: 1, filter: 'blur(0px)', transition: { delay: 0.5 } }}
      exit={{ opacity: 0, filter: 'blur(10px)', transition: { duration: 0.5 } }}
    >
      <S.Container>
        <S.AnimationContainer>
          <RiveComponent />
        </S.AnimationContainer>
        <AnimatePresence>
          {!isUnlocking && (
            <S.Sub
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, filter: 'blur(0px)', transition: { delay: 0.5 }, height: 24 }}
              exit={{ filter: 'blur(10px)', opacity: 0, height: 0 }}
            >
              Complete the {isTask ? 'task' : 'test'} to progress forward
            </S.Sub>
          )}
        </AnimatePresence>
        <S.FakeSlide>
          <S.FakeTitle>Guides and safe areas</S.FakeTitle>
          <S.FakeSub>
            It is the mark of an educated mind to be able to entertain a thought without accepting it. No great mind has ever existed without a touch
            of madness. Those who know, do. Those that understand, teach. The high-minded man must care more for the truth than for what people think.
          </S.FakeSub>
        </S.FakeSlide>
      </S.Container>
    </S.Box>
  );
};
