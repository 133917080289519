import { useGetUserData } from 'hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRefetchHistoryQuery } from 'store';

export const useGetInitialNotifsCount = () => {
  const { data, refetch } = useRefetchHistoryQuery({ limit: 0 });
  const [counter, setCounter] = useState(data?.totalEvents ?? 0);
  const user = useGetUserData(true);

  const changesWardString = useMemo(() => {
    try {
      const {
        exp: {
          items: userItems,
          limit: { effects },
          balance: { amount },
        },
      } = user;
      return userItems
        .map((i) => i.quantity.toString())
        .concat(amount.toString())
        .concat(...effects.map((i) => i.expiredAt))
        .join(',');
    } catch (error) {
      return [];
    }
  }, [user._id]);

  const fetchNotifsCount = useCallback(() => {
    refetch()
      .unwrap()
      .then(() => setCounter(data?.totalEvents ?? 0))
      .catch(console.error);
  }, [refetch, data?.totalEvents]);

  useEffect(() => {
    fetchNotifsCount();

    return () => {
      setCounter(0);
    };
  }, [fetchNotifsCount, changesWardString, user?.history?.events, data?.totalEvents]);

  return { counter, refetch: fetchNotifsCount };
};
