import { Link, useLocation } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import clsx from 'clsx';

import { ReactComponent as Right } from '../images/Interface/NavRight.svg';
import { ReactComponent as Left } from '../images/Interface/NavLeft.svg';
import { ReactComponent as Settings } from './assets/pictures/settings.svg';
import { ReactComponent as Bell } from './assets/pictures/bell.svg';

import SearchMain from './Search/SearchMain';
import { Logo } from 'shared/utils';

import './styles/navbar-style.css';
import styles from './styles.module.css';
import { Nav, NavIconWrapper, NavWrapper, SearchbarWrapper, SignUp } from './styles';
import { useNavigateToAuth } from 'hooks';
import { Flex } from 'shared/ui';

export const NavBarUnAuth = () => {
  const navToAuth = useNavigateToAuth();
  const { pathname } = useLocation();

  return (
    <NavWrapper className="navbar">
      <Logo withLabel height={38} size="xsmall" />
      <SearchbarWrapper>
        <Left style={{ margin: '0px', minWidth: 24 }} />
        <Nav className="glass-div">
          <SearchMain />
          <NavIconWrapper className={`nav-notifications bright--hover`} onClick={() => navToAuth()}>
            <Bell />
          </NavIconWrapper>
          <NavIconWrapper className="bright--hover" onClick={() => navToAuth()}>
            <Settings className="settings" />
          </NavIconWrapper>
        </Nav>
        <Right style={{ margin: '0px', minWidth: 24 }} />
      </SearchbarWrapper>
      <Flex>
        <Link className={clsx(styles.link, styles.login)} to={ROUTES.AUTH} state={{ prev: pathname }}>
          Log in
        </Link>
        <SignUp className={clsx('blur-5')}>
          <Link className={clsx(styles.link)} to={ROUTES.AUTH} state={{ prev: pathname }}>
            Sign up
          </Link>
        </SignUp>
      </Flex>
    </NavWrapper>
  );
};
