import { THEMES } from 'app/constants';
import styled, { css } from 'styled-components';

export const Container = styled.div<{$theme?: THEMES}>(
    ({$theme}) => css`
      height: 76px;
      padding: 16px 29px 16px 20px;
      display: flex;
      align-items: center;
      border-radius: 10px;
      border: 1px solid #6487ef;
      background: ${$theme === THEMES.DARK ? '#23273a' : 'rgb(var(--glass-bg--rgb))'};
    `
  ),
  Text = styled.span<{$theme?: THEMES}>(
    ({$theme}) => css`
      margin-left: 12px;
      min-width: 73px;
      color: ${$theme === THEMES.DARK ? 'var(--basic-dark)' : 'var(--mighty-blue)'};
    `
  );
