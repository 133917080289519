import { FC } from 'react';
import { Player, IPlayerProps } from '@lottiefiles/react-lottie-player';
import { THEMES } from 'app/constants';

import dailyAnimationLight from 'assets/animations/daily-checkmark--light.json';
import dailyAnimationDark from 'assets/animations/daily-checkmark.json';
import dailyAnimationGoldWithGradient from 'assets/animations/daily-checkmark-full.json';

interface Props extends Omit<IPlayerProps, 'src'> {
  theme?: THEMES;
  variant?: 'default' | 'gold';
}

export const DailyCheckLottie: FC<Props> = ({ theme, variant = 'default', ...rest }) => {
  let src;

  if (variant === 'default') {
    src = theme === THEMES.DARK ? dailyAnimationDark : dailyAnimationLight;
  } else {
    src = dailyAnimationGoldWithGradient;
  }

  return <Player autoplay keepLastFrame src={src} {...rest} />;
};
