import { FC } from 'react';
import { TInfoBadgeProps } from './types';

import { ReactComponent as OrangeInfoIcon } from 'assets/icons/orange-info.svg';

import * as Elements from './styles';

const InfoBadge: FC<TInfoBadgeProps> = ({ type, children, ...props }) => {
  return (
    <Elements.SRoot {...props}>
      <OrangeInfoIcon />
      &nbsp;{children}
    </Elements.SRoot>
  );
};

export default InfoBadge;
