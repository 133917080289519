import { FC } from 'react';

import styles from './styles.module.css';

type TDisabledOverlayProps = {
  children: React.ReactNode;
};

const DisabledOverlay: FC<TDisabledOverlayProps> = ({ children }) => {
  return (
    <div className={styles.root}>
      {children}
      <div className={styles.overlay} />
    </div>
  );
};

export default DisabledOverlay;
