import { ButtonShowActivatedCodes, ContainerActivatedCodes } from './style';
import { useState } from 'react';
import { useDisplay } from 'hooks';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';

import { ReactComponent as Check } from 'assets/icons/checked.svg';

import { DesktopModalActivationCode } from '../modal/desktop';
import { ModalActivatedCodes } from '../modal/mobile';
import BasicButton from 'shared/ui/basic-button/button';

interface IProps {
  variant?: 'small' | 'mighty-basic';
}
export const ActivatedCodes = ({ variant }: IProps) => {
  const theme = useSelector(getCurrentTheme);
  const [isOpen, setIsOpen] = useState(false);
  const { isMobile } = useDisplay();

  return (
    <ContainerActivatedCodes className={variant === 'mighty-basic' ? 'small' : variant}>
      {variant === 'mighty-basic' ? (
        <BasicButton label={'Show all codes'} onClick={() => setIsOpen(true)} variant={'cool'} />
      ) : (
        <>
          {!isMobile ? (
            <div style={{ color: '#627EEA', fontSize: '20px', cursor: 'pointer' }} onClick={() => setIsOpen(true)}>
              See codes
            </div>
          ) : (
            <ButtonShowActivatedCodes className={clsx(variant, theme)} onClick={() => setIsOpen(true)}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Check /> Show Activated codes
              </div>
            </ButtonShowActivatedCodes>
          )}
        </>
      )}
      {isMobile ? (
        <ModalActivatedCodes isOpen={isOpen} onClose={() => setIsOpen(false)} />
      ) : (
        <DesktopModalActivationCode isOpen={isOpen} onClose={() => setIsOpen(false)} />
      )}
    </ContainerActivatedCodes>
  );
};
