import * as S from './styles';
import RewardItem from '../Item';
import { IRewardMinimal } from 'store';
import { IDatabaseItem } from 'interface';
import { useMemo } from 'react';
import { useGetCurrentTheme } from 'hooks';

type Props = {
  items: IDatabaseItem[];
  rewards: IRewardMinimal[];
  updateReward: (id: number, quantity: number) => void;
  search?: string | null;
};

const List = ({ items, rewards, updateReward, search }: Props) => {
  const lower = (str: string) => str.toLowerCase();

  const filtered = useMemo(() => (search ? items.filter((el) => lower(el.name).includes(lower(search))) : items), [search, items]);
  const sorted = useMemo(
    () =>
      search
        ? filtered.sort((a, b) => {
            return Number(lower(b.name).startsWith(lower(search))) - Number(lower(a.name).startsWith(lower(search)));
          })
        : filtered,
    [search, filtered]
  );
  const theme = useGetCurrentTheme();

  return (
    <S.Container initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
      <S.Header $theme={theme}>
        <S.Heading $theme={theme}>Item</S.Heading>
        <S.Heading $theme={theme} style={{ marginLeft: 'auto', minWidth: '102px' }}>
          Amount
        </S.Heading>
      </S.Header>
      <S.List>
        {sorted.map((el) => (
          <RewardItem key={'RewardItem' + el.fungibleType + el.id} rewards={rewards} updateReward={updateReward} item={el} />
        ))}
      </S.List>
    </S.Container>
  );
};

export default List;
