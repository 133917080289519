import { FC, useEffect } from 'react';
import { useSlideContext } from '../SlideContext';
import { ESlideTypes, IRewardMinimal, ISlideWidget, NEW_ITEM_ID } from 'store';
import { TTestCourseConstructorContainerProps } from './types';

import WidgetsList from '../WidgetsList';
import CourseConstructorContainer from '../Container';
import TestSlideForm from './components/InfoSlideTypeForm';
import BlurOverlay from 'components/library/blurOverlay';

import styles from './styles.module.css';

const TestCourseConstructorContainer: FC<TTestCourseConstructorContainerProps> = ({ onSlideDelete, onChange, allRewards, path, isQuest }) => {
  const { slide } = useSlideContext();

  useEffect(() => {
    if (slide._id === NEW_ITEM_ID) {
      handleTitleChange(slide.name);
    }
  }, [slide._id]);

  const handleTitleChange = (value: string) => {
    const newSlide = { ...slide, name: value };

    onChange(newSlide);
  };

  const handleWidgetsChange = (widgets: ISlideWidget[]) => {
    onChange({ ...slide, content: widgets });
  };

  const handleRewardsChange = (newRewards: IRewardMinimal[]) => {
    onChange({ ...slide, rewards: newRewards });
  };

  return (
    <div className={styles.root}>
      <CourseConstructorContainer
        allRewards={allRewards}
        onSlideDelete={onSlideDelete}
        title={slide.name}
        path={path}
        // inUpdate={isInUpdate}
        slideId={slide._id}
        onTitleChange={handleTitleChange}
        hideControls={!slide.typeOfSlide}
        onRewardsChange={handleRewardsChange}
        rewards={slide.rewards}
        isQuest={isQuest}
      >
        {slide.typeOfSlide && slide.typeOfSlide !== ESlideTypes.INFO ? (
          <div className={styles.slideContent}>
            {slide._id === NEW_ITEM_ID && <BlurOverlay />}

            <WidgetsList items={slide.content} onChange={handleWidgetsChange} />
            <TestSlideForm
              type={slide.typeOfSlide}
              widgets={slide.content}
              answers={slide.slideType.answers}
              slideId={slide._id}
              onChange={onChange}
              //@ts-ignore
              rightAnswers={slide.slideType.rightAnswers}
            />
          </div>
        ) : null}
      </CourseConstructorContainer>
    </div>
  );
};

export default TestCourseConstructorContainer;
