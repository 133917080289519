import clsx from 'clsx';
import { useGetCurrentTheme } from 'hooks';

import * as Styled from './styles';

interface IProps {
  className?: string;
  label?: string | React.ReactElement;
  icon?: any;
  isDisabled?: boolean;
  onClick?: () => void;
}

export function CircleButton({ className = '', label, icon, isDisabled, onClick }: IProps) {
  const theme = useGetCurrentTheme();
  return (
    <Styled.Container
      className={clsx(theme, {
        [className]: !!className,
      })}
      onClick={onClick}
      disabled={isDisabled}
    >
      <>
        {icon}
        {label}
      </>
    </Styled.Container>
  );
}
