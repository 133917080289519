import { FC, PropsWithChildren, useRef, RefObject } from 'react';
import ReactDOM from 'react-dom';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useTooltip } from './useTooltip';
import { AnimatePresence, motion } from 'framer-motion';

import { STooltip } from './styles';

interface ITooltipProps extends PropsWithChildren {
  triggerRef: RefObject<any>;
  className?: string;
  size?: 'small' | 'large';
  maxWidth?: number;
  position?: '' | 'cool';
  isMovable?: boolean;
  margin?: number;
  clickable?: boolean;
  isRight?: boolean;
}
export const Tooltip: FC<ITooltipProps> = ({
  triggerRef,
  children,
  className = '',
  size = 'large',
  maxWidth,
  position = '',
  isMovable = false,
  margin = 10,
  clickable = false,
  isRight = false,
}) => {
  const theme = useSelector(getCurrentTheme);

  const $tooltipRef = useRef<HTMLDivElement>(null);

  const showTooltip = useTooltip({ tooltipRef: $tooltipRef, triggerRef, position, isMovable, margin, clickable, isRight });

  return ReactDOM.createPortal(
    <AnimatePresence initial={false}>
      {showTooltip && (
        <STooltip
          as={motion.div}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className={clsx(`${theme} ${className}`)}
          style={{ position: 'fixed', visibility: 'hidden', zIndex: 2000 }}
          ref={$tooltipRef}
          $size={size}
          $maxWidth={maxWidth}
        >
          {children}
        </STooltip>
      )}
    </AnimatePresence>,
    document.getElementById('portal')!
  );
};
