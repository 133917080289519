import { FC, useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { getCooldown } from 'utils/cooldowns';
import { EAnimationStates } from '../../types';
import { panelVariants } from './motion/variants';
import { useDailyRewardsContext } from '../../context';

import { CurrentRewards, NextRewards, Streak } from '../ui';

import { HiArrowRight } from 'react-icons/hi';
import { ReactComponent as Spinner } from 'assets/icons/spinner--pie.svg';

import BasicButton from 'shared/ui/basic-button/button';

import * as Styled from './styled';
import dayjs from 'dayjs';
import { useDisplay } from 'hooks';
import clsx from 'clsx';

const rewardsUpdateTime = dayjs(getCooldown().fiveAmUtcToday.toString()).format('HH:mm');

interface IProps {
  className?: string;
  onClose: () => void;
}

export const DailyRewardsPanel: FC<IProps> = ({ className = '', onClose }) => {
  const { isMobile } = useDisplay();
  const { currentDailyRewards, isRewardable, rewardUser, userDailis } = useDailyRewardsContext();
  const [animationState, setAnimationState] = useState<EAnimationStates>(EAnimationStates.INIT);
  const isStatsAnimated = ![EAnimationStates.INIT, EAnimationStates.END].includes(animationState);
  const isLoading = animationState === EAnimationStates.LOADING;
  const [countDown, setCountDown] = useState(10);

  const handleAnimationStates = (value: EAnimationStates) => setAnimationState(value);

  const handleProcessStep = () => {
    if (![EAnimationStates.FAIL, EAnimationStates.END].includes(animationState)) {
      switch (animationState) {
        case EAnimationStates.INIT:
          handleAnimationStates(EAnimationStates.PRE_LOADING);
          break;

        case EAnimationStates.PRE_LOADING:
          handleAnimationStates(EAnimationStates.LOADING);
          rewardUser()
            .then(() => {
              handleAnimationStates(EAnimationStates.SUCCESS);
            })
            .catch(() => handleAnimationStates(EAnimationStates.FAIL));
          break;

        case EAnimationStates.LOADING:
          handleAnimationStates(EAnimationStates.SUCCESS);
          break;

        case EAnimationStates.SUCCESS:
          handleAnimationStates(EAnimationStates.END);
          setCountDown(9);
          break;

        default:
          break;
      }
    } else {
      handleAnimationStates(EAnimationStates.INIT);
    }
  };

  useEffect(() => {
    let timer: any;
    if (countDown < 10 && !isRewardable) {
      timer = setInterval(() => {
        setCountDown((prevCount) => {
          if (prevCount <= 1) {
            clearInterval(timer);
            onClose();
            return 0;
          }
          return prevCount - 1;
        });
      }, 1000);
    }
    return () => clearInterval(timer);
  }, [countDown, onClose, isRewardable]);

  const label = isLoading ? 'Collecting reward…' : isRewardable ? 'Collect your rewards every day' : 'Reward collected!';

  const currentCloseLabel = countDown === 10 ? 'Close' : `Close (${countDown}s)`;

  return (
    <Styled.Container className={className}>
      <Styled.Title>{label}</Styled.Title>
      <CurrentRewards
        rewards={currentDailyRewards}
        userDailis={userDailis}
        isRewardable={isRewardable}
        variant={'panel'}
        animationState={animationState}
        handleProcessStep={handleProcessStep}
      />
      <motion.div
        className={'next-rewards'}
        variants={panelVariants}
        initial={'initial'}
        animate={isStatsAnimated ? 'animate' : 'initial'}
        transition={{ duration: 0.4, delay: isRewardable ? 0.3 : 0.8 }}
        onAnimationComplete={() => setCountDown(9)}
        style={{ willChange: 'transform opacity' }}
      >
        <NextRewards
          className={clsx('next-rewards', { mobile: isMobile })}
          rewards={currentDailyRewards}
          userDailis={userDailis}
          isRewardable={isRewardable}
          label={'After'}
          variant={'panel'}
        />
      </motion.div>
      <motion.div
        variants={panelVariants}
        initial={'initial'}
        animate={isStatsAnimated ? 'animate' : 'initial'}
        transition={{ duration: 0.4, delay: isRewardable ? 0.4 : 0.7 }}
        style={{ willChange: 'transform opacity' }}
      >
        <Streak variant={'panel'} className={clsx('streak', { mobile: isMobile })} />
      </motion.div>
      <Styled.ButtonWrap>
        <BasicButton
          isLabelFirst
          label={isRewardable ? 'Collect' : currentCloseLabel}
          variant={'cool'}
          icon={isRewardable ? <HiArrowRight /> : ''}
          fullWidth
          className={'button'}
          loading={isLoading}
          loaderIcon={<Spinner className={'spinner'} />}
          onClick={isRewardable ? handleProcessStep : onClose}
        />
      </Styled.ButtonWrap>
      <Styled.Text>You can collect a new reward every day after {rewardsUpdateTime} local time</Styled.Text>
    </Styled.Container>
  );
};
