import { IEffectIndicatorProps } from './types';
import { CountDown } from './countdown';
import { Amount } from './amount';
import { useMemo } from 'react';
import { TItemFungibleTypeForEffects } from 'interface';

export const EffectIndicator = ({
  type = 'energy',
  className,
  zIndex,
  onTimeout,
  isClickable,
  onClick,
  amount,
  effect,
  showAmount,
}: IEffectIndicatorProps) => {
  const infoType: 'countdown' | 'amount' = useMemo(() => {
    const amountTypes: TItemFungibleTypeForEffects[] = ['energy', 'no-energy', 'no-premium'];
    if (amountTypes.includes(type)) {
      return 'amount';
    }
    return 'countdown';
  }, [type]);

  return infoType === 'countdown' ? (
    <CountDown
      key={effect.expiredAt}
      className={className}
      zIndex={zIndex}
      onTimeout={onTimeout}
      isClickable={isClickable}
      onClick={onClick}
      type={type}
      effect={effect}
    />
  ) : (
    <Amount className={className} type={type} zIndex={zIndex} isClickable={isClickable} onClick={onClick} amount={amount} showAmount={showAmount} />
  );
};
