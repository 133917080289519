import { createContext, useContext } from 'react';
import { IDailyReward } from './types';
import { IProfileDailyReward } from 'interface';

export interface IDailyRewardsContext {
  currentDailyRewards: IDailyReward[];
  isRewardable: boolean;
  streak: number;
  isLoading: boolean;
  rewardUser(): Promise<void>;
  userDailis: IProfileDailyReward[];
}

export const DailyRewardsContext = createContext<IDailyRewardsContext>(null as any);

export const useDailyRewardsContext = () => useContext(DailyRewardsContext);
