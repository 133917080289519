import IconButton from 'components/library/iconButton';
import { FC, memo } from 'react';
import { TCourseWidgetProps } from './types';
import { ReactComponent as DotsIcon } from 'assets/icons/dots-double-vertical.svg';
import DropdownMenu from 'components/library/dropdownMenu';

import styles from './styles.module.css';
import { ECourseConstructorWidgetTypes } from '../../types';
import FileWidget from './components/FileWidget';
import { TFileWidgetType } from './components/FileWidget/types';
import CodeWidget from './components/CodeWidget';
import WarningWidget from './components/WarningWidget';
import TextWidget from './components/TextWidget';
import { getActionsDropdownItems } from './components/CodeWidget/WidgetDropdownMenu';
import { ESlideWidgetTypes } from 'store';
import { TVariantWarningIcon } from './components/WarningWidget/WarningIcons/types';
import { TCustomizationWarning } from './components/WarningWidget/model';
import TaskWidget from './components/TaskWidget/TaskWidget';
import { EIcons, ESpecialIcons } from './components/TaskWidget/NewCustomizationMenu/constants';
import QuoteWidget, { TQuoteWidgetprops } from './components/QuoteWidget';

const widgetTypeComponentMap: Record<
  ECourseConstructorWidgetTypes,
  (
    id: string,
    type: ESlideWidgetTypes,
    value: string,
    icon: TVariantWarningIcon | EIcons | ESpecialIcons | undefined,
    color: string | undefined,
    image: string | undefined,
    title: string | undefined,
    link: string | undefined,
    author: string | undefined,
    isRequired: boolean | undefined,
    onChange: (value: string | TCustomizationWarning) => void,
    onDelete: (widgetId: string) => void
  ) => React.ReactElement
> = {
  [ECourseConstructorWidgetTypes.TEXT]: (id, type, value = '', icon, color, image, title, link, author, isRequired, onChange) => (
    <TextWidget content={value ?? ''} onChange={onChange} />
  ),
  [ECourseConstructorWidgetTypes.WARNING]: (id, type, value = '', icon, color, image, title, link, author, isRequired, onChange) => (
    <WarningWidget
      id={id}
      content={value ?? ''}
      onChange={onChange}
      isCustomization
      color={color ? color : undefined}
      icon={icon ? (icon as TVariantWarningIcon) : undefined}
      image={image}
    />
  ),
  [ECourseConstructorWidgetTypes.CODE]: (id, _type, value = '', icon, color, image, title, link, author, isRequired, onChange) => {
    return <CodeWidget webEditorSetup={{ withOutput: false, withConsole: false, withConstructor: true }} content={value} onChange={onChange} />;
  },
  [ECourseConstructorWidgetTypes.FILE]: (id, type, value = '', icon, color, image, title, link, author, isRequired, onChange, onDelete) => (
    <FileWidget id={id} type={type as TFileWidgetType} onChange={onChange} onDelete={onDelete} value={value} />
  ),
  [ECourseConstructorWidgetTypes.IMAGE]: (id, type, value = '', icon, color, image, title, link, author, isRequired, onChange, onDelete) => (
    <FileWidget id={id} type={type as TFileWidgetType} onChange={onChange} onDelete={onDelete} value={value} />
  ),
  [ECourseConstructorWidgetTypes.VIDEO]: (id, type, value = '', icon, color, image, title, link, author, isRequired, onChange, onDelete) => (
    <FileWidget id={id} type={type as TFileWidgetType} onChange={onChange} onDelete={onDelete} value={value} />
  ),
  [ECourseConstructorWidgetTypes.TASK]: (id, _type, value = '', icon, color, image, title, link, author, isRequired, onChange) => {
    return (
      <TaskWidget
        color={color}
        icon={icon as EIcons}
        title={title}
        link={link}
        content={value}
        onChange={onChange as () => void}
        isRequired={isRequired}
      />
    );
  },
  [ECourseConstructorWidgetTypes.QUOTE]: (id, _type, value = '', icon, color, image, title, link, author, isRequired, onChange) => {
    return (
      <QuoteWidget
        author={author}
        onChange={onChange as (arg: Partial<TQuoteWidgetprops>) => void}
        color={color ?? '#FFFFFF'}
        icon={(icon as EIcons) ?? EIcons.QUOTE}
        content={value ?? ''}
      />
    );
  },
};

const CourseConstructorWidget: FC<TCourseWidgetProps> = ({
  id,
  type,
  value = '',
  onAddWidget,
  onAddTaskWidget,
  icon,
  color,
  image,
  onChange,
  onDelete,
  onDuplicate,
  title,
  link,
  author,
  isRequired,
  dragHandle,
  draggingLabel,
}) => {
  return (
    <div className={styles.root}>
      <DropdownMenu
        letChildrenOverflow
        toggle={<IconButton title={draggingLabel} className={styles.dotsButton} icon={dragHandle || <DotsIcon />} />}
        items={getActionsDropdownItems({
          onAddText: () => onAddWidget?.(ESlideWidgetTypes.TEXT),
          onDuplicate,
          onDelete,
          onTurn: () => {},
          onAddCode: () => onAddWidget?.(ESlideWidgetTypes.CODE),
          onAddFile: () => onAddWidget?.(ESlideWidgetTypes.FILE),
          onAddImage: () => onAddWidget?.(ESlideWidgetTypes.IMAGE),
          onAddWarning: () => onAddWidget?.(ESlideWidgetTypes.WARNING),
          onAddVideo: () => onAddWidget?.(ESlideWidgetTypes.VIDEO),
          onAddTask: onAddTaskWidget,
          onAddQuote: () => onAddWidget?.(ESlideWidgetTypes.QUOTE),
        })}
      />
      {type ? widgetTypeComponentMap[type](id, type, value, icon, color, image, title, link, author, isRequired, onChange, onDelete) : null}
    </div>
  );
};

export default memo(CourseConstructorWidget);
