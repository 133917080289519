import clsx from 'clsx';
import { FC } from 'react';

import { Toggle } from 'shared/ui/toggle';
import { MobileModalMotion } from 'shared/utils/mobile/mobileModalMotion';
import SideChapter from '../SideChapter';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  sidePanelCourse: any;
  openChapter: any;
  isToggle: boolean;
  toggleIsOpen: boolean;
  isCompleteHidden: boolean;
  isRewsHidden: boolean;
  handleToggle: void;
  setIsCompleteHidden: (bool: boolean) => void;
  setIsRewsHidden: (bool: boolean) => void;
  onCloseToggle: () => void;
}

export const MobileSideChapter: FC<Props> = ({
  className = '',
  sidePanelCourse,
  isCompleteHidden,
  isToggle,
  openChapter,
  setIsCompleteHidden,
  setIsRewsHidden,
  isRewsHidden,
  handleToggle,
  toggleIsOpen,
  onCloseToggle,
}) => {
  return (
    <>
      <div className={clsx('sidepanel__chapters tiny-sb', { [className]: !!className })}>
        {sidePanelCourse?.chapters?.map((chapter: any, index: number) => (
          <SideChapter key={'sidepanel__chapter' + index} order={index} chapter={chapter} isOpen={index === openChapter} openThis={handleToggle} />
        ))}
      </div>

      {isToggle && (
        <MobileModalMotion isOpen={toggleIsOpen} title={'Chapter List'} rightIcon={<div />} onClose={onCloseToggle} spacing={90}>
          <div className={clsx('toggles', styles.toggles)}>
            <div className={styles.option}>
              <p>Show Completed</p>
              <Toggle className={styles.toggle} checked={!isCompleteHidden} onChange={() => setIsCompleteHidden(!isCompleteHidden)} />
            </div>
            <div className={styles.option}>
              <p>Hide Rewards</p>
              <Toggle className={styles.toggle} checked={isRewsHidden} onChange={() => setIsRewsHidden(!isRewsHidden)} />
            </div>
          </div>
        </MobileModalMotion>
      )}
    </>
  );
};
