import { useEffect, useRef, useState } from 'react';
import { EIcons, ESpecialIcons } from '../TaskWidget/NewCustomizationMenu/constants';
import * as S from './styles';
import TiptapEditor from 'components/library/tiptapEditor';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import Text from '@tiptap/extension-text';
import Bubble from '../TextWidget/components/Bubble';
import { MenuIcons } from '../TaskWidget/NewCustomizationMenu/ui';
import CustomizationMenu from '../TaskWidget/NewCustomizationMenu/NewCustomizationMenu';

export type TQuoteWidgetprops = {
  icon?: EIcons | ESpecialIcons;
  color?: string;
  content?: string;
  editable?: boolean;
  author?: string;
  onChange?: (arg: Partial<TQuoteWidgetprops>) => void;
};

const QuoteWidget = ({
  color: defaultColor = '#FFFFFF',
  content: defaultContent = 'Please type a quote...',
  icon: defaultIcon = EIcons.QUOTE,
  author: defaultAuthor = 'John Doe',
  editable = true,
  onChange,
}: TQuoteWidgetprops) => {
  const iconRef = useRef(null);

  const [customization, setCustomization] = useState<{ icon: EIcons | ESpecialIcons; color: string }>({
    icon: defaultIcon,
    color: defaultColor,
  });

  const handleCustomizationChange = (changes: Partial<{ icon: EIcons | ESpecialIcons; color: string }>) => {
    setCustomization((s) => ({ ...s, ...changes }));
  };

  const [content, setContent] = useState(defaultContent);
  const [author, setAuthor] = useState(defaultAuthor);
  const [isChanged, setIsChanged] = useState(false);

  const Element = ({ isOpen, onClick }: { onClick?: any; isOpen?: any }) => {
    return (
      <S.IconBox style={{ position: 'relative' }} ref={iconRef} onClick={onClick ? onClick : () => {}} color={customization.color}>
        <MenuIcons type={customization.icon} />
      </S.IconBox>
    );
  };

  useEffect(() => {
    if (onChange && isChanged) {
      onChange({
        author,
        icon: customization.icon,
        color: customization.color,
        content,
      });
      setIsChanged(false);
    }
  }, [isChanged]);

  useEffect(() => {
    if (
      (typeof defaultAuthor !== 'undefined' && author !== defaultAuthor) ||
      (typeof defaultColor !== 'undefined' && customization.color !== defaultColor) ||
      (typeof defaultIcon !== 'undefined' && customization.icon !== defaultIcon) ||
      (typeof defaultContent !== 'undefined' && content !== defaultContent)
    ) {
      setIsChanged(true);
    }
  }, [author, customization, content, defaultAuthor, defaultColor, defaultIcon, defaultContent]);

  useEffect(() => {
    if (customization.color === '#FFFFFF' && customization.icon === EIcons.QUOTE) {
      setIsChanged(true);
    }
  }, []);

  return (
    <S.SRoot className={'root-transition'}>
      <S.Container color={customization.color}>
        {editable ? (
          <CustomizationMenu disableSpecials Element={Element} current={customization} onSelect={handleCustomizationChange} />
        ) : (
          <Element />
        )}
        <TiptapEditor
          extensions={[StarterKit, Text, TextStyle, Color, Underline]}
          bubble={(editor) => <Bubble editor={editor} />}
          content={content}
          placeholder={'Type a quote...'}
          onChange={(c) => setContent(c)}
          editable={editable}
        />
        {editable ? <S.AuthorInput value={author} onChange={(e) => setAuthor(e.target.value)} /> : <S.AuthorStr>{author}</S.AuthorStr>}
      </S.Container>
    </S.SRoot>
  );
};

export default QuoteWidget;
