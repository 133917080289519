import { FC } from 'react';

import styles from './styles.module.css';
import * as Elements from './styles';

type TSlideContainerProps = {
  children: React.ReactNode;
  rightPanel?: React.ReactNode;
};

const SlideContainer: FC<TSlideContainerProps> = ({ children, rightPanel }) => {
  return (
    <Elements.SRoot className={styles.root}>
      <Elements.SContainer className={styles.container}>
        <Elements.SBackground className={styles.background} />
        <Elements.SContent className={styles.content}>{children}</Elements.SContent>
      </Elements.SContainer>
      {rightPanel}
    </Elements.SRoot>
  );
};

export default SlideContainer;
