import { FC } from 'react';
import { HiArrowLeft } from 'react-icons/hi';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as Search } from 'assets/icons/search.svg';
import { THEMES } from '../../../../app/constants';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';

interface Props {
  rightIcon?: React.ReactNode;
  leftIcon?: React.ReactNode;
  title: string | React.ReactNode;
  back?: () => void;
  handleClickLeftIcon?: () => void;
  handleClickRightIcon?: () => void;
  background?: boolean;
  className?: string;
  zIndex?: number;
  hideRightIcon?: boolean;
}

export const MobileTopNav: FC<Props> = ({
  title,
  leftIcon,
  rightIcon,
  back,
  handleClickLeftIcon,
  handleClickRightIcon,
  background = true,
  className = '',
  zIndex = 100,
  hideRightIcon,
}) => {
  const currentTheme = useSelector(getCurrentTheme);
  const navigate = useNavigate();

  function handleBack() {
    if (back) {
      return back();
    }
    return navigate(-1);
  }

  return (
    <div
      style={{
        backgroundColor: background ? (currentTheme === THEMES.LIGHT ? '#FDFDFD' : '#1C1D26') : '',
        zIndex,
      }}
      className={clsx(className, styles.container)}
    >
      {leftIcon ? <span onClick={() => handleClickLeftIcon && handleClickLeftIcon()}>{leftIcon}</span> : <HiArrowLeft onClick={handleBack} />}
      {typeof title === 'string' ? <h3 className={title?.length > 13 ? styles.responsiveTitle : ''}>{title}</h3> : title}
      {rightIcon ? <span onClick={handleClickRightIcon}>{rightIcon}</span> : hideRightIcon ? <div style={{ width: '18px' }} /> : <Search />}
    </div>
  );
};
