import { Navigate, Outlet, useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { useEffect, useState } from 'react';

interface IProps {
  isAuth: boolean;
  partnerId?: string;
  redirectPath?: string;
}

export const PartnerRoute = ({ isAuth, partnerId, redirectPath = ROUTES.MAIN }: IProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(location.search);
  const currentPartnerId = searchParams.get('partnerID') ?? localStorage.getItem('partnerID');
  const [redirectAfterLogin, setRAL] = useState<string | null>(null);

  useEffect(() => {
    if (!isAuth) {
      setRAL(`${location.pathname}${location.search}`);
      return;
    }
    if (redirectAfterLogin) {
      navigate(redirectAfterLogin, { replace: true });
      return;
    }
    navigate(`${location.pathname}${location.search}`, { replace: true });
  }, [isAuth]);

  if (!currentPartnerId && partnerId) {
    searchParams.set('partnerID', partnerId);
    return <Navigate to={`${location.pathname}?${searchParams.toString()}`} replace />;
  }

  if (!currentPartnerId && !partnerId) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
