import { useDisclosure } from 'hooks';
import { useState, useEffect, useMemo } from 'react';
import Drawer from 'shared/ui/drawer/Drawer';
import { useSpacePageContext } from '../../context';
import { useLazyGetSpaceAnalyticsQuery } from 'store';
import { SpaceAnalyticsContext } from './context';
import { Analytics } from './components';

interface SpaceAnalyticsProps {
  spaceId: string;
}

const SpaceAnalytics: React.FC = () => {
  const { space, setModal, modal } = useSpacePageContext();
  const { isOpen: isFullscreen, toggle: toggleFullscreen } = useDisclosure(false);
  const isOpen = useMemo(() => modal === 'analytics', [modal]);

  if (!space) return null;
  return (
    <Drawer
      width={isFullscreen ? '100%' : '1000px'}
      isVisible={isOpen}
      position="right"
      onToggleFullPage={toggleFullscreen}
      onClose={() => setModal(null)}
    >
      <SpaceAnalyticsWrapped spaceId={space?._id} />
    </Drawer>
  );
};

const SpaceAnalyticsWrapped: React.FC<SpaceAnalyticsProps> = ({ spaceId }) => {
  const [type, setType] = useState<'quest' | 'course' | undefined>(undefined);
  const [get, { isFetching, data }] = useLazyGetSpaceAnalyticsQuery();

  useEffect(() => {
    get({ id: spaceId, type });
  }, [get, spaceId]);

  const filteredCourses = useMemo(
    () =>
      data?.items
        ? data.items.filter((course) => {
            if (type === 'course') {
              return !course.isQuest;
            }
            if (type === 'quest') {
              return course.isQuest;
            }
            return true;
          })
        : [],
    [type, data?.items]
  );
  if (isFetching) {
    return <>loading...</>;
  }
  if (!data || !data?.items?.length) {
    return <>no data</>;
  }

  const { items: courses, total, totalPages, page } = data;

  const handleSetType = (type: 'quest' | 'course' | undefined) => setType(type);

  return (
    <SpaceAnalyticsContext.Provider
      value={{ spaceId, type, setType: handleSetType, isLoading: isFetching, courses: courses ?? [], displayCourses: filteredCourses ?? [] }}
    >
      <Analytics />
    </SpaceAnalyticsContext.Provider>
  );
};

export default SpaceAnalytics;
