import { motion } from 'framer-motion';
import styles from './styles.module.css';
import { IPasswordStrong } from 'features/auth/utils';

interface IProps {
  passInfo: IPasswordStrong;
}
export const StrongPasswordIndicator = ({ passInfo }: IProps) => {
  const variants = {
    none: {
      width: '0%',
      backgroundColor: '#F14F63',
    },
    bad_length: {
      width: '20%',
      backgroundColor: '#F14F63',
    },
    no_lower: {
      width: '20%',
      backgroundColor: '#F14F63',
    },
    no_upper: {
      width: '20%',
      backgroundColor: '#F14F63',
    },
    no_number: {
      width: '20%',
      backgroundColor: '#F14F63',
    },
    middle: {
      width: '66%',
      backgroundColor: '#FFBF66',
    },
    high: {
      width: '100%',
      backgroundColor: '#29AB88',
    },
  };

  if (passInfo.strong === 'none') {
    return null;
  }

  return (
    <div className={styles.container}>
      <motion.div
        className={styles.progress}
        animate={{
          width: '100%',
          height: '10px',
        }}
      >
        <motion.div className={styles.fill} animate={passInfo.strong} variants={variants} transition={{ duration: 0.5 }} />
      </motion.div>
      <motion.span animate={{ color: passInfo.backgroundColor }} className={styles.text}>
        {passInfo?.title}
      </motion.span>
    </div>
  );
};
