import { ActivatedCodesItem } from '../codeItem';
import { ContainerCodes, SModalActivatedCodes } from './styles';
import { useGetDigitalsUserQuery } from 'store';
import { useEffect } from 'react';
import MightyLoaderBig from 'shared/utils/loadings/mighty/MightyLoaderBig';

export const ListOfCodes = () => {
  const { data, refetch } = useGetDigitalsUserQuery();

  useEffect(() => {
    refetch();
  }, [refetch]);

  return (
    <SModalActivatedCodes>
      <h4>Activated Codes</h4>
      <ContainerCodes className={'tiny-sb'}>
        {!data ? (
          <MightyLoaderBig />
        ) : (
          data.items.map((item) => (
            <ActivatedCodesItem
              key={item.id}
              code={item.code}
              isActivated={item.activated}
              description={item.description}
              url={item.url}
              image={item.item.imageThumb}
              name={item.item.name}
            />
          ))
        )}
      </ContainerCodes>
    </SModalActivatedCodes>
  );
};
