import { motion } from 'framer-motion';
import { Flex } from 'shared/ui';
import styled from 'styled-components';

export const Container = styled(Flex)`
  flex-grow: 1;
  z-index: 10;
  &:hover {
    z-index: 11;
  }
  margin-bottom: 16px;
`;

export const MenuWrap = styled(motion.div)`
  &.absolute {
    left: calc(100% + 8px);
    top: 0;
    position: absolute;
  }
`;
