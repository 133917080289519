import clsx from 'clsx';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';

import styles from './styles.module.css';

type Props = {
  label: string;
  className?: string;
  isDisabled?: boolean;
  onClick: (arg: any) => any;
};

export default function Block({ label, onClick, isDisabled = false, className = '' }: Props) {
  const theme = useSelector(getCurrentTheme);

  return (
    <button disabled={isDisabled} className={clsx(styles.answer, styles[theme], { [className]: !!className })} onClick={onClick}>
      {label}
    </button>
  );
}
