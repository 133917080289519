import { FC, useState } from 'react';
import clsx from 'clsx';
import { IChange, Value } from '../ChangerList/types/types';
import { useSelector } from 'react-redux';
import { getCustomizationItems } from 'store';
import { CUSTOMIZATION_BOTTLES, CUSTOMIZATION_POWDERS, GLOWS, NAME_COLORS, NAME_COLORS_CARDS } from 'app/constants';
import { useDisplay } from 'hooks';
import { EItemFungibleType, IDatabaseItem } from 'interface';

import Nickname from 'shared/ui/nickname/Nickname';
import UserExpLabel from 'shared/ui/user-exp-label/label';
import LittleItemsCarousel from 'shared/ui/little-items-carousel';
import CarouselItem from '../CarouselItem/CarouselItem';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';

import styles from './styles.module.scss';

interface Props {
  expToken: number;
  nickname: string;
  avatar: string;
  value: Value;
  currentItemId: number | null;
  handleChanges: (newValue: IChange, itemId: number) => void;
  customization: any;
}

const ConstructorUser: FC<Props> = ({ nickname, expToken, avatar, value, handleChanges, currentItemId, customization }) => {
  const [activeItem, setActiveItem] = useState<number | null>(null);
  const [color, setColor] = useState<any | null>(customization.name);
  const [glow, setGlow] = useState<any | null>(customization.glow);
  const [font, setFont] = useState<any | null>(customization.font);
  const customizationItems = useSelector(getCustomizationItems);
  const { isMobile } = useDisplay();

  const items = customizationItems
    .filter((item) => {
      switch (value) {
        case 'name':
          return CUSTOMIZATION_BOTTLES.includes(item.fungibleType as EItemFungibleType);
        case 'glow':
          return CUSTOMIZATION_POWDERS.includes(item.fungibleType as EItemFungibleType);
        // case 'font':
        //   return CUSTOMIZATION_BOTTLES.includes(item.fungibleType as EItemFungibleType);

        default:
          return false;
      }
    })
    .map((item) => (
      <CarouselItem
        key={'customization-caro-item' + item.id}
        id={item.id}
        isActive={item.id === activeItem || activeItem === item.id}
        onClick={() => handleClick(item)}
      />
    ));

  function handleClick(item: IDatabaseItem) {
    setActiveItem(item.id);

    if (value === 'name') {
      const name = NAME_COLORS[item.fungibleType as EItemFungibleType];
      if (name?.light === 'default') {
        setColor(null);
        handleChanges('', item.id);
        return;
      }
      const cards = NAME_COLORS_CARDS[item.fungibleType as EItemFungibleType];
      if (!!name && cards) {
        const color = {
          ...name,
          cards,
        };
        setColor(color);
        handleChanges(color, item.id);
      }
    }

    if (value === 'glow') {
      const glow = GLOWS[item.fungibleType as EItemFungibleType];
      if (glow) {
        setGlow(glow);
        handleChanges(glow, item.id);
      }
    }

    if (value === 'font') {
      setFont(item.grade);
      handleChanges(item.grade, item.id);
    }
  }

  return (
    <>
      <div className={clsx(styles.container)}>
        <div className={styles.left}>
          <div className={styles.userRow}>
            <Nickname color={color ?? 'none'} nickname={nickname} customization={{ ...customization, font }} className={styles.nickname} />
            <UserExpLabel customization={customization} background={color} className={styles.expLabel} exp={expToken} isShowImage={false} />
          </div>
          {!isMobile && <LittleItemsCarousel width="72%" className={styles.slider} items={items} />}
        </div>
        <div className={styles.right}>
          <AvatarPic className={clsx(styles.avatar)} avatar={avatar} nickname={nickname} customization={{ ...customization, glow }} />
        </div>
      </div>
      {isMobile && <LittleItemsCarousel width="100%" className={styles.slider} items={items} />}
    </>
  );
};

export default ConstructorUser;
