import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

import CheckAnswerButton from '../utils/CheckAnswerButton/CheckAnswerButton';

import * as Styled from './styles';
import { useGetCustomNav } from '../utils/useGetCustomNav';

const TypeAnswer = ({ slideId, right, updateRight, elementWithCondition }) => {
  const [userInput, setUserInput] = useState({});
  const theme = useSelector(getCurrentTheme);

  const handleInput = (e) => setUserInput({ field1: e.target.value });

  const buttonElement = useGetCustomNav(
    <CheckAnswerButton
      className="lesson-button"
      isActive={Boolean(Object.values(userInput)[0])}
      userAnswers={Object.values(userInput)}
      slideId={slideId}
      right={right}
      updateRight={updateRight}
    />,
    elementWithCondition
  );

  return (
    <>
      <div className="gap">
        <div className="lesson-content-space">
          <Styled.Textarea placeholder="Write your answer..." className={`${theme} right-${right}`} onChange={handleInput} />
        </div>
      </div>
      {buttonElement}
    </>
  );
};

export default TypeAnswer;
