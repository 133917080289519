export const ERRORS = {
  name: 'Please add name',
  nameLen: 'The name must be shorter than 50 characters',
  nameFormat: "Please, don't use special symbols",
  nameDuplicate: 'This name is already taken',
  webiste: 'Please add website',
  websiteFormat: 'Please enter your website in correct format',
  bio: 'Please add bio',
  bioMinLen: 'Please write 15 symbols minimum',
  bioMaxLen: 'Please use 150 letters / symbols max',
  tags: 'Please select at least 1 tag',
  avatar: 'Please upload an avatar',
};

export const VARIANTS = {
  animate: {
    x: 0,
    opacity: 1,
  },
  initial: {
    x: 100,
    opacity: 1,
  },
  exit: {
    x: -100,
    opacity: 1,
  },
};

export const WebsiteRegExp =
  /^(https:\/\/)(([\w]+:)?\/\/)?(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/;
export const SpaceNameRegExp = /^[a-zA-Z0-9\.\:\s\!&\?\(\)-_\*\;]+$/;
