import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getIsAuth, getUserData, useGetSubscribersQuery, useSubscribeToSpaceMutation, useUnsubscribeFromSpaceMutation } from 'store';
import { motion } from 'framer-motion';
import { AnimatedCheckIcon } from 'shared/ui/motion/animatedCheckSvg';
import { Player, PlayerEvent } from '@lottiefiles/react-lottie-player';
import animationLottie from './animation/follow_button.json';
import animationLottieUnfollow from './animation/unfollow_button.json';

import * as S from './styles';
import { useDisplay, useNavigateToAuth } from 'hooks';

type Props = {
  spaceId: string;
  small?: boolean;
  large?: boolean;
};

const unFollowedBg =
  'linear-gradient(90deg, #6487EF 0%, #53259B 125.56%), linear-gradient(281.94deg, rgba(255, 255, 255, 0.21) -0.2%, rgba(255, 255, 255, 0.03) 98.72%)';

export const SpaceFollowButton = ({ spaceId, small, large }: Props) => {
  const [subscribe, { isLoading: isSubscribing }] = useSubscribeToSpaceMutation();
  const [unsubscribe, { isLoading: isUnsubscribing }] = useUnsubscribeFromSpaceMutation();
  const { data: followers, isFetching: isLoadingFollowers, refetch } = useGetSubscribersQuery(spaceId);
  const { _id: userId } = useSelector(getUserData);
  const isFollowing = useMemo(() => (followers ? followers.items.some((el: any) => el.userId._id === userId && el.isSub) : false), [followers]);
  const [isFollowed, setIsFollowed] = useState(false);
  const [isAnimationVisible, setIsAnimationVisible] = useState(false);
  const [isAnimationUnfolowVisible, setIsAnimationUnfolowVisible] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const navToAuth = useNavigateToAuth();
  const isAuth = useSelector(getIsAuth);

  const { isMobile } = useDisplay();

  const theme = useSelector(getCurrentTheme);
  const playerRef = useRef<any>(null);

  const background = unFollowedBg;

  const isLoading = isSubscribing || isUnsubscribing || isLoadingFollowers;

  useEffect(() => {
    setIsFollowed(isFollowing);
  }, [isFollowing]);

  const handleClick = useCallback(
    (e: any) => {
      e.stopPropagation();
      if (!isAuth) {
        navToAuth();
        return;
      }

      if (isLoading) {
        return;
      }
      setIsFollowed(!isFollowing);
      if (isFollowing) {
        setIsAnimationVisible(false);
        if (isMobile) {
          setIsAnimationUnfolowVisible(true);
        }
        setIsPlaying(true);
        const player = playerRef.current;
        if (player) {
          player.play();
        }
        unsubscribe({ spaceId: spaceId })
          .unwrap()
          .then(console.log)
          .catch((err) => {
            console.error(err);
            setIsFollowed(true);
          })
          .finally(() => refetch());
      } else {
        setIsAnimationVisible(true);
        subscribe({ spaceId: spaceId })
          .unwrap()
          .then(console.log)
          .catch((err) => {
            console.error(err);
            setIsFollowed(false);
          })
          .finally(() => refetch());
      }
    },
    [isFollowing, isLoading]
  );

  const handleMouseEnter = () => {
    if (isFollowed && !isAnimationVisible) {
      setIsAnimationUnfolowVisible(true);
    }
  };

  const handleMouseLeave = () => {
    if (isFollowed && !isPlaying) {
      setIsAnimationUnfolowVisible(false);
    }
  };

  function handleEventPlayer(event: PlayerEvent) {
    if (event === 'complete') setIsAnimationVisible(false);
  }

  const styles = { cursor: isLoading ? 'wait' : 'pointer', background };

  return (
    <div
      onClick={isLoading || isAnimationVisible || isPlaying ? (e) => e.stopPropagation() : handleClick}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      style={{
        maxHeight: '56px',
        maxWidth: '176px',
        overflow: 'hidden',
      }}
    >
      {isAnimationVisible && (
        <Player src={animationLottie} autoplay loop={false} keepLastFrame onEvent={handleEventPlayer} className="follow-button-player" />
      )}
      <Player
        style={{ display: isAnimationUnfolowVisible ? 'block' : 'none', height: small ? 41 : large ? 56 : 52, minWidth: '150px' }}
        ref={playerRef}
        src={animationLottieUnfollow}
        onEvent={(event: PlayerEvent) => {
          if (event === 'complete') {
            setIsAnimationVisible(false);
            setIsPlaying(false);
            setIsAnimationUnfolowVisible(false);
          }
        }}
        direction={1}
      />
      {!isAnimationVisible && !isPlaying && !isAnimationUnfolowVisible && (
        <S.FollowButton
          $small={small}
          $large={large}
          $theme={theme}
          as={motion.button}
          style={{ ...styles, ...(background === unFollowedBg ? { color: 'white' } : {}) }}
          disabled={isLoading}
        >
          <AnimatedCheckIcon style={{ marginRight: 10 }} isVisible={isFollowed} size={20} />
          <span>{isFollowed ? 'Followed' : 'Follow'}</span>
        </S.FollowButton>
      )}
    </div>
  );
};
