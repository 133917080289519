import styled from 'styled-components';
import { IGeneralProps } from './types';

export const UIParent = styled.div.attrs<IGeneralProps>(({ as = 'div' }) => ({ as }))<IGeneralProps>`
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};
  margin: ${({ margin }) => margin || '0'};
  padding: ${({ padding }) => padding || '0'};
`;

export const MotionParent = styled(UIParent);
