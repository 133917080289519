import { HTMLAttributes } from 'react';

export enum EInfoBadgeTypes {
  WARNING = 'warning',
}

export type TInfoBadgeProps = HTMLAttributes<HTMLDivElement> & {
  type: EInfoBadgeTypes;
  children: React.ReactNode;
};
