import { useEffect, useState } from 'react';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { GRADES, THEMES } from 'app/constants';
import clsx from 'clsx';

import styles from './styles.module.css';

interface IProps {
  completion: number;
  grade?: string;
  isProgressAnimated?: boolean;
  className?: string;
}

export function ProgressBar({ completion = 0, grade = GRADES.COMMON, isProgressAnimated = false, className = '' }: IProps) {
  const theme = useSelector(getCurrentTheme);
  const [progress, setProgress] = useState(completion);
  const mainBarBg = theme === THEMES.DARK ? `var(--grade-${theme}-color-${grade})` : 'white';

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    if (isProgressAnimated) {
      interval = setInterval(() => {
        setTimeout(() => {
          if (Math.round(completion) !== Math.round(progress)) {
            setProgress((prev) => {
              const newValue = prev + (completion - prev) / 10;
              return newValue;
            });
          } else {
            setProgress(completion);
          }
        }, 1000);
      }, 30);
    }

    return () => clearInterval(interval);
  }, [completion, progress, isProgressAnimated]);

  return (
    <div className={clsx(styles.container, styles[theme], styles[grade], { [className]: !!className })}>
      <div className={styles.progress}>
        <div
          className={styles.filler}
          style={{
            left: `-${100 - progress}%`,
            background: completion >= progress ? mainBarBg : 'var(--font-color-strong)',
          }}
        />
        {completion > 0 && (
          <div
            className={styles.filler}
            style={{
              left: `-${100 - completion}%`,
              zIndex: completion >= progress ? 1 : 3,
              background: completion < progress ? mainBarBg : 'var(--font-color-strong)',
            }}
          />
        )}
      </div>
      <p className={styles.completion}>{Math.round(progress)}%</p>
    </div>
  );
}
