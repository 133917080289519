import { FC } from 'react';
import styles from './styles.module.scss';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { ICustomization } from 'interface';

interface Props {
  nickname: string;
  color?: any;
  className?: string;
  customization: ICustomization;
}

const Nickname: FC<Props> = ({ nickname, color, className, customization }) => {
  const theme = useSelector(getCurrentTheme);

  function currentColor() {
    if (color) {
      return color[theme];
    }
    return customization.name[theme] !== 'default' ? customization.name[theme] : 'var(--font-color-strong)';
  }

  return (
    <h1 style={{ color: currentColor() }} className={clsx(styles.nickname, styles[customization.font], className)}>
      {nickname ? (nickname.length < 20 ? nickname : nickname.slice(0, 20) + '...') : 'Rising Phoenix'}
    </h1>
  );
};

export default Nickname;
