import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import { useDisplay } from 'hooks';
import { TTermsPolicy } from './types';
import { Content } from './components/Content/Content';

type Props = {
  onClose: () => void;
  page?: TTermsPolicy;
};

export const TermsPrivacyModal = ({ page, onClose }: Props) => {
  const { isMobile } = useDisplay();

  return (
    <CenteredModal
      show={Boolean(page)}
      onClose={onClose}
      zIndex={[10011, 10010]}
      closeButtonPosition={{ top: '2px' }}
      isClosing={!isMobile}
      backgroundColor={isMobile ? 'var(--body-color)' : 'rgba(28, 29, 38, 0.5)'}
    >
      <Content page={page} onClose={onClose} />
    </CenteredModal>
  );
};
