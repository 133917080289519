import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      border-radius: 5px;
      padding: 19px 19px 27px;
      width: 332px;
      height: 115px;
      flex-shrink: 0;
      letter-spacing: 0.5px;
      background: radial-gradient(160.49% 83.11% at 83.99% 23.47%, rgba(60, 63, 84, 0.4) 0%, rgba(0, 0, 0, 0) 100%),
        linear-gradient(42deg, rgba(41, 43, 58, 0.52) 0%, rgba(74, 78, 102, 0.1) 44.77%, rgba(193, 204, 244, 0.07) 100%);
      background-blend-mode: normal, overlay;

      &.light {
        filter: drop-shadow(0px 0px 0px rgba(28, 29, 38, 0.4)) drop-shadow(4px 5px 7px rgba(28, 29, 38, 0.39));
        backdrop-filter: blur(23px);
        background: var(--basic-light-bg);
      }

      &.containable {
        background: none !important;
        width: 100%;
        height: fit-content;
        padding: 0;
      }

      & .progressbar {
        margin-bottom: 10px;
      }
    `
  ),
  Header = styled.div(
    () => css`
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 16px;
      line-height: 1; /* 23.2px */
      margin-bottom: 13.5px;

      &,
      p,
      span {
        color: var(--font-color-strong);
      }
    `
  );
