import styled, { css } from 'styled-components';

export const SFieldLinkPopup = styled.div(
  () => css`
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    position: relative;

    .link-field-popup__icon-button {
      position: absolute;
      right: 15px;
      top: 50%;
      transform: translateY(-50%);
    }

    .link-field-popup__input {
      padding-right: 60px;
    }
  `
);
