import { ChangeEventHandler, FC } from 'react';
import { BasicButtonV1, IButtonProps } from 'shared/ui';

import styles from './styles.module.css';

type TFileButtonProps = {
  onChange: ChangeEventHandler<HTMLInputElement>;
  children: React.ReactNode;
  id: string;
  accept?: string;
} & Omit<IButtonProps, 'onChange'>;

const FileButton: FC<TFileButtonProps> = ({ children, onChange, id, accept, ...props }) => {
  return (
    <BasicButtonV1 className={styles.root} {...props}>
      <input type="file" hidden id={id} onChange={onChange} accept={accept} />
      {children}
      <label className={styles.label} htmlFor={id} />
    </BasicButtonV1>
  );
};

export default FileButton;
