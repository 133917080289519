import { ReactElement } from 'react';
import { useMaterialPageContext } from '../../materialPageContext';
import { AnimatePresence, motion } from 'framer-motion';

export const useGetCustomNav = (button: ReactElement, elementWithCondition?: { element: ReactElement; condition: boolean }) => {
  const { isInProgress, customNav } = useMaterialPageContext();

  // if (elementWithCondition && elementWithCondition.condition) {
  //   return <>{elementWithCondition.element}</>
  // }

  // if (!isInProgress && !!customNav) {
  //   return <>{customNav}</>;
  // }

  // if (!customNav) {
  //   return <>{button}</>;
  // }

  // return <>{button}</>;

  const variants = {
    initial: { opacity: 0, y: -10, scale: 0.8 },
    animate: { opacity: 1, y: 0, scale: 1 },
  };

  const elemWcondExists = elementWithCondition && elementWithCondition.condition && elementWithCondition.element;

  return (
    <AnimatePresence exitBeforeEnter>
      {!!customNav && !isInProgress && (
        <motion.div variants={variants} initial={'initial'} animate={'animate'} exit={'initial'} key={'custom-nav'}>
          {customNav}
        </motion.div>
      )}
      {elemWcondExists && !customNav && (
        <motion.div variants={variants} initial={'initial'} animate={'animate'} exit={'initial'} key={'eWc'}>
          {elementWithCondition.element}
        </motion.div>
      )}
      {!elemWcondExists && (
        <motion.div variants={variants} initial={'initial'} animate={'animate'} exit={'initial'} key={'bttn'}>
          {button}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
