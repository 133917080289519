import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const Container = styled.div<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? '#ffffff26' : 'var(--basic-light-bg)')};
  border-radius: 40px;
  height: 48px;
  min-width: 48px;
  width: fit-content;
`;

export const VolumeIcon = styled.div<{ $theme: THEMES }>`
  display: flex;
  & > svg {
    width: 24px;
    height: 24px;
    color: ${({ $theme }) => ($theme === THEMES.DARK ? 'white' : 'var(--mighty-blue)')};
  }
  margin-left: 12px;
  margin-right: 12px;
`;

export const SliderContainer = styled.div`
  overflow: hidden;
  width: 0;
  transition: 0.3s;
  &.isOpen {
    width: 67px;
    padding-right: 12px;
  }
`;
