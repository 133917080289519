import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const CopyBox = styled.div`
  display: flex;
  gap: 6px;
  margin-bottom: 60px;
  width: 100%;
  @media screen and (max-width: 992px) {
    margin-bottom: 32px;
  }
`;

export const CopyBlock = styled.div<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#627EEA' : '#fff')};
  background: ${(p) =>
    p.$theme === THEMES.LIGHT
      ? 'rgba(98, 126, 234, 0.15)'
      : 'linear-gradient(283.99deg, rgba(255, 255, 255, 0.105) -0.22%, rgba(255, 255, 255, 0.015) 183.47%);'};
  background-blend-mode: overlay;
  mix-blend-mode: normal;
  border-radius: 4px;
  height: 48px;
  font-size: 16px;
  font-weight: 400;
  padding: 0px 12px;
  width: 100%;
  min-width: calc(100% - 48px);
  cursor: pointer;
  & > span {
    white-space: nowrap;
    color: inherit;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 100%;
  }
`;

export const CopyButton = styled.div<{ $theme: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#627EEA' : '#fff')};
  background: ${(p) => (p.$theme === THEMES.LIGHT ? 'rgba(98, 126, 234, 0.15)' : '#FFFFFF1A')};
  background-blend-mode: overlay;
  mix-blend-mode: normal;
  height: 48px;
  width: 48px;
  min-width: 48px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  overflow: hidden;
  & > div {
    width: 28px;
    height: 28px;
    & > svg {
      width: 100%;
      height: 100%;
    }
  }
`;
