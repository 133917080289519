import { FC, PropsWithChildren, useRef } from 'react';
import clsx from 'clsx';
import { useHorizontalScroll } from 'hooks';
import styles from './styles.module.scss';

interface IProps extends PropsWithChildren {
  className?: string;
}

export const HorizontalScrollContainer: FC<IProps> = ({ className = '', children }) => {
  const ref = useRef(null);
  const { onMouseDown, onMouseUp, onMouseMove } = useHorizontalScroll({ ref });

  return (
    <div onMouseDown={onMouseDown} onMouseUp={onMouseUp} onMouseMove={onMouseMove} className={clsx(className, styles.container)} ref={ref}>
      {children}
    </div>
  );
};
