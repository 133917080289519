import clsx from 'clsx';
import { DetailedHTMLProps, HTMLAttributes, memo, PropsWithChildren, ReactNode, useEffect } from 'react';
import { AnimatePresence, ForwardRefComponent, HTMLMotionProps, motion, PanInfo } from 'framer-motion';

import { BsChevronLeft } from 'react-icons/bs';

import styles from './styles.module.css';
import { modal, overlay } from './data';

interface IProps
  extends PropsWithChildren,
    Omit<ForwardRefComponent<DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>, HTMLMotionProps<'div'>>, '$$typeof'> {
  isFull?: boolean;
  isOpen: boolean;
  className?: string;
  classNameContainer?: string;
  onClose: () => void;
  iconLeft?: ReactNode;
  iconRight?: ReactNode;
  blur?: number;
  zIndex?: number;
}
function MobileModalUI({
  isFull = false,
  isOpen = false,
  onClose,
  children,
  className = '',
  classNameContainer = '',
  iconLeft,
  iconRight,
  blur = 20,
  zIndex = undefined,
  ...rest
}: IProps) {
  useEffect(() => {
    if (isOpen) {
      document.documentElement.style.overflowY = 'hidden';
    } else {
      document.documentElement.style.overflowY = 'unset';
    }

    return () => {
      document.documentElement.style.overflowY = 'unset';
    };
  }, [isOpen]);

  if (!children) {
    return null;
  }

  function handleClose(e: any) {
    e.preventDefault();
    onClose();
  }

  function handleDragEnd(event: MouseEvent | TouchEvent | PointerEvent, info: PanInfo) {
    if (info.offset.y > 100 || info.velocity.y > 500) {
      onClose();
    }
  }

  return (
    <AnimatePresence>
      {isOpen && (
        <>
          <motion.div
            key={'overlay'}
            variants={overlay}
            initial="hidden"
            animate={'visible'}
            exit="exit"
            className={clsx(styles.background, { [styles.inactive]: !isOpen, [className]: !!className })}
            onClick={handleClose}
            style={zIndex ? { zIndex: zIndex } : undefined}
          >
            <div className={styles.icons}>
              {iconLeft && iconLeft}
              {iconRight && iconRight}
            </div>
          </motion.div>
          <motion.div
            {...rest}
            key={'modal-mobile'}
            drag={'y'}
            dragConstraints={{ top: 0 }}
            onDragEnd={handleDragEnd}
            onTouchStart={rest.defaultProps?.onTouchStart}
            onTouchMove={rest.defaultProps?.onTouchMove}
            onTouchEnd={rest.defaultProps?.onTouchEnd}
            dragTransition={{
              bounceStiffness: 600,
              bounceDamping: 20,
            }}
            variants={modal}
            initial="hidden"
            animate={'visible'}
            exit="exit"
            style={{ backdropFilter: Boolean(blur) ? `blur(${blur}px)` : 'none', zIndex: zIndex ? zIndex + 1 : undefined }}
            className={clsx(styles.container, {
              [styles.inactive]: !isOpen,
              [styles.full]: isFull,
              [classNameContainer]: !!classNameContainer,
            })}
          >
            {isFull && (
              <button className={styles.close} onClick={onClose}>
                <BsChevronLeft strokeWidth={1} />
              </button>
            )}
            {children}
          </motion.div>
        </>
      )}
    </AnimatePresence>
  );
}

export const MobileModal = memo(MobileModalUI);
