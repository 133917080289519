import clsx from 'clsx';
import styles from './styles.module.scss';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { useMaterialPageContext } from '../../../materialPageContext';

const CourseNavigator = ({ navigateUser, locked, isAnswer = false, isLoading = false }) => {
  const { isInProgress, customNav } = useMaterialPageContext();
  if (!isAnswer)
    if (!isInProgress && !!customNav && !locked) {
      return <>{customNav}</>;
    }
  return (
    <div className={clsx(styles.courseNav, 'lesson-button')}>
      <button className={styles.multiple} disabled={locked || isLoading} style={{ display: !locked ? 'flex' : 'none' }} onClick={navigateUser}>
        {isLoading ? <MightyLoaderMini className={styles.loading} /> : 'Continue'}
      </button>
    </div>
  );
  return <></>;
};

export default CourseNavigator;
