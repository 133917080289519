import hexToRgba from 'hex-to-rgba';
import styled, { createGlobalStyle, css } from 'styled-components';

export const AnswerEditorStyles = createGlobalStyle(
    ({ theme }: { theme: any }) => css`
      .inline-answers-v2 {
        background: ${hexToRgba(theme.theme.fontColor, 0.1)};
        padding: 35px;
        border-radius: 10px;
        width: 100%;

        [contenteditable='true'] {
          outline: none;
        }

        &__bubble {
          padding: 9px;
          border-radius: 5px;
          position: absolute;
          background: #fff;
          font-size: 14px;
          transform: translate(-50%, calc(-100% - 10px));
          display: flex;
          align-items: center;
          gap: 5px;
          cursor: pointer;

          span {
            color: #383838 !important;
          }
        }

        &-wrapper {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
        }

        &-placeholder {
          text-transform: uppercase;
          color: var(--font-color);
          font-size: 14px;
          margin-bottom: 5px;
        }

        &__answer-badge,
        .mighty-answer {
          line-height: 40px;
          padding: 8px 14px;
          background: var(--basic-blue);
          border-radius: 65px;
          color: var(--font-color-strong);
          font-weight: bold;
          user-select: none;
          pointer-events: none;
        }

        &__answer-input {
          line-height: 40px;
          padding: 8px 14px;
          background: var(--basic-blue);
          border-radius: 5px;
          color: var(--font-color-strong);
          font-weight: bold;
          width: fit-content;
          max-width: 100%;
          display: inline-flex;
          flex-wrap: wrap;

          &-measure {
            position: absolute;
            top: -9999px;
            left: -9999px;
            visibility: hidden;
            white-space: pre;
            font-weight: bold;
          }

          input {
            width: 5px;
            background: transparent;
            outline: none;
            border: none;
            color: var(--font-color-strong);
            font-weight: bold;
          }
        }

        & div {
          min-height: 36px;
          white-space: break-spaces;
        }

        @media screen and (max-width: 768px) {
          padding: 15px;
        }
      }
    `
  ),
  ButtonsRow = styled.div(
    () => css`
      margin-top: 15px;
      display: flex;
      align-items: center;
      gap: 15px;
    `
  );
