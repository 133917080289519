import { MouseEventHandler, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getItems, useGreetUserMutation, useLazyGetMyInventoryQuery } from 'store';
import clsx from 'clsx';
import { Variants } from 'framer-motion';
import { useDisplay } from 'hooks';

import book from '../../assets/book.png';
import chibi from '../../assets/chibi.png';

import { BasicButtonV1 } from 'shared/ui/button';
import { MiniUser } from './mini-user';

import * as Styled from './styles';

const panels = [
  {
    header: 'Earn EXP',
    subtitle: 'Completing test and assignments while learning our courses',
    image: book,
  },
  {
    header: 'Spend EXP ',
    subtitle: "Skins, Consumables, WL's, Discounts and Big Prizes!",
    image: chibi,
  },
];

interface IGreetingProps {
  onClose: MouseEventHandler<HTMLButtonElement>;
}

export const Greeting = ({ onClose }: IGreetingProps) => {
  const { isMobile } = useDisplay();
  const coinsTarget = useRef<HTMLDivElement>(null);
  const startTarget = useRef<HTMLImageElement>(null);
  const [isDisabled, setIsDisabled] = useState(true);
  const isAnimationStarted = useRef(false);
  const [isSettingCoords, setIsSettingCoords] = useState(false);
  const [targetCoords, setTargetCoords] = useState({ x: 0, y: 0, startX: 0, startY: 0 });
  const [exp, setEXP] = useState(0);
  const items = useSelector(getItems);
  const expToken = items[0];
  const { image } = expToken;
  const [greetUser] = useGreetUserMutation();
  const [getInv] = useLazyGetMyInventoryQuery();

  useEffect(() => {
    if (coinsTarget.current && startTarget.current) {
      const startRect = startTarget.current.getBoundingClientRect();
      const targetRect = coinsTarget.current.getBoundingClientRect();
      setTargetCoords({
        startX: isSettingCoords ? startRect.left + startRect.width / 4 + window.scrollX : 0,
        startY: isSettingCoords ? startRect.top + startRect.height / 4 + window.scrollY : 0,
        x: targetRect.left + window.scrollX,
        y: targetRect.top + window.scrollY,
      });
    }
  }, [coinsTarget, startTarget, isSettingCoords]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      isAnimationStarted.current = true;

      greetUser()
        .then(() => getInv())
        .then(() => setEXP(50))
        .then(() => setIsDisabled(false));
    }, 3000);
    return () => clearTimeout(timeout);
  }, []);

  const variants: Variants = {
    initial: {
      opacity: 0,
      height: 100,
    },
    animate: {
      opacity: [1, 1, 1, 1, 0],
      height: 50,
    },
  };

  return (
    <>
      {isMobile ? (
        <>
          <Styled.Container
            key={'greeter'}
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -1000, opacity: -1 }}
            className={clsx('mobile')}
            onAnimationComplete={() => setIsSettingCoords(true)}
          >
            <Styled.Image ref={startTarget} src={image} alt="exp token" />
            <Styled.Header>Congratulations!</Styled.Header>
            <Styled.Text>You got 50 EXP for registering</Styled.Text>
            <Styled.Panels>
              {panels.map(({ header, subtitle, image }) => (
                <Styled.Panel key={header}>
                  <img src={image} alt={header} />
                  <Styled.PanelBody>
                    <p>{header}</p>
                    <span>{subtitle}</span>
                  </Styled.PanelBody>
                </Styled.Panel>
              ))}
            </Styled.Panels>
            <BasicButtonV1 disabled={isDisabled} isLoading={isDisabled} onClick={onClose} variant="primary">
              Continue
            </BasicButtonV1>
          </Styled.Container>
          <Styled.MiniUser as={MiniUser} targetRef={coinsTarget} exp={exp} />
        </>
      ) : (
        <>
          <Styled.Container
            key={'greeter'}
            initial={{ x: 200, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: -1000, opacity: -1 }}
            onAnimationComplete={() => setIsSettingCoords(true)}
          >
            <Styled.Image ref={startTarget} src={image} alt="exp token" />
            <Styled.Header>Congratulations!</Styled.Header>
            <Styled.Text>You got 50 EXP for registering</Styled.Text>
            <Styled.Panels>
              {panels.map(({ header, subtitle, image }) => (
                <Styled.Panel key={header}>
                  <img src={image} alt={header} />
                  <Styled.PanelBody>
                    <p>{header}</p>
                    <span>{subtitle}</span>
                  </Styled.PanelBody>
                </Styled.Panel>
              ))}
            </Styled.Panels>
            <BasicButtonV1 disabled={isDisabled} isLoading={isDisabled} onClick={onClose} variant="primary">
              Continue
            </BasicButtonV1>
          </Styled.Container>
          <MiniUser targetRef={coinsTarget} exp={exp} />
        </>
      )}
      {[...Array(6).keys()].map((i) => (
        <Styled.Coin
          key={'coin' + i}
          src={image}
          alt="exp token"
          variants={variants}
          animate={isAnimationStarted.current ? 'animate' : 'initial'}
          $startX={isAnimationStarted.current ? targetCoords.x : targetCoords.startX}
          $startY={isAnimationStarted.current ? targetCoords.y : targetCoords.startY}
          style={{ transitionDelay: i * 0.09 + 's' }}
        />
      ))}
    </>
  );
};
