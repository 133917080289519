import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';

export const SRoot = styled.div(
    () => css`
      margin-top: 15px;

      > span {
        display: block;
        font-size: 14px;
        text-transform: uppercase;
        color: var(--font-color);
        margin-bottom: 5px;
        opacity: 0.7;
      }
    `
  ),
  SAnswersWrapper = styled.div(
    () => css`
      display: flex;
      gap: 15px;
      flex-wrap: wrap;
    `
  ),
  SAnswer = styled.div(
    ({ theme }) => css`
      display: flex;
      gap: 5px;
      font-size: 14px;
      align-items: center;
      font-weight: bold;
      padding: 8px 10px;
      border-radius: 5px;
      background: ${hexToRgba(theme.theme.fontColor, 0.1)};
    `
  );
