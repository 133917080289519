import { FC } from 'react';
import * as yup from 'yup';
import { ISelectedText } from './types';
import { SText, SAddComment } from './styles';
import { ReactComponent as MessageDots } from 'assets/icons/message-dots.svg';
import { DropdownMotion } from '../../shared/ui';
import Tabs from '../../components/library/tabs';
import { useSelectedText } from './useSelectedText';
import { useMaterialPageContext } from '../../components/pages/study/courses/material-pages/materialPageContext';
import { createPortal } from 'react-dom';

const schema = yup.object().shape({
  comment: yup.string().required('Field must be filled in'),
});

export const SelectedText: FC<ISelectedText> = ({ editor, id, trigger }) => {
  const { slideId } = useMaterialPageContext();

  const { selectedText, idText, tabs, containerRef } = useSelectedText({ editor, trigger, id, schema, slideId });

  return (
    <>
      {createPortal(
        <div ref={containerRef} style={{ zIndex: 1, whiteSpace: 'pre', position: 'fixed' }}>
          {selectedText && idText === id && (
            <DropdownMotion
              closeOutside={false}
              triggerNode={
                <SAddComment>
                  <MessageDots />
                  <SText>Add Comment</SText>
                </SAddComment>
              }
            >
              <Tabs tabs={tabs} />
            </DropdownMotion>
          )}
        </div>,
        document.getElementById('portal')!
      )}
    </>
  );
};
