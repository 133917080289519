import { CgClose } from 'react-icons/cg';
import BasicButton from 'shared/ui/basic-button/button';

import styles from './styles.module.css';

export default function UnfollowConfirm({ name, accept, close }) {
  const theme = localStorage.getItem('theme');

  return (
    <div className={`glass-div basic ${theme} friend-finder_conf --remove-confirm`}>
      <p id="header">
        Unfollow <span>{name}</span>?
      </p>
      <div className="btns">
        <BasicButton label="Yes, I'm sure" className={styles.button} onClick={accept} />
        <BasicButton label="Cancel" className={styles.button} onClick={close} />
      </div>
      <CgClose strokeWidth={0.1} className={styles.close} role={'button'} onClick={close} />
    </div>
  );
}
