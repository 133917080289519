import { useCallback, useMemo, useState } from 'react';

import CheckAnswerButton from '../utils/CheckAnswerButton/CheckAnswerButton';

import styles from '../Fillblocks/styles.module.css';
import { NodesView } from '../Fillblocks/NodesView';
import { useGetCustomNav } from '../utils/useGetCustomNav';

const FillblocksCode = ({ answers = [], slideId, nodes, right, updateRight, typeOfSlide, elementWithCondition }) => {
  const [userInput, setUserInput] = useState([]);

  const handleAnswersChange = useCallback((answers) => {
    setUserInput(answers);
  }, []);

  const shuffledAnswers = useMemo(
    () =>
      [...answers]
        .map((i) => ({ item: i, sort: Math.random() }))
        .sort((a, b) => a.sort - b.sort)
        .map((el) => el.item),
    [answers]
  );

  const buttonElement = useGetCustomNav(
    <CheckAnswerButton
      className="lesson-button"
      isActive={Boolean(userInput[0])}
      userAnswers={userInput}
      slideId={slideId}
      right={right}
      updateRight={updateRight}
    />,
    elementWithCondition
  );

  return (
    <>
      <div className={styles.gaps}>
        <div className="lesson-content-space">
          <NodesView
            typeOfSlide={typeOfSlide}
            rootNode={nodes}
            answers={shuffledAnswers}
            onChange={handleAnswersChange}
            right={right}
            updateRight={updateRight}
          />
        </div>
      </div>
      {buttonElement}
    </>
  );
};

export default FillblocksCode;
