import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  left: 100%;
  width: 50px;
  top: 0;
  height: 100%;
`;

const gradiDark = 'linear-gradient(233.42deg, rgba(255, 255, 255, 0.22) 2.69%, rgba(255, 255, 255, 0.05) 44.6%)';
const gradiLight = 'linear-gradient(233.42deg, rgba(17, 17, 17, 0.22) 2.69%, rgba(17, 17, 17, 0.05) 44.6%)';

export const Container = styled(motion.div)<{ $theme?: THEMES }>`
  position: absolute;
  left: 10px;
  width: 40px;
  height: fit-content;
  border-radius: 10px;
  background-image: ${(p) => (p.$theme === THEMES.LIGHT ? gradiLight : gradiDark)};
  z-index: 20;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
`;

export const Tooltip = styled.div`
  position: absolute;
  top: 50%;
  right: calc(100% + 10px);
  transform: translateY(-50%);
  display: block;
  color: white;
  font-size: 14px;
  font-weight: 400;
  padding: 6px 11px;
  background: #000;
  border-radius: 2px;
  height: fit-content;
  opacity: 0;
  transition: 0.3s;
  white-space: nowrap;
  pointer-events: none;
`;

export const Button = styled.div`
  width: 40px;
  height: 40px;
  position: relative;
  border-radius: 10px;
  transition: 0.3s;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  &:hover {
    background: #ffffff10;
    & ${Tooltip} {
      opacity: 1 !important;
    }
  }
  & > svg {
    width: 24px;
    height: 24px;
    &.settings-icon {
      width: 33px;
      height: 33px;
    }
  }
`;

export const HiddenInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  &::file-selector-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
  }
`;
