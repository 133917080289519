import { useState } from 'react';
import clsx from 'clsx';
import { IExpEffectWidgetData } from 'shared/ui/effect-indicator/types';
import { useGetEffectsData } from 'hooks';

import { EffectsList } from './effects-list';
import { Head } from './head';
import { EffectPage } from './effect-page';
import { ActiveSum } from './active-sum';

import * as Styled from './styles';

interface IProps {
  className?: string;
  handleGoToMain: () => void;
}

export const EffectsPanel = ({ handleGoToMain, className = '' }: IProps) => {
  const { effects, handleTimeout } = useGetEffectsData({ isBadges: true });
  const [activeEffect, setActiveEffect] = useState<null | IExpEffectWidgetData>();

  const handleCaptureEffect = (effectLabel: string | null) => () => {
    setActiveEffect(effectLabel ? effects.find((effect) => effect.type === effectLabel) ?? null : null);
  };

  return (
    <Styled.Container className={clsx({ [className]: !!className })}>
      {activeEffect ? (
        <EffectPage effect={activeEffect} onClose={handleCaptureEffect(null)} />
      ) : (
        <>
          <Head className="header" />
          <ActiveSum effects={effects} />
          <EffectsList effects={effects} handleTimeout={handleTimeout} handleCaptureEffect={handleCaptureEffect} />
        </>
      )}
    </Styled.Container>
  );
};
