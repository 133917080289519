import { InputHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';
import ReactCodeInput from 'react-code-input';
import styles from './styles.module.css';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  value: string;
  containerClassName?: string;
  onChange: (...args: any) => void;
  isValid: boolean;
  disabled: boolean;
  fields?: number;
}

export const CodeInput = forwardRef<HTMLInputElement, IProps>(
  ({ isValid, disabled, name = '', value = '', onChange, containerClassName, fields = 6 }, ref) => {
    const props = {
      className: `${styles.codeInput} ${containerClassName}`,
      inputStyle: {
        border: 'var(--basic-border)',
        borderWidth: '2px',
        borderRadius: '7px',
        fontSize: '65px',
        width: '65px',
        height: '94px',
        backgroundColor: 'transparent',
        color: 'var(--font-color-strong)',
      },
      inputStyleInvalid: {
        fontSize: '65px',
        width: '65px',
        height: '94px',
        backgroundColor: 'transparent',
        border: '2px solid #F14F63',
        borderRadius: '7px',
        color: 'var(--font-color-strong)',
      },
    };
    return (
      <ReactCodeInput
        autoFocus
        isValid={isValid}
        disabled={disabled}
        value={value}
        onChange={onChange}
        name={name}
        inputMode={'numeric'}
        type="number"
        fields={fields}
        {...props}
      />
    );
  }
);
