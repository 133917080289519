import Document from './entities/document/Document';
import { getUserData } from 'store';
import { shallowEqual, useSelector } from 'react-redux';
import InventoryItem from 'components/library/cards/inventory/InventoryItem';
import { ESearchGroupKey } from '../types';
import CoursesBundle from 'components/pages/study/courses/courses-bundle';
import FriendCard from 'components/pages/profile/profile/FriendsSmall/FriendCard';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import PartnerItem from 'components/pages/shop/partner-item/partner-item';
import { SpaceItem } from 'components/pages/spaces/ui';

export default function SearchResults({ items }) {
  const { resultCourses } = useSelector(getUserData, shallowEqual);
  const navigate = useNavigate();

  const handleCourseNav = (_id) => () => {
    navigate(`${ROUTES.COURSES}/${_id}`);
  };

  return items.map(([group, arrOfItems = []], idx) => {
    if (arrOfItems.length < 1) {
      return null;
    }

    switch (group) {
      case ESearchGroupKey.courses:
        for (const course of arrOfItems) {
          if (resultCourses && resultCourses?.find((i) => i?._id?.toString() === course?._id?.toString())) {
            course.isBought = true;
          }
        }
        return (
          <div className="search-more-group" id={group} key={'search-more-group' + idx}>
            <p className="group-name">{group}</p>
            <div className="group">
              <CoursesBundle bundle={arrOfItems} onClick={handleCourseNav} />
            </div>
          </div>
        );
      case ESearchGroupKey.spaces:
        return (
          <div className="search-more-group" id={group} key={'search-more-group' + idx}>
            <p className="group-name">{group}</p>
            <div className={`group ${ESearchGroupKey.spaces}`}>
              {arrOfItems.map((space) => (
                <div key={space._id} className="space">
                  <SpaceItem space={space} forcedMobile />
                </div>
              ))}
            </div>
          </div>
        );
      case ESearchGroupKey.items:
        return (
          <div className="search-more-group" id={group} key={'search-more-group' + idx}>
            <p className="group-name">{group}</p>
            <div className="group">
              {[...arrOfItems]
                .filter((item) => item.item.type === 'fungible')
                .map((item) => (
                  <InventoryItem itemId={item.item.id} isLarge key={item.item.name + idx} />
                ))}
            </div>
            <p className="group-name">digitals</p>
            <div className="group">
              {[...arrOfItems]
                .filter((item) => item.item.type === 'digital')
                .map((item, index) => (
                  <PartnerItem key={item.item.name + index} item={item} />
                ))}
            </div>
            <p className="group-name">NFTs</p>
            <div className="group">
              {[...arrOfItems]
                .filter((item) => item.item.type === 'non-fungible')
                .map((item, index) => (
                  <PartnerItem key={item.item.name + index} item={item} />
                ))}
            </div>
          </div>
        );
      case ESearchGroupKey.users:
        return (
          <div className="search-more-group" id={group} key={'search-more-group' + idx}>
            <p className="group-name">{group}</p>
            <div className="group">
              {arrOfItems.map((item) => (
                <FriendCard key={item.nickname + idx} {...item} />
              ))}
            </div>
          </div>
        );
      case ESearchGroupKey.docs:
        return (
          <div className="search-more-group" id={group} key={'search-more-group' + idx}>
            <p className="group-name">{group}</p>
            <div className="group">
              {arrOfItems.map((item) => (
                <Document key={item.translationKey} {...item} />
              ))}
            </div>
          </div>
        );

      default:
        return <></>;
    }
  });
}
