import { TButtonsProps } from './types';
import PreaparedButton from './styledButton';
import style from './style.module.scss';
import { useEVMAuth } from 'hooks';
import { useEffect } from 'react';

const EVMLinkButton = ({ title, handleClose, setStage, stage }: TButtonsProps) => {
  const { handleAttach, isAttaching, openModal, isConnected, closeModal } = useEVMAuth();

  useEffect(() => {
    if (isConnected) {
      setStage('progress');
      handleAttach()
        .then(() => {
          setStage('success');
          setTimeout(() => {
            setStage('initial');
          }, 1000);
        })
        .finally(() => {
          handleClose();
          closeModal();
        });
    }
  }, [isConnected]);

  const handleModal = () => {
    setStage('initial');
    openModal();
  };

  return (
    <div className={style.wrapper}>
      <PreaparedButton onClick={handleModal} isDisabled={isAttaching || stage === 'error'} title={title} />{' '}
    </div>
  );
};

export default EVMLinkButton;
