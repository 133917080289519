import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useStateSelector } from 'hooks/useStateSelector';
import { deepCopy } from 'utils/object';
import { ROUTES } from 'app/constants';
import { coursesConstructorActions, coursesConstructorAPI, NEW_ITEM_ID } from 'store';
import { dispatch } from 'hooks';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { toast } from 'react-toastify';

import DisabledOverlay from 'components/library/disabledOverlay';
import Layout from 'pages/coursesConstructor/components/Layout';
import ChapterListItem from './components/ChapterListItem';
import { buildBreadcrumbs } from '../../../../utils/breadcrumbs';
import LogoLoader from 'shared/ui/loader/MightyLoader';
import { ConfirmationModal } from 'shared/ui/course-card';
import { useGetIfUserCanEditCourse } from 'pages/coursesConstructor/hooks';
import Answer from 'components/library/messages/Answer';
import { CourseParserModal } from 'features/CourseParserModal';

import { ReactComponent as DotsIcon } from 'assets/icons/dots-double-vertical.svg';

import styles from './styles.module.css';
import ConstructorButton from 'shared/ui/constructorButtons';
import { IChapter } from 'interface/courses';
import { Flex } from 'shared/ui';

const SortableItem = ({ id, children, isEditMode }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <Flex ref={setNodeRef} style={style} className={styles.dndItem}>
      {!isEditMode && (
        <div className={styles.dragIcon} {...attributes} {...listeners}>
          <DotsIcon />
        </div>
      )}
      {children}
    </Flex>
  );
};

export const CoursesConstructorChaptersList: FC = () => {
  const { courseId, spaceName } = useParams<{ courseId: string; spaceName?: string }>();
  const navigate = useNavigate();

  const [updateChapter] = coursesConstructorAPI.useUpdateChapterMutation();
  const [deleteChapter, { isLoading: isDeletingChapter }] = coursesConstructorAPI.useDeleteChapterMutation();
  const [addChapter, { isLoading: isAddingChapterLoading }] = coursesConstructorAPI.useCreateChapterMutation();
  const [reorderChapters, { isLoading: isReordering }] = coursesConstructorAPI.useReorderChaptersMutation();
  const [toggleLockChapter, { isLoading: isLockingChapter }] = coursesConstructorAPI.useToggleLockChapterMutation();

  const { data, isLoading, isError, refetch } = coursesConstructorAPI.useFetchChaptersQuery(courseId as string);
  const breadcrumbs = data?.breadcrumbs;
  const chaptersRewards = data?.chaptersRewards ?? {};
  const authors = data?.authors ?? [];

  const { chapters: originalChapters = [] } = useStateSelector<{ chapters: any; courses: any }>((state) => state.coursesConstructor);
  const [chapters, setChapters] = useState<IChapter[]>(originalChapters);
  const [courseParserIsOpen, setCourseParserIsOpen] = useState(false);

  const sensors = useSensors(useSensor(PointerSensor));

  useEffect(() => {
    setChapters(originalChapters);
  }, [originalChapters]);

  const isAddingNewChapter = useMemo(() => chapters?.some((item: any) => item._id === NEW_ITEM_ID), [chapters]);

  const [chapterIsInEditId, setChapterIsInEditId] = useState<string | null>(null);
  const [chapterForDeletion, setChapterForDeletion] = useState<string | null>(null);
  const { isAllowed, isSpaceLoading } = useGetIfUserCanEditCourse(authors);
  const handleChapterIsInEditModeChanged = (chapterId: string) => () => {
    if (isAddingNewChapter) {
      dispatch(coursesConstructorActions.discardAddChapter());

      return;
    }

    if (chapterIsInEditId === chapterId) {
      setChapterIsInEditId(null);
      return;
    }

    setChapterIsInEditId(chapterId);
  };

  const handleChapterChange = (chapterId: string) => (form: { name: string }) => {
    const chapter = chapters.find((item: any) => item._id === chapterId);
    if (!chapter) {
      return;
    }

    if (chapterId === NEW_ITEM_ID) {
      const newChapter = deepCopy(chapter);
      newChapter.name = form.name;
      const { _id, ...newChapterData } = newChapter;

      addChapter(newChapterData)
        .unwrap()
        .then(() => {
          setChapterIsInEditId(null);
        })
        .catch((ex) => {
          toast.error(<Answer label={''} subtext={ex?.data?.message} type="incorrect" />);
        });

      return;
    }

    const updatedChapter = deepCopy(chapter);

    updateChapter({
      chapterId,
      dto: {
        ...updatedChapter,
        courseId: undefined,
        name: form.name,
      },
    }).then(() => {
      if (isError) {
        return;
      }

      setChapterIsInEditId(null);
    });
  };

  const handleChapterDelete = () => {
    if (chapterForDeletion) {
      deleteChapter(chapterForDeletion)
        .unwrap()
        .then(() => {
          dispatch(coursesConstructorActions.deleteChapter(chapterForDeletion));
          setChapterForDeletion(null);
          refetch();
        })
        .catch((ex) => {
          toast.error(<Answer label={'Error'} subtext={ex?.data?.message} type="incorrect" />);
        });
    }
  };

  const handleAddEmptyChapter = () => {
    dispatch(coursesConstructorActions.addEmptyChapter({ courseId }));
  };

  const handleEditChapterRequest = (chapterId: string) => () => {
    navigate(`/courses/edit/chapter/${chapterId}`);
  };

  const handleEditChapterRequestInSpace = (chapterId: string) => () => {
    navigate(`/${spaceName}/chapter/${chapterId}`);
  };

  useEffect(() => {
    if (authors.length > 0) {
      if (isAllowed) {
        refetch();
      } else if (!isSpaceLoading) {
        navigate(ROUTES.COURSES_CONSTRUCTOR);
      }
    }
  }, [authors, isAllowed, isSpaceLoading]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const chaptersCopy = [...chapters];
    const oldIndex = chaptersCopy.findIndex((item) => item._id === active.id);
    const newIndex = chaptersCopy.findIndex((item) => item._id === over.id);
    const newItems = arrayMove(chaptersCopy, oldIndex, newIndex);
    setChapters(newItems);

    const chaptersIds = newItems.map((i: any) => i._id);

    reorderChapters({ courseId: courseId!, chaptersIds })
      .unwrap()
      .catch((ex) => {
        setChapters(chaptersCopy);
        toast.error(<Answer subtext={ex?.data?.message} type="incorrect" />);
      });
  };

  const handleToggleLockChapter = async (chapterId: string) => {
    if (!isLockingChapter) {
      toggleLockChapter({ chapterId })
        .unwrap()
        .then((res) => {
          setChapters((prev: any) =>
            prev.map((curr: any) => {
              const item = res.chapters.find((ch: any) => ch._id === curr._id);
              if (item) {
                return { ...curr, dependencies: item.dependencies };
              }
              return curr;
            })
          );
          toast.success(<Answer label={'success'} subtext={res.message} type="correct" />);
        })
        .catch((ex) => {
          console.error(ex);
          toast.error(<Answer label={'Error'} subtext={ex?.data?.message} type="incorrect" />);
        });
    }
  };

  if (isError) return <div>Something went wrong...</div>;

  const isMenuDisabled = chapters.some((item: any) => item._id === NEW_ITEM_ID) || !!chapterIsInEditId;

  return (
    <>
      <ConfirmationModal
        title="Please confirm deletion"
        isLoading={isDeletingChapter}
        isOpen={!!chapterForDeletion}
        deleteCourse={handleChapterDelete}
        onClose={() => setChapterForDeletion(null)}
      />
      <CourseParserModal
        isOpen={courseParserIsOpen}
        onClose={() => setCourseParserIsOpen(false)}
        refetchCourse={refetch}
        courseParams={{
          courseId: courseId ?? '',
          chapterId: null,
        }}
      />
      <Layout title="Chapters" breadcrumbs={buildBreadcrumbs(breadcrumbs, spaceName)}>
        {isLoading ? (
          <LogoLoader className={styles.loader} />
        ) : (
          <>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={chapters.map((chapter) => chapter._id)} strategy={verticalListSortingStrategy}>
                {chapters.map((item, index) => {
                  const chapterItem = (
                    <ChapterListItem
                      key={item._id}
                      courseId={courseId}
                      index={index}
                      id={item._id}
                      isInEditMode={chapterIsInEditId === item._id || item._id === NEW_ITEM_ID}
                      name={item.name}
                      dependencies={item?.dependencies}
                      onIsInEditModeChange={handleChapterIsInEditModeChanged(item._id)}
                      onChange={handleChapterChange(item._id)}
                      onDelete={() => setChapterForDeletion(item._id)}
                      onEditChapterRequest={!!spaceName ? handleEditChapterRequestInSpace(item._id) : handleEditChapterRequest(item._id)}
                      onToggleLockChapter={handleToggleLockChapter}
                      rewards={chaptersRewards[item._id]}
                      onRewardsShow={() => {}}
                      isLoading={isAddingChapterLoading}
                    />
                  );

                  if ((chapterIsInEditId && item._id !== chapterIsInEditId) || (isAddingNewChapter && item._id !== NEW_ITEM_ID)) {
                    return (
                      <DisabledOverlay key={item._id}>
                        <div style={{ padding: '25px 5px' }}>{chapterItem}</div>
                      </DisabledOverlay>
                    );
                  }

                  return (
                    <SortableItem key={item._id} id={item._id} isEditMode={chapterIsInEditId === item._id}>
                      {chapterItem}
                    </SortableItem>
                  );
                })}
              </SortableContext>
            </DndContext>
            <div className={styles.buttonsContainer}>
              <ConstructorButton label="Add a new chapter" onClick={handleAddEmptyChapter} variant="add" disabled={isMenuDisabled} />
              <ConstructorButton label="Import from URL" onClick={() => setCourseParserIsOpen(true)} variant="import" disabled={isMenuDisabled} />
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default CoursesConstructorChaptersList;
