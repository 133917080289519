import { useEffect } from 'react';
import { ROUTES } from 'app/constants';
import { useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { tokenService } from 'services';
import { getIsAuth } from 'store';

export const WebviewRedirectPage = () => {
  const isAuth = useSelector(getIsAuth);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const accessToken = searchParams.get('access');
  const refreshToken = searchParams.get('refresh');

  useEffect(() => {
    if (isAuth) {
      navigate(ROUTES.MAIN, { replace: true });
      return;
    }
    if (accessToken && refreshToken) {
      tokenService.setAccessToken(accessToken);
      tokenService.setRefreshToken(refreshToken);
    }
    navigate(ROUTES.MAIN, { replace: true });
    setTimeout(() => {
      window.location.reload();
    }, 200);
  }, [accessToken, refreshToken, isAuth, navigate]);

  return <p>If you have knowledge, let others light their candles in it.</p>;
};
