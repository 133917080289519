import { FC, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import ConstructorUser from '../../ConstructorUser/ConstructorUser';
import InventoryItem from '../../../../../../../library/cards/inventory/InventoryItem';
import BasicButton from 'shared/ui/basic-button/button';
import { BsArrowRight } from 'react-icons/bs';
import { EItemFungibleType } from 'interface';
import CongratulationModal from '../CongratulationModal/CongratulationModal';
import { IChange, IContent, ICustomizationItem } from '../../ChangerList/types/types';
import { useDisplay } from 'hooks';
import { getCurrentTheme, getCustomizationItems, getUserData } from 'store';
import { ReactComponent as Angle } from 'assets/icons/angle.svg';
import { useSelector } from 'react-redux';

interface IProps {
  content: IContent;
  onClose: () => void;
  handleCustomization: (fungibleType: EItemFungibleType) => Promise<void>;
}

export interface IChanges {
  newValue: any;
  oldValue: any;
  itemId?: number;
}

const ChangerModal: FC<IProps> = ({ content, onClose, handleCustomization }) => {
  const {
    nickname,
    avatar,
    expTokenBalance,
    customization,
    exp: { items: userItems },
  } = useSelector(getUserData);
  const theme = useSelector(getCurrentTheme);
  const [isCongratulation, setIsCongratulation] = useState(false);
  const customizationItems = structuredClone(useSelector(getCustomizationItems));
  const [changes, setChanges] = useState<IChanges | null>(null);
  const currentInventoryItem = changes?.itemId || content.inventoryItem;
  const item: ICustomizationItem = customizationItems.find((i) => i.id === currentInventoryItem)!;
  const userBalance = userItems.find((i) => i.item.id === currentInventoryItem)?.quantity ?? 0;
  const { isMobile } = useDisplay();
  // TODO прикрутить рефетч инвентаря при использовании шмотки, или в сторе изменять

  useEffect(() => {
    return () => {
      setChanges(null);
    };
  }, []);

  function handleChanges(newValue: IChange, itemId: number) {
    setChanges({ newValue, oldValue: customization[content.value], itemId });
  }

  function handleApplyChanges() {
    if (changes) {
      const itemFungibleType = item?.fungibleType as EItemFungibleType;
      if (itemFungibleType) {
        handleCustomization(itemFungibleType as EItemFungibleType).then(() => setIsCongratulation(true));
      }
    }
  }

  if (!item) {
    return null;
  }

  return (
    <div className={`menu-main-wrap glass-div basic ${theme} strong ${styles.wrapper}`}>
      {isCongratulation && changes ? (
        <CongratulationModal
          text={content.congratulation}
          value={content.value}
          avatar={avatar}
          nickname={nickname}
          inventoryItem={item?.id}
          quantity={userBalance}
          onClose={onClose}
          changes={changes}
          theme={theme}
          customization={customization}
        />
      ) : (
        <>
          {isMobile && <Angle className={styles.angle} onClick={onClose} />}
          <h3 className={styles.title}>{content.title}</h3>
          <p className={styles.description}>{content.description}</p>
          <ConstructorUser
            currentItemId={currentInventoryItem}
            value={content.value}
            expToken={expTokenBalance}
            nickname={nickname}
            avatar={avatar}
            handleChanges={handleChanges}
            customization={customization}
          />
          <p className={styles.required}>Required</p>
          <div className={styles.row}>
            <div>
              <InventoryItem amount={userBalance} itemId={currentInventoryItem} isRightInfo isShowTooltip={false} />
            </div>
            {!isMobile && (
              <BasicButton
                className={styles.button}
                isLabelFirst
                icon={<BsArrowRight />}
                label="Change"
                variant="cool"
                onClick={handleApplyChanges}
                isDisabled={changes === null || userBalance < 1}
              />
            )}
          </div>
          {isMobile && (
            <BasicButton
              className={styles.button}
              label="Change"
              variant="cool"
              onClick={handleApplyChanges}
              isDisabled={changes === null || userBalance < 1}
            />
          )}
        </>
      )}
    </div>
  );
};

export default ChangerModal;
