import * as S from './styles';
import Answer from './components/Answer';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { IAnswer } from 'store/answers/types';
import { IComment } from 'store';
import Comment from './components/Comment';

type AnswerProps = {
  answers: IAnswer[];
  isLoading: boolean;
  removeItem: (_id: string) => void;
};

export const SlideAnswers = ({ answers, isLoading, removeItem }: AnswerProps) => {
  if ((!isLoading && !answers) || !answers.length) {
    return <>No answers ;(</>;
  }

  return (
    <S.Container>
      {isLoading && <MightyLoaderMini />}

      {answers && !isLoading && (
        <S.List>
          {answers.map((el) => (
            <Answer key={el._id} removeAnswer={removeItem} answer={el} />
          ))}
        </S.List>
      )}
    </S.Container>
  );
};

type CommentProps = {
  comments: IComment[];
  isLoading: boolean;
  removeItem: (_id: string) => void;
};

export const SlideComments = ({ comments, isLoading, removeItem }: CommentProps) => {
  if ((!isLoading && !comments) || !comments.length) {
    return <>No comments ;(</>;
  }

  return (
    <S.Container>
      {isLoading && <MightyLoaderMini />}

      {comments && !isLoading && (
        <S.List>
          {comments.map((el) => (
            <Comment key={el._id} removeComment={removeItem} comment={el} />
          ))}
        </S.List>
      )}
    </S.Container>
  );
};
