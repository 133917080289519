import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    grid-area: pockets;
    display: flex;
    justify-content: space-between;
    max-width: var(--max-profile-width);
    min-width: 100%;
    gap: 95px;
    margin-bottom: 80px !important;
    margin-top: 18px;
    z-index: 2 !important;

    @media screen and (max-width: 1250px) {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  `
);
