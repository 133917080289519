import { createContext, useContext } from 'react';
import { ISpace } from 'store';

export type TSpacePageModal = 'edit' | 'analytics' | null;

export const SpacePageContext = createContext<{
  space: ISpace | null;
  updateSpace: (arg: Partial<ISpace>) => void;
  setModal: (arg: TSpacePageModal) => void;
  modal: TSpacePageModal;
}>({
  space: null,
  updateSpace: () => {},
  modal: null,
  setModal: () => {},
});

export const useSpacePageContext = () => useContext(SpacePageContext);
