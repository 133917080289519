import styled from 'styled-components';

export const EditorContainer = styled.div`
    width: 100%;
    position: relative;
  `,
  SCompileButton = styled.button`
    width: 100px;
    height: 45px;
    border-radius: 0 0 10px 0;
    background-color: #6487ef;
    color: #fff;
    position: absolute;
    bottom: -20px;
    right: -20px;
    border: none;
    transition: background-color 0.15s ease-in-out;

    &:hover {
      background-color: #5172de;
    }
  `;
