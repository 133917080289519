import { FC } from 'react';
import { motion } from 'framer-motion';

import { useDisplay } from 'hooks';

import { bubbles } from './data/data';
import { IDatabaseItem } from 'interface';

import { ReactComponent as Undercolor } from '../../assets/undercolor.svg';
import * as Styled from './styled';

interface ICurrentItemProps {
  isActive: boolean;
  isSuccess: boolean;
  item: IDatabaseItem;
  gradeColor: string;
  showBubbles: boolean;
}

export const CurrentItem: FC<ICurrentItemProps> = ({ isActive, isSuccess, item, gradeColor, showBubbles }) => {
  const { isMobile } = useDisplay();

  const currentVariant = isMobile
    ? { animate: isActive ? { y: isSuccess ? 100 : 180, scale: isSuccess ? 1.4 : 1.25 } : {} }
    : { animate: isActive ? { y: isSuccess ? 130 : 160, scale: isSuccess ? 0.85 : 1.04 } : {} };

  return (
    <Styled.Container as={motion.div} {...currentVariant} $active={isActive} style={{ willChange: 'transform' }}>
      <Styled.ImageWrap>
        <Styled.Image src={item.image} alt={item.name} />
        <Undercolor className={'activate-item__undercolor'} color={gradeColor} />
        {showBubbles && (
          <>
            {bubbles.map((bubble, index) => (
              <Styled.Bubble
                as={motion.span}
                initial={{ opacity: 0 }}
                animate={{ y: [0, -80], opacity: [0, 1, 0] }}
                transition={{ repeat: Infinity, ease: 'linear', duration: 2, delay: index * 0.3 }}
                key={index}
                $size={bubble.size}
                $position={bubble.position}
                $color={gradeColor}
              />
            ))}
          </>
        )}
      </Styled.ImageWrap>
    </Styled.Container>
  );
};
