import styled from 'styled-components';

export const Container = styled.div`
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
  `,
  Effects = styled.button`
    width: 100%;
  `,
  Chevron = styled.button`
    color: var(--font-color-strong);
    display: none;
    align-items: center;

    &.visible {
      display: flex;
    }
  `;
