import { Button as Btn, Flex } from 'shared/ui';
import styled, { css } from 'styled-components';

export const Container = styled(Flex)(() => css``),
  Button = styled(Btn)(
    () => css`
      padding-bottom: 5px;
      color: var(--font-color-strong);

      &.active {
        border-bottom: 3px solid var(--mighty-blue);
      }
    `
  );
