import { useGetCurrentTheme } from 'hooks';
import { ReactComponent as AddIcon } from './assets/add-icon.svg';
import { ReactComponent as ImportIcon } from './assets/import-icon.svg';

import * as S from './styles';

type Props = {
  label: string;
  onClick: () => void;
  variant: 'add' | 'import';
  disabled?: boolean;
};

const ICONS = {
  add: <AddIcon />,
  import: <ImportIcon />,
};

const ConstructorButton = ({ label, onClick, variant, disabled }: Props) => {
  const theme = useGetCurrentTheme();

  return (
    <S.Button $theme={theme} $disabled={disabled} className={variant} onClick={onClick}>
      <S.IconBox>{ICONS[variant]}</S.IconBox>
      <S.Label>{label}</S.Label>
    </S.Button>
  );
};

export default ConstructorButton;
