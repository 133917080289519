import styled from 'styled-components';

export const Container = styled.label`
    display: block;
    padding: 20px;
    background: rgba(255, 255, 255, 0.04);
    border-radius: 4px;
    &.error {
      box-shadow: 0px 0px 0px 1px #f14f63;
    }
  `,
  Input = styled.input`
    padding: 0 2px;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    caret-color: var(--font-color-strong);
    color: var(--font-color-strong);
    background: transparent;

    font-size: 16px;
    line-height: 145%;
    font-weight: 400;

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 1000px var(--basic-light-bg) inset !important;
      -webkit-text-fill-color: var(--basic-light) !important;
    }
    &.dark:-webkit-autofill,
    &.dark:-webkit-autofill:hover,
    &.dark:-webkit-autofill:active,
    &.dark:-webkit-autofill:focus {
      -webkit-box-shadow: 0 0 0 1000px var(--basic-dark-bg) inset !important;
      -webkit-text-fill-color: var(--basic-dark) !important;
    }
  `;
