export const ErrorHandler = (error: any) => {
  if (error) {
    const { status, message } = error;

    if (status === 400 || status === 401) {
      return;
    }

    throw new Response('', {
      status,
      statusText: message || 'test',
    });
  }
  return null;
};
