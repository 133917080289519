import { IExpEffectWidgetData } from 'shared/ui/effect-indicator/types';
import { useSelector } from 'react-redux';
import { getItems } from 'store';
import { useMemo } from 'react';

import { EffectIndicator } from 'shared/ui';
import { Badges } from '../../badges';
import { HoverData } from 'shared/ui/effect-indicator';

import { Body, Container, Header, Widget } from './styles';

interface Props extends IExpEffectWidgetData {
  handleTimeout(): void;
  handleCaptureEffect(effectName: string): () => void;
}

export const EffectsListItem = ({ badges, type, handleTimeout, handleCaptureEffect, ...rest }: Props) => {
  const items = useSelector(getItems);
  const item = useMemo(() => items.find((item) => item.fungibleType === rest.effect.name), [items, rest.effect.name]);

  return (
    <Container onClick={handleCaptureEffect(type)} key={rest.effect.expiredAt}>
      <Widget {...rest} as={EffectIndicator} onTimeout={handleTimeout} type={type} />
      <Body>
        {item ? (
          <Header className={item.grade}>{item.name}</Header>
        ) : (
          <Header>
            <HoverData type={type} isBadge={false} />
          </Header>
        )}
        {badges && <Badges items={badges} />}
      </Body>
    </Container>
  );
};
