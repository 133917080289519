import moment from 'moment';
import { EVENTS, GRADES, SUBJECTS } from 'app/constants';
import { getItemByKey } from 'utils';
import { useGetCurrentTheme, useGetItems } from 'hooks';

import InventoryItem from '../cards/inventory/InventoryItem';
import AvatarPic from '../utils/AvatarPic/avatar';
import { LazyImage } from 'shared/ui/lazyImage';
import { PremiumIcon } from 'shared/ui';

import styles from './styles.module.scss';

const Notif = ({
  event,
  eventData,
  subject,
  status,
  date,
  _id,
  handleAddFriend,
  handleDeclineFriend,
  handleAcceptChallenge,
  handleRejectBattleChallenge,
  loading,
}) => {
  const theme = useGetCurrentTheme();
  const items = useGetItems();

  const checkType = () => {
    try {
      if (subject === SUBJECTS.ITEMS || subject === SUBJECTS.NFTS) {
        const { item: id, amount } = eventData;
        const item = getItemByKey('id', id, items);

        if (!item) {
          return null;
        }

        if (event === EVENTS.ITEMRECEIVED) {
          const message = {
            title: (
              <span>
                You have received{' '}
                <span style={{ color: `var(--grade-${theme}-color-${item.grade})` }}>
                  {amount} {item.name}
                </span>
              </span>
            ),
            img: <InventoryItem itemId={item.id} isSmall isRightTooltip isAmountShown={false} />,
            color: `var(--grade-${theme}-color-${item.grade})`,
          };
          return message;
        }
        if (event === EVENTS.ITEMDAILYREWARDRECEIVED) {
          const message = {
            title: (
              <span>
                You have received{' '}
                <span style={{ color: `var(--grade-${theme}-color-${item.grade})` }}>
                  {amount} {item.name}
                </span>{' '}
                as daily reward
              </span>
            ),
            img: <InventoryItem itemId={item.id} isSmall isRightTooltip isAmountShown={false} />,
            color: `var(--grade-${theme}-color-${item.grade})`,
          };
          return message;
        }
        if (event === EVENTS.NFTTRANSFERED) {
          return {
            title: (
              <span>
                NFT <span style={{ color: `var(--grade-${theme}-color-${item.grade})` }}>{item.name}</span> was successfully transferred
              </span>
            ),
            img: <InventoryItem itemId={item.id} isSmall isRightTooltip isAmountShown={false} />,
            color: `var(--grade-${theme}-color-${item.grade})`,
          };
        }
        if (event === EVENTS.ITEMPURCHASED) {
          const message = {
            title: (
              <span>
                You have bought{' '}
                <span style={{ color: `var(--grade-${theme}-color-${item.grade})` }}>
                  {amount} {item.name}
                </span>
              </span>
            ),
            img: <InventoryItem itemId={item.id} isSmall isRightTooltip isAmountShown={false} />,
            color: `var(--grade-${theme}-color-${item.grade})`,
          };
          return message;
        }
        if (event === EVENTS.ITEMACTIVATED) {
          const message = {
            title: (
              <span>
                You have activated <span style={{ color: `var(--grade-${theme}-color-${item.grade})` }}>{item.name}</span>
              </span>
            ),
            img: <InventoryItem itemId={item.id} isSmall isRightTooltip isAmountShown={false} />,
            color: `var(--grade-${theme}-color-${item.grade})`,
          };
          return message;
        }
        if (event === EVENTS.DISCOUNTCODEACTIVATED) {
          const message = {
            title: (
              <span>
                You have activated <span style={{ color: `var(--grade-${theme}-color-${item.grade})` }}>{item.name}</span> code
              </span>
            ),
            img: <InventoryItem itemId={item.id} isSmall isRightTooltip isAmountShown={false} />,
            color: `var(--grade-${theme}-color-${item.grade})`,
          };
          return message;
        }
      }
      if (subject === SUBJECTS.BATTLES) {
        let { user, battle } = eventData;
        if (event === EVENTS.USERCHALLENGED) {
          const message = {
            title: <span>{user.nickname} sent a battle request</span>,
            img: <AvatarPic className={styles.avatar} avatar={user.avatar} nickname={user.nickname} />,
            color: 'var(--basic-blue)',
            btns: [
              {
                disabled: loading,
                onClick: function () {
                  handleAcceptChallenge(battle);
                },
                label: 'Accept',
                type: 'accept',
              },
              {
                disabled: loading,
                onClick: function () {
                  handleRejectBattleChallenge(battle);
                },
                label: 'Decline',
                type: 'decline',
              },
            ],
          };
          return message;
        }
      }
      if (subject === SUBJECTS.FRIENDS) {
        let { user } = eventData;
        if (!user) return null;
        if (event === EVENTS.USERFRIENDED) {
          const message = {
            title: <span>{user.nickname} is now your friend</span>,
            img: <AvatarPic className={styles.avatar} avatar={user.avatar} nickname={user.nickname} />,
            color: 'var(--basic-blue)',
          };
          return message;
        }
        if (event === EVENTS.USERFOLLOWED) {
          const message = {
            title: <span>{user.nickname} followed you</span>,
            img: <AvatarPic className={styles.avatar} avatar={user.avatar} nickname={user.nickname} />,
            color: 'var(--basic-blue)',
            btns: [
              {
                disabled: loading,
                onClick: function () {
                  handleAddFriend(eventData.user);
                },
                label: 'Accept',
                type: 'accept',
              },
              {
                disabled: loading,
                onClick: function () {
                  handleDeclineFriend(user._id);
                },
                label: 'Decline',
                type: 'decline',
              },
            ],
          };
          return message;
        }
      }
      if (subject === SUBJECTS.PREMIUM) {
        return {
          title: (
            <span>
              {event === EVENTS.PREMIUMACTIVATED ? (
                <>
                  You have activated <span style={{ color: `var(--grade-${theme}-color-${GRADES.LEGENDARY})` }}>Premium</span> sub
                </>
              ) : (
                <>
                  <span style={{ color: `var(--grade-${theme}-color-${GRADES.LEGENDARY})` }}>Premium</span> sub has expired
                </>
              )}
            </span>
          ),
          img: <PremiumIcon width={'100%'} height={'100%'} />,
          color: `var(--grade-${theme}-color-${GRADES.LEGENDARY})`,
        };
      }
    } catch (error) {
      return null;
    }
  };

  const notifItem = checkType();

  if (!notifItem) return <></>;

  const { title, img, color, btns } = notifItem;

  try {
    return (
      <div className="single-ntf-wrap bright--hover">
        <div className="event-data__wrap">
          <div className="dot-wrap">
            <span className="dot" style={{ background: color }}></span>
          </div>
          <div className="ntf-desc-wrap">
            <h6>{title}</h6>
            <h6 id="date">{moment(date).format('MMM DD, YYYY [at] hh:mm A')}</h6>
          </div>
          <div className="ntf-img">{typeof img === 'string' ? <LazyImage src={img} alt="" /> : img}</div>
        </div>
        <div className="btns">
          {status === 'pending' &&
            btns &&
            btns.map(({ disabled, onClick, label, type }, index) => (
              <button disabled={disabled} onClick={onClick} key={_id + index} className={`notif-btn ${type}`}>
                {label}
              </button>
            ))}
        </div>
      </div>
    );
  } catch (ex) {
    return <p>{ex.message}</p>;
  }
};

export default Notif;
