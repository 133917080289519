import { TVariantWarningIcon } from './components/Widget/components/WarningWidget/WarningIcons/types';

export type TCourseConstructorProps = {
  children: React.ReactNode;
  index?: number;
  slideId?: string;
};

export enum ECourseConstructorWidgetTypes {
  TEXT = 'Text',
  WARNING = 'Warning',
  IMAGE = 'Image',
  VIDEO = 'Video',
  FILE = 'File',
  CODE = 'Code',
  TASK = 'Task',
  QUOTE = 'Quote',
}

export type TCourseConstructorWidget = {
  id: string;
  type: ECourseConstructorWidgetTypes;
  content: string;
  icon: TVariantWarningIcon;
  color: string;
  title?: string;
  author?: string;
};
