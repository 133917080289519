import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import clsx from 'clsx';
import styles from './styles.module.css';
import { ReactComponent as MailIcon } from 'assets/components/mail.svg';
import { ReactComponent as PassIcon } from 'assets/components/password.svg';
import { Error, StrongPasswordIndicator, Terms } from 'features/auth/ui';
import { getPasswordStrong } from 'features/auth/utils';
import { ISignUpForm } from 'interface';
import { BasicButtonV1, Checkbox, Input, InputPassword } from 'shared/ui';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';

interface IProps {
  isLoading: boolean;
  error?: string;
  onSubmitHandler: (data: ISignUpForm) => void;
}

// const captchaKey = process.env.REACT_APP_CAPTCHA_KEY ?? '';

// const baseURL = process.env.REACT_APP_BACKEND_URL || 'http://localhost:4000/api';

export const SignUpForm = ({ onSubmitHandler, isLoading, error }: IProps) => {
  const { t } = useTranslation(['auth', 'common']);
  // const $hCaptchaRef = useRef<HCaptcha>(null);
  const [checked, setChecked] = useState(false);
  const [isCaptchValid] = useState(true);
  const [checkboxError, setCheckboxError] = useState(false);
  const schema = yup.object().shape({
    email: yup.string().email(t('common:formErrors.email')).required(t('common:formErrors.requiredEmail')),
    password: yup
      .string()
      .min(5, t('common:formErrors.minLength', { count: 5 }))
      .max(32, t('common:formErrors.maxLength', { count: 32 }))
      .required(t('common:formErrors.requiredPassword')),
    confirm_password: yup
      .string()
      .min(5, t('common:formErrors.minLength', { count: 5 }))
      .max(32, t('common:formErrors.maxLength', { count: 32 }))
      .required(t('common:formErrors.requiredPassword'))
      .oneOf([yup.ref('password')], t('common:formErrors.notMatchPassword')),
  });
  const {
    control,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors, isValid },
  } = useForm<ISignUpForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  // const handleCaptchaChange = async (token: string) => {
  //   try {
  //     const { data } = await axios.post(`${baseURL}/captcha/verify`, { captchaPassCode: token });
  //     const { success } = data;
  //     if (!success) {
  //       $hCaptchaRef.current?.resetCaptcha();
  //       return;
  //     }

  //     setIsCaptchaValid(success);
  //   } catch (err) {
  //     $hCaptchaRef.current?.resetCaptcha();
  //   }
  // };

  // Логика для слежения за изменениями ввода паролей
  const password = watch('password');
  const cpassword = watch('confirm_password');
  useEffect(() => {
    if (password !== cpassword && !!cpassword && !!password) {
      setError('confirm_password', { message: "The passwords don't match" });
    } else {
      clearErrors('confirm_password');
    }
  }, [password, cpassword]);

  const onSubmit = (data: ISignUpForm) => {
    if (!isCaptchValid) {
      toast.error(<Answer type="incorrect" subtext="Please fill captcha" />);
      return;
    }

    if (checked) {
      onSubmitHandler(data);
    } else {
      toast.error(<Answer type="incorrect" subtext="Error: Approve Terms and Conditions to Register" />);
      setCheckboxError(true);
    }
  };

  const { strong: passwordStrength } = getPasswordStrong(password, t('common:strongPassword', { returnObjects: true }));

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input
            icon={MailIcon}
            type="email"
            placeholder={t('signUp.form.email')}
            className={styles.input}
            isError={!!errors?.email?.message}
            {...field}
          />
        )}
      />
      <Controller
        name="password"
        control={control}
        render={({ field }) => (
          <InputPassword
            className={clsx(styles.input, {
              [styles.inputWithValue]: !!password,
              [styles.lowStrongPassword]: passwordStrength === 'no_lower' || passwordStrength === 'no_upper' || passwordStrength === 'no_number',
              [styles.middleStrongPassword]: passwordStrength === 'middle',
              [styles.highStrongPassword]: passwordStrength === 'high',
            })}
            icon={PassIcon}
            placeholder={t('signUp.form.password')}
            isError={!!errors?.password?.message || !!errors?.confirm_password?.message}
            {...field}
          />
        )}
      />
      <StrongPasswordIndicator passInfo={getPasswordStrong(password, t('common:strongPassword', { returnObjects: true }))} />
      <Controller
        name="confirm_password"
        control={control}
        render={({ field }) => (
          <InputPassword
            className={clsx({
              [styles.lowStrongPassword]: passwordStrength === 'no_lower' || passwordStrength === 'no_upper' || passwordStrength === 'no_number',
              [styles.middleStrongPassword]: passwordStrength === 'middle',
              [styles.highStrongPassword]: passwordStrength === 'high',
            })}
            icon={PassIcon}
            placeholder={t('signUp.form.password')}
            isError={!!errors?.password?.message || !!errors?.confirm_password?.message}
            {...field}
          />
        )}
      />
      <div className={styles.errorContainer}>
        <Error title={errors?.email?.message || errors?.password?.message || errors?.confirm_password?.message || error} />
      </div>

      <div className={styles.termsContainer}>
        <Checkbox onChange={() => setChecked(!checked)} defaultChecked={checked} containerClassName={styles.checkbox} isError={checkboxError} />
        <Terms />
      </div>

      {/* <div style={{ margin: '10px 0' }}>
        <HCaptcha ref={$hCaptchaRef} sitekey={captchaKey} onVerify={handleCaptchaChange} />
      </div> */}

      <BasicButtonV1
        isLoading={isLoading}
        disabled={
          !checked || isLoading || !isValid || passwordStrength === 'no_lower' || passwordStrength === 'no_upper' || passwordStrength === 'no_number'
        }
        type={'submit'}
      >
        {t('signUp.form.submit')}
      </BasicButtonV1>
    </form>
  );
};
