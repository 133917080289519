import { Outlet } from 'react-router-dom';
import { AuthPageView } from './pageView';

export const AuthPageV2 = () => {
  return (
    <AuthPageView>
      <Outlet />
    </AuthPageView>
  );
};
