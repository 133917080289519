import styled, { css } from 'styled-components';

export const SButton = styled.button(
  ({ theme }) => css`
    outline: none;
    display: flex;
    align-items: center;
    font-size: 16px;
    padding: 9px 14px 9px 8px;
    border-radius: 5px;
    line-height: 1;
    height: 34px;
    width: fit-content;
    border: var(--basic-border);
    background: transparent;
    color: var(--font-color);
  `
);
