import clsx from 'clsx';
import { Await, useLoaderData, useNavigate } from 'react-router-dom';
import { useDisplay } from 'hooks';
import { IResultCourse, TResultCourseWithFullCourseId } from 'interface/courses';
import { ROUTES } from 'app/constants';

import { ProfileCourseCard } from 'shared/ui/course-card';
import ProfileCoursesPlaceholder from './ProfileCoursesPlaceholder/ProfileCoursesPlaceholder';

import styles from './styles.module.css';

interface IProps {
  className?: string;
  coursesGiven?: IResultCourse[];
  onCourseCapture: (_id: string) => void;
}

export default function Courses({ className = '', onCourseCapture, coursesGiven }: IProps) {
  const coursesData = useLoaderData() as { coursesStartedFromLoader: Promise<TResultCourseWithFullCourseId[]> };

  const navigate = useNavigate();
  const { isMobile } = useDisplay();

  return (
    <Await resolve={coursesData?.coursesStartedFromLoader}>
      {(coursesLoaded) => {
        const courses = coursesGiven ?? coursesLoaded ?? [];
        return (
          <div className={clsx(styles.container, { [className]: !!className })}>
            <div className={'see-all-label'} onClick={() => navigate(ROUTES.MAIN, { replace: true })}>
              See All
            </div>

            {courses.length > 0 ? (
              <div className={clsx(styles.courses, 'mobile-padding', { [styles.large]: courses.length > 1 && !isMobile })}>
                {courses.map((course: TResultCourseWithFullCourseId, index: number) => (
                  <ProfileCourseCard onClick={() => onCourseCapture(course._id)} {...course} key={'profile-course' + index} />
                ))}
                <button
                  className={clsx(styles.seeAll, { [styles.three]: courses.length === 3 && !isMobile, [styles.mobile]: isMobile }, 'see-all-button')}
                  onClick={() => navigate(ROUTES.MAIN, { replace: true })}
                >
                  See all
                </button>
              </div>
            ) : (
              <div className="mobile-padding">
                <ProfileCoursesPlaceholder />
              </div>
            )}
          </div>
        );
      }}
    </Await>
  );
}
