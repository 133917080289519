import { useEffect, useState } from 'react';
import { IDatabaseItemQuantified } from 'interface';
import { useSelector } from 'react-redux';
import { dispatch, useDisplay, useGetMultipliedExp } from 'hooks';
import { setText as setTextForSpeech, getCurrentTheme } from 'store';

import InventoryItem from 'components/library/cards/inventory/InventoryItem';
import { useMaterialPageContext } from '../../materialPageContext';
import { TextToSpeech } from 'features/TextToSpeech';
import getSubtitle from '../../tests/utils/getSubtitle';

import * as Styled from './styles';

export default function Underlesson({ slide }: any) {
  const { getMultipliedExp } = useGetMultipliedExp();
  const [text, setText] = useState<any>('');
  const { rewards = [], slideId }: { rewards: IDatabaseItemQuantified[]; slideId: string } = useMaterialPageContext()!;
  const theme = useSelector(getCurrentTheme);
  const { isMobile } = useDisplay();

  const getTitle = () => {
    if (slide.typeOfSlide === 'Info') {
      return setText(slide.name + '.');
    }
    return setText(getSubtitle(slide.typeOfSlide) + '.');
  };

  const getTextToSpeech = () => {
    try {
      if (slideId === slide._id) {
        getTitle();
        slide?.content
          .filter((content: any) => content.type === 'Text' || content.type === 'Warning')
          .forEach((content: any) => {
            const JsonContent = JSON.parse(content?.content);
            if (JsonContent?.content) {
              JsonContent.content.forEach((con: any) => {
                if (con.content) {
                  con.content.forEach((c: any) => c.type === 'text' && setText((prevState: any) => prevState + ' ' + c.text));
                }
              });
              setText((prevState: any) => prevState + '.');
            }
          });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setText('');
    getTextToSpeech();
  }, [slide]);

  useEffect(() => {
    dispatch(setTextForSpeech(text));
  }, [text]);

  return (
    <Styled.Container>
      <Styled.Label>
        <Styled.Text className={`glass-div linear ${theme}`}>Continue with audio</Styled.Text>
        <TextToSpeech className="play-button" />
      </Styled.Label>
      {Boolean(rewards.length) && (
        <div className="part-rewards">
          <p>Rewards for test</p>
          <div className={`rewards sb ${isMobile && 'mobile'}`}>
            {rewards.slice(0, isMobile ? 3 : rewards.length).map(({ quantity, id }, index) => (
              <InventoryItem key={'material-page-rew' + index} isCircle={isMobile} itemId={id} amount={getMultipliedExp(id, quantity)} isSmall />
            ))}
          </div>
        </div>
      )}
    </Styled.Container>
  );
}
