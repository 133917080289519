import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { ROUTES } from 'app/constants';

interface IProps {
  isAuth: boolean;
  redirectPath?: string;
}

export const ProtectedRoute = ({ isAuth, redirectPath = ROUTES.AUTH }: IProps) => {
  const { pathname } = useLocation();
  return isAuth ? <Outlet /> : <Navigate to={redirectPath} state={{ prev: pathname }} />;
};
