import { ButtonHTMLAttributes, DetailedHTMLProps, forwardRef } from 'react';

import * as Styled from './styles';

export interface IButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: string | React.ReactNode;
}

export const NullButton = forwardRef<HTMLButtonElement, IButtonProps>(({ children, ...rest }, ref) => {
  return (
    <Styled.Button {...rest} ref={ref}>
      {children}
    </Styled.Button>
  );
});
