import { useDisplay } from 'hooks';
import clsx from 'clsx';
import { IExpEffectWidgetData } from 'shared/ui/effect-indicator/types';
import { useSelector } from 'react-redux';
import { getItems } from 'store';
import { useMemo } from 'react';

import { EffectIndicator, PremiumLabel } from 'shared/ui';
import { Badges } from '../../badges';
import { HoverData } from 'shared/ui/effect-indicator';

import * as Styled from './styles';

interface IProps {
  effect: IExpEffectWidgetData;
}

export const Head = ({ effect }: IProps) => {
  const items = useSelector(getItems);
  const item = useMemo(() => items.find((item) => item.fungibleType === effect.effect.name), [items, effect.effect.name]);

  const { isMobile } = useDisplay();
  const { badges, type } = effect;

  return (
    <Styled.Container className={clsx({ mobile: isMobile })}>
      <Styled.Indicator className={clsx({ mobile: isMobile })} {...effect} as={EffectIndicator} type={type} onTimeout={() => {}} />
      <Styled.Info className={clsx({ mobile: isMobile })}>
        <Styled.Header className={clsx({ mobile: isMobile })}>
          {['scholar-coin', 'emperor-badge', 'emperor-key', 'emperor-crown'].includes(type) ? (
            <PremiumLabel />
          ) : item ? (
            item.name
          ) : (
            <HoverData type={type} />
          )}
        </Styled.Header>
        {badges && <Styled.EffectBadges className={clsx({ mobile: isMobile })} as={Badges} items={badges} />}
      </Styled.Info>
    </Styled.Container>
  );
};
