import styled from 'styled-components';
import errIcon from '../../../assets/error.svg';
import pencilIcon from '../../../assets/penicl.svg';
import { THEMES } from 'app/constants';

export const Col = styled.div<{ $error?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: ${(p) => (p.$error ? '27px' : '0px')};
  transition: 0.3s;
`;

export const SpaceFormBox = Col;

export const Error = styled.p<{ $error?: boolean; $center?: boolean; $posStatic?: boolean }>`
  display: flex;
  transition: 0.3s;
  opacity: ${(p) => (p.$error ? 1 : 0)};
  pointer-events: ${(p) => (p.$error ? 'none' : 'all')};
  color: #f14f63;
  white-space: nowrap;
  gap: 6px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  position: ${(p) => (p.$posStatic ? 'static' : 'absolute')};
  bottom: -25px;
  ${(p) => p.$center && 'justify-content: center;'}
  width: 100%;
  &::before {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(${errIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const SpaceFormError = Error;

export const Label = styled.label<{ $theme?: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '#ffffff')};
  font-size: 18px;
  line-height: 19.8px;
  font-weight: 400;
  margin-bottom: 12px;
`;

export const SpaceFormLabel = Label;

export const Input = styled.input<{ $error?: boolean; $theme: THEMES }>`
  border: none;
  outline: none;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  padding: 0px 20px;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? 'rgb(230, 234, 250)' : '#FFFFFF1C')};
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};
  font-size: 18px;
  border: 1px solid transparent;
  transition: 0.3s;
  ${(p) => (p.$error ? 'border: 1px solid #F14F63; position: relative;' : '')}
`;

const pencilStuff = `
background-image: url(${pencilIcon});
  background-position: 21px 20px;
  background-repeat: no-repeat;
  text-indent: 30px;

&:focus {
    text-indent: 0px;
    background-position: -30px 20px;
}`;

export const TextArea = styled.textarea<{ $error?: boolean; $noPencil?: boolean; $theme: THEMES }>`
  border: 1px solid transparent;
  transition: 0.3s;
  width: 100%;
  height: 100px;
  padding: 20px;
  border: none;
  outline: none;
  border-radius: 4px;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? 'rgb(230, 234, 250)' : '#FFFFFF1C')};
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : 'white')};

  transition: 0.3s;
  resize: vertical;
  &::placeholder {
    color: #ffffff;
    opacity: 0.4;
  }
  ${(p) => (p.$error ? 'border: 1px solid #F14F63;' : '')}
  ${(p) => !p.$noPencil && pencilStuff}
`;
