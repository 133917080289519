export const screenVariants = {
  enter: {
    opacity: 0,
  },
  center: {
    opacity: 1,
    transition: {
      opacity: { duration: 0.2 },
    },
  },
  exit: {
    opacity: 0,
  },
};

export const pathVariants = {
  initial: { pathLength: 0, opacity: 0 },
  animate: {
    pathLength: 1,
    opacity: 1,

    transition: {
      opacity: {
        delay: 0.2,
      },

      pathLength: {
        duration: 0.8,
        ease: 'easeInOut',
        delay: 0.2,
      },
    },
  },
};

export const hintVariants = {
  initial: { opacity: 0, y: 20 },
  animate: {
    opacity: 1,
    y: 0,

    transition: {
      delay: 0.5,
    },
  },
};
