import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Lock = styled(motion.span)(
  () => css`
    background: var(--basic-blue);
    height: 24px;
    padding: 4px 10px 4px 8px;
    border-radius: 40px;
    color: white;
    display: flex;
    align-items: center;

    font-size: 14px;
    font-weight: 500;
    line-height: 1;

    svg {
      height: 100%;
      width: auto;
    }
  `
);
