import { FC, useMemo, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { toast } from 'react-toastify';
import { shallowEqual, useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { useActivateItem, useDisplay } from 'hooks';
import clsx from 'clsx';

import { BackArrow, CurrentItem } from './components';
import BasicButton from 'shared/ui/basic-button/button';
import { Activated } from '../../../../library/messages/Activated/Activated';

import { EActivateItemType, type IActivateItemProps } from './types/types';
import { components } from './data/data';

import * as Styled from './styled';
import { useGetEffectData } from './hooks';
import { EItemFungibleType } from 'interface';

export const ActivateItem: FC<IActivateItemProps> = ({ item, handleClose, type }) => {
  const { activate } = useActivateItem();
  const [isActive, setIsActive] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme, shallowEqual);
  const data = useGetEffectData(item.fungibleType as EItemFungibleType);

  const { value, desc } = useMemo(() => {
    if (!data) {
      return {
        value: '',
        desc: '',
      };
    }
    const value = data.desc[0];
    const desc = data.getFullDesc();

    return { value, desc };
  }, [data]);

  const Component = useMemo(() => components[type], [type]);

  const gradeColor = useMemo(() => (item.grade === 'uncommon' ? '#B8DA76' : `var(--grade-${theme}-color-${item.grade})`), [item.grade, theme]);

  const useItem = async () => {
    if (isSuccess) {
      setIsSuccess(false);
    }
    setIsActive(true);
    await activate(item.id)
      .then((res) => {
        if (res?.result.id) {
          console.log(res);

          // setIsSuccess(true);
          toast(<Activated item={item} gradeColor={gradeColor} value={value} desc={desc} />, {
            autoClose: 10000,
            hideProgressBar: true,
            closeButton: false,
          });
        } else {
          handleClose();
        }
      })
      .catch(() => console.log('z'));
  };

  const handleEnd = () => {
    // setIsActive(false);
    setIsSuccess(true);
  };

  return (
    <Styled.Container>
      <BackArrow show={isActive ? isSuccess : true} handleClose={handleClose} />
      <CurrentItem item={item} isActive={isActive} isSuccess={isSuccess} gradeColor={gradeColor} showBubbles={type !== 'energyRecovery'} />
      {type === EActivateItemType.NOEFFECT ? (
        <Styled.Content className={clsx({ 'no-effect': !isMobile })}>
          <Styled.Desc>{item.description}</Styled.Desc>
          <Component type={item.fungibleType} />
        </Styled.Content>
      ) : (
        <>
          <AnimatePresence>
            {!isSuccess &&
              (!isActive ? (
                <Styled.Content as={motion.div} exit={{ opacity: 0 }}>
                  <Styled.Title>Activate Item?</Styled.Title>
                  <Styled.Desc>{item.description}</Styled.Desc>
                  <Component type={item.fungibleType} />
                  <BasicButton label={'Use Item'} variant={'cool'} className={'activate-item__button'} onClick={useItem} />
                  {/*{!isMobile && <Checkbox label={"Don't ask permission next time"} containerClassName={'activate-item__checkbox'} />}*/}
                </Styled.Content>
              ) : (
                <Styled.Content as={motion.div} animate={{ y: isMobile ? 70 : 50 }}>
                  <Component type={item.fungibleType} isActive={isActive} onEnd={handleEnd} />
                </Styled.Content>
              ))}
          </AnimatePresence>
          {isSuccess && (
            <Styled.Success as={motion.div} initial={{ opacity: 0, y: 50 }} animate={{ opacity: 1, y: isMobile ? 10 : 0 }}>
              <h3 style={{ color: gradeColor }}>Success!</h3>
              <p>Item is activated</p>
              <Styled.ButtonsWrap>
                <BasicButton label={'Continue'} variant={'cool'} onClick={handleClose} />
              </Styled.ButtonsWrap>
            </Styled.Success>
          )}
        </>
      )}
    </Styled.Container>
  );
};
