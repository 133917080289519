import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  gap: 8px;
  flex-grow: 1;
  width: 100%;
  min-width: 400px;
  position: relative;
`;

export const Menu = styled.div`
  margin-right: 15px;
  align-self: center;
`;
export const Rewards = styled.div`
  margin-left: 37px;
`;

export const RewardsLabel = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  &.rewardAbsolute {
    position: absolute;
    left: 100%;
    display: grid;
    align-items: center;
    gap: 10px;
    grid-template-columns: auto 36px;
  }
`;

export const MenuWrap = styled(motion.div)`
  position: absolute;
  left: calc(100% + 8px);
  top: 0;
`;
