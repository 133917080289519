import { IContent } from './Content';

const makeText = (text: string[]) => ({ type: 'text', content: text });
const makeList = (text: string[], mark: 'dot' | 'count' | 'chevron' | 'blank' = 'dot') => ({ type: 'list', content: text, mark });

const termsAndConditions: IContent[] = [
  {
    title: `GENERAL CONDITIONS OF USE OF SERVICES OF
      https://app.mighty.study/ portal, price and registration method
      
      Article 1.`,
  },
  {
    paragraph: [
      {
        header: '1.1. Introduction',
        text: [
          makeText([
            'This Agreement ("Agreement") is entered into by and between Artem Dubinin PR OSTALO OBRAZOVANJE MIGHTY STUDY NIS ("Mighty Study") and the individual ("User") who registers on the Mighty Study platform. By registering and using the platform, the User agrees to comply with and be bound by the following terms and conditions',
            'Users have the option to create a "Mighty Space" on the Mighty Study platform. By creating a Mighty Space, the User automatically becomes a Partner and agrees to additional terms specific to Space management and operation',
            'This Agreement applies to all Users of the platform, whether they are general Users accessing educational content or Partners who have created Spaces. The term "User" in this Agreement refers to both general Users and Partners, unless specifically stated otherwise',
          ]),
        ],
      },
    ],
  },
  {
    paragraph: [
      {
        header: '1.2. Definitions',
        text: [
          makeList([
            'Mighty Space: A business or community account created by the Partner on the Mighty Study platform, intended for use by groups of people for educational and community-building purposes.',
            'Platform: The online platform provided by Mighty Study for the creation and management of Mighty Spaces.',
            'Users: Individuals who access and use Mighty Study website (app.mighty.study), Mighty Spaces, as well as other sections of the website.',
            'Training: Any educational course, program, mini-game or other learning activity offered on the Mighty Study platform.',
            'Services: The services provided by Mighty Study, including but not limited to:',
          ]),
          makeList(
            [
              'Hosting and management of Mighty Spaces',
              'Providing and facilitating access to Training content and materials',
              'Facilitating the earning, management, and use of EXP points',
              'Offering and maintaining the Mighty Widget for integration with external platforms',
              'Providing analytics and reporting tools for Users and Partners',
              'Offering digital collectibles and other items in the Mighty Study store',
              'Offering content creation services for Partners (Enterprise plans)',
            ],
            'count'
          ),
        ],
      },
    ],
  },
  {
    paragraph: [
      {
        header: '1.2. Definitions',
        text: [
          makeList([
            'Mighty Space: A business or community account created by the Partner on the Mighty Study platform, intended for use by groups of people for educational and community-building purposes.',
            'Platform: The online platform provided by Mighty Study for the creation and management of Mighty Spaces.',
            'Users: Individuals who access and use Mighty Study website (app.mighty.study), Mighty Spaces, as well as other sections of the website.',
            'Training: Any educational course, program, mini-game or other learning activity offered on the Mighty Study platform.',
            'Services: The services provided by Mighty Study, including but not limited to:',
          ]),
          makeList(
            [
              'Hosting and management of Mighty Spaces',
              'Providing and facilitating access to Training content and materials',
              'Facilitating the earning, management, and use of EXP points',
              'Offering and maintaining the Mighty Widget for integration with external platforms',
              'Providing analytics and reporting tools for Users and Partners',
              'Offering digital collectibles and other items in the Mighty Study store',
              'Offering content creation services for Partners (Enterprise plans)',
            ],
            'count'
          ),
          makeList([
            'Platform: The online platform provided by Mighty Study, including the website at https://app.mighty.study and any associated applications or services, for the creation, management and use of Mighty Spaces and other Services.',
          ]),
          makeText([
            'The general conditions of use of the services of the https://app.mighty.study/ portal determine the conditions for the use of services provided by Artem Dubinin OSTALO OBRAZOVANJE MIGHTY STUDY NIS (hereinafter referred to as the MIGHTY STUDY) for natural, legal entities and entrepreneurs (hereinafter referred to as the: User). ',
            'The general terms of use of services are binding for both Contracting Parties and apply to all their mutual contractual relations, unless otherwise expressly agreed in a separate Agreement.',
            'MIGHTY STUDY is obliged to make the General Conditions, as well as any changes thereof, publicly available at https://app.mighty.study/ portal.',
          ]),
        ],
      },
    ],
  },
  {
    title: `CHANGES TO THE GENERAL CONDITIONS

    Article 2
    `,
    paragraph: [
      {
        text: [
          makeText([
            'MIGHTY STUDY reserves the right to amend the General Conditions in order to comply with changes in applicable laws, regulations, general acts, internal business policies, or to address the needs of the Users themselves. Any changes to the General Conditions will be communicated to the User in a clear and understandable manner, and such changes will not take effect until at least 15 days after the User has been notified.',
            'MIGHTY STUDY will notify Users of any changes to the General Conditions via email or through a prominent notice on the https://app.mighty.study/ portal.',
            'Users have the right to withdraw from the contract within 15 days of receiving the notice of the changes if they do not agree with the new terms.',
            'If the User continues to use the portal and the services of Artem Dubinin PR Mighty Study after the changes to the General Conditions have taken effect, this will be considered as acceptance of the new terms.',
            'However, if the User does not agree with the changes, they may terminate the contract without any penalty within the 15-day period from receiving the notice of changes.',
            'In the event that during the duration of the Training there is a change in the General Conditions, the General Conditions that were valid at the time of the conclusion of the Training-Registration Agreement are binding for the Users.',
          ]),
        ],
      },
    ],
  },
  {
    title: `TRAINING ATTENDANCE

    Article 3.
    `,
    paragraph: [
      {
        text: [
          makeText([
            'Training attendance in terms of these conditions is done by choosing a course or educational mini-games on the online learning platform, and after the User has registered on the platform as specified in the Articles below. When the User selects the desired Training in the manner from the above paragraph and after confirming his choice and registers himself, it will be considered that the training has started.',
            'Each User, through a username and password, gets access to the part of the platform that contains his basic data, data about the training he attends and other data important for the smooth and timely performance of the Training and use of the platform.',
            'By attending training, solving tests and other activities at the https://app.mighty.study/ portal, the User receives EXP points (hereinafter referred to as the EXP).',
            'EXP points can be exchanged for digital collectibles and other items placed by MIGHTY STUDY in its store.',
            'MIGHTY STUDY is not responsible for the further use and use of digital collectibles and all items on other portals and platforms, which are obtained in the MIGHTY STUDY store.',
            'The User has no time limit in which he can use EXP points on the platform.',
            'EXP points are personal in nature and are not transferable to other persons.',
            'Most of the items on the MIGHTY STUDY platform are presented as Digital Collectibles by using blockchain standards ERC-721 and ERC-1155, however some of the items may be of other standards such as ERC-20 and others, not limited only to the blockchain standard.',
            'Energy is a parameter that limits the amount of EXP points that can be earned per month. When the User performs any action, including but not limited to: answering tests, completing assignments, or taking part in mini games,  he spends his energy on it.',
            'MIGHTY STUDY reserves the right to change the amount of EXP points earned per completed activities in any potential situation.',
            "MIGHTY STUDY reserves the right to change the amount of energy a User gains and/or the rate at which any User's energy is regenerated.",
            'MIGHTY STUDY reserves the right to add and change the ways in which the User gains experience on the platform and consumes energy, as well as to add new games, tests or modify existing ones.',
            'MIGHTY STUDY does not guarantee the future value of EXP points and their future purchasing power.',
            'MIGHTY STUDY reserves the right to ban a particular User or limit him for a certain period of time if the company deems him to be malicious or a bot.',
          ]),
        ],
      },
    ],
  },
  {
    title: `Creation of Mighty Space and Use of the Platform

    Article 4.
    `,

    paragraph: [
      {
        header: '4.1 General',
        text: [
          makeText([
            'To create a Mighty Space, the Partner must complete the registration process, providing accurate and complete information as requested by Mighty Study.',
            'By creating a Mighty Space, the Partner acknowledges that they have read, understood, and agree to be bound by this Agreement. The Partner enters into a partnership with Mighty Study, which is intended to foster a collaborative environment for incentivized learning and community engagement.',
            'The Partner agrees to comply with all applicable laws and regulations in connection with their use of the Platform and management of their Mighty Space.',
            'The Partner is responsible for all content posted within their Mighty Space. This includes, but is not limited to, educational materials, quizzes, challenges, and any other interactive elements. Mighty Study reserves the right to remove any content that violates its policies or is deemed inappropriate.',
            'Creating a Mighty Space grants the Partner access to additional features and tools for community management and content creation. The Partner agrees to use these tools responsibly and in accordance with the guidelines provided by Mighty Study.',
            "The Partner agrees to handle all user data in accordance with Mighty Study's privacy policy and any applicable data protection laws. This includes data collected from users who engage with their Mighty Space.",
            'By creating a Mighty Space, the Partner gains the ability to customize their learning environment, set up reward systems, and engage with their community. The Partner agrees to use these features in a manner that aligns with the educational goals of the Mighty Study platform.',
            'The Partner understands that the success and growth of their Mighty Space depend on their active management and engagement. Mighty Study provides the tools and platform, but the Partner is responsible for creating valuable content and fostering community engagement, unless the Partner has purchased an Enterprise plan which might or might not include content creation or marketing.',
            'Mighty Study reserves the right to monitor Mighty Spaces for compliance with these terms and may take action, including but not limited to removing content or suspending a Mighty Space, if violations occur.',
            'The Partner agrees to cooperate with Mighty Study in addressing any issues or concerns related to their Mighty Space, including responding to user complaints or technical problems in a timely manner.',
            "By creating a Mighty Space, the Partner grants Mighty Study the right to run promotions related to the partnership and to publicly announce the existence of the partnership. This may include, but is not limited to, featuring the Partner's Mighty Space in marketing materials, case studies, or on Mighty Study's website.",
            "However, the Partner explicitly agrees not to make any public statements or claims about having entered into a partnership with Mighty Study without prior written consent from Mighty Study. This restriction is in place to protect Mighty Study's brand and to ensure accurate representation of the relationship between Mighty Study and its Partners.",
            "The Partner understands that while they are using Mighty Study's platform and services, this does not constitute a formal business partnership, joint venture, or any form of agency relationship beyond the scope defined in this Agreement.",
            "Any promotional materials or public statements about the use of Mighty Study's platform must be approved by Mighty Study in writing before publication or distribution. The Partner agrees to comply with any branding guidelines or requirements provided by Mighty Study when referencing the platform or their Mighty Space.",
            'Mighty Study reserves the right to revoke promotional rights or terminate the partnership if the Partner violates these terms or engages in any activity that Mighty Study determines, in its sole discretion, to be harmful to its brand or reputation.',
          ]),
        ],
      },
      {
        header: '4.2 Mighty Widget',
        text: [
          makeText([
            'The Partner has the option to utilize the Mighty Widget, a powerful tool that allows for seamless integration of their Mighty Space into various external platforms. Through the use of iFrame technology, the Partner can connect their Mighty Space to:',
          ]),
          makeList(
            [
              'Their own website',
              'Twitter groups (as a mini app)',
              'Discord channels',
              'Documentation platforms (e.g., Gitbook)',
              'Other compatible online platforms',
            ],
            'count'
          ),
          makeText([
            'This integration enables the Partner to extend the reach of their Mighty Space beyond the Mighty Study platform, providing their audience with direct access to educational content and engagement features within their preferred environments.',
            'The Partner is responsible for ensuring that the integration of the Mighty Widget complies with the terms of service and technical requirements of the third-party platforms they choose to use. MIGHTY STUDY provides the necessary tools and documentation for integration but is not responsible for any conflicts or issues arising from the use of the Mighty Widget on external platforms.',
            "The Partner agrees to maintain the integrity of the Mighty Widget and not to modify its core functionality or attempt to circumvent any security measures. MIGHTY STUDY reserves the right to suspend or revoke access to the Mighty Widget if it is used in a manner that violates these terms or poses a risk to the platform's security or reputation.",
            'MIGHTY STUDY may update the Mighty Widget from time to time to improve functionality, fix bugs, or address security concerns. The Partner agrees to promptly implement any such updates to ensure the continued smooth operation of their integrated Mighty Space.',
            'While the Mighty Widget offers flexible integration options, the Partner acknowledges that some features or functionalities may be limited or differ when accessed through external platforms compared to the native Mighty Study environment.',
            "Customization: Partners may customize certain aspects of the widget's appearance to align with their brand. However, core functionality cannot be altered.",
            'Performance: While Mighty Study strives to ensure optimal performance, we do not guarantee uninterrupted or error-free operation of the widget on external platforms.',
            'Updates: Mighty Study will provide updates to the widget as necessary. Partners are responsible for implementing these updates in a timely manner to ensure security and functionality.',
            'Usage Limitations: The widget is designed to handle standard traffic loads. For high-volume usage, partners should consult with Mighty Study for optimized solutions.',
            'Compatibility: The Mighty Widget is compatible with most modern web platforms and frameworks. A detailed compatibility list is available upon request.',
            'Support: Mighty Study provides basic troubleshooting support for widget integration. Extended support plans are available for Pro plans and beyond.',
          ]),
        ],
      },
    ],
  },
  {
    title: `METHOD OF REGISTRATION

    Article 5.
    `,
    paragraph: [
      {
        text: [
          makeText([
            `When the User is on the https://app.mighty.study/ portal, he needs to follow the following steps in order to complete the registration:`,
          ]),
          makeList(
            [
              'The User enters his e-mail.',
              'The User enters his password',
              'The User repeats his password',
              'The User agrees to all user conditions and privacy policy',
              'The User confirms his email by entering a 4 digit code sent to his email.',
              'The User registers at the https://app.mighty.study/ (platform)',
            ],
            'chevron'
          ),
          makeText(['Or']),
          makeList(
            [
              "1) User connects his Google Account  / Twitter Account  / Apple Account / Telegram Account crypto wallet to our website.(all these options already have a password inside, in this case we don't need a password.)",
              '2) The User agrees to all terms and privacy policy',
              '3) Register on the platform [https://app.mighty.study/].',
            ],
            'blank'
          ),
        ],
      },
    ],
  },
  {
    title: `PRICE AND METHODS OF PAYMENT

    Article 6.
    `,
    paragraph: [
      {
        header: '6.1 General',
        text: [
          makeText(['MIGHTY STUDY offers the following subscription plans for Partners:']),
          makeText(['Free Tier:']),
          makeList(['Basic Functionality', 'Limited design options', 'Mighty Ads & Branding - no whitelabel', 'Basic Analytics']),
          makeText(['Basic Plan Price: $99 / month']),
          makeText(['Features:']),
          makeList([
            'Up to 5,000 monthly active users',
            'Personalized widget design',
            'Integration with Telegram, Discord, and website',
            'Advanced data analytics',
          ]),
          makeText(['Pro Plan Price: $399 / month']),
          makeText(['Features:']),
          makeList([
            'Up to 15,000 monthly active users\nAll Basic Plan features, plus:',
            'Certificates & achievements for users',
            'On-chain tasks / credentials',
            'Branded treasure chests',
            'Priority support',
          ]),
          makeText(['Enterprise Plan Price - Contact us for custom pricing']),
          makeText(['Features:']),
          makeList([
            'Unlimited monthly active users\nAll Pro Plan features, plus:',
            'Content creation services',
            'Marketing campaigns management',
            'Enhanced platform visibility',
            'Custom API & Domain integration',
          ]),
          makeText([
            'MIGHTY STUDY reserves the right to modify its fee structure and the features included in each plan at any time. Partners will be notified of any changes to their current plan.',
          ]),
        ],
      },
      {
        header: '6.3 Conversion Statement',
        text: [
          makeText([
            'All payments will be made in the local currency of the Republic of Serbia. The official exchange rate list is used for informative display of prices in other currencies. The amount for which your payment card will be charged will be expressed in your local currency through conversion to the same at the exchange rate used by the card organizations, which cannot be known to us at the time of the transaction. As a result of this conversion, there is a possibility of a slight difference from the original price listed on our website, which the portal has no influence on.',
          ]),
        ],
      },
      {
        header: '6.4 Refunds',
        text: [
          makeText([
            'Subscription is a form of delivery of digital content that is not delivered on a permanent record carrier, by using which (watching video lessons in part or in full, solving quizzes/tests, reading scripts) the execution has started and the User agrees to thereby lose the right to withdraw from the Agreement.',
            'Before submitting any request for a refund, the User is obliged to first contact technical support with a precisely stated reason for requesting a refund.',
            'In the case of a refund to the User, regardless of the reason for the refund, MIGHTY STUDY will make the refund using the same payment method the User originally used unless the User has explicitly agreed in advance to receive the refund via cryptocurrency',
            'If there are difficulties in registering a payment on the portal or some other technical problems, the User is obliged to contact technical support first.',
            'In the case of a refund to the Partner, regardless of the reason for the refund, MIGHTY STUDY is obliged to make the refund exclusively via cryptocurrency.',
          ]),
        ],
      },
    ],
  },
  {
    title: `
    PROTECTION OF PARTICIPANT'S PERSONAL DATA

    Type of data

    Article 7.
    `,
    paragraph: [
      {
        text: [
          makeText(['MIGHTY STUDY collects, processes, and stores the following types of personal data:']),
          makeList(
            [
              `User account information:
    •   Email address
    •   Password (encrypted)
    •   Username or display name
              `,
              `Optional profile information:
    •   Profile picture
    •   Bio or personal description
              `,

              `Usage data:
    •   IP addresses
    •   Device information (operating system, browser type)
    •   Date and time of access
    •   Pages visited and features used within the platform
              `,

              `Learning-related data:
    •   Course enrollment and progress
    •   Quiz and assignment results
    •   EXP points earned
    •   Digital collectibles acquired
              `,
              `Communication data:
    •   Messages sent within the platform
    •   Comments or posts in community spaces
    •   Messages related to customer support
              `,
              `For Partners (Space creators) only:
    •   Name of organization or individual
    •   Contact information for account management
              `,
            ],
            'count'
          ),
          makeText([
            'MIGHTY STUDY does not collect or store physical addresses, phone numbers, or payment information directly. Any payment processing is handled by third-party payment processors, and MIGHTY STUDY does not have access to full payment details.',
          ]),
          makeText(['MIGHTY STUDY processes and stores this data for the purposes of:']),
          makeList([
            'Providing and improving our services',
            'Personalizing user experience',
            'Analyzing usage patterns to enhance the platform',
            'Communicating with users about their accounts and activities',
            'Ensuring the security and proper functioning of the platform',
          ]),
          makeText([
            "MIGHTY STUDY will retain personal data only for as long as necessary to fulfill the purposes for which it was collected or as required by applicable laws. Personal data related to user accounts will be retained for the duration of the user's account activity and up to two years after the account is closed or the services are terminated unless a longer retention period is required by law.",
          ]),
          makeText(['Rights of Data Subjects: "Users have the following rights regarding their personal data:"']),
          makeList([
            'The right to access their personal data and request a copy.',
            'The right to rectification of inaccurate or incomplete personal data.',
            "The right to erasure ('right to be forgotten') of personal data, subject to certain legal exceptions.",
            'The right to restrict or object to the processing of their personal data.',
            'The right to data portability, allowing them to obtain and reuse their personal data for their own purposes across different services.',
            'The right to lodge a complaint with a supervisory authority (The Commissioner for Information of Public Importance and Personal Data Protection of the Republic of Serbia)."',
          ]),
          makeText([
            'Process for Exercising Rights: "Users can exercise these rights at any time by contacting us at [insert contact details, e.g., a dedicated privacy email address]. We will respond to all requests in accordance with applicable legal requirements, typically within 30 days."',
            "Data Protection Compliance: \"MIGHTY STUDY is committed to protecting the personal data of Users in accordance with the Law on Personal Data Protection ('Zakon o zaštiti podataka o ličnosti') and the General Data Protection Regulation (GDPR). All data is processed lawfully, fairly, and transparently, ensuring that the user's privacy is respected and protected",
            'When entering payment card data, confidential information is transmitted via a public network in a protected (encrypted) form using the SSL protocol and the PKI system, as current state-of-the-art cryptographic technologies.',
            'Data on participants will be processed by authorized personnel who, for the needs of Mighty Study, perform tasks in the domain of administration, analytics, and customer support.',
            'Mighty Study stores and processes this data within the deadlines and in the manner established by applicable data protection laws, including but not limited to the General Data Protection Regulation (GDPR) where applicable.',
            'By accepting these conditions, the User consents to the collection, processing, and storage of the aforementioned data. Users have the right to access, correct, or request deletion of their personal data, subject to certain exceptions defined by law.',
            'All user data is strictly protected and is available only to employees who need the data to perform their work. All employees of Mighty Study are responsible for respecting the principles of privacy protection. We undertake not to use the collected data for any other purpose than those stated above, nor to forward them to third parties without explicit consent or legal obligation.',
            'Mighty Study implements appropriate technical and organizational measures to ensure a level of security appropriate to the risk, including encryption of sensitive data, regular security assessments, and employee training on data protection.',
            'Artem Dubinin PR MIGHTY STUDY undertakes to take all measures within the limits of technically available resources, and in accordance with internationally recognized standards, in order to protect the integrity of data on the https://app.mighty.study/ portal, on client user accounts, as well as to protect the integrity of data downloaded from clients. The security of the https://app.mighty.study/ portal is ensured by proven antivirus systems, advanced firewall solutions, as well as constant server monitoring and control.',
            'Users can find more detailed information about data processing practices, their rights, and how to exercise them in our Privacy Policy, which is an integral part of these terms and conditions.',
            'Links to other websites',
            "The https://app.mighty.study/ portal consists of author's content, partner content and links to other websites. You use all content on the https://app.mighty.study/ portal at your own risk.",
            "Artem Dubinin PR MIGHT STUDY does not bear responsibility for any material or non-material damage caused by visiting links and pages that are not on the https://app.mighty.study/ portal, as well as for events that are objectively beyond of Artem Dubinin PR MIGHT STUDY's control and the technical capabilities.",
          ]),
        ],
      },
    ],
  },
  {
    title: `
        INTELLECTUAL PROPERTY RIGHTS

    Article 8.
    `,
    paragraph: [
      {
        text: [
          makeText([
            'All learning materials in video and audio format, as well as documents, photographs, illustrations, and trademarks, etc., that are published on this platform are owned by Artem Dubinin PR OSTALO OBRAZOVANJE MIGHTY STUDY NIS or have been placed on the portal with the permission of the owner or authorized holder of rights.',
            'The content of the above paragraph may not be used or its integrity changed without the permission of Mighty Study. The use of any part of this website for commercial purposes or its incorporation into any other publication or website is strictly prohibited.',
            'If the Partner creates the course content independently, the Partner retains complete ownership over the materials. All intellectual property rights in such content belong solely to the Partner. By posting content, Partners grant Mighty Study a non-exclusive, worldwide, royalty-free license to use, reproduce, and display partner-created content for the purposes of operating, promoting, and improving the platform.',
            'If Mighty Study creates course content that is used in a Mighty Space without charge to the Partner, and the partner decides to display it on their page for more inclusive user experience, Mighty Study retains complete ownership of the content. The Partner is granted a limited, non-exclusive, non-transferable license to use this content to enhance the experience within their Mighty Space.',
            'User-Generated Content: Users retain ownership of any content they create and share on the platform. By posting content, users grant Mighty Study a worldwide, non-exclusive, royalty-free license to use, reproduce, modify, adapt, publish, translate, and distribute the content for the purpose of operating and promoting the platform.',
            'Feedback and Suggestions: Any feedback, suggestions, ideas, or other information provided by users to Mighty Study regarding the platform or its services becomes the property of Mighty Study, and users waive all rights to any claim of ownership or compensation related to such feedback.',
            "Trademark Usage: Partners and Users may not use Mighty Study's trademarks, logos, or brand elements without explicit written permission. Guidelines for authorized use will be provided separately upon request.",
            'Content Removal: Mighty Study reserves the right to remove any content that infringes upon the intellectual property rights of others. Users and Partners are responsible for ensuring they have the necessary rights to post any content on the platform.',
            'DMCA Compliance: Mighty Study respects the intellectual property rights of others and complies with the Digital Millennium Copyright Act (DMCA). If you believe that your copyrighted work has been used in a way that constitutes copyright infringement, please contact our designated copyright agent at info@mighty.study.',
            'Unauthorized use of any part of the https://app.mighty.study/ portal, without the written permission of the copyright owner, is considered a copyright violation and is subject to criminal prosecution.',
            'The Partner may choose to purchase course content or marketing services from Mighty Study. The quantity, scope, and price of these additional services will be reflected in the invoices and will depend on factors such as the type and difficulty of content, number of hours required for completion, and complexity of marketing services.',
            'Prior to commencement of any work, Mighty Study and the Partner will agree on a general scope of work. The final price will be determined based on the actual work performed and will be itemized in the invoice.',
            'Upon full payment of the invoice, the Partner will retain all intellectual property rights in the purchased content or marketing materials. Mighty Study agrees to transfer all such rights to the Partner upon receipt of full payment.',
            'Mighty Study reserves the right to use the created content or marketing materials as examples of its work, unless explicitly agreed otherwise in writing.',
            "The User/Partner agrees to indemnify, defend, and hold harmless Mighty Study, its officers, directors, employees, and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) the User/Partner's uploading, posting, or submission of content to the Mighty Study platform; (ii) the User/Partner's use of the Mighty Study platform; (iii) the User/Partner's violation of any term of this Agreement; or (iv) the User/Partner's violation of any third party right, including without limitation any copyright, property, or privacy right. This defense and indemnification obligation will survive the termination or expiration of this Agreement and the User/Partner's use of the Mighty Study platform.",
          ]),
        ],
      },
    ],
  },
  {
    title: `MUTUAL RIGHTS AND OBLIGATIONS

    Rights and obligations

    Article 9.
`,
    paragraph: [
      {
        text: [
          makeText([
            'By creating a Mighty Space, the Partner enters into a partnership with Mighty Study. This partnership is intended to foster a collaborative environment for incentivized learning and community engagement. ',
            'By accepting the General Terms and Conditions, the User acquires all rights and obligations Contained and agrees to use the Mighty Space in accordance with the guidelines and policies established by Mighty Study, which may be updated from time to time.',
          ]),
        ],
      },
      {
        header: "9.1 User's rights",
        text: [
          makeText([
            'The User has the right to enjoy uninterrupted Training in accordance with these General Conditions and other Agreements.',
            'The User can do the following on the platform:',
            `
  -   Perform registration and access the platform
  -   Learn and earn EXP points
  -   Receive items as a free gift
  -   Play educational mini-games
  -   Spend EXP points in the MIGHTY STUDY store (app.mighty.study/shop)
  -   Add other Users as friends on the platform
  -   Follow and unfollow Mighty Spaces
  -   Export their learning progress and achievements data
  -   Participate in special events or challenges hosted on the platform
  -   Provide feedback on courses and platform features
  -   Request deletion of their account and personal data, subject to applicable laws
            `,
          ]),
        ],
      },
      {
        header: "9.2 User's obligations",
        text: [
          makeText([
            'By accepting the General Conditions of Use, the User undertakes the following obligations:',
            `
  -   To make payments in a timely manner as specified herein
  -   To ensure they have the necessary technical capabilities (such as a compatible device and internet connection) to access and use the platform and its content
  -   To respect copyright and related rights, and not to download teaching and material from the MIGHTY STUDY portal, and especially not to transfer it to third parties or publish it publicly and make it accessible to a wider circle of people
  -   To keep relevant account information up to date through the platform's user interface, and to inform MIGHTY STUDY about significant changes that may affect the use of the platform or management of a Space, including but not limited to:
  -   For individual users who create Spaces: changes in details that may affect the operation of their Space
  -   For organizational users that create Spaces: changes in organization name, primary contact, legal status, or other details that may affect their use of the platform or management of their Space
  -   to respect copyright and related rights, and not to download teaching and material from the MIGHTY STUDY portal, and especially not to transfer it to third parties or publish it publicly and make it accessible to a wider circle of people
            `,
            'The User agrees not to violate or attempt to violate the security of the Platform, including by:',
            `
  -   Accessing data not intended for the User;
  -   Logging into a server or account the User is not authorized to access;
  -   Attempting to probe, scan or test the vulnerability of the Platform;
  -   Breaching or attempting to breach the Platform's security or authentication measures;
  -   Using the Platform to distribute malware or other malicious code.
  -   Using artificial intelligence, bots, scripts or other automated means to access or interact with the Platform.
  -   Creating any types of bots, which do anything on the portal and use any type of automation to complete tests and abuse the system in order to collect more points
  -   Hacking the system and try to increase EXP points / empty the store in a malicious way / get an advantage in answers / exploit any other ways to gain an advantage over other players.
  -   Purchasing the profile of another user
  -   Using the profile of another User
  -   Receiving access (both authorized and unauthorized) to the account of any User on the platform.

            `,
            'Failure to comply with the prohibitions and restrictions provided for in these conditions by the User, as well as their misuse, entails the criminal liability of the User.',
            'A User who has accepted the general conditions when signing up to the distance learning platform, creates a username and password for access to the distance learning system and access to the On-line service for Users.',
            'The participant is responsible for keeping his access credentials safe and MIGHTY STUDY bears no responsibility in case of loss of the login / password credentials and its use by unauthorized persons.',
            'The User is obliged to notify the technical support without delay in case of loss of the login or passport, so that new credentials are assigned to him, and the User account remains protected from misuse by third parties.',
          ]),
        ],
      },
      {
        header: '9.3 MIGHTY STUDY rights',
        text: [
          makeText(['Mighty Study has the following rights:']),
          makeList([
            'To change the General Terms and Conditions and update them from time to time.',
            'To charge the user for the services provided.',
            'To modify, suspend, or discontinue any aspect of the platform, including features, content, or services, at any time without prior notice.',
            "To refuse, limit, or cancel service to any user at Mighty Study's sole discretion.",
            'To remove or edit any content posted on the platform that violates these terms or is deemed inappropriate.',
            'To investigate and take appropriate legal action against anyone who violates these terms, including reporting such violations to law enforcement authorities.',
            "To transfer these rights or obligations under these terms to any successor in interest of the company's business.",
          ]),
        ],
      },
      {
        header: '9.4 Obligations of MIGHTY STUDY',
        text: [
          makeText([
            'Mighty Study agrees to provide the necessary tools and support to facilitate the creation and management of Mighty Spaces on its platform, subject to these terms and applicable laws.\nMIGHTY STUDY is obliged:',
            `
  -   To enable the User to carry out training without hindrance and access to all applications on the platform, as provided for in these terms.
  -   To allow the User unhindered access to EXP points and their use in the store.
  -   To use commercially reasonable efforts to maintain the security, operation and availability of the Platform. However, Mighty Study does not guarantee continuous or uninterrupted access.
  -   To provide a functional and secure widget that integrates with supported platforms as described in documentation.
            `,
            'Mighty Study is not responsible for any user-generated content on the platform. The obligation to maintain security is limited to implementing reasonable, industry-standard measures to protect user data and platform integrity.',
          ]),
        ],
      },
    ],
  },
  {
    title: `
    TERMINATION

Article 10.
`,
    paragraph: [
      {
        header: '10.1 User/Partner Termination:',
        text: [
          makeText([
            'The Partner may terminate this Agreement at any time by deleting their Mighty Space and ceasing all use of the Platform.',
            'The User or Partner who wishes to terminate this Agreement and delete their account or Mighty Space must send an email to info@mighty.study with the subject line "Request to Delete Account".',
            "The email should include the User's or Partner's registered email address and any other identifying information required to process the deletion request.",
            "Mighty Study will process the deletion request within a reasonable timeframe, subject to verification of the requestor's identity and authority to delete the account.",
            'The User or Partner understands that account deletion is permanent and cannot be undone.',
          ]),
        ],
      },
      {
        header: '10.2 Mighty Study Termination:',
        text: [
          makeText([
            "Mighty Study may terminate this Agreement and suspend or delete the User's account or Partner's Mighty Space at any time for any reason, including but not limited to:",
            `
  -   Violation of this Agreement or any applicable laws
  -   Extended inactivity of the account, as defined in our separate Inactive Account Policy
  -   Any other reason deemed appropriate by Mighty Study in its sole discretion
            `,
            'Mighty Study will make reasonable efforts to notify Users/Partners of account termination, but reserves the right to terminate accounts immediately and without notice in cases of severe violations.',
            'Upon termination of this Agreement, all licenses granted to the User/Partner herein will immediately cease, and the User/Partner must discontinue all use of the Platform.',
            'Unless otherwise required by law, Mighty Study is not obligated to provide refunds or credits for any prepaid fees in the event of account termination, regardless of whether the termination was initiated by the User/Partner or by Mighty Study.',
          ]),
        ],
      },
      {
        header: '10.3 Consequences of Termination:',
        text: [
          makeText([
            'Upon confirmation of account deletion by Mighty Study, all licenses granted herein will immediately cease, and the User/Partner must discontinue all use of the Platform.',
            'Any outstanding fees owed to Mighty Study will become immediately due and payable.',
            'Following account deletion, Mighty Study will handle User/Partner data in accordance with applicable laws and our privacy practices. This may include retaining certain data for legal, financial, or technical reasons for a period of time before full deletion.',
          ]),
        ],
      },
    ],
  },
  {
    title: `
      LIMITATION OF LIABILITY

    Article 11.
    `,
    paragraph: [
      {
        text: [
          makeText([
            'Any information provided on this website, including featured projects and educational content, should not be considered as financial or investment advice. The information is provided as-is with no guarantees. We cannot validate the claims or the trustworthiness of the projects listed. By using this information, you agree not to hold MIGHTY STUDY responsible for the results of your investment decisions or other actions resulting from it. Users are strongly advised to conduct their own research before making any investment decisions.',
            'The educational content provided on the platform is for informational purposes only and should not be considered a substitute for professional advice in any field, including but not limited to legal, financial, or medical advice. Users are encouraged to consult with qualified professionals before making any decisions based on the information provided.',
            'MIGHTY STUDY does not endorse or guarantee the success of any projects, courses, or content featured on the platform. Users participate in activities and make decisions at their own risk.',
            "MIGHTY STUDY makes no warranties about the accuracy, reliability, completeness, or timeliness of the platform's content, services, software, text, graphics, and links. The platform and all its contents are provided 'as is' without any warranties, except as required by law.",
            'Mighty Study shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or related to the use of our platform or services, except in cases of gross negligence or intentional harm, to the extent that such limitation of liability is prohibited by applicable law.',
            'This includes, but is not limited to, damages for loss of profits, data, use of products or services, or any associated equipment, cost of substitute services, or any other losses.',
            "Mighty Study is not responsible for the correct functioning, quality, or any other aspects of items placed by partners in the Mighty shop. Users acknowledge that these items are provided by third-party partners and Mighty Study makes no warranties or representations regarding these items. The use of any items obtained from the Mighty shop is at the user's own risk, and Mighty Study shall not be liable for any damages or losses resulting from the use of these items.",
            'Mighty Study is not responsible for content that partners upload to the platform. Users acknowledge that such content is provided by third-party partners and Mighty Study makes no warranties or representations regarding this content.',
            "Furthermore, Mighty Study is not responsible for the distribution of rewards provided by any Partner. The use of any partner-provided content or rewards is at the user's own risk, and Mighty Study shall not be liable for any damages or losses resulting from such use.",
            "In no event shall MIGHTY STUDY's total liability to the User or Partner for all damages, losses, and causes of action exceed the amount paid by the User or Partner to MIGHTY STUDY in the twelve (12) months preceding the event giving rise to the claim.",
            'Some jurisdictions do not allow the exclusion of certain warranties or the limitation or exclusion of liability for certain types of damages. Therefore, some of the above limitations in this section may not apply to you. If you are dissatisfied with any portion of the service or with these terms of service, your sole and exclusive remedy is to discontinue use of the service.',
          ]),
        ],
      },
    ],
  },
  {
    title: `
      DISPUTE RESOLUTION AND COURT JURISDICTION

      Article 12.
    `,
    paragraph: [
      {
        text: [
          makeText([
            'MIGHTY STUDY and the User agree that they will settle all possible disputes regarding the interpretation and application of these General Conditions amicably.',
            'This Agreement shall be governed by and construed in accordance with the laws of the State of Serbia, without regard to its conflict of laws principles.',
            'In the event of a dispute arising out of or relating to this Agreement, both parties agree to first attempt to resolve the dispute informally by negotiating in good faith. If the dispute cannot be resolved through negotiation within 30 days, the parties agree to submit to mediation by a neutral mediator. Only if mediation fails to resolve the dispute may the User proceed to file a claim in an ordinary court.',
            'While the User retains the right to pursue any consumer disputes in the ordinary courts of Serbia, both parties are encouraged to resolve disputes through arbitration in accordance with the rules of the Serbia Arbitration Association. In the event of any legal proceedings arising out of or relating to this Agreement, the prevailing party shall be entitled to recover its reasonable attorney fees and legal costs from the losing party.',
            'This Agreement constitutes the entire agreement between the parties regarding the subject matter hereof and supersedes all prior and contemporaneous agreements, understandings, and representations, whether oral or written.',
            'This Agreement shall be governed by and construed in accordance with the laws of the Republic of Serbia. Any disputes arising out of or in connection with this Agreement shall be subject to the exclusive jurisdiction of the courts in Nis, Serbia.',
          ]),
        ],
      },
    ],
  },
  {
    title: `
      MISCELLANEOUS

      Article 13.
    `,
    paragraph: [
      {
        text: [
          makeText([
            'Mighty Study reserves the right to modify this Agreement at any time. Any changes will be effective immediately upon posting on the Mighty Study website. The Partner’s continued use of the Platform after any such modifications shall constitute acceptance of the revised Agreement.',
            'If any provision of this Agreement is held to be invalid or unenforceable, such provision shall be struck and the remaining provisions shall be enforced to the fullest extent under law.',
            'The failure of either party to enforce any right or provision of this Agreement shall not constitute a waiver of such right or provision',
          ]),
        ],
      },
    ],
  },
];

const privacyPolicy = [
  {
    title: 'Privacy protection:',
    paragraph: [
      {
        text: [
          makeText([
            'On behalf of Artem Dubinin PR MIGHTY STUDY, we undertake to protect the privacy of all our users. We collect only necessary, basic data about customers/users and data necessary for business operations and user information in accordance with good business practices and with the objective of providing quality service. We give users the possibility to make choices, including the possibility to decide whether or not they want to be removed from mailing lists used for marketing campaigns. All user/customer data is strictly stored',
            'Bearing in mind the legal regulation in the field of personal data privacy of natural persons, primarily the new Personal Data Protection Act, it is very important for us that you read the Privacy Protection, in order to be familiar with the types of personal data that we most often process, for which purposes we process your personal data, who has access to your data, and in particular to make you aware of your rights regarding the processing of personal data. We take care of the protection of your personal data and fully support your rights as a user provided by this law. Questions, complaints, requests and additional information important for the protection of your privacy can be sent to the e-mail: artemdubinin@protonmail.com',
          ]),
        ],
      },
    ],
  },
  {
    title: 'Operator:',
    paragraph: [
      {
        text: [
          makeText([
            'The operator is the main person responsible for the processing of your personal data who decides on their purpose and intent',
            'Artem Dubinin PR MIGHTY STUDY ',
            'phone: +381 62 1249131',
            'artemdubinin@protonmail.com  ',
            'Jablanicka Street, No. 10, Nis-Medijana',
          ]),
        ],
      },
    ],
  },
  {
    title: 'Type of data we collect:',
    paragraph: [
      {
        text: [
          makeText([
            'When collecting your personal data, we collect only the most necessary data with a strictly and clearly defined purpose in order to provide you with the best possible service',
            'We collect data during the process of visiting the https://app.mighty.study/ portal and after creating an order, namely: time of website visit, date, time spent on the portal, source, IP address, username, user ID, password, first name, last name, optionally company name, address, contact phone, e-mail, date of birth, user membership status, order history, device ID, operating system and corresponding version or other device identifiers, geolocation data, comments, ratings, responses to comments, data that you voluntarily share with us while using our portal, all for the purpose of improving the range of services and products we offer you',
            'In case you share personal data of another person with us, you must have their prior consent. You are required to inform the persons whose data you share, about the processing of personal data in accordance with our Privacy Policy',
          ]),
        ],
      },
    ],
  },
  {
    title: 'For what purpose and on what legal basis do we collect your data?',
    paragraph: [
      {
        text: [
          makeText([
            'For some processing purposes, we process your personal data based on your consent to the processing of personal data with prior notification on all relevant aspects of the processing. It is important that you know that you can withdraw your consent at any time and without explanation, which results in the termination of further processing',
            'Also, in some situations we have a legitimate interest in processing your data, such as for example, when processing is necessary for the purpose of preventing fraud or potential abuse',
            'We may process your personal data if the law requires us to do so. Also, we can process your personal data if the processing is necessary in order to protect your vital interests. If we process your personal data when the law requires us to do so or in order to protect your vital interests, we will inform you about it',
            'The most common purpose of personal data processing occurs when you register as a user, when purchasing and subscribing to services on our portal. The data we most often process are name and surname, date of birth, e-mail, phone number, address, and history of your orders',
            'In order to achieve our business purpose, we process your personal data based on legitimate interest. Of course, we do this only if your interest or your fundamental rights and freedoms do not outweigh our legitimate interest',
          ]),
        ],
      },
    ],
  },
  {
    title: 'Who are the data users?',
    paragraph: [
      {
        text: [
          makeText([
            'We will never forward your data to unauthorized third parties. Considering that as part of our business operations we receive the services of certain providers, we give them limited and strictly monitored access to your personal data. All recipients of data must meet the requirements of legal data protection and must, with valid evidence, prove the required level of personal data protection',
            'Depending on the specific purpose, the following may have access to certain user personal data:',
          ]),
          makeList([
            'Employees of Artem Dubinin PR MIGHTY STUDY as well as part-time and similar collaborators (who undertake to keep data confidential), only to the extent necessary for processing purposes',
            'Artem Dubinin PR MIGHTY STUDY may forward data to its related parties, when necessary and always within our legitimate interest and defined purposes',
            'Our partners, who perform certain processing tasks for Artem Dubinin PR MIGHTY STUDY as processors, such as external collaborators-Partners who provide us with certain services, software companies that create and maintain softwares, as well as improving our marketing campaigns by enabling personalized advertising',
            'Newly established entities or the entity acquiring ownership of the Company, if the Company is involved in a merger, acquisition, purchase, sale of shares or other status change\n',
          ]),
        ],
      },
    ],
  },
  {
    title: 'Data protection:',
    paragraph: [
      {
        text: [
          makeText([
            'In order to secure the data we process, we always strive to apply the necessary standards in the protection of personal data, and to apply all necessary technical, organizational and personnel protection measures in accordance with the requirements of the current law on the protection of personal data, including but not limited to: technical protection measures, protection measures related to physical access to the location where your data is stored, information security protection measures for your data in accordance with current regulations, and other measures that are suitable for processing and are necessary to ensure the protection of specific personal data.',
            'Third parties that process data are also obliged to implement all necessary technical, organizational and personnel measures.',
            'Depending on the purpose of data processing, different data deletion rules apply. Collected data is also marked with a deletion rule. When the data retention period expires, the stored data will be deleted.',
            'We can delete your personal data upon request via e-mail artemdubinin@protonmail.com. You will be informed about the deletion of your data by e-mail sent to the e-mail address from which you sent the request for deletion of personal data.',
          ]),
        ],
      },
    ],
  },
  {
    title: 'Your rights:',
    paragraph: [
      {
        text: [
          makeList([
            'The right to receive confirmation as to whether we are processing your personal data',
            'The right to access your personal data',
            'The right to correct your personal data in the event of an error',
            'The right to request the termination of the processing of your personal data',
            'The right to delete your personal data',
            'The right to request the transfer of personal data to another service provider',
          ]),
          makeText(['You can exercise all your rights by contacting us at artemdubinin@protonmail.com']),
        ],
      },
    ],
  },
  {
    title: 'Responsible authority in the Republic of Serbia:',
    paragraph: [
      {
        text: [
          makeText([
            'The competent authority for the protection of personal data is the Commissioner for Information of Public Importance and Personal Data Protection of the Republic of Serbia. You can contact the authority at Kralja Aleksandra Boulevard, No. 15, 11000 Belgrade, Republic of Serbia, by e-mail at office@poverenik.rs or by phone at +381 11 3408 900.',
          ]),
        ],
      },
    ],
  },
  {
    title: 'Cookie Policy:',
    paragraph: [
      {
        text: [
          makeText([
            'In order to make visits to our website as attractive as possible and to optimize the use of some functions, we use the so-called cookies. Cookies are text files stored on your devices. Some cookies are deleted after the browser session expires, that is, after closing your browser (so-called session cookies). Other cookies remain on your devices and can be used to recognize your browser during your next visit to our website (permanent cookies).',
          ]),
        ],
      },
    ],
  },
  {
    title: 'Protection of confidential data on transaction during online shopping',
    paragraph: [
      {
        text: [
          makeText([
            'When shopping online on the https://app.mighty.study/ portal, confidential information is transmitted via a public network in a protected (encrypted) form.',
            "Data security during purchases is guaranteed by the payment card processor, OTP banka a.d. Belgrade, so the entire payment process is carried out on the bank's website. The payment card data is not available to our system at any moment.",
          ]),
        ],
      },
    ],
  },
  {
    title: 'Privacy policy and protection of confidential data on transaction',
    paragraph: [
      {
        text: [
          makeText([
            'On behalf of Artem Dubinin PR MIGHTY STUDY, we undertake to protect the privacy of all our users. We collect only necessary, basic data about customers/users and data necessary for business operations and user information in accordance with good business practices and with the objective of providing quality service. We give users possibility to make choices, including the possibility to decide whether or not they want to be removed from mailing lists used for marketing campaigns. All user/customer data is strictly stored and is available only to employees who need the data to perform their work. All employees of Artem Dubinin PR MIGHTY TUDY are responsible for respecting the principles of privacy protection.',
            'Payment can be made by payment card and cryptocurrency (ETH BTC USDC). The payment is converted to crypto dollars (USDC) when we receive the payment. Any possible refunds can also be made using cryptocurrencies.',
            "When entering payment card data, confidential information is transmitted via a public network in a protected (encrypted) form using the SSL protocol and the PKI system, as current state-of-the-art cryptographic technologies. Data security during purchases is guaranteed by the payment card processor. The security of data during purchases is guaranteed by the payment card process of OTP banka a.d. Belgrade, so the entire payment process is carried out on the bank's website. The payment card data is not available to our system at any moment.",
          ]),
        ],
      },
    ],
  },
];

export const data = {
  terms: {
    title: 'Terms and Conditions',
    intro:
      'Artem Dubinin PR OSTALO OBRAZOVANJE MIGHTY STUDY NIS is a legal entity registered with the Agency for Business Registers with Company Registration Number 66829090, Taxpayer’s Identification Number: 113442582 and Activity Code Number 8559. It is located at Jablanicka Street, No. 10 in Nis, municipality of Medijana, Republic of Serbia.',
    content: termsAndConditions,
  },
  policy: {
    title: 'Privacy Policy',
    intro: '',
    content: privacyPolicy,
  },
};
