import styled, { css } from 'styled-components';

export const Container = styled.h2(
  () => css`
    font-weight: 500;
    font-size: 2.7rem;
    line-height: 1;
    margin-bottom: 20px;
    color: var(--font-color-strong);

    @media screen and (max-width: 992px) and (min-width: 626px) {
      margin-bottom: 26px;
    }
  `
);
