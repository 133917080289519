import * as S from './styles';
import certImg from '../../assets/certificate.png';
import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';

const CertificatesTab = () => {
  const handleLink = () => {
    window.open('https://discord.gg/7cdjn7QZ6M', '_blank');
  };

  return (
    <S.Tab initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}>
      <S.Image src={certImg} />
      <S.Title>Your space does not have any Certificates</S.Title>
      <S.Subtitle>Please contact us on discord to discuss the terms and conditions for creating your own certificates</S.Subtitle>
      <S.DiscordButton onClick={handleLink} icon={DiscordIcon}>
        Contact
      </S.DiscordButton>
    </S.Tab>
  );
};

export default CertificatesTab;
