import ChestImageAnimated from '../screen/ChestAnimated';
import ReferralsProgress from '../screen/progress';
import BasicButton from 'shared/ui/basic-button/button';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FeatureCard } from 'shared/ui';
import { Logo } from 'shared/utils';
import { ROUTES, THEMES } from 'app/constants';

import * as S from './styles';

type Props = {
  isForcingHorizontal?: boolean;
};

const ReferralsBanner = ({ isForcingHorizontal }: Props) => {
  const theme = useGetCurrentTheme();
  const { isMobile, viewportWidth } = useDisplay();
  const navigate = useNavigate();

  const isHorizontal = useMemo(() => isForcingHorizontal || viewportWidth <= 1539, [viewportWidth, isForcingHorizontal]);

  const ContainerComponent = isHorizontal ? S.ContainerHorizontal : S.ContainerVertical;

  if (isMobile) {
    const bgDark = '#FFFFFF0F';
    const bgLight = '#d8e0f9';

    return (
      <FeatureCard
        background={theme === THEMES.LIGHT ? bgLight : bgDark}
        padding="21px 23px"
        headSlot={
          <S.MobileTitle $theme={theme}>
            <Logo containerClassName="logo" /> Referral program
          </S.MobileTitle>
        }
      >
        <S.MobileContainer $theme={theme}>
          <ChestImageAnimated size={150} smallerGlow containerClassName="chest" />
          <S.Col>
            <S.MobileSub>
              Invite your friends <br /> and get rewards
            </S.MobileSub>
            <BasicButton
              onClick={() => navigate(ROUTES.REFERRALS)}
              label="Learn more"
              variant="cool"
              style={{ maxWidth: 161, marginRight: 'auto', height: 54 }}
            />
          </S.Col>
        </S.MobileContainer>
      </FeatureCard>
    );
  }

  return (
    <ContainerComponent $theme={theme}>
      <ChestImageAnimated size={isHorizontal ? 210 : 200} containerClassName="chest" smallerGlow />
      <S.Info>
        <S.Title $theme={theme}>
          <S.Blue>Learn</S.Blue> Together, <br />
          <S.Blue>Earn</S.Blue> Together!
        </S.Title>
        <S.Sub $isHorizontal={isHorizontal} $theme={theme}>
          Invite friends to join the learning adventure and earn exclusive rewards for each referral.
        </S.Sub>
      </S.Info>
      {!isHorizontal && <ReferralsProgress containerClassName="refs" hideArrows hideUser />}
      <BasicButton
        label="Learn More"
        variant="cool"
        size="lg"
        fullWidth={false}
        onClick={() => navigate(ROUTES.REFERRALS)}
        className="refs-banner-button"
        style={{ height: isHorizontal ? 38 : 49, fontSize: isHorizontal ? 14 : 18 }}
      />
    </ContainerComponent>
  );
};

export default ReferralsBanner;
