import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { Link } from 'react-router-dom';
import { ELinks } from 'app/constants';

import discordGovernance from 'assets/images/discord-governance.png';

import { LazyImage } from 'shared/ui/lazyImage';
import { InView } from 'shared/ui/motion';

import styles from './styles.module.css';

export default function DiscordInvite() {
  const theme = useSelector(getCurrentTheme);
  return (
    <InView forcedOnMobile className={clsx(styles.container, `glass-div basic ${theme}`)}>
      <LazyImage src={discordGovernance} alt="discord" className={styles.image} />
      <h4 className={styles.header}>Become a part of our governance. Join our discord to know more!</h4>
      <div className={styles.footer}>
        <a className={styles.discord} target="_blank" rel="noreferrer" href={ELinks.DISCORD}>
          Join
        </a>
        <Link className={styles.link} to={'/'}>
          Learn How
        </Link>
      </div>
    </InView>
  );
}
