import styled from 'styled-components';

export const Input = styled.input`
    overflow: hidden;
    background: none;

    width: 100%;
    height: 43px;

    padding: 0 10px;

    outline: none;
    border: none;

    display: flex;
    flex-grow: 1;

    &::placeholder {
      color: rgba(170, 170, 170, 0.65);
      font-weight: normal;
    }

    color: ${({ theme }) => theme.theme.fontColor};
    font-size: 17px;
    font-weight: 600;

    @media (max-width: 992px) {
      min-width: 60px;
      width: 100%;

      padding-left: 5px;
    }
  `,
  SearchContainer = styled.div`
    display: flex;
    align-items: center;

    width: 100%;
    flex-grow: 1;

    &:focus {
      border: ${({ theme }) => 'solid 1px ' + theme.theme.fontColor};
    }

    position: relative;

    & > div {
      width: 100%;
    }
  `;
