import { motion } from 'framer-motion';
import styled from 'styled-components';

export const MobileSlideContainer = styled.div`
  width: 335px;
  min-width: 335px;
  max-width: 335px;
  height: 175px;
  position: relative;
`;

export const MobileSlideBox = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  padding: 21px 25px;
  border-radius: 5px;
  background: ${(p) => p.color || '#FFFFFF0a'};
  border: 1px solid #ffffff0a;
  height: 100%;
  margin-left: auto;
  width: 196px;
  backdrop-filter: blur(40px);

  @media screen and (max-width: 992px) {
    padding: 21px 10px;
  }
`;

export const MobileSlideName = styled.span`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 13px;
  color: white;
  font-size: 22px;
  line-height: 100%;
  & > span {
    color: inherit;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
  }
`;

export const MobileSlideImage = styled.img`
  width: 116px;
  height: 116px;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 39px;
  transform: translateY(-50%);
  z-index: -1;
  border-radius: 50%;
`;

export const MobileSlidetags = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-bottom: auto;
`;

export const Tag = styled.div`
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  padding: 0px 8px;
  overflow: hidden;
  border-radius: 4px;

  & > span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 500;
  }
`;

const imagesPositions = [
  //1
  `left: 95px; top: -10px;`,
  `left: 37px; top: -5px;`,
  `left: -5px; top: 38px;`,
  `left: -5px; bottom: 38px;`,
  `left: 37px; bottom: -5px;`,
  `left: 95px; bottom: -10px;`,
];

export const RewardImage = styled(motion.img)<{ index: number }>`
  width: 40px;
  height: 40px;
  position: absolute;
  object-fit: contain;
  pointer-events: none;
  ${(p) => imagesPositions[p.index]}
`;
