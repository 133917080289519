import { FC } from 'react';
import CoursesEditLesson from './parts/editLesson';
import { useGlobalLoader } from '../../shared/utils/GlobalLoader/useGlobalLoader';

const CoursesConstructorSlidesPage: FC = () => {
  useGlobalLoader({ dependency: [false] });
  return <CoursesEditLesson />;
};

export default CoursesConstructorSlidesPage;
