export const getShortStr = (
  str?: string,
  settings?: {
    fStart?: number;
    lStart?: number;
    symbol?: string;
    symbolRepeatCount?: number;
  }
) => {
  if (!str) {
    return '';
  }

  const { fStart = 0, lStart = str.length, symbol = '.', symbolRepeatCount = 3 } = settings ?? {};

  const fPart = str.slice(0, fStart);
  const lPart = str.slice(lStart, str.length);

  return `${fPart}${symbol.repeat(symbolRepeatCount)}${lPart}`;
};

export const emailTruncate = (email?: string) => {
  if (!email) return '';

  const [start, end] = email.split('@');
  const shortStart = getShortStr(start, { fStart: 2, lStart: start.length - 1, symbol: '*' });

  return `${shortStart}@${end}`;
};
