import styled, { css } from 'styled-components';
import bg from 'assets/images/contribution-bg.svg';

export const Container = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      h1,
      span,
      p {
        color: var(--font-color-strong);
      }

      button {
        border-radius: 54px;
        height: 54px;
        min-height: 54px;
        padding-top: 0;
        padding-bottom: 0;
        width: fit-content;
      }
    `
  ),
  Header = styled.h1(
    () => css`
      margin-bottom: 16px;
      font-size: 28px;
      font-weight: 500;
      line-height: 115%;
      letter-spacing: 0.5px;
    `
  ),
  Title = styled.p(
    () => css`
      margin-bottom: 26px;
      font-size: 16px;
      font-weight: 400;
      line-height: 115%;
      letter-spacing: 0.5px;
    `
  ),
  Span = styled.span(
    () => css`
      margin-bottom: 26px;
      line-height: 134%;
      font-size: 16px;
      padding: 10px 20px;
      border-radius: 4px;
      background: linear-gradient(282deg, rgb(212 212 255 / 29%) -0.2%, rgb(211 210 255 / 11%) 98.72%);
      background-blend-mode: overlay;
    `
  ),
  ImageContainer = styled.div(
    () => css`
      position: relative;
      margin-bottom: 32px;
      margin-top: -15px;
      height: 218px;
      width: 218px;
      background-image: url(${bg});
      background-repeat: no-repeat;
      background-position: center;

      img {
        width: 100%;
        height: auto;
        object-fit: contain;
        scale: 0.9;
      }
    `
  );
