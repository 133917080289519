import * as S from '../styles';
import { dispatch, useGetCurrentTheme, useGetIsAuth, useGetUserData, useLogout } from 'hooks';
import { setCurrentTheme } from 'store';
import { ROUTES, THEMES } from 'app/constants';
import { useLocation, useNavigate } from 'react-router-dom';
import expImg from '../assets/exp.png';
import { ReactComponent as MoonIcon } from '../assets/moon.svg';
import { ReactComponent as SunIcon } from '../assets/sun.svg';
import { ReactComponent as SettingsIcon } from '../assets/settings.svg';
import { ReactComponent as StudyIcon } from 'assets/icons/learn.svg';
import { ReactComponent as SpacesIcon } from 'assets/icons/planet.svg';
import { ReactComponent as ShopIcon } from 'assets/icons/shopping-bag.svg';
import { ReactComponent as PlayIcon } from 'assets/icons/play.svg';
import { ReactComponent as NotifsIcon } from 'assets/icons/bell.svg';
import { ReactComponent as AuthIcon } from '../assets/auth.svg';
import { ReactComponent as LogoutIcon } from 'assets/icons/logout-small.svg';
import { Avatar } from 'components/library/Avatar';
import { useEffect, useMemo } from 'react';
import { useGetEffectsColorData } from 'shared/ui/effect-indicator/data';
import { Effects } from 'components/pages/study/courses/material-pages/effects';
import LeftOffLessonCompact from './components/left-off-compact';

type Props = {
  isHidden?: boolean;
  handleClose: () => void;
  updateModal: (arg: 'user' | 'notifs' | 'effects' | null) => void;
};

const HorizontalMobileMenuMain = ({ handleClose, updateModal }: Props) => {
  const userData = useGetUserData();
  const isAuth = useGetIsAuth();
  const { pathname } = useLocation();
  const logout = useLogout();

  const { nickname, avatar, amount, energy, maxEnergy } = useMemo(() => {
    if (!userData._id)
      return {
        nickname: '',
        avatar: '',
        amount: 0,
        energy: 1,
        maxEnergy: 1,
      };
    return {
      nickname: userData?.nickname,
      avatar: userData?.avatar,
      amount: userData?.exp?.balance?.amount,
      energy: userData?.exp?.energy,
      maxEnergy: userData?.exp?.limit?.maxEnergy,
    };
  }, [userData]);

  const currentEnergy = useMemo(() => Math.floor((energy / maxEnergy) * 100), [maxEnergy, energy]);

  const theme = useGetCurrentTheme();
  const toggleTheme = () => dispatch(setCurrentTheme(theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK));
  const { color } = useGetEffectsColorData('energy');

  const navigate = useNavigate();

  const navToSetting = () => navigate('/profile?screen=Settings');
  const navToPlay = () => navigate(ROUTES.PLAY);
  const navToProfile = () => navigate(ROUTES.PROFILE);
  const navToSpaces = () => navigate(ROUTES.SPACES);
  const navToLearn = () => navigate(ROUTES.MAIN);
  const navtoShop = () => navigate(ROUTES.SHOP);
  const navToAuth = () => navigate(ROUTES.AUTH);
  const logOutHandler = () => {
    logout()
      .then(() => {
        navigate(ROUTES.AUTH, { replace: true });
      })
      .catch(console.error);
  };
  const handleOpenNotifs = () => {
    updateModal('notifs');
    handleClose();
  };
  const handleOpenEffects = () => {
    updateModal('effects');
    handleClose();
  };

  useEffect(() => {
    handleClose();
  }, [pathname]);

  return (
    <S.Menu>
      {!!isAuth && <LeftOffLessonCompact />}
      <S.ButtonWrapper onClick={navToLearn}>
        <S.CircleButton $theme={theme}>
          <StudyIcon />
        </S.CircleButton>
        <S.Sub>Learn</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper onClick={navToSpaces}>
        <S.CircleButton $theme={theme}>
          <SpacesIcon />
        </S.CircleButton>
        <S.Sub>Spaces</S.Sub>
      </S.ButtonWrapper>
      <S.ButtonWrapper onClick={navtoShop}>
        <S.CircleButton $theme={theme}>
          <ShopIcon />
        </S.CircleButton>
        <S.Sub>Shop</S.Sub>
      </S.ButtonWrapper>
      {!isAuth && (
        <S.ButtonWrapper onClick={navToAuth}>
          <S.CircleButton $theme={theme}>
            <AuthIcon />
          </S.CircleButton>
          <S.Sub>Login</S.Sub>
        </S.ButtonWrapper>
      )}
      {!!isAuth && (
        <>
          <S.ButtonWrapper onClick={navToPlay}>
            <S.CircleButton $theme={theme}>
              <PlayIcon />
            </S.CircleButton>
            <S.Sub>Play</S.Sub>
          </S.ButtonWrapper>
          <S.ButtonWrapper onClick={handleOpenNotifs}>
            <S.CircleButton $theme={theme}>
              <NotifsIcon />
            </S.CircleButton>
            <S.Sub>Notifications</S.Sub>
          </S.ButtonWrapper>

          <S.ButtonWrapper>
            <S.CircleButton $theme={theme} onClick={toggleTheme}>
              {theme === THEMES.DARK ? <SunIcon /> : <MoonIcon />}
            </S.CircleButton>
            <S.Sub>{theme === THEMES.DARK ? 'Light' : 'Dark'}</S.Sub>
          </S.ButtonWrapper>
          <S.ButtonWrapper onClick={navToSetting}>
            <S.CircleButton $theme={theme}>
              <SettingsIcon />
            </S.CircleButton>
            <S.Sub>Settings</S.Sub>
          </S.ButtonWrapper>
          <S.ButtonWrapper onClick={navToProfile}>
            <Avatar userName={nickname} src={avatar} size={48} />
            <S.Sub>Profile</S.Sub>
          </S.ButtonWrapper>
          <S.ButtonWrapper onClick={handleOpenEffects}>
            <div style={{ pointerEvents: 'none' }}>
              <Effects $larger />
            </div>
            <S.Sub $color={color}>{currentEnergy}%</S.Sub>
          </S.ButtonWrapper>
          <S.ButtonWrapper $cursor={'default'}>
            <S.CircleButton $theme={theme}>
              <img src={expImg} className="exp" />
            </S.CircleButton>
            <S.Sub $noMaxWidth>{amount} EXP</S.Sub>
          </S.ButtonWrapper>
          <S.ButtonWrapper onClick={logOutHandler}>
            <S.CircleButton $theme={theme}>
              <LogoutIcon />
            </S.CircleButton>
            <S.Sub>Logout</S.Sub>
          </S.ButtonWrapper>
        </>
      )}
    </S.Menu>
  );
};

export default HorizontalMobileMenuMain;
