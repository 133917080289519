import { EffectsListItem } from './item';
import { IExpEffectWidgetData } from 'shared/ui/effect-indicator/types';

import * as Styled from './styles';
import clsx from 'clsx';

interface IProps {
  effects: IExpEffectWidgetData[];
  handleTimeout(effectName: string): () => void;
  handleCaptureEffect(effectName: string): () => void;
}

export const EffectsList = ({ effects, handleTimeout, handleCaptureEffect }: IProps) => {
  return (
    <Styled.Container className={clsx('tiny-sb', { short: effects.length < 4 })}>
      {effects.map((item) => (
        <EffectsListItem handleCaptureEffect={handleCaptureEffect} handleTimeout={handleTimeout(item.type)} key={item.type} {...item} />
      ))}
    </Styled.Container>
  );
};
