import { EItemFungiblePremiumType, EServiceEffect, TItemFungibleTypeForEffects } from 'interface';

export const effectHoverLabels: Record<string, any> = {
  energy: 'energy',
  'no-energy': 'noEnergy',
  'common-energy-potion': 'common-energy-potion',
  'common-energy-recovery-potion': 'common-energy-recovery-potion',
  'common-exp-potion': 'common-exp-potion',
  'scholar-coin': 'scholar-coin',
  'seven-day-premium': 'seven-day-premium',
  'emperor-key': 'emperor-key',
  'emperor-crown': 'emperor-crown',
  'no-premium': 'noPremium',
};

export const getIsAllowed = (name: EItemFungiblePremiumType | EServiceEffect) => {
  const allowed: TItemFungibleTypeForEffects[] = [
    'energy',
    'no-energy',
    'scholar-coin',
    'emperor-badge',
    'emperor-key',
    'emperor-crown',
    'no-premium',
  ];
  return allowed.includes(name);
};
export const initialSecs = () => Math.ceil(Math.random() * TWENTY_MINS_IN_SECONDS);

export const defaultState = {
  label: null,
  isAnimating: false,
};

export const TWENTY_MINS_IN_SECONDS = 60 * 20;
export const DURATION = 0.25;
export const DELAY = 0;
export const variants = {
  show: {
    width: 'fit-content',
    opacity: 1,
  },
  hide: {
    width: 0,
    opacity: 0,
  },
};
export const transition = {
  duration: DURATION,
  delay: DELAY,
};
