import { motion } from 'framer-motion';
import styled, { keyframes } from 'styled-components';

const shimmer = keyframes`
  100% {
    background-position-x: -100%;
  }
`;
const SKELETON_BG = 'linear-gradient(-45deg, rgba(100, 135, 239, 0.1) 40%, rgba(100, 135, 239, 0.2) 50%, rgba(100, 135, 239, 0.1) 60%)';

export const ParentElement = styled.div`
  background: ${SKELETON_BG};
  animation: ${shimmer} 2s infinite linear;
  background-size: 300%;
  background-position-x: 200%;
`;

export const SpaceCardSkeleton = styled(ParentElement)`
  width: 722px;
  min-width: 722px;
  height: 244px;
  border-radius: 10px;
  &.small {
    width: 327px;
    height: 268px;
    min-width: auto;
  }
  @media screen and (max-width: 992px) {
    &.small {
      width: 100%;
    }
  }
`;

export const MobileSlideSkeleton = styled.div`
  width: 355px;
  min-width: 355px;
  position: relative;
  height: 175px;
  &::before {
    width: 140px;
    height: 140px;
    left: 39px;
    top: 50%;
    transform: translateY(-50%);
    background: ${SKELETON_BG};
    animation: ${shimmer} 2s infinite linear;
    border-radius: 50%;
    background-size: 300%;
    background-position-x: 200%;
    position: absolute;
    content: '';
    display: block;
  }
  &::after {
    position: absolute;
    right: 0;
    top: 0;
    width: 196px;
    height: 100%;
    background: ${SKELETON_BG};
    animation: ${shimmer} 2s infinite linear;
    border-radius: 10px;
    background-size: 300%;
    background-position-x: 200%;
    content: '';
    display: block;
  }
`;

export const QuestSkeleton = styled(ParentElement)`
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 50%;
  animation: ${shimmer} 2s infinite linear;
  background-image: ${SKELETON_BG};
  background-size: 300%;
  background-position-x: 200%;
  position: relative;
  &::after {
    display: block;
    position: absolute;
    top: calc(100% + 6px);
    left: 0;
    width: 100%;
    height: 18px;
    border-radius: 4px;
    background: inherit;
    animation: ${shimmer} 2s infinite linear;
    content: '';
  }
`;

export const SliderSkeletonBox = styled(motion.div)`
  height: fit-content;
  display: flex;
  gap: 8px;
  overflow: hidden;
  border-radius: 10px;
  margin-bottom: 64px;
`;

export const SpacesSkeletonBox = styled(motion.div)`
  gap: 16px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-bottom: 60px;
  @media screen and (max-width: 992px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  }
`;

export const QuestSliderSkeleton = styled(motion.div)`
  width: 100%;
  height: fit-content;
  margin-bottom: 64px;
  gap: 16px;
  display: flex;
`;

export const MobileSliderBox = styled.div`
  display: flex;
  justify-content: center;
  width: calc(100vw + 18px);
  align-items: center;
  gap: 15px;
  overflow: hidden;
  margin-bottom: 80px;
  margin-left: -20px;
`;

export const TitleLoader = styled(ParentElement)`
  width: 200px;
  height: 32px;
  border-radius: 4px;
  margin-bottom: 24px;
`;

export const CourseCardLoader = styled(ParentElement)`
  margin-bottom: 24px;
  border-radius: 8px;
  height: 296px;
  width: 244px;
  @media screen and (max-width: 992px) {
    width: 180px;
    height: 224px;
    min-width: 180px;
  }
`;

export const CourseLoaderBox = styled.div`
  width: 100%;
  gap: 19px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  @media screen and (max-width: 992px) {
    display: flex;
    gap: 16px;
  }
`;
