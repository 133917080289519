import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)<{ bottom?: string; left?: number; top?: number }>`
  /* left: ${(p) => (p.left ? `${p.left}px` : 0)}; */
  /* top: ${(p) => (p.top ? `${p.top}px` : 0)}; */
  width: 430px;
  top: 80px;
  left: 0px;
  max-width: 100%;
  height: 369px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: absolute;
  background: linear-gradient(263.63deg, rgba(255, 255, 255, 0.1) -3%, rgba(37, 38, 47, 0.1) -2.99%, rgba(37, 38, 47, 0.1) 104.5%);
  box-shadow: 16px 13px 34px rgba(28, 29, 38, 0.8);
  backdrop-filter: blur(174px);
  z-index: 1200;
  border-radius: 5px;
  border: 2px solid #ffffff20;
  transition: 0.05s;
`;
export const Tabs = styled.div`
  padding: 15px 18px 9px;
  display: flex;
  align-items: flex-end;
  gap: 20px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.15);
`;

export const Flex = styled.div<{ dir?: 'column' | 'row'; gap?: string }>`
  display: flex;
  flex-direction: ${(p) => p.dir || 'row'};
  gap: ${(p) => p.gap || 'none'};
`;

export const Tab = styled.p<{ $active?: boolean }>`
  opacity: ${({ $active }) => ($active ? 1 : 0.5)};
  color: white;
  position: relative;
  font-weight: 400;
  transition: 0.3s;
  cursor: pointer;
  &:hover {
    opacity: ${({ $active }) => ($active ? 1 : 0.8)};
  }
  &::after {
    width: 100%;
    height: 3px;
    background: #627eea;
    opacity: ${({ $active }) => ($active ? 1 : 0)};
    left: 0;
    bottom: -11px;
    display: block;
    content: '';
    position: absolute;
    transition: 0.3s;
  }
`;

export const Label = styled.p`
  color: 'white';
  opacity: 0.4;
  font-size: '12px';
`;

export const ColorsBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  background: rgba(255, 255, 255, 0.04);
  padding: 16px 18px;
  overflow: auto;
`;

export const ColorsList = styled.div`
  display: flex;
  gap: 13px;
`;
export const Color = styled.div<{ color: string; isActive?: boolean }>`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  background: ${(p) => p.color};
  outline: 4px solid ${(p) => (p.isActive ? p.color + '70' : 'transparent')};
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transition: 0.1s;
    transform: scale(1.05);
  }
`;
export const ColorButton = styled.div`
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transition: 0.1s;
    transform: scale(1.05);
  }
`;

export const IconsBlock = styled.div`
  padding: 18px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const IconsList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const IconBox = styled.div<{ color: string }>`
  width: 30px;
  height: 30px;
  border-radius: 2px;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background: #ffffff0a;
  }
  & > * {
    color: ${(p) => p.color};
    transition: 0.3s;
    height: 20px;
    width: auto;
  }
  & .quotePath {
    fill: ${(p) => p.color};
    transition: 0.3s;
  }
`;

export const SpecialsMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  padding: 18px;
`;
export const SpecialsList = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 52px);
`;
export const SpecialBox = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  border-radius: 2px;
  background: transparent;
  transition: 0.3s;
  aspect-ratio: 1 / 1;
  cursor: pointer;
  &:hover {
    background: #ffffff0a;
  }
`;
export const SpecialRound = styled.div<{ color: string }>`
  background: ${(p) => p.color + '66'}; // 40% opacity
  width: 42px;
  height: 42px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  & > * {
    width: 22px;
  }
`;

export const PickerMenu = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;
export const PickerBox = styled.div`
  display: flex;
  background: #ffffff1a;
  border-radius: 4px;
`;
export const PickerBlock = styled.div`
  &:not(:last-child) {
    border-right: 1px solid #ffffff20;
  }
  display: flex;
  justify-content: center;
  align-items: center;
  width: auto;
  height: 36px;
  padding: 0px 10px;
  gap: 8px;
  font-size: 14px;
  font-weight: 400;
`;
export const PickerPreview = styled.div<{ color: string }>`
  border-radius: 50%;
  background: ${(p) => p.color};
  width: 26px;
  height: 26px;
  cursor: pointer;
  transition: 0.1s;
  &:hover {
    transform: scale(1.1);
  }
`;
export const PickerHex = styled.p`
  color: #afb4b8;
`;

export const PickerBtn = styled.button`
  width: auto;
  height: 36px;
  padding: 0px 16px;
  justify-content: center;
  align-items: center;
  background: #ffffff1a;
  color: white;
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  outline: none;
  border-radius: 4px;
  border: none;
`;

export const UploadImgBox = styled.div`
  padding: 0px 16px;
`;
