import styled, { css } from 'styled-components';

export const Container = styled.div<{ $zIndex: number }>(
    ({ $zIndex }) => css`
      position: fixed;
      top: -50px;
      left: 0;
      right: 0;
      bottom: -50px;
      background: var(--trans-bg);
      backdrop-filter: blur(60px);
      z-index: ${$zIndex};
      display: flex;
      flex-direction: column;
      overflow-y: auto;
      padding-top: 80px;
      color: white;

      .groups {
        --height: 46px;
        --padding: 10px;
        padding-left: var(--padding-mobile);
        height: calc(var(--padding) + var(--height));
        max-height: calc(var(--padding) + var(--height));

        padding-bottom: var(--padding);
      }
    `
  ),
  Button = styled.div(
    () => css`
      align-self: flex-end;
      color: inherit;
      min-width: 24px;
      height: 24px;
      display: flex;
      & > svg {
        width: 100%;
        height: 100%;
      }
    `
  ),
  Header = styled.h1(
    () => css`
      font-size: 28px;
      font-weight: 500;
      line-height: 110%;
      letter-spacing: -0.28px;
      margin: 0 0 20px 0;
      color: inherit;
      display: flex;
      align-items: center;
      justify-content: space-between;
    `
  );
