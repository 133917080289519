import { toast } from 'react-toastify';
import { useLazySearchQuery } from 'store';

export const useFetchSearchItems = () => {
  const [search, { isLoading }] = useLazySearchQuery();

  async function fetch(input: string) {
    if (!/[a-zA-Z0-9._\-@]/gi.test(input)) {
      toast.error('Use numbers, letters, and symbols hyphen dash and dot only');
      return [];
    }

    return search(input)
      .unwrap()
      .then(Object.entries)
      .catch((ex) => {
        toast.error(ex?.message ?? 'Something went wrong...');
        console.error(ex);
      });
  }

  return { fetch, isLoading };
};
