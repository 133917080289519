import { useSelector } from 'react-redux';
import { getIsAuth } from 'store';
import useClickOutside from 'hooks/useClickOutside';
import { useSearch } from './utils/useSearch';

import { ReactComponent as Search } from 'assets/icons/search.svg';

import { NavIconWrapper } from '../styles';
import SearchItemsArea from './SearchItemsArea';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import styles from './styles/styles.module.css';
import * as Styled from './styles';

export default function SearchMain() {
  const isAuth = useSelector(getIsAuth);
  const domNode = useClickOutside(() => setShowSearch(false));
  const { handleSearchFocus, isLoading, handleInput, goToItem, searchedItems, showSearch, setShowSearch, userInput } = useSearch();

  return (
    <>
      <Styled.SearchContainer className="">
        <NavIconWrapper onFocus={handleSearchFocus}>
          {isLoading ? <MightyLoaderMini className={styles.loader} /> : <Search style={{ marginLeft: '5px' }} />}
          <Styled.Input
            autoComplete={isAuth ? 'on' : 'off'}
            id="search-main"
            placeholder="Search Courses, Shop, Accounts, Items..."
            value={userInput}
            type="text"
            onChange={handleInput}
          />
        </NavIconWrapper>
      </Styled.SearchContainer>
      {searchedItems.length > 0 && showSearch && (
        <SearchItemsArea goToItem={goToItem} items={searchedItems} isInput={userInput.length > 0} setShowSearch={setShowSearch} domNode={domNode} />
      )}
    </>
  );
}
