import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      position: fixed;
      right: 10px;
      top: 10px;
      z-index: 1000;
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      padding: 4px 0;
      margin-right: 14px;
    `
  ),
  Exp = styled.span(
    () => css`
      margin-bottom: 6px;
      color: var(--font-color-strong);
      font-size: 22px;
      font-weight: 700;
      line-height: 100%;
    `
  ),
  Energy = styled.div(
    () => css`
      color: white;
      svg {
        color: rgba(1, 255, 186, 1);
        fill: rgba(1, 255, 186, 1);
      }
    `
  );
