import styled, { css } from 'styled-components';

export const SRoot = styled.div(
  () => css`
    width: 100%;
    line-height: 1.42;
  `
);

export const Container = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  padding-left: 15px;
  border-left: 2px solid ${(p) => p.color};
  position: relative;
  min-height: 96px;
  transition: 0.3s;
`;

export const AuthorInput = styled.input`
  color: #afb4b8;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.65;
  padding: 0;
  border: none;
  outline: none;
  background: none;
  width: fit-content;
`;

export const AuthorStr = styled.p`
  color: #afb4b8;
  font-size: 16px;
  font-weight: 400;
  opacity: 0.65;
  background: none;
  width: fit-content;
`;

export const IconBox = styled.div<{ color: string }>`
  color: ${(p) => p.color};
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 0.3s;
  & > * {
    width: 100%;
    height: auto;
  }
  & .quotePath {
    fill: ${(p) => p.color};
    transition: 0.3s;
  }
`;
