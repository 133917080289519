import Header from '../Header';
import * as Styled from './styles';
import { useDisplay } from 'hooks';
import { profileAnimationDelay } from '../../../layout/user-layout';
import { SlideX } from 'shared/ui/motion';

type Props = {
  label: string;
  children: any;
  className?: string;
};

const delayPockets: any = {
  Inventory: 1,
  Courses: 2,
  Energy: 3,
  Effects: 4,
  Friends: 5,
};

export default function PocketItem({ label, children, className = '' }: Props) {
  const { isMobile } = useDisplay();

  if (!isMobile && delayPockets[label]) {
    return (
      <SlideX delay={delayPockets[label] * profileAnimationDelay}>
        <Styled.Container className={className}>
          <Header label={label} />
          {children}
        </Styled.Container>
      </SlideX>
    );
  }
  return (
    <Styled.Container className={className}>
      <Header label={label} />
      {children}
    </Styled.Container>
  );
}
