import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      height: fit-content;
      position: relative;
      max-height: calc(471px - (var(--padding-y) * 2));

      & * {
        color: var(--font-color-strong);
      }

      &.mobile {
        max-height: none;
      }
    `
  ),
  Chevron = styled.button(
    () => css`
      width: 100%;
      border-radius: 37px;
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        border-radius: 37px;
        padding: 10px;
        margin: -5px;
        margin-bottom: 5px;
        height: 37px;
        width: 37px;
      }
      &:hover {
        svg {
          background-color: rgba(255, 255, 255, 0.05);
        }
      }
      &.mobile {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        width: 50px;
        height: 50px;

        svg {
          height: auto;
          width: 100%;
        }
      }
    `
  ),
  Title = styled.div(
    () => css`
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
      line-height: 100%;
    `
  ),
  Blocks = styled.div(
    () => css`
      max-height: 220px;
      overflow-y: auto;
      padding-right: 15px;

      &.mobile {
        max-height: 200px;
        padding-bottom: 10px;
      }
    `
  ),
  Block = styled.div(
    () => css`
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0;
      }
    `
  );
