import { FC, useEffect, useState } from 'react';
import { SCodeWebOutputBrowser, SCodeWebOutputConsole, SCodeWebOutputContainer, SCodeWebOutputHeader, SCodeWebOutputSwitch } from './style/styles';
import { motion } from 'framer-motion';
import { TWebWidgetSetup } from '../../../index';
import clsx from 'clsx';
import { useDisplay } from 'hooks';

import { ReactComponent as ConsoleIcon } from 'assets/icons/consoleIcon.svg';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

interface Props {
  srcDoc: string;
  output: any[];
  config: TWebWidgetSetup;
  className?: string;
  compiledLang?: string;
}

type IHeaderOutput = 'browser' | 'console';

export const CodeWebOutput: FC<Props> = ({ srcDoc, output, config, className = '', compiledLang }) => {
  const [type, setType] = useState<IHeaderOutput>('browser');
  const theme = useSelector(getCurrentTheme);
  const { isMobile } = useDisplay();

  useEffect(() => {
    if (compiledLang) {
      setType('console');
    }
  }, [compiledLang]);

  const currentHeader =
    type === 'browser' ? (
      <>
        Browser{' '}
        {config.withConsole && (
          <SCodeWebOutputSwitch className={clsx(theme)} onClick={() => setType('console')}>
            <ConsoleIcon />
          </SCodeWebOutputSwitch>
        )}
      </>
    ) : (
      <>
        Console{' '}
        {compiledLang === null && (
          <SCodeWebOutputSwitch onClick={() => setType('browser')}>
            <ConsoleIcon />
          </SCodeWebOutputSwitch>
        )}
      </>
    );

  return (
    <SCodeWebOutputContainer
      as={motion.div}
      initial={isMobile ? {} : { opacity: 0, x: -100, zIndex: 0 }}
      animate={isMobile ? {} : { opacity: 1, x: 0 }}
      transition={isMobile ? {} : { delay: 0.5, duration: 0.5 }}
      className={clsx(theme, { [className]: !!className })}
    >
      <SCodeWebOutputHeader className={clsx(theme)}>{currentHeader}</SCodeWebOutputHeader>
      <SCodeWebOutputBrowser style={{ display: type === 'browser' ? 'block' : 'none' }}>
        <iframe id={'iframe-web-output'} src={srcDoc} title="output" sandbox="allow-scripts" frameBorder="1" width="100%" height="100%" />
      </SCodeWebOutputBrowser>
      <SCodeWebOutputConsole style={{ display: type === 'console' ? 'block' : 'none' }}>
        <div className={'console-scroll tiny-sb tiny-sb__dark'}>
          {compiledLang === null
            ? output.map((l) => (
                <div className={clsx('cnsl-line', l?.error ? 'cnsl-line__error' : '')} key={Math.random()}>
                  {l?.error && <AiFillCloseCircle color={'rgb(229,104,98)'} />}
                  {JSON.stringify(l?.error ? l.error : l)}
                </div>
              ))
            : CompilerOutput(compiledLang)}
        </div>
      </SCodeWebOutputConsole>
    </SCodeWebOutputContainer>
  );
};

function CompilerOutput(compilerLang: any) {
  console.log({ compilerLang });
  const { result, error } = compilerLang;

  const line = (value: string, isError: boolean) => (
    <div className={clsx('cnsl-line', isError ? 'cnsl-line__error' : '')} key={Math.random()}>
      {isError && <AiFillCloseCircle color={'rgb(229,104,98)'} />}
      {value}
    </div>
  );

  return <>{result ? result.map((l: any) => line(JSON.stringify(l), false)) : line(JSON.stringify(error), true)}</>;
}
