import { useDisplay } from 'hooks';
import { ISpace } from 'store';
import SpaceItem from '../SpaceItem';
import { useMemo } from 'react';
import MobileSpaceSlide from './slides';
import UniversalSlider from 'components/library/universal-slider';

type Props = {
  spaces: ISpace[];
};

const SpacesSlider = ({ spaces }: Props) => {
  const { isMobile } = useDisplay();

  const slides = useMemo(
    () =>
      spaces.map((el) =>
        isMobile ? <MobileSpaceSlide key={el._id + 'slider'} space={el} /> : <SpaceItem large={true} key={el._id + 'slider'} space={el} />
      ),
    [spaces, isMobile]
  );

  if (isMobile) {
    return (
      <UniversalSlider
        containerDivProps={{ style: { marginBottom: '80px' } }}
        slides={slides}
        gap={30}
        variableWitdh
        spaced
        // slideSizeOfSlider
        slideHeight={200}
        autoplay={5000}
        dotsOffsetY={8}
        slidesToScroll={1}
        slidesToShow={3}
        centerMode
        shadowed
        infinite
        dots
      />
    );
  }

  return (
    <>
      <UniversalSlider
        slides={slides}
        arrows={'bottom'}
        slidesToScroll={1}
        slidesToShow={1}
        variableWitdh
        centerMode={false}
        autoplay={5000}
        infinite
        sliderDivProps={{
          style: {
            borderRadius: 10,
          },
        }}
        gap={8}
        slideWidth={722}
      />
    </>
  );
};

export default SpacesSlider;
