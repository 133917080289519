import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      backdrop-filter: var(--standard-blur);
      border-radius: 9px;
      width: 245px;
      height: 68px;
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 8px 10px;
      cursor: pointer;
      position: relative;
      margin-left: 1px;
      &.light {
        background: #e6eafa;
      }

      &:hover {
        filter: brightness(1.15);
      }

      & .group-img {
        margin: 0px 7px;
      }

      @media screen and (max-width: 768pt) {
        flex: 1 0 auto;
      }
    `
  ),
  Title = styled.p(
    () => css`
      width: 100%;
      font-size: 18px;
      line-height: 124%;
      color: var(--font-color-strong);
      text-transform: capitalize;
      text-align: left;
    `
  ),
  Image = styled.img(
    () => css`
      height: 52px;
      width: 52px;
      max-height: 52px;
      max-width: 52px;
    `
  ),
  Frame = styled(motion.div)(
    () => css`
      &.active {
        background-size: 100% 100%;
        scale: 1.01;
        background-repeat: no-repeat;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        border-radius: 9px;
      }
    `
  );
