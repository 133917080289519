import { THEMES } from 'app/constants';
import styled, { css } from 'styled-components';

export const Wrap = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      color: var(--font-color-strong);
    `
  ),
  Label = styled.p<{ $fontSize?: string; $margin?: string }>(
    ({ $fontSize, $margin = '14px' }) => css`
      color: inherit;
      font-size: ${$fontSize};
      font-weight: 500;
      line-height: 100%;
      margin-bottom: ${$margin};
      white-space: nowrap;
    `
  ),
  Container = styled.div<{ $variant: 'banner' | 'panel' }>(
    ({ $variant }) => css`
      display: flex;

      ${$variant === 'banner' &&
      css`
        gap: 11px;

        ${Reward} {
          width: 53px;
          height: 53px;

          img {
            width: 45px;
          }
        }

        ${Date} {
          font-size: 16px;
        }
      `}

      ${$variant === 'panel' &&
      css`
        gap: 8px;

        ${Reward} {
          width: 46px;
          height: 46px;

          img {
            width: 100%;
          }
        }

        ${Date} {
          font-size: 14px;
        }
      `}
    `
  ),
  RewardWrap = styled.div(() => css``),
  Reward = styled.div<{ $grade: string }>(
    ({ $grade, theme }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 26.906px;
        background: var(--grade-${theme.dark ? THEMES.DARK : THEMES.LIGHT}-color-${$grade});
        opacity: 0.15;
      }

      img {
        position: relative;
        z-index: 2;
      }

      p {
        color: var(--font-color-strong);
        font-size: 27px;
        font-weight: 500;
        line-height: 100%;
        &.mobile {
          color: white;
        }
      }
    `
  ),
  Date = styled.p(
    () => css`
      text-align: center;
      margin-top: 9px;
      color: var(--font-color-strong);
      line-height: 100%;
      opacity: 0.6;

      &.mobile {
        color: white;
      }
    `
  );
