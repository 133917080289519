import { ILesson, ILessonFull, IResultCourse } from 'interface/courses';
import * as S from '../styles';
import { useParams } from 'react-router-dom';
import { useMemo } from 'react';
import HorizontalMobileMenu from './menu';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import clsx from 'clsx';
import { RESULT_STATUSES } from 'app/constants';
import { useGetResultCourseParts } from 'components/pages/study/courses/material-pages/VerticalLessonPage/utils';

type Props = {
  hide?: boolean;
  resultCourse: IResultCourse;
  lesson: ILesson | ILessonFull;
  changeMenuState: (arg: 'progress' | 'menu' | null) => void;
  menuState: 'progress' | 'menu' | null;
  scrollTop: number;
  screenHeight: number;
};

const LessonMobileMenu = ({ resultCourse, lesson, hide = false, changeMenuState, menuState, screenHeight, scrollTop }: Props) => {
  const theme = useGetCurrentTheme();
  const { vh, viewportWidth } = useDisplay();
  const isDisplaying = menuState === 'menu';

  const toggleDisplay = () => {
    if (menuState === 'menu') {
      changeMenuState(null);
    } else {
      changeMenuState('menu');
    }
  };

  const openLessons = () => changeMenuState('progress');

  const { chapterId = '', lessonId = '' } = useParams();
  const { resultLesson } = useGetResultCourseParts({ resultCourse, chapterId, lessonId });
  const realProgress = useMemo(
    () =>
      resultLesson
        ? Math.floor((resultLesson.resultSlides.filter((el) => el.status === RESULT_STATUSES.FINISHED).length / lesson.slides.length) * 100)
        : 0,
    [resultLesson, lesson]
  );
  const scrollPercentage = useMemo(() => Math.floor((scrollTop / (screenHeight - 100 * vh)) * 100), [scrollTop, screenHeight, vh]);
  const progress = useMemo(() => Math.min(scrollPercentage, realProgress), [scrollPercentage, realProgress]);
  const progressScale = useMemo(() => (viewportWidth * progress) / 100, [progress, viewportWidth]);

  return (
    <S.Container $theme={theme} className={clsx('blur-15', { isHidden: !isDisplaying || hide })}>
      <S.ToggleButton onClick={toggleDisplay}>
        <S.ToggleIconClosed $visible={!isDisplaying} />
        <S.ToggleIconOpened $visible={isDisplaying} />
      </S.ToggleButton>
      <S.Progress />
      <S.ProgressLine style={{ transform: `scaleX(${progressScale})` }} />
      <HorizontalMobileMenu openLessons={openLessons} isHidden={!isDisplaying} />
    </S.Container>
  );
};

export default LessonMobileMenu;
