import * as S from './styles';
import { MenuIcons } from 'components/library/courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/ui';
import { EIcons } from 'components/library/courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/constants';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import { useDisplay, useGetCurrentTheme, useGetIsAuth, useGetItems, useNavigateToAuth } from 'hooks';
import { useMemo, useState } from 'react';
import { ESlideWidgetTypes, IRewardMinimal, getPartnerId } from 'store';
import { ICourseFull } from 'interface/courses';
import completedIcon from './assets/completed.png';
import { GRADES, ROUTES } from 'app/constants';
import { useSelector } from 'react-redux';
import { lightenColor } from '../utils';
import { colorToHex } from './hooks';
import CircleMenu from './questMenu';
import useAverageColorFromImageUrl from 'hooks/useAverageColorFromImageUrl';

type Props = {
  course: ICourseFull;
  isFinished?: boolean;
  isOwner?: boolean;
  onDelete?: (_id: string) => void;
  spaceImage?: string;
  isInSlider?: boolean;
};

const gradeColors = {
  [GRADES.COMMON]: 'rgb(125, 125, 125)',
  [GRADES.LEGENDARY]: 'rgb(240, 200, 137)',
  [GRADES.UNCOMMON]: 'rgb(41, 171, 136)',
  [GRADES.MYTHICAL]: 'rgb(241, 79, 99)',
  [GRADES.RARE]: 'rgb(149, 216, 245)',
  [GRADES.EPIC]: 'rgb(202, 71, 206)',
  [GRADES.DIVINE]: 'rgb(124, 214, 234)',
};

const Quest = ({ course, isOwner, isFinished, onDelete, spaceImage, isInSlider }: Props) => {
  const theme = useGetCurrentTheme();
  const partnerId = useSelector(getPartnerId);
  const items = useGetItems();
  const isAuth = useGetIsAuth();
  const navigate = useNavigate();
  const navToAuth = useNavigateToAuth();
  const { isMobile } = useDisplay();
  const { pathname } = useLocation();
  const [isHovered, setIsHovered] = useState(false);
  const averageColor = useAverageColorFromImageUrl(spaceImage || '');

  const spaceImageColor = useMemo(() => colorToHex(averageColor).substring(0, 7), [averageColor]);
  const name = useMemo(() => course.chapters[0]?.lessons[0]?.slides[0]?.name || course.name, [course]);
  const task = useMemo(() => course.chapters[0]?.lessons[0]?.slides[0]?.content?.find((el) => el.type === ESlideWidgetTypes.TASK) || null, [course]);
  const isInFeed = useMemo(() => matchPath(ROUTES.SPACES, pathname), [pathname]);
  const rewards: IRewardMinimal[] = useMemo(() => {
    return course.chapters[0]?.lessons[0]?.slides[0]?.rewards || [];
  }, [course]);

  const rewardImg = useMemo(() => {
    if (!rewards.length) {
      return '';
    }
    const lastReward = [...rewards].sort((a, b) => a.id - b.id)[rewards.length - 1];
    const neededItem = items.find((el) => el.id === lastReward.id);
    if (!neededItem) {
      return '';
    }

    return neededItem.imageThumb || '';
  }, [rewards]);

  const gradeColor = gradeColors[GRADES.COMMON];

  const goToCourse = () => {
    if (!isAuth) {
      navToAuth();
      return;
    }

    if (partnerId) {
      navigate(`${ROUTES.COURSES}/${course._id}/${course.chapters[0]._id}/${course.chapters[0].lessons[0]._id}?partnerID=${partnerId}`);
      return;
    }

    if (isInFeed) {
      navigate(ROUTES.FEED, { state: { firstQuestId: course._id } });
      return;
    }

    navigate(`${ROUTES.COURSES}/${course._id}/${course.chapters[0]._id}/${course.chapters[0].lessons[0]._id}`);
  };

  const getRoundSvgWColor = (color?: string) =>
    color && !isFinished ? (
      <svg className="quest-circle" viewBox="0 0 100 100" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <linearGradient id={course._id + 'gradient'} x1="0" y1="0" x2="1" y2="1">
          <stop offset="0%" stopColor={lightenColor(color, 20)}></stop>
          <stop offset="108%" stopColor={lightenColor(color, -25)}></stop>
        </linearGradient>
        <circle r="48.5" cx="50" cy="50" className="external-circle" strokeWidth="3" fill="none" stroke={`url(#${course._id + 'gradient'})`}></circle>
      </svg>
    ) : null;

  return (
    <S.Questbox onMouseLeave={() => setIsHovered(false)} $hover={isOwner && isHovered} $theme={theme} $darker={isFinished}>
      {isFinished && <S.RightTopIcon src={completedIcon} />}
      {!isFinished && rewardImg && <S.RightTopIcon src={rewardImg} />}
      <S.QuestTop onClick={goToCourse} $finished={isFinished} bg={task ? task.color : gradeColor}>
        {getRoundSvgWColor(task ? task.color : spaceImage ? spaceImageColor : gradeColor)}
        {isOwner && !isMobile && (
          <CircleMenu handleNavigate={goToCourse} color={task ? task.color : gradeColor} course={course} onDelete={onDelete} isVisible={isHovered} />
        )}
        <S.QuestIconFrame
          onMouseEnter={() => setIsHovered(!!isOwner && !isMobile)}
          className={theme}
          $finished={isFinished}
          bg={task ? task.color : gradeColor}
        >
          {task ? (
            <MenuIcons type={task.icon as EIcons} />
          ) : spaceImage ? (
            <img src={spaceImage} style={{ borderRadius: '50%', width: '90%', height: '90%' }} alt="" />
          ) : (
            <MenuIcons type={EIcons.INFO} />
          )}
        </S.QuestIconFrame>
      </S.QuestTop>
      <div style={{ maxHeight: isInSlider ? 0 : 'auto' }}>{name}</div>
    </S.Questbox>
  );
};

export default Quest;
