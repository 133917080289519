import { useEffect, useMemo, useReducer } from 'react';
import { FRIEND_STATUS } from 'app/constants';
import { shallowEqual, useSelector } from 'react-redux';
import { getIsOpenSidePanel, setIsOpenSidePanel, useGetCourseByIdQuery } from 'store';
import { dispatch, useDisplay, useGetUserData } from 'hooks';
import defaultSlidesSet from './utils/defaultSlides';

import { SlideLessonLeftOff } from 'components/shared/slides';
import LeftOffLesson from 'components/pages/study/courses/left-off-lesson/left-off-lesson';
import Inventory from '../Inventory/inventory';
import Courses from '../Courses/courses';
import FriendsSmall from '../FriendsSmall';
import Pockets from '../Pockets';
import { CourseBanner } from 'components/pages/study/courses/courseBanner/CourseBanner';
import { DailyRewards } from '../dailyRewards';
import { DelayedComponent } from 'shared/ui/delayed-component';
import { MultipurposeBanner } from 'shared/ui';
import { Effects } from './effects';
import UniversalSlider from 'components/library/universal-slider';
import ReferralsBanner from 'components/pages/referrals/banner/ReferralsBanner';

import styles from '../styles.module.scss';
import { ICourse } from 'interface/courses';

interface IState {
  exactCourseBannerId: null | string;
  slides: any;
  isSeeDetails: boolean;
  isMPBannerForcedClosed: boolean;
}

const Profile = () => {
  const { shopPanel: isShopPanelOpen } = useSelector(getIsOpenSidePanel, shallowEqual);
  const { isMobile } = useDisplay();
  const {
    friends,
    exp: { items },
  } = useGetUserData();

  const [{ exactCourseBannerId, slides, isSeeDetails, isMPBannerForcedClosed: isMPBannerOpenClosed }, updateState] = useReducer(
    (prev: IState, next: Partial<IState>) => ({ ...prev, ...next }),
    {
      exactCourseBannerId: null,
      slides: defaultSlidesSet,
      isSeeDetails: false,
      isMPBannerForcedClosed: Boolean(isShopPanelOpen),
    }
  );

  // const { course: courseFetched, isReady } = useFetchAndProcessCourse(exactCourseBannerId);
  const { data: courseFetched } = useGetCourseByIdQuery(exactCourseBannerId || '', { skip: !exactCourseBannerId });
  const isReady = !!courseFetched;

  const userFriends = useMemo(() => friends.filter((i) => i.status === FRIEND_STATUS.FRIENDS).map((fr) => fr.recipient), [friends, friends.length]);

  useEffect(() => {
    updateState({ isMPBannerForcedClosed: Boolean(isShopPanelOpen) });
  }, [isShopPanelOpen]);

  const handleCourseCapture = (courseId: string) => {
    if (isMobile) {
      const isAlreadyCaptured = exactCourseBannerId === courseId && isSeeDetails;
      isAlreadyCaptured
        ? updateState({ isSeeDetails: false })
        : updateState({
            exactCourseBannerId: courseId,
            isSeeDetails: true,
          });
    } else {
      const isAlreadyCaptured = exactCourseBannerId === courseId;

      if (isAlreadyCaptured) {
        updateState({ exactCourseBannerId: null, slides: defaultSlidesSet });
      } else {
        if (isMPBannerOpenClosed) {
          updateState({ isMPBannerForcedClosed: false });
          dispatch(setIsOpenSidePanel({ shopPanel: false }));
        }
        updateState({
          exactCourseBannerId: courseId,
          slides: [
            {
              component: LeftOffLesson,
              name: `LeftOffLesson_profileCourse_${courseId}`,
              props: { horizontalClassName: 'leftOffBanner', givenCourseId: courseId, isPanelOnly: true },
            },
          ],
        });
      }
    }
  };

  const caroSlides = useMemo(() => {
    const slides = [<DailyRewards />, <SlideLessonLeftOff />, <ReferralsBanner />];
    // if (!isPremium) {
    //   slides.push(<SlidePremium />);
    // }
    return slides;
  }, []);

  return (
    <>
      {isMobile ? (
        <>
          <UniversalSlider
            containerClassName={styles.carousel}
            slides={caroSlides}
            slideHeight={250}
            dots
            spaced
            dotsOffsetY={-30}
            variableWitdh
            // slideSizeOfSlider
            isWidthOfPage
            slidesToShow={1}
            slidesToScroll={1}
            listOverflow="visible"
          />
          <CourseBanner
            key={exactCourseBannerId ?? 'CourseBannerAtProfile'}
            course={courseFetched as ICourse}
            open={isSeeDetails}
            setSelectedCourse={exactCourseBannerId ? () => handleCourseCapture(exactCourseBannerId) : () => {}}
            isReady={isReady}
          />
        </>
      ) : (
        <DelayedComponent delay={1000}>
          <MultipurposeBanner
            isForcedHidden={isMPBannerOpenClosed}
            isPanelOnly={!!exactCourseBannerId}
            key={exactCourseBannerId ?? 'MultipurposeBannerAtProfile'}
            slides={slides}
            onClose={exactCourseBannerId ? () => handleCourseCapture(exactCourseBannerId) : undefined}
            getCurrentSlides={(slides) => dispatch(setIsOpenSidePanel({ multiPanel: !!slides.length }))}
          />
        </DelayedComponent>
      )}
      <Pockets
        leftPockets={[
          {
            label: 'Inventory',
            node: <Inventory inventory={items} />,
            className: 'inventory',
          },
          {
            label: 'Effects',
            node: (
              <div className="mobile-padding">
                <Effects />
              </div>
            ),
            className: 'effects',
          },
        ]}
        rightPockets={[
          { label: 'Courses', node: <Courses onCourseCapture={handleCourseCapture} />, className: 'courses' },
          {
            label: 'Friends',
            node: <FriendsSmall friends={userFriends} className="mobile-padding" />,
            className: 'fixed-size--right friends',
          },
        ]}
      />
    </>
  );
};

export default Profile;
