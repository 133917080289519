import { ChangeEvent, FC, useCallback, useEffect, useRef, useState } from 'react';
import * as yup from 'yup';
import { IPathProp } from 'pages/coursesConstructor/parts/editLesson/types';
import { NEW_ITEM_ID, IRewardMinimal } from 'store';
import { EInfoBadgeTypes } from 'components/library/infoBadge/types';
import { IDatabaseItemQuantified } from 'interface';

import SlideRightPanel from '../SlideRightPanel';
import InfoBadge from 'components/library/infoBadge';
import TransparentTextarea from 'components/library/transparentTextarea';
import SlideContainer from '../SlideContainer';

import styles from './styles.module.css';
import NewRewardsModal from 'pages/coursesConstructor/parts/chaptersList/components/NewRewardsModal';

const MAX_LENGTH = 50;

type TCourseConstructorContainerProps = {
  title: string;
  slideId: string;
  children: React.ReactNode;
  hideControls?: boolean;
  allRewards: IDatabaseItemQuantified[];
  rewards: IRewardMinimal[];
  inUpdate?: boolean;
  path: IPathProp;
  onSlideDelete: () => void;
  onTitleChange: (value: string) => void;
  onRewardsChange: (newRewards: IRewardMinimal[]) => void;
  isQuest?: boolean;
};

const CourseConstructorContainer: FC<TCourseConstructorContainerProps> = ({
  children,
  onSlideDelete,
  title,
  slideId,
  hideControls = false,
  onTitleChange,
  allRewards,
  rewards,
  onRewardsChange,
  inUpdate = false,
  path,
  isQuest,
}) => {
  const [isRewardsModalOpened, setIsRewardsModalOpened] = useState(false);
  const $onChangeTimerId = useRef<number | null>(null);
  const $titleInputRef = useRef<HTMLTextAreaElement>(null);
  const [titleValue, setTitleValue] = useState(title);
  const [error, setError] = useState<string | null>(null);
  const schema = yup.object().shape({
    name: yup
      .string()
      .required('Title is required')
      .test('string-equality', 'Title is required', () => titleValue !== 'Enter slide title here...')
      .min(3, 'Min value is 3')
      .max(MAX_LENGTH),
  });

  useEffect(() => {
    (async () => {
      try {
        await schema.validate({ name: titleValue });

        if (error) {
          setError(null);
        }
      } catch (e: any) {
        setError(e.errors[0]);
      }
    })();
  }, [titleValue]);

  const handleTitleChange = async (e: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    if ($onChangeTimerId.current) {
      window.clearTimeout($onChangeTimerId.current);
    }

    try {
      await schema.validate({ name: value });

      if (error) {
        setError(null);
      }

      $onChangeTimerId.current = window.setTimeout(() => onTitleChange(value), 1000);
    } catch (e: any) {
      setError(e.errors[0]);
    } finally {
      setTitleValue(value);
    }
  };

  const handleTabKeyPress = useCallback(
    (evt: KeyboardEvent) => {
      if (evt.keyCode !== 9) {
        return;
      }

      if (error) {
        evt.stopPropagation();
        evt.preventDefault();
        $titleInputRef.current?.focus();
      }
    },
    [error]
  );

  const handleRewardsSubmit = (newRewards: IRewardMinimal[]) => {
    onRewardsChange(newRewards);
    setIsRewardsModalOpened(false);
  };

  useEffect(() => {
    document.addEventListener('keyup', handleTabKeyPress);

    return () => {
      document.removeEventListener('keyup', handleTabKeyPress);
    };
  }, [handleTabKeyPress]);

  return (
    <SlideContainer
      rightPanel={
        !hideControls && (
          <SlideRightPanel
            path={path}
            allRewards={allRewards}
            slideRewards={rewards}
            onSlideDelete={onSlideDelete}
            onChangeRewardsRequest={() => setIsRewardsModalOpened(true)}
            isQuest={isQuest}
          />
        )
      }
    >
      {!hideControls && (
        <>
          <TransparentTextarea
            maxLength={MAX_LENGTH}
            ref={$titleInputRef}
            placeholder="Enter slide title here..."
            className={styles.title}
            onChange={handleTitleChange}
            value={titleValue === 'Enter slide title here...' ? '' : titleValue}
          />
          {inUpdate && <div>Updating...</div>}
          {error && (
            <InfoBadge type={EInfoBadgeTypes.WARNING} className={styles.infoBadge}>
              {error}
            </InfoBadge>
          )}
          {slideId === NEW_ITEM_ID && (
            <InfoBadge type={EInfoBadgeTypes.WARNING} className={styles.infoBadge}>
              Please fill slide title before edit content
            </InfoBadge>
          )}
        </>
      )}
      {children}
      {isRewardsModalOpened && (
        // <RewardsModal onClose={() => setIsRewardsModalOpened(false)} slideRewards={rewards} allRewards={allRewards} onSubmit={handleRewardsSubmit} />
        <NewRewardsModal
          isOpen={isRewardsModalOpened}
          handleClose={() => setIsRewardsModalOpened(false)}
          slideRewards={rewards}
          onSubmit={handleRewardsSubmit}
        />
      )}
    </SlideContainer>
  );
};

export default CourseConstructorContainer;
