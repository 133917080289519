import walletLinkIcon from 'assets/icons/wallet-auth--link.svg';
import { TemplateButton } from '../authButtons';
import { useEffect } from 'react';
import { useEVMAuth } from 'hooks';

export const WalletAuth = () => {
  const { openModal, handleLogin, isConnected } = useEVMAuth();

  useEffect(() => {
    if (isConnected) {
      handleLogin();
    }
  }, [handleLogin, isConnected]);

  return <TemplateButton icon={<Icon />} title="Connect Wallet" variant="walletAuth" onClick={() => openModal()} />;
};

const Icon = () => (
  <span
    style={{
      color: 'var(--mighty-blue)',
      height: '36px',
      width: '36px',
      display: 'inline-flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '30px',
    }}
  >
    <img src={walletLinkIcon} alt="wallet-link-icon" style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
  </span>
);
