import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
    `
  ),
  Header = styled.h6(
    () => css`
      font-size: 16px;
      line-height: 18px;
      color: var(--font-color-strong);
      margin-bottom: 12px;
      text-transform: capitalize;
    `
  ),
  Items = styled.div<{ $columns: number }>(
    ({ $columns }) => css`
      display: grid;
      grid-template-columns: repeat(${$columns}, 1fr);
      gap: 10px;
      max-height: 312px;
      min-width: fit-content;
      overflow: hidden auto;
    `
  );
