import * as S from './styles';
import chestImg from '../../assets/chest-image.png';

type Props = {
  size?: number;
  containerClassName?: string;
  smallerGlow?: boolean;
};

export const ChestImageAnimated = ({ size = 430, smallerGlow, containerClassName }: Props) => {
  return (
    <S.Container $size={size} className={containerClassName}>
      <S.ChestImage src={chestImg} />
      <S.Glow1 $noAnim={smallerGlow} />
      {!smallerGlow && <S.Glow2 />}
      {/* <S.Token1 /> */}
      {/* <S.Token2 /> */}
      {/* <S.Token3 /> */}
    </S.Container>
  );
};

export default ChestImageAnimated;
