import { MutableRefObject, useCallback, useEffect } from 'react';

export const useMNClickOutside = (callback: () => void, ...$refs: MutableRefObject<HTMLElement | null>[]) => {
  const handleClickOutside = useCallback(
    (evt: MouseEvent) => {
      if (!evt.target) {
        return;
      }

      if ($refs.some((ref) => ref.current?.contains(evt.target as Node) || ref.current === evt.target)) {
        return;
      }

      callback();
    },
    [callback, $refs]
  );

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [callback, $refs]);
};
