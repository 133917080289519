import styled from 'styled-components';
import plusIcon from './assets/plus-darker.svg';
import bgImage from './assets/bg.webp';
import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';

export const Page = styled.div<{ $loading?: boolean }>`
  display: flex;
  flex-direction: column;
  max-width: 1034px;
  position: relative;
  z-index: 1;
  padding: 0 10px 80px 10px;
  transition: transform 0.3s ease-in-out;

  &.partner {
    max-width: unset;

    @media screen and (min-width: 1025px) {
      width: 95%;
      .space-courses-list {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }

  ${(p) => p.$loading && 'overflow: hidden;'}
  @media screen and (min-width: 1620px) {
    margin-left: calc(50vw - (1015px / 2) - 206px);

    &.partner {
      margin-left: 0;
    }

    /* transform: translateX(calc(50vw - (1015px / 2) - 206px)); */
  }
  @media screen and (max-width: 992px) {
    transform: none;
    padding: max(70px, 15vw) 19px 19px;
    width: 100%;
    max-width: 100vw;
    transform: none;
    padding-bottom: 100px;
    margin-top: -31px;
  }
`;

export const PageBg = styled(motion.div)<{ $bg?: string }>`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 240px;
  background-image: url(${(p) => p.$bg || bgImage});
  background-repeat: no-repeat;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0px 0px 10px 10px;
  z-index: -1;
  @media screen and (max-width: 992px) {
    /* top: -30px; */
    aspect-ratio: 5 / 1;
    height: auto;
    min-height: 150px;
  }
`;

export const BackButton = styled.p<{ $theme: THEMES; $mobileColor?: string }>`
  color: ${(p) => (p.$theme === THEMES.DARK ? '#afb4b880' : '#1C1D26')};
  font-size: 18px;
  line-height: 100%;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: translateX(-10px);
  }
  ${(p) =>
    p.$theme === THEMES.LIGHT &&
    `
    & path {
      stroke: #1C1D26;
      stroke-opacity: 1;
    }
  `}
  @media screen and (max-width: 992px) {
    font-size: 0px;
    position: absolute;
    width: 36px;
    height: 36px;
    top: 53px;
    left: 10px;
    margin: 0;
    filter: hue-rotate(60deg);
    & svg {
      width: 100%;
      height: 100%;
    }
    & path {
      stroke: ${(p) => p.$mobileColor || '#fff'};
    }
  }
`;
export const Col = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
  margin-bottom: 48px;
  @media screen and (max-width: 992px) {
    margin-bottom: 22px;
  }
`;

export const Topic = styled.p<{ $theme: THEMES }>`
  font-size: 26px;
  line-height: 100%;
  color: ${(p) => (p.$theme === THEMES.DARK ? '#fdfdfd' : 'rgb(98, 126, 234)')};
  font-weight: 500;
`;

const miniCoursesScroll = `
  width: calc(100% + 40px);
  padding-left: 20px;
  padding-right: 20px;
  margin: 0px -20px;
  overflow-X: auto; 
  
`;

export const CoursesList = styled.div<{ $smaller?: boolean }>`
  display: grid;
  gap: 19px;
  grid-template-columns: ${(p) => (p.$smaller ? '1fr 1fr 1fr 1fr' : '1fr 1fr 1fr')};
  width: 100%;
  @media screen and (max-width: 992px) {
    display: flex;
    ${(p) => !p.$smaller && 'flex-wrap: wrap;'}
    gap: ${(p) => (p.$smaller ? 16 : 22)}px;
    /* flex-direction: ${(p) => (p.$smaller ? 'row' : 'column')}; */
    &::-webkit-scrollbar {
      display: none;
      height: 0;
      width: 0;
    }
    scrollbar-width: none;
    ${(p) => p.$smaller && miniCoursesScroll}
  }
`;

const horizontal = `
  flex-direction: row;
`;

export const AddCourseBox = styled.div<{ $small?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: ${(p) => (p.$small ? '246px' : '332.5px')} !important;
  border-radius: 8px;
  overflow: hidden;
  min-height: ${(p) => (p.$small ? '296px' : '417px')};
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    transform: scale(1.01);
  }
  @media screen and (max-width: 992px) {
    width: ${(p) => (p.$small ? '180px' : '100%')};
    ${(p) => p.$small && 'min-width: 180px;'}
    height: ${(p) => (p.$small ? '220px' : '134px')};
    min-height: ${(p) => (p.$small ? '220px' : '134px')};
    ${(p) => !p.$small && horizontal}
  }
`;

export const AddCourseTop = styled.div<{ $small?: boolean; $theme: THEMES }>`
  backdrop-filter: blur(27.5px);
  background-color: ${(p) => (p.$theme === THEMES.DARK ? 'rgba(255, 255, 255, 0.06)' : '#627EEA1A')};
  border-radius: 8px;
  min-height: ${(p) => (p.$small ? '142px' : '183px')};
  height: ${(p) => (p.$small ? '142px' : '183px')};
  background-image: url(${plusIcon});
  background-position: center;
  background-size: 80px;
  background-repeat: no-repeat;
  z-index: 1;
  position: relative;
  @media screen and (max-width: 992px) {
    min-height: ${(p) => (p.$small ? '90px' : '134px')};
    height: ${(p) => (p.$small ? '90px' : '134px')};
    width: ${(p) => (p.$small ? '100%' : '134px')};
    ${(p) => !p.$small && 'min-width: 134px;'}
  }
`;

export const AddCourseBottom = styled.div<{ $small?: boolean; $theme: THEMES }>`
  flex: 1;
  min-height: calc(100% - ${(p) => (p.$small ? '142px' : '183px')});
  box-sizing: content-box;
  display: flex;
  background: ${(p) => (p.$theme === THEMES.DARK ? '#FFFFFF0F' : '#1111110f')};
  margin-top: -70px;
  backdrop-filter: blur(69.0818px);
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
  padding-top: 70px;
  @media screen and (max-width: 992px) {
    height: ${(p) => (p.$small ? '175px' : '134px')};
    width: 100%;
    padding-top: ${(p) => (p.$small ? '45px' : '0')};
    margin-top: ${(p) => (p.$small ? '-45px' : '0')};
    padding-left: ${(p) => (!p.$small ? '45px' : '0')};
    margin-left: ${(p) => (!p.$small ? '-45px' : '0')};
  }
`;
