import { FC, PropsWithChildren } from 'react';
import { BackButton } from 'components/library/BackButton';

import style from './styles.module.scss';
import { useBackButtonClick } from '../useBackButtonClick';

export const AuthPageViewDesktop: FC<PropsWithChildren> = ({ children }) => {
  const handleBackButtonClick = useBackButtonClick();

  return (
    <div className={style.wrapper}>
      <BackButton className={style.authBackButton} onClick={handleBackButtonClick} text={'Back'} />

      <div className={style.wrap}>{children}</div>
    </div>
  );
};
