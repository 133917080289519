import { FC } from 'react';
import expToken from 'assets/images/exp-token.png';
import { ReactComponent as ClockIcon } from 'assets/icons/clock.svg';
import { CountUpWrap, Percent, ActivationWrap, Time } from '../styled';
import * as Styled from './styled';
import CountUp from 'react-countup';
import { motion } from 'framer-motion';
import { EItemFungibleType } from 'interface';
import { useGetEffectData } from '../../../hooks';

interface IExpReceivedProps {
  isActive?: boolean;
  onEnd?: () => void;
  type: EItemFungibleType.ExpPotion | EItemFungibleType.EnhancedExpPotion | EItemFungibleType.Amethyst | EItemFungibleType.YellowDiamond;
}

export const ExpReceived: FC<IExpReceivedProps> = ({ isActive, onEnd, type }) => {
  const handleEnd = () => {
    onEnd && setTimeout(() => onEnd(), 1000);
  };
  const data = useGetEffectData(type as EItemFungibleType);
  let value = 0,
    forTime = '';

  if (data) {
    [, forTime] = data.desc;
    value = data.value;
  }

  if (isActive) {
    return (
      <CountUpWrap $color={'#95D8F5'}>
        <motion.img animate={{ rotate: '360deg' }} transition={{ duration: 2, delay: 0.3 }} src={expToken} alt={'expToken'} />
        <CountUp duration={2.5} delay={0.3} start={0} end={value} suffix={'%'} prefix={'+'} onEnd={handleEnd} />
      </CountUpWrap>
    );
  }

  return (
    <ActivationWrap>
      <Styled.ExpTokens>
        <img src={expToken} alt={'expToken'} />
        <img src={expToken} alt={'expToken'} />
        <img src={expToken} alt={'expToken'} />
      </Styled.ExpTokens>
      <div>
        <Percent $color={'#95D8F5'} $textShadowColor={'rgba(149, 216, 245, 0.30)'} $textAlign={'start'}>
          +{value}
          <span>%</span>
        </Percent>
        <Time>
          <ClockIcon width={'18px'} height={'18px'} color={'#95D8F5'} />
          <span>{forTime}</span>
        </Time>
      </div>
    </ActivationWrap>
  );
};
