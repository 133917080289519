import clsx from 'clsx';

import styles from './styles.module.css';

type Props = {
  item: any;
  className?: string;
};

export default function CarouselItem({ item, className = '' }: Props) {
  return <div className={clsx(styles.item, { [className]: !!className })}>{item}</div>;
}
