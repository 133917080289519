import Breadcrumbs from 'components/library/breadcrumbs';
import { FC, useState } from 'react';
import clsx from 'clsx';

import { TBreadcrumbChunk } from '../../../../utils/breadcrumbs';

import styles from './styles.module.css';
import { CommentsDrawer } from 'features/CommentsDrawer';
import { DashboardContext } from 'pages/coursesConstructor/parts/dashboard/DashboardContext';
import { CourseDashboard } from 'pages/coursesConstructor/parts/dashboard';

type TLayoutProps = {
  breadcrumbs: TBreadcrumbChunk[];
  title: string;
  children: React.ReactNode;
};

const Layout: FC<TLayoutProps> = ({ breadcrumbs, title, children }) => {
  const [slideId, setSlideId] = useState('');
  const [answersCount, setAnswersCount] = useState(0);
  const [commentsCount, setCommentsCount] = useState(0);
  const [contextTab, setContextTab] = useState<'Answers' | 'Comments' | ''>('');

  return (
    <div className={clsx('mobile-padding', styles.root)}>
      <DashboardContext.Provider
        value={{ setSlideId, slideId, tab: contextTab, setTab: setContextTab, answersCount, setAnswersCount, commentsCount, setCommentsCount }}
      >
        <Breadcrumbs chunks={breadcrumbs} />
        <CommentsDrawer />
        <CourseDashboard />
        <h1>{title}</h1>
        {children}
      </DashboardContext.Provider>
    </div>
  );
};

export default Layout;
