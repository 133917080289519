import React from 'react';
import { useSpaceAnalyticsContext } from '../../context';
import { IAnalyticsCourse } from 'store';
import * as S from './styles';

interface StatCardProps {
  title: string;
  calculateValue: (courses: IAnalyticsCourse[]) => number;
}

const StatCard: React.FC<StatCardProps> = ({ title, calculateValue }) => {
  const { courses } = useSpaceAnalyticsContext();

  const value = calculateValue(courses);

  return (
    <S.Container>
      <S.Header>{title}</S.Header>
      <S.Paragraph>{value.toLocaleString()}</S.Paragraph>
    </S.Container>
  );
};

export const AnalyticsSummary: React.FC = () => {
  const calculateTotalStarted = (courses: IAnalyticsCourse[]) => courses.reduce((sum, course) => sum + course.started, 0);
  const calculateTotalFinished = (courses: IAnalyticsCourse[]) => courses.reduce((sum, course) => sum + course.finished, 0);

  return (
    <S.SummaryContainer>
      <StatCard title="Courses Started" calculateValue={calculateTotalStarted} />
      <StatCard title="Courses Finished" calculateValue={calculateTotalFinished} />
    </S.SummaryContainer>
  );
};
