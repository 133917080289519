import { Container } from './styles';
import premiumIcon from 'assets/images/premium-icon.png';
import premiumIconBig from 'assets/images/premium-icon--big.png';

type Props = {
  width?: string | number | undefined;
  height?: string | number | undefined;
  isBig?: boolean;
  className?: string;
};

export const PremiumIcon = ({ isBig = false, className = '', width, height }: Props) => {
  return (
    <Container className={className} style={{ width, height }}>
      <img src={isBig ? premiumIconBig : premiumIcon} alt="PremiumIcon" />
    </Container>
  );
};
