import { CourseCardProps } from '../CourseCard/types';
import * as S from './style';
import { ReactComponent as PencilIcon } from './icons/pencil-icon.svg';
import { ReactComponent as UnpublishIcon } from './icons/closed-eye-icon.svg';
import { ReactComponent as PublishIcon } from './icons/eye-icon.svg';
import { ReactComponent as TrashIcon } from './icons/trash-icon.svg';
import { ReactComponent as CopyIcon } from './icons/copy.svg';
import { ReactComponent as ConstructorIcon } from './icons/constructor-icon.svg';
import { ReactComponent as ImageIcon } from './icons/uploadImage.svg';
import { ReactComponent as SettingsIcon } from 'assets/icons/settings.svg';
import { coursesConstructorAPI, getCurrentTheme } from 'store';
import { ECourseSpaceVisibility } from 'app/constants';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useDisplay } from 'hooks';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { useSelector } from 'react-redux';

type CardProps = { courseId?: string } & Pick<
  CourseCardProps,
  | 'handleImageUpload'
  | 'handleOpenConfirmationModal'
  | 'handleOpenEditModal'
  | 'handleDuplication'
  | 'isInSpace'
  | 'openEditorInSpace'
  | 'isSpaceAdmin'
  | 'courseData'
>;

interface Props extends CardProps {
  isVisible: boolean;
  handleClose: () => void;
  spaceId?: string;
}

const variants = {
  initial: {
    x: -10,
    opacity: -0.5,
  },
  animate: {
    x: 0,
    opacity: 1,
  },
  exit: {
    x: -20,
    opacity: -0.5,
  },
};

const Tooltip = ({ children }: any) => <S.Tooltip onClick={(e) => e.stopPropagation()}>{children}</S.Tooltip>;

const CourseCardHoverMenu = ({
  handleClose,
  isVisible,
  courseData,
  isInSpace,
  spaceId,
  handleDuplication,
  handleOpenConfirmationModal,
  handleImageUpload,
  handleOpenEditModal,
  openEditorInSpace,
}: Props) => {
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);
  const [toggleSpaceVisibilityStatus, { isLoading: IsTogglingVisibility }] = coursesConstructorAPI.useEditCourseSpaceVisibilityMutation();
  const [spaceVisibility, setSpaceVisibility] = useState(courseData.spaceVisibility === ECourseSpaceVisibility.Visible);

  const prepHandler = (handler: () => void) => () => {
    handler();
    handleClose();
  };

  function handleChangeSpaceVisibility() {
    const { spaceVisibility } = courseData;
    setTimeout(() => {
      if (spaceId) {
        const newVisibility =
          spaceVisibility === ECourseSpaceVisibility.Invisible ? ECourseSpaceVisibility.Visible : ECourseSpaceVisibility.Invisible;
        toggleSpaceVisibilityStatus({ spaceVisibility: newVisibility, spaceId: spaceId, id: courseData._id })
          .unwrap()
          .catch(() => {
            toast.error(<Answer label="You are unauthorized for this..." type="incorrect" />);
          })
          .then(() => setSpaceVisibility((prev) => !prev));
      }
    }, 300);
  }

  if (isMobile) {
    return null;
  }

  return (
    <S.Wrapper>
      <AnimatePresence>
        {isVisible && (
          <S.Container $theme={theme} variants={variants} initial={'initial'} animate={'animate'} exit={'exit'}>
            <S.Button onClick={prepHandler(handleOpenEditModal)}>
              <SettingsIcon className="settings-icon" />
              <Tooltip>Edit Course Info</Tooltip>
            </S.Button>
            {!!openEditorInSpace && (
              <S.Button onClick={prepHandler(openEditorInSpace)}>
                <PencilIcon />
                <Tooltip>Open in constructor</Tooltip>
              </S.Button>
            )}
            <S.Button>
              <ImageIcon />
              <Tooltip>Change banner</Tooltip>
              <S.HiddenInput type="file" onChange={handleImageUpload} />
            </S.Button>
            {isInSpace && spaceId && (
              <S.Button onClick={handleChangeSpaceVisibility}>
                {IsTogglingVisibility ? (
                  <MightyLoaderMini style={{ width: 24, height: 25 }} />
                ) : spaceVisibility ? (
                  <PublishIcon />
                ) : (
                  <UnpublishIcon />
                )}
                <Tooltip> {spaceVisibility ? 'Published' : 'Unpublished'}</Tooltip>
              </S.Button>
            )}
            {!isInSpace && (
              <S.Button onClick={prepHandler(handleDuplication)}>
                <CopyIcon />
                <Tooltip>Duplicate Course</Tooltip>
              </S.Button>
            )}
            <S.Button onClick={prepHandler(handleOpenConfirmationModal)}>
              <TrashIcon />
              <Tooltip>Delete Course</Tooltip>
            </S.Button>
          </S.Container>
        )}
      </AnimatePresence>
    </S.Wrapper>
  );
};

export default CourseCardHoverMenu;
