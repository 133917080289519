import * as Parts from '../parts/styles';
import { useGetCurrentTheme } from 'hooks';
import { CopyButton, DashboardDropdown } from '../parts';
import { ReactNode, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { coursesConstructorAPI, ISpace, useLazyGetFullCourseQuery, useLazyGetSpaceByNameQuery } from 'store';
import { ICourse } from 'interface/courses';
import { ECourseSpaceVisibility } from 'app/constants';
import ResizebleSkeleton from 'shared/ui/course-card/CourseCard/utils/ui/skeletons/resizebleSkeleton';

import { ReactComponent as ShowPassIcon } from 'assets/icons/eye-t1--open.svg';
import { ReactComponent as HidePassIcon } from 'assets/icons/eye-t1--close.svg';
import { ReactComponent as ChainlinkIcon } from 'assets/icons/chainlink.svg';

type TOption = {
  label: string;
  value: string;
  icon?: ReactNode;
};

const OPTIONS: TOption[] = [
  {
    label: 'Public',
    value: 'visible',
    icon: <ShowPassIcon />,
  },
  {
    label: 'Hidden',
    value: 'invisible',
    icon: <HidePassIcon />,
  },
  {
    label: 'Private',
    value: 'private',
    icon: <ChainlinkIcon />,
  },
];

interface IState {
  course?: ICourse;
  space?: ISpace;
  isReady: boolean;
}
const defaultState: IState = {
  course: undefined,
  space: undefined,
  isReady: false,
};

const VisibilityChanger = () => {
  const [spaceInfo, setSpaceInfo] = useState<ISpace | null>(null);
  const theme = useGetCurrentTheme();
  const { lessonId, chapterId, courseId, spaceName = '' } = useParams();
  const id = lessonId ?? chapterId ?? courseId ?? '';
  const spaceNamePrepared = spaceName.replaceAll('_', ' ');

  const [getSpace, { isFetching: isSpaceFetching }] = useLazyGetSpaceByNameQuery();
  const { data: courseInfo, isFetching: isCourseFetching } = coursesConstructorAPI.useGetCourseInfoBySlideIdQuery(id);
  const [{ course, space, isReady }, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), defaultState);
  const [getCourse] = useLazyGetFullCourseQuery();
  const [editVisibility, { isLoading: isActionLoading }] = coursesConstructorAPI.useEditCourseSpaceVisibilityMutation();

  const isLoading = isSpaceFetching || isCourseFetching;

  useEffect(() => {
    if (spaceNamePrepared) {
      getSpace(spaceNamePrepared)
        .unwrap()
        .then((res) => setSpaceInfo(res));
    }
  }, [spaceNamePrepared, getSpace]);

  useEffect(() => {
    (async function () {
      const courseId = courseInfo?._id;
      if (courseId) {
        const course = await getCourse(courseId).unwrap();
        if (course && spaceName && spaceInfo?._id) {
          updateState({ course, space: spaceInfo, isReady: true });
        } else if (course) {
          updateState({ course, isReady: true });
        }
      }
    })();
    return updateState(defaultState);
  }, [courseInfo?._id, spaceInfo?._id, getCourse, spaceName]);

  const handleChange = (value: string) => {
    if (course && !isActionLoading) {
      const prevCourseState = { ...course };
      updateState({ course: { ...course, spaceVisibility: value as ECourseSpaceVisibility } });
      editVisibility({ spaceVisibility: value as ECourseSpaceVisibility, spaceId: space ? space._id : undefined, id: course._id })
        .unwrap()
        .catch((err) => {
          console.error(err);
          updateState({ course: { ...prevCourseState } });
        });
    }
  };

  const visibility = useMemo(() => course?.spaceVisibility || ECourseSpaceVisibility.Invisible, [course]);

  const firstLessonNavLink = useMemo(() => {
    if (!course?._id) {
      return '';
    }
    if (!course.chapters.length || !course.chapters[0].lessons?.length) {
      return `${window.location.origin}/${course?._id}`;
    }
    return `${window.location.origin}/courses/${course?._id}/${course.chapters[0]._id}/${course.chapters[0].lessons[0]._id}`;
  }, [course]);

  if (!isReady || isLoading) {
    return <ResizebleSkeleton $width={'300px'} $height={'44px'} $radius={'8px'} />;
  }

  if (!course || !spaceName) {
    return null;
  }

  const handleCopy = () => course?._id && navigator.clipboard.writeText(firstLessonNavLink);

  return (
    <Parts.ButtonBox>
      <Parts.ButtonLeft $theme={theme}>Course Visibility</Parts.ButtonLeft>
      <DashboardDropdown buttonClassName={'_' + visibility} options={OPTIONS} currentValue={visibility} handleChange={handleChange} />
      <Parts.CopyWrapper>
        <CopyButton handleCopy={handleCopy} />
      </Parts.CopyWrapper>
    </Parts.ButtonBox>
  );
};

export default VisibilityChanger;
