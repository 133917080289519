import { useEffect, useMemo, useRef, useState } from 'react';
import * as S from './styles';
import { REWARDS_BY_REFS, REWARDS_STEPS } from './constants';
import { useDisplay, useGetCurrentTheme, useGetUserData } from 'hooks';

import { ReactComponent as RefIcon } from '../../assets/refIcon.svg';
import { Avatar } from 'components/library/Avatar';
import { getRefTooltipText, useGetRefRewards, useUserPosition } from './hooks';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import chestImgSmall from '../../assets/chest-image-small.png';
import { Logo } from 'shared/utils';

type Props = {
  hideUser?: boolean;
  containerClassName?: string;
  hideArrows?: boolean;
};

const ReferralsProgress = ({ containerClassName, hideArrows, hideUser }: Props) => {
  const theme = useGetCurrentTheme();
  const { isMobile } = useDisplay();
  const { referrals: refsData, avatar, nickname } = useGetUserData();
  const blockRef = useRef<HTMLDivElement | null>(null);
  const trackRef = useRef<HTMLDivElement | null>(null);
  const containerRef = useRef<HTMLDivElement | null>(null);
  const timeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const getRewards = useGetRefRewards();

  const { referralBonus: referrals = 0 } = refsData;

  const { blockOffset, progress, marginBig, marginSmall, defaultTrackPos = 0 } = useUserPosition({ refs: referrals, blockRef });

  const [trackPosition, setTrackPosition] = useState(defaultTrackPos);

  const [userHovered, setUserHovered] = useState(false);

  const isScrolledMax = useMemo(() => {
    if (!trackRef.current || !containerRef.current) {
      return false;
    }

    const trackWidth = trackRef.current.getBoundingClientRect().width || 0;
    const containerWidth = containerRef.current.getBoundingClientRect().width || 0;

    return trackPosition === trackWidth - containerWidth;
  }, [trackRef.current, containerRef.current, trackPosition]);

  const updateTrackPosition = (dir = 1) => {
    if (trackRef.current && containerRef.current) {
      const trackWidth = trackRef.current.getBoundingClientRect().width || 0;
      const containerWidth = containerRef.current.getBoundingClientRect().width || 0;

      const step = dir * containerWidth;

      setTrackPosition((pos) => {
        if (pos + step >= trackWidth - containerWidth) {
          return trackWidth - containerWidth;
        }
        if (pos + step < 0) {
          return 0;
        }

        return pos + step;
      });
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({ left: defaultTrackPos });
    }
  }, [isMobile, defaultTrackPos, containerRef]);

  useEffect(() => {
    setTrackPosition(defaultTrackPos);
  }, [defaultTrackPos]);

  useEffect(() => {
    if (trackPosition !== defaultTrackPos) {
      timeoutRef.current = setTimeout(() => setTrackPosition(defaultTrackPos), 5000);
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [trackPosition, defaultTrackPos]);

  const formedItems = useMemo(
    () =>
      REWARDS_BY_REFS.map((el, i) => {
        const nextRefs = REWARDS_BY_REFS[i + 1]?.referrals || null;
        const isLastActive = el.referrals <= referrals && (!nextRefs || nextRefs > referrals);
        const rewards = getRewards(el.referrals);
        const reward = rewards[0];
        if (!reward) {
          return null;
        }
        return (
          <S.Item key={reward.name + i} $marginRight={isLastActive ? marginBig : marginSmall}>
            <S.RefsNum $active={el.referrals <= referrals} $theme={theme}>
              <RefIcon />
              {el.referrals}
            </S.RefsNum>
            <S.Reward $theme={theme} $active={el.referrals <= referrals}>
              <S.ChestBlock $smaller={hideUser} $active={el.referrals <= referrals}>
                <S.ChestImg src={reward.imageThumb} className={`${el.referrals === 1 ? 'first' : ''}`} />
              </S.ChestBlock>
              <span>{reward.name}</span>
            </S.Reward>
          </S.Item>
        );
      }),
    [referrals, theme, hideUser, getRewards]
  );

  return (
    <>
      <S.Container className={containerClassName}>
        {!isMobile && !hideArrows && (
          <S.Buttons $theme={theme}>
            <S.Button $hide={trackPosition === 0} onClick={() => updateTrackPosition(-1)}>
              <BsChevronLeft style={{ paddingRight: 3 }} />
            </S.Button>
            <S.Button $hide={isScrolledMax} onClick={() => updateTrackPosition()}>
              <BsChevronRight style={{ paddingLeft: 3 }} />
            </S.Button>
          </S.Buttons>
        )}
        <S.Wrapper $noPadding={hideUser} ref={containerRef}>
          <S.Track ref={trackRef} animate={{ x: isMobile ? 0 : trackPosition * -1 }} transition={{ bounce: 0, duration: 0.7, ease: 'easeInOut' }}>
            <S.Line $theme={theme} />
            <S.Progress $width={referrals === 0 ? 0 : progress} />
            {!hideUser && (
              <S.User onMouseEnter={() => setUserHovered(true)} onMouseLeave={() => setUserHovered(false)} $left={progress} $hovered={userHovered}>
                <S.UserBlock
                  $hovered={userHovered}
                  $translateX={blockOffset}
                  ref={blockRef}
                  $theme={theme}
                  $hidden={REWARDS_STEPS.includes(referrals) || referrals === 0}
                >
                  <RefIcon />
                  {referrals} <img src={chestImgSmall} alt="chest" />
                  {referrals}
                </S.UserBlock>
                <S.UserFloat $theme={theme} $active={userHovered}>
                  {!isMobile && referrals < 1000 && (
                    <S.Tooltip $right={referrals >= 800} $hidden={!userHovered} $theme={theme}>
                      {getRefTooltipText(referrals)} <Logo inline height={21} containerClassName="refs-logo" />
                    </S.Tooltip>
                  )}
                  <S.UserFrame />
                  <Avatar userName={nickname} size={51} src={avatar} />
                </S.UserFloat>
              </S.User>
            )}

            {formedItems}
          </S.Track>
        </S.Wrapper>
      </S.Container>
    </>
  );
};

export default ReferralsProgress;
