import clsx from 'clsx';
import { Container } from './styles';
import { NullButton } from '../../button';
import { THEMES } from 'app/constants';

type Props = {
  className?: string;
  onClick(): any;
  label: string;
  isActive?: boolean;
  variant?: 'regular' | 'gold';
  theme?: THEMES;
};

export const Tag = ({ label, onClick, className = '', isActive = false, variant = 'regular', theme }: Props) => {
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    onClick();
  };

  return (
    <Container as={NullButton} onClick={handleClick} $active={isActive} $theme={theme} className={clsx(variant, { [className]: !!className })}>
      {label}
    </Container>
  );
};
