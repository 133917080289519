import { useEffect, useMemo, useRef, useState } from 'react';
import { TUseResizableImage } from './types';
import { useDebounce } from 'hooks';

export const useResizableImage = ({ width, justifyContent, containerRef, onChange }: TUseResizableImage) => {
  const debounce = useDebounce();
  const [widthState, setWidthState] = useState(width);

  const [percentWidth, setPercentWidth] = useState(0);
  const [isDragging, setIsDragging] = useState(false);
  const [position, setPosition] = useState(justifyContent);

  const startX = useRef<any>(null!); // Начальная позиция X при перетаскивании
  const startWidth = useRef<any>(null!); // Начальная ширина при перетаскивании

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const containerWidth = containerRef.current.clientWidth;
    const currentWidth = containerWidth * (width / 100);
    setWidthState(Math.min(Math.max(currentWidth, 100), containerWidth));
  }, [width, containerRef]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const containerWidth = containerRef.current.clientWidth;
    setPercentWidth(Math.round((widthState / containerWidth) * 100));
  }, [widthState, containerRef]);

  useEffect(() => {
    if (!containerRef.current) {
      return;
    }
    const containerWidth = containerRef.current.clientWidth;
    if (position !== justifyContent) {
      updateImageSetup(Math.round((widthState / containerWidth) * 100).toString(), position);
    }
  }, [position, justifyContent, containerRef, widthState]);

  const handleDragStart = (e: any) => {
    setIsDragging(true);
    startX.current = e.clientX;
    startWidth.current = widthState;
  };

  const handleDrag = useMemo(
    () =>
      debounce((e: any) => {
        if (startX.current !== null && e.clientX !== 0) {
          const diffX = e.clientX - startX.current;
          const newWidth = startWidth.current + diffX;

          const containerWidth = containerRef.current!.clientWidth;

          setWidthState(Math.min(Math.max(newWidth, 100), containerWidth));
        }
      }, 0),
    [containerRef]
  );

  const handleDragEnd = () => {
    setIsDragging(false);
    startX.current = null;
    startWidth.current = null;

    if (percentWidth !== width) {
      updateImageSetup(percentWidth.toString(), position);
    }
  };

  function updateImageSetup(width: string, align: string) {
    return onChange({ width, align });
  }

  return {
    widthState,
    position,
    percentWidth,
    setPosition,
    handleDragStart,
    handleDrag,
    handleDragEnd,
    isDragging,
  };
};
