import { useReducer } from 'react';
import clsx from 'clsx';
import { EMenuScreens } from 'app/constants';
import { createPortal } from 'react-dom';
import { useSelector } from 'react-redux';
import { getActiveUserMenuPanel, setActiveUserMenuPanel } from 'store';
import useClickOutside from 'hooks/useClickOutside';
import { dispatch, useDisplay, useGetUserData, useLogout } from 'hooks';
import { IProps, IState, TPanels } from './types';

import { BsChevronDown } from 'react-icons/bs';

import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { EffectsPanel, MainPanel } from './panels';

import styles from './styles.module.css';

const defaultState: IState = {
  addressCopy: false,
};

const UserMenu = ({ setActiveAndShow }: IProps) => {
  const logOutHandler = useLogout();
  const { isAddressActivated: isUserAddressActivated, customization, nickname, avatar, address } = useGetUserData(true);
  const { isMobile } = useDisplay();
  const activeUserMenuPanel = useSelector(getActiveUserMenuPanel);
  // const { isPremium } = useGetIsPremium();
  const [state, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), {
    ...defaultState,
  });

  const { addressCopy } = state;

  const domNode = useClickOutside<HTMLDivElement>(handleClickOutside);

  if (isMobile) {
    return null;
  }

  function handleClickOutside() {
    if (activeUserMenuPanel !== 'none') {
      updateState(defaultState);
      dispatch(setActiveUserMenuPanel('none'));
    }
  }

  const handleLogOut = () => {
    updateState(defaultState);
    dispatch(setActiveUserMenuPanel('none'));
    logOutHandler();
  };

  function handleAddressCopy() {
    if (isUserAddressActivated && !addressCopy) {
      navigator.clipboard.writeText(address);
      updateState({ addressCopy: true });
      setTimeout(() => {
        updateState({ addressCopy: false });
      }, 1300);
    }
  }

  const handleMainMenu = (screen: EMenuScreens) => () => {
    setActiveAndShow(screen);
    handleClickOutside();
  };

  const handlePanel = (value: TPanels) => () => dispatch(setActiveUserMenuPanel(value));
  return (
    <>
      <div className={clsx(styles.container, { [styles.shown]: activeUserMenuPanel === 'none' })} role="button" onClick={handlePanel('main')}>
        <AvatarPic
          smallGlow
          className={styles.avatar}
          customization={customization}
          nickname={nickname}
          avatar={avatar}
          //  isFramed={isPremium}
        />
        <BsChevronDown className={clsx(styles.toggleBtn, { [styles.open]: activeUserMenuPanel !== 'none' })} strokeWidth={1} />
      </div>
      {createPortal(
        <div ref={domNode}>
          <MainPanel
            className={clsx(styles.page, styles.menu, { [styles.open]: activeUserMenuPanel === 'main' })}
            handleMainToEffects={handlePanel('effects')}
            handleMainMenu={handleMainMenu}
            handleAddressCopy={handleAddressCopy}
            handleLogOut={handleLogOut}
            isAddressActivated={isUserAddressActivated}
            addressCopy={addressCopy}
          />
          <EffectsPanel
            className={clsx(styles.page, styles.menu, { [styles.open]: activeUserMenuPanel === 'effects' })}
            handleGoToMain={handlePanel('main')}
          />
        </div>,
        document.getElementById('portal')!
      )}
    </>
  );
};

export default UserMenu;
