import { FC, PropsWithChildren, useEffect, useState } from 'react';

interface IProps extends PropsWithChildren {
  delay: number;
}

export const DelayedComponent: FC<IProps> = ({ delay, children }) => {
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(true);
    }, delay);
    return () => clearTimeout(timer);
  }, [delay]);

  if (!visible) {
    return null;
  }

  return <>{children}</>;
};
