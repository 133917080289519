import { FC } from 'react';
import { useDisplay, useGetAuthMethodsData } from 'hooks';
import { emailTruncate } from 'utils/string';
import { IOnetimerComponent, OnetimeNotificationPremium } from '../types';
import clsx from 'clsx';

import { ReactComponent as ModalBg } from './assets/bg.svg';
import premiumImage from './assets/premium-image.png';

import { MobileModal } from 'shared/utils/mobile';
import BasicButton from 'shared/ui/basic-button/button';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';

import * as Styled from './styled';

interface Props extends OnetimeNotificationPremium, IOnetimerComponent {}

const handleSeeTx = (tx: string) => () => {
  console.log({ tx });

  window.open(tx);
};

export const PremiumCongratulation: FC<Props> = ({ onClose, txHash }) => {
  const { getEmail: getIEmail } = useGetAuthMethodsData();
  const email = getIEmail() ?? '';
  const { isMobile } = useDisplay();

  return isMobile ? (
    <MobileModal isOpen onClose={() => {}}>
      <Styled.Container>
        <img src={premiumImage} alt="premium" className={clsx('premium')} />

        <Styled.Content>
          <Styled.Title $fontSize={'32px'}>{'Congratulations!'}</Styled.Title>
          <Styled.Text>
            You have successfully purchased premium account. We have emailed you the receipt to{' '}
            <Styled.Mark $color={'var(--mighty-blue)'}>{emailTruncate(email)}</Styled.Mark>
          </Styled.Text>
          <BasicButton label={'Continue'} variant={'cool'} className={'button'} onClick={onClose} />
          <Styled.See role="button" onClick={handleSeeTx(txHash)}>
            See on Explorer
          </Styled.See>
        </Styled.Content>
      </Styled.Container>
    </MobileModal>
  ) : (
    <CenteredModal show isClosing={false}>
      <Styled.Container>
        <ModalBg className={clsx('background')} />

        <img src={premiumImage} alt="premium" className={clsx('premium')} />

        <Styled.Content>
          <Styled.Title $fontSize={isMobile ? '32px' : '48px'}>{'Congratulations!'}</Styled.Title>
          <Styled.Text $maxWidth={'420px'}>
            You have successfully purchased premium account. We have emailed you the receipt to{' '}
            <Styled.Mark $color={'var(--mighty-blue)'}>{emailTruncate(email)}</Styled.Mark>
          </Styled.Text>
          <BasicButton label={'Continue'} variant={'cool'} className={'button'} onClick={onClose} />
          <Styled.See role="button" onClick={handleSeeTx(txHash)}>
            See on Explorer
          </Styled.See>
        </Styled.Content>
      </Styled.Container>
    </CenteredModal>
  );
};
