import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Controller, useForm } from 'react-hook-form';
import { FC, useEffect } from 'react';
import * as yup from 'yup';

import TransparentTextarea from 'components/library/transparentTextarea';
import { TLessonCardProps } from './types';

import InfoBadge from 'components/library/infoBadge';
import { EInfoBadgeTypes } from 'components/library/infoBadge/types';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import BasicButton from 'shared/ui/basic-button/button';

import * as S from './styles';
import LockLabel from 'pages/coursesConstructor/parts/chaptersList/components/lock';
import { useGetCurrentTheme } from 'hooks';
import { THEMES } from 'app/constants';

const LessonCard: FC<TLessonCardProps> = ({
  id,
  name,
  lessonIdx,
  isInEditMode,
  isLoading,
  onChange,
  onDelete,
  onIsInEditModeChange,
  onEditSlidesRequest,
  dependencies,
}) => {
  const theme = useGetCurrentTheme();
  const schema = yup.object().shape({
    name: yup.string().min(2, 'The minimum number of characters in the name is 2').max(50, 'The minimum number of characters in the name is 60'),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset: resetForm,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    values: { name },
  });

  useEffect(() => {
    if (!isInEditMode) {
      resetForm();
    }
  }, [isInEditMode, resetForm]);
  // USE ChapterCard AS ATCHITECTURE REFFERENCE

  const handleSeeSlides = () => {
    if (isInEditMode) {
      return;
    }
    onEditSlidesRequest();
  };

  return (
    <S.Wrapper>
      <S.Root $theme={theme} as="form" onSubmit={handleSubmit(onChange)} onClick={handleSeeSlides} id={`form:${id}`}>
        <S.LockWrap>
          <LockLabel count={dependencies?.length} />
          Lesson {lessonIdx}
        </S.LockWrap>
        <S.Bottom>
          <S.Name>
            {isInEditMode ? (
              <Controller
                name="name"
                control={control}
                render={({ field }) => (
                  <TransparentTextarea minLength={2} maxLength={50} as={S.Control} {...field} placeholder="Enter a new lesson name..." />
                )}
              />
            ) : (
              <S.NameLabel title={name}>{name}</S.NameLabel>
            )}
          </S.Name>
          {!isInEditMode && (
            <S.SeeItem role="button">
              <span>See Slides</span> <ArrowRight />
            </S.SeeItem>
          )}
        </S.Bottom>
      </S.Root>
      {isInEditMode && (
        <S.Controls>
          <BasicButton size="sm" onClick={onIsInEditModeChange} style={{color: theme === THEMES.LIGHT ? '#111' : undefined}} variant="outline" label="Cancel" />
          <BasicButton type="submit" form={`form:${id}`} size="sm" variant="cool" label="Save" isDisabled={!isValid || isLoading} />
        </S.Controls>
      )}
      {errors.name && (
        <S.ErrorMessage>
          <InfoBadge type={EInfoBadgeTypes.WARNING}>&nbsp;{errors.name.message}</InfoBadge>
        </S.ErrorMessage>
      )}
    </S.Wrapper>
  );
};

export default LessonCard;
