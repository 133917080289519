export const COLORS = [
  { id: 1, color: '#FDFDFD' },
  { id: 2, color: '#29AB88' },
  { id: 4, color: '#95D8F5' },
  { id: 3, color: '#FFBF66' },
  { id: 6, color: '#CA47CE' },
  { id: 2, color: '#F14F63' },
];

export enum ESpecialIcons {
  YOUTUBE = 'Youtube',
  TWITTER = 'Twitter',
  DISCORD = 'Discord',
  INSTAGRAM = 'Instagram',
  FACEBOOK = 'Facebook',
  TELEGRAM = 'Telegram',
  MAIL = 'Mail',
  WEBSITE = 'Website',
  // on-chain
  TON = 'TON',
}

export enum ETask {
  TONBridgeBSC = 'TON_bridgeBSC',
  TONBridgeETH = 'TON_bridgeETH',
  TONDeposit = 'TON_deposit',
}

export enum EIcons {
  HEART = 'Heart',
  STAR = 'Star',
  TIMER = 'Timer',
  GAMEPAD = 'Gamepad',
  PLANET = 'Planet',
  GAUNTLET = 'Gauntlet',
  INFO = 'Info',
  PASSWORD = 'Password',
  CHECKBOX = 'Checkbox',
  CODE = 'Code',
  ANALYTICS = 'Analytics',
  COMPASS = 'Compass',
  MESSAGE = 'Message',
  CHARTBOX = 'Chartbox',
  LOCK = 'Lock',
  MICROPHONE = 'Microphone',
  WALLET = 'Wallet',
  PICTURE = 'Picture',
  QUOTE = 'Quote',
}

export const SOCIALS = [
  {
    id: 1,
    color: '#FF0209',
    icon: ESpecialIcons.YOUTUBE,
  },
  {
    id: 2,
    color: '#1D9BF0',
    icon: ESpecialIcons.TWITTER,
  },
  {
    id: 3,
    color: '#5765F2',
    icon: ESpecialIcons.DISCORD,
  },
  {
    id: 4,
    color: '#E226A8',
    icon: ESpecialIcons.INSTAGRAM,
  },
  {
    id: 5,
    color: '#1877F2',
    icon: ESpecialIcons.FACEBOOK,
  },
  {
    id: 6,
    color: '#29A9EB',
    icon: ESpecialIcons.TELEGRAM,
  },
  {
    id: 7,
    color: '#FFFFFF',
    icon: ESpecialIcons.MAIL,
  },

  {
    id: 8,
    color: '#FFFFFF',
    icon: ESpecialIcons.WEBSITE,
  },
];
