export type TBackendBreadcrumbChunk = {
  name: string;
  id: string;
};

export type TBackendBreadcrumbs = {
  course: TBackendBreadcrumbChunk;
  chapter: TBackendBreadcrumbChunk;
  lesson: TBackendBreadcrumbChunk;
};

export type TBreadcrumbChunk = {
  path?: string;
  label: string;
};

export const buildBreadcrumbs = (backendBreadcrumbs: TBackendBreadcrumbs, spaceName = '') => {
  if (!backendBreadcrumbs) {
    return [];
  }

  const breadcrumbs: TBreadcrumbChunk[] = [];

  const { course, chapter, lesson } = backendBreadcrumbs;

  if (course) {
    {
      !spaceName && breadcrumbs.push({ label: 'Courses', path: `/courses/edit` });
    }

    breadcrumbs.push({
      path: !!spaceName ? `/${spaceName}/course/${course.id}` : `/courses/edit/course/${course.id}`,
      label: course.name,
    });
  }

  // if (chapter) {
  //   breadcrumbs.push({ label: 'Chapters', path: !!spaceName ? `/${spaceName}/course/${course.id}` : `/courses/edit/course/${course.id}` });

  //   breadcrumbs.push({
  //     path: !!spaceName ? `/${spaceName}/chapter/${chapter.id}` : `/courses/edit/chapter/${chapter.id}`,
  //     label: chapter.name,
  //   });
  // }

  if (lesson) {
    // breadcrumbs.push({ label: 'Lessons', path: !!spaceName ? `/${spaceName}/course/${course.id}` : `/courses/edit/course/${course.id}` });

    breadcrumbs.push({
      path: !!spaceName ? `/${spaceName}/lesson/${lesson.id}` : `/courses/edit/lessons/${lesson.id}`,
      label: lesson.name,
    });
  }

  return breadcrumbs;
};
