import { ESlideTypes } from 'store';

const getSubtitle = (typeOfSlide) => {
  let sub = 'choose right answers';

  if (typeOfSlide === ESlideTypes.FILLSPACES) sub = 'fill in the gaps';
  if (typeOfSlide === ESlideTypes.TYPEANSWER) sub = 'input your answer';
  if (typeOfSlide === ESlideTypes.FILLBLOCKS) sub = 'place blocks in a right order';
  if (typeOfSlide === ESlideTypes.MULTICHOICE) sub = 'choose right answer(s)';

  return sub;
};

export default getSubtitle;
