import clsx from 'clsx';

import { Badge } from './badge';

import * as Styled from './styles';
import { TExpEffectWidgetDataBadge } from 'shared/ui/effect-indicator/types';

interface Props {
  items: TExpEffectWidgetDataBadge[];
  className?: string;
}

export const Badges = ({ items, className = '' }: Props) => {
  return (
    <Styled.Badges className={clsx({ [className]: !!className })}>
      {items.map((item, index) => (
        <Badge data={item} key={item.type + index} />
      ))}
    </Styled.Badges>
  );
};
