import styled, { css } from 'styled-components';

export const Container = styled.div<{ $active: boolean }>(
    ({ $active }) => css`
      margin: -125px auto -37px;
      width: fit-content;
      pointer-events: none;
      height: ${$active && '430px'};

      @media screen and (max-width: 992px) {
        margin: -145px auto -60px;
      }
    `
  ),
  ImageWrap = styled.div(
    () => css`
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .activate-item__undercolor {
        position: absolute;
        z-index: -1;
      }
    `
  ),
  Image = styled.img(
    () => css`
      height: 298px;
      width: 298px;

      @media screen and (max-width: 992px) {
        transform: scale(0.82);
      }
    `
  ),
  Bubble = styled.span<{ $size: number; $position: { top: number; left: number }; $color: string }>(
    ({ $size, $position, $color }) => css`
      background: ${$color};
      width: ${`${$size}px`};
      height: ${`${$size}px`};
      border-radius: 100%;
      position: absolute;
      top: ${`${$position.top}px`};
      left: ${`${$position.left}px`};
    `
  );
