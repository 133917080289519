import styled from 'styled-components';
import bg from 'assets/backgrounds/main--backgrounds/spaces/space-creation-bg.webp';
import errIcon from '../assets/error.svg';
import pencilIcon from '../assets/penicl.svg';
import cameraIcon from '../assets/camera.svg';
import chevron from '../assets/chevron-left.svg';
import { motion } from 'framer-motion';

export const Page = styled.div`
  position: fixed;
  max-height: 100vh;
  height: 100%;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1000;
  background-image: url(${bg});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-sizing: border-box;
  gap: 60px;
  &::after {
    position: absolute;
    width: 173px;
    height: 88px;
    left: 50%;
    top: 67%;
    transform: translate(-50%, -50%);
    background: linear-gradient(102.24deg, #627eea 7.22%, #7598ff 66.78%);
    filter: blur(98.5966px);
    border-radius: 910.123px;
    content: '';
    display: block;
    z-index: 0;
    pointer-events: none;
  }
  @media screen and (max-width: 992px) {
    justify-content: space-between;
    padding: 71px 20px 116px;
    overflow-y: scroll;
    &::after {
      display: none;
    }
  }
`;

export const Stage = styled(motion.div)`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 490px;
  @media screen and (max-width: 992px) {
    min-height: auto;
    width: 100%;
  }
`;

export const Title = styled.h2`
  font-size: 57px;
  color: #ffffff;
  position: relative;
  line-height: 64.98px;
  display: flex;
  align-items: center;
  text-align: center;
  gap: 8px;
  &:last-of-type {
    margin-bottom: 25px;
  }
  &:first-of-type {
    margin-top: auto;
  }
  @media screen and (max-width: 992px) {
    font-size: 36px;
    line-height: 41.04px;
    & > div {
      width: 31px;
      height: 31px;
    }
  }
`;

export const Form = styled.form<{ $maw?: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 364px;
  max-width: ${(p) => (p.$maw ? p.$maw : 'none')};
  @media screen and (max-width: 992px) {
    max-width: none;
    min-width: auto;
    width: 100%;
  }
`;

export const Col = styled.div<{ $error?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  margin-bottom: ${(p) => (p.$error ? '27px' : '0px')};
  transition: 0.3s;
`;

export const Error = styled.p<{ $error?: boolean; $center?: boolean; $posStatic?: boolean }>`
  display: flex;
  transition: 0.3s;
  opacity: ${(p) => (p.$error ? 1 : 0)};
  pointer-events: ${(p) => (p.$error ? 'none' : 'all')};
  color: #f14f63;
  white-space: nowrap;
  gap: 6px;
  align-items: center;
  font-size: 16px;
  font-weight: 400;
  position: ${(p) => (p.$posStatic ? 'static' : 'absolute')};
  bottom: -25px;
  ${(p) => p.$center && 'justify-content: center;'}
  width: 100%;
  &::before {
    display: block;
    content: '';
    width: 20px;
    height: 20px;
    background-image: url(${errIcon});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const Label = styled.label`
  color: #ffffff;
  font-size: 18px;
  line-height: 19.8px;
  font-weight: 400;
  margin-bottom: 12px;
`;

export const Input = styled.input<{ error?: boolean }>`
  border: none;
  outline: none;
  width: 100%;
  height: 60px;
  border-radius: 4px;
  padding: 0px 20px;
  background: #ffffff1c;
  color: white;
  font-size: 18px;
  ${(p) => (p.error ? 'border: 1px solid #F14F63; position: relative' : '')}
  border: 1px solid transparent;
  transition: 0.3s;
`;

const pencilStuff = `&::placeholder {
    color: #FFFFFF;
    opacity: 0.4;
}
&:focus {
    text-indent: 0px;
    background-position: -30px 20px;
}`;

export const TextArea = styled.textarea<{ $error?: boolean; $noPencil?: boolean }>`
  border: 1px solid transparent;
  transition: 0.3s;
  width: 100%;
  height: 100px;
  padding: 20px;
  background: #ffffff1c;
  background-image: url(${pencilIcon});
  background-position: 21px 20px;
  background-repeat: no-repeat;
  text-indent: 30px;
  border: none;
  outline: none;
  border-radius: 4px;
  color: white;
  transition: 0.3s;
  resize: vertical;
  ${(p) => (p.$error ? 'border: 1px solid #F14F63;' : '')}
  ${(p) => !p.$noPencil && pencilStuff}
`;

export const BioExample = styled.p`
  font-size: 16px;
  line-height: 20px;
  color: #ffffff66;
  margin-top: 12px;
  & > span {
    color: #627eea;
  }
`;

export const Button = styled.button<{ $isOutside?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(90deg, rgba(100, 135, 239, 0.4) 0%, rgba(83, 37, 155, 0.4) 125.56%),
    linear-gradient(281.94deg, #627eea -0.2%, rgba(160, 180, 255, 0.19) 98.72%), linear-gradient(284.5deg, #627eea 4.22%, #b3c2ff 145.28%);
  font-size: 20px;
  font-weight: 500;
  color: white;
  padding: 0px 36px;
  height: 56px;
  min-height: 56px;
  border: none;
  outline: none;
  border-radius: 300px;
  width: fit-content;
  margin-top: 32px;
  transition: 0.3s;
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    filter: brightness(1.1);
  }
`;

export const SpaceGradientButton = Button;

export const AvatarPicker = styled.div<{ $error?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff33;
  border-radius: 50%;
  border: 1px solid ${(p) => (p.$error ? '#F14F63' : 'transparent')};
  transition: 0.3s;
  margin-bottom: 14px;
  cursor: pointer;
  overflow: hidden;
  width: 190px;
  height: 190px;
  position: relative;
  background-image: url(${cameraIcon});
  background-repeat: no-repeat;
  background-size: 50px 50px;
  background-position: center;
  transition: 0.2s;
  &:hover {
    opacity: 0.8;
  }
`;

export const AvatarImage = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
export const HiddenImageInput = styled.input`
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;
export const SpaceName = styled.p`
  font-size: 30px;
  line-height: 100%;
  color: white;
  text-align: center;
  font-weight: 500;
  text-transform: capitalize;
`;

export const BackButton = styled.p`
  font-size: 18px;
  line-height: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  left: 100px;
  top: 40px;
  z-index: 1;
  color: #afb4b8;
  transition: 0.2s;
  cursor: pointer;
  &:hover {
    opacity: 0.9;
  }
  &::before {
    content: '';
    display: block;
    width: 22px;
    height: 22px;
    background-image: url(${chevron});
    background-size: 100% 100%;
    background-repeat: no-repeat;
    & > path {
      stroke: #afb4b8;
    }
  }
  @media screen and (max-width: 992px) {
    display: none;
  }
`;
