import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      align-items: center;
      gap: 30px;
      box-shadow: 10px 10px 15px rgba(28, 29, 38, 0.35);
      background: var(--glass-dark);
      width: 100%;
      height: 142px;
      padding: 19px 25px;
      border-radius: 10px;

      &.light {
        background: var(--glass-light);
      }

      @media screen and (min-width: 1539px) {
        flex-direction: column;
        justify-content: center;
        padding: 40px;
        height: 250px;
      }
    `
  ),
  Image = styled.div(
    () => css`
      width: 60px;
      min-width: 60px;

      @media screen and (min-width: 1539px) {
        width: 100px;
        min-width: 100px;
      }
    `
  ),
  Text = styled.p(
    () => css`
      font-size: 25px;

      @media screen and (min-width: 1539px) {
        margin: 0;
        font-size: 30px;
        text-align: center;
      }
    `
  );
