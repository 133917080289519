export enum LANGS {
  RU = 'ru',
  EN = 'en',
}

export enum THEMES {
  DARK = 'dark',
  LIGHT = 'light',
}

export enum LOCAL_STORAGE_KEYS {
  THEME = 'theme',
  LANGUAGE = 'i18nextLng',
  AVATAR_SHAPE = 'avatarShape',
  BANNERS = 'banners',
  DEV = 'dev',
  ISPREMIUM = 'isPremium',
  SIGNUP_TEMP = 'signup_temp',
  LESSON_PAGE_VOLUME = 'volume_lesson_page',
  INSTALL_APP_CD = 'install_app_cd',
}

export type BANNERS_ENTITY = Record<string, number>;

export type TMenuScreens = 'Inventory' | 'Profile' | 'Settings' | 'Design';

export enum EPortals {
  FULLSCREEN = 'fullscreen-portal',
  DRAWER = 'drawer-portal',
  PORTAL = 'portal',
  LOADING = 'loading-portal',
  // for anything that should be above all other portals
  SUPREME = 'supreme-portal',
}

export enum EMenuScreens {
  INVENTORY = 'Inventory',
  PROFILE = 'Profile',
  SETTINGS = 'Settings',
  DESIGN = 'Design',
  SPACES = 'Spaces',
}

export enum STATIC_PATHS {
  GENERAL = '/general',
  ITEMS_IMAGES = '/items',
  COURSES_IMAGES = '/images',
}

if (!global.structuredClone) {
  global.structuredClone = function structuredClone(objectToClone: any) {
    const stringified = JSON.stringify(objectToClone);
    const parsed = JSON.parse(stringified);
    return parsed;
  };
}

export const RENEWAL_TIME_UTC = 5; // 6AM for CET
export const RENEWAL_TIME_CET = RENEWAL_TIME_UTC + 1; // 6AM for CET

export enum ELinks {
  DISCORD = 'https://discord.gg/7cdjn7QZ6M',
  MEDIUM = 'https://medium.com/@mighty_study',
  TWITTER = 'https://twitter.com/mighty_study',
  YOUTUBE = 'https://www.youtube.com/@Mighty_Study',
  LANDING = 'https://mighty.study',
  INSTAGRAM = 'https://www.instagram.com/mighty_study/',
  TELEGRAM = 'https://t.me/+prYpQkt681djZWFi',
}

export const RESIGNUP_MESSAGE = 'resignup_required';

export enum ELogoSizes {
  XSmall = 'xsmall',
  Small = 'small',
  Regular = 'regular',
  Large = 'large',
  XLarge = 'xlarge',
}

export const LOGOS_SIZES = {
  [ELogoSizes.XSmall]: 50,
  [ELogoSizes.Small]: 125,
  [ELogoSizes.Regular]: 250,
  [ELogoSizes.Large]: 375,
  [ELogoSizes.XLarge]: 500,
};

export const persistRootKeyPrefix = 'root';
