import { GRADES } from 'app/constants';
import { EItemFungiblePremiumType, EServiceEffect, TItemFungibleTypeForEffects } from 'interface';
import { useSelector } from 'react-redux';
import { getItems } from 'store';

import lightning from 'assets/icons/lightning--white.svg';
import premium from 'assets/icons/premium.png';
import noPremium from 'assets/images/no-premium-icon.png';

const efs = {
  [GRADES.COMMON]: 'rgb(125, 125, 125)',
  [GRADES.LEGENDARY]: 'rgb(240, 200, 137)',
  [GRADES.UNCOMMON]: 'rgb(41, 171, 136)',
  [GRADES.MYTHICAL]: 'rgb(241, 79, 99)',
  [GRADES.RARE]: '#95D8F5',
  [GRADES.EPIC]: 'rgb(202, 71, 206)',
  [GRADES.DIVINE]: 'rgb(124, 214, 234)',
};

export const useGetEffectsColorData = (fungibleType: TItemFungibleTypeForEffects): Record<string, any> => {
  const items = useSelector(getItems);

  if (
    ![
      EServiceEffect.ENERGY,
      EServiceEffect.NOENERGY,
      EServiceEffect.NOPREMIUM,
      EItemFungiblePremiumType.PremiumOneDay,
      EItemFungiblePremiumType.PremiumOneWeek,
      EItemFungiblePremiumType.PremiumOneMonth,
      EItemFungiblePremiumType.PremiumOneYear,
    ].includes(fungibleType as EServiceEffect)
  ) {
    const { grade = 'common', imageThumb = '' } = items.find((i) => i.fungibleType === fungibleType)!;

    return { color: efs[grade], image: imageThumb, grade };
  }

  const data = {
    [EItemFungiblePremiumType.PremiumOneDay]: {
      image: premium,
      color: efs[GRADES.LEGENDARY],
    },
    [EItemFungiblePremiumType.PremiumOneWeek]: {
      image: premium,
      color: efs[GRADES.LEGENDARY],
    },
    [EItemFungiblePremiumType.PremiumOneMonth]: {
      image: premium,
      color: efs[GRADES.LEGENDARY],
    },
    [EItemFungiblePremiumType.PremiumOneYear]: {
      image: premium,
      color: efs[GRADES.LEGENDARY],
    },
    [EServiceEffect.ENERGY]: {
      image: lightning,
      color: efs[GRADES.UNCOMMON],
    },
    [EServiceEffect.NOENERGY]: {
      image: lightning,
      color: efs[GRADES.MYTHICAL],
    },
    [EServiceEffect.NOPREMIUM]: {
      image: noPremium,
      color: efs[GRADES.LEGENDARY],
    },
  };
  // TODO zalupa.
  // @ts-ignore
  return data[fungibleType];
};
