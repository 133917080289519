import { ReactComponent as Search } from 'assets/icons/search.svg';
import { FaSpinner } from 'react-icons/fa';
import { IoMdClose } from 'react-icons/io';

import './sb-small.css';

export default function SearchbarSmall({ input, handleInput, clearInput, placeholder, loading, autoFocus }) {
  const theme = localStorage.getItem('theme');

  return (
    <div className="searchbar --small">
      <label htmlFor="">
        {loading ? <FaSpinner className="search-icon spinner" /> : <Search className="search-icon" />}
        <input
          type="text"
          className={`glass-div basic ${theme}`}
          placeholder={placeholder}
          autoFocus={autoFocus}
          value={input}
          onChange={handleInput}
        />
        {input && <IoMdClose size={22} strokeWidth={0.5} className="search-icon close" role={'button'} onClick={clearInput} />}
      </label>
    </div>
  );
}
