import * as S from './styles';
import * as SMini from '../../../CourseCardMini/styles';
import { forwardRef } from 'react';
import clsx from 'clsx';

type Props = {
  isMini?: boolean;
  isMobile?: boolean;
};

export const CourseCardSkeleton = forwardRef<HTMLDivElement, Props>(({ isMini = false, isMobile = false }, ref) => {
  return (
    <SMini.Container className={clsx({ mobile: isMobile })} ref={ref}>
      <S.Container />
    </SMini.Container>
  );
});
