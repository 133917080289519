import { useEffect, useState, useRef } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';

import { ITooltipSmallProps } from './types';

import styles from './styles.module.css';

export const TooltipSmall: React.FC<ITooltipSmallProps> = ({ className = '', delay = 400, direction = 'top', text, children, isShow = true }) => {
  const theme = useSelector(getCurrentTheme);
  const [active, setActive] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    const hideTip = () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      setActive(false);
    };

    document.addEventListener('wheel', hideTip);
    document.addEventListener('scroll', hideTip);

    return () => {
      document.removeEventListener('wheel', hideTip);
      document.removeEventListener('scroll', hideTip);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const showTip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setActive(true);
    }, delay);
  };

  const hideTip = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setActive(false);
  };

  if (!isShow) return <>{children}</>;

  return (
    <div className={clsx(styles.container, className)} onMouseEnter={showTip} onMouseLeave={hideTip}>
      {children}
      {active && <div className={clsx(styles.tooltip, styles[theme], styles[direction], 'ttbody')}>{text}</div>}
    </div>
  );
};
