import { TItemFungibleTypeForEffects } from 'interface';

import * as Styled from './styles';
import { data } from './data';

type Props = {
  type: TItemFungibleTypeForEffects | null;
  isBadge?: boolean;
};

export const HoverData = ({ type = null, ...props }: Props) => {
  if (!type) {
    return null;
  }

  const Item = data[type];

  if (!Item) {
    return null;
  }

  return (
    <Styled.Container>
      <Item {...props} />
    </Styled.Container>
  );
};
