import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import styles from './styles.module.css';
import { ReactComponent as MailIcon } from 'assets/components/mail.svg';
import { Error } from '../../../ui';
import { ISendCodeForm } from 'interface';
import { BasicButtonV1, Input } from 'shared/ui';

interface IProps {
  error?: string;
  isLoading: boolean;
  onSubmitHandler: (data: ISendCodeForm) => void;
}

export const SendCodeForm = ({ isLoading, error, onSubmitHandler }: IProps) => {
  const { t } = useTranslation(['auth', 'common']);
  const schema = yup.object().shape({
    email: yup.string().email(t('common:formErrors.email')).required(t('common:formErrors.requiredEmail')),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<ISendCodeForm>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const onSubmit = (data: ISendCodeForm) => {
    onSubmitHandler(data);
  };

  return (
    <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="email"
        control={control}
        render={({ field }) => (
          <Input icon={MailIcon} placeholder={t('resetPassword.sendCode.form.email')} isError={!!errors?.email?.message} {...field} />
        )}
      />
      <div className={styles.errorContainer}>
        <Error title={errors?.email?.message || error} />
      </div>
      <BasicButtonV1 isLoading={isLoading} disabled={isLoading || !isValid} type={'submit'}>
        {t('resetPassword.sendCode.form.submit')}
      </BasicButtonV1>
    </form>
  );
};
