import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import clsx from 'clsx';
import { useLazyActivateUserQuery, useSendEmailActivationMutation } from 'store';
import { useNavigate } from 'react-router-dom';
import { LOCAL_STORAGE_KEYS, ROUTES } from 'app/constants';
import { useDisplay } from 'hooks';
import { MobileModal } from 'shared/utils/mobile';
import { ISignUpForm } from 'interface';

import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import BasicButton from 'shared/ui/basic-button/button';
import { LetterIcon } from 'components/pages/premium/screens/BuyPremium/components/Payment/PaymentModal/components/LetterIcon/LetterIcon';
import { FaSpinner } from 'react-icons/fa';
import { CodeInput } from 'features/auth/ui';

import * as Styled from './styles';
import Answer from 'components/library/messages/Answer';

export const UserActivationCodeChecker = () => {
  const { isMobile } = useDisplay();
  const { email = '' } = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.SIGNUP_TEMP) ?? '{}') as ISignUpForm;
  const [sendEmailAct, { isLoading: isEmailSending }] = useSendEmailActivationMutation();
  const [checkCode, { error: errorGiven }] = useLazyActivateUserQuery();
  const [code, setCode] = useState('');
  const [error, setError] = useState(errorGiven as any);
  const navigate = useNavigate();
  const [isCheckingCode, setIsCheckingCode] = useState(false);

  const handleGoToLogin = useCallback(() => {
    navigate(ROUTES.LOGIN);
  }, [navigate]);

  const isLoading = isCheckingCode || isEmailSending;

  const handleEmailConfirm = useCallback(
    () =>
      sendEmailAct({ email })
        .unwrap()
        .then(() => {
          toast.success(<Answer label="Email sent!" subtext="Please check your inbox." type="correct" />);
          return true;
        })
        .catch(() => {
          toast.error(<Answer label="Oops:\" subtext="Something went wrong..." type="incorrect" />);
        }),
    [sendEmailAct]
  );

  const handleInput = useCallback(
    (str: string) => {
      if (!isEmailSending) {
        setCode(() => str);
        if (str.length === 4) {
          setIsCheckingCode(true);
          checkCode({ code: str, email })
            .unwrap()
            .then(() => {
              handleGoToLogin();
            })
            .catch((err: any) => {
              console.error({ err });
              setError(err);
            })
            .finally(() => {
              setIsCheckingCode(false);
            });
        }
      }
    },
    [checkCode, handleGoToLogin, email, isEmailSending]
  );

  useEffect(() => {
    if (code.length < 4 && error) {
      setError(null);
    }
  }, [code]);

  const isErrorShown = error?.data?.message && code.length === 4;

  return !isMobile ? (
    <Styled.Container>
      <Styled.Modal as={CenteredModal} show={true} onClose={() => {}} isClosing={false}>
        <Styled.Body>
          <Styled.Letter as={LetterIcon} lightningColor="purple" />
          <Styled.Header>Check your email</Styled.Header>
          <Styled.Text>
            Enter 4 digit code sent to <Styled.Span>{email}</Styled.Span> to activate your account
          </Styled.Text>
          <CodeInput
            containerClassName={clsx('code', { error: isErrorShown })}
            fields={4}
            onChange={handleInput}
            isValid={!(isErrorShown && code.length === 4)}
            disabled={false}
            value={code}
          />
          {isErrorShown && <p className={clsx('error')}>{error?.data?.message}</p>}
          <Styled.Buttons>
            <Styled.Button onClick={handleGoToLogin} as={BasicButton} label={'Back to Login'} variant="white" />
            <Styled.Button
              onClick={handleEmailConfirm}
              as={BasicButton}
              label={isLoading ? '' : 'Resend code'}
              variant="cool"
              icon={isLoading ? <FaSpinner className={'spinner'} /> : null}
              isDisabled={isLoading}
            />
          </Styled.Buttons>
        </Styled.Body>
      </Styled.Modal>
    </Styled.Container>
  ) : (
    <Styled.Container>
      <MobileModal isOpen onClose={() => {}} classNameContainer={clsx('modal mobile')}>
        <Styled.Body className={clsx('mobile')}>
          <Styled.Letter as={LetterIcon} lightningColor="purple" />
          <Styled.Header>Check your email</Styled.Header>
          <Styled.Text>
            Enter 4 digit code sent to <Styled.Span>{email}</Styled.Span> to activate your account
          </Styled.Text>
          <CodeInput
            containerClassName={clsx('code', { error: isErrorShown })}
            fields={4}
            onChange={handleInput}
            isValid={!(isErrorShown && code.length === 4)}
            disabled={false}
            value={code}
          />
          {isErrorShown && <p className={clsx('error', { error: isErrorShown })}>{error?.data?.message}</p>}
          <Styled.Buttons>
            <Styled.Button onClick={handleGoToLogin} as={BasicButton} label={'Back to Login'} variant="white" />
            <Styled.Button
              onClick={handleEmailConfirm}
              as={BasicButton}
              label={isLoading ? '' : 'Resend code'}
              variant="cool"
              icon={isLoading ? <FaSpinner className={'spinner'} /> : null}
              isDisabled={isLoading}
            />
          </Styled.Buttons>
        </Styled.Body>
      </MobileModal>
    </Styled.Container>
  );
};
