import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';
import { IRewardMinimal } from 'store';
import * as S from './styles';
import { useGetCurrentTheme } from 'hooks';
import clsx from 'clsx';
import ClaimRewardsButton from '..';
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { RewardsClaimed } from '../../../../tests/TestFrame/TestFrame';

type Props = {
  rewards: IRewardMinimal[];
  disabled?: boolean;
  rewarded?: boolean;
};

export const TaskRewards = ({ rewards, rewarded }: Props) => {
  const theme = useGetCurrentTheme();
  const [isAnimationEnded, setAnimationEnded] = useState(rewarded);
  const [isAnimationStarted, setAnimationStarted] = useState(false);
  const handleStartAnimation = () => {
    if (!isAnimationEnded) {
      setAnimationStarted(true);
    }
  };

  useEffect(() => {
    if (!isAnimationEnded && rewarded) handleStartAnimation();
  }, [isAnimationEnded, rewarded]);

  const handleAnimationEnd = () => {
    setTimeout(() => {
      setAnimationEnded(true);
    }, 2000);
  };

  if (!rewards.length) {
    return null;
  }

  return (
    <AnimatePresence>
      {isAnimationEnded ? (
        <RewardsClaimed rewards={rewards} />
      ) : (
        <S.Container className={clsx({ theme })}>
          <LockIcon />
          Complete task to receive rewards
          <ClaimRewardsButton inTask rewards={rewards} disabled={true} autoCompleteOn={isAnimationStarted} handleCompletion={handleAnimationEnd} />
        </S.Container>
      )}
    </AnimatePresence>
  );
};
