import { FC, RefObject, useCallback, useRef } from 'react';
import clsx from 'clsx';
import { motion } from 'framer-motion';
import { useSelector } from 'react-redux';
import { getCurrentTheme, getIsAuth, getItems, setShopItem } from 'store';
import { Tooltip } from 'shared/ui';
import { dispatch, useDisplay, useNavigateToAuth } from 'hooks';
import { ReactComponent as BuyButton } from './assets/buy_button.svg';
import items from 'testCourses/testItems';
import { IInventoryItemProps } from './types';
import { getItemByKey } from 'utils';

import { ReactComponent as Wallet } from 'components/library/images/ShopInventory/wallet.svg';
import { ReactComponent as Magic } from 'assets/icons/wand-magic.svg';
import { ReactComponent as Eye } from 'assets/icons/eye.svg';

import { LazyImage } from 'shared/ui/lazyImage';
import { FlipInventoryItem } from './motion/FlipInventoryItem/FlipInventoryItem';

import svgbg from '../../backgrounds/svgs.module.css';
import './styles/inventory_item.css';
import './styles/shopcard.css';
import styles from './styles.module.css';

const InventoryItem = ({
  isSmall = false,
  isSmallMid = false,
  isBordered = true,
  isLarge = false,
  isRightInfo = false,
  isCongrats = false,
  isCircle = false,
  isAmountShown = true,
  isShowTooltip = true,
  navigateOnClick = true,
  isMovableTooltip = true,
  amount,
  itemId,
  action,
  className = '',
  hoverEffect = false,
  activeHoverEffect = false,
  variantTooltip = 'default',
  withAnimation,
  withBackground = true,
  price,
}: IInventoryItemProps) => {
  const theme = useSelector(getCurrentTheme);
  const appItems = useSelector(getItems);
  const ref = useRef(null);
  const isAuth = useSelector(getIsAuth);
  const { isMobile } = useDisplay();
  const navToAuth = useNavigateToAuth();

  const handleClick = useCallback(() => {
    if (!isAuth) {
      navToAuth();
    }

    if (!navigateOnClick) {
      return;
    }

    if (typeof action === 'undefined') {
      return dispatch(setShopItem({ itemId, type: 'item' }));
    }

    action();
  }, [navigateOnClick, action, itemId, isAuth]);

  const { name, description, imageThumb, grade, type } = getItemByKey('id', itemId, appItems);
  const isPartner = type === 'non-fungible' || type === 'digital';

  if (!appItems.length) {
    return null;
  }

  const { color, shop_item, shop_item_light }: any = items.find((i) => i.grade === grade);

  const TooltipItem: FC<{ triggerRef: RefObject<any>; size?: 'small' | 'large' }> = ({ triggerRef, size = 'large' }) => {
    return isShowTooltip ? (
      <Tooltip
        isMovable={isMovableTooltip}
        triggerRef={triggerRef}
        className={clsx(styles.tt, styles[variantTooltip])}
        size={size}
        position={variantTooltip === 'cool' ? (isMobile ? '' : 'cool') : ''}
      >
        {variantTooltip === 'default' && (
          <div className={styles.ttcontainer}>
            <div className={styles.header}>
              <div className={styles.data}>
                <p style={{ color: color[theme] }}>{name}</p>
                <span>
                  {amount} in inventory <Wallet width={14} height={14} />
                </span>
              </div>
              <div className={styles.tooltipImageContainer}>
                <LazyImage src={imageThumb} className={styles.tooltipImage} alt={name} />
              </div>
            </div>
            <p className={styles.desc}>{description}</p>
          </div>
        )}
        {variantTooltip === 'cool' && (
          <>
            {shop_item}
            <div className={styles.ttcontainer}>
              <div className={styles.descWrap}>
                <p style={{ color: color[theme] }}>{name}</p>
                <div className={styles.desc} style={{ color: color[theme] }}>
                  {description}
                </div>
              </div>
              <div className={styles.tooltipImageContainer}>
                <LazyImage src={imageThumb} className={styles.tooltipImage} alt={name} />
              </div>
            </div>
          </>
        )}
      </Tooltip>
    ) : (
      <></>
    );
  };

  if (withAnimation) {
    return <FlipInventoryItem amount={amount} image={imageThumb} name={name} grade={grade} description={description} className={className} />;
  }

  if (isLarge)
    return (
      <div
        className={clsx(styles.container, {
          [className]: !!className,
          [styles.large]: isLarge,
          [svgbg.nav]: navigateOnClick,
        })}
        onClick={handleClick}
      >
        <div className={clsx(styles.image, styles.large)}>
          {theme === 'dark' ? shop_item : shop_item_light}
          <LazyImage src={imageThumb} alt={name} className={clsx(styles.image, styles.shopCardImage)} />
        </div>

        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ ease: 'easeOut', duration: 1 }} className={styles.shopDesc}>
          <h3 style={{ color: color[theme] }}>{name?.length < 18 ? name : name?.slice(0, 16) + '...'}</h3>
          <h5 style={{ color: color[theme] }}>{grade}</h5>
          <p className={styles.price}>{price} EXP</p>
          <button className={clsx(styles.buttonContainer, styles[theme])}>
            <BuyButton className={styles.buyButtonIcon} />
            <h1 className={styles.buyButtonTitle}> Buy </h1>
          </button>
        </motion.div>
        <div className={clsx(styles.ttTrigger, 'disable-propagation')} ref={ref} />
        <TooltipItem triggerRef={ref} size={'large'} />
      </div>
    );

  return (
    <div className={clsx({ [styles.isRightInfo]: isRightInfo })}>
      <div
        className={clsx(styles.container, svgbg.mightybg, svgbg.item, svgbg[theme], {
          [svgbg[grade]]: withBackground,
          [className]: !!className,
          [svgbg.small]: isSmall,
          [svgbg.noborder]: !isBordered,
          [svgbg.sm]: isSmallMid,
          [svgbg.effect]: isCircle,
          [svgbg.nav]: navigateOnClick,
          [styles.hoverEffect]: hoverEffect,
          [styles.activeHoverEffect]: activeHoverEffect,
          [styles.isPartner]: isPartner,
        })}
        onClick={handleClick}
      >
        <div className={clsx(styles.ttTrigger, 'disable-propagation')} ref={ref} />
        <LazyImage src={imageThumb} className={styles.image} alt="Item" />
        <TooltipItem triggerRef={ref} size={'small'} />
        <p className={styles.quantity} style={{ color: `var(--grade-${theme}-color-${grade})` }}>
          {isAmountShown && amount}
        </p>
        {hoverEffect && <Eye className={styles.eye} />}
      </div>
      {isRightInfo && (
        <div className={styles.info}>
          <div style={{ color: color[theme] }}>
            <Magic />
            {isCongrats && 'used'} 1 {name}
          </div>
          <div className={styles.wallet}>
            <Wallet width={17} height={20} />
            <span>
              {amount} {isCongrats && 'left'} in inventory{' '}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default InventoryItem;
