import styles from './styles.module.css';
import { NavLink, useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

interface IProps {
  path: string;
  title: string;
  index: 1 | 2;
}

export const Tab = ({ path, title, index }: IProps) => {
  const location = useLocation();
  const animateBorder = {
    1: {
      open: { x: 0, opacity: 1 },
      closed: { x: '+110%', opacity: 0 },
    },
    2: {
      open: { x: 0, opacity: 1 },
      closed: { x: '-110%', opacity: 0 },
    },
  };
  return (
    <div className={styles.link}>
      <NavLink className={({ isActive }) => (isActive ? `${styles.tab} ${styles.active}` : styles.tab)} to={path}>
        {title}
      </NavLink>
      <motion.div
        className={styles.border}
        animate={location.pathname === path ? 'open' : 'closed'}
        transition={{ duration: 0.2 }}
        variants={animateBorder[index]}
      />
    </div>
  );
};
