import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    display: flex;
    flex-direction: column;
    & > div {
      margin-bottom: 82px;
    }
    & > div:last-of-type {
      margin-bottom: 0;
    }
    & > div {
      z-index: 5;
    }

    @media screen and (max-width: 992px) {
      display: grid;
      grid-template-rows: repeat(fit-content(100%), 4);
      grid-template-columns: 100%;
      grid-template-areas:
        'courses'
        'energy'
        'inventory'
        'friends';

      .courses {
        grid-area: courses;
      }
      .energy {
        grid-area: energy;
      }
      .inventory {
        grid-area: inventory;
      }
      .friends {
        grid-area: friends;
      }
      & > div {
        margin-bottom: 40px;
      }
    }
  `
);
