import styled from 'styled-components';

const MightyInputField = styled.input`
  min-width: 50px;
  max-width: 120px;
  height: 25px;

  background: ${({ theme }) => theme.theme.backgroundTransparent};
  mix-blend-mode: normal;
  color: ${({ theme }) => theme.theme.fontColor};

  padding: 0 5px;

  border: 1px solid ${({ theme }) => theme.theme.border};
  border-radius: 3px;

  &:focus {
    outline: 1px solid ${({ theme }) => theme.theme.border};
  }

  font-weight: 400;
  font-size: 1.6rem;
  line-height: 145%;

  &.--arena {
    color: var(--battle-color-blue);
    text-align: center;

    font-weight: 400;
    font-size: 14px;
    line-height: 152%;
  }
`;

export default MightyInputField;
