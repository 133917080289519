import { STATIC_HOST_PATH } from 'app/constants/path';
import { useDisplay, useGetCurrentTheme, useGetMultipliedExp } from 'hooks';
import { useEffect, useReducer, useState } from 'react';
import { useLazyGetFullCourseQuery, ESlideTypes } from 'store';
import { THEMES } from 'app/constants';
import { linear } from 'popmotion';
import { MaterialPageContext } from './materialPageContext';

import { GlobalLoader } from 'shared/utils';
import LessonFrame from './lessons/LessonFrame';

import { ReactComponent as Times } from 'assets/icons/times.svg';

const defaultState = {
  course: null,
  chapter: null,
  lesson: null,
  resultCourse: null,
  isLoading: true,
  active: 0,
  progress: 0,
  total: 0,
  toggleModal: false,
  isSidePanelOn: false,
  endLesson: false,
  imageLogo: null,
  isEnergyModal: false,
  animateIsExit: false,
  right: null,
};

export default function Preview({ courseId, chapterId, lessonId, slideIndex, slideId, onClose }) {
  const [getCourse] = useLazyGetFullCourseQuery();
  const { getMultipliedExp } = useGetMultipliedExp();

  const theme = useGetCurrentTheme();
  const [state, updateState] = useReducer((prev, next) => ({ ...prev, ...next }), defaultState);
  const { course, active, imageLogo } = state;
  const { isMobile } = useDisplay();

  const [animateIsExit, setAnimateIsExit] = useState(false);

  useEffect(() => {
    async function getCourseData() {
      const courseFetched = await getCourse(courseId).unwrap();
      const imageLogo = STATIC_HOST_PATH + '/' + courseFetched.imageLogo;
      updateState({ course: courseFetched, imageLogo });
    }

    getCourseData();
  }, [courseId]);

  useEffect(() => {
    updateState({ active: slideIndex });
  }, [slideIndex]);

  if (!course?.chapters || !animateIsExit) {
    return (
      <div className={`material-page__wrap ${theme}`}>
        <GlobalLoader isLoading={!course?.chapters} onLoad={() => setAnimateIsExit(true)} />
      </div>
    );
  }
  const chapter = course?.chapters.find((chapter) => chapter?._id === chapterId);
  const lesson = chapter?.lessons.find((less) => less?._id === lessonId);
  const slide = lesson?.slides[active];
  const rewards = slide?.rewards.map((r) => ({ ...r, quantity: getMultipliedExp(r.id, r.quantity) }));

  return (
    <MaterialPageContext.Provider
      value={{
        navigateUser: () => {},
        navForward: () => {},
        handleEnergyModalOpen: () => {},
        rewards,
        slideId,
      }}
    >
      <div className={`material-page__wrap ${theme} preview tiny-sb`}>
        <div className="material-page">
          {!isMobile && (
            <div className={'material-page__top-bar'}>
              <div className={'material-page__top-bar_left material-page__left-column'}>
                <button className={'material-page__top-bar_close'} onClick={onClose}>
                  <Times style={{ color: 'var(--font-color)' }} />
                </button>
                <div className={'material-page__imageLogo'}>
                  <img src={imageLogo} alt="" />
                </div>
                <div className={'material-page__top-bar_path'}>
                  <p>{course.name}</p>
                  <span>
                    {chapter.name} {lesson.name}
                  </span>
                </div>
              </div>
            </div>
          )}
          {isMobile && (
            <span className="top-info">
              {slide.typeOfSlide === ESlideTypes.INFO ? 'lesson' : 'test'} {slide.index + 1}: {slide.name}
            </span>
          )}
          <div className={`${isMobile ? `lesson-area glass-div ${theme} ${theme === THEMES.DARK && linear}` : 'lesson-area-desktop'}`}>
            <LessonFrame key={slide._id} slide={slide} lesson={lesson} right={null} updateRight={() => null} navigatorProps={{ locked: true }} />
          </div>
        </div>
      </div>
    </MaterialPageContext.Provider>
  );
}
