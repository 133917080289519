const suspensedUrls: string[] = [];

export const suspenseLoader: any = async ({ request }: any) => {
  let loader;

  const baseUrl = request.url.split('?')[0];

  if (suspensedUrls.includes(baseUrl)) {
    loader = new Promise((resolve) => resolve(null));
  } else {
    loader = new Promise((resolve) => setTimeout(() => resolve(null), 1000));
    suspensedUrls.push(baseUrl);
  }

  try {
    return { loader };
  } catch (e) {
    throw new Error('An error occurred while loading page.');
  }
};
