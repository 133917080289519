import { ChangeEvent, FC, KeyboardEvent, useEffect, useRef, useState } from 'react';
import { IAnswer } from 'store';

import { AiOutlinePlus } from 'react-icons/ai';

import { AutoWidthInput } from 'components/library/autoWidthInput';
import { Buttonv2 } from 'components/library/buttonv2';

import * as Elements from './styles';

type TCreateInvalidAnswerButtonProps = {
  rightAnswers: IAnswer[];
  onCreatingModeChange: (isInCreatingMode: boolean) => void;
  onAddInvalidAnswer: (answer: string) => void;
  onError: (error: string) => void;
};

export const CreateInvalidAnswerButton: FC<TCreateInvalidAnswerButtonProps> = ({
  rightAnswers,
  onCreatingModeChange,
  onError,
  onAddInvalidAnswer,
}) => {
  const [isInCreatingMode, setIsInCreatingMode] = useState(false);
  const [wrongAnswer, setWrongAnswer] = useState('');

  const $inputRef = useRef<HTMLInputElement>(null);

  const handleCreatingModeStart = () => {
    setIsInCreatingMode(true);
    onCreatingModeChange(true);
  };

  const handleCreatingModeStop = () => {
    setIsInCreatingMode(false);
    onCreatingModeChange(false);
    setWrongAnswer('');
    onError('');
  };

  const handleWrongAnswerChange = (evt: ChangeEvent<HTMLInputElement>) => {
    setWrongAnswer(evt.target.value);
  };

  const handleKeyDown = (evt: KeyboardEvent) => {
    if (evt.key === 'Enter' && rightAnswers.some(({ text }) => text === wrongAnswer)) {
      onError(`Answer ${wrongAnswer} already exists in right answers.`);
      return;
    }

    if (evt.key === 'Enter') {
      handleCreatingModeStop();
      onAddInvalidAnswer(wrongAnswer);
      return;
    }
  };

  useEffect(() => {
    if (!isInCreatingMode) {
      return;
    }

    $inputRef.current?.focus();
  }, [isInCreatingMode]);

  return (
    <Elements.SRoot>
      {!isInCreatingMode ? (
        <Buttonv2 onClick={handleCreatingModeStart}>
          <AiOutlinePlus /> Wrong Answer
        </Buttonv2>
      ) : (
        <Elements.SInputWrapper>
          <div>Add wrong answer:</div>
          <AutoWidthInput
            className="invalid-answers__input"
            ref={$inputRef}
            value={wrongAnswer}
            onKeyDown={handleKeyDown}
            onChange={handleWrongAnswerChange}
            onBlur={handleCreatingModeStop}
          />
        </Elements.SInputWrapper>
      )}
    </Elements.SRoot>
  );
};
