import { EItemFungibleType, IDatabaseItem } from 'interface';
import { EActivateItemType } from '../features/ActivateItem/types/types';
import { effectTypes } from '../features/ActivateItem/data/data';

export const getItemEffectType = (item: IDatabaseItem) => {
  for (const [type, effects] of Object.entries(effectTypes)) {
    if (effects.includes(item.fungibleType as EItemFungibleType)) {
      return type as EActivateItemType;
    }
  }
  return EActivateItemType.NOEFFECT;
};
