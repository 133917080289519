import { shallowEqual, useSelector } from 'react-redux';
import { getAppSettings, getCurrentLanguage, getCurrentTheme, getIsAuth, getIsInit, getItems, getPartnerId } from 'store';

export const useGetIsAuth = (isShallowEqual?: boolean) => useSelector(getIsAuth, isShallowEqual ? shallowEqual : undefined);
export const useGetCurrentTheme = (isShallowEqual?: boolean) => useSelector(getCurrentTheme, isShallowEqual ? shallowEqual : undefined);
export const useGetCurrentLanguage = (isShallowEqual?: boolean) => useSelector(getCurrentLanguage, isShallowEqual ? shallowEqual : undefined);
export const useGetSettings = (isShallowEqual?: boolean) => useSelector(getAppSettings, isShallowEqual ? shallowEqual : undefined);
export const useGetIsInit = (isShallowEqual?: boolean) => useSelector(getIsInit, isShallowEqual ? shallowEqual : undefined);
export const useGetItems = (isShallowEqual?: boolean) => useSelector(getItems, isShallowEqual ? shallowEqual : undefined);
export const useGetPartnerId = (isShallowEqual?: boolean) => useSelector(getPartnerId, isShallowEqual ? shallowEqual : undefined);
