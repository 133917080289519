import { useSelector } from 'react-redux';
import * as S from './styles';
import { getCurrentTheme } from 'store';
import { useMemo, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { ERewardsTabs, REWARDS_TABS_COLORS } from '../../types';

const tabsVariants = {
  initial: {
    width: '0px',
    padding: '4px 4px',
    marginrRight: '8px',
    opacity: 0,
  },
  animate: {
    width: '100%',
    padding: '4px 4px',
    marginrRight: '8px',
    opacity: 1,
  },
  exit: {
    width: '0px',
    padding: '4px 0px',
    marginRight: '0px',
    opacity: 0,
  },
};

type Props = {
  search: string | null;
  tab: ERewardsTabs;
  updateState: (arg: { tab?: ERewardsTabs; search?: string | null }) => void;
};

const RewardsFilters = ({ search, tab, updateState }: Props) => {
  const theme = useSelector(getCurrentTheme);
  const [searchOpened, setSearchOpened] = useState(false);

  const handleOpen = () => setSearchOpened(true);
  const handleClose = () => {
    setSearchOpened(false);
    updateState({ search: null });
  };
  const handleSearch = (search: string) => updateState({ search });

  const tabsRef = useRef<HTMLDivElement | null>(null);

  const tabsWidth = useMemo(() => (tabsRef.current ? tabsRef.current.getBoundingClientRect().width : 0), [tabsRef]);

  const handleRight = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft = tabsWidth;
    }
  };

  const handleLeft = () => {
    if (tabsRef.current) {
      tabsRef.current.scrollLeft = 0;
    }
  };

  const handleRef = (node: HTMLDivElement) => {
    tabsRef.current = node;
  }

  return (
    <S.Container>
      <AnimatePresence exitBeforeEnter>
        {!searchOpened && (
          <S.TabsBox variants={tabsVariants} exit={'exit'} initial={'initial'} animate={'animate'} $theme={theme}>
            <S.Left onClick={handleLeft} />
            <S.Tabs ref={handleRef}>
              {Object.values(ERewardsTabs).map((el) => (
                <S.Tab
                  key={el}
                  $active={el === tab}
                  $color={REWARDS_TABS_COLORS[el].color}
                  $bg={REWARDS_TABS_COLORS[el].bg}
                  onClick={() => updateState({ tab: el })}
                >
                  {el}
                </S.Tab>
              ))}
            </S.Tabs>
            <S.Right onClick={handleRight} />
          </S.TabsBox>
        )}
      </AnimatePresence>
      <S.SearchBox animate={searchOpened ? { width: '520px' } : { width: '48px' }} $theme={theme} $active={searchOpened} onClick={handleOpen}>
        <S.SearchIcon />
        {searchOpened && (
          <>
            <S.SearchInput
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.2 }}
              placeholder="Search Items"
              $theme={theme}
              onChange={(e) => handleSearch(e.target.value)}
              value={search || ''}
            />
            <S.CloseButton onClick={handleClose} />
          </>
        )}
      </S.SearchBox>
    </S.Container>
  );
};

export default RewardsFilters;
