import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import { BsChevronLeft, BsChevronRight, BsSearch, BsXLg } from 'react-icons/bs';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  margin-bottom: 20px;
  width: 100%;
  gap: 8px;
`;

export const TabsBox = styled(motion.div)<{ $theme: THEMES }>`
  display: flex;
  overflow: hidden;
  align-items: center;
  padding: 4px;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? '#11111108' : '#FFFFFF08')};
  border-radius: 100px;
  width: 100%;
`;

export const Left = styled(BsChevronLeft)`
  width: 24px;
  cursor: pointer;
`;

export const Right = styled(BsChevronRight)`
  width: 24px;
  cursor: pointer;
`;

export const Tabs = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  border-radius: 46px;
  max-width: 464px;
  scroll-behavior: smooth;
`;
export const Tab = styled.div<{ $color: string; $bg: string; $active?: boolean }>`
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 18px;
  border-radius: 46px;
  white-space: nowrap;
  cursor: pointer;
  color: ${(p) => (p.$active ? p.$color : 'var(--font-color)')};
  background: ${(p) => (p.$active ? p.$bg : 'transparent')};
  transition: 0.3s;
`;

export const SearchBox = styled(motion.div)<{ $active?: boolean; $theme: THEMES }>`
  display: flex;
  height: 48px;
  align-items: center;
  justify-content: center;
  gap: 3px;
  background: ${(p) => (p.$theme === THEMES.LIGHT ? '#11111108' : '#FFFFFF08')};
  border-radius: 100px;
  ${(p) => p.$active && 'jutify-content: flex-start;'}
  min-width: 48px;
  padding: 0px 12px;
`;
export const SearchIcon = styled(BsSearch)`
  width: 24px;
  cursor: pointer;
`;

const SearchCloseIcon = styled(BsXLg)`
  width: 28px;
  cursor: pointer;
`;
export const CloseButton = ({ onClick }: { onClick: () => void }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ delay: 0.4 }}
    style={{
      display: 'flex',
      alignItems: 'center',
      height: 28,
    }}
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
  >
    <SearchCloseIcon />
  </motion.div>
);

export const SearchInput = styled(motion.input)<{ $theme: THEMES }>`
  background: none;
  border: none;
  outline: none;
  font-size: 18px;
  height: 20px;
  width: 100%;
  color: ${(p) => (p.$theme === THEMES.DARK ? 'white' : '#111')};
`;
