import React, { useEffect } from 'react';
import { javascript } from '@codemirror/lang-javascript';
import { html } from '@codemirror/lang-html';
import { python } from '@codemirror/lang-python';
import { css } from '@codemirror/lang-css';
import { githubDark } from '@uiw/codemirror-theme-github';
import { CodeWebEditorContainer } from './style/styled';
import CodeMirror from '@uiw/react-codemirror';

const extensions: Record<string, any> = { javascript, html, python, css };

interface CodeWebEditorProps {
  language: 'javascript' | 'html' | 'python' | 'css';
  value: string;
  onChange: (value: string) => void;
  readOnly?: boolean;
}

export const CodeWebEditor: React.FC<CodeWebEditorProps> = ({ language, value, onChange, readOnly = false }) => {
  useEffect(() => {
    const scrollEditor = document.querySelectorAll('.cm-scroller');
    scrollEditor.forEach((item) => {
      if (!item.classList.contains('tiny-sb')) {
        item.classList.add('tiny-sb');
      }
    });
  }, []);

  return (
    <CodeWebEditorContainer>
      <CodeMirror
        value={value}
        height="100%"
        extensions={[extensions[language]()]}
        onChange={onChange}
        theme={githubDark}
        readOnly={readOnly}
        basicSetup={{
          autocompletion: true,
          closeBrackets: true,
          closeBracketsKeymap: true,
          highlightActiveLine: true,
          highlightSelectionMatches: true,
          lineNumbers: true,
        }}
      />
    </CodeWebEditorContainer>
  );
};
