import Drawer from 'shared/ui/drawer/Drawer';
import { CircleButton } from 'shared/ui/circle-button';
import { BiChevronDown, BiSolidVolume } from 'react-icons/bi';
import { useTextToSpeech } from '../utils/useTextToSpeech';
import { DropdownMotion } from 'shared/ui';

import { SSelect, SettingContainer } from '../style/styled';
import { RangeSlider } from 'shared/ui/RangeSlider';

export const SettingsTextToSpeech = () => {
  const { settings, handleRateChange, handleVoiceChange, handleVolumeChange, handlePitchChange } = useTextToSpeech();

  const { voice, volume, rate, pitch } = settings;

  const normalizeVoiceList = window.speechSynthesis.getVoices().map((voice) => ({ title: voice.name, onClick: () => handleVoiceChange(voice.name) }));

  return (
    <Drawer isVisible={false} position={'right'} triggerNode={<CircleButton icon={<BiSolidVolume />} />}>
      <SettingContainer>
        <label>
          Voice:
          <DropdownMotion
            itemsPlacement={{ right: 50 }}
            triggerNode={
              <SSelect>
                <p>{Boolean(voice) ? voice : 'loading'}</p> <BiChevronDown />{' '}
              </SSelect>
            }
            items={normalizeVoiceList}
          />
        </label>

        <br />

        <label>
          Pitch:
          <RangeSlider myValue={pitch} min={0.5} max={2} onChange={handlePitchChange} withKnob />
        </label>

        <br />

        <label>
          Speed:
          <RangeSlider myValue={rate} min={0.5} max={2} onChange={handleRateChange} withKnob />
        </label>
        <br />
        <label>
          Volume:
          <RangeSlider myValue={volume} min={0} max={1} onChange={handleVolumeChange} withKnob />
        </label>
      </SettingContainer>
    </Drawer>
  );
};
