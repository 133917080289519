import { FC } from 'react';
import letter from '../../assets/letter.png';
import errorIcon from '../../../../../../../assets/icons/error.png';
import * as Styled from './styled';
import { motion } from 'framer-motion';
import { rotateVariants } from '../../../../../../../motion/variants';
import mask from './assets/mask.png';

interface ILetterIconProps {
  icon?: string | 'error';
  className?: string;
  lightningColor?: 'blue' | 'green' | 'red' | 'yellow' | 'purple' | string;
}
export const LetterIcon: FC<ILetterIconProps> = ({ icon, className = '', lightningColor }) => {
  return (
    <Styled.Container className={className}>
      <Styled.Content>
        <Styled.Letter src={letter} alt={'letter'} />
        {icon && <Styled.Icon src={icon === 'error' ? errorIcon : icon} />}
        {lightningColor && (
          <Styled.Lightning
            as={motion.div}
            $color={lightningColor}
            variants={rotateVariants}
            animate={'animate'}
            transition={{ duration: 24, repeat: Infinity, ease: 'linear' }}
          />
        )}
        <Styled.Mask src={mask} alt={'mask'} />
      </Styled.Content>
    </Styled.Container>
  );
};
