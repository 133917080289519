import { getUserData } from 'store';
import { useSelector } from 'react-redux';

import { EnergyIncomeIndicator } from 'shared/ui/energy-widget';

import * as Styled from './styles';

export const Energy = () => {
  const {
    exp: {
      energy,
      limit: { maxEnergy },
    },
  } = useSelector(getUserData);

  const percent = Math.ceil((energy / maxEnergy) * 100);

  return (
    <Styled.Container>
      <Styled.Span>{percent}% Energy</Styled.Span>
      <Styled.Income as={EnergyIncomeIndicator} />
    </Styled.Container>
  );
};
