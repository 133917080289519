import styled, { css } from 'styled-components';
import rgba from 'hex-to-rgba';

export const SDropdown = styled.div<{ $maxWidth?: number; $items?: boolean; $variant: string; $noPadding?: boolean }>(
  ({ theme, $maxWidth, $items, $variant, $noPadding }) => css`
    background: ${$variant === 'primary' ? theme.theme.background : theme.theme.basicBG};
    border: 2px solid ${rgba(theme.theme.fontColor, 0.1)};
    border-radius: 5px;
    max-width: ${$maxWidth + 'px'};
    padding: ${$noPadding ? 0 : $items ? '3px 0' : '15px'};
  `
);

export const SDropdownContentWrapper = styled.div(() => css``);

export const STrigger = styled.div(
  () => css`
    background: transparent;
    cursor: pointer;
    padding: 0;
    border: none;
  `
);
