import { useReducer } from 'react';

import CheckAnswerButton from '../utils/CheckAnswerButton/CheckAnswerButton';

import { MightyCheckboxRadio } from 'shared/study-ui/checkbox-radio/mighty-checkbox-radio';
import { useGetCustomNav } from '../utils/useGetCustomNav';

const MultiTest = ({ answers, type, slideId, right, updateRight, elementWithCondition }) => {
  const [state, updateState] = useReducer((prev, next) => ({ ...prev, ...next }), { answers, type, userInput: {} });
  const { userInput } = state;
  const isRadio = type === 'single';
  const words = ['A. ', 'B. ', 'C. ', 'D. ', 'E. ', 'F. '];

  const handleInput = (e) => {
    const value = e.target.value;

    if (right === true) return;

    updateRight(null);
    if (userInput[value]) {
      if (isRadio) {
        return updateState({ ...state, userInput: {} });
      }
      const newUserInput = { ...userInput };
      delete newUserInput[value];
      return updateState({ ...state, userInput: newUserInput });
    }
    if (isRadio) {
      return updateState({ ...state, userInput: { [value]: value } });
    }
    updateState({ ...state, userInput: { ...userInput, [value]: value } });
  };

  const buttonElement = useGetCustomNav(
    <CheckAnswerButton
      className="lesson-button"
      isActive={Boolean(Object.values(userInput)[0])}
      userAnswers={Object.values(userInput)}
      slideId={slideId}
      updateRight={updateRight}
      right={right}
    />,
    elementWithCondition
  );

  const minBigLength = 5;
  const shortAnswersAmount = state.answers.filter(({ text }) => text.length < minBigLength).length;
  const isBigNeeded = shortAnswersAmount % 2 !== 0;

  return (
    <>
      <div className="multi">
        {[...state.answers]
          .sort((a, b) => b.text.length - a.text.length)
          .map(({ text }, index) => {
            const style = right === null ? {} : { transitionDelay: 250 * index + 'ms' };
            const isLast = index === state.answers.length - 1;
            const isBig = text.length >= minBigLength || (isBigNeeded && isLast);

            return (
              <MightyCheckboxRadio
                className={`answer ${isBig && 'big'}`}
                type={isRadio ? 'radio' : 'checkbox'}
                style={style}
                right={right}
                label={text}
                prefix={words[index]}
                key={'mighty-choices' + index}
                checked={Object.values(userInput).includes(text)}
                onChange={handleInput}
              />
            );
          })}
      </div>
      {buttonElement}
    </>
  );
};

export default MultiTest;
