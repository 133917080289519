import styled from 'styled-components';

export const SCodeWebOutputContainer = styled.div`
  border: none;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  &.light {
    border: 2px solid rgba(100, 135, 239, 0.4);
  }
`;

export const SCodeWebOutputHeader = styled.div`
  width: 100%;
  height: 41px;
  padding: 8px 15px;
  background-color: rgba(255, 255, 255, 0.15);
  color: #fff;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 142%;
  display: flex;
  gap: 10px;
  color: var(--font-color-strong);
  &.light {
    background: rgba(100, 135, 239, 0.4);
  }
`;

export const SCodeWebOutputBrowser = styled.div`
  background-color: #fff;
  height: calc(100% - 41px);

  iframe {
    border: none;
    position: static;
  }
`;

export const SCodeWebOutputConsole = styled.div`
  background-color: #fff;
  height: calc(100% - 41px);
  color: #000;
  padding: 10px;

  .console-scroll {
    overflow-y: scroll;
    height: 100%;
    padding: 0 10px 20px 0;
  }

  .cnsl-line {
    padding: 0 15px;
    border-radius: 5px;
    display: flex;
    align-items: center;

    &__error {
      padding: 5px 15px;
      background-color: rgba(229, 104, 98, 0.15);
      color: rgb(229, 104, 98);

      svg {
        margin-right: 5px;
      }
    }
  }
`;

export const SCodeWebOutputSwitch = styled.button`
  padding: 5px;
  height: 100%;
  width: auto;
  border-radius: 5px;
  border: none;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
  transition: background-color 0.15s ease-in-out;
  color: var(--font-color-strong);

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
  &.light {
    background-color: rgba(255, 255, 255, 0.45);
  }
`;
