import { InlineAnswersEditor } from '../../core';
import { IInlineAnswerExtension } from '../../core/types';
import { getCursorCoordinates } from '../../utils/getCursorCoordinates';

export type TInlineAnswerBubbleExtensionProperties = {
  onAddAnswer: (answer: string) => void;
};

export class InlineAnswersBubbleExtension implements IInlineAnswerExtension<TInlineAnswerBubbleExtensionProperties> {
  private _editor!: InlineAnswersEditor;
  private _node!: HTMLDivElement;
  private _visible = false;
  private _selectedText: string | null = null;
  private _props: TInlineAnswerBubbleExtensionProperties;

  constructor(props: TInlineAnswerBubbleExtensionProperties) {
    this._props = props;
  }

  updateProps = (props: TInlineAnswerBubbleExtensionProperties): void => {
    this._props = props;
  };

  attach = (editor: InlineAnswersEditor): void => {
    this._editor = editor;

    this._bootstrap();
  };

  detach = (): void => {
    this._unsetListeners();
  };

  private _initNode = () => {
    const bubbleWrapper = document.createElement('div');
    bubbleWrapper.className = 'inline-answers-v2__bubble';
    bubbleWrapper.style.visibility = 'hidden';

    const bubbleIcon = document.createElement('div');
    bubbleIcon.innerHTML = `
    <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1.5 5.5026H5M5 5.5026L5 9.29427M5 5.5026L5 1.71094M5 5.5026L8.5 5.5026" stroke="black" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
    `;
    bubbleWrapper.appendChild(bubbleIcon);

    const bubbleText = document.createElement('span');
    bubbleText.textContent = 'Add as answer';
    bubbleWrapper.appendChild(bubbleText);

    this._node = bubbleWrapper;

    this._editor.getWrapper().appendChild(bubbleWrapper);
  };

  private _hide = () => {
    if (!this._visible) {
      return;
    }

    if (document.activeElement !== this._editor.getRoot()) {
      this._editor.restoreSelection();
    }

    this._node.style.visibility = 'hidden';
    this._visible = false;
  };

  private _show = () => {
    if (this._visible) {
      return;
    }

    this._editor.freezeSelection();

    this._node.style.visibility = 'visible';
    this._visible = true;
  };

  private _bootstrap = () => {
    this._initNode();
    this._initListeners();
  };

  private _handleSelection = () => {
    if (!this._node) {
      return;
    }

    setTimeout(() => {
      const selection = window.getSelection();
      if (!selection || !selection.rangeCount) {
        this._hide();
        return;
      }

      const range = selection.getRangeAt(0);
      const container = range.commonAncestorContainer;
      const walker = document.createTreeWalker(container, NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT);
      let node = walker.nextNode();

      let selectionContentHasAnswerBadge = false;

      while (node) {
        if (node.nodeName === 'SPAN' && (node as HTMLSpanElement).getAttribute('data-mighty-answer')) {
          selectionContentHasAnswerBadge = true;
          break;
        }

        node = walker.nextNode();
      }

      const selectionContent = selection?.toString();

      if (
        !selectionContent ||
        !selectionContent.trim().length ||
        selectionContentHasAnswerBadge ||
        selectionContent.length > 30 ||
        selectionContent.includes('\n')
      ) {
        this._hide();
        return;
      }

      this._selectedText = selectionContent;
      this._show();

      const wrapperCoords = this._editor.getWrapper().getBoundingClientRect();
      const coordinates = getCursorCoordinates();

      this._node.style.left = `${coordinates.x + coordinates.width / 2 - wrapperCoords.x}px`;
      this._node.style.top = `${coordinates.y - wrapperCoords.y}px`;
    }, 100);
  };

  private _handleDocumentMouseUp = (evt: MouseEvent) => {
    if (!this._visible) {
      return;
    }

    if (evt.target !== this._editor.getWrapper() && !this._editor.getWrapper().contains(evt.target as Element)) {
      this._hide();
    }
  };

  private _handleBubbleClick = () => {
    if (!this._selectedText) {
      return;
    }

    this._hide();

    this._props.onAddAnswer(this._selectedText);
    this._selectedText = null;
  };

  private _initListeners = () => {
    this._editor.getRoot().addEventListener('mouseup', this._handleSelection);
    this._editor.getRoot().addEventListener('keyup', this._handleSelection);

    this._node.addEventListener('click', this._handleBubbleClick);

    document.addEventListener('click', this._handleDocumentMouseUp);
  };

  private _unsetListeners = () => {
    this._editor.getRoot().removeEventListener('mouseup', this._handleSelection);
    this._editor.getRoot().removeEventListener('keyup', this._handleSelection);

    this._node.removeEventListener('click', this._handleBubbleClick);

    document.removeEventListener('click', this._handleDocumentMouseUp);
  };
}
