import rgba from 'hex-to-rgba';
import * as yup from 'yup';
import { ChangeEvent, FC, useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { NEW_ITEM_ID } from 'store';
import { TTestSlideProps } from '../../types';
import { useSlideContext } from 'components/library/courseConstructor/components/SlideContext';
import { updateSlideField_SlideType } from 'store/coursesConstructor/utils';

import { ReactComponent as DotsVerticalIcon } from 'assets/icons/dots-vertical.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil.svg';
import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import DropdownMenu from 'components/library/dropdownMenu';
import SelectionInput from 'components/library/selectionInput';
import { ESelectionInputType } from 'components/library/selectionInput/types';
import { EInfoBadgeTypes } from 'components/library/infoBadge/types';
import InfoBadge from 'components/library/infoBadge';
import { useTheme } from 'styled-components';
import BasicButton from 'shared/ui/basic-button/button';
import TransparentTextarea from 'components/library/transparentTextarea';

import * as Styled from 'shared/study-ui/checkbox-radio/styles';
import styles from './styles.module.css';

const NEW_ANSWER_IDX = -1;

const Multichoice: FC<TTestSlideProps> = ({ answers, rightAnswers, onChange, slideId }) => {
  // $external
  const { slide } = useSlideContext();
  const theme = useTheme() as any;
  const optionValidationSchema = yup.object().shape({ title: yup.string().required().min(2).max(180) });

  // $refs
  const $answerTitleInputRef = useRef<HTMLTextAreaElement>(null);

  // $memo
  const isMulti = useMemo(() => rightAnswers.length > 1, [rightAnswers]);

  // $state
  const [answerEditingIdx, setAnswerEditingIdx] = useState<number | null>(null);
  const [editingAnswerTitle, setEditingAnswerTitle] = useState('');
  const [editingAnswerError, setEditingAnswerError] = useState('');

  // $handlers [change correct answers]

  // TODO - rightAnswers нужен при создании курса, надо найти способ это указать в интерфейсе

  const handleChangeCorrectAnswers = (answerText: string) => () => {
    if (rightAnswers.some((item) => item.text === answerText)) {
      onChange(
        //@ts-ignore
        updateSlideField_SlideType(slide, {
          answers,
          //@ts-ignore
          rightAnswers: rightAnswers.filter((item) => item.text !== answerText),
        })
      );
    } else {
      onChange(
        //@ts-ignore
        updateSlideField_SlideType(slide, {
          answers,
          //@ts-ignore
          rightAnswers: [...rightAnswers, { text: answerText }],
        })
      );
    }
  };

  const handleAddNewAnswer = () => {
    setAnswerEditingIdx(NEW_ANSWER_IDX);
  };

  const handleDiscardAddNewAnswer = () => {
    setAnswerEditingIdx(null);
    setEditingAnswerError('');
    setEditingAnswerTitle('');
  };

  const handleAnswerEdit = (idx: number) => () => {
    console.log(idx);

    setAnswerEditingIdx(idx);
  };

  const handleAnswerTitleChange = async (evt: ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value;

    try {
      await optionValidationSchema.validate({ title: value });
      setEditingAnswerError('');
    } catch (e: any) {
      setEditingAnswerError(e.errors[0]);
    } finally {
      setEditingAnswerTitle(value);
    }
  };

  const handleChangeAnswerSubmit = () => {
    if (answerEditingIdx === null) {
      return;
    }

    let newAnswers: any[] = [...answers];
    let newRightAnswers: any[] = [...rightAnswers];

    if (answerEditingIdx === NEW_ANSWER_IDX) {
      newAnswers = [...answers, { text: editingAnswerTitle }];
    } else {
      newAnswers[answerEditingIdx] = { text: editingAnswerTitle };

      const prevAnswer = answers[answerEditingIdx];
      if (rightAnswers.some((item) => item.text === prevAnswer.text)) {
        newRightAnswers = newRightAnswers.map((item) => (item.text === prevAnswer.text ? { text: editingAnswerTitle } : item));
      }
    }

    onChange(
      //@ts-ignore
      updateSlideField_SlideType(slide, {
        answers: newAnswers,
        //@ts-ignore
        rightAnswers: newRightAnswers,
      })
    );
  };

  const handleAnswerDelete = (answerText: string) => () => {
    const newAnswers = answers.filter((item) => item.text !== answerText);
    const newRightAnswers = rightAnswers.filter((item) => item.text !== answerText);

    onChange(
      //@ts-ignore
      updateSlideField_SlideType(slide, {
        answers: newAnswers,
        //@ts-ignore
        rightAnswers: newRightAnswers,
      })
    );
  };

  const optionTitleInput = useMemo(() => {
    return (
      <div key={slideId}>
        <Styled.Container
          className={clsx(styles.controlWrapper, isMulti ? 'checkbox' : 'radio')}
          style={{ borderColor: rgba(theme.theme.fontColorStrong, 0.05) }}
        >
          <Styled.Label>
            <TransparentTextarea
              autoFocus
              className={styles.addInput}
              onChange={handleAnswerTitleChange}
              minLength={2}
              maxLength={180}
              placeholder="Enter answer title here"
              ref={$answerTitleInputRef}
              value={editingAnswerTitle}
            />
          </Styled.Label>
        </Styled.Container>
        <div className={styles.editingControls}>
          <BasicButton label="Cancel" variant="outline" onClick={handleDiscardAddNewAnswer} size="sm" />
          <BasicButton isDisabled={Boolean(editingAnswerError)} label="Save" variant="cool" onClick={handleChangeAnswerSubmit} size="sm" />
        </div>
      </div>
    );
  }, [editingAnswerTitle, editingAnswerError]);

  useEffect(() => {
    if (answerEditingIdx !== null) {
      $answerTitleInputRef.current?.focus();
    }
  }, [answerEditingIdx]);

  useEffect(() => {
    handleDiscardAddNewAnswer();
  }, [answers, rightAnswers]);

  return (
    <div>
      <div className={styles.title}>Answers</div>
      <div className={styles.options}>
        {slideId === NEW_ITEM_ID && <div className={styles.blurBg} />}
        {answers.map((answer, idx) =>
          answerEditingIdx === idx ? (
            optionTitleInput
          ) : (
            <div className={styles.optionWrapper} key={idx}>
              <SelectionInput
                checked={rightAnswers.some((item) => item.text === answer.text)}
                label={answer.text}
                type={isMulti ? ESelectionInputType.CHECKBOX : ESelectionInputType.RADIO}
                onChange={handleChangeCorrectAnswers(answer.text)}
                additionalInfo={
                  <DropdownMenu
                    toggle={
                      <button className={styles.dotsButton}>
                        <DotsVerticalIcon />
                      </button>
                    }
                    items={[
                      {
                        title: 'Edit Answer',
                        icon: <PencilIcon />,
                        onClick: handleAnswerEdit(idx),
                      },
                      {
                        title: 'Delete Answer',
                        icon: <TrashIcon />,
                        onClick: handleAnswerDelete(answer.text),
                      },
                    ]}
                  />
                }
              />
            </div>
          )
        )}
        {answerEditingIdx !== null && answerEditingIdx === NEW_ANSWER_IDX && optionTitleInput}
        {answers.length < 4 && answerEditingIdx === null && (
          <Styled.Container
            className={clsx(styles.controlWrapper, isMulti ? 'checkbox' : 'radio')}
            onClick={handleAddNewAnswer}
            style={{ borderColor: rgba(theme.theme.fontColorStrong, 0.05) }}
          >
            <Styled.Label>
              <Styled.Span>
                <PlusIcon />
                Add new answer
              </Styled.Span>
            </Styled.Label>
          </Styled.Container>
        )}
      </div>
      {editingAnswerError && <InfoBadge type={EInfoBadgeTypes.WARNING}>{editingAnswerError}</InfoBadge>}
      {answerEditingIdx !== null && answers.filter((_, idx) => idx !== answerEditingIdx).some((item) => item.text === editingAnswerTitle) && (
        <InfoBadge type={EInfoBadgeTypes.WARNING}>
          Answer with value&nbsp;<strong>{editingAnswerTitle}</strong>
          &nbsp;already exists
        </InfoBadge>
      )}
    </div>
  );
};

export default Multichoice;
