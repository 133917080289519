import styled from 'styled-components';

export const Container = styled.div`
  width: 600px;
  height: 575px;
  padding: 40px 40px 15px;
  overflow: hidden;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  font-size: 31px;
  line-height: 100%;
  color: var(--font-color-strong);
  margin-bottom: 14px;
`;
