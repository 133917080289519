import { EGroupTag } from 'app/constants';
import { useGetCourseGroups } from 'hooks';

import ai from 'assets/images/group-cards/ai.png';
import favs from 'assets/images/group-cards/favs.png';
import originals from 'assets/images/group-cards/originals.png';
import bitcoin from 'assets/images/group-cards/bitcoin.png';
import socials from 'assets/images/group-cards/socials.png';
import webdev from 'assets/images/group-cards/web-dev.png';
import web3 from 'assets/images/group-cards/web3.png';
import gaming from 'assets/images/group-cards/gaming.png';
import dataScience from 'assets/images/group-cards/data-science.png';
import programming from 'assets/images/group-cards/programming.png';
import blockchainProgramming from 'assets/images/group-cards/blockchain-programming.png';
import defi from 'assets/images/group-cards/defi.png';

export const useGroupImage = (key: string) => {
  const { favouritesKey } = useGetCourseGroups();

  const images: Record<string, string> = {
    [EGroupTag.AIAutomation]: ai,
    [EGroupTag.BitcoinCryptocurrency]: bitcoin,
    [EGroupTag.SocialMedia]: socials,
    [EGroupTag.WebDevelopment]: webdev,
    [EGroupTag.Web3]: web3,
    [EGroupTag.Gaming]: gaming,
    [EGroupTag.DataScience]: dataScience,
    [EGroupTag.Programming]: programming,
    [EGroupTag.BlockchainProgramming]: blockchainProgramming,
    [EGroupTag.DecentralisedFinance]: defi,
    [EGroupTag.Originals]: originals,
    [favouritesKey]: favs,
  };
  return images[key] ?? originals;
};
