import { THEMES } from 'app/constants';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setCurrentThemeWithoutSaving } from 'store';

const useOverwriteThemeByPathname = (forcedTheme = THEMES.DARK) => {
  const theme = (localStorage.getItem('theme') || THEMES.DARK) as THEMES;
  const { pathname } = useLocation();
  const dispatch = useDispatch();

  const blockers = ['/fullscreen/play/mindbattle', '/fullscreen/play/meditation', '/play/leaderboard', '/auth'];

  useEffect(() => {
    const isForceTheme = !!blockers.find((el) => pathname.includes(el));
    if (isForceTheme) {
      dispatch(setCurrentThemeWithoutSaving(forcedTheme));
    } else {
      dispatch(setCurrentThemeWithoutSaving(theme));
    }
  }, [pathname]);
};

export default useOverwriteThemeByPathname;
