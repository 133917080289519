import * as Styled from './styles';

type Props = {
  header: string;
  component: React.ReactNode;
  className?: string;
  containerClassName?: string;
};

const Group = ({ header, className = '', component, containerClassName = '' }: Props) => {
  return (
    <Styled.Container className={containerClassName}>
      <Styled.Header className={className}>{header}</Styled.Header>
      {component}
    </Styled.Container>
  );
};

export default Group;
