import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import { BsXLg } from 'react-icons/bs';
import { Flex, MotionFlex } from 'shared/ui';
import styled from 'styled-components';

export const Container = styled(MotionFlex)<{ $isIOS?: boolean }>`
  width: 100%;
  padding: 28px 50px;
  justify-content: space-between; // temp fix until MotionFlex fix
  align-items: center; // temp fix until MotionFlex fix
  @media screen and (max-width: 992px) {
    padding: 12px 0px;
    ${({ $isIOS }) => $isIOS && 'padding: 56px 0px 12px !important;'}
  }
`;

export const LeftPart = styled(MotionFlex)`
  gap: 16px;
  align-items: center; // temp fix until MotionFlex fix
  @media screen and (max-width: 992px) {
    gap: 8px;
  }
`;

export const FixedBox = styled(motion.div)`
  left: 50px;
  top: 30px;
  position: fixed;
`;

export const Image = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 4px;
  object-fit: cover;
`;

export const Cross = styled(BsXLg)<{ $theme: THEMES }>`
  width: 24px;
  height: 24px;
  stroke-width: 0.5px;
  cursor: pointer;
`;

export const Col = styled(Flex)`
  gap: 6px;
  flex-direction: column;
  width: 100%;
`;
export const Name = styled.p<{ $theme: THEMES }>`
  font-size: 18px;
  color: ${({ $theme }) => ($theme === THEMES.DARK ? 'white' : '#111111')};
  line-height: 100%;
  @media screen and (max-width: 992px) {
    white-space: nowrap;
    max-width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
  }
`;

export const Sub = styled.p<{ $theme: THEMES }>`
  font-size: 16px;
  color: ${({ $theme }) => ($theme === THEMES.DARK ? '#ffffff66' : '#11111166')};
`;

export const RightPart = styled.div``;
