import styled, { css } from 'styled-components';

export const MightyButton = styled.button(
  () => css`
    border-radius: 31px;
    border: none;
    width: auto;
    min-width: 180px;
    height: 66px;
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;

    background: var(--transbg-color);
    color: var(--font-color-strong);
    backdrop-filter: blur(14px);

    &.active {
      color: white;
      background: linear-gradient(90deg, #6487ef 0%, #53259b 100%);
      box-shadow: 9px 5px 10px 0px rgba(48, 49, 61, 0.01), 34px 21px 16px 0px rgba(48, 49, 61, 0.01), 53px 33px 18px 0px rgba(48, 49, 61, 0);
    }

    &.light.active {
      background: linear-gradient(90deg, #6487ef 0%, #d4b8ff 100%);
      box-shadow: 9px 5px 10px 0px rgba(48, 49, 61, 0.01), 34px 21px 16px 0px rgba(48, 49, 61, 0.01), 53px 33px 18px 0px rgba(48, 49, 61, 0);
    }

    cursor: pointer;

    font-size: 2rem;
    line-height: 1;

    padding: 0 20px;

    transition: all 0.3s ease-in-out;

    @media screen and (max-width: 992px) {
      width: 100%;
      border-radius: 39px;
    }

    &.multiple {
      position: relative;
    }

    &:disabled {
      opacity: 0.7;
    }
  `
);
