import { FC } from 'react';
import { GRADES, THEMES } from 'app/constants';
import items from 'testCourses/testItems';
import { useSelector } from 'react-redux';
import { getCustomizationItems } from 'store';
import clsx from 'clsx';
import { AnimatePresence, motion } from 'framer-motion';
import { getCurrentTheme } from 'store';

import { ReactComponent as Line } from './assets/checked.svg';

import { LazyImage } from 'shared/ui';

import styles from './styles.module.scss';

interface Props {
  id: number;
  isActive?: boolean;
  onClick: () => void;
}

const CarouselItem: FC<Props> = ({ id, isActive = false, onClick }) => {
  const customizationItems = useSelector(getCustomizationItems);
  const theme = useSelector(getCurrentTheme);

  const { grade = GRADES.COMMON, imageThumb: image = '' } = customizationItems.find((i) => i.id === id) ?? customizationItems[0];
  const { color }: any = items.find((i) => i.grade === grade);

  return (
    <div className={clsx(styles.item, isActive ? styles.isActive : '')} onClick={onClick}>
      <LazyImage
        className={styles.imageItem}
        src={image}
        alt={'carousel item'}
        style={{ border: `2px dashed ${isActive && theme === THEMES.LIGHT ? color[theme] : 'transparent'}` }}
      />
      <AnimatePresence initial={true}>
        {isActive && (
          <motion.div
            className={styles.checkedBox}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ ease: 'easeOut', duration: 0.15 }}
          >
            <div className={styles.checked} />
            <Line className={styles.svg} />
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default CarouselItem;
