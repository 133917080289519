import * as Styled from './styles';
import Pocket from './Pocket';
import { useDisplay } from 'hooks';

type Props = {
  leftPockets: any[];
  rightPockets: any[];
};

export default function Pockets({ leftPockets = [], rightPockets = [] }: Props) {
  const { isMobile } = useDisplay();
  return isMobile ? (
    <Styled.Container className="pockets">
      <Pocket>{[...leftPockets, ...rightPockets]}</Pocket>
    </Styled.Container>
  ) : (
    <Styled.Container className="pockets">
      <Pocket>{leftPockets}</Pocket>
      <Pocket>{rightPockets}</Pocket>
    </Styled.Container>
  );
}
