import { THEMES } from 'app/constants';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  max-width: 383px;
  height: 100px;
  display: flex;
  align-items: center;
  padding: 16px;
  /* background: linear-gradient(135.15deg, rgba(255, 255, 255, 0.05) 1.75%, rgba(255, 255, 255, 0) 100%); */
  background: linear-gradient(135.15deg, rgba(100, 135, 239, 0.14) 1.75%, rgba(100, 135, 239, 0.06) 100%);

  /* backdrop-filter: blur(20px); */
  border-radius: 12px;
  & > .chest {
    min-width: 100px;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Text = styled.p<{ $theme?: THEMES }>`
  color: ${(p) => (p.$theme === THEMES.LIGHT ? '#111' : '##FAFAFA')};
  font-size: 18px;
  line-height: 100%;
  margin-bottom: auto;
  max-width: 90%;
`;

export const Link = styled.p`
  display: flex;
  align-items: center;
  color: #627eea;
  font-size: 16px;
  line-height: 100%;
  cursor: pointer;
`;
