import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      grid-area: 1 / 2 / 2 / 3;
      display: flex;
      flex-direction: column;
      height: fit-content;
      border-left: 2px solid var(--basic-border-color);
      position: relative;

      @media screen and (max-width: 992px) {
        grid-area: 2;
        margin: 24px 0;
      }
    `
  ),
  Item = styled.div(
    () => css`
      padding-left: 14px;
      padding-top: 4px;
      padding-bottom: 4px;
      position: relative;
      left: -2px;
      cursor: pointer;
      &.active {
        border-left: 2px solid var(--basic-border-color);
      }
      & p {
        opacity: 0.7;
      }
      &.active p {
        color: var(--font-color-strong);
        opacity: 1;
        margin-left: -2px;
      }
    `
  );
