import * as S from '../styles';
import { useDisclosure, useDisplay, useGetCurrentTheme, useGetIsAuth } from 'hooks';
import clsx from 'clsx';
import HorizontalMobileMenuMain from './menu';
import { ROUTES } from 'app/constants';
import { matchPath, useLocation } from 'react-router-dom';
import { useState } from 'react';
import { MobileModal as NotificationsModal } from 'shared/ui/modal';
import Notifications from 'components/library/notifications/Notifications';
import { MobileModal } from '../../modal';
import { Effects } from '../../mobile-navbar/screens';

const MobileMenu = () => {
  const theme = useGetCurrentTheme();
  const isAuth = useGetIsAuth();
  const [currentModal, setCurrentModal] = useState<'user' | 'notifs' | 'effects' | null>(null);
  const { isOpen, toggle, close } = useDisclosure(false);
  const { isMobile } = useDisplay();
  const { pathname } = useLocation();

  const handleCloseModals = () => setCurrentModal(null);

  const hideOn: string[] = [
    ROUTES.AUTH,
    ROUTES.SIGN_UP,
    ROUTES.LOGIN,
    ROUTES.RESET_PASSWORD,
    ROUTES.AUTHEMAIL,
    ROUTES.FEED,
    ROUTES.COURSES_LESSON_PAGE,
  ];

  const hide = hideOn.some((path) => matchPath(path, pathname));

  if (!isMobile || hide) {
    return null;
  }

  return (
    <>
      <S.Container $theme={theme} className={clsx('blur-15', { isHidden: !isOpen })}>
        <S.ToggleButton onClick={toggle}>
          <S.ToggleIconClosed $visible={!isOpen} />
          <S.ToggleIconOpened $visible={isOpen} />
        </S.ToggleButton>
        <S.Progress />
        <HorizontalMobileMenuMain isHidden={!isOpen} handleClose={close} updateModal={setCurrentModal} />
      </S.Container>
      {isAuth && (
        <>
          <NotificationsModal isOpen={currentModal === 'notifs'} handleClose={handleCloseModals} header={'Notifications'} zIndex={200}>
            <Notifications onClose={() => {}} />
          </NotificationsModal>
          <MobileModal isOpen={currentModal === 'effects'} onClose={handleCloseModals}>
            <Effects />
          </MobileModal>
        </>
      )}
    </>
  );
};

export default MobileMenu;
