import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { formatAnswers } from '../utils/formatAnswers';
import clsx from 'clsx';
import { ESlideTypes, ESlideWidgetTypes, IAnswer } from 'store';

import Block from '../Block';
import { extractHTMLFromJsonDOM } from 'utils/contenteditable';
import TextContent from '../../../SlideContent/components/Text';

import styles from './styles.module.css';

type TNodesViewProps = {
  rootNode: string;
  answers: IAnswer[];
  onChange: (answers: string[]) => void;
  right?: null | boolean;
  updateRight?: (param: null | boolean) => void;
  typeOfSlide?: ESlideTypes;
};

export const NodesView: FC<TNodesViewProps> = ({ rootNode, answers, onChange, right, updateRight, typeOfSlide }) => {
  const $containerRef = useRef<HTMLDivElement>(null);
  const [initialized, setInitialized] = useState(false);
  const [filledAnswers, setFilledAnswers] = useState<number[]>(new Array(answers.length).fill(-1));
  const [answersFieldsCount, setAnswersFieldsCount] = useState(0);
  const theme = useSelector(getCurrentTheme);
  const filledAnswersCount = useMemo(() => {
    return filledAnswers.filter((item) => item >= 0).length;
  }, [filledAnswers]);

  const handleContainerClick = useCallback((evt: MouseEvent) => {
    const target = evt.target as HTMLElement;

    if (target.getAttribute('data-type') === 'mighty-answer-block') {
      updateRight && updateRight(null);
      const answerIdx = target.getAttribute('data-answer-idx');
      if (answerIdx === null) {
        return;
      }

      target.textContent = ' ';

      target.className = '';

      // target.style.transitionDelay = `${250 * Number(answerIdx)}ms`;

      setFilledAnswers((prev) => {
        const arrClone = structuredClone(prev);
        arrClone[Number(answerIdx)] = -1;
        return arrClone;
      });
    }
  }, []);

  const handleBlockClick = useCallback(
    (answerIdx: number) => () => {
      if (updateRight !== undefined) {
        updateRight(null);
      }
      setFilledAnswers((prev) => {
        const emptySlotIdx = prev.findIndex((item) => item < 0);
        if (emptySlotIdx < 0 || !$containerRef.current) {
          return prev;
        }

        const arrClone = structuredClone(prev);
        arrClone[emptySlotIdx] = answerIdx;

        const node = $containerRef.current.querySelector(`[data-answer-idx="${emptySlotIdx}"]`);
        if (node) {
          node.textContent = answers[answerIdx].text;
          node.classList.add(styles.gapsFilled);
        }

        return arrClone;
      });
    },
    [answers]
  );

  useEffect(() => {
    const userAnswers = filledAnswers.map((answerIdx) => answers[answerIdx]?.text ?? '');
    onChange(userAnswers);
  }, [filledAnswers, onChange]);

  useEffect(() => {
    if (!$containerRef.current || initialized) {
      return;
    }

    const DOMNode = extractHTMLFromJsonDOM(rootNode);
    if (!DOMNode) {
      return;
    }

    const answersCount = DOMNode.querySelectorAll('[data-mighty-type="mighty-answer-slot"]').length;
    setAnswersFieldsCount(answersCount);
    setFilledAnswers(new Array(answersCount).fill(-1));

    const formattedDOMNode = formatAnswers(DOMNode);
    $containerRef.current.innerHTML = formattedDOMNode.innerHTML;

    // Для выравнивания гепов
    const answers = $containerRef.current.querySelectorAll('[data-type="mighty-answer-block"]');
    answers.forEach((item, index) => {
      item.textContent = ' ';
      // @ts-ignore
      item.style.transitionDelay = `${250 * index}ms`;
    });

    setInitialized(true);
  }, [initialized]);

  return (
    <div>
      {typeOfSlide === ESlideTypes.FILLBLOCKS_CODE ? (
        <TextContent
          type={ESlideWidgetTypes.CODE}
          content={
            <div
              contentEditable="false"
              className={clsx('nodes-container', styles.contentPreview, styles[theme], styles['right-' + right])}
              ref={$containerRef}
              onClick={handleContainerClick}
            />
          }
        />
      ) : (
        <div
          contentEditable="false"
          className={clsx('nodes-container', styles.contentPreview, styles[theme], styles['right-' + right])}
          ref={$containerRef}
          onClick={handleContainerClick}
        />
      )}
      <div className={styles.answers}>
        {answers.map((item, idx) => (
          <Block
            isDisabled={filledAnswers.includes(idx) || filledAnswersCount >= answersFieldsCount}
            label={item.text}
            onClick={handleBlockClick(idx)}
            key={idx}
          />
        ))}
      </div>
    </div>
  );
};
