import LeftOffLesson from 'components/pages/study/courses/left-off-lesson/left-off-lesson';
// import { Premium } from 'components/shared/Premium';
import { DailyRewards } from '../../dailyRewards';
import { CARDS_SIZES } from '../../dailyRewards/types';
import ReferralsBanner from 'components/pages/referrals/banner/ReferralsBanner';

const defaultSlidesSet = [
  // { component: Premium, name: 'Premium', props: { isBannerOnly: true } },
  {
    component: ReferralsBanner,
    name: 'Referrals',
  },
  {
    component: LeftOffLesson,
    name: 'LeftOffLesson',
    props: { horizontalClassName: 'leftOffBanner' },
  },
  { component: DailyRewards, name: 'DailyRewards', props: { bannerCardsSize: CARDS_SIZES.ISSMALL } },
];
export default defaultSlidesSet;
