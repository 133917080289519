import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme, setCurrentTheme, getItems, getUserData } from 'store';
import { IDatabaseItemQuantified } from 'interface';
// import { useSearchParams } from 'react-router-dom';
import { GRADES, ITEM_IDS, THEMES } from 'app/constants';
import { dispatch } from 'hooks';
import { IWindowItem, TArtifactWindowType } from '../types/types';
import { certificatesData } from '../../../components/pages/certificates/data';

interface IProps {
  windowItem?: IWindowItem;
  type: TArtifactWindowType;
}

export const useWindowItem = ({ windowItem, type }: IProps) => {
  const theme = useSelector(getCurrentTheme);
  const itemsFromDB = useSelector(getItems);
  const {
    exp: { items: userItems },
    expTokenBalance,
  } = useSelector(getUserData);

  const [activeSidebar, setActiveSidebar] = useState<boolean>(false);
  const [item, setItem] = useState<IDatabaseItemQuantified | null | IWindowItem | any>(windowItem ? windowItem : null);
  const [otherItems, setOtherItems] = useState<IWindowItem[]>([]);
  const [, setAmountItem] = useState(0);

  // const [searchParams, setSearchParams] = useSearchParams();
  // const itemsFromDB = useSelector(getItems);
  // const userProfile = useSelector(getUserData);

  const [amountInInventory, setAmountInInventory] = useState<number>();

  useEffect(() => {
    if (!windowItem) return;

    setItem(windowItem);

    if (itemsFromDB !== undefined && 0 !== null && userItems) {
      // const currentItem = itemsFromDB.find((item) => item.id === 0)!;
      // const currentImage = currentItem.image;
      // const currentOtherItems = itemsFromDB.filter((item) => item.id !== 0 && item.type === windowItem.type);
      const currentUserItems = userItems.map((i) => i.item);
      const currentOtherItems = [...currentUserItems, ...itemsFromDB].filter((item) => item.id !== 0);
      const amountItem = userItems.find((userItem) => userItem?.id === 0);
      const currentAmount = 0 === ITEM_IDS.EXP_TOKEN ? expTokenBalance : amountItem ? amountItem.quantity : 0;

      // setItem({ ...currentItem, imageLg: currentImage });
      setOtherItems(currentOtherItems);
      setAmountItem(currentAmount);
    }
    if (type === 'certificate') {
      setOtherItems(certificatesData.filter((certificate) => certificate._id !== windowItem?._id).slice(0, 4));
    }
  }, [windowItem, type]);

  // const [searchParams, setSearchParams] = useSearchParams();

  // const { items: userItems } = userProfile.inventory ?? { items: itemsFromDB };

  // useEffect(() => {
  //   if (searchParams.get('itemId') || searchParams.get('windowItemId')) {
  //     const windowId = searchParams.get('windowItemId');
  //     const id = searchParams.get('itemId');
  //
  //     const itemId = windowId ? parseInt(windowId) : parseInt(id!);
  //
  //     if (itemsFromDB !== undefined && itemId !== null && userItems) {
  //       const currentItem: IInventoryItemFlat = itemsFromDB.find((item) => item.itemId === itemId)!;
  //       const currentImage = `${ITEMS_IMAGES_PATH}/${currentItem.imageLarge}`;
  //       const currentOtherItems: IInventoryItemFlat[] = itemsFromDB.filter((item) => item.itemId !== itemId);
  //       const balance = userProfile?.expTokenBalance;
  //       const amountItem = userItems.find((userItem) => userItem?.item?.itemId === itemId);
  //       const currentAmount: any = itemId === ITEM_IDS.EXP_TOKEN ? balance : amountItem ? amountItem.amount : 0;
  //
  //       setItem({ ...currentItem, imageLg: currentImage });
  //       setOtherItems(currentOtherItems);
  //       setAmountItem(currentAmount);
  //     }
  //   }
  // }, [searchParams.get('itemId'), searchParams.get('windowItemId')]);

  useEffect(
    () => () => {
      setItem(undefined);
      setOtherItems([]);
      setAmountInInventory(undefined);
    },
    []
  );

  // useEffect(() => {
  //   if (JSON.stringify(windowItem) !== JSON.stringify(item) && windowItem) {
  //     console.log(windowItem);
  //     setItem(windowItem);
  //   }
  // }, [windowItem]);

  // function setSearch(windowItemId: string) {
  //   searchParams.set('windowItemId', windowItemId);
  //   return setSearchParams(searchParams);
  // }

  function toggleSidebar() {
    return setActiveSidebar(!activeSidebar);
  }

  function toggleTheme() {
    dispatch(setCurrentTheme(theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK));
  }

  // function currentImage() {
  //   if (windowItem) {
  //     return windowItem.image;
  //   }
  //   return item.image;
  // }

  function currentButtonBg(): string {
    const grade = item?.grade ? item.grade : GRADES.MYTHICAL;
    return theme === 'dark' ? 'rgba(255, 255, 255, 0.04)' : `var(--grade-light-color-${grade}-bg)`;
  }

  return {
    theme,
    activeSidebar,
    item,
    otherItems,
    amountInInventory,
    // // setSearch,
    toggleSidebar,
    toggleTheme,
    // // currentImage,
    currentButtonBg,
  };
};
