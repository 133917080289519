import { ESlideTypes } from 'store';

import MultiTest from '../MultiTest/MultiTest';
import Fillspaces from '../Fillspaces/Fillspaces';
import TypeAnswer from '../TypeAnswer/TypeAnswer';
import Fillblocks from '../Fillblocks/Fillblocks';
import FillspacesCode from '../FillspacesCode/FillspacesCode';
import FillblocksCode from '../FillblocksCode/FillblocksCode';
import { SlideContent } from '../../SlideContent';

import * as Styled from './styles';
import React, { useEffect, useMemo, useState } from 'react';
import { useGetCurrentTheme, useGetItems } from 'hooks';
import { ReactComponent as CompletedIcon } from 'assets/icons/check-fill-star.svg';
import clsx from 'clsx';

const TestFrame = ({ slide, right, updateRight, testsIds, isCompleted = false, isRewarded = false }) => {
  const theme = useGetCurrentTheme();
  const items = useGetItems();
  // радио/галочки
  let Content = MultiTest;
  // один большой ввод текста
  if (slide.typeOfSlide === ESlideTypes.TYPEANSWER) Content = TypeAnswer;
  // пробелы в тексте, писать надо прям внутри пробелов
  if (slide.typeOfSlide === ESlideTypes.FILLSPACES) Content = Fillspaces;
  // пробелы в тексте, писать надо прям внутри пробелов, но на фоне кода
  if (slide.typeOfSlide === ESlideTypes.FILLSPACES_CODE) Content = FillspacesCode;
  // пробелы в тексте, в конце есть блоки-выборы ответов с готовыми ответами, которые на клик занимают свои места в тексте
  // но на фоне кода
  if (slide.typeOfSlide === ESlideTypes.FILLBLOCKS_CODE) Content = FillblocksCode;
  // пробелы в тексте, в конце есть блоки-выборы ответов с готовыми ответами, которые на клик занимают свои места в тексте
  if (slide.typeOfSlide === ESlideTypes.FILLBLOCKS) Content = Fillblocks;

  const isFinished = useMemo(() => (isRewarded || !slide.slideType.isOptional) && isCompleted, [isCompleted, isRewarded, slide]);

  const [isOpen, setIsOpen] = useState(!isFinished);
  const toggleOpen = () => setIsOpen((s) => !s);

  useEffect(() => {
    // set the initial state of "isOpen", right === null means that user did not answer (a.k.a. he answered before, but did not answer a second time)
    if (right === null && !!isFinished) {
      setIsOpen(false);
    }
  }, [isFinished, right]);

  const rewsImages = useMemo(() => {
    const { rewards } = slide;

    return items
      .filter((el) => rewards.find((rew) => rew.id === el.id))
      .map((el) => el.imageThumb)
      .slice(0, 3);
  }, [items, slide]);

  const currentTestIndex = useMemo(() => (testsIds ? testsIds.findIndex((el) => el === slide._id) + 1 || 1 : 1), [testsIds, slide]);
  const imagesElem = (
    <>
      {!!rewsImages.length && (
        <Styled.RewsImages>
          {rewsImages.map((src, index) => (
            <Styled.RewsImage
              key={src + index + index}
              variants={Styled.getRewsImageVariants(0.7 + index * 0.2)}
              initial={'initial'}
              animate={'animate'}
              src={src}
            />
          ))}
        </Styled.RewsImages>
      )}
    </>
  );

  return (
    <>
      <Styled.Container>
        <div className="lesson-content-space">
          <h3>{slide.name}</h3>
          {Boolean(slide.content.length) && (
            <>
              <Styled.Body>
                <SlideContent slide={slide} />
              </Styled.Body>
            </>
          )}
        </div>
      </Styled.Container>
      <Styled.Content>
        <Styled.Heading
          $theme={theme}
          $items={rewsImages.length}
          onClick={toggleOpen}
          className={clsx({ isWrong: right === false, isCompleted: isFinished, isOpen })}
        >
          {imagesElem}
          Test {currentTestIndex}: {slide.typeOfSlide}
          {isFinished && <CompletedIcon />}
          <Styled.Chevron $isOpen={isOpen} />
        </Styled.Heading>
        <Styled.ContentBox className={clsx({ isOpen })}>
          <Styled.ContentOverflowWrap className={clsx({ isOpen })}>
            <Content
              className="lesson-button"
              {...slide.slideType}
              slideId={slide._id}
              content={slide.content}
              right={right}
              updateRight={updateRight}
              elementWithCondition={{
                element: !!rewsImages.length ? <RewardsClaimed rewards={slide.rewards} /> : <Styled.ClosingGap />,
                condition: !!right,
              }}
            />
          </Styled.ContentOverflowWrap>
        </Styled.ContentBox>
      </Styled.Content>
    </>
  );
};

export const RewardsClaimed = ({ rewards }) => {
  const items = useGetItems();
  const rewsImages = useMemo(() => {
    return items
      .filter((el) => rewards.find((rew) => rew.id === el.id))
      .map((el) => el.imageThumb)
      .slice(0, 3);
  }, [items, rewards]);

  return (
    <Styled.RewardsClaimed
      variants={Styled.rewsClaimedVariants}
      initial={'initial'}
      animate={'animate'}
      exit={'exit'}
      $isRewards={!!rewsImages.length}
    >
      {!!rewsImages.length ? 'Rewards Claimed' : ''}
      <Styled.RewsImages>
        {rewsImages.map((src, index) => (
          <Styled.RewsImage
            key={src + index + index}
            variants={Styled.getRewsImageVariants(0.7 + index * 0.2)}
            initial={'initial'}
            animate={'animate'}
            src={src}
          />
        ))}
      </Styled.RewsImages>
    </Styled.RewardsClaimed>
  );
};

export default React.memo(TestFrame);
