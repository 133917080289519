import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: flex;
      align-items: center;
      position: relative;
      height: 76px;
      cursor: pointer;
      &,
      & * {
        user-select: none;
      }
      &.mobile {
        height: 68px;
        padding: 8px 0 8px 9.5px;
        ${ImageContainer} {
          height: 52px;
          width: 52px;
          min-height: 52px;
          min-width: 52px;
        }

        ${Title} {
          font-weight: 400;
        }
      }
    `
  ),
  TitleWrap = styled.span(
    () => css`
      position: relative;
      left: -20px;
      z-index: 2;
      height: 38px;
      width: fit-content;
      padding: 10px 20px 10px;
    `
  ),
  BG = styled.div(
    () => css`
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      border-radius: 4px;
      height: 100%;
      width: 100%;
      backdrop-filter: blur(10px);

      transition: all 0.1s ease-in-out;
    `
  ),
  Title = styled.span(
    () => css`
      position: relative;
      z-index: 2;
      font-size: 18px;
      font-weight: 500;
      line-height: 1;
      text-transform: capitalize;
      color: #fff;
      letter-spacing: 0.5px;
      white-space: nowrap;
    `
  ),
  ImageContainer = styled.div(
    () => css`
      position: relative;
      height: 76px;
      width: 76px;
      min-height: 76px;
      min-width: 76px;
      z-index: 3;
      margin-right: 8px;
    `
  ),
  Image = styled.img(
    () => css`
      position: absolute;
      transform: rotateY('45deg');
      height: 100%;
      width: 100%;
      min-height: 100%;
      min-width: 100%;
      object-fit: contain;
    `
  );
