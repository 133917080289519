import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      display: grid;
      grid-template-areas:
        'label big'
        'bar bar';
      justify-content: space-between;
      row-gap: 8px;
      column-gap: 4px;
      width: 100%;
      & * {
        color: var(--font-color-strong);
      }

      &.none,
      &.small {
        display: flex;
        align-items: center;
      }
    `
  ),
  BarContainer = styled.div<{ $height?: string; $background?: string }>(
    ({ $height = '6px', $background = 'rgba(255, 255, 255, 0.5)' }) => css`
      height: ${$height};
      width: 100%;
      position: relative;
      border-radius: 10px;
      overflow: hidden;
      background: ${$background};

      &.big {
        grid-area: bar;
      }
    `
  ),
  Bar = styled.div<{ $height?: string; $background?: string }>(
    ({ $height = '6px', $background = 'var(--font-color-strong)' }) => css`
      height: ${$height};
      width: 100%;
      background: ${$background};
      border-radius: 10px;
      position: absolute;
      left: -2%;
    `
  ),
  Numbers = styled.span(
    () => css`
      font-size: 1.3rem;
      font-weight: 500;

      &.big {
        grid-area: big;
      }
    `
  ),
  Label = styled.span(
    () => css`
      font-size: 14px;
      font-weight: 400;
      line-height: 100%;
      display: none;

      &.big {
        display: inline-block;
        grid-area: label;
      }
    `
  );
