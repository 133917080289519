import StarterKit from '@tiptap/starter-kit';
import TiptapEditor from '../tiptapEditor';
import * as S from './styles';
import TextStyle from '@tiptap/extension-text-style';
import Color from '@tiptap/extension-color';
import Underline from '@tiptap/extension-underline';
import Text from '@tiptap/extension-text';
import Bubble from '../courseConstructor/components/Widget/components/TextWidget/components/Bubble';
import { Toggle } from 'shared/ui';
import { EToggleSize } from 'shared/ui/toggle/types';
import { memo, useCallback, useEffect, useMemo, useState } from 'react';
import CustomizationMenu from '../courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/NewCustomizationMenu';
import { EIcons, ESpecialIcons } from '../courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/constants';
import { ReactComponent as OkIcon } from './assets/ok.svg';
import { MenuIcons } from '../courseConstructor/components/Widget/components/TaskWidget/NewCustomizationMenu/ui';
import { ReactComponent as ArrowIcon } from './assets/arrow.svg';
import { AnimatePresence } from 'framer-motion';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { TTaskWidgetProps } from '../courseConstructor/components/Widget/components/TaskWidget/TaskWidget';
import { LinkModal } from 'features/userLinks';
import { EAuthMethods } from 'interface';
import { timeout } from 'shared/utils';
import { getTextColor } from 'components/pages/spaces/ui/hooks';
import { useDisplay, useGetCurrentTheme } from 'hooks';

const SocialTask = memo(
  ({
    icon = EIcons.INFO,
    color = '#FDFDFD',
    content: defaultContent = 'Type task description...',
    title: defaultTitle = 'Task',
    link: defaultLink = 'https://link_address.com',
    editable = true,
    isRequired: defaultIsRequired = false,
    onChange,
    onTaskComplete,
  }: TTaskWidgetProps) => {
    const [customization, setCustomization] = useState<{
      icon: EIcons | ESpecialIcons;
      color: string;
    }>({
      color: color,
      icon: icon,
    });
    const [title, setTitle] = useState(defaultTitle);
    const [link, setLink] = useState(defaultLink);
    const [completed, setCompleted] = useState(false);
    const [content, setContent] = useState(defaultContent);
    const [isRequired, setIsRequired] = useState(defaultIsRequired);
    const [isChanged, setIsChanged] = useState(false);
    const [authMethod, setAuthMethod] = useState<EAuthMethods | null>(null);
    const [isTimingOut, setIsTimingOut] = useState(false);
    const theme = useGetCurrentTheme();
    const { isMobile } = useDisplay();

    const textColor = useMemo(() => getTextColor(color, theme, 40), [color, theme]);

    const handleSelect = (changes: Partial<{ icon: EIcons | ESpecialIcons; color: string }>) => {
      setCustomization((s) => ({ ...s, ...changes }));
    };

    const handleClick = useCallback(async () => {
      if (!completed && !editable) {
        window.open(defaultLink);
        setIsTimingOut(true);

        if (onTaskComplete) {
          const max = 10;
          let counter = 0;
          while (counter < max) {
            const {
              status: isCompleted,
              isStopped,
              stopAction,
            } = await Promise.resolve(onTaskComplete()).then(function (value: any) {
              return value;
            });
            if (isStopped) {
              if (stopAction === 'link-modal--TON') {
                setAuthMethod(EAuthMethods.TON);
              }
              setIsTimingOut(false);
              break;
            }

            if (isCompleted) {
              setCompleted(true);
              setIsTimingOut(false);
              break;
            }
            await timeout(2000);
            counter++;
          }
        }
        setIsTimingOut(false);
      }
    }, [defaultLink, completed, editable, onTaskComplete]);

    useEffect(() => {
      if (onChange && isChanged) {
        onChange({
          icon: customization.icon,
          color: customization.color,
          link,
          title,
          content,
          isRequired,
        });
        setIsChanged(false);
      }
    }, [isChanged]);

    useEffect(() => {
      if (
        (typeof color !== 'undefined' && customization.color !== color) ||
        (typeof icon !== 'undefined' && customization.icon !== icon) ||
        (typeof defaultLink !== 'undefined' && link !== defaultLink) ||
        (typeof defaultContent !== 'undefined' && content !== defaultContent) ||
        (typeof defaultTitle !== 'undefined' && title !== defaultTitle) ||
        isRequired !== defaultIsRequired
      ) {
        setIsChanged(true);
      }
    }, [customization, content, link, title, isRequired, color, icon, defaultContent, defaultLink, defaultTitle, defaultIsRequired]);

    return (
      <>
        <LinkModal selectedMethod={authMethod} clearMethod={() => setAuthMethod(null)} />
        <S.TaskContainer $theme={theme} $completed={completed} $bgColor={customization.color || ''}>
          <S.TaskTop>
            <CustomizationMenu
              current={customization}
              onSelect={handleSelect}
              Element={({ onClick, isOpen }: any) => (
                <ImageFrame isOpen={isOpen} onClick={onClick} color={customization.color} icon={customization.icon} editable={editable} />
              )}
            />
            <S.FlexCol $jcc style={{ height: isMobile ? undefined : '54px' }}>
              {editable ? (
                <S.Title
                  $color={textColor}
                  value={title}
                  onChange={(e) => {
                    setTitle(e.target.value);
                  }}
                />
              ) : (
                <S.TitleStr $color={textColor}>{title}</S.TitleStr>
              )}
              <TiptapEditor
                extensions={[StarterKit, Text, TextStyle, Color, Underline]}
                bubble={(editor) => <Bubble editor={editor} />}
                content={content || ''}
                className='task_editor'
                placeholder="Open link"
                onChange={(c) => {
                  setContent(c);
                }}
                editable={editable}
              />
            </S.FlexCol>
            <AnimatePresence>
              {isTimingOut ? (
                <MightyLoaderMini style={{ width: '46px', height: '46px' }} />
              ) : (
                <S.TaskButton
                  $theme={theme}
                  onClick={handleClick}
                  $completed={completed}
                  $editable={editable}
                  $bgColor={customization.color}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: 20, opacity: 0 }}
                  initial={{ y: 20, opacity: 0 }}
                  $color={textColor}
                >
                  {completed ? (
                    <>
                      Done <OkIcon />
                    </>
                  ) : (
                    'Complete task'
                  )}
                </S.TaskButton>
              )}
            </AnimatePresence>
          </S.TaskTop>
          {editable ? (
            <S.TaskBottom>
              <S.FlexCol>
                <S.TaskLabel $color={textColor}>Link</S.TaskLabel>
                <S.TaskInput
                  $theme={theme}
                  $color={textColor}
                  value={link}
                  onChange={(e) => {
                    setLink(e.target.value);
                  }}
                  placeholder="Enter link"
                />
              </S.FlexCol>
              <S.TogglerBox>
                <S.TogglerLabel>Optional</S.TogglerLabel>
                <Toggle
                  checked={!isRequired}
                  onChange={() => {
                    setIsRequired((s) => !s);
                  }}
                  toggleSize={EToggleSize.XL}
                />
              </S.TogglerBox>
            </S.TaskBottom>
          ) : null}
        </S.TaskContainer>
        {completed ? <S.Message>Good job!</S.Message> : null}
      </>
    );
  }
);

interface IImageFrameProps {
  isOpen: boolean;
  onClick: () => void;
  color: string;
  icon: EIcons | ESpecialIcons;
  editable: boolean;
}

const ImageFrame = memo(({ isOpen, onClick, color, icon, editable }: IImageFrameProps) => {
  const theme = useGetCurrentTheme();

  return (
    <>
      {editable ? (
        <S.ImageFrame $theme={theme} onClick={onClick}>
          <S.Image color={color}>
            <MenuIcons type={icon as ESpecialIcons | EIcons} />
          </S.Image>
          <ArrowIcon style={{ transform: `rotate(${isOpen ? 0 : 180}deg)`, transition: '0.3s' }} />
        </S.ImageFrame>
      ) : (
        <S.Image $solo={true} color={color}>
          <MenuIcons type={icon as ESpecialIcons | EIcons} />
        </S.Image>
      )}
    </>
  );
});

export default SocialTask;
