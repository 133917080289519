import { FC } from 'react';
import IconButton from '../../../../../../../../iconButton';
import { AiOutlineClose } from 'react-icons/ai';

import * as Elements from './styles';

export type TWrongAnswers = {
  wrongAnswers: string[];
  onDelete: (answer: string) => void;
};

export const WrongAnswers: FC<TWrongAnswers> = ({ wrongAnswers, onDelete }) => {
  const handleDeleteAnswer = (answer: string) => () => {
    onDelete(answer);
  };

  return (
    <Elements.SRoot>
      <span>Incorrect Answers</span>
      <Elements.SAnswersWrapper>
        {wrongAnswers.map((answer, idx) => (
          <Elements.SAnswer key={idx}>
            <span>{answer}</span>
            <IconButton size={20} icon={<AiOutlineClose size={15} />} onClick={handleDeleteAnswer(answer)} />
          </Elements.SAnswer>
        ))}
      </Elements.SAnswersWrapper>
    </Elements.SRoot>
  );
};
