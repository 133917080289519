import { SecondaryButton } from './styles';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as OkIcon } from 'assets/icons/checked.svg';
import { useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import clsx from 'clsx';
import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { NullButton } from 'shared/ui';
import { CSSProperties } from 'styled-components';

type Props = {
  handleCopy?: () => void;
  style?: CSSProperties;
};

export const CopyButton = ({ handleCopy, style }: Props) => {
  const [copied, setCopied] = useState(false);
  const theme = useSelector(getCurrentTheme);
  const onClick = () => {
    !!handleCopy && handleCopy();
    setCopied(true);
  };

  useEffect(() => {
    let timeout: any;
    if (copied) {
      timeout = setTimeout(() => setCopied(false), 2000);
    }
    return () => clearTimeout(timeout);
  }, [copied]);

  useEffect(() => {
    return () => setCopied(false);
  }, []);

  const variants = {
    initial: {
      opacity: 0,
      y: 10,
    },
    animate: {
      opacity: 1,
      y: 0,
    },
    exit: {
      opacity: 0,
      y: -20,
    },
  };

  const iconStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  };

  return (
    <SecondaryButton style={style} className={clsx('button', theme)} as={NullButton} onClick={onClick}>
      <AnimatePresence exitBeforeEnter>
        {copied ? (
          <motion.div key={'copied'} style={iconStyle} variants={variants} animate={'animate'} initial={'initial'} exit={'exit'}>
            <OkIcon />
          </motion.div>
        ) : (
          <motion.div key={'uncopied'} style={iconStyle} variants={variants} animate={'animate'} initial={'initial'} exit={'exit'}>
            <CopyIcon />
          </motion.div>
        )}
      </AnimatePresence>
    </SecondaryButton>
  );
};
