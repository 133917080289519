import styled, { css } from 'styled-components';

export const Wrap = styled.div(
    () => css`
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 992px) {
        width: 100%;
        padding: 0 20px;
      }
    `
  ),
  Head = styled.div<{ $border?: string; $padding?: string }>(
    ({ $border, $padding = '0 19px' }) => css`
      display: flex;
      align-items: center;
      justify-content: center;
      padding: ${$padding};
      border-radius: 4px;
      position: absolute;
      top: -28px;
      z-index: 2;
      background: linear-gradient(283deg, rgb(53 68 111 / 50%) 28.94%, rgba(255, 255, 255, 0.1) 143.24%);
      backdrop-filter: blur(20px);
      height: 38px;
      border: ${$border};
    `
  ),
  Body = styled.div<{ $padding?: string; $background?: string }>(
    ({ $padding = '24px 19px 14px 30px', $background = 'rgba(255, 255, 255, 0.03)' }) => css`
      width: 345px;
      height: 157px;
      background: ${$background};
      border: 1px solid rgba(255, 255, 255, 0.06);
      backdrop-filter: blur(39.5px);
      border-radius: 10px;
      padding: ${$padding};

      &.light {
        border-color: var(--basic-border-color);
      }

      @media screen and (max-width: 992px) {
        width: 100%;
      }
    `
  );
