import { FC, useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import CountUp from 'react-countup';

import { EnergyAnimation } from './components';

import { ReactComponent as EnergyBg } from './assets/energy--bg.svg';
import { ReactComponent as EnergyIcon } from 'assets/icons/lightning.svg';
import { useGetEffectData } from '../../../hooks';
import { useUserEnergyLevel } from 'hooks';

import { CountUpWrap, Percent } from '../styled';
import * as Styled from './styled';
import { EItemFungibleType } from 'interface';

interface IEnergyProps {
  isActive?: boolean;
  onEnd?: () => void;
  type: EItemFungibleType.SmallEnergyPotion | EItemFungibleType.LargeEnergyPotion;
}

export const Energy: FC<IEnergyProps> = ({ isActive, onEnd, type }) => {
  const [isEnd, setIsEnd] = useState(false);
  const data = useGetEffectData(type as EItemFungibleType);
  const { energyPersentage } = useUserEnergyLevel();
  let value = 0;

  if (data) {
    value = data.value;
  }

  const currentEnergy = useMemo(() => energyPersentage, []);
  const newEnergy = currentEnergy + value > 100 ? 100 : currentEnergy + value;

  const handleEnd = () => {
    setIsEnd(true);
    onEnd && setTimeout(() => onEnd(), 1000);
  };

  if (isActive) {
    return (
      <CountUpWrap $color={isEnd ? '#B8DA76' : ''} $gap={'18px'}>
        <EnergyAnimation isEnd={isEnd} />
        <CountUp useEasing={true} duration={2} delay={0.3} start={currentEnergy} end={newEnergy} suffix={'%'} onEnd={handleEnd} />
      </CountUpWrap>
    );
  }

  return (
    <Styled.Container>
      <Styled.Energy>
        <EnergyBg />
        <div className={'energy__data'}>
          <svg xmlns="http://www.w3.org/2000/svg" width="81" height="9" viewBox="0 0 81 9" fill="none">
            <rect x="0.6875" y="0.179688" width="80" height="8" rx="4.13462" fill="#111111" fillOpacity="0.4" />
            <rect x="0.6875" y="0.179688" width={(currentEnergy / 100) * 80} height="8" rx="4.13462" fill="#2AAB8E" />
          </svg>
          <span>{currentEnergy}%</span>
          <EnergyIcon color={'#e0e0e0'} />
        </div>
      </Styled.Energy>
      <Percent $color={'#b8da77'} $textShadowColor={'rgba(184, 218, 119, 0.3)'}>
        +{value}
        <span>%</span>
      </Percent>
      <Styled.Energy $increasing>
        <EnergyBg />
        <div className={'energy__data'}>
          <motion.svg xmlns="http://www.w3.org/2000/svg" width="81" height="9" viewBox="0 0 81 9" fill="none">
            <rect x="0.6875" y="0.179688" width="80" height="8" rx="4.13462" fill="#111111" fillOpacity="0.4" />
            <motion.rect
              initial={{ width: (currentEnergy / 100) * 80 }}
              animate={{ width: (newEnergy / 100) * 80 }}
              transition={{
                delay: 0.2,
                duration: 1,
              }}
              x="0.6875"
              y="0.179688"
              height="8"
              rx="4.13462"
              fill="#2AAB8E"
            />
          </motion.svg>
          <CountUp useEasing={true} duration={1} delay={0.2} start={currentEnergy} end={newEnergy} suffix={'%'} />
          <EnergyIcon color={'#e0e0e0'} />
        </div>
      </Styled.Energy>
    </Styled.Container>
  );
};
