import { RefObject, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getIdText, getSelectedText, setSelectedText, usePostCommentMutation, getUserData } from 'store';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { SButtonWrapper, SContentWrapper } from './styles';
import { BasicButtonV1, Input } from 'shared/ui';
import { toast } from 'react-toastify';
import Answer from 'components/library/messages/Answer';
import { useDebounce } from 'hooks';

type IUseSelectedText = {
  schema: any;
  id: string;
  editor?: any;
  slideId?: string;
  trigger?: RefObject<HTMLDivElement>;
};

export const useSelectedText = ({ schema, id, editor, slideId, trigger }: IUseSelectedText) => {
  const selectedText = useSelector(getSelectedText);
  const idText = useSelector(getIdText);
  const { _id } = useSelector(getUserData);
  const dispatch = useDispatch();
  const debounce = useDebounce();
  const containerRef = useRef<HTMLDivElement>(null);

  const [sendComments, { isLoading }] = usePostCommentMutation();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    let element: any;
    if (editor) {
      element = editor.options.element as HTMLDivElement;
      element.addEventListener('mouseup', handleTextSelection);
    } else {
      element = trigger?.current;
      element.addEventListener('mouseup', handleTextSelection);
    }
    return () => {
      element.removeEventListener('mouseup', handleTextSelection);
      dispatch(setSelectedText({ selectedText: '', idText: '' }));
    };
  }, []);

  const handleTextSelection = useCallback(
    debounce(() => {
      const selection = window.getSelection();
      const tooltip = containerRef.current;
      if (!tooltip) {
        handleFormReset();
        return;
      }

      if (!selection) {
        handleFormReset();
        return;
      }
      const resetTooltip = () => {
        if (tooltip) {
          tooltip.style.top = '';
          tooltip.style.left = '';
          tooltip.style.translate = '';
        }
      };

      const setPositionTooltip = (tooltip: HTMLDivElement, top: number, right: number) => {
        tooltip.style.top = `${top}px`;
        tooltip.style.left = `${right}px`;
        tooltip.style.translate = '0 -50px';
      };

      const selectedText = selection.toString().trim();

      if (selectedText) {
        if (!tooltip) return;
        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();
        const { top, right } = rect;

        setPositionTooltip(tooltip, top, right);

        dispatch(setSelectedText({ selectedText, idText: id }));
      } else {
        resetTooltip();
        handleFormReset();
      }
    }, 10),
    []
  );

  const onSubmit = async (data: any) => {
    if (slideId) {
      const comment: any = {
        userId: _id,
        comment: {
          selectedText,
          commentText: data.comment,
          createdAt: Date.now(),
        },
        slideId,
        widgetId: id,
      };

      await sendComments(comment)
        .unwrap()
        .then(() => {
          handleFormReset();
          toast.success(<Answer type="correct" label="Comment sent successfully!" />);
        })
        .catch(() => toast.error(<Answer type="incorrect" label="Error while sending the comment" />));
    }
  };

  function handleFormReset() {
    reset();
    dispatch(setSelectedText({ selectedText: '', idText: '' }));
  }

  const tabs = [
    {
      title: 'Comment',
      content: (
        <SContentWrapper onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <Input
                autoFocus
                defaultValue={field.value}
                placeholder={'Write your comment here...'}
                isError={!!errors?.comment?.message}
                {...field}
              />
            )}
          />
          <SButtonWrapper>
            <BasicButtonV1 type={'submit'} variant={'solid'} isLoading={isLoading} disabled={isLoading}>
              Comment
            </BasicButtonV1>
            <BasicButtonV1 type={'button'} variant={'outline'} onClick={handleFormReset}>
              Cancel
            </BasicButtonV1>
          </SButtonWrapper>
        </SContentWrapper>
      ),
    },
    {
      title: 'Suggest',
      content: (
        <SContentWrapper onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="comment"
            control={control}
            render={({ field }) => (
              <Input
                autoFocus
                defaultValue={field.value}
                placeholder={'Write your comment here...'}
                isError={!!errors?.comment?.message}
                {...field}
              />
            )}
          />
          <SButtonWrapper>
            <BasicButtonV1 type={'submit'} variant={'solid'} isLoading={isLoading} disabled={isLoading}>
              Comment
            </BasicButtonV1>
            <BasicButtonV1 type={'button'} variant={'outline'} onClick={handleFormReset}>
              Cancel
            </BasicButtonV1>
          </SButtonWrapper>
        </SContentWrapper>
      ),
    },
  ];

  return {
    selectedText,
    idText,
    tabs,
    containerRef,
  };
};
