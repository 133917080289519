import { ErrorHandler } from 'shared/utils/ErrorHandler';
import { meditationApi, store } from 'store';

export const meditationLoader = (): any => {
  const meditationFromLoader = store.dispatch(meditationApi.endpoints.getMeditation.initiate());

  try {
    const response = meditationFromLoader.unwrap().catch(ErrorHandler);

    return {
      meditationFromLoader: response,
    };
  } catch (e) {
    throw new Error('An error occurred while loading meditation.');
  } finally {
    meditationFromLoader.unsubscribe();
  }
};
