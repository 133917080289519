import { RENEWAL_TIME_CET } from 'app/constants';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const getCooldown = (from = new Date()) => {
  // 5 AM UTC === 6 AM CET
  const today6AMCET = dayjs().utc().set('hours', RENEWAL_TIME_CET).set('minute', 0).set('second', 0);
  const tommorow6AMCET = dayjs(today6AMCET).subtract(1, 'day');
  const yesterday6AMCET = dayjs(today6AMCET).add(1, 'day');
  const lastEvent = dayjs(from).utc();
  const isCooldown = lastEvent.isAfter(today6AMCET);

  return {
    isCooldown,
    fiveAmUtcToday: today6AMCET,
    fiveAmUtcYesterday: tommorow6AMCET,
    fiveAmUtcTomorrow: yesterday6AMCET,
  };
};
