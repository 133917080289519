import { useDashboardContext } from 'pages/coursesConstructor/parts/dashboard';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  IComment,
  useLazyGetCommentsByChapterQuery,
  useLazyGetCommentsByCourseQuery,
  useLazyGetCommentsByLessonQuery,
  useLazyGetCommentsBySlideQuery,
} from 'store';
import {
  useLazyGetAnswersByChapterIdQuery,
  useLazyGetAnswersByCourseIdQuery,
  useLazyGetAnswersByLessonIdQuery,
  useLazyGetAnswersBySlideIdQuery,
} from 'store/answers/answers.api';
import { IAnswer } from 'store/answers/types';

const HOOKS = {
  answers: {
    course: useLazyGetAnswersByCourseIdQuery,
    chapter: useLazyGetAnswersByChapterIdQuery,
    lessons: useLazyGetAnswersByLessonIdQuery,
    slide: useLazyGetAnswersBySlideIdQuery,
  },
  comments: {
    course: useLazyGetCommentsByCourseQuery,
    chapter: useLazyGetCommentsByChapterQuery,
    lessons: useLazyGetCommentsByLessonQuery,
    slide: useLazyGetCommentsBySlideQuery,
  },
};

const useGetNeededRequestHook = (type: 'answers' | 'comments', slideId?: string) => {
  const { lessonId, chapterId, courseId } = useParams();

  if (!!slideId) {
    return { hook: HOOKS[type].slide, id: slideId };
  }
  if (lessonId) {
    return { hook: HOOKS[type].lessons, id: lessonId };
  }
  if (chapterId) {
    return { hook: HOOKS[type].chapter, id: chapterId };
  }
  if (courseId) {
    return { hook: HOOKS[type].course, id: courseId };
  }

  return { hook: HOOKS[type].slide, id: '' };
};

const removeItem = (setState: any) => (_id: string) => setState((s: any[]) => s.filter((i) => i._id !== _id));

export const useGetComments = (slideId?: string) => {
  const [comments, setComments] = useState<IComment[]>([]);
  const { hook, id } = useGetNeededRequestHook('comments', slideId);
  const { setCommentsCount } = useDashboardContext();
  const [getItems, { isFetching }] = hook();

  useEffect(() => {
    setComments([]);
    if (!!id) {
      getItems(id, false)
        .unwrap()
        .then((res) => {
          setComments(res.items as unknown as IComment[]);
        })
        .catch(console.log);
    }
  }, [hook, getItems, id]);

  useEffect(() => {
    setCommentsCount(comments.length);
  }, [comments.length]);

  return { comments, isLoading: isFetching, removeComment: removeItem(setComments) };
};

export const useGetAnswers = (slideId?: string) => {
  const [answers, setAnswers] = useState<IAnswer[]>([]);
  const { hook, id } = useGetNeededRequestHook('answers', slideId);
  const { setAnswersCount } = useDashboardContext();
  const [getItems, { isFetching }] = hook();

  useEffect(() => {
    setAnswers([]);
    if (!!id) {
      getItems(id, false)
        .unwrap()
        .then((res) => {
          setAnswers(res.items as unknown as IAnswer[]);
        })
        .catch(console.log);
    }
  }, [hook, getItems, id]);

  useEffect(() => {
    setAnswersCount(answers.length);
  }, [answers.length]);

  return { answers, isLoading: isFetching, removeAnswer: removeItem(setAnswers) };
};
