import { useCallback, useEffect, useMemo, useReducer } from 'react';
import ReactDom from 'react-dom';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { ROUTES } from 'app/constants';

import { useDebounce, useDisplay, useObserver } from 'hooks';
import { useAddFriend } from 'hooks';
import useRemoveFriend from 'hooks/useRemoveFriend';
import { useNavigate } from 'react-router-dom';
import { useLazyGetUserDataQuery, useRejectBattleMutation } from 'store';
import useClickOutside from 'hooks/useClickOutside';
import { useReadNotifs } from './helpers';
import { useGetMoreEvents } from './helpers/useGetMoreEvents';

import { FaSpinner } from 'react-icons/fa';
import { BsCheck2All, BsChevronDown } from 'react-icons/bs';

import Notif from './Notif';
import Groups from 'components/pages/study/courses/mobile-searchbar/Groups';
import Group from 'components/pages/study/courses/mobile-searchbar/SearchScreen/Group';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import Answer from '../messages/Answer';

import styles from './styles.module.scss';
import './notifications.css';

const defaultState = {
  items: [],
  filter: 'all',
  isViewAll: false,
  isLoadingState: false,
  page: 1,
  totalPages: 1,
  isLoading: false,
};

const Notifications = ({ isOpen = true, onClose }) => {
  const debounce = useDebounce();
  const domNode = useClickOutside(onClose);
  const { isMobile } = useDisplay();
  const { removeFriend } = useRemoveFriend();
  const { addFriend, isLoading: addLoading } = useAddFriend();
  const [rejectBattle] = useRejectBattleMutation();
  const [getUser] = useLazyGetUserDataQuery();
  const navigate = useNavigate();
  const [{ isLoading, items, filter, isViewAll, isLoadingState, page, totalPages }, updateState] = useReducer(
    (prev, next) => ({ ...prev, ...next }),
    {
      items: [],
      filter: 'all',
      isViewAll: false,
      isLoadingState: false,
      page: 1,
      totalPages: 1,
      isLoading: false,
    }
  );

  const { readNotifitations, isLoading: isReading } = useReadNotifs({ updateState });

  const counters = useMemo(() => {
    const list = [];
    items.forEach((item) => (list[item.subject] ? list[item.subject]++ : (list[item.subject] = 1)));
    return list;
  }, [items]);

  const {
    handleRefetch,
    isLoadable,
    isLoading: isFetchingNotifications,
  } = useGetMoreEvents({
    updateState,
    page,
    totalPages: totalPages,
    items,
    isLoading,
  });

  const handlerLoader = useCallback(
    debounce((isVisible) => {
      if (isVisible && isLoadable) handleRefetch();
    }, 200),
    [isLoadable, handleRefetch]
  );

  const setObserverRef = useObserver({ handler: handlerLoader });

  useEffect(() => {
    updateState(defaultState);
    handlerLoader(true);
  }, [isOpen]);

  const handleAddFriend = async (friend) =>
    await addFriend(friend._id).then(() => toast.success(<Answer label={`${friend.nickname} is now your friend!`} type="correct" />));

  const handleDeclineFriend = async (id) =>
    await removeFriend(id).catch((ex) => {
      console.error(ex);
      toast.error('Oops! Some error occured:/');
    });

  const handleAcceptChallenge = (battleId) => {
    navigate(`${ROUTES.FULLSCREEN}/${ROUTES.FULLSCREEN_MINDBATTLE}/${battleId}`, { replace: true });
  };

  const handleRejectBattleChallenge = async (battleId) => {
    updateState({ isLoadingState: true });
    await rejectBattle({ battleId })
      // TODO переделать. Незачем всего юзера тащить
      .then(getUser)
      .catch((ex) => {
        console.log(ex);
        toast.error('Oops! Some error occured:/');
      })
      .finally(() => updateState({ isLoadingState: false }));
  };

  const totalCounter = useMemo(() => Object.values(counters).reduce((acc, next) => acc + next, 0), [counters]);

  const itemsContent = useMemo(
    () =>
      items
        .filter((i) => (filter === 'all' ? true : i.subject === filter))
        .map((item) => (
          <Notif
            key={'notification' + item._id}
            {...item}
            loading={addLoading || isLoadingState}
            handleAddFriend={handleAddFriend}
            handleDeclineFriend={handleDeclineFriend}
            handleAcceptChallenge={handleAcceptChallenge}
            handleRejectBattleChallenge={handleRejectBattleChallenge}
          />
        )),
    [items, filter, addLoading, isLoadingState]
  );

  if (isMobile) {
    const groups = ['all'].concat(Object.keys(counters));
    const countersForGroups = totalCounter > 0 && groups.map((group) => (group === 'all' ? totalCounter : counters[group]));

    return (
      <div>
        <Group
          header="Categories"
          className="mobile-padding categories"
          containerClassName="categories-group"
          component={
            <Groups
              className="groups notifs"
              isSearchIcon={false}
              handleOpen={() => {}}
              handleGroups={(arg) => updateState({ filter: arg })}
              activeGroups={[filter]}
              groups={groups}
              counters={countersForGroups}
              countersForNotifications={counters}
            />
          }
        />
        <div className="ntf-title-wrap">
          <button disabled={isReading} className="mark-as-read bright--hover" onClick={readNotifitations}>
            {isReading ? (
              <>
                <FaSpinner className="spinner" />
                <h6>Wait...</h6>
              </>
            ) : (
              <>
                <BsCheck2All size={23} />
                <h6>Mark as read</h6>
              </>
            )}
          </button>
        </div>
        <div className="notifications-area-wrap tiny-sb">
          {itemsContent}
          {(isLoadable || isFetchingNotifications) && (
            <div className={styles.eventsLoader} ref={setObserverRef}>
              <MightyLoaderMini />
            </div>
          )}
        </div>
      </div>
    );
  }

  return ReactDom.createPortal(
    <div className={clsx(styles.container, `notifications-container ${isOpen && 'open'}`)} ref={domNode} onWheel={(e) => e.stopPropagation()}>
      <div className="ntf-title-wrap">
        <h6>Notifications</h6>
        <button disabled={isReading} className="mark-as-read bright--hover" onClick={readNotifitations}>
          {isReading ? (
            <>
              <FaSpinner className="spinner" />
              <h6>Wait...</h6>
            </>
          ) : (
            <>
              <BsCheck2All size={25} />
              <h6>Mark as read</h6>
            </>
          )}
        </button>
      </div>
      <div className="ntf-filters-wrap">
        <button className={`filter-btn ${filter.includes('all') && 'active'}`} onClick={() => updateState({ filter: 'all' })}>
          <h6>
            all <span className="nots-counter">{totalCounter}</span>
          </h6>
        </button>
        {Object.keys(counters).map((key) => (
          <button
            className={`filter-btn ${filter.includes(key) && 'active'} bright--hover`}
            onClick={() => updateState({ filter: key })}
            key={'filter-btn-' + key}
          >
            <h6>
              {key} <span className="nots-counter">{counters[key]}</span>
            </h6>
          </button>
        ))}
      </div>

      <div className="notifications-area-wrap tiny-sb">
        {itemsContent}
        {(isLoadable || isFetchingNotifications) && (
          <div className={styles.eventsLoader} ref={setObserverRef}>
            <MightyLoaderMini />
          </div>
        )}
      </div>

      <div className="view-all-wrap bright--hover" role={'button'} onClick={() => updateState({ isViewAll: !isViewAll })}>
        View All
        <BsChevronDown className={`rotatable ${isViewAll && 'active'}`} />
      </div>
    </div>,
    document.getElementById('portal')
  );
};

export default Notifications;
