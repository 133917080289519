import styled from 'styled-components';

export const Container = styled.div`
  height: fit-content;

  display: flex;
  flex-direction: column;
  position: relative;

  opacity: 0;
  scale: 0.98;
  transition: 0.3s;

  &.isVisible {
    opacity: 1;
    scale: 1;
  }

  @media screen and (max-width: 992px) {
    width: 100%;
  }

  & .lesson-button {
    margin-top: 32px;
  }
  & h3 {
    font-weight: 500;
    font-size: 2.8rem;
    line-height: 1;
    margin-bottom: 2.2rem;

    @media screen and (max-width: 992px) {
      font-size: 18px;
      line-height: 20.7px;
    }
  }
  & .page-num {
    position: absolute;
    top: 23px;
    right: 18px;

    opacity: 0.5;

    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  & img {
    border-radius: 26px;
  }
`;
