import { Table } from '../Table';
import { TypePicker } from '../TypePicker';
import { AnalyticsSummary } from '../AnalyticsSummary';
import * as S from './styles';

export const Analytics: React.FC = () => {
  return (
    <S.Container $flexDirection="column" $gap="10px">
      <AnalyticsSummary />
      <TypePicker />
      <Table />
    </S.Container>
  );
};
