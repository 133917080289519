import { CgClose } from 'react-icons/cg';
import BasicButton from 'shared/ui/basic-button/button';

import styles from './styles.module.css';

export default function RemoveConfirm({ name, accept, close, isLoading }) {
  const theme = localStorage.getItem('theme');

  return (
    <div className={`glass-div basic ${theme} friend-finder_conf --remove-confirm`}>
      <p id="header">
        Remove <span>{name}</span> from friends?
      </p>
      <p id="subtext">This action is irrevocable</p>
      <div className="btns">
        <BasicButton loading={isLoading} isDisabled={isLoading} label="Yes, I'm sure" className={styles.button} onClick={accept} />
        <BasicButton isDisabled={isLoading} label="Cancel" className={styles.button} onClick={close} />
      </div>
      <CgClose strokeWidth={0.1} className={styles.close} role={'button'} onClick={close} />
    </div>
  );
}
