import { FC } from 'react';

import * as Elements from './styles';

export type TButtonv2Props = {
  onClick: () => void;
  children: React.ReactNode;
};

export const Buttonv2: FC<TButtonv2Props> = ({ onClick, children }) => {
  return <Elements.SButton onClick={onClick}>{children}</Elements.SButton>;
};
