import * as S from './styles';
import { useSpaceAnalyticsContext } from '../../context';

export const Table: React.FC = () => {
  const { displayCourses } = useSpaceAnalyticsContext();

  return (
    <S.Table>
      <thead>
        <S.Tr>
          <S.Th>Course name</S.Th>
          <S.Th>Duration</S.Th>
          <S.Th>Started</S.Th>
          <S.Th>Finished</S.Th>
          <S.Th>Completion %</S.Th>
        </S.Tr>
      </thead>
      <tbody>
        {displayCourses.map((course, index) => (
          <S.Tr key={'course' + index}>
            <S.Td>{course.name}</S.Td>
            <S.Td>{course.estimatedTime}</S.Td>
            <S.Td>{course.started}</S.Td>
            <S.Td>{course.finished}</S.Td>
            <S.Td>{`${course.completion}%`}</S.Td>
          </S.Tr>
        ))}
      </tbody>
    </S.Table>
  );
};
