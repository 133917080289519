import * as S from './styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import Quest from './quest';
import { useGetQuestsBySpaceIdQuery } from 'store';
import './slick-styles.scss';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import { useParams } from 'react-router-dom';
import { BasicButtonV1, Modal } from 'shared/ui';
import { AdminModalButtons, AdminModalContainer, AdminModalTitle } from '../SpaceEditingModal/styles';
import modalStyles from '../SpaceEditingModal/modal.module.scss';
import { coursesConstructorAPI } from 'store';
import { toast } from 'react-toastify';
import { THEMES } from 'app/constants';
import QuestDndGrid from './dnd/questDndGrid';
import Answer from 'components/library/messages/Answer';
import UniversalSlider from 'components/library/universal-slider';
import { QuestSliderLoader } from '../SpaceShimmerLoader/spaceShimmerLoader';

type Props = {
  isOwner?: boolean;
  spaceId: string;
  spaceImage?: string;
  onAddQuest: () => void;
};

const SpaceQuests = ({ spaceId, isOwner, onAddQuest, spaceImage }: Props) => {
  const { data, isLoading, refetch } = useGetQuestsBySpaceIdQuery(spaceId, { skip: !spaceId, refetchOnMountOrArgChange: true });
  const [deleteCourse, { isLoading: isDeleting }] = coursesConstructorAPI.useDeleteCourseMutation();
  const { isMobile } = useDisplay();
  const containerRef = useRef(null);
  const [questForDeletion, setQuestForDeletion] = useState<string | null>(null);
  const { spaceName } = useParams();
  const theme = useGetCurrentTheme();

  const handleDelete = () => {
    if (questForDeletion) {
      deleteCourse(questForDeletion)
        .unwrap()
        .then(() => {
          setQuestForDeletion(null);
          refetch();
        })
        .catch(() => toast.error(<Answer label="Couldn't remove course" type="incorrect" />));
    }
  };

  useEffect(() => {
    if (!isLoading && !!data) {
      refetch();
    }
  }, [spaceName]);

  const memoedQuests = useMemo(
    () =>
      data?.quests
        ? [...data?.quests]
            .sort((a, b) => (a.indexNumber || 1) - (b.indexNumber || 1))
            .map((el) => (
              <Quest
                spaceImage={spaceImage}
                key={el._id}
                isOwner={isOwner}
                course={el}
                isFinished={data?.completed && !!data.completed.find((c) => c === el._id)}
                onDelete={setQuestForDeletion}
                isInSlider
              />
            ))
        : [],
    [JSON.stringify(data?.quests), isOwner, JSON.stringify(data?.completed)]
  );

  if (isLoading || !data?.quests || !spaceId) {
    return <QuestSliderLoader />;
  }

  if (!isLoading && !data?.quests) {
    return null;
  }

  if (!data.quests.length) {
    return null;
  }

  return (
    <>
      <S.Col>
        <S.Title $theme={theme}>Quests</S.Title>
        <S.Container ref={containerRef}>
          <>
            {isOwner && !isMobile ? (
              <>
                <QuestDndGrid
                  spaceId={spaceId}
                  completedQuests={data?.completed}
                  onDelete={setQuestForDeletion}
                  spaceImage={spaceImage || ''}
                  quests={data.quests}
                  FirstElem={
                    <div>
                      <S.Questbox $theme={theme} $darker={true} onClick={onAddQuest}>
                        <S.AddQuestImage $theme={theme} />
                        Add quest
                      </S.Questbox>
                    </div>
                  }
                />
              </>
            ) : isMobile ? (
              <S.ScrollList>{memoedQuests}</S.ScrollList>
            ) : (
              <UniversalSlider
                listOverflow="visible"
                gap={16}
                slides={memoedQuests}
                arrows={isMobile ? 'none' : 'buttonsSides'}
                slidesToScroll={isMobile ? 1 : 9}
                slidesToShow={isMobile ? 3 : 9}
                shadowsWidth={70}
                variableWitdh
                slideWidth={100}
                arrowsOffset={70}
                spaced
                removeSpacing={isMobile ? undefined : 'removeX'}
                containerDivProps={{ style: { marginBottom: 30 } }}
              />
            )}
          </>
        </S.Container>
      </S.Col>
      <Modal
        open={!!questForDeletion}
        closeButtonLocation="outside"
        className={`${modalStyles.adminDeleteModal} ${modalStyles[theme]}`}
        onClose={() => setQuestForDeletion(null)}
      >
        <AdminModalContainer>
          <AdminModalTitle $theme={theme}>Are you sure you want to delete quest?</AdminModalTitle>
          <AdminModalButtons>
            {isDeleting ? (
              <MightyLoaderMini />
            ) : (
              <>
                <BasicButtonV1
                  onClick={() => setQuestForDeletion(null)}
                  style={{ background: theme === THEMES.LIGHT ? '#1111111A' : '#FFFFFF1A' }}
                  variant="solid"
                  color="transparent"
                  size="sm"
                >
                  Cancel
                </BasicButtonV1>
                <BasicButtonV1 variant="solid" size="sm" onClick={handleDelete}>
                  Confirm
                </BasicButtonV1>
              </>
            )}
          </AdminModalButtons>
        </AdminModalContainer>
      </Modal>
    </>
  );
};

export default SpaceQuests;
