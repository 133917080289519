import { THEMES } from 'app/constants';
import * as S from './styles';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

type Props = {
  register?: any;
  disabled?: boolean;
  error?: boolean;
  errorText?: string;
  labelToShow?: string;
  label?: string;
  placeholder?: string;
  noPencil?: boolean;
  // theme?: THEMES;
};

const SpaceFormInput = ({ register, disabled, error, errorText, label, labelToShow, placeholder }: Props) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <S.Col $error={error}>
      {!!labelToShow && <S.Label $theme={theme}>{labelToShow}</S.Label>}
      {!!register ? (
        <S.Input $theme={theme || THEMES.DARK} $error={error} {...register(label, { disabled })} placeholder={placeholder} />
      ) : (
        <S.Input $theme={theme || THEMES.DARK} $error={error} placeholder={placeholder} />
      )}
      <S.Error $error={!!errorText}>{errorText}</S.Error>
    </S.Col>
  );
};

export const SpaceFormArea = ({ register, disabled, error, errorText, label, labelToShow, placeholder, noPencil }: Props) => {
  const theme = useSelector(getCurrentTheme);
  return (
    <S.Col $error={error}>
      {!!labelToShow && <S.Label $theme={theme}>{labelToShow}</S.Label>}
      {!!register ? (
        <S.TextArea $theme={theme || THEMES.DARK} $noPencil={noPencil} $error={error} {...register(label, { disabled })} placeholder={placeholder} />
      ) : (
        <S.TextArea $theme={theme || THEMES.DARK} $noPencil={noPencil} $error={error} placeholder={placeholder} />
      )}
      <S.Error $error={!!errorText}>{errorText}</S.Error>
    </S.Col>
  );
};

export default SpaceFormInput;
