export const iframeDocLayout = (html: string, css: string, js: string) => {
  const escapedJs = js.replace(/<\/script/g, '<\\/script').replace(/<!--/g, '<\\!--');
  return `
        <html class="tiny-sb__dark" lang="eng">
           <head>
              <link rel="preconnect" href="https://fonts.googleapis.com">
              <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
              <link href="https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap" rel="stylesheet">
           </head>
           <body>${html}</body>
           <style>
                * {
                   font-family: 'Ubuntu', sans-serif;
                }
                
                  *::-webkit-scrollbar {
            width: 8px;
          }
        
          *::-webkit-scrollbar-track {
            background-color: transparent;
            border-radius: 100px;
          }
        
          *::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.15);
            border: 1px solid #fff;
            border-radius: 100px;
          }
        
          *::-webkit-scrollbar-corner {
            background-color: transparent;
          }
        
          .tiny-sb {
            scrollbar-width: thin;
            scrollbar-color: #AFB4B8 rgba(218, 222, 225, 0.15);
          }
        
          .tiny-sb__dark {
            scrollbar-color: #1C1D26 rgba(12, 12, 12, 0.15);
          }
        
          .tiny-sb::-webkit-scrollbar {
            width: 2px;
            height: 2px;
          }
        
          .tiny-sb::-webkit-scrollbar-track {
            background-color: rgba(218, 222, 225, 0.15);
            width: 2px;
            border-radius: 100px;
          }
        
          .tiny-sb__dark::-webkit-scrollbar-track {
            background-color: rgba(0, 0, 0, 0.2);
          }
        
          .tiny-sb::-webkit-scrollbar-thumb {
            background-color: #AFB4B8;
            border: none;
            border-radius: 100px;
          }
        
          .tiny-sb__dark::-webkit-scrollbar-thumb {
            background-color: #1C1D26;
          }
        
          .tiny-sb::-webkit-scrollbar-corner {
            background-color: transparent;
          }
                
                p, span {
                   font-size: 14px;
                }
                            
                h1 {
                   font-size: 26px ;
                }
                            
                h2 {
                  font-size: 24px;
                }
                            
                h3 {
                   font-size: 22px;
                }
                            
                h4 {
                   font-size: 20px;
                }
                            
                h5 {
                   font-size: 18px;
                }
                            
                h6 {
                   font-size: 16px;
                }
                ${css}
           </style>
           <script>
                
             console.log = function() {
                 window.parent.postMessage({log: Array.from(arguments)}, "*");
             };
             
             try {
                 ${escapedJs}
             } catch (e) {
                 window.parent.postMessage({error: {message: e.message}}, "*");
             }
           </script>
        </html>
        `;
};
