import { InlineAnswersEditor } from '../../core';
import { IInlineAnswerExtension } from '../../core/types';

export class InlineAnswersAnswerBadgeExtension implements IInlineAnswerExtension<{}> {
  private _editor!: InlineAnswersEditor;

  attach = (editor: InlineAnswersEditor): void => {
    this._editor = editor;
  };

  detach = (): void => {
    throw new Error('Method not implemented.');
  };

  public insertAnswer = (answer: string) => {
    const answerBadge = document.createElement('span');
    answerBadge.setAttribute('contenteditable', 'false');
    answerBadge.setAttribute('data-mighty-answer', answer);
    answerBadge.className = 'inline-answers-v2__answer-badge';
    answerBadge.textContent = answer;

    this._editor.insertNode(answerBadge);
  };

  updateProps(props: {}): void {}
}
