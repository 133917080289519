import { FC, useMemo } from 'react';
import clsx from 'clsx';
import { BsChevronLeft } from 'react-icons/bs';

import { useDisplay, useGetCurrentTheme } from 'hooks';

import { data } from './data';
import { TTermsPolicy } from '../../types';
import styles from './styles.module.scss';

interface IParagraph {
  header?: string;
  text: { type: string; content: string[]; mark?: string }[];
}

export interface IContent {
  title?: string;
  paragraph?: IParagraph[];
}

interface IProps {
  page?: TTermsPolicy;
  onClose?: () => void;
}

export const Content: FC<IProps> = ({ page, onClose }) => {
  const theme = useGetCurrentTheme();
  const { isMobile } = useDisplay();
  const typeData = useMemo(() => data[page as keyof typeof data] || data.terms, [page]);

  if (!page) return <></>;

  return (
    <div className={clsx(styles.container, !isMobile && 'glass-div basic', theme)}>
      {isMobile && <BsChevronLeft strokeWidth={1} onClick={onClose} />}
      <h3 className={styles.title}>{typeData.title}</h3>
      <div className={clsx(styles.content, 'tiny-sb')}>
        <p>{typeData.intro}</p>
        <br />
        {typeData.content.map((content, index) =>
          page === 'terms' ? (
            <TermsContent content={content} key={'TermsContent' + index} />
          ) : page === 'policy' ? (
            <PolicyContent content={content} key={'PolicyContent' + index} />
          ) : null
        )}
      </div>
    </div>
  );
};

const TermsContent = ({ content }: { content: IContent }) => {
  return (
    <div className={styles.terms}>
      {content.title && (
        <h4 className={styles.contentTitle} style={{ textAlign: 'center' }}>
          {content.title}
        </h4>
      )}
      {content.paragraph && content.paragraph.map((paragraph, index) => <ContentItem paragraph={paragraph} key={'paragraph' + index} />)}
    </div>
  );
};

const PolicyContent = ({ content }: { content: IContent }) => {
  return (
    <div className={styles.policy}>
      <h4 className={styles.contentTitle}>{content.title}</h4>
      {content.paragraph && content.paragraph.map((paragraph, index) => <ContentItem paragraph={paragraph} key={'paragraph' + index} />)}
    </div>
  );
};

const ContentItem = ({ paragraph }: { paragraph: IParagraph }) => {
  if (!paragraph) return null;
  return (
    <>
      {paragraph.header && (
        <h5 className={styles.contentHeader}>
          <strong>{paragraph.header}</strong>
        </h5>
      )}
      {paragraph.text.map((text, index) =>
        text.type === 'text' ? (
          <p className={styles.paragraph} key={'text' + index}>
            {text.content.map((item, index) => (
              <p className={styles.text} key={'text' + index}>
                {item}
              </p>
            ))}
          </p>
        ) : text.type === 'list' ? (
          <List key={'text' + index} text={text} />
        ) : null
      )}
    </>
  );
};

const List = ({ text }: { text: { type: string; content: string[]; mark?: string } }) => {
  return text.mark === 'dot' ? (
    <ul>
      {text.content.map((item, index) => (
        <li key={'list' + index}>{item}</li>
      ))}
    </ul>
  ) : text.mark === 'count' ? (
    <ol>
      {text.content.map((item, index) => (
        <li key={'list' + index}>{item}</li>
      ))}
    </ol>
  ) : text.mark === 'chevron' ? (
    <ul className={styles.chevron}>
      {text.content.map((item, index) => (
        <li key={'list' + index}>{item}</li>
      ))}
    </ul>
  ) : (
    <ul className={styles.blank}>
      {text.content.map((item, index) => (
        <li key={'list' + index}>{item}</li>
      ))}
    </ul>
  );
};
