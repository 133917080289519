import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      margin-bottom: 25.5px;
    `
  ),
  Header = styled.h4(
    () => css`
      color: var(--font-color-strong);
      font-size: 16px;
      line-height: 110%;
      font-weight: 400;
      letter-spacing: -0.16px;
      margin: 0;
      margin-bottom: 12px;
    `
  );
