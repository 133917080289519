import { MouseEvent } from 'react';
import styles from '../styles.module.css';
import clsx from 'clsx';
import { BsCheck } from 'react-icons/bs';
import { LazyImage } from '../../lazyImage';

interface IProps {
  onClick: (value: string) => void;
  option: { value: string; label: string; icon?: any };
  selectedValue: boolean;
  detailed?: boolean;
}
export const DropDownItem = ({ onClick, option: { value, label, icon = '' }, selectedValue, detailed }: IProps) => {
  const onClickHandler = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    onClick(value);
  };

  const Icon = typeof icon === 'string' ? () => <LazyImage src={icon} alt={'dd-icon'} className={styles.givenIcon} /> : icon;

  return (
    <div className={`${styles.item} dropdown-item`} onClick={onClickHandler}>
      {icon && <Icon className={styles.givenIcon} />}
      <span className={clsx(styles.span, { [styles.activeOne]: selectedValue })}>
        {label}
        {detailed && <span>{value}</span>}
      </span>
      {selectedValue && <BsCheck className={styles.check} />}
    </div>
  );
};
