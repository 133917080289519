import { useState } from 'react';

import { ReactComponent as Checked } from 'assets/icons/check-fill-green-small.svg';
import { ReactComponent as Copy } from 'assets/icons/copy-2.svg';
import { ReactComponent as ArrowRight } from 'assets/icons/arrow-right.svg';
import { IoCheckmarkOutline } from 'react-icons/io5';

import { SCodeField, SCodeFieldButton, SActivatedCodeItem, SCodeHeader } from './style';

interface IProps {
  description: string;
  code: string;
  isActivated: boolean;
  url: string;
  name: string;
  image: string;
}

export const ActivatedCodesItem = ({ description, code, isActivated, url, image, name }: IProps) => {
  const [isCopied, setIsCopied] = useState(false);
  const handleCopyCode = () => {
    navigator.clipboard
      .writeText(code)
      .then(() => {
        setIsCopied(true);
      })
      .catch(() => setIsCopied(false));
  };

  return (
    <SActivatedCodeItem>
      <SCodeHeader>
        <div className={'activateCode-image'}>
          <img src={image} alt={name} />
          {isActivated && <Checked />}
        </div>
        <div className={'column'}>
          <h5>{description}</h5>
          <p className={'activate-grey-color'}>{description}</p>
        </div>
      </SCodeHeader>

      <div className={'activate-field'}>
        <SCodeField>
          <p>{code}</p>
        </SCodeField>
        <SCodeFieldButton className={isCopied ? 'active' : ''} onClick={handleCopyCode}>
          {isCopied ? <IoCheckmarkOutline /> : <Copy />}
        </SCodeFieldButton>
      </div>
      <p className={'activate-grey-color'}>Activate on</p>
      <div className={'activate-field'}>
        <SCodeField>
          <p>{url}</p>
        </SCodeField>
        <SCodeFieldButton as={'a'} href={url} target={'_blank'}>
          <ArrowRight />
        </SCodeFieldButton>
      </div>
    </SActivatedCodeItem>
  );
};
