import clsx from 'clsx';
import { useRef, useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';

import styles from './styles.module.scss';
import CarouselItem from './item/carousel-item';

interface IProps {
  insertedItems?: React.ReactNode[];
  items: React.ReactNode[];
  isDisabled?: boolean;
  className?: string;
  itemClassName?: string;
  buttonClassName?: string;
  width?: string;
  height?: string;
  translate?: number;
  forcedNumberOfSlides?: number;
}

export default function LittleItemsCarousel({
  insertedItems = [],
  items: itemsToShow = [],
  isDisabled = false,
  className = '',
  itemClassName = '',
  buttonClassName = '',
  width = '',
  height = '',
  translate = 100,
  forcedNumberOfSlides,
}: IProps) {
  const [slide, setSlide] = useState(0);
  const [touchPosition, setTouchPosition] = useState<null | number>(null);
  const $parentRef = useRef<HTMLDivElement>(null);
  const $childrenRef = useRef<HTMLDivElement>(null);
  const items = [...insertedItems, ...itemsToShow];

  function handleSlideChange(direction: number) {
    // Calculate width of parent container
    const parentWidth = $parentRef?.current?.getBoundingClientRect()?.width ?? 0;

    // Calculate width of a single child item
    const singleItemWidth = $childrenRef.current ? ($childrenRef.current.firstChild as HTMLElement)?.offsetWidth ?? 0 : 0;

    // Calculate the number of visible items in one container's width
    const numVisibleItems = Math.floor(parentWidth / singleItemWidth);

    // Calculate the total number of slides
    const numSlides = forcedNumberOfSlides ?? Math.ceil(items.length / numVisibleItems);

    let newSlide = slide + direction;

    if (newSlide < 0) {
      newSlide = numSlides - 1; // Move to the last slide
    } else if (newSlide >= numSlides) {
      newSlide = 0; // Move to the first slide
    }

    setSlide(newSlide);
  }

  // This function is called when a touch event starts (i.e., when the user starts touching the screen)
  function handleTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    // Get the x-coordinate of the touch point relative to the left edge of the browser viewport
    const touchDown = e.touches[0].clientX ?? 0;

    // Store the x-coordinate in the state variable touchPosition
    setTouchPosition(touchDown);
  }

  // This function is called when a touch move event occurs (i.e., when the user moves their finger across the screen)
  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    // If the touch start position is not recorded, exit the function.
    // This might happen if the touchMove event was triggered without a preceding touchStart event
    if (touchPosition === null) {
      return;
    }

    // Get the current x-coordinate of the touch point
    const currentPosition = e.touches[0].clientX ?? 0;

    // Calculate the direction of the movement by subtracting the start position from the current position
    const direction = touchPosition - currentPosition;

    // If the movement is more than 10px to the right, change the slide to the next one
    if (direction > 10) {
      handleSlideChange(1);
    }

    // If the movement is more than 10px to the left, change the slide to the previous one
    if (direction < -10) {
      handleSlideChange(-1);
    }

    // After handling the movement, reset the touch start position
    // This is so that the next touch move event will be relative to its own touch start event
    setTouchPosition(null);
  }

  if (items.length < 4) {
    return (
      <div
        className={clsx(styles.wrapper, {
          [className]: !!className,
        })}
      >
        <div className={styles.slider}>
          <div className={styles.slidesList}>
            {items.map((item: any, index: number) => (
              <CarouselItem item={item} key={'caro-item-' + index} />
            ))}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={clsx(styles.wrapper, {
        [className]: !!className,
        [styles.disabled]: isDisabled,
      })}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      style={{ width, height }}
    >
      <button className={clsx({ [buttonClassName]: !!buttonClassName }, styles.arrow, styles.left)} onClick={() => handleSlideChange(-1)}>
        <BsChevronLeft strokeWidth={1.5} />
      </button>
      <div className={styles.slider} ref={$parentRef}>
        <div className={styles.slidesList} style={{ transform: `translateX(-${slide * translate}%)` }} ref={$childrenRef}>
          {items.map((item: any, index: number) => (
            <CarouselItem item={item} key={'caro-item-' + index} className={itemClassName} />
          ))}
        </div>
      </div>
      <button className={clsx({ [buttonClassName]: !!buttonClassName }, styles.arrow, styles.right)} onClick={() => handleSlideChange(1)}>
        <BsChevronRight strokeWidth={1.5} />
      </button>
    </div>
  );
}
