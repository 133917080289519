import { useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { setIsMainMenu, useGetMySpacesQuery } from 'store';
import { useDisplay, useGetCurrentTheme, useGetUserData } from 'hooks';
import { useDispatch } from 'react-redux';
import { useGetSpaces } from './hooks';
import { EMenuScreens, ROUTES, USER_ROLES } from 'app/constants';
import { ESpaceRoles } from 'store/spaces/types';

import { ReactComponent as PlanetIcon } from './assets/planet-icon.svg';
import { ReactComponent as SpaceIcon } from 'assets/icons/planet.svg';

import { Logo } from 'shared/utils';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import { SlowSlideX } from 'shared/ui/motion';
import { SpaceShimmerLoader, SpaceSliderLoader } from './ui/SpaceShimmerLoader/spaceShimmerLoader';
import SpacesSlider from './ui/Sliders';
import PagLoader from './pagLoader';
import SpacesList from './list';
import QuestsFeed from './ui/SpaceQuest/feed/quest-feed';

import * as S from './spaces.styles';

const SpacesPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useGetCurrentTheme();
  const { isMobile } = useDisplay();
  const { spaces = [], isFetching, isLoading, initiate, isFinished } = useGetSpaces(true, 9);
  const { data: mySpacesResponse = [], isLoading: mySpacesLoading } = useGetMySpacesQuery();
  const { _id, roles } = useGetUserData();
  const isPageLoader = spaces.length > 0 && !isLoading && !isFinished;

  const isAdmin = useMemo(() => (roles ? roles.includes(USER_ROLES.ADMIN) : false), [roles]);

  const mySpaces = useMemo(() => {
    if (!_id) return [];
    return mySpacesResponse.filter((el) =>
      el.users.some((u) => {
        if (u.userId._id !== _id) {
          return false;
        }
        return u.roles.some((role) => [ESpaceRoles.OWNER, ESpaceRoles.ADMIN].includes(role));
      })
    );
  }, [mySpacesResponse, _id]);

  const handleButtonClick = useCallback(() => {
    if (!isLoading) {
      if (mySpaces.length > 0) {
        if (isMobile) {
          navigate('/?screen=Spaces');
          return;
        }
        dispatch(setIsMainMenu({ isMainMenu: true, screen: EMenuScreens.SPACES }));
      } else {
        if (!roles || !roles.includes(USER_ROLES.ADMIN)) {
          const SPACE_FILL_FORM_LINK = 'https://forms.gle/RwrPzvMH21anR4VR6';
          window.open(SPACE_FILL_FORM_LINK, '_blank');
          return;
        }
        navigate(ROUTES.SPACE_CREATION);
      }
    }
  }, [isLoading, mySpaces, isMobile, dispatch, navigate, roles]);

  return (
    <S.Page>
      {isMobile && <S.PageBg $theme={theme} />}
      <S.Section $noMargin={true}>
        <SlowSlideX>
          <S.TitleBox>
            <S.TitleLeft>
              <S.Title $theme={theme}>
                Welcome{' '}
                <S.TitleBottom $theme={theme}>
                  {' '}
                  to <Logo inline width={isMobile ? 25 : 35} height={'auto'} /> Mighty Spaces
                </S.TitleBottom>
              </S.Title>

              <S.TitleDesc $theme={theme}>Unlock Knowledge, Gain Rewards, Connect and Create!</S.TitleDesc>
            </S.TitleLeft>
            <S.CreateButton $theme={theme} onClick={handleButtonClick}>
              {isLoading ? (
                <MightyLoaderMini style={{ width: 25, height: 25 }} />
              ) : mySpaces.length <= 0 ? (
                <>
                  <PlanetIcon /> Create Space
                </>
              ) : (
                <>
                  <SpaceIcon /> My spaces
                </>
              )}
            </S.CreateButton>
          </S.TitleBox>
        </SlowSlideX>
        {isLoading || !spaces.length ? <SpaceSliderLoader /> : <SpacesSlider spaces={spaces.length > 10 ? spaces.slice(0, 10) : spaces} />}
        <QuestsFeed />
      </S.Section>
      {isLoading ? <SpaceShimmerLoader /> : <SpacesList title="My Spaces" spacesList={mySpaces} isLoading={mySpacesLoading} moreCols />}
      {isLoading ? <SpaceShimmerLoader /> : <SpacesList title="Following" spacesList={mySpacesResponse} isLoading={mySpacesLoading} moreCols />}
      <SpacesList title="Spaces" spacesList={spaces?.filter((el) => el.isVerified)} isLoading={isLoading} hideUntilLoaded />
      {isAdmin && <UnverifiedSpaces verifiedLoaded={isFinished} />}
      {isPageLoader && <PagLoader isFinished={isFinished} initiate={initiate} isFetching={isFetching} />}
    </S.Page>
  );
};

const UnverifiedSpaces = ({ verifiedLoaded }: { verifiedLoaded: boolean }) => {
  const { spaces, isLoading, isFetching, isFinished, initiate } = useGetSpaces(false, 9);

  if (!verifiedLoaded) {
    return null;
  }

  return (
    <>
      <SpacesList title="Inactive" spacesList={spaces} isLoading={isLoading} hideUntilLoaded />
      {!isFinished && <PagLoader isFetching={isFetching} initiate={initiate} isFinished={isFinished} />}
    </>
  );
};

export default SpacesPage;
