import { IDatabaseItem } from 'interface';

export enum EActivateItemType {
  ENERGY = 'energy',
  EXPRECEIVED = 'expReceived',
  ENERGYRECOVERY = 'energyRecovery',
  NOEFFECT = 'noEffect',
}

export type TActivateItemType = `${EActivateItemType}`;

export interface IActivateItemProps {
  item: IDatabaseItem;
  handleClose: () => void;
  type: EActivateItemType;
}
