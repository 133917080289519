import { FC, HTMLAttributes } from 'react';
import clsx from 'clsx';

import styles from './styles.module.css';

type TEmptyPlaceholder = HTMLAttributes<HTMLDivElement> & {
  children: React.ReactNode;
};

const EmptyPlaceholder: FC<TEmptyPlaceholder> = ({ children, className, ...props }) => {
  return (
    <div className={clsx(styles.root, className)} {...props}>
      {children}
    </div>
  );
};

export default EmptyPlaceholder;
