import { useSearchParams } from 'react-router-dom';
import { TTermsPolicy } from './types';

const useTerms = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get('page') as TTermsPolicy;

  const handleChoose = (page: TTermsPolicy) => {
    if (!page) {
      searchParams.delete('page');
      setSearchParams(searchParams);
    } else {
      setSearchParams({ page });
    }
  };

  const handleClose = () => {
    handleChoose(null);
  };

  return { page, handleChoose, handleClose };
};
export default useTerms;
