import { useSpaceAnalyticsContext } from '../../context';
import * as S from './styles';

export const TypePicker: React.FC = () => {
  const { type, setType } = useSpaceAnalyticsContext();

  const handleClick = (type?: 'quest' | 'course') => () => setType(type);

  return (
    <S.Container $gap={'10px'}>
      <S.Button className={!type ? 'active' : ''} onClick={handleClick()}>
        All
      </S.Button>
      <S.Button className={type === 'course' ? 'active' : ''} onClick={handleClick('course')}>
        Courses
      </S.Button>
      <S.Button className={type === 'quest' ? 'active' : ''} onClick={handleClick('quest')}>
        Quests
      </S.Button>
    </S.Container>
  );
};
