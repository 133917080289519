import styles from './style.module.scss';
import { motion } from 'framer-motion';
import glow from './assets/glow.svg';
import { expToken } from 'utils/items';

export const RewardBannerInfo = () => {
  const variants = {
    open: { y: 0, opacity: 1, height: 'auto' },
    closed: { y: -400, opacity: 0, height: 0 },
  };

  return (
    <motion.div initial="open" animate={'open'} variants={variants} transition={{ duration: 0.5 }}>
      <div className={styles.banner}>
        <img src={glow} alt="glow" className={styles.glow} />
        <img src={expToken.image} alt="token" className={styles.chestImage} />
        <h1>Unlock Your Reward</h1>
        <p>Register now to claim 100 EXP bonus.</p>
      </div>
    </motion.div>
  );
};
