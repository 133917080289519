import { FC } from 'react';
import CourseConstructorContainer from '../Container';
import WidgetsList from '../WidgetsList';
import { useSlideContext } from '../SlideContext';
import { IRewardMinimal, ISlideWidget } from 'store';
import { IPathProp } from 'pages/coursesConstructor/parts/editLesson/types';
import { IDatabaseItemQuantified } from 'interface';
import { ISlideFull } from 'interface/courses';

type TInfoCourseConstructorContainer = {
  allRewards: IDatabaseItemQuantified[];
  onSlideDelete: () => void;
  onChange: (newSlide: ISlideFull) => void;
  path: IPathProp;
  isQuest?: boolean;
};

const InfoCourseConstructorContainer: FC<TInfoCourseConstructorContainer> = ({ onSlideDelete, onChange, allRewards, path, isQuest }) => {
  const { slide } = useSlideContext();

  const handleTitleChange = (value: string) => {
    onChange({ ...slide, name: value });
  };

  const handleWidgetsChange = (widgets: ISlideWidget[]) => {
    onChange({ ...slide, content: widgets });
  };

  const handleRewardsChange = (newRewards: IRewardMinimal[]) => {
    onChange({ ...slide, rewards: newRewards });
  };

  return (
    <CourseConstructorContainer
      // Fields
      title={slide.name}
      slideId={slide._id}
      allRewards={allRewards}
      rewards={slide.rewards}
      path={path}
      // inUpdate={isInUpdate}
      // Handlers
      onTitleChange={handleTitleChange}
      onSlideDelete={onSlideDelete}
      onRewardsChange={handleRewardsChange}
      isQuest={isQuest}
    >
      <WidgetsList
        // Fields
        items={slide.content}
        //Handlers
        onChange={handleWidgetsChange}
      />
    </CourseConstructorContainer>
  );
};

export default InfoCourseConstructorContainer;
