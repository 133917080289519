import styled, { css } from 'styled-components';

export const Container = styled.div(
    () => css`
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      line-height: 100%;
      letter-spacing: 0.5px;
      padding: 8px 0;
      white-space: pre;
    `
  ),
  Header = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: var(--grade-dark-color-mythical);
  `,
  Span = styled.span`
    font-size: 14px;
    text-decoration-line: underline;
    opacity: 0.7;
    cursor: pointer;
    &:hover {
      opacity: 1;
    }
  `;
