import { Flex } from 'shared/ui';
import * as S from '../../SpacePageTop/styles';
import { AnalyticsIcon, PencilIcon } from 'assets/icons/icons';

interface IScreensMenu {
  textColor: string;
  onSpaceEditorOpen: () => void;
  onAnalyticsOpen: () => void;
}

const ScreensMenu = ({ textColor, onSpaceEditorOpen, onAnalyticsOpen }: IScreensMenu) => {
  return (
    <Flex $justifyContent={'flex-end'} $gap={'10px'}>
      <>
        <MenuButton textColor={textColor} label={'Edit Space'} icon={<PencilIcon />} onClick={onSpaceEditorOpen} />
        <MenuButton textColor={textColor} label={'Analytics'} icon={<AnalyticsIcon />} onClick={onAnalyticsOpen} />
      </>
    </Flex>
  );
};

const MenuButton = ({ label, icon, onClick, textColor }: { label: string; icon: React.ReactNode; onClick: () => void; textColor: string }) => {
  return (
    <S.BottomEditBox>
      <S.AdminButton $color={textColor} onClick={onClick}>
        {icon} {label}
      </S.AdminButton>
    </S.BottomEditBox>
  );
};

export default ScreensMenu;
