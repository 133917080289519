import { useEffect, useState } from 'react';
import * as Styled from './styles';
import InfoPageSwitch from 'components/pages/study/courses/material-pages/lower-menu/InfoPageSwitch/switch';
import { INamedDatabaseEntity } from 'interface/courses';
import { BsChevronLeft } from 'react-icons/bs';

interface Props {
  items: INamedDatabaseEntity[];
}

export default function Paginator({ items }: Props) {
  const [active, setActive] = useState(-1);
  const [last, setLast] = useState(-1);
  const itemsNames = items.map((slide) => slide.name);

  useEffect(() => {
    const item = document.getElementById(items[active]?._id);
    if (item && active >= 0) {
      item?.scrollIntoView({
        behavior: 'smooth',
      });
      setLast(active);
      setActive(-1);
    }
  }, [active, items]);

  return (
    <Styled.Container>
      <Styled.Chevron className="chevron">
        <BsChevronLeft />
        Slides
      </Styled.Chevron>
      <Styled.Wrapper className="wrapper">
        <InfoPageSwitch className="switch" items={itemsNames} active={last} setActive={setActive} />
      </Styled.Wrapper>
    </Styled.Container>
  );
}
