import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useRef } from 'react';

import * as Styled from './styles';
import { Tooltip } from 'shared/ui';
import { Toggle } from 'shared/ui/toggle';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  header: string;
  description: string;
  className?: string;
}

export const TooltippedToggle = forwardRef<HTMLInputElement, IProps>(({ className = '', header, description, ...rest }, ref) => {
  const triggerRef = useRef(null);
  return (
    <Styled.Container className={className} ref={triggerRef}>
      <Tooltip triggerRef={triggerRef}>
        <p>
          <strong>{header}</strong>
        </p>
        <p>{description}</p>
      </Tooltip>
      <Toggle {...rest} ref={ref} />
    </Styled.Container>
  );
});
