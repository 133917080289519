import * as S from './styles';
import * as Loaders from './loaders.styles';
import { ISpace, ISpaceUser } from 'store';
import { THEMES } from 'app/constants';
import { shortenLink } from '../utils';
import { ReactComponent as PencilIcon } from '../../assets/pencil.svg';
import { SpaceFollowButton } from '../../SpaceCreation/components/followButton';

type ContentProps = {
  space: ISpace | null;
  color?: string;
  theme: THEMES;
  textColor: string;
  isBright?: boolean;
  viewportWidth: number;
  isAbleToEdit: boolean;
  followers?: { items: ISpaceUser[] };
  coursesLength?: number;
  isMobile: boolean;
  setModalVisible: (arg: boolean) => void;
};

const SpaceTopContentMobile = ({
  space,
  textColor,
  theme,
  color,
  isBright,
  viewportWidth,
  isAbleToEdit,
  followers,
  isMobile,
  setModalVisible,
  coursesLength,
}: ContentProps) => {
  return (
    <S.SpaceInfoTopMobile>
      <S.Col style={{ alignItems: 'flex-start' }}>
        <S.ImageBox color={color !== 'rgba(255,255,255,1)' ? color : ''}>
          {!!space ? <S.Image $dropShadow={isBright && theme !== THEMES.DARK} src={space.imageThumb} /> : <Loaders.ImageLoader />}
        </S.ImageBox>
        <S.Name $color={textColor} $isVerified={space?.isVerified}>
          {!!space ? (
            <>
              <span>{space.name}</span>{' '}
              <S.Link rel={'noreferrer'} href={space.link} target={'_blank'}>
                {shortenLink(space.link)}
              </S.Link>
            </>
          ) : (
            'Loading...'
          )}
        </S.Name>
      </S.Col>
      <S.Col>
        <S.InfoContainer>
          <S.InfoBox>
            {!!space ? (
              <>
                <S.InfoNum $color={textColor}>{followers ? followers.items.length : '...'}</S.InfoNum>
                <S.InfoTopic $color={textColor}>Students</S.InfoTopic>
              </>
            ) : (
              <>
                <Loaders.Num />
                <Loaders.Sub />
              </>
            )}
          </S.InfoBox>
          {viewportWidth > 400 && (
            <S.InfoBox>
              {!!space ? (
                <>
                  <S.InfoNum $color={textColor}>{coursesLength}</S.InfoNum>
                  <S.InfoTopic $color={textColor}>Courses</S.InfoTopic>
                </>
              ) : (
                <>
                  <Loaders.Num />
                  <Loaders.Sub />
                </>
              )}
            </S.InfoBox>
          )}
        </S.InfoContainer>
        <S.SpaceEditBox animate={{ y: 0, opacity: 1 }} initial={{ y: 20, opacity: 0 }} exit={{ y: 20, opacity: -1 }}>
          {isAbleToEdit && (
            <S.EditButton $color={textColor} onClick={() => !!space && setModalVisible(true)}>
              <PencilIcon /> Edit
            </S.EditButton>
          )}
          {!isAbleToEdit && !!space && <SpaceFollowButton large={!isMobile} spaceId={space._id} />}
          <S.OpenLink $color={textColor} onClick={() => window.open(space?.link || '')} />
        </S.SpaceEditBox>
      </S.Col>
    </S.SpaceInfoTopMobile>
  );
};

export default SpaceTopContentMobile;
