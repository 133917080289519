import { getCurrentTheme } from 'store';
import { useSelector } from 'react-redux';
import { EMenuScreens } from 'app/constants';

import { ReactComponent as Profile } from './assets/Profile.svg';
import { ReactComponent as Inventory } from 'assets/icons/wallet.svg';
import { ReactComponent as Close } from './assets/Close.svg';
import { ReactComponent as Settings } from './assets/Settings.svg';
import { ReactComponent as Design } from 'assets/icons/sunCircle.svg';
import { ReactComponent as Space } from './assets/spaces-icon.svg';

import { TooltipSmall } from 'shared/ui/tooltip';

import './styles/menu.scss';
import { useEffect, useRef } from 'react';

const buttons = {
  Inventory: { name: 'Inventory', logo: <Inventory /> },
  Profile: { name: 'Profile', logo: <Profile /> },
  Settings: { name: 'Settings', logo: <Settings /> },
  Design: { name: 'Design', logo: <Design /> },
  Spaces: { name: 'Spaces', logo: <Space /> },
};

const MenuNav = ({
  active = EMenuScreens.INVENTORY,
  onActiveMenu,
  setShowMenu,
  showCloseButton = true,
  showTooltip = true,
  variant = 'default',
  className = '',
}) => {
  const theme = useSelector(getCurrentTheme);

  const { logo, name } = buttons[active] ?? buttons[EMenuScreens.INVENTORY];

  const containerRef = useRef(null);

  useEffect(() => {
    // this is fixing weird blur for .container elements
    if (containerRef.current) {
      setTimeout(() => {
        requestAnimationFrame(() => {
          containerRef.current.style.opacity = '0.99';
          requestAnimationFrame(() => {
            containerRef.current.style.opacity = '1';
          });
        });
      }, 400);
    }
  }, []);

  return (
    <div className={`mobile-padding menu-nav-wrap ${variant} ${className} ${theme}`}>
      <div className="menu-nav-left">
        <div className={`menu-nav-first ${theme} container`}>
          {logo}
          <div className="menu-nav-text" key={name}>
            {name}
          </div>
        </div>

        {Object.values(buttons)
          .filter((i) => i.name !== active)
          .map((i) => (
            <TooltipSmall key={i.name} text={i.name} direction={'top'} delay={'100'} isShow={showTooltip}>
              <div ref={containerRef} className={`menu-nav-circle container ${theme}`} key={i.name} onClick={() => onActiveMenu({ screen: i.name })}>
                {i.logo}
              </div>
            </TooltipSmall>
          ))}
      </div>
      {showCloseButton && (
        <div className="menu-nav-right">
          <div className={`menu-nav-circle container ${theme}`} style={{ marginRight: '0px' }} onClick={() => setShowMenu(false)}>
            <Close stroke="var(--font-color)" />
          </div>
        </div>
      )}
    </div>
  );
};

export default MenuNav;
