import { useTranslation } from 'react-i18next';
import styles from './styles.module.css';
import { SetNewPasswordForm } from './setNewPasswordForm/setNewPasswordForm';
import { AnimateWrapper } from '../../ui';
import { ISetNewPasswordForm } from 'interface';
import { Modal } from 'shared/ui';

interface IProps {
  open: boolean;
  error?: string;
  isLoading: boolean;
  onSubmitHandler: (data: ISetNewPasswordForm) => void;
  onCloseModalHandler: () => void;
}

export const SetNewPasswordModal = ({ open, isLoading, error, onSubmitHandler, onCloseModalHandler }: IProps) => {
  const { t } = useTranslation('auth');
  return (
    <Modal className={styles.modal} open={open} onClose={onCloseModalHandler}>
      <AnimateWrapper>
        <div className={styles.container}>
          <div className={styles.title}>{t('resetPassword.setNewPassword.title')}</div>
          <div className={styles.subTitle}>{t('resetPassword.setNewPassword.subTitle')}</div>
          <SetNewPasswordForm error={error} onSubmitHandler={onSubmitHandler} isLoading={isLoading} />
        </div>
      </AnimateWrapper>
    </Modal>
  );
};
