import { useGetCurrentTheme } from 'hooks';
import * as S from '../styles';

import { ReactComponent as XIcon } from '../../assets/x.svg';
import { ReactComponent as TelegramIcon } from '../../assets/telegram.svg';
import { ReactComponent as FacebookIcon } from '../../assets/facebook.svg';
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg';
// import { ReactComponent as DiscordIcon } from '../../assets/discord.svg';
import { ReactComponent as ShareIcon } from 'assets/icons/share-icon-classic.svg';

import { CSSProperties, useState } from 'react';
import { FacebookSubMenu, InstagramSubMenu, TelegramSubMenu, XSubMenu } from './subMenus/menus';
import { useReferralsShareText } from '../utils';
import { useLocation } from 'react-router-dom';

type Props = {
  title?: string;
  titleStyles?: CSSProperties;
  refLink: string;
  tgRefLink: string;
};

const MobileSocialsBlock = ({ title, titleStyles, refLink, tgRefLink }: Props) => {
  const theme = useGetCurrentTheme();
  const { state } = useLocation();
  const [currentSubMenu, setCurrentSubMenu] = useState<'facebook' | 'instagram' | 'telegram' | 'x' | 'discord' | null>(
    state?.isTelegramPreopen ? 'telegram' : null
  );

  const handleMenuOpen = (arg: 'facebook' | 'instagram' | 'telegram' | 'x' | 'discord') => () => setCurrentSubMenu(arg);
  const handleClose = () => setCurrentSubMenu(null);

  const shareText = useReferralsShareText();

  const shareData = {
    title: 'Mighty Study',
    url: refLink,
    text: shareText,
  };

  const isWebShareAPISupported = !!navigator?.canShare;

  const handleShareAPI = () => {
    if (isWebShareAPISupported) {
      navigator.share(shareData).catch(console.error);
    }
  };

  return (
    <>
      <S.SocialSub $theme={theme} style={titleStyles || {}}>
        {title || 'Share:'}
      </S.SocialSub>
      <S.Socials $theme={theme}>
        {/* <S.SocialButton onClick={handleMenuOpen('discord')}>
          <DiscordIcon />
        </S.SocialButton> */}
        <S.SocialButton onClick={handleMenuOpen('x')}>
          <XIcon />
        </S.SocialButton>
        <S.SocialButton onClick={handleMenuOpen('telegram')}>
          <TelegramIcon />
        </S.SocialButton>
        <S.SocialButton onClick={handleMenuOpen('facebook')}>
          <FacebookIcon />
        </S.SocialButton>
        <S.SocialButton onClick={handleMenuOpen('instagram')}>
          <InstagramIcon />
        </S.SocialButton>
        {isWebShareAPISupported && (
          <S.SocialButton onClick={handleShareAPI}>
            <ShareIcon />
          </S.SocialButton>
        )}
      </S.Socials>
      {/* <DiscordSubMenu refLink={refLink} shareText={shareText} isOpen={currentSubMenu === 'discord'} onClose={handleClose} /> */}
      <XSubMenu refLink={refLink} shareText={shareText} isOpen={currentSubMenu === 'x'} onClose={handleClose} />
      <TelegramSubMenu refLink={tgRefLink} shareText={shareText} isOpen={currentSubMenu === 'telegram'} onClose={handleClose} />
      <FacebookSubMenu refLink={refLink} shareText={shareText} isOpen={currentSubMenu === 'facebook'} onClose={handleClose} />
      <InstagramSubMenu refLink={refLink} shareText={shareText} isOpen={currentSubMenu === 'instagram'} onClose={handleClose} />
    </>
  );
};

export default MobileSocialsBlock;
