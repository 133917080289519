import { ChangeEvent, FC, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import * as yup from 'yup';

import { ReactComponent as DotsVertical } from 'assets/icons/dots-vertical.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';

import DropdownMenu from 'components/library/dropdownMenu';
import { BasicButtonV1 } from 'shared/ui';
import InfoBadge from 'components/library/infoBadge';
import BasicButton from 'shared/ui/basic-button/button';
import { useSlideContext } from 'components/library/courseConstructor/components/SlideContext';

import { TTestSlideProps } from '../../types';
import { EInfoBadgeTypes } from 'components/library/infoBadge/types';
import { updateSlideField_SlideType } from 'store/coursesConstructor/utils';

import styles from './styles.module.css';
import { Textarea } from 'components/pages/study/courses/material-pages/tests/TypeAnswer/styles';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

const NEW_ANSWER_IDX = -1;

const Typeanswer: FC<TTestSlideProps> = ({ answers, rightAnswers, onChange }) => {
  const { slide } = useSlideContext();
  const $localAnswerTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const [answerInEditIdx, setAnswerInEditIdx] = useState<number | null>(null);
  const [localAnswer, setLocalAnswer] = useState('');
  const [localAnswerError, setLocalAnswerError] = useState('');
  const answerValidationSchema = yup.object().shape({ answer: yup.string().required().max(100) });
  const theme = useSelector(getCurrentTheme);

  const handleStartAnswerEdit = (answerIdx = NEW_ANSWER_IDX) => {
    if (answerIdx !== NEW_ANSWER_IDX) {
      setLocalAnswer(rightAnswers[answerIdx].text);
    }
    setAnswerInEditIdx(answerIdx);
  };

  const handleStopAnswerEdit = () => {
    setAnswerInEditIdx(null);
  };

  const handleChange = async (evt: ChangeEvent<HTMLTextAreaElement>) => {
    const value = evt.target.value;

    try {
      await answerValidationSchema.validate({ answer: value });
      setLocalAnswerError('');
    } catch (e: any) {
      setLocalAnswerError(e.errors[0]);
    } finally {
      setLocalAnswer(value);
    }
  };

  const handleSubmit = () => {
    if (localAnswerError || answerInEditIdx === null) {
      return;
    }

    let newRightAnswers = [...rightAnswers];

    if (answerInEditIdx === NEW_ANSWER_IDX) {
      newRightAnswers = [...newRightAnswers, { text: localAnswer }];
    } else {
      newRightAnswers[answerInEditIdx] = { text: localAnswer };
    }
    // TODO - rightAnswers нужен при создании курса, надо найти способ это указать в интерфейсе
    onChange(
      //@ts-ignore
      updateSlideField_SlideType(slide, {
        answers: newRightAnswers,
        //@ts-ignore
        rightAnswers: newRightAnswers,
      })
    );
  };

  const handleAnswerDelete = (answerIdx: number) => () => {
    const newAnswers = rightAnswers.filter((_, idx) => idx !== answerIdx);

    onChange(
      //@ts-ignore
      updateSlideField_SlideType(slide, {
        answers: newAnswers,
        //@ts-ignore
        rightAnswers: newAnswers,
      })
    );
  };

  useEffect(() => {
    handleStopAnswerEdit();
  }, [rightAnswers]);

  useEffect(() => {
    if (answerInEditIdx === null) {
      return;
    }

    $localAnswerTextAreaRef.current?.focus();
  }, [answerInEditIdx]);

  const editAnswerTextarea = useMemo(
    () => (
      <div>
        <Textarea
          className={theme}
          value={localAnswer}
          placeholder="Type your answer here..."
          onChange={handleChange}
          rows={localAnswer?.split('\n').length}
          ref={$localAnswerTextAreaRef}
        />
        <div className={styles.controls}>
          <BasicButton label="Cancel" onClick={handleStopAnswerEdit} size="sm" variant="outline" />
          <BasicButton label="Save" onClick={handleSubmit} size="sm" variant="cool" />
        </div>
      </div>
    ),
    [handleChange]
  );

  return (
    <div>
      <div className={styles.title}>answer</div>
      <div className={styles.answers}>
        {rightAnswers.map((answer: any, idx: number) =>
          idx === answerInEditIdx ? (
            <Fragment key={answer.text + idx}>{editAnswerTextarea}</Fragment>
          ) : (
            <div className={`${styles.answer}`} key={answer.text + idx}>
              <div className={`${styles.answerContent} ${styles[theme]}`} onClick={() => handleStartAnswerEdit(idx)}>
                {answer.text}
              </div>
              <DropdownMenu
                toggle={<DotsVertical />}
                toggleClassName={styles.answerSettingsButton}
                items={[
                  { title: 'Delete', onClick: handleAnswerDelete(idx) },
                  { title: 'Edit', onClick: () => handleStartAnswerEdit(idx) },
                ]}
              />
            </div>
          )
        )}
        {answerInEditIdx === NEW_ANSWER_IDX && editAnswerTextarea}
      </div>
      {localAnswerError && <InfoBadge type={EInfoBadgeTypes.WARNING}>{localAnswerError}</InfoBadge>}
      {answerInEditIdx === null && (
        <BasicButtonV1 size="sm" variant="rounded" fullWidth={false} onClick={() => handleStartAnswerEdit()}>
          <PlusIcon />
          &nbsp;Add one more possible answer
        </BasicButtonV1>
      )}
    </div>
  );
};

export default Typeanswer;
