import styled, { css } from 'styled-components';
import hexToRgba from 'hex-to-rgba';
import { THEMES } from 'app/constants';

export const WarningRoot = styled.div<{ $withImage: boolean; $theme: THEMES }>(
    ({ color, $withImage: withImage, $theme }) => css`
      width: 100%;
      background: linear-gradient(77.35deg, rgba(255, 225, 82, 0.2) 0.89%, rgba(255, 212, 53, 0.2) 52.67%, rgba(255, 211, 65, 0.2) 128.34%);
      border-radius: 10px;
      border: 1px solid ${color};
      background: ${hexToRgba(color ? color : '#fff', 0.1)};
      padding: 16px;
      align-items: center;
      ${$theme === THEMES.LIGHT && 'box-shadow: 0px 2px 7px #11111133;'}
      ${withImage &&
      css`
        padding-left: 174px;
        min-height: 150px;
        max-height: 150px;
        /* overflow: hidden; */
        align-items: flex-start;
      `}
      position: relative;
      display: flex;
      transition: background, border ease-in-out 0.15s;

      p {
        color: var(--font-color-strong);
        line-height: 1.35;
      }
    `
  ),
  IconContainer = styled.div(
    (props) => css`
      width: 38px;
      height: 38px;
      min-width: 38px;
      margin-right: 12px;
      cursor: pointer;
      svg {
        width: 100%;
        height: auto;
        color: ${props.color === 'default' || props.color === '#FDFDFD' ? 'var(--font-color-strong);' : props.color};
      }
    `
  ),
  Image = styled.div(
    ({ theme }) => css`
      width: 148px;
      height: 148px;
      border-radius: 9px;
      position: absolute;
      left: 0;
      top: 0;
      cursor: pointer;

      .warning-image-close {
        background: ${hexToRgba(theme.theme.background, 0.8)};
        position: absolute;
        right: 5px;
        top: 5px;

        &:hover {
          background: ${hexToRgba(theme.theme.background, 1)};
        }
      }
    `
  ),
  Content = styled.div<{ $withImage: boolean }>(
    ({ $withImage: withImage }) => css`
      width: 100%;
      display: flex;
      height: inherit;
      max-height: inherit;
      ${withImage && 'margin-top: -16px;'}
      ${withImage && 'padding: 16px 0;;'}
      overflow: hidden;
    `
  );
