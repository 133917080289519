import { ROUTES } from 'app/constants';

import { AiOutlineDown } from 'react-icons/ai';

import { ReactComponent as Learn } from 'assets/icons/sidebar-icons/learn.svg';
import { ReactComponent as Play } from 'assets/icons/sidebar-icons/play.svg';
import { ReactComponent as Shop } from 'assets/icons/sidebar-icons/shop.svg';
import { ReactComponent as Govern } from 'assets/icons/sidebar-icons/govern.svg';
import { ReactComponent as Settings } from 'components/library/navbar/assets/pictures/settings.svg';
import { ProfileIcon } from 'assets/icons/icons';

export default function useSidebarData() {
  const sidebarData = [
    {
      title: 'Study',
      path: ROUTES.MAIN,
      altPath: ROUTES.MAIN,
      publicPath: ROUTES.MAIN,
      translationKey: 'study',
      icon: <Learn />,
      iconClosed: <AiOutlineDown size={11} />,
    },
    {
      title: 'Profile',
      path: ROUTES.PROFILE,
      altPath: ROUTES.PROFILE,
      publicPath: ROUTES.AUTH,
      translationKey: 'profile',
      icon: <ProfileIcon />,
      iconClosed: <AiOutlineDown size={11} />,
    },
    {
      title: 'Play',
      path: ROUTES.PLAY,
      altPath: ROUTES.PLAY,
      publicPath: ROUTES.PLAY,
      translationKey: 'play',
      icon: <Play />,
      iconClosed: <AiOutlineDown size={11} />,
    },
    {
      title: 'Shop',
      path: ROUTES.SHOP,
      altPath: ROUTES.SHOP,
      publicPath: ROUTES.SHOP,
      translationKey: 'shop',
      icon: <Shop />,
      iconClosed: <AiOutlineDown size={11} />,
    },
    {
      title: 'Spaces',
      path: ROUTES.SPACES,
      altPath: ROUTES.SPACES,
      publicPath: ROUTES.SPACES,
      translationKey: 'spaces',
      icon: <Govern />,
      iconClosed: <AiOutlineDown size={11} />,
    },
    {
      title: 'Settings',
      path: ROUTES.SETTINGS,
      altPath: ROUTES.SETTINGS,
      publicPath: ROUTES.AUTH,
      translationKey: 'settings',
      icon: <Settings />,
      iconClosed: <Settings />,
    },
    // {
    //   title: 'Docs',
    //   path: ROUTES.INFO,
    //   altPath: ROUTES.INFO,
    //   publicPath: ROUTES.INFO,
    //   translationKey: 'docs',
    //   icon: <IoIosPaper />,
    //   iconClosed: <IoIosPaper />,
    // },
  ];

  return sidebarData;
}
