import { useSelector } from 'react-redux';
import { getCurrentLanguage, setShopItem } from 'store';

import { LazyImage } from 'shared/ui';
import { useMemo } from 'react';
import { ESearchGroupKey } from '../types';
import { Logo } from 'shared/utils';
import { ROUTES } from 'app/constants';
import { STATIC_HOST_PATH } from 'app/constants/path';
import { useNavigate } from 'react-router-dom';
import { dispatch, useGetIsAuth } from 'hooks';
import AvatarPic from 'components/library/utils/AvatarPic/avatar';

import styles from './styles.module.scss';

export default function SearchGroupItem({ groupName, item }) {
  const lang = useSelector(getCurrentLanguage);
  const navigate = useNavigate();
  const isAuth = useGetIsAuth();

  const { image, link, name } = useMemo(() => {
    let image = '',
      name = '',
      link = '';
    switch (groupName) {
      case ESearchGroupKey.docs:
        name = item?.name?.find((i) => i.language === lang)?.title ?? '';
        image = <Logo />;
        link = ROUTES.INFO;
        break;
      case ESearchGroupKey.users:
        name = item?.nickname ?? '';
        image = <AvatarPic className={styles.avatar} nickname={name} avatar={item?.avatar} customization={item?.customization} smallGlow />;
        link = `user/${item._id}`;
        break;
      case ESearchGroupKey.courses:
        name = item?.name ?? '';
        image = `${STATIC_HOST_PATH}${item?.imageLogo}`;
        link = item.currentProgressPath ? `${ROUTES.COURSES}/${item.currentProgressPath}` : `${ROUTES.COURSES}/${item._id}`;
        break;
      case ESearchGroupKey.items:
        name = item?.item?.name ?? '';
        image = item?.item?.imageThumb ?? '';
        link = isAuth ? `shop?itemId=${item?.item?.id}` : ROUTES.AUTH;
        break;
      case ESearchGroupKey.spaces:
        name = item?.name ?? '';
        image = item?.imageThumb ?? '';
        link = item.name;
        break;

      default:
        break;
    }
    return {
      image,
      name,
      link,
    };
  }, [groupName, item, lang]);

  const handleNavToItem = () => {
    switch (groupName) {
      case ESearchGroupKey.items:
        isAuth ? dispatch(setShopItem({ itemId: item.id })) : navigate(link);
        break;

      default:
        navigate(link);

        break;
    }
  };

  return (
    <div className="search-group-item item-container" role={'button'} onClick={handleNavToItem}>
      <div className="item-img">{typeof image === 'string' ? <LazyImage src={image} alt="language" /> : image}</div>
      <p>{name}</p>
    </div>
  );
}
