import { FC, useState } from 'react';
import { HiArrowRight } from 'react-icons/hi';
import { BsChevronLeft } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useDailyRewardsContext } from '../../context';

import { CurrentRewards, NextRewards, Streak } from '../ui';
import BasicButton from 'shared/ui/basic-button/button';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import { MobileTopNav } from 'shared/utils/mobile';
import { DailyRewardsPanel } from '../DailyRewardsPanel/DailyRewardsPanel';
import { getUserData } from 'store';
import { FeatureCard } from 'shared/ui';

// import { ReactComponent as Options } from 'assets/icons/options2.svg';
import exp from 'assets/images/exp-token.png';

import * as Styled from './styled';
import styles from './styles.module.scss';

interface IProps {
  className?: string;
}

export const DailyRewardsCard: FC<IProps> = ({ className = '' }) => {
  const [showModal, setShowModal] = useState(false);
  const { expTokenBalance } = useSelector(getUserData);
  const { isRewardable, userDailis, currentDailyRewards: rewards } = useDailyRewardsContext();

  return (
    <>
      <FeatureCard headSlot={<Streak showGreat={false} isSmall />} headPadding={'unset'} padding={'17px 19px 20px 30px'} className={className}>
        <Styled.Container>
          <CurrentRewards handleProcessStep={() => {}} rewards={rewards} userDailis={userDailis} isRewardable={isRewardable} onlyToday />
          <Styled.Wrap $isRewardable={isRewardable}>
            <Styled.Title>{isRewardable ? 'Collect your rewards every day' : 'Reward collected!'}</Styled.Title>
            {isRewardable ? (
              <BasicButton
                isLabelFirst
                label={'Collect'}
                variant={'cool'}
                icon={<HiArrowRight />}
                className={'button'}
                onClick={() => setShowModal(true)}
              />
            ) : (
              <NextRewards
                rewards={rewards}
                userDailis={userDailis}
                isRewardable={isRewardable}
                max={3}
                fontSize={'14px'}
                labelMargin={'8px'}
                variant={'panel'}
                isRealNext
              />
            )}
          </Styled.Wrap>
        </Styled.Container>
      </FeatureCard>
      <CenteredModal show={showModal} isClosing={false} portal={'root'} backgroundColor={'#0A0E23'} direction={'x'}>
        <MobileTopNav
          title={
            <div className={styles.expTitle}>
              <img src={exp} alt={'exp'} />
              <span>{expTokenBalance}</span>
            </div>
          }
          background={false}
          leftIcon={<BsChevronLeft strokeWidth={1} color={'#fff'} />}
          handleClickLeftIcon={() => setShowModal(false)}
          // rightIcon={<Options color={'#fff'} />}
          className={styles.topNav}
        />
        <Styled.Background />
        <Styled.ModalContent>
          <DailyRewardsPanel onClose={() => setShowModal(false)} />
        </Styled.ModalContent>
      </CenteredModal>
    </>
  );
};
