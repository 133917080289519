import { useReducer } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { SettingsCard, SettingsCardWrapper } from 'shared/ui';
import { dispatch, useGetAuthMethodsData } from 'hooks';
import { getCurrentTheme, setCurrentTheme, getUserData } from 'store';
import { NETWORKS, THEMES } from 'app/constants';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Copy } from '../assets/Copy.svg';
import { ReactComponent as Change } from '../assets/Change.svg';
// import { ReactComponent as Language } from '../assets/language.svg';
import { ReactComponent as Light } from '../assets/theme.svg';
import { ReactComponent as Angle } from 'assets/icons/angle.svg';
import { ReactComponent as Inbox } from 'assets/icons/inbox.svg';
import { ReactComponent as Dark } from 'assets/icons/toggler-icons/dark.svg';

import { ReactComponent as Mail } from '../assets/letter-icon.svg';
import { ReactComponent as Theme } from '../assets/theme-icon.svg';
import { ReactComponent as CodesIcon } from '../assets/codes-icon.svg';
// import { ReactComponent as LinkIcon } from '../assets/link-icon.svg';

import AvatarPic from 'components/library/utils/AvatarPic/avatar';
import { TooltipSmall } from 'shared/ui/tooltip';
import NicknameChanger from 'shared/utils/nickname-changer/NicknameChanger';
import BlockModal from 'components/library/utils/BlockModal';
import ThemeToggler from 'components/library/sidebar/ThemeToggler';
import Nickname from 'shared/ui/nickname/Nickname';
import { ActivatedCodes } from '../../../../features/activateCodes';
import { UserLinks } from 'features/userLinks';
import { MightyVersion } from 'components/shared/versionMighty';

import './styles/screens.css';
import './styles/settings.css';
import styles from './styles.module.css';

// const langOptions = Object.entries(LANGUAGES).map(([key, lang]) => ({ label: lang?.nativeName, value: key }));

type Screens = 'email' | 'password' | 'main';
interface IProps {
  variant: 'default' | 'mobile';
}
interface IState {
  isNickChanging: boolean;
  // isLanguages: boolean;
  isEmailShown: boolean;
  currentNetwork: NETWORKS;
  mobileScreen: Screens;
}

const Settings = ({ variant = 'default' }: IProps) => {
  const { customization, avatar, nickname } = useSelector(getUserData);
  const { getEmail: getIEmail } = useGetAuthMethodsData();
  const email = getIEmail() ?? '';
  const theme = useSelector(getCurrentTheme);
  // const currentLanguage = useSelector(getCurrentLanguage);
  const [state, updateState] = useReducer((prev: IState, next: Partial<IState>) => ({ ...prev, ...next }), {
    isNickChanging: false,
    // isLanguages: false,
    currentNetwork: NETWORKS.GOERLI,
    mobileScreen: 'main',
    isEmailShown: false,
  });
  const { isNickChanging, mobileScreen, isEmailShown } = state;
  const { t } = useTranslation();
  const style = { color: 'var(--font-color-strong)' };
  const mr5 = { marginRight: '5px' };

  // const defaultLang = LANGUAGES[currentLanguage].nativeName;

  // const networkOptions = [
  //   { label: 'mainnet', value: NETWORKS.MAINNET },
  //   { label: 'goerli', value: NETWORKS.GOERLI },
  // ];

  // function onLangChangeHandler(val: string) {
  //   const lang = LANGS[val.toUpperCase() as keyof typeof LANGS];
  //   if (currentLanguage !== lang && lang) {
  //     handleMobileScreens('main')();
  //     dispatch(setCurrentLanguage(lang));
  //   }
  // }
  // function onNetChangeHandler(val: string) {
  //   updateState({ currentNetwork: NETWORKS[val as keyof typeof NETWORKS] });
  // }
  // function handleAddressCopy() {
  //   navigator.clipboard.writeText(address);
  // }
  function handleEmailCopy() {
    navigator.clipboard.writeText(email);
  }
  function handleNickname() {
    updateState({ isNickChanging: !isNickChanging });
  }
  function toggleTheme() {
    dispatch(setCurrentTheme(theme === THEMES.DARK ? THEMES.LIGHT : THEMES.DARK));
  }

  return (
    <div>
      <div className="profile-main">
        <BlockModal show={isNickChanging} hide={handleNickname}>
          <NicknameChanger open={isNickChanging} nickname={nickname} close={handleNickname} />
        </BlockModal>
        {variant === 'default' && (
          <>
            <div className="profile-row">
              <AvatarPic className={clsx(styles.avatar, 'menu-avatar')} avatar={avatar} nickname={nickname} customization={customization} />
              <div className="profile-col">
                <div className="row-down">
                  <Nickname nickname={nickname} customization={customization} />
                  <TooltipSmall direction="bottom" text={'Edit'}>
                    <Change className="sm-icon" role="button" onClick={handleNickname} />
                  </TooltipSmall>
                </div>
                {/* <div className="row-center">
                  <div className="profile-follow">{getShortStr(?.address, { fStart: 5, lStart: -5 })}</div>
                  <TooltipSmall direction="bottom" text={'Copy'}>
                    <Copy className="sm-icon" role="button" onClick={handleAddressCopy} />
                  </TooltipSmall>
                </div> */}
              </div>
            </div>
            <div className="settings-menu-wrap">
              <div className="settings-section">
                {email && (
                  <div className="settings-row" style={style}>
                    <div className="row-center">
                      <Mail style={mr5} />
                      <div>{t('settings.email')}</div>
                      {/* <TooltipSmall direction="bottom" text={'Edit'}>
                      <Change className="sm-icon" />
                    </TooltipSmall> */}
                    </div>
                    <div className="email">{email}</div>
                  </div>
                )}
                {/* <div className="settings-row" style={style}>
                  <div>{t('settings.password')}</div>
                  <div className="row-center">
                    <div>{t('options.notSet')}</div>
                    <TooltipSmall direction="bottom" text={'Edit'}>
                      <Change className="sm-icon" />
                    </TooltipSmall>
                  </div>
                </div> */}
                {/* <div className="settings-row" style={style}>
                  <div className="row-center">
                    <Network style={mr5} />
                    <div>{t('settings.networkKey')}</div>
                  </div>
                  <Select
                    defaultValue={currentNetwork}
                    placeholder={currentNetwork}
                    value={currentNetwork}
                    options={networkOptions}
                    onChange={onNetChangeHandler}
                  />
                </div> */}
                <div className="settings-row" style={style}>
                  <div className="row-center">
                    <Theme style={mr5} />
                    <div>{t('settings.themeKey')}</div>
                  </div>
                  <ThemeToggler />
                </div>
                {/* <div className="settings-row" style={style}>
                  <div className="row-center">
                    <Language style={mr5} />
                    <div>{t('settings.languageKey')}</div>
                  </div>
                  <Select
                    defaultValue={defaultLang}
                    placeholder={defaultLang}
                    value={currentLanguage}
                    options={langOptions}
                    onChange={onLangChangeHandler}
                  />
                </div> */}
              </div>
              {/* <div className="settings-section">
                <PremiumWidget />
              </div> */}
              <div className="settings-section">
                <div className="settings-row" style={style}>
                  <div className="row-center">
                    <CodesIcon style={mr5} />
                    <div>Activated Codes</div>
                  </div>
                  <ActivatedCodes variant={'small'} />
                </div>
              </div>
              <div className="settings-section">
                <UserLinks />
              </div>
            </div>
          </>
        )}
        {variant === 'mobile' && (
          <div className={clsx(styles.menuWrapper, styles[mobileScreen])}>
            {mobileScreen === 'main' && (
              <>
                <SettingsCardWrapper className={styles.cardWrapper}>
                  {isEmailShown ? (
                    <SettingsCard onClick={handleEmailCopy} leftIcon={<Inbox />} rightIcon={<Copy />} title={email} />
                  ) : (
                    <SettingsCard
                      onClick={() => updateState({ isEmailShown: true })}
                      leftIcon={<Inbox />}
                      rightIcon={<Angle className={styles.angle} />}
                      title={'Email'}
                    />
                  )}
                  {/* <SettingsCard
                    leftIcon={<Lock className={styles.lock} />}
                    rightIcon={<Angle className={styles.angle} />}
                    title={'Recovery Password'}
                  /> */}
                  {/* <SettingsCard
                    leftIcon={<Language />}
                    onClick={handleMobileScreens('language')}
                    rightIcon={<Angle className={styles.angle} />}
                    title={'Language'}
                  /> */}
                </SettingsCardWrapper>
                <SettingsCardWrapper onClick={toggleTheme}>
                  <SettingsCard leftIcon={<Theme />} rightIcon={theme === 'dark' ? <Dark /> : <Light />} title={'Theme'} />
                </SettingsCardWrapper>
                <UserLinks />
                <ActivatedCodes />
              </>
            )}
            {/* {mobileScreen === 'language' && (
              <SettingsCardWrapper>
                {langOptions.map((lang) => (
                  <SettingsCard key={lang.label} onClick={() => onLangChangeHandler(lang.value)} title={lang.label} />
                ))}
              </SettingsCardWrapper>
            )} */}
          </div>
        )}
        <MightyVersion />
      </div>
    </div>
  );
};

export default Settings;
