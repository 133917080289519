import styled, { css } from 'styled-components';
import profileImage from 'assets/backgrounds/main--backgrounds/profile/profile.webp';
import profileImageWhite from 'assets/backgrounds/main--backgrounds/profile/white/profile.webp';

import coursesImage from 'assets/backgrounds/main--backgrounds/courses/courses.webp';
import coursesImageWhite from 'assets/backgrounds/main--backgrounds/courses/white/courses.webp';

import spacesImage from 'assets/backgrounds/main--backgrounds/spaces/spaces-bg-dark.webp';
import spacesImageLight from 'assets/backgrounds/main--backgrounds/spaces/spaces-bg-light.webp';
import spacesCreationImage from 'assets/backgrounds/main--backgrounds/spaces/space-creation-bg.webp';

export const Container = styled.div(
  ({ theme }) => css`
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: top center;

    display: flex;
    justify-content: center;

    &.page-background__profile {
      background-image: url(${profileImage});

      &.light {
        background-image: url(${profileImageWhite});
      }
    }

    &.page-background__courses {
      background-image: url(${coursesImage});

      &.light {
        background-image: url(${coursesImageWhite});
      }
      svg {
        transform: translateX(120px);
      }
    }

    &.page-background__spaces {
      background-image: url(${spacesImage});
      background-size: contain;
      background-position: -1px -1px;
      &.light {
        background-image: url(${spacesImageLight});
        background-size: 100% auto;
      }
    }

    &.page-background__space-creation {
      background-image: url(${spacesCreationImage});
    }

    svg {
      color: ${theme.theme.body};
      position: absolute;

      &.page-svg-bg-profile {
      }
    }
    .page-svg-bg-profile {
      transform: scale(1.5);
    }
  `
);
