const MODAL_STYLES = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',

  width: '100%',
  heigth: '100%',

  zIndex: 1000,

  border: 'none',
};

const OVERLAY_STYLES = {
  position: 'absolute',
  top: 0,
  right: 0,
  backgroundColor: 'rgba(28, 29, 38, 0.5)',
  backdropFilter: 'blur(2.5px)',
  zIndex: 1000,

  height: '100%',
  width: '100%',
};

const BlockModal = ({ show, hide, children }) => {
  if (!show) return null;

  return (
    <>
      <div className="block-modal__overlay" style={OVERLAY_STYLES} onClick={hide} />
      <div className="block-modal" style={MODAL_STYLES}>
        {children}
      </div>
    </>
  );
};

export default BlockModal;
