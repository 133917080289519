import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled, { css } from 'styled-components';
import { ReactComponent as DoneSVG } from '../assets/done.svg';
import { BsChevronDown } from 'react-icons/bs';
import { Button } from 'shared/ui';

const INACTIVE_COLOR = {
  [THEMES.DARK]: '#3f4356',
  [THEMES.LIGHT]: 'rgba(17, 17, 17, 0.08)',
};

const ACTIVE_COLOR = '#6487EF';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  mask-mode: alpha;
  gap: 16px;
  animation: appear 1s ease-in-out;

  @keyframes appear {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

export const Chapter = styled.div<{ $theme: THEMES }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  font-size: 18px;
  font-weight: 500;
  width: 100%;
  color: ${({ $theme }) => INACTIVE_COLOR[$theme]};
  transition: 0.3s;
  cursor: pointer;
  &.isOpened {
    color: var(--font-color-strong);
  }
  &.isLessonsOpen {
    margin-bottom: 9px;
  }
`;

export const ChapterBox = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
  &:hover {
    ${Chapter} {
      color: var(--font-color-strong);
    }
  }
`;

export const ChapterNavContainer = styled.span`
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const ChapterNum = styled.span<{ $theme: THEMES }>`
  width: 36px;
  min-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.04);
  color: var(--font-color-strong);
  font-size: 18px;
  font-weight: 700;
`;

export const ChapterName = styled.span`
  width: 100%;
  text-overflow: ellipsis;
  text-transform: uppercase;
  overflow: hidden;
  white-space: nowrap;
  color: var(--font-color-strong);
  transition: 0.3s;
  &:hover {
    opacity: 0.9;
  }
`;

export const LessonsToggle = styled(Button)(
  () => css`
    border-radius: 50%;
    width: 38px;
    height: 38px;
    min-width: 38px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s;
    svg {
      width: 20px;
      height: 20px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.2);
    }
  `
);

export const Chevron = styled(BsChevronDown)`
  color: var(--font-color-strong);
  transition: 0.3s;
  rotate: -90deg;

  &.isReversed {
    rotate: 0deg;
  }
`;

export const Icon = styled.svg<{ $small?: boolean }>`
  width: ${({ $small }) => ($small ? '19px' : '24px')};
  min-width: ${({ $small }) => ($small ? '19px' : '24px')};
  height: ${({ $small }) => ($small ? '19px' : '24px')};
`;

export const ChapterLessons = styled(motion.div)`
  gap: 20px;
  display: grid;
  grid-template-rows: 0fr;
  width: 100%;
  transition: 0.3s;
  border-left: 1px solid #ffffff1a;
  &.isOpen {
    grid-template-rows: 1fr;
  }
`;

export const LessonsNested = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  opacity: 0;
  transition: 0.3s;
  position: relative;
  gap: 4px;
  margin-left: -1px;
  &.isOpen {
    opacity: 1;
  }
`;

export const LessonHead = styled.p`
  display: flex;
  align-items: center;
  gap: 7px;
  font-weight: 500;
  font-size: 16px;
  transition: 0.3s;
  padding-left: 12px;
  width: 100%;
  height: 30px;
  color: var(--font-color-strong);
  position: relative;
  border-radius: 0px 4px 4px 0px;
  background: transparent;
  padding-right: 5px;
  &.isActive {
    color: ${ACTIVE_COLOR};
    &::before {
      left: 0;
      top: 50%;
      position: absolute;
      width: 1px;
      height: 18px;
      display: block;
      transform: translateY(-50%);
      content: '';
      background-color: ${ACTIVE_COLOR};
      transition: 0.3s;
    }
  }
  &:hover {
    opacity: 0.85;
    background: #ffffff0f;
    &::before {
      height: 30px;
    }
  }
`;

export const LessonName = styled.span`
  font-size: inherit;
  color: inherit;
  max-width: 100%;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const LessonBox = styled.div<{ $theme: THEMES }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 100%;
  cursor: pointer;

  &.isActive {
    & ${LessonName} {
      color: ${ACTIVE_COLOR};
    }
    & ${LessonHead} {
      color: ${ACTIVE_COLOR};
    }
  }
  &:hover {
    & ${LessonName} {
      color: var(${ACTIVE_COLOR});
      opacity: 1;
    }
    & ${LessonHead} {
      color: var(${ACTIVE_COLOR});
    }
  }
`;

export const DoneIcon = styled(DoneSVG)`
  width: 18px;
  height: 18px;
  margin-top: -1px;
`;
