import styled, { css } from 'styled-components';

export const Container = styled.div(
  () => css`
    --padding-x: 24px;
    --padding-y: 30px;
    padding: var(--padding-y) var(--padding-x);
    max-height: 471px;
  `
);
