import { FC, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { TCourseConstructorProps } from './types';

import styles from './styles.module.css';

export const CourseConstructor: FC<TCourseConstructorProps> = ({ children, index = 0, slideId }) => {
  const { hash } = useLocation();

  useEffect(() => {
    if (slideId === hash.replace('#', '')) {
      document.getElementById(slideId)?.scrollIntoView();
    }
  }, [hash, slideId]);

  return (
    <div className={styles.root} id={slideId}>
      <h5 className={styles.slideTitle}>Slide {index + 1}</h5>
      {children}
    </div>
  );
};

export default CourseConstructor;
