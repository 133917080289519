import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';
import * as Styled from './styles';
import styles from './styles.module.css';
import { useGetCurrentTheme } from 'hooks';

enum TLabels {
  SAVING = 'Saving...',
  SAVED = 'Saved',
}

type Props = {
  isLoading?: boolean;
};

export default function SlideSaver({ isLoading = true }: Props) {
  const theme = useGetCurrentTheme();

  return (
    <Styled.Container>
      <MightyLoaderMini className={styles.logo} isLoading={isLoading} />
      <Styled.Text $theme={theme}>{isLoading ? TLabels.SAVING : TLabels.SAVED}</Styled.Text>
    </Styled.Container>
  );
}
