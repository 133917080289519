import { AnimatePresence, motion } from 'framer-motion';
import { FC, SVGProps } from 'react';

interface IProps extends SVGProps<SVGSVGElement> {
  isVisible: boolean;
  initial?: boolean;
  size?: number;
  delay?: number;
  onAnimationComplete?: any;
  onAnimationEnd?: any;
  haveAExit?: boolean;
}

export const AnimatedCheckIcon: FC<IProps> = ({
  isVisible,
  initial = true,
  className,
  size = 24,
  delay = 0,
  onAnimationComplete,
  onAnimationEnd,
  haveAExit = false,
  style,
}) => {
  return (
    <AnimatePresence initial={initial} onExitComplete={onAnimationEnd}>
      {isVisible && (
        <svg
          className={className}
          style={{ width: size, height: size, ...style }}
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={3}
          stroke="currentColor"
        >
          <motion.path
            strokeLinecap="round"
            strokeLinejoin="round"
            initial={{ pathLength: 0 }}
            animate={{ pathLength: [0, 0.35, 0.35, 1] }}
            exit={haveAExit ? { pathLength: 0 } : {}}
            transition={{
              type: 'tween',
              duration: 1.1,
              ease: [0.45, 0.05, 0.55, 0.95],
              delay,
              times: [0, 0.2, 0.7, 1],
            }}
            onAnimationComplete={onAnimationComplete}
            d="M4.5 12.75l6 6 9-13.5"
          />
        </svg>
      )}
    </AnimatePresence>
  );
};
