import { motion } from 'framer-motion';
import * as Styled from './styles';
import { FC, PropsWithChildren } from 'react';
import { BsXLg } from 'react-icons/bs';

interface Props extends PropsWithChildren {
  isOpen: boolean;
  handleClose(): void;
  closeLabel?: string;
  header?: string;
  zIndex?: number;
}

const variants = {
  closed: {
    opacity: 0,
    y: '100%',
  },
  open: {
    opacity: 1,
    y: 0,
  },
};

export const MobileModal: FC<Props> = ({ isOpen, handleClose, closeLabel = 'Close', header, children, zIndex = 10 }) => {
  return (
    <Styled.Container as={motion.div} animate={isOpen ? 'open' : 'closed'} variants={variants} initial="closed" exit="closed" $zIndex={zIndex} className='blur-50'>
      {/*  */}
      {header && (
        <Styled.Header className="mobile-padding">
          {header}
          <Styled.Button className="cancel mobile-padding" onClick={handleClose}>
            <BsXLg />
          </Styled.Button>
        </Styled.Header>
      )}
      {children}
    </Styled.Container>
  );
};
