import styled, { css } from 'styled-components';

export const Button = styled.button(
  () => css`
    border: none;
    padding: 15px;
    color: var(--font-color-strong);
    border-radius: 5px;
    opacity: 0.3;

    &.ready {
      opacity: 1;
      scale: 1.1;
    }

    &:hover {
      opacity: 1;
    }

    svg {
      width: 100%;
      height: auto;
    }

    @media screen and (max-width: 766px) {
      bottom: 80px;
    }
  `
);
