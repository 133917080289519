import styled, { css } from 'styled-components';

export const SMeasure = styled.div(
    () => css`
      position: absolute;
      top: -9999px;
      left: -9999px;
      visibility: hidden;
      white-space: pre;
      font-size: 16px;
    `
  ),
  SInput = styled.input(
    () => css`
      width: 5px;
      min-width: 5px;
      background: transparent;
      outline: none;
      border: none;
      font-size: 16px;
    `
  );
