import { PropsWithChildren } from 'react';

import * as Styled from './styles';
import clsx from 'clsx';

interface Props extends PropsWithChildren {
  header?: string;
  className?: string;
  columns?: number;
}

export const Group = ({ children, header, className = '', columns = 1 }: Props) => {
  return (
    <Styled.Container className={clsx({ [className]: !!className })}>
      {header && <Styled.Header>{header}</Styled.Header>}
      <Styled.Items $columns={columns}>{children}</Styled.Items>
    </Styled.Container>
  );
};
