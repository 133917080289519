import { ChangeEvent, forwardRef, HTMLAttributes, useEffect, useRef } from 'react';

import * as Elements from './styles';

type TAutoWidthInputProps = HTMLAttributes<HTMLInputElement> & {
  value: string;
  wrapperTag?: 'span' | 'div';
};

export const AutoWidthInput = forwardRef<HTMLInputElement, TAutoWidthInputProps>(
  ({ value, onChange, className, wrapperTag = 'div', ...others }, ref) => {
    const $measureRef = useRef<HTMLDivElement>(null);
    const $inputRef = useRef<HTMLInputElement>(null);

    const handleInput = (evt: ChangeEvent<HTMLInputElement>) => {
      if (!$measureRef.current) {
        return;
      }
    };

    useEffect(() => {
      const actualRef = (ref as any)?.current ?? $inputRef.current;

      if (!actualRef || !$measureRef.current) {
        return;
      }

      actualRef.style.width = `${$measureRef.current.offsetWidth + 5}px`;
    }, [value]);

    const input = (
      <>
        <Elements.SMeasure ref={$measureRef} className={className}>
          {value}
        </Elements.SMeasure>
        <Elements.SInput ref={ref ?? $inputRef} value={value} onChange={onChange} className={className} onInput={handleInput} {...others} />
      </>
    );

    if (wrapperTag === 'div') {
      return <div>{input}</div>;
    }

    return <span>{input}</span>;
  }
);
