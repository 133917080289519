import { useState } from 'react';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import clsx from 'clsx';
import { GRADES } from 'app/constants';

import { IOnetimerComponent, OnetimeNotificationCommentReward } from '../types';
import { BasicButtonV1 } from 'shared/ui/button';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';

import * as Styled from './styles';
import styles from './styles.module.scss';
import { ONETIMERS_TYPES } from 'interface';
import { useGetItemGetterByKey } from 'hooks';

interface Props extends OnetimeNotificationCommentReward, IOnetimerComponent {}

const CommentRewards = ({ onClose, onSkip, courseName, lessonName, rewards: rews, type }: Props) => {
  const theme = useSelector(getCurrentTheme);
  const getItem = useGetItemGetterByKey();
  const [rewards, setRewards] = useState(rews.map((rew) => ({ ...getItem('id', rew.id), quantity: rew.quantity })));
  const active = rewards.length - 1;

  const handleClose = () => {
    if (rewards.length - 1 > 0) {
      setRewards((prev) => {
        const newRewards = [...prev];
        newRewards.pop();
        return newRewards;
      });
    } else {
      onClose();
    }
  };

  const subject = type === ONETIMERS_TYPES.USERCOMMENTREWARDED ? 'comment' : 'answer';

  return (
    <>
      {rewards.map(({ quantity, name, image }, index) => {
        return active === index ? (
          <CenteredModal key={image} show onClose={onSkip} closeButtonLocation="outside">
            <Styled.Container className={clsx(styles.container, styles[theme])}>
              <Styled.ImageContainer>
                <img src={image} alt={name} />
              </Styled.ImageContainer>
              <Styled.Header>Thank you for your contribution!</Styled.Header>
              <Styled.Title>
                You received{' '}
                <span style={{ color: `var(--grade-${theme}-color-${GRADES.COMMON})` }}>
                  {quantity} {name}
                </span>{' '}
                for your {subject}
              </Styled.Title>
              <Styled.Span>
                {lessonName} | {courseName} course
              </Styled.Span>
              <BasicButtonV1 variant="primary" onClick={handleClose}>
                Continue
              </BasicButtonV1>
            </Styled.Container>
          </CenteredModal>
        ) : null;
      })}
    </>
  );
};

export default CommentRewards;
