import { memo, useState } from 'react';
import TestFrame from '../tests/TestFrame/TestFrame';
import Lesson from './Lesson/Lesson';
import { motion } from 'framer-motion';
import Underlesson from './underlesson';
import CourseNavigator from '../tests/utils/CourseNavigator/CourseNavigator';
import { useGetMappedContent } from './hooks/useGetMappedContent';

const LessonFrame = ({ slide, navigatorProps, hideUnderLesson = false, forceLoading = false, updateRight, right }) => {
  let Content = Lesson;

  const [completedTasksCount, setCompletedTasksCount] = useState(0);
  const [tasksLeft, setTasksLeft] = useState(0);

  const { mappedContent } = useGetMappedContent({ slide, incrementCompletedTasksCount: () => setCompletedTasksCount((s) => s + 1), setTasksLeft });

  if (slide?.typeOfSlide !== 'Info') {
    Content = TestFrame;
  }

  return (
    <motion.div initial={{ x: 300, opacity: 0 }} animate={{ x: 0, opacity: 1 }} transition={{ duration: 0.4 }} className="lesson-content-area">
      <Content slide={{ ...slide, content: mappedContent }} right={right} updateRight={updateRight} />
      {slide?.typeOfSlide === 'Info' && (
        <CourseNavigator
          {...{ ...navigatorProps, isLoading: navigatorProps.isLoading || forceLoading }}
          locked={navigatorProps.locked || tasksLeft > completedTasksCount}
        />
      )}
      {!hideUnderLesson && <Underlesson slide={slide} />}
    </motion.div>
  );
};

export default memo(LessonFrame);
