import { FC } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import styles from './styles.module.scss';

interface IHeaderProps {
  title: string;
  handleClick: () => void;
  marginBottom?: string;
}

export const Header: FC<IHeaderProps> = ({ title, handleClick, marginBottom }) => {
  return (
    <div className={styles.header} style={marginBottom ? { marginBottom } : {}}>
      <BsChevronLeft className={styles.chevron} strokeWidth={'1px'} size={'20px'} onClick={handleClick} />
      <h5>{title}</h5>
    </div>
  );
};
