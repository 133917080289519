import { FC, useMemo } from 'react';
import { TLessonListItemProps } from './types';
import { useDisclosure } from 'hooks';
import { IRewardMinimal } from 'store';
import clsx from 'clsx';

import LessonCard from '../LessonCard';
import { Menu } from '../../../chaptersList/components/ChapterListItem/components';

import * as S from './styles';

const LessonListItem: FC<TLessonListItemProps> = ({
  id,
  name,
  onIsInEditModeChange,
  lessonIdx,
  isInEditMode,
  rewards,
  onChange,
  onDelete,
  onTapDeteleButton,
  onEditSlidesRequest,
  isLoading,
  rewardIsAbsolute,
  onToggleLockLesson,
  dependencies,
}) => {
  const { isOpen, open, close } = useDisclosure(false);

  const rewardsUnduplicated = useMemo(() => {
    if (!rewards?.length) return [];

    const duplicates: { [key: number]: IRewardMinimal } = {};

    rewards.forEach((obj) => {
      const rewardId = obj.id;

      if (duplicates.hasOwnProperty(rewardId)) {
        duplicates[rewardId].quantity += obj.quantity;
      } else {
        duplicates[rewardId] = { ...obj };
      }
    });

    return Object.values(duplicates);
  }, [rewards]);

  const isLocked = useMemo(() => Boolean(dependencies?.length), [dependencies]);

  return (
    <S.Root onMouseEnter={open} onMouseLeave={close} className={clsx(rewardIsAbsolute ? 'relative' : '')}>
      <LessonCard
        id={id}
        lessonIdx={lessonIdx}
        name={name}
        onChange={onChange}
        onDelete={onDelete}
        isInEditMode={isInEditMode}
        onIsInEditModeChange={onIsInEditModeChange}
        onEditSlidesRequest={onEditSlidesRequest}
        isLoading={isLoading}
        dependencies={dependencies}
      />
      <Menu
        onDelete={onDelete}
        onIsInEditModeChange={onIsInEditModeChange}
        rewards={rewardsUnduplicated}
        onLock={() => onToggleLockLesson(id)}
        isLocked={isLocked}
        isShown={isOpen}
        isAbsolutePos
      />
    </S.Root>
  );
};

export default LessonListItem;
