import * as Styled from './styles';

type Props = {
  className?: string;
  percent: number;
  bg?: {
    color?: string;
    width?: string;
  };
  circle?: {
    color?: string;
    width?: string;
  };
};

export const CircleProgress = ({ className = '', percent = 0, bg, circle }: Props) => {
  const strokeWidthCircle = 8;
  const radius = 50 - strokeWidthCircle / 2;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (percent / 100) * circumference;

  return (
    <Styled.Container className={className} viewBox="0 0 100 100">
      <circle cx="50" cy="50" r={radius} fill="none" stroke={bg?.color ?? 'none'} strokeWidth={bg?.width ?? strokeWidthCircle} />
      <circle
        cx="50"
        cy="50"
        strokeLinecap="round"
        r={radius}
        fill="none"
        stroke={circle?.color ?? 'red'}
        strokeWidth={circle?.width ?? strokeWidthCircle}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
      <circle
        cx="50"
        cy="50"
        strokeLinecap="round"
        r={radius}
        fill="none"
        className="blured"
        stroke={circle?.color ?? 'red'}
        strokeWidth={circle?.width ?? strokeWidthCircle}
        strokeDasharray={circumference}
        strokeDashoffset={offset}
      />
    </Styled.Container>
  );
};
