import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  position: fixed;
  top: 105px;
  left: 56px;
  height: calc(100vh - 188px);
  width: calc((100vw - 752px) / 2 - 100px);
  max-width: 250px;
  overflow: auto;
  padding-bottom: 50px;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  @media screen and (min-width: 1351px) {
    opacity: 0.2;
    transition: 0.3s;
    &:hover {
      opacity: 1;
    }
  }
  @media screen and (max-width: 1350px) {
    width: 270px;
    padding: 20px;
    top: 50%;
    left: 0;
    translate: -100% -50%;
    background: var(--body-color);
    transition: 0.3s;
    opacity: 0;
    border-radius: 0px 20px 20px 0px;
    z-index: 100;
    &.isOpen {
      translate: 0% -50%;
      opacity: 1;
    }
  }
  @media screen and (max-width: 992px) {
    position: relative !important;
    top: auto !important;
    left: auto !important;
    width: 100% !important;
    max-width: none !important;
    height: 100%;
    overflow-y: auto;
    transition: 0.3s;
    background: none;
    translate: none !important;
  }
`;

export const MobileContainer = styled(motion.div)<{ $isOpen?: boolean; $theme: THEMES }>`
  position: fixed;
  bottom: 0px;
  left: 0;
  width: 100% !important;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? '#262733cc' : 'rgba(217, 217, 217, 0.4)')};
  backdrop-filter: blur(30px);
  border-radius: 20px 20px 0px 0px;
  height: 70vh;
  max-height: 446px;
  padding: 24px 20px 20px;
  z-index: 9999;
  &::after {
    position: absolute;
    top: ${({ $isOpen }) => ($isOpen ? 8 : -13)}px;
    left: 50%;
    transform: translateX(-50%);
    width: ${({ $isOpen }) => ($isOpen ? 48 : 139)}px;
    height: 5px;
    border-radius: 10px;
    background-blend-mode: luminosity;
    background: ${({ $isOpen, $theme }) => ($isOpen ? '#3d3d3d80' : $theme === THEMES.DARK ? '#FFFFFF80' : '#3d3d3d60')};
    background-blend-mode: overlay;
    display: block;
    content: '';
    transition: 0.5s;
  }
`;
