import { useEffect, useMemo, useState } from 'react';
import * as S from './styles';
import { COLORS, EIcons, ESpecialIcons, SOCIALS } from './constants';
import { MenuIcons } from './ui';
import { ReactComponent as Plus } from './assets/plus.svg';
import { useDisclosure } from 'hooks';
import useClickOutside from 'hooks/useClickOutside';
import { AnimatePresence } from 'framer-motion';
import ColorPicker from './picker';
import { ImagePlusIcon } from 'assets/icons/icons';
import IconButton from 'components/library/iconButton';
import styles from './button.styles.module.css';

type Props = {
  onSelect: (arg: Partial<{ icon: EIcons | ESpecialIcons; color: string }>) => void;
  current: { icon: string; color: string };
  Element: React.FC<any>;
  disableSpecials?: boolean;
  bottomOffset?: string; //px
  onUploadImage?: any;
};

enum ETabs {
  SPECIALS = 'Special icons',
  ICONS = 'Icons',
  // EMOJIS = 'Emojis',
}

const CustomizationMenu = ({ current, onSelect, Element, disableSpecials, bottomOffset, onUploadImage }: Props) => {
  const { close, isOpen, open } = useDisclosure(false);
  const { icon, color } = current;

  const [tab, setTab] = useState<ETabs>(disableSpecials ? ETabs.ICONS : ETabs.SPECIALS);
  const [isPickingColor, setPickingColor] = useState(false);
  const menuRef = useClickOutside<HTMLDivElement>(close);

  useEffect(() => {
    setPickingColor(false);
  }, [isOpen]);

  const tabContent = useMemo(() => {
    switch (tab) {
      case ETabs.SPECIALS:
        return (
          <S.SpecialsMenu>
            <S.Flex dir="column" gap="12px">
              <S.Label>Social Icons</S.Label>
              <S.SpecialsList>
                {SOCIALS.map((el, index) => (
                  <S.SpecialBox key={el.id + index} onClick={() => onSelect({ color: el.color, icon: el.icon })}>
                    <S.SpecialRound color={el.color}>
                      <MenuIcons type={el.icon} />
                    </S.SpecialRound>
                  </S.SpecialBox>
                ))}
              </S.SpecialsList>
            </S.Flex>
          </S.SpecialsMenu>
        );
      case ETabs.ICONS:
        return (
          <>
            <S.IconsBlock>
              <S.Label>Icons</S.Label>
              <S.IconsList>
                {Object.values(EIcons).map((el) => (
                  <S.IconBox key={el} color={color} onClick={() => onSelect({ icon: el })}>
                    <MenuIcons type={el} />
                  </S.IconBox>
                ))}
              </S.IconsList>
            </S.IconsBlock>
          </>
        );
    }
  }, [tab, icon, color, isPickingColor]);

  // const handleScrollClose = () => isOpen && close();

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScrollClose);

  //   return () => window.removeEventListener('scroll', handleScrollClose);
  // }, [isOpen]);

  return (
    <>
      <Element onClick={open} isOpen={isOpen} />
      <AnimatePresence>
        {isOpen && (
          <S.Container
            bottom={bottomOffset}
            ref={menuRef}
            animate={{
              y: 0,
              opacity: 1,
            }}
            initial={{ y: 200, opacity: 0 }}
            exit={{ y: 200, opacity: -1 }}
          >
            <S.Tabs>
              {Object.values(ETabs).map((el) => {
                if (disableSpecials && el === ETabs.SPECIALS) {
                  return null;
                }

                return (
                  <S.Tab key={el} $active={tab === el} onClick={() => setTab(el)}>
                    {el}
                  </S.Tab>
                );
              })}
            </S.Tabs>
            <S.ColorsBlock>
              <S.Label>Color</S.Label>
              {isPickingColor ? (
                <ColorPicker color={color} onChange={onSelect} closePicker={() => setPickingColor(false)} />
              ) : (
                <S.ColorsList>
                  {COLORS.map((el) => (
                    <S.Color key={el.id} color={el.color} onClick={() => onSelect({ color: el.color })} isActive={color === el.color} />
                  ))}
                  <S.ColorButton onClick={() => setPickingColor(true)}>
                    <Plus />
                  </S.ColorButton>
                </S.ColorsList>
              )}
            </S.ColorsBlock>
            {tabContent}
            {!!onUploadImage && (
              <S.UploadImgBox>
                <IconButton className={styles.iconButton} size={37} icon={<ImagePlusIcon />} onClick={onUploadImage} />
              </S.UploadImgBox>
            )}
          </S.Container>
        )}
      </AnimatePresence>
    </>
  );
};

export default CustomizationMenu;
