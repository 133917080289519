import styled, { css } from 'styled-components';

export const SRoot = styled.div(
    ({ theme }) => css`
      display: flex;
      gap: 30px;

      @media screen and (max-width: 576px) {
        gap: 10px;
      }
    `
  ),
  SBackground = styled.div(
    ({ theme }) => css`
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      position: absolute;
      border-radius: 5px;

      &::after {
        content: '';
        position: absolute;
        left: 2px;
        top: 2px;
        right: 2px;
        bottom: 2px;
        border-radius: 5px;
        background: linear-gradient(42deg, rgba(158, 142, 255, 0.08) 0%, rgba(175, 125, 255, 0.02) 100%), var(--basic-bg);
        background-blend-mode: overlay;
      }
    `
  ),
  SContainer = styled.div(
    () => css`
      position: relative;
      width: 828px;
      padding: 25px 32px 35px 20px;

      @media screen and (max-width: 576px) {
        width: calc(100% - 63px);
        padding: 25px;
      }
    `
  ),
  SContent = styled.div(
    () => css`
      position: relative;
    `
  );
