import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'app/constants';
import { coursesConstructorAPI, NEW_ITEM_ID } from 'store';
import { DndContext, closestCenter, PointerSensor, useSensor, useSensors, DragEndEvent } from '@dnd-kit/core';
import { SortableContext, verticalListSortingStrategy, useSortable, arrayMove } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { ReactComponent as DotsIcon } from 'assets/icons/dots-double-vertical.svg';
import { ReactComponent as PlusIcon } from 'assets/icons/plus.svg';
import DisabledOverlay from 'components/library/disabledOverlay';
import Layout from 'pages/coursesConstructor/components/Layout';
import { BasicButtonV1, Flex } from 'shared/ui';

import LessonListItem from './components/LessonListitem';

import styles from './styles.module.css';
import { buildBreadcrumbs } from 'utils/breadcrumbs';
import LogoLoader from 'shared/ui/loader/MightyLoader';
import { ConfirmationModal } from 'shared/ui/course-card';
import { toast } from 'react-toastify';
import { useGetIfUserCanEditCourse } from 'pages/coursesConstructor/hooks';
import Answer from 'components/library/messages/Answer';
import { CourseParserModal } from 'features/CourseParserModal';
import ConstructorButton from 'shared/ui/constructorButtons';
import { addEmptyLesson } from './utils';

const SortableItem = ({ id, children, disabled, dragHandle }: any) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: id, disabled });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} className={styles.dndItem}>
      {!disabled && (
        <div className={styles.dragIcon} {...attributes} {...listeners}>
          {dragHandle}
        </div>
      )}
      {children}
    </div>
  );
};

interface IProps {
  chapterIdProp: string;
  courseId?: string;
}

const CoursesConstructorLessonsList: FC<IProps> = ({ chapterIdProp: chapId, courseId }) => {
  const { spaceName } = useParams<{ chapterId: string; spaceName?: string }>();
  const navigate = useNavigate();

  const sensors = useSensors(useSensor(PointerSensor));

  const { data, isLoading, isError, refetch } = coursesConstructorAPI.useFetchLessonsQuery(chapId as string);
  const [toggleLockLesson, { isLoading: isLockingLesson }] = coursesConstructorAPI.useToggleLockLessonMutation();

  const breadcrumbs = data?.breadcrumbs;
  const lessonsRewards = data?.lessonsRewards ?? {};
  const authors = data?.authors ?? [];

  // Mutations
  const [updateLesson, { isError: isUpdateLessonError }] = coursesConstructorAPI.useUpdateLessonMutation();
  const [deleteLesson, { isLoading: isDeletingLesson }] = coursesConstructorAPI.useDeleteLessonMutation();
  const [addLesson, { isLoading: isAddingLessonLoading }] = coursesConstructorAPI.useAddLessonMutation();
  const [reorderLessons, { isLoading: isReordering }] = coursesConstructorAPI.useReorderLessonsMutation();
  // Queries
  // const { lessons: originalLessons } = useStateSelector<{ lessons: any }>((state) => state.coursesConstructor);
  // State
  const [lessonIsInEditId, setLessonIsInEditId] = useState<string | null>(null);
  const [lessons, setLessons] = useState<any[]>([]);
  const [courseParserIsOpen, setCourseParserIsOpen] = useState(false);
  const [lessonIdForDeletion, setLessonIdForDeletion] = useState<string | null>(null);

  const { isAllowed, isSpaceLoading } = useGetIfUserCanEditCourse(authors);

  useEffect(() => {
    if (authors.length > 0) {
      if (!isAllowed && !isSpaceLoading) {
        navigate(ROUTES.COURSES_CONSTRUCTOR);
      }
    }
  }, [authors, isAllowed, isSpaceLoading]);

  useEffect(() => {
    if (data?.lessons) {
      setLessons([...data.lessons]);
    }
  }, [data?.lessons]);

  useEffect(() => {
    const lessonsHasNewItem = lessons.some((item: any) => item._id === NEW_ITEM_ID);

    if (lessonsHasNewItem && !lessonIsInEditId) {
      setLessonIsInEditId(NEW_ITEM_ID);
    } else if (!lessonsHasNewItem && lessonIsInEditId === NEW_ITEM_ID) {
      setLessonIsInEditId(null);
    }
  }, [lessons, lessonIsInEditId]);

  const handleLessonIsInEditModeChanged = (lessonId: string) => () => {
    if (lessonId === NEW_ITEM_ID) {
      setLessons(lessons.filter((el) => el._id !== NEW_ITEM_ID));
      return;
    }

    if (lessonIsInEditId === lessonId) {
      setLessonIsInEditId(null);
      return;
    }

    setLessonIsInEditId(lessonId);
  };

  const handleLessonChange = (lessonId: string) => (form: { name: string }) => {
    const lesson = structuredClone(lessons.find((item: any) => item._id === lessonId));
    if (!lesson) {
      return;
    }

    if (lessonId === NEW_ITEM_ID) {
      const { _id, description, ...newLessonData } = lesson;
      addLesson({ ...newLessonData, name: form.name })
        .unwrap()
        .then((res) => {
          setLessonIsInEditId(null);
          setLessons(lessons.map((item: any) => (item._id === NEW_ITEM_ID ? res : item)));
        })
        .catch((ex) => {
          alert('Error on lesson add');

          toast.error(<Answer label={''} subtext={ex?.data?.message} type="incorrect" />);
        });

      return;
    }

    const { chapterId, description, ...lessonData } = lesson;

    updateLesson({ lessonId, dto: { ...lessonData, name: form.name } })
      .unwrap()
      .then((newLesson) => {
        if (isUpdateLessonError) {
          alert('Error on update lesson');
          return;
        }

        setLessons((prev) => {
          const changedLessonIndex = prev.findIndex((i) => i._id === lessonId);
          if (changedLessonIndex !== -1) {
            const newLess = { ...prev[changedLessonIndex], ...newLesson };
            return [...prev.slice(0, changedLessonIndex), newLess, ...prev.slice(changedLessonIndex + 1)];
          }
          return [...prev];
        });
        setLessonIsInEditId(null);
      });
  };

  const handleLessonDelete = () => {
    if (lessonIdForDeletion) {
      deleteLesson(lessonIdForDeletion)
        .unwrap()
        .then(() => {
          setLessons((lessons) => lessons.filter((el) => el._id !== lessonIdForDeletion));
          setLessonIdForDeletion(null);
          refetch();
        })
        .catch((ex) => {
          toast.error(<Answer subtext={ex?.data?.message} type="incorrect" />);
        });
    }
  };

  const handleAddEmptyLesson = () => {
    addEmptyLesson({
      chapterId: chapId,
      type: 'lesson',
      id: NEW_ITEM_ID,
      setLessons,
    });
  };

  const handleEditSlidesRequest = (lessonId: string) => () => {
    if (spaceName) {
      navigate('/' + spaceName + `/lesson/${lessonId}`);
      return;
    }

    navigate(`/courses/edit/lessons/${lessonId}`);
  };

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (!over || active.id === over.id) {
      return;
    }

    const lessonsCopy = [...lessons];
    const oldIndex = lessonsCopy.findIndex((item) => item._id === active.id);
    const newIndex = lessonsCopy.findIndex((item) => item._id === over.id);
    const newItems = arrayMove(lessonsCopy, oldIndex, newIndex);
    setLessons(newItems);

    const lessonsIds = newItems.map((i: any) => i._id);

    reorderLessons({ chapterId: chapId, lessonsIds })
      .unwrap()
      .catch((ex) => {
        setLessons(lessonsCopy);
        toast.error(<Answer subtext={ex?.data?.message} type="incorrect" />);
      });
  };

  const handleOnDelete = (id: string) => () => {
    setLessonIdForDeletion(id);
  };

  const handleToggleLockLesson = async (lessonId: string) => {
    if (!isLockingLesson) {
      toggleLockLesson({ lessonId })
        .unwrap()
        .then((res) => {
          setLessons((prev: any) =>
            prev.map((curr: any) => {
              const item = res.lessons.find((ch: any) => ch._id === curr._id);
              if (item) {
                return { ...curr, dependencies: item.dependencies };
              }
              return curr;
            })
          );
          toast.success(<Answer label={'success'} subtext={res.message} type="correct" />);
        })
        .catch((ex) => {
          console.error(ex);
          toast.error(<Answer label={'Error'} subtext={ex?.data?.message} type="incorrect" />);
        });
    }
  };

  if (isError) return <div>Something went wrong...</div>;

  if (chapId) {
    if (isLoading) {
      return (
        <>
          <div className={styles.shimmer}></div>
          <div className={styles.shimmer}></div>
          <div className={styles.shimmer}></div>
        </>
      );
    }
    return (
      <Flex style={{ maxWidth: '458px' }} $flexDirection="column">
        <CourseParserModal
          isOpen={courseParserIsOpen}
          onClose={() => setCourseParserIsOpen(false)}
          courseParams={{
            courseId: courseId ?? '',
            chapterId: chapId ?? null,
          }}
          refetchCourse={refetch}
        />

        <ConfirmationModal
          title="Please confirm deletion"
          isLoading={isDeletingLesson}
          isOpen={!!lessonIdForDeletion}
          deleteCourse={handleLessonDelete}
          onClose={() => setLessonIdForDeletion(null)}
        />

        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={lessons.map((lesson) => lesson._id)} strategy={verticalListSortingStrategy}>
            {Boolean(lessons) &&
              lessons.map((item: any) => {
                const lessonItem = (
                  <LessonListItem
                    // Fields
                    isInEditMode={lessonIsInEditId === item._id || item._id === NEW_ITEM_ID}
                    lessonIdx={item.lessonIdx}
                    name={item.name}
                    id={item._id}
                    rewards={lessonsRewards[item._id]}
                    isLoading={isAddingLessonLoading}
                    // Actions
                    onIsInEditModeChange={handleLessonIsInEditModeChanged(item._id)}
                    onEditSlidesRequest={handleEditSlidesRequest(item._id)}
                    onTapDeteleButton={() => {
                      setLessonIdForDeletion(item._id);
                    }}
                    onDelete={handleOnDelete(item._id)}
                    onChange={handleLessonChange(item._id)}
                    key={item._id + 'lesson-item'}
                    rewardIsAbsolute={true}
                    onToggleLockLesson={handleToggleLockLesson}
                    dependencies={item?.dependencies}
                  />
                );

                if (lessonIsInEditId && item._id !== lessonIsInEditId) {
                  return (
                    <DisabledOverlay key={item._id}>
                      <div style={{ padding: '25px 5px' }}>{lessonItem}</div>
                    </DisabledOverlay>
                  );
                }

                return (
                  <SortableItem key={item._id} id={item._id} disabled={isReordering || !!lessonIsInEditId} dragHandle={<DotsIcon />}>
                    {lessonItem}
                  </SortableItem>
                );
              })}
          </SortableContext>
        </DndContext>
        <div className={`${styles.buttonsContainer} ${styles.inLessons}`}>
          <ConstructorButton variant="add" disabled={!!lessonIsInEditId} onClick={handleAddEmptyLesson} label="Add a new lesson" />
          <ConstructorButton variant="import" disabled={!!lessonIsInEditId} onClick={() => setCourseParserIsOpen(true)} label="Import from URL" />
        </div>
      </Flex>
    );
  }

  return (
    <>
      <ConfirmationModal
        title="Please confirm deletion"
        isLoading={isDeletingLesson}
        isOpen={!!lessonIdForDeletion}
        deleteCourse={handleLessonDelete}
        onClose={() => setLessonIdForDeletion(null)}
      />

      <Layout title={`${breadcrumbs?.chapter?.name}: Lessons & Tests`} breadcrumbs={buildBreadcrumbs(breadcrumbs, spaceName)}>
        {isLoading ? (
          <LogoLoader className={styles.loader} />
        ) : (
          <>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
              <SortableContext items={lessons.map((lesson) => lesson._id)} strategy={verticalListSortingStrategy}>
                {Boolean(lessons) &&
                  lessons.map((item: any, index: number) => {
                    const lessonItem = (
                      <LessonListItem
                        // Fields
                        isInEditMode={lessonIsInEditId === item._id || item._id === NEW_ITEM_ID}
                        lessonIdx={item.lessonIdx}
                        name={item.name}
                        id={item._id}
                        rewards={lessonsRewards[item._id]}
                        isLoading={isAddingLessonLoading}
                        // Actions
                        onIsInEditModeChange={handleLessonIsInEditModeChanged(item._id)}
                        onEditSlidesRequest={handleEditSlidesRequest(item._id)}
                        onDelete={() => setLessonIdForDeletion(item._id)}
                        onChange={handleLessonChange(item._id)}
                        onTapDeteleButton={() => {
                          setLessonIdForDeletion(item._id);
                        }}
                        key={index}
                        onToggleLockLesson={handleToggleLockLesson}
                        dependencies={item?.dependencies}
                      />
                    );

                    if (lessonIsInEditId && item._id !== lessonIsInEditId) {
                      return (
                        <DisabledOverlay key={item._id}>
                          <div style={{ padding: '25px 5px' }}>{lessonItem}</div>
                        </DisabledOverlay>
                      );
                    }

                    return (
                      <SortableItem key={item._id} id={item._id} disabled={isReordering} dragHandle={<DotsIcon />}>
                        {lessonItem}
                      </SortableItem>
                    );
                  })}
              </SortableContext>
            </DndContext>
            <br />
            <div className={styles.addBlock}>
              <BasicButtonV1 variant="rounded" fullWidth={false} size="sm" onClick={handleAddEmptyLesson} disabled={!!lessonIsInEditId}>
                <PlusIcon /> Add a new lesson
              </BasicButtonV1>
            </div>
          </>
        )}
      </Layout>
    </>
  );
};

export default CoursesConstructorLessonsList;
