import styled, { css } from 'styled-components';
import bg from 'components/pages/layouts/fullscreen/assets/caves-bg.png';

export const Container = styled.div(
    () => css`
      position: fixed;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 1000;
      background-image: url(${bg});
      background-size: cover;
      .modal.mobile {
        padding-bottom: 40px;
      }
    `
  ),
  Modal = styled.div(
    () => css`
      padding-top: calc(2rem + 168px) !important;
    `
  ),
  Body = styled.div(
    () => css`
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 509px;
      height: fit-content;
      border-radius: 12px;
      border: 2px solid var(--basic-border-color, rgba(255, 255, 255, 0.7));
      background: linear-gradient(0deg, rgba(50, 53, 71, 0.47) 5.23%, rgb(106 116 177 / 80%) 107.31%);
      backdrop-filter: blur(21.5px);
      padding: 0 60px 30px;

      & p,
      & span,
      & h4 {
        color: white;
        text-align: center;
      }

      .spinner {
        color: white;
      }

      .code {
        width: fit-content;
        justify-content: center;
        gap: 10px;
        margin-bottom: 34px;

        input {
          color: white !important;
          background: rgba(var(--basic-light--rgb), 0.5) !important;
        }
        &.error {
          margin-bottom: 20px;
        }
      }
      .error {
        margin-bottom: 20px;
        color: rgb(241, 79, 99);
      }

      &.mobile {
        width: 100%;
        padding: 96px 32px 0;
        background: none;
        border: none;
        position: relative;
        backdrop-filter: none;

        ${Letter} {
          position: absolute;
          margin-top: 0;
          top: -115px;
        }
        ${Header} {
          font-size: 28px;
        }
        ${Text} {
          width: 65%;
        }
        ${Buttons} {
          margin-top: auto;
        }
      }
    `
  ),
  Letter = styled.div(
    () => css`
      margin-top: -23%;
      margin-bottom: 60px;
      margin-left: 30px;
    `
  ),
  Header = styled.h4(
    () => css`
      font-size: 47px;
      font-weight: 500;
      line-height: 115%;
      letter-spacing: 0.5px;
      margin-bottom: 12px;
    `
  ),
  Text = styled.p(
    () => css`
      font-size: 18px;
      line-height: 124%;
      margin-bottom: 32px;
    `
  ),
  Span = styled.span(
    () => css`
      color: var(--mighty-blue) !important;
    `
  ),
  Buttons = styled.div(
    () => css`
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      justify-content: center;
      gap: 10px;
    `
  ),
  Button = styled.button(
    () => css`
      min-width: 157px;
      height: 54px;
      font-size: 18px;
    `
  );
