import { ReactComponent as TrashIcon } from 'assets/icons/trash.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/copy.svg';
import { ReactComponent as AddBlockIcon } from 'assets/icons/add-block.svg';

import { ReactComponent as CodeIcon } from 'assets/icons/code.svg';
import { ReactComponent as ImagePlusIcon } from 'assets/icons/image-plus.svg';
import { ReactComponent as VideoIcon } from 'assets/icons/video.svg';
import { ReactComponent as AttachmentIcon } from 'assets/icons/attachment.svg';
import { ReactComponent as InfoIcon } from 'assets/icons/info.svg';
import { ReactComponent as QuoteIcon } from 'assets/icons/quote-icon.svg';
import { ReactComponent as SocTaskIcon } from 'assets/icons/soc-task-icon.svg';

// social tasks topics
import { ReactComponent as WebsiteTopicIcon } from 'assets/icons/socials/task-topics/website-topic.svg';
import { ReactComponent as MailTopicIcon } from 'assets/icons/socials/task-topics/mail-topic-icon.svg';
import { ReactComponent as YTTopicIcon } from 'assets/icons/socials/task-topics/yt-topic.svg';
import { ReactComponent as FBTopicIcon } from 'assets/icons/socials/task-topics/facebook-topic.svg';
import { ReactComponent as XTopicIcon } from 'assets/icons/socials/task-topics/x-topic.svg';
import { ReactComponent as DiscordTopicIcon } from 'assets/icons/socials/task-topics/discord-topic.svg';
import { ReactComponent as InstTopicIcon } from 'assets/icons/socials/task-topics/inst-topic.svg';
import { ReactComponent as TGTopicIcon } from 'assets/icons/socials/task-topics/telegram-topic.svg';
import { TDropdownMenuItem } from '../../../../../../dropdownMenu/types';
import { ETaskWidgetPreset } from '../../TaskWidget/types';
// on-chain
import { ReactComponent as OnChainIcon } from 'assets/icons/tasks/on-chain/on-chain.svg';
import { ReactComponent as TONTopicIcon } from 'assets/icons/tasks/on-chain/TON.svg';

export type TActionsDropdownItemsProps = {
  onDelete: () => void;
  onDuplicate: () => void;
  onTurn: () => void;
} & TBlocksDropdownItemsProps;

export type TBlocksDropdownItemsProps = {
  onAddText: () => void;
  onAddCode: () => void;
  onAddImage: () => void;
  onAddVideo: () => void;
  onAddFile: () => void;
  onAddWarning: () => void;
  onAddTask: (preset: ETaskWidgetPreset) => void;
  onAddQuote: () => void;
};

export const getActionsDropdownItems = ({ onDelete, onDuplicate, onTurn, ...others }: TActionsDropdownItemsProps): TDropdownMenuItem[] => [
  {
    title: 'Delete',
    icon: <TrashIcon />,
    onClick: onDelete,
  },
  {
    title: 'Duplicate',
    icon: <CopyIcon />,
    onClick: onDuplicate,
  },
  {
    title: 'Add block',
    icon: <AddBlockIcon />,
    menu: {
      items: getBlocksDropdownItems(others),
    },
  },
];

export const getBlocksDropdownItems = (props: TBlocksDropdownItemsProps): TDropdownMenuItem[] => [
  {
    title: 'Text',
    icon: <CodeIcon />,
    onClick: props.onAddText,
  },
  {
    title: 'Code',
    icon: <CodeIcon />,
    onClick: props.onAddCode,
  },
  {
    title: 'Image',
    icon: <ImagePlusIcon />,
    onClick: props.onAddImage,
  },
  {
    title: 'Video',
    icon: <VideoIcon />,
    onClick: props.onAddVideo,
  },
  {
    title: 'File',
    icon: <AttachmentIcon />,
    onClick: props.onAddFile,
  },
  {
    title: 'Warning',
    icon: <InfoIcon />,
    onClick: props.onAddWarning,
  },
  {
    title: 'Quote',
    icon: <QuoteIcon />,
    onClick: props.onAddQuote,
  },
  {
    title: 'Social Tasks',
    icon: <SocTaskIcon />,
    menu: {
      items: [
        {
          title: 'Website',
          isTopic: true,
          icon: <WebsiteTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.WEBSITE,
          onClick: () => props.onAddTask(ETaskWidgetPreset.WEBSITE),
        },
        {
          title: 'Mail',
          isTopic: true,
          icon: <MailTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.MAIL,
          onClick: () => props.onAddTask(ETaskWidgetPreset.MAIL),
        },
        {
          title: 'Youtube',
          isTopic: true,
          icon: <YTTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.YT_VISIT,
          onClick: () => props.onAddTask(ETaskWidgetPreset.YT_VISIT),
        },
        {
          title: ETaskWidgetPreset.YT_WATCH,
          onClick: () => props.onAddTask(ETaskWidgetPreset.YT_WATCH),
        },
        {
          title: 'Facebook',
          isTopic: true,
          icon: <FBTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.FACEBOOK,
          onClick: () => props.onAddTask(ETaskWidgetPreset.FACEBOOK),
        },
        {
          title: 'X',
          isTopic: true,
          icon: <XTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.X_FOLLOW,
          onClick: () => props.onAddTask(ETaskWidgetPreset.X_FOLLOW),
        },
        {
          title: ETaskWidgetPreset.X_LIKE,
          onClick: () => props.onAddTask(ETaskWidgetPreset.X_LIKE),
        },
        {
          title: ETaskWidgetPreset.X_RETWEET,
          onClick: () => props.onAddTask(ETaskWidgetPreset.X_RETWEET),
        },
        {
          title: 'Discord',
          isTopic: true,
          icon: <DiscordTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.DISCORD,
          onClick: () => props.onAddTask(ETaskWidgetPreset.DISCORD),
        },
        {
          title: 'Instagram',
          isTopic: true,
          icon: <InstTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.INSTAGRAM_VISIT,
          onClick: () => props.onAddTask(ETaskWidgetPreset.INSTAGRAM_VISIT),
        },
        {
          title: ETaskWidgetPreset.INSTAGRAM_SAVE,
          onClick: () => props.onAddTask(ETaskWidgetPreset.INSTAGRAM_SAVE),
        },
        {
          title: ETaskWidgetPreset.INSTAGRAM_COMMENT,
          onClick: () => props.onAddTask(ETaskWidgetPreset.INSTAGRAM_COMMENT),
        },
        {
          title: ETaskWidgetPreset.INSTAGRAM_LIKE,
          onClick: () => props.onAddTask(ETaskWidgetPreset.INSTAGRAM_LIKE),
        },
        // {
        //   title: 'Github',
        //   isTopic: true,
        //   icon: <GitHubTopicIcon />
        // },
        {
          title: 'Telegram',
          isTopic: true,
          icon: <TGTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.TELEGRAM,
          onClick: () => props.onAddTask(ETaskWidgetPreset.TELEGRAM),
        },
      ],
      bottomAlign: true,
    },
  },
  {
    title: 'On-Chain Tasks',
    icon: <OnChainIcon />,
    menu: {
      items: [
        {
          title: 'TELEGRAM OPEN NETWORK',
          isTopic: true,
          icon: <TONTopicIcon />,
        },
        {
          title: ETaskWidgetPreset.ONCHAIN_DEPOSIT,
          onClick: () => props.onAddTask(ETaskWidgetPreset.ONCHAIN_DEPOSIT),
        },
        {
          title: ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_ETH,
          onClick: () => props.onAddTask(ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_ETH),
        },
        {
          title: ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_BSC,
          onClick: () => props.onAddTask(ETaskWidgetPreset.ONCHAIN_BRIDGE_TON_BSC),
        },
        // {
        //   title: ETaskWidgetPreset.ONCHAIN_STAKE_TON,
        //   onClick: () => props.onAddTask(ETaskWidgetPreset.ONCHAIN_STAKE_TON),
        // },
      ],
      bottomAlign: true,
    },
  },
];
