import { ReactComponent as MenuIcon } from 'assets/icons/menu-1.svg';
import { BsChevronLeft } from 'react-icons/bs';
import { useSwipe } from 'hooks';

import { MobileModal } from 'shared/utils/mobile';
import Details from '../Details';
import MightyLoaderMini from 'shared/ui/loader/mini/MightyLoaderMini';

import styles from '../styles.module.scss';

type Props = {
  isOpen: boolean;
  setSelectedCourse(): void;
  setSeeChaptersList(value: boolean): () => void;
  seeChaptersList: boolean;
  course: any;
  cover: string;
  goToCourse(): void;
  isReady: boolean;
};
const MobileBanner = ({ isOpen, setSelectedCourse, setSeeChaptersList, seeChaptersList, course, cover, goToCourse, isReady }: Props) => {
  const { onTouchEnd, onTouchStart, onTouchMove } = useSwipe({
    direction: 'vertical',
    handleLeftTop: () => {},
    handleRightBottom: setSelectedCourse,
  });

  const handleOpenDetails = (e: React.MouseEvent<any>) => {
    e.stopPropagation();
    setSeeChaptersList(true)();
  };

  return (
    <MobileModal
      isOpen={isOpen}
      onClose={setSelectedCourse}
      iconLeft={<BsChevronLeft size={50} strokeWidth={1} onClick={setSelectedCourse} />}
      iconRight={<MenuIcon width={'53px'} height={'47px'} onClick={handleOpenDetails} />}
      className={styles.background}
      classNameContainer={styles.container}
      defaultProps={{
        onTouchEnd,
        onTouchStart,
        onTouchMove,
      }}
    >
      {isReady ? (
        <Details
          course={course}
          cover={cover}
          seeChaptersList={seeChaptersList}
          goToCourse={goToCourse}
          setSeeChaptersList={setSeeChaptersList(false)}
        />
      ) : (
        <MightyLoaderMini className={styles.loader} />
      )}
    </MobileModal>
  );
};

export default MobileBanner;
