export const overlay = {
  hidden: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.1,
    },
  },
  exit: {
    opacity: 0,
    transition: {
      duration: 0.1,
    },
  },
};

export const modal = {
  hidden: {
    y: '100%',
    transition: { duration: 0.5 },
  },
  visible: {
    y: '0',
    transition: {
      type: 'spring',
      damping: 17,
      stiffness: 150,
      duration: 0.5,
    },
  },
  exit: {
    y: '100%',
    transition: {
      type: 'just',
      duration: 0.5,
    },
  },
};
