import { InlineAnswersEditor } from '../../core';
import { IInlineAnswerExtension } from '../../core/types';

export type TInlineAnswersAnswerInputExtensionProps = {
  onAddAnswer: (answer: string) => void;
};

export class InlineAnswersAnswerInputExtension implements IInlineAnswerExtension<TInlineAnswersAnswerInputExtensionProps> {
  private _editor!: InlineAnswersEditor;
  private _visible = false;
  private _node!: HTMLSpanElement;
  private _measureNode!: HTMLDivElement;
  private _props: TInlineAnswersAnswerInputExtensionProps;

  constructor(props: TInlineAnswersAnswerInputExtensionProps) {
    this._props = props;
  }

  attach = (editor: InlineAnswersEditor) => {
    this._editor = editor;

    this._bootstrap();
  };

  detach = () => {
    throw new Error('Method not implemented.');
  };

  private _bootstrap = () => {
    this._initListeners();
  };

  private _initListeners = () => {
    this._editor.getRoot().addEventListener('keydown', this._handleEditorKeydown);
  };

  private _handleEditorKeydown = (evt: KeyboardEvent) => {
    if (evt.key === '/' && !this._visible) {
      this.mount();
      evt.preventDefault();

      return;
    }
  };

  public mount = (mountToEnd = false) => {
    let selection = window.getSelection();
    if (!selection) {
      this._editor.focus();
      selection = window.getSelection();
    }

    if (!selection) {
      return;
    }

    this._createInputElement();
    this._setupInputListeners();

    const inputNode = this._node.querySelector('input');

    this._editor.freezeSelection();

    const range = selection.rangeCount > 0 ? selection.getRangeAt(0) : new Range();

    if (mountToEnd) {
      const lastNode = this._editor.getRoot().lastElementChild;
      if (lastNode) {
        range.selectNodeContents(lastNode);
        range.collapse(false);
      }
    }

    range.insertNode(this._node);

    selection.removeAllRanges();
    selection.addRange(range);
    range.collapse(false);

    inputNode?.focus();

    this._visible = true;
  };

  private _remove = () => {
    this._resetInputListeners();
    this._node?.remove();
    this._measureNode?.remove();

    this._editor.restoreSelection();

    this._visible = false;
  };

  private _handleInputKeydown = (evt: KeyboardEvent) => {
    if (!this._node) {
      return;
    }

    const input = this._node.querySelector('input') as HTMLInputElement;
    const value = input.value.trim();

    if (evt.key === 'Backspace' && !value) {
      evt.preventDefault();
      this._remove();
      return;
    }

    if (evt.key === 'Escape' && !value) {
      evt.preventDefault();
      evt.stopPropagation();

      this._remove();

      this._editor.insertText('/');
      return;
    }

    if (evt.key === 'Enter') {
      evt.preventDefault();

      if (!value) {
        this._remove();
        return;
      }

      this._remove();
      this._props.onAddAnswer(input.value?.trim());
    }
  };

  private _handleInputInput = () => {
    const input = this._node.querySelector('input');
    if (!input) {
      return;
    }

    this._measureNode.textContent = input.value;

    input.style.width = `${this._measureNode.offsetWidth + 5}px`;
  };

  private _createInputElement = () => {
    const inputWrapper = document.createElement('span');
    inputWrapper.setAttribute('contenteditable', 'false');
    inputWrapper.className = 'inline-answers-v2__answer-input';
    inputWrapper.textContent = 'Add answer: ';

    const input = document.createElement('input');
    inputWrapper.appendChild(input);

    this._node = inputWrapper;

    const measure = document.createElement('div');
    measure.className = 'inline-answers-v2__answer-input-measure';
    document.body.appendChild(measure);
    this._measureNode = measure;
  };

  private _setupInputListeners = () => {
    if (!this._node) {
      return;
    }

    const input = this._node.querySelector('input');

    input?.addEventListener('keydown', this._handleInputKeydown);
    input?.addEventListener('input', this._handleInputInput);
  };

  private _resetInputListeners = () => {
    if (!this._node) {
      return;
    }

    const input = this._node.querySelector('input');

    input?.removeEventListener('keydown', this._handleInputKeydown);
    input?.removeEventListener('input', this._handleInputInput);
  };

  updateProps(props: TInlineAnswersAnswerInputExtensionProps): void {
    this._props = props;
  }
}
