import { useTonConnectUI } from '@tonconnect/ui-react';
import PreaparedButton from './styledButton';
import { useCallback, useEffect } from 'react';
import { TButtonsProps } from './types';
import { useLinkTon } from './hooks/useLinkTon';

const TONLinkButton = ({ setStage, handleClose, stage, title }: TButtonsProps) => {
  const [tonConnectUI] = useTonConnectUI();

  useLinkTon();

  useEffect(() => {
    return () => {
      tonConnectUI.closeModal();
      tonConnectUI.disconnect();
    };
  }, []);

  const handleClick = useCallback(() => {
    switch (stage) {
      case 'error':
        setStage('initial');
        break;
      case 'initial':
        tonConnectUI.openModal();
        break;
      case 'progress':
        break;
      case 'success':
        handleClose();
        break;
    }
  }, [stage]);

  return <PreaparedButton onClick={handleClick} title={title} />;
};

export default TONLinkButton;
