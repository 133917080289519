import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled(motion.div)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  justify-content: center;
  /* gap: 12px; */
  height: 153px;
  color: var(--font-color-strong);
  position: relative;
  & > svg {
    width: 64px;
    height: 64px;
  }
  & canvas {
  }
`;

export const Sub = styled(motion.p)`
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  color: inherit;
`;

export const FakeSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
  color: inherit;
  position: absolute;
  top: 0;
  left: 0;
  filter: blur(10px);
  pointer-events: none;
  @media screen and (max-width: 992px) {
    height: 200px;
    overflow: hidden;
  }
`;

export const FakeTitle = styled.h3`
  font-size: 28px;
`;

export const FakeSub = styled.p`
  font-size: 18px;
`;

export const LoaderBox = styled(motion.div)`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: fit-content;
  padding-bottom: 30px;
`;

export const AnimationContainer = styled.div`
  margin-bottom: -20px;
  height: 104px;
  width: 100%;
`;

export const Box = styled(motion.div)`
  height: 153px;
`;
