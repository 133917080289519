import { getTestsBlocksDropdownItems } from '../../Widget/components/CodeWidget/TestAdderDropdownMenu';
import { ESlideTypes, ESlideWidgetTypes } from 'store';

import { getBlocksDropdownItems } from '../../Widget/components/CodeWidget/WidgetDropdownMenu';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';
import styles from './styles.module.css';
import { useSlideContext } from '../../SlideContext';
import { DropdownMotion, Flex } from 'shared/ui';
import { TooltippedToggle } from 'shared/ui/toggle';
import { ETaskWidgetPreset } from '../../Widget/components/TaskWidget/types';

type Props = {
  handleAddNewWidget: () => (arg: ESlideWidgetTypes) => void;
  handleMakeTest: (type: ESlideTypes, isGenerated?: boolean, isRandomTestType?: boolean) => void;
  handleAddTaskWidget: () => (arg: ETaskWidgetPreset) => void;
};

export default function Dropdowns({ handleAddNewWidget, handleMakeTest, handleAddTaskWidget }: Props) {
  const {
    slide,
    loading,
    attachmentAction: { toggleAnyCorrect, toggleIsOptional },
  } = useSlideContext();

  function handleToggleAnyCorrect() {
    toggleAnyCorrect(slide._id);
  }
  function handleToggleIsOptional() {
    toggleIsOptional(slide._id);
  }

  return (
    <div className={styles.dropdownMenu}>
      <DropdownMotion
        triggerNode={
          <span className={styles.dropdownMenuToggle}>
            <AiOutlinePlus /> Add block
          </span>
        }
        items={getBlocksDropdownItems({
          onAddText: () => handleAddNewWidget()(ESlideWidgetTypes.TEXT),
          onAddCode: () => handleAddNewWidget()(ESlideWidgetTypes.CODE),
          onAddFile: () => handleAddNewWidget()(ESlideWidgetTypes.FILE),
          onAddImage: () => handleAddNewWidget()(ESlideWidgetTypes.IMAGE),
          onAddWarning: () => handleAddNewWidget()(ESlideWidgetTypes.WARNING),
          onAddVideo: () => handleAddNewWidget()(ESlideWidgetTypes.VIDEO),
          onAddTask: handleAddTaskWidget(),
          onAddQuote: () => handleAddNewWidget()(ESlideWidgetTypes.QUOTE),
        })}
      />
      {slide.typeOfSlide === ESlideTypes.INFO ? (
        <DropdownMotion
          triggerNode={
            <span className={styles.dropdownMenuToggle}>
              <AiOutlinePlus /> Add test
            </span>
          }
          items={getTestsBlocksDropdownItems({
            onMakeFillblocks: () => handleMakeTest(ESlideTypes.FILLBLOCKS),
            onMakeFillblocksCode: () => handleMakeTest(ESlideTypes.FILLBLOCKS_CODE),
            onMakeFillspaces: () => handleMakeTest(ESlideTypes.FILLSPACES),
            onMakeFillspacesCode: () => handleMakeTest(ESlideTypes.FILLSPACES_CODE),
            onMakeMultichoice: () => handleMakeTest(ESlideTypes.MULTICHOICE),
            onMakeTypeAnswer: () => handleMakeTest(ESlideTypes.TYPEANSWER),
            onMakeGeneratedTest: (type: ESlideTypes, isRandomTestType?: boolean) => handleMakeTest(type, true, isRandomTestType),
          })}
        />
      ) : (
        <>
          <button className={styles.dropdownMenuToggle} onClick={() => handleMakeTest(ESlideTypes.INFO)}>
            <AiOutlineClose /> Remove test
          </button>
          <Flex margin="0 0 0 auto" $gap="25px">
            <div className={styles.anyCorrect}>
              <p>Optional</p>
              <TooltippedToggle
                disabled={loading}
                header="Optional test"
                description="This test can be skipped, but user still has to finish it on order to get rewards"
                checked={slide.slideType?.isOptional}
                onChange={handleToggleIsOptional}
              />
            </div>
            <div className={styles.anyCorrect}>
              <p>Any correct</p>
              <TooltippedToggle
                disabled={loading}
                header="Questionare"
                description="Toggles if this is a questionare"
                checked={slide.slideType.isAnyCorrect}
                onChange={handleToggleAnyCorrect}
              />
            </div>
          </Flex>
        </>
      )}
    </div>
  );
}
