import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';
import { setIsFriendFinder } from 'store';
import { dispatch } from 'hooks';

import { ReactComponent as Add } from 'components/library/images/icons/add-user.svg';
import { BsArrowRight } from 'react-icons/bs';

import styles from './styles.module.css';

export default function ProfileFriendsPlaceholder() {
  const theme = useSelector(getCurrentTheme);

  function handleFriendsFinder() {
    dispatch(setIsFriendFinder(true));
  }

  return (
    <div className={clsx(styles.container, styles[theme])} role="button" onClick={handleFriendsFinder}>
      <Add className={styles.icon} />
      <p className={styles.title}>You don't have any friends yet, click here to find a friend</p>
      <BsArrowRight className={styles.arrow} />
    </div>
  );
}
