import { DetailedHTMLProps, forwardRef, InputHTMLAttributes, useRef, useState } from 'react';
import styles from './styles.module.css';
import { ReactComponent as ShowIcon } from 'assets/components/showPassword.svg';
import { ReactComponent as HideIcon } from 'assets/components/hidePassword.svg';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

interface IProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  icon?: any;
  isError?: boolean;
}

export const InputPassword = forwardRef<HTMLInputElement, IProps>(({ icon: Icon, isError, value = '', className = '', ...rest }, ref) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const theme = useSelector(getCurrentTheme);
  const VisibilityIcon = showPassword ? HideIcon : ShowIcon;

  const changeVisiblePasswordHandler = () => {
    setShowPassword(!showPassword);
  };

  const onInputFocus = () => {
    inputRef?.current?.focus();
  };

  return (
    <>
      <div
        onClick={onInputFocus}
        className={`${clsx(styles.container, {
          [styles.error]: isError,
          [styles.hasValue]: value,
          [className]: !!className,
          [styles[theme]]: theme,
        })} glass-div basic ${theme}`}
      >
        <Icon className={clsx(styles.icon, { [styles.error]: isError })} />
        <input type={showPassword ? 'text' : 'password'} ref={inputRef} {...rest} className={clsx(styles.input, styles[theme])} />
        <VisibilityIcon onClick={changeVisiblePasswordHandler} className={clsx(styles.showIcon, { [styles.error]: isError })} />
      </div>
    </>
  );
});
