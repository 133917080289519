import styled, { css } from 'styled-components';

export const SMenu = styled.div(
    ({ theme }) => css`
      border-radius: 5px;
      border: 2px solid rgba(255, 255, 255, 0.05);
      padding: 3px 0;
      background: ${theme.theme.background};
      min-width: 170px;
      font-size: 14px;
    `
  ),
  SToggle = styled.div(
    () => css`
      background: transparent;
      cursor: pointer;
      padding: 0;
      border: none;
    `
  );
