import styled, { css } from 'styled-components';

export const SummaryContainer = styled.div(
    () => css`
      margin-bottom: 20px;
    `
  ),
  Container = styled.div(
    () => css`
      background-color: #2c2c3e;
      border-radius: 8px;
      padding: 20px;
      color: #ffffff;
      display: inline-block;
      margin: 0 10px 10px 0;
      min-width: 200px;
      height: 130px;
      width: 226px;

      & * {
        color: var(--font-color-string);
      }
    `
  ),
  Header = styled.h3(
    () => css`
      font-size: 18px;
      margin-bottom: 10px;
      line-height: 1;
      font-weight: 400;
    `
  ),
  Paragraph = styled.p(
    () => css`
      font-size: 30px;
      font-weight: bold;
      margin: 0;
    `
  );
