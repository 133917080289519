import { THEMES } from 'app/constants';
import { motion } from 'framer-motion';
import styled from 'styled-components';

export const ImageFrame = styled.div<{ $theme: THEMES }>`
  width: 88px;
  height: 54px;
  border-radius: 50px 6px 6px 50px;
  background: ${({ $theme }) => ($theme === THEMES.DARK ? '#d9d9d90f' : '#d9d9d933')};
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;
export const Image = styled.div<{ color?: string; $solo?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  color: ${(p) => p.color || 'white'};
  border-radius: 50%;
  height: ${(p) => (p.$solo ? '46px' : '100%')};
  min-width: ${(p) => (p.$solo ? '46px' : 'auto')};
  aspect-ratio: 1 / 1;
  background: rgba(255, 255, 255, 0.17);
  & svg,
  & img {
    width: 30px;
    height: 30px;
    object-fit: contain;
  }
`;

export const TaskContainer = styled.div<{ $bgColor?: string; $completed?: boolean; $theme: THEMES }>`
  ${(p) => !p.$completed && !p.$bgColor && 'background: rgba(255, 255, 255, 0.1);'}
  ${(p) => !p.$completed && p.$bgColor && `background: ${p.$bgColor}${p.$theme === THEMES.DARK ? '11' : '99'};`}
  ${(p) => p.$completed && 'background: rgba(41, 171, 136, 0.1);'}
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 2px;
  border: ${(p) => (p.$completed ? '2px solid #29AB88' : 'none')};
  ${({ $theme }) => $theme === THEMES.LIGHT && 'box-shadow: 0px 2px 7px #11111133;'}
  /* overflow: hidden; */
  transition: 0.3s;

  & .task_editor p {
    line-height: 100%;
  }
`;

export const TaskTop = styled.div`
  display: flex;
  gap: 16px;
  padding: 13px;
  align-items: center;
  position: relative;
  transition: 0.3s;
  @media screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    padding: 17px;
  }
`;

export const TaskBottom = styled.div`
  padding: 37px 18px 18px;
  display: flex;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  align-items: center;
  gap: 44px;
  transition: 0.3s;
`;
export const TaskButton = styled(motion.button)<{
  $bgColor?: string;
  $completed?: boolean;
  $editable?: boolean;
  $color: string;
  $theme: THEMES;
}>`
  font-size: 18px;
  padding: 0 20px;
  box-shadow: 34px 21px 16px rgba(48, 49, 61, 0.01), 9px 5px 10px rgba(48, 49, 61, 0.01);
  border-radius: 6px;
  background: #ffffff1a;
  ${(p) =>
    !p.$editable && !p.$bgColor?.includes('FDFDFD') && `background: linear-gradient(90deg, ${p.$bgColor} 0%, ${p.$bgColor} 48%, #FFFFFF 227.06%);`}
  ${(p) =>
    p.$completed &&
    'background: linear-gradient(90deg, #29AB88 0%, #B8DA76 227.06%), linear-gradient(284.22deg, rgba(255, 255, 255, 0.665) -0.22%, rgba(255, 255, 255, 0.095) 197.75%);'}
    border: none;
  ${(p) => !p.$completed && p.$theme === THEMES.LIGHT && 'box-shadow: 0px 2px 7px #11111133;'}
  outline: none;
  white-space: nowrap;
  color: ${({ $color, $completed }) => ($completed ? '#fff' : $color)};
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  height: 46px;
  gap: 8px;
  transition: 0.3s;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;

export const TaskLabel = styled.label<{ $color: string }>`
  color: ${({ $color }) => $color};
  font-size: 16px;
  line-height: 100%;
  margin-bottom: 8px;
  margin-top: -24px;
  transition: 0.3s;
`;

export const TaskInput = styled.input<{ $color: string; $theme: THEMES }>`
  border: none;
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  line-height: 100%;
  padding: 17px 21px;
  background: rgba(255, 255, 255, 0.04);
  color: ${({ $color }) => $color};
  ${({ $theme }) => $theme === THEMES.LIGHT && 'box-shadow: 0px 2px 7px #11111120;'}
  &::placeholder {
    opacity: 0.5;
  }
`;

export const FlexCol = styled.div<{ gap?: string; $jcc?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: ${(p) => p.gap || '0px'};
  ${(p) => (p.$jcc ? 'justify-content: center;' : '')}
`;

export const FlexRow = styled.div<{ gap?: string }>`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${(p) => p.gap || '0px'};
`;

export const TogglerBox = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: fit-content;
  transition: 0.3s;
`;

export const TogglerLabel = styled.p`
  font-size: 18px;
  font-weight: 400;
  transition: 0.3s;
`;

export const Title = styled.input<{ $color: string }>`
  border: none;
  outline: none;
  color: ${({ $color }) => $color};
  font-size: 16px;
  line-height: 100%;
  background: none;
  width: fit-content;
  padding: 0;
  transition: 0.3s;
`;

export const TitleStr = styled.p<{ $color: string }>`
  color: ${({ $color }) => $color} !important;
  font-size: 16px;
  line-height: 100%;
  transition: 0.3s;

  @media screen and (max-width: 992px) {
    margin-bottom: 16px;
  }
`;

export const Message = styled.p`
  width: 100%;
  margin-top: 12px;
  text-align: right;
  color: #29ab88 !important;
  font-size: 18px;
  font-weight: 400;
  transition: 0.3s;
`;
