import styled, { css } from 'styled-components';

export const SContainer = styled.div<{ size: number }>(
  ({ size }) => css`
    width: ${size}px;
    height: ${size}px;
    min-width: ${size}px;
    min-height: ${size}px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--font-color-strong);
    gap: 2px;

    span {
      color: var(--basic-blue);
    }
  `
);
