import { ReactComponent as VerifiedIcon } from '../../assets/verified.svg';
import { ReactComponent as PeopleIcon } from '../../assets/people-icon.svg';
import * as S from './styles';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { useGetUserRelatedSpaceInfo } from 'components/pages/spaces/hooks';
import { SpaceFollowButton } from 'components/pages/spaces/SpaceCreation/components/followButton';
import useAverageColorFromImageUrl from 'hooks/useAverageColorFromImageUrl';
import { useDisplay } from 'hooks';
import { useSelector } from 'react-redux';
import { ISpace, getCurrentTheme, useGetSubscribersQuery } from 'store';
import { THEMES } from 'app/constants';
import clsx from 'clsx';
import { NavigateOptions } from 'react-router-dom';

type Props = {
  space: ISpace;
  handleNavigate: (url: string, opts?: NavigateOptions) => void;
};

export const SpaceItem = ({ space, handleNavigate }: Props) => {
  const [isHover, setIsHover] = useState(false);
  const { data: followers } = useGetSubscribersQuery(space._id);
  const { isAbleToEdit } = useGetUserRelatedSpaceInfo(space);
  const { isMobile } = useDisplay();
  const theme = useSelector(getCurrentTheme);
  const color = useAverageColorFromImageUrl(space.imageThumb, { opacity: theme === THEMES.DARK ? '0.1' : '0.5' });
  const spaceURL = space.name.replaceAll(' ', '_');

  const bottomVariants = {
    animate: {
      y: 0,
      opacity: 1,
    },
    exit: {
      y: 30,
      opacity: 0,
    },
    initial: {
      y: 30,
      opacity: 0,
    },
  };

  return (
    <S.ItemContainer
      className={clsx(theme)}
      color={color}
      onMouseEnter={() => (isMobile ? () => {} : setIsHover(true))}
      onMouseLeave={() => (isMobile ? () => {} : setIsHover(false))}
      onClick={() => handleNavigate(spaceURL)}
    >
      <S.ItemImage src={space.imageThumb} />
      <S.ItemInfo>
        <S.ItemName $theme={theme}>
          <span>{space.name}</span> {space.isVerified && <VerifiedIcon />}
        </S.ItemName>

        {isHover && (
          <AnimatePresence>
            <S.ItemBottom $theme={theme} variants={bottomVariants} animate={'animate'} initial={'initial'}>
              {isAbleToEdit ? (
                <S.EditButton
                  $theme={theme}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleNavigate(spaceURL, { state: { edit: true } });
                  }}
                >
                  Edit
                </S.EditButton>
              ) : (
                <SpaceFollowButton small spaceId={space._id} />
              )}
            </S.ItemBottom>
          </AnimatePresence>
        )}
        {!isHover && (
          <AnimatePresence>
            <S.ItemBottom $theme={theme} variants={bottomVariants} animate={'animate'} initial={'initial'}>
              <PeopleIcon /> {followers && followers.items.length}
            </S.ItemBottom>
          </AnimatePresence>
        )}
      </S.ItemInfo>
    </S.ItemContainer>
  );
};
