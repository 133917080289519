import styled from 'styled-components';

export const SWrapper = styled.div<{ $theme: string }>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 25px;
    min-height: 60px;
    border-radius: 5px;
    padding: 16px 25px;
    color: var(--font-color-strong);
    cursor: pointer;
    border: 1px solid ${(p) => (p.$theme === 'dark' ? 'rgba(255, 255, 255, 0.08)' : 'var(--mighty-blue)')};
    background: ${({ $theme }) => ($theme === 'dark' ? 'rgba(255, 255, 255, 0.04)' : 'rgba(255, 255, 255, 1)')};
  `,
  SRow = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
  `,
  STitle = styled.div`
    flex: 1;
  `;
