import { motion } from 'framer-motion';
import styled from 'styled-components';

export const Container = styled.div<{ $size: number }>`
  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;
  min-width: ${({ $size }) => $size}px;
  min-height: ${({ $size }) => $size}px;
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Background = styled.div<{ $color: string }>`
  background: ${({ $color }) => $color};
  position: absolute;
  left: 50%;
  top: 49%;
  width: 100%;
  height: 100%;
  opacity: 0.5;
  border-radius: 4px;
  transform: rotate(-1.5deg) translate(-50%, -50%);
  z-index: 0;
`;

export const Image = styled(motion.img)`
  width: 123% !important;
  min-width: 123% !important;
  height: 123% !important;
  position: relative;
  z-index: 1;
  /* border-radius: 4px; */
  /* transform: rotate(12deg); */
  /* position: absolute; */
  /* top: 50%; */
  /* left: 50%; */
`;
