import { FC, PropsWithChildren } from 'react';
import clsx from 'clsx';

import { Logo } from 'shared/utils';

import { ReactComponent as Background } from './assets/background.svg';
import { ReactComponent as IconGradient } from './assets/mighty-icon--gradient.svg';
import { BackButton } from 'components/library/BackButton';
import { useSelector } from 'react-redux';
import { getIsShowSuccessAuthModal } from 'store';

import style from '../desktop/styles.module.scss';
import * as Styled from './styled';
import { useBackButtonClick } from '../useBackButtonClick';

export const AuthPageViewMobile: FC<PropsWithChildren> = ({ children }) => {
  const isShowSuccessAuthModal = useSelector(getIsShowSuccessAuthModal);

  const handleBackButtonClick = useBackButtonClick();

  if (isShowSuccessAuthModal) {
    return <Styled.Container>{children}</Styled.Container>;
  }

  return (
    <Styled.Container>
      <BackButton className={style.authBackButton} onClick={handleBackButtonClick} text={''} />
      <Styled.Wrap className={'blur-100'} initial={{ y: 400 }} animate={{ y: 0 }} transition={{ type: 'spring', bounce: 0 }}>
        <Styled.Content $isDetected={false}>
          <Styled.MightyIcon>
            <IconGradient />
            <Logo containerClassName={clsx('logo-container')} />
          </Styled.MightyIcon>
          <h2 className={'download-app__title'}>Welcome to Mighty</h2>
          <p className={'download-app__text'}>Learn | Play | Win rewards</p>
          {children}
        </Styled.Content>
        <Background className={'download-app__background'} />
      </Styled.Wrap>
    </Styled.Container>
  );
};
