import styled from 'styled-components';

export const Container = styled.svg`
  height: 100%;
  width: 100%;
  transform: rotateZ(-90deg);

  .blured {
    mix-blend-mode: overlay;
    filter: blur(3px);
  }
`;
