import { THEMES } from 'app/constants';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useDisplay, useGetCurrentTheme } from 'hooks';
import { useSelector } from 'react-redux';
import { getCurrentTheme } from 'store';

import { ReactComponent as Energy } from './assets/energy--low.svg';
// import premiumIcon from 'components/shared/Premium/assets/premium.png';
import { CgClose } from 'react-icons/cg';

// import BasicButton from 'shared/ui/basic-button/button';
import { MobileModal } from 'shared/utils/mobile';
// import { Benefits } from 'components/pages/premium/screens/ChoosePlan/components/Benefits/Benefits';
// import { benefits } from 'components/shared/Premium/data/benefits';
// import LittleItemsCarousel from 'shared/ui/little-items-carousel';
// import { BenefitCard } from 'components/pages/premium/screens/ChoosePlan/components/Benefits';
import CenteredModal from 'components/library/utils/CenteredModal/CenteredModal';
import expPotionImg from 'assets/images/exp-potion.png';
import expPotionLgImg from 'components/library/images/itemPage/exp_potion_lg.png';

import * as Styled from './styles';
import BasicButton from 'shared/ui/basic-button/button';

type Props = {
  isShown: boolean;
  onClose: () => any;
};

export function EnergyGoneModal({ isShown, onClose }: Props) {
  const navigate = useNavigate();
  const theme = useGetCurrentTheme();
  // TODO открыть сайдбар с бутылкой энергии, иначе перекинуть в шоп
  // const handleBuyPremium = () => navigate(ROUTES.FULLSCREEN_PREMIUM);
  const { isMobile } = useDisplay();

  if (!isShown) {
    return null;
  }

  const body = (
    <Styled.Container className={clsx({ mobile: isMobile })}>
      <Styled.Images>
        <Styled.Image as={Energy} />
      </Styled.Images>
      <Styled.Header>The energy is gone :(</Styled.Header>
      <Styled.Text>Buy boosters to keep getting EXP</Styled.Text>
      <Styled.ItemsList>
        <Styled.ItemBox className={`${theme}`}>
          <Styled.ItemImage src={expPotionImg} />
        </Styled.ItemBox>
        <Styled.ItemBox className={`${theme}`}>
          <Styled.ItemImage src={expPotionLgImg} />
        </Styled.ItemBox>
      </Styled.ItemsList>
      <BasicButton onClick={() => navigate('/shop')} label="Go to shop" variant="cool" className="button" />
    </Styled.Container>
  );

  if (isMobile) {
    return (
      <Styled.MobileModal>
        <CgClose role="button" onClick={onClose} className={clsx('close-btn')} size={50} />
        <MobileModal classNameContainer={clsx('mobileModalContainer', theme)} isOpen={isShown} onClose={onClose}>
          {body}
        </MobileModal>
      </Styled.MobileModal>
    );
  }

  return (
    <Styled.Modal
      as={CenteredModal}
      className={clsx('modal')}
      show={isShown}
      onClose={onClose}
      backgroundColor="none"
      closeButtonPosition={{ top: '-40px' }}
      closeButtonVariant={theme === THEMES.LIGHT ? 'red-light' : 'regular'}
    >
      <Styled.BG className={clsx(theme)} />
      {body}
    </Styled.Modal>
  );
}
