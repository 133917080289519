import styled from 'styled-components';

export const CodeWebEditorContainer = styled.div`
  width: 100%;

  .CodeMirror-wrap {
    .CodeMirror-cursor {
      border-left: 1px solid #fff;
    }
    .CodeMirror-line span[role='presentation'] {
      line-break: anywhere;
    }
  }

  .cm-editor,
  .cm-gutters {
    background: black !important;
  }
  .cm-editor {
    outline: none;
  }
`;
