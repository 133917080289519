import { RESULT_STATUSES, ROUTES, SOUNDS, STANDARD_ENERGY_FEE } from 'app/constants';
import { STATIC_HOST_PATH } from 'app/constants/path';
import Answer from 'components/library/messages/Answer';
import { useGetIsAuth, useGetMultipliedExp, useGetUserData, useNavigateToAuth } from 'hooks';
import useAudio from 'hooks/useAudio';
import useSendLoadedMessageToIframe from 'hooks/useSendLoadedMessageToIframe';
import { IChapterFull, ICourseFull, IResultCourse, ISlideFull } from 'interface/courses';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { sleep } from 'shared/utils';
import { getLessonPageVolume, getPartnerId, IRewardMinimal, useCompleteSlideMutation, useGetCourseForCompletionQuery, useGetMyPartsQuery } from 'store';
import { IAnswerResponse, IAnswerResponseError, ICompleteSlideResponseSuccess } from 'store/course/types';
import rewardUserLocal from 'utils/rewardUserLocal';

export const useLessonForCompletion = () => {
  const { courseId = '', chapterId = '', lessonId = '' } = useParams();
  const [resultCourse, setResultCourse] = useState<IResultCourse | null>();
  const isAuth = useGetIsAuth();
  const navigate = useNavigate();
  const partnerId = useSelector(getPartnerId);

  const {
    data: courseForCompletion,
    isLoading,
    isFetching,
    isError,
    refetch,
  } = useGetCourseForCompletionQuery({ courseId, chapterId, lessonId }, { skip: !courseId || !chapterId || !lessonId });
  useSendLoadedMessageToIframe(!!courseForCompletion && !isLoading && !isFetching);

  const course = useMemo(() => (courseForCompletion ? courseForCompletion.course : null), [courseForCompletion]);

  useEffect(() => {
    if (courseForCompletion) {
      setResultCourse(courseForCompletion.resultCourse);
    }
  }, [courseForCompletion]);

  useEffect(() => {
    refetch();
  }, [isAuth]);

  const { chapter, lesson } = useMemo(() => {
    if (!course) {
      return { chapter: null, lesson: null };
    }
    const chapter = course.chapters.find((el) => el._id === chapterId);
    if (!chapter) {
      return { chapter: null, lesson: null };
    }

    const lesson = chapter.lessons.find((el) => el._id === lessonId) ?? null;
    return { chapter, lesson };
  }, [course, chapterId, lessonId]);

  const { completedSlides, rewardedSlides } = useMemo(() => {
    if (!resultCourse?.resultChapters) {
      return { completedSlides: [], rewardedSlides: [] };
    }
    const chapter = resultCourse.resultChapters.find((el) => el.chapterId === chapterId);
    if (!chapter) {
      return { completedSlides: [], rewardedSlides: [] };
    }
    const lesson = chapter.resultLessons.find((el) => el.lessonId === lessonId);
    if (!lesson) {
      return { completedSlides: [], rewardedSlides: [] };
    }
    const rewardedSlides = lesson.resultSlides.filter((el) => el.isRewardsClaimed).map((el) => el.slideId);
    const completedSlides = lesson.resultSlides.filter((el) => el.status === RESULT_STATUSES.FINISHED).map((el) => el.slideId);
    return { completedSlides, rewardedSlides };
  }, [resultCourse]);

  const { imageLogo, isQuest, space } = useMemo(() => {
    if (!course) {
      return {
        isQuest: false,
        space: undefined,
        imageLogo: '',
      };
    } else
      return {
        isQuest: course.isQuest,
        space: course.authors.find((el) => el.kind === 'Space') || undefined,
        imageLogo: STATIC_HOST_PATH + '/' + (course.isQuest ? course.imageCover : course.imageLogo ?? course.imageCover ?? course.imageBanner),
      };
  }, [course]);

  useEffect(() => {
    if ((!courseForCompletion && !isLoading) || isError) {
      navigate(ROUTES.MAIN);
      return;
    }
    if (!isAuth && !partnerId && isQuest) {
      navigate(ROUTES.MAIN);
      return;
    }
  }, [courseForCompletion, isError, isLoading, isQuest, isAuth, partnerId]);

  return {
    course,
    resultCourse,
    setResultCourse,
    isLoading: isLoading || isFetching,
    isQuest,
    space,
    imageLogo,
    chapter,
    lesson,
    completedSlides,
    rewardedSlides,
  };
};

type Props = {
  course: ICourseFull | null;
  chapter: IChapterFull | null;
};

export const useGetLessonMethods = ({ course, chapter }: Props) => {
  const navigate = useNavigate();
  const screenRef = useRef<HTMLDivElement | null>(null);
  const { nextLessonId = null, nextChapterId = null } = useMemo(() => {
    if (!chapter) return { nextLessonId: null, nextChapterId: null };
    const { nextLessonId, nextChapterId } = chapter;
    return { nextLessonId, nextChapterId };
  }, [chapter]);
  const partnerId = useSelector(getPartnerId);

  const navToNextLesson = () => {
    if (nextLessonId && course && chapter) {
      if (partnerId) {
        navigate(`${ROUTES.COURSES}/${course._id}/${nextChapterId ?? chapter._id}/${nextLessonId}?partnerID=${partnerId}`);
        return;
      }
      navigate(`${ROUTES.COURSES}/${course._id}/${nextChapterId ?? chapter._id}/${nextLessonId}`);
    }
  };

  const navToOverview = () => {
    if (course) {
      if (partnerId) {
        navigate(`/${partnerId}?partnerID=${partnerId}`);
        return;
      }
      if (course.spaceInfo?.name) {
        navigate(`/${course.spaceInfo.name.replaceAll(' ', '_')}`);
        return;
      }
      navigate(ROUTES.MAIN);
    }
  };

  return {
    navToOverview,
    navToNextLesson,
    nextLessonId,
    screenRef,
  };
};

export const usePlayLessonPage = () => {
  const { play: _playSlideChange } = useAudio({ sound: SOUNDS.SLIDE_CHANGE.sound });
  const { play: _playReward } = useAudio({ sound: SOUNDS.RECEIVING_REWARD.sound });
  const { play: _playAnswerRight } = useAudio({ sound: SOUNDS.ANSWER_RIGHT.sound });
  const { play: _playAnswerWrong } = useAudio({ sound: SOUNDS.ANSWER_WRONG.sound });

  return {
    playSlideChange: () => _playSlideChange(SOUNDS.SLIDE_CHANGE.volume),
    playReward: () => _playReward(SOUNDS.RECEIVING_REWARD.volume),
    playAnswerRight: () => _playAnswerRight(SOUNDS.ANSWER_RIGHT.volume),
    playAnswerWrong: () => _playAnswerWrong(SOUNDS.ANSWER_WRONG.volume),
  };
};

type TSlideMethodsProps = {
  resultCourse: IResultCourse | null;
  completedSlides: string[];
  slide: ISlideFull;
  updateRight: (arg: boolean | null) => void;
  setIsLoading: (arg: boolean) => void;
  handleEnergyModal: (arg: boolean) => void;
  setResultCourse: (arg: IResultCourse) => void;
  addClaimedRewards: (arg: IRewardMinimal[]) => void;
  isSlideRewarded: boolean;
};

export const useSlideMethods = ({
  completedSlides,
  handleEnergyModal,
  resultCourse,
  setIsLoading,
  slide,
  updateRight,
  setResultCourse,
  addClaimedRewards,
  isSlideRewarded,
}: TSlideMethodsProps) => {
  const { refetch: getExp } = useGetMyPartsQuery({ props: ['exp'] });
  const { chapterId, lessonId } = useParams();
  const [isStoppedCauseEnergy, setStoppedCauseEnergy] = useState(false);
  const { playAnswerRight, playAnswerWrong, playReward } = usePlayLessonPage();
  const navToAuth = useNavigateToAuth();
  const isAuth = useGetIsAuth();
  const { getMultipliedExp } = useGetMultipliedExp();
  const [completeSlide, { isLoading: isCompleting }] = useCompleteSlideMutation();

  const isLoading = useMemo(() => isCompleting, [isCompleting]);

  const userData = useGetUserData();

  const energyLevel = useMemo(() => {
    if (userData?.exp) {
      return userData.exp.energy;
    } else {
      return 40;
    }
  }, [userData]);

  const resultSlide = useMemo(() => {
    const resChap = resultCourse?.resultChapters.find((el) => el.chapterId === chapterId);
    if (!resChap) {
      return null;
    }
    const resLesson = resChap.resultLessons.find((el) => el.lessonId === lessonId);
    if (!resLesson) {
      return null;
    }
    return resLesson.resultSlides.find((el) => el.slideId === slide._id) ?? null;
  }, [lessonId, chapterId, resultCourse, slide]);

  const completeInfoSlide = useCallback(() => {
    if (isStoppedCauseEnergy || !resultSlide) {
      return;
    }
    if (energyLevel < 25) {
      handleEnergyModal(true);
      setStoppedCauseEnergy(true);
      return;
    }
    if (completedSlides.includes(slide._id)) {
      return;
    }

    completeSlide({ resultCourseId: resultCourse?._id || '', slideId: slide._id, userAnswers: [] })
      .unwrap()
      .then((res) => {
        setResultCourse(res.resultCourse);
      })
      .catch(console.error);
  }, [slide, completedSlides, isAuth, setIsLoading, isStoppedCauseEnergy, resultSlide]);

  const catchSlideError = useCallback(
    async ({ data: result }: { data: IAnswerResponseError }) => {
      if (result.energy === 0 && result.message === 'Not enough energy') {
        handleEnergyModal(true);
      }
      if (result.answersResult === 'wrong') {
        toast.error(<Answer type="incorrect" label={'Wrong answer'} />, { autoClose: 2500 });
        playAnswerWrong();
        updateRight(false);
        return;
      }
      await sleep(100, setIsLoading, false);
      toast.error(<Answer type="incorrect" label={result.message ?? 'Something went wrong :/'} />, { autoClose: 2500 });
    },
    [playAnswerWrong]
  );

  const answerRightRoutine = useCallback(
    async (result: IAnswerResponse | ICompleteSlideResponseSuccess, asyncHandler?: () => Promise<void>) => {
      if (result.answersResult === 'right') {
        playAnswerRight();
        updateRight(true);
        toast.success(<Answer type="correct" />, { autoClose: 5000 });
        if (!!slide.rewards.length && !isSlideRewarded) {
          const rewsWMultipliedExp = slide.rewards.map((r) => ({ ...r, quantity: getMultipliedExp(r.id, r.quantity) }));
          addClaimedRewards(rewsWMultipliedExp);
          await sleep(1000, () => {
            playReward();
            rewardUserLocal(rewsWMultipliedExp);
          });
        }
      }
      if (!!asyncHandler && !slide.slideType.isOptional) {
        await asyncHandler();
      }
      if ('resultCourse' in result) {
        setResultCourse(result.resultCourse);
      }
      getExp();
    },
    [isSlideRewarded, playAnswerRight, playReward]
  );

  const completeTestSlide = useCallback(
    async (userAnswers: string[], asyncHandler?: () => Promise<void>) => {
      updateRight(null);
      if (!resultSlide) {
        return;
      }
      if (!isAuth) {
        navToAuth();
        return;
      }
      if (energyLevel < STANDARD_ENERGY_FEE) {
        handleEnergyModal(true);
        return;
      }
      await completeSlide({ resultCourseId: resultCourse?._id || '', slideId: slide._id, userAnswers })
        .unwrap()
        .then((result) => answerRightRoutine(result, asyncHandler))
        .catch(catchSlideError);
    },
    [slide, resultCourse, completedSlides, isAuth, energyLevel, isLoading, isSlideRewarded, resultSlide, catchSlideError, answerRightRoutine]
  );

  return {
    completeTestSlide,
    completeInfoSlide,
    isLoading,
    resultSlide,
  };
};
